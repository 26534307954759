import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';

import { candidatesViewFilterLabels } from '../../../../helpers/constants';
import ViewSelectFilter from '../../../molecules/filters/view-select-filter/ViewSelectFilter';
import OptionInterface from '../../../atoms/form/option/option.interface';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	FilterParamsType,
	parseUrlParams,
	stringifyParamsForUrl,
} from '../../../../helpers/custom/url';
import CandidatesViewSelectFilterInterface from './candidates-view-select-filter.interface';

const CandidatesViewSelectFilter: FC<CandidatesViewSelectFilterInterface> = ({
	itemsToExclude,
	decreaseIndent,
	defaultFiltering,
}) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [options, setOptions] = useState<OptionInterface[]>([]);
	const [filter, setFilter] = useState<string>();

	useEffect(() => {
		const { filter } = parseUrlParams<FilterParamsType>(location.search);
		setFilter(filter || defaultFiltering);
	}, [defaultFiltering, location.search]);

	const getOptions = useCallback(() => {
		let itemsToDisplay = [];

		if (itemsToExclude && itemsToExclude.length) {
			itemsToDisplay = candidatesViewFilterLabels.filter(
				(label) => !itemsToExclude.includes(label.value),
			);
		} else {
			itemsToDisplay = candidatesViewFilterLabels;
		}

		const getIndent = (indent?: number) => {
			if (indent && decreaseIndent) {
				const result = indent - 1;
				if (result !== 0) return result;
			} else return indent;
		};

		const receivedOptions: OptionInterface[] =
			itemsToDisplay &&
			itemsToDisplay.map(({ text, value, indent }: OptionInterface) => ({
				text,
				value,
				indent: getIndent(indent),
			}));

		setOptions(receivedOptions);
	}, [decreaseIndent, itemsToExclude]);

	const changeOption = useCallback(
		({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
			const filterParams = parseUrlParams<FilterParamsType>(location.search);
			filterParams.filter = value;

			navigate({
				pathname: location.pathname,
				search: stringifyParamsForUrl(filterParams),
			});
		},
		[location.pathname, location.search, navigate],
	);

	useEffect(() => getOptions(), [getOptions]);

	return <ViewSelectFilter defaultValue={filter} options={options} onChange={changeOption} />;
};

export default React.memo(CandidatesViewSelectFilter);
