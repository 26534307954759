import React, { FC } from 'react';
import classNames from 'classnames';

import ModalDownloadItemProcessingInterface from './modal-download-item-processing.interface';
import Title from '../../atoms/title/Title';
import TypographyText from '../../atoms/ui/typography-text';
import Loader from '../../molecules/loader';

import styles from './ModalDownloadItemProcessing.module.scss';

const ModalDownloadItemProcessing: FC<ModalDownloadItemProcessingInterface> = ({
	title,
	description,
	className,
	...rest
}) => {
	return (
		<div className={classNames(styles.wrap, className)} {...rest}>
			<Title
				className={styles.title}
				component="h3"
				marginBottom={10}
				fontSize={20}
				fontWeight={600}
			>
				{title}
			</Title>

			<Loader className={styles.loader} />

			{description && <TypographyText className={styles.desc}>{description}</TypographyText>}
		</div>
	);
};

export default ModalDownloadItemProcessing;
