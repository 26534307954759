import React from 'react';

import { shallowEqual, useSelector } from 'react-redux';
import { RootStateType } from '../../../../store/reducers/root-reducer';
import Title from '../../../atoms/title/Title';
import TopPageLayout from '../../../templates/top-page-layout/TopPageLayout';
import useActiveCompany from '../../../../helpers/hooks/use-active-company/useActiveCompany';

import styles from './ProfileTopBar.module.scss';
import Warnings from '../warnings/warnings';

const ProfileTopBar = () => {
	const {
		user: { info: userInfo },
		companies: { activeCompanyId, items },
	} = useSelector((state: RootStateType) => state, shallowEqual);
	const activeCompany = useActiveCompany(activeCompanyId, items);

	return (
		<>
			<Warnings />
			<TopPageLayout className={styles.wrap}>
				<div>
					<Title component="h2" fontSize={26} fontWeight={600}>
						{userInfo?.profile
							? `${userInfo?.profile?.firstName} ${userInfo?.profile?.lastName}`
							: null}
					</Title>
					<Title
						component="h3"
						fontSize={20}
						fontWeight={300}
						className={styles.subtitle}
					>
						{userInfo?.profile?.title}
					</Title>
				</div>

				{activeCompany?.logoUrl && (
					<img
						className={styles.logo}
						src={activeCompany?.logoUrl}
						alt={activeCompany?.name}
					/>
				)}
			</TopPageLayout>
		</>
	);
};

export default ProfileTopBar;
