import React, { FC, useEffect, useMemo, useState } from 'react';

import Logo from '../../atoms/icons/logo';

import HeaderInterface from './header.interface';
import { useDispatch } from 'react-redux';
import Container from '../../templates/container';
import useActiveCompany from '../../../helpers/hooks/use-active-company/useActiveCompany';
import Dropdown from '../dropdown';
import Navigation from '../../molecules/navigation/Navigation';

import AccountMenu from '../../molecules/custom/account-menu/AccountMenu';
import styles from './Header.module.scss';
import useCompanies from '../../../helpers/hooks/use-companies/useCompanies';
import useUser from '../../../helpers/hooks/use-user/useUser';
import { getActiveCompanyBalance } from '../../../store/slices/companies.slice';
import questionMark from '../../../assets/icons/question-in-circle.svg';
import Tooltip from '../../atoms/ui/tooltip';
import classNames from 'classnames';
import ExternalLink from '../../atoms/navigation/external-link/ExternalLink';
import { learnMoreGA4Event } from '../../../helpers/google-analytics-4/events';
import { getCompanyDataForGA, getJobDataForGA } from '../../../helpers/google-analytics-4/helpers';
import { getContentDataForGA } from '../../../helpers/custom/common';
import { useLocation } from 'react-router-dom';
import { GAItemBrand, GAMethod } from '../../../helpers/google-analytics-4/index.constants';
import useJob from '../../../helpers/hooks/use-job/useJob';

const Header: FC<HeaderInterface> = ({ children, navLinks }) => {
	const dispatch = useDispatch();
	const location = useLocation();

	const { info } = useUser();
	const {
		activeCompanyId,
		companies,
		activeCompanyCanBoost,
		activeCompanyBalance,
		balanceUpdateLoading,
	} = useCompanies();
	const activeCompany = useActiveCompany(activeCompanyId, companies);
	const { info: jobInfo } = useJob();

	const [dropdownOpenState, setDropdownOpenState] = useState(false);

	const renderDropdownToggle = useMemo(() => {
		return (
			<div className={styles['dropdown-toggle']}>
				<div className={styles.user}>
					{info?.profile?.firstName} {info?.profile?.lastName}
					{activeCompany?.name ? `, ${activeCompany?.name}` : ''}
				</div>

				{activeCompanyCanBoost ? (
					<div className={styles['spark-credits']}>
						SparkCredits: {balanceUpdateLoading ? ' ' : activeCompanyBalance}
					</div>
				) : null}
			</div>
		);
	}, [
		activeCompany?.name,
		activeCompanyBalance,
		activeCompanyCanBoost,
		balanceUpdateLoading,
		info?.profile?.firstName,
		info?.profile?.lastName,
	]);

	const renderKnowledgeBaseSection = useMemo(() => {
		const knowledgeBaseLink = process.env.REACT_APP_BOOST_KNOWLEDGE_BASE_URL;

		if (!knowledgeBaseLink) return null;

		const triggerLearnMoreGA4Event = () => {
			learnMoreGA4Event(
				{
					...(jobInfo && jobInfo?.id ? { jobData: getJobDataForGA(jobInfo) } : {}),
					companyData: getCompanyDataForGA(activeCompany),
					contentData: getContentDataForGA(location),
				},
				{
					itemBrand: GAItemBrand.sparkBoost,
					method: GAMethod.header,
				},
			);
		};

		return (
			<div className={classNames(styles['knowledge-base'], styles['item-with-tooltip'])}>
				<ExternalLink
					href={knowledgeBaseLink}
					target="_blank"
					rel="noopener noreferrer"
					className={styles['knowledge-link']}
					onClick={triggerLearnMoreGA4Event}
				>
					<img src={questionMark} alt="Knowledge base" />
				</ExternalLink>

				<Tooltip className={styles['item-tooltip']} text={'Knowledge base'} />
			</div>
		);
	}, [activeCompany, jobInfo, location]);

	useEffect(() => {
		if (activeCompanyId && activeCompanyCanBoost) {
			dispatch(getActiveCompanyBalance(activeCompanyId));
		}
	}, [activeCompanyId, activeCompanyCanBoost, dispatch]);

	return (
		<header className={styles.header}>
			<Container flex className={styles['header-container']}>
				<Logo className={styles.logo} to="/jobs" />

				<Navigation links={navLinks} />

				{children}

				{info && (
					<Dropdown
						open={dropdownOpenState}
						onOpenStateChangeHandler={setDropdownOpenState}
						className={classNames(
							styles['logout-btn'],
							!activeCompanyCanBoost ? styles['without-credits'] : '',
						)}
						dropdownContentClassName={styles['dropdown-content']}
						toggle={renderDropdownToggle}
						dropdownContent={
							<AccountMenu
								dropdownOpenState={dropdownOpenState}
								setDropdownOpenState={setDropdownOpenState}
								hideSparkCredits={!activeCompanyCanBoost}
							/>
						}
					/>
				)}

				{renderKnowledgeBaseSection}
			</Container>
		</header>
	);
};

export default React.memo(Header);
