import { useSelector } from 'react-redux';
import { ErrorRedirectionInterface } from '../../../store/slices/error-redirection.slice';

export default function useRedirectionErrors() {
	const {
		errorRedirection: { error },
	} = useSelector((state: { errorRedirection: ErrorRedirectionInterface }) => state);

	return {
		error,
	};
}
