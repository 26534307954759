import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { getMinMaxWidthStyles } from '../../../helpers';
import inputStyles from '../form-elemets/input-with-float-label/InputWithLabel.module.scss';
import PhoneFieldWithCountryCodeInterface from './phone-field-with-country-code.interface';
import Label from '../../atoms/form/label/Label';
import Error from '../../molecules/ui/error';

import styles from './PhoneFieldWithCountryCode.module.scss';

const PhoneFieldWithCountryCode: FC<PhoneFieldWithCountryCodeInterface> = ({
	phoneNumberRef,
	phoneCountryCodeRef,
	id,
	label,
	labelSettings,
	error,
	className,
	onValueChange,
	phoneCountryCodeInputSettings,
	phoneNumberInputSettings,
	minWidth,
	maxWidth,
	value,
	...rest
}) => {
	const [countryCodeState, setCountryCodeState] = useState<string | undefined>('');
	const [phoneState, setPhoneState] = useState<string | undefined>('');
	const [isLabelUp, setLabelUp] = useState<boolean>(false);

	useEffect(() => {
		if (!phoneCountryCodeInputSettings?.value && phoneCountryCodeInputSettings?.defaultValue) {
			setCountryCodeState(phoneCountryCodeInputSettings.defaultValue.toString());
		}

		if (!phoneNumberInputSettings?.value && phoneNumberInputSettings?.defaultValue) {
			setPhoneState(phoneNumberInputSettings?.defaultValue.toString());
		}
	}, [
		phoneNumberInputSettings?.defaultValue,
		phoneCountryCodeInputSettings.defaultValue,
		phoneCountryCodeInputSettings?.value,
		phoneNumberInputSettings?.value,
	]);

	useEffect(() => {
		if (phoneCountryCodeInputSettings?.value) {
			setCountryCodeState(phoneCountryCodeInputSettings.value.toString());
		}

		if (phoneNumberInputSettings?.value) {
			setPhoneState(phoneNumberInputSettings?.value.toString());
		}
	}, [phoneNumberInputSettings.value, phoneCountryCodeInputSettings.value]);

	const inlineStyles = getMinMaxWidthStyles(minWidth, maxWidth);

	useEffect(() => {
		setLabelUp(true);
	}, []);

	const onInputFocusHandler = () => {
		setLabelUp(true);
	};

	const onInputBlurHandler = (event: { target: { value: string | any[] } }) => {
		if (!event.target.value.length) setLabelUp(false);
	};

	const onPhoneInputChange = (value: string, country: { dialCode: string }) => {
		setCountryCodeState(country.dialCode || '');

		setPhoneState(value || '');

		if (onValueChange) {
			onValueChange({
				phoneCountryCode: country.dialCode,
				phoneNumber: value?.replace(country.dialCode, ''),
			});
		}
	};

	return (
		<div
			className={classNames(
				inputStyles['form-item'],
				styles.wrap,
				isLabelUp && inputStyles['up-label'],
				error && inputStyles['error__wrap'],
				className,
			)}
			style={inlineStyles}
		>
			<Label
				htmlFor={id}
				className={classNames(inputStyles.label, styles.label)}
				{...{
					...labelSettings,
				}}
			>
				{label}
			</Label>

			<PhoneInput
				{...{ value, ...rest }}
				country={'us'}
				placeholder=""
				onFocus={onInputFocusHandler}
				onBlur={onInputBlurHandler}
				buttonClass={styles.button}
				inputProps={{
					onPaste: (event: any) => {
						if (
							(countryCodeState &&
								event.clipboardData.getData('text').indexOf(countryCodeState) ===
									0) ||
							event.clipboardData.getData('text').replace(/\+/g, '').indexOf('1') ===
								0
						) {
							setPhoneState(
								event.clipboardData
									.getData('text')
									.trim()
									.replace(/-/g, '')
									.replace(/\+/g, ''),
							);

							event.preventDefault();
						}
					},
				}}
				inputClass={styles.input}
				onChange={onPhoneInputChange}
			/>

			<input
				{...phoneCountryCodeInputSettings}
				hidden
				ref={phoneCountryCodeRef}
				value={countryCodeState}
				tabIndex={-1}
				onChange={() => {}}
			/>

			<input
				{...phoneNumberInputSettings}
				hidden
				ref={phoneNumberRef}
				tabIndex={-1}
				value={
					(countryCodeState && phoneState?.indexOf('1') === 0) ||
					(countryCodeState && phoneState?.indexOf(countryCodeState) === 0)
						? phoneState?.replace(countryCodeState, '')
						: !countryCodeState && phoneState?.indexOf('1') === 0
						? phoneState.replace('1', '')
						: phoneState
				}
				onChange={() => {}}
			/>

			{error && <Error className={inputStyles.error}>{error}</Error>}
		</div>
	);
};

export default PhoneFieldWithCountryCode;
