import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';

import {
	requireValidation,
	spacesValidation,
	transformNumericFieldsOfPayload,
} from '../../../../helpers/forms/forms';
import {
	changeUserInfo,
	ProfileType,
	UserInfoInterface,
} from '../../../../store/slices/user.slice';
import EditableViewForm from '../../editable-view-form';
import InputWithFloatLabel from '../../form-elemets/input-with-float-label/InputWithFloatLabel';
import EditableAccountDetailsViewFormInterface from './editable-account-details-view-form.interface';
import PhoneFieldWithCountryCode from '../../phone-field-with-country-code';
import { RootStateType } from '../../../../store/reducers/root-reducer';
import { ExternalErrorType } from '../../../../helpers/axios/axios-global';
import useExternalFormErrors from '../../../../helpers/hooks/use-external-form-errors';
import { setCompaniesErrors } from '../../../../store/slices/companies.slice';

const EditableAccountDetailsViewForm: FC<EditableAccountDetailsViewFormInterface> = ({
	userInfo,
}) => {
	const dispatch = useDispatch();
	const { register, errors, handleSubmit, clearErrors } = useForm();
	const {
		user: { editLoading },
	} = useSelector((state: RootStateType) => state);
	const [editModeState, setEditModeState] = useState(false);
	const [externalErrors, setExternalErrors] = useState<Partial<ExternalErrorType>>();
	const normalizedExternalErrors = useExternalFormErrors(externalErrors);
	const [cancelState, setCancelState] = useState(true);
	const [values, setValues] = useState<Partial<ProfileType>>({
		firstName: '',
		lastName: '',
		phoneCountryCode: '',
		phoneNumber: '',
		title: '',
		calendarLink: '',
	});

	useEffect(() => {
		if (userInfo) {
			setValues({
				firstName: userInfo.profile.firstName,
				lastName: userInfo.profile.lastName,
				phoneCountryCode: userInfo.profile.phoneCountryCode,
				phoneNumber: userInfo.profile.phoneNumber,
				title: userInfo.profile.title,
				calendarLink: userInfo.profile.calendarLink,
			});

			clearErrors();
			setExternalErrors(undefined);
			dispatch(setCompaniesErrors([]));
		}
		// eslint-disable-next-line
	}, [userInfo, cancelState, dispatch]);

	const onFormSubmitHandler = useCallback(
		(data: Partial<ProfileType>) => {
			setEditModeState(true);
			const normalizedData = transformNumericFieldsOfPayload<any>(data, ['phoneCountryCode']);

			if (normalizedData && Object.keys(normalizedData).length) {
				dispatch<any>(changeUserInfo(normalizedData))
					.then((data: ExternalErrorType | Partial<UserInfoInterface>) => {
						if ((data as ExternalErrorType)?.errors) {
							setExternalErrors(data as ExternalErrorType);
						} else if (data) {
							setValues((data as UserInfoInterface).profile);
							setEditModeState(false);
							setExternalErrors(undefined);
						}
					})
					.catch((error: ExternalErrorType) => {
						setExternalErrors(error);
					});
			}
		},
		[dispatch],
	);

	return (
		<EditableViewForm
			{...{ editModeState }}
			loading={editLoading}
			onSubmit={handleSubmit(onFormSubmitHandler)}
			afterModeChange={setCancelState}
			sectionTitle="Profile Details"
		>
			<InputWithFloatLabel
				className={classNames('form-item')}
				minWidth="calc(50% - 32px)"
				maxWidth="calc(50% - 32px)"
				id="first-name"
				ref={register(spacesValidation)}
				visibleDisableStyles={false}
				label="First name"
				error={
					errors?.firstName?.message ||
					(normalizedExternalErrors?.errors?.firstName &&
						'First name should not be empty')
				}
				inputSettings={{
					name: 'firstName',
					value: values.firstName,
					onChange: (event) => {
						setValues((prevState) => ({
							...prevState,
							firstName: event.target.value,
						}));
					},
				}}
			/>

			<InputWithFloatLabel
				className={classNames('form-item')}
				minWidth="calc(50% - 32px)"
				maxWidth="calc(50% - 32px)"
				id="last-name"
				ref={register(spacesValidation)}
				visibleDisableStyles={false}
				label="Last name"
				error={
					errors?.lastName?.message ||
					(normalizedExternalErrors?.errors?.lastName && 'Last name should not be empty')
				}
				inputSettings={{
					name: 'lastName',
					value: cancelState ? userInfo?.profile?.lastName || '' : values.lastName,
					onChange: (event) => {
						setValues((prevState) => ({
							...prevState,
							lastName: event.target.value,
						}));
					},
				}}
			/>

			<PhoneFieldWithCountryCode
				phoneCountryCodeRef={register(requireValidation())}
				phoneNumberRef={register(requireValidation())}
				minWidth="calc(50% - 32px)"
				maxWidth="calc(50% - 32px)"
				className={classNames('form-item', 'wide')}
				value={`${values.phoneCountryCode}${values.phoneNumber}`}
				onValueChange={(data) => {
					if (data) {
						setValues((prevState) => {
							return {
								...prevState,
								...data,
							};
						});
					}
				}}
				phoneCountryCodeInputSettings={{
					name: 'phoneCountryCode',
					value: values.phoneCountryCode,
				}}
				phoneNumberInputSettings={{
					name: 'phoneNumber',
					value: values.phoneNumber,
				}}
				error={
					errors?.phoneCountryCode?.message ||
					errors?.phoneNumber?.message ||
					normalizedExternalErrors?.errors?.phoneCountryCode ||
					normalizedExternalErrors?.errors?.phoneNumber
				}
				id="phoneNumber"
				label="Phone number"
			/>

			<InputWithFloatLabel
				className={classNames('form-item')}
				minWidth="calc(50% - 32px)"
				maxWidth="calc(50% - 32px)"
				id="title"
				ref={register()}
				visibleDisableStyles={false}
				label="Title"
				error={normalizedExternalErrors?.errors?.title}
				inputSettings={{
					name: 'title',
					value: cancelState ? userInfo?.profile?.title || '' : values.title,
					onChange: (event) => {
						setValues((prevState) => ({
							...prevState,
							title: event.target.value,
						}));
					},
				}}
			/>

			<InputWithFloatLabel
				className={classNames('form-item')}
				minWidth="calc(50% - 32px)"
				maxWidth="calc(50% - 32px)"
				id="calendar-link"
				ref={(ref) => {
					register(ref, {
						...spacesValidation,
					});
				}}
				visibleDisableStyles={false}
				label="Calendar link"
				error={
					(errors?.calendarLink && errors?.calendarLink.message) ||
					normalizedExternalErrors?.errors?.calendarLink
				}
				inputSettings={{
					name: 'calendarLink',
					value: cancelState
						? userInfo?.profile?.calendarLink || ''
						: values.calendarLink,
					onChange: (event) => {
						setValues((prevState) => ({
							...prevState,
							calendarLink: event.target.value,
						}));
					},
				}}
			/>
		</EditableViewForm>
	);
};

export default React.memo(EditableAccountDetailsViewForm);
