import { useSelector } from 'react-redux';

import { CandidateSliceInterface } from '../../../store/slices/candidate.slice';

export default function useCandidate() {
	const {
		candidate: { info: candidateInfo, loading: candidateLoading },
	} = useSelector((store: { candidate: CandidateSliceInterface }) => store);

	return {
		candidateInfo,
		candidateLoading,
	};
}
