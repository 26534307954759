import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import TabsInterface from './tabs.interface';
import Tab from '../../../atoms/ui/tab/Tab';
import { useNavigate } from 'react-router-dom';

const Tabs: FC<TabsInterface> = ({
	tabs,
	tabClassName,
	activeTabClassName,
	defaultActiveTab,
	tabsWrapClassName,
	onTabChange,
	noUrlChange,
	...rest
}) => {
	const navigate = useNavigate();
	const [activeTab, setActiveTab] = useState(defaultActiveTab || '');

	const isActiveTab = useCallback((key: string) => key === activeTab, [activeTab]);

	useEffect(() => {
		if (defaultActiveTab && tabs?.filter(({ key }) => isActiveTab(key)).length) {
			setActiveTab(defaultActiveTab);
		} else if (defaultActiveTab) {
			setActiveTab(tabs[0].key);
		}
	}, [defaultActiveTab, isActiveTab, navigate, tabs]);

	const onTabClick = useCallback(
		(key: string) => {
			setActiveTab(key);
			if (onTabChange) onTabChange(key);

			if (!noUrlChange) {
				navigate({
					search: `?tab=${key}`,
				});
			}
		},
		[onTabChange, noUrlChange, navigate],
	);

	const tabsElement = useMemo(
		() =>
			tabs.map(({ key, text }) => (
				<Tab
					key={key}
					isActive={isActiveTab(key)}
					onClick={() => onTabClick(key)}
					className={tabClassName}
					activeTabClassName={activeTabClassName}
				>
					{text}
				</Tab>
			)),
		[activeTabClassName, isActiveTab, onTabClick, tabClassName, tabs],
	);

	const [tabInfo] = useMemo(
		() => tabs?.filter(({ key }) => isActiveTab(key)),
		[tabs, isActiveTab],
	);

	return (
		<div {...rest}>
			<div className={tabsWrapClassName}>{tabsElement}</div>

			{tabInfo ? tabInfo.component : tabs[0].component}
		</div>
	);
};

export default Tabs;
