import { Dispatch } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import productsApi from '../../api/products.api';

export interface ProductInterface {
	id: number;
	name?: string;
	credits: string;
	price: string;
}

export interface ProductsListSliceInterface {
	items: ProductInterface[];
	loading: boolean;
}

const initialState: ProductsListSliceInterface = {
	items: [],
	loading: false,
};

export const productsListSlice = createSlice({
	name: 'products',
	initialState,
	reducers: {
		loading(state) {
			state.loading = true;
		},
		loaded(state) {
			state.loading = false;
		},
		getProductsListSuccess(state, { payload }: any) {
			state.items = payload;
			state.loading = false;
		},
		getProductsListFailed(state, action: PayloadAction<string>) {
			state.loading = false;
			console.error(action);
		},
	},
});

export const getProductsList = () => async (dispatch: Dispatch<any>) => {
	dispatch(loading());

	try {
		const response = await productsApi.getProductsList();

		if (!response.length) throw new Error('Empty results.');

		dispatch(getProductsListSuccess(response));
	} catch (err) {
		dispatch(getProductsListFailed(err.toString()));

		throw new Error(err.toString());
	}
};

export const { loading, loaded, getProductsListSuccess, getProductsListFailed } =
	productsListSlice.actions;
export default productsListSlice.reducer;
