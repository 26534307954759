import React, { FC, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import {
	getAuthorizationNeedTerms,
	sendValidationCode,
} from '../../../../../store/slices/authorization.slice';
import TroubleSignInEmailStepInterface from './trouble-sign-in-email-step.interface';
import troubleFormStyles from '../../login-trouble/LoginTrouble.module.scss';
import InputWithFloatLabel from '../../../form-elemets/input-with-float-label/InputWithFloatLabel';
import Button from '../../../../atoms/buttons/button/Button';
import SlideFormHeading from '../../../../molecules/custom/slide-form-heading';

import styles from './TroubleSignInEmailStep.module.scss';
import { AutoLoginActions, TokenTypes } from '../../../../../helpers/custom/common';
import { APP_URLS } from '../../../../../helpers/routes/routes';
import { useLocation } from 'react-router-dom';
import useAuthorization from '../../../../../helpers/hooks/use-authorization/useAuthorization';

const TroubleSignInEmailStep: FC<TroubleSignInEmailStepInterface> = ({
	sliderRef,
	closeModalWindow,
	activeSliderNumberState,
	title,
	description,
	setNextHandler,
}) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const { register, errors, handleSubmit } = useForm();

	const { error, loading } = useAuthorization();

	const onFormSubmitHandler = useCallback(
		async (data: { email: string }) => {
			await dispatch(getAuthorizationNeedTerms(data.email));

			const autologinData = {
				tokenType: TokenTypes.validationCode,
				action: AutoLoginActions.troubleSignin,
				destinationUrl: `${APP_URLS.login}${location.search}`,
			};

			await dispatch(sendValidationCode(data, autologinData));

			if (sliderRef && setNextHandler) {
				setNextHandler(undefined);
				// @ts-ignore
				sliderRef.current.slickNext();
			}
		},
		[dispatch, location.search, setNextHandler, sliderRef],
	);

	const formHandleSubmit = handleSubmit(onFormSubmitHandler);

	useEffect(() => {
		if (
			setNextHandler &&
			(activeSliderNumberState === undefined || activeSliderNumberState === 0)
		) {
			setNextHandler(() => formHandleSubmit);
		}
		// eslint-disable-next-line
	}, [activeSliderNumberState]);

	return (
		<form
			className={classNames(troubleFormStyles.step, styles.form)}
			onSubmit={formHandleSubmit}
		>
			<SlideFormHeading className={styles.heading} {...{ title, description }} />

			{register && (
				<InputWithFloatLabel
					className={troubleFormStyles.input}
					loading={loading}
					ref={register({
						required: {
							value: true,
							message: 'This field is required',
						},
						pattern: {
							value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
							message: 'Enter a valid e-mail address',
						},
					})}
					inputSettings={{
						type: 'email',
						name: 'email',
						autoFocus: true,
					}}
					error={(errors && errors.email && errors.email.message) || error}
					id="email"
					label="Email"
				/>
			)}

			<div className={troubleFormStyles.actions}>
				<Button
					type="button"
					small
					reversedStyles
					minWidth={100}
					maxWidth={100}
					onClick={closeModalWindow}
				>
					Cancel
				</Button>

				<Button type="submit" small minWidth={100} maxWidth={100}>
					Next
				</Button>
			</div>
		</form>
	);
};

export default TroubleSignInEmailStep;
