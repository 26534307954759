import React, { FC, useCallback } from 'react';
import classNames from 'classnames';

import LinkWithIconInterface from './link-with-icon.interface';

import styles from './LinkWithIcon.module.scss';

const LinkWithIcon: FC<LinkWithIconInterface> = ({
	icon,
	iconClassName,
	disabled,
	children,
	className,
	...rest
}) => {
	const preventClickForDisabledLink = useCallback(
		(event: React.SyntheticEvent<HTMLAnchorElement>) => {
			if (disabled) {
				event.preventDefault();
			}
		},
		[disabled],
	);

	return (
		<a
			className={classNames(styles.link, disabled && styles.disabled, className)}
			{...rest}
			onClick={preventClickForDisabledLink}
		>
			{icon && <i className={classNames(`icon-${icon}`, styles.icon, iconClassName)} />}

			{children}
		</a>
	);
};

export default LinkWithIcon;
