import classNames from 'classnames';
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../components/atoms/buttons/button/Button';
import TypographyText from '../../components/atoms/ui/typography-text';
import FormActions from '../../components/molecules/form/form-actions';
import Switcher from '../../components/molecules/form/switcher';
import Loader from '../../components/molecules/loader';
import CalendarLinkSettingPopup from '../../components/organisms/custom/calendar-link-setting-popup';
import { CheckedCandidatesInterface } from '../../components/organisms/custom/candidates/candidates.interface';
import SectionForSelectType from '../../components/organisms/custom/select-with-sections/section-for-select.type';
import SelectWithSections from '../../components/organisms/custom/select-with-sections/SelectWithSections';
import TextEditorWithTextInsert from '../../components/organisms/form-elemets/text-editor-with-html';

import NestedPopupHeader from '../../components/organisms/popup-headers/nested-popup-header/NestedPopupHeader';
import ShowMoreDropdown from '../../components/organisms/show-more-dropdown';
import Container from '../../components/templates/container';
import { CandidatesStatusValues } from '../../helpers/constants';
import { CompanyEmailTemplates } from '../../helpers/custom/common';
import {
	emailTagRegexPattern,
	generateEmailText,
	TemplateTags,
} from '../../helpers/custom/editors';
import useActiveCompany from '../../helpers/hooks/use-active-company/useActiveCompany';
import { RootStateType } from '../../store/reducers/root-reducer';
import {
	changeCandidateStatus,
	changeMultipleCandidatesStatuses,
	getRejectionReasons,
} from '../../store/slices/candidates.slice';
import {
	EmailTemplateType,
	getCommunicationTemplateById,
} from '../../store/slices/companies.slice';
import { getJobById, JobInterface } from '../../store/slices/job.slice';
import { CandidateRejectionInterface } from './candidate-rejection.interface';

import styles from './CandidateRejection.module.scss';
import ResizeDeviceContext from '../../contexts/resize-device-context';

const CandidateRejection: FC<CandidateRejectionInterface> = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const { register, handleSubmit, errors: formErrors } = useForm();

	const device = useContext(ResizeDeviceContext);

	const {
		companies: { activeCompanyId, items },
		user: { info: userInfo, loading: userEditLoading },
	} = useSelector((state: RootStateType) => state);
	const activeCompany = useActiveCompany(activeCompanyId, items);

	const [candidatesToReject, setCandidatesToReject] = useState<
		Partial<CheckedCandidatesInterface>[]
	>([]);
	const [rejectionSource, setRejectionSource] = useState('');
	const [jobInfo, setJobInfo] = useState<JobInterface>();
	const [rejectionReason, setRejectionReason] = useState<number | undefined>();
	const [rejectionTemplate, setRejectionTemplate] = useState<EmailTemplateType>();
	const [sendRejectionEmail, setSendRejectionEmail] = useState(
		activeCompany?.sendEmailOnRejection,
	);

	const [openCalendarLinkModal, setOpenCalendarLinkModal] = useState<boolean | undefined>();
	const [calendarLinkSuccess, setCalendarLinkSuccess] = useState(false);
	const [hasCalendarLink, setHasCalendarLink] = useState(!!userInfo?.profile?.calendarLink);

	const [rejectionReasonOptions, setRejectionReasonOptions] = useState<SectionForSelectType[]>(
		[],
	);

	const multipleCandidates = useMemo(() => candidatesToReject.length > 1, [candidatesToReject]);

	const emailTextData = useMemo(
		() => ({
			userFirstName: userInfo?.profile?.firstName,
			userLastName: userInfo?.profile?.lastName,
			jobTitle: jobInfo?.title,
			facility: jobInfo?.facility,
			multipleCandidates,
			...(!multipleCandidates &&
				candidatesToReject[0] && { candidateFirstName: candidatesToReject[0]?.firstName }),
			...(!multipleCandidates &&
				candidatesToReject[0] && { candidateLastName: candidatesToReject[0].lastName }),
			phoneNumber: userInfo?.profile?.phoneNumber
				? `+${userInfo?.profile?.phoneCountryCode}${userInfo?.profile?.phoneNumber}`
				: '',
			calendarLink: userInfo?.profile?.calendarLink,
		}),
		[candidatesToReject, jobInfo, multipleCandidates, userInfo],
	);

	const onSwitcherChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setSendRejectionEmail(event.currentTarget.checked);
	}, []);

	const onCalendarLinkSuccess = useCallback(() => {
		if (!userEditLoading) {
			setCalendarLinkSuccess(true);
			setOpenCalendarLinkModal(false);
		}
	}, [userEditLoading]);

	const rejectSubmitHandler = async (data: any) => {
		const subject = data.subject;
		const body = data.body;

		if (body?.includes(`[${TemplateTags.yourCalendarLink}]`) && !hasCalendarLink) {
			setOpenCalendarLinkModal(true);

			if (rejectionTemplate)
				//@ts-ignore
				setRejectionTemplate((prevState) => ({ ...prevState, body }));
		} else {
			if (multipleCandidates) {
				const payload = candidatesToReject.map(
					(candidate: Partial<CheckedCandidatesInterface>) => {
						const newSubjectText = subject
							?.replace(emailTagRegexPattern.candidateFirstName, candidate?.firstName)
							.replace(emailTagRegexPattern.candidateLastName, candidate?.lastName);

						const newBodyText = body
							?.replace(emailTagRegexPattern.candidateFirstName, candidate?.firstName)
							.replace(emailTagRegexPattern.candidateLastName, candidate?.lastName)
							.replace(
								emailTagRegexPattern.yourCalendarLink,
								userInfo?.profile?.calendarLink || '',
							);

						return {
							id: candidate.id,
							status: CandidatesStatusValues.rejected,
							rejectionReason,
							subject: sendRejectionEmail ? newSubjectText : undefined,
							body: sendRejectionEmail ? newBodyText : undefined,
						};
					},
				);

				if (jobInfo?.id) {
					await dispatch(
						changeMultipleCandidatesStatuses(
							jobInfo.id,
							//@ts-ignore
							{ applications: payload },
							//@ts-ignore
							location?.state?.settings,
						),
					);
				}
			} else {
				const newBodyText = body?.replace(
					emailTagRegexPattern.yourCalendarLink,
					userInfo?.profile?.calendarLink || '',
				);

				await dispatch(
					changeCandidateStatus(
						candidatesToReject[0].id as string,
						CandidatesStatusValues.rejected.toString(),
						rejectionReason,
						sendRejectionEmail ? subject : undefined,
						sendRejectionEmail ? newBodyText : undefined,
					),
				);
			}

			navigate(-1);
		}
	};

	const renderHeader = useMemo(() => {
		if (rejectionSource === 'list') {
			return (
				<NestedPopupHeader
					logo={{ type: 'white' }}
					title={multipleCandidates ? 'Reject candidates' : 'Reject candidate'}
					backgroundColor={'#525668'}
				/>
			);
		} else {
			return (
				<NestedPopupHeader
					logo={{ type: 'white' }}
					onBackClick={() => navigate(-1)}
					title={'Reject candidate'}
					backgroundColor={'#525668'}
				/>
			);
		}
	}, [multipleCandidates, navigate, rejectionSource]);

	const renderNamesSection = useMemo(() => {
		if (multipleCandidates) {
			return (
				<div className={styles['candidates']}>
					<ShowMoreDropdown
						trigger={
							<>
								<span className={styles.candidate}>
									{candidatesToReject[0]?.firstName}{' '}
									{candidatesToReject[0]?.lastName} and{' '}
									{candidatesToReject.length - 1} others
								</span>
							</>
						}
						dropdownContent={
							<div className={styles['others-names']}>
								{candidatesToReject.map((candidate: any) => {
									return (
										<div key={candidate.id} className={styles.name}>
											{candidate?.firstName} {candidate?.lastName}
										</div>
									);
								})}
							</div>
						}
					/>
				</div>
			);
		} else {
			return (
				<div className={styles['candidate']}>
					{candidatesToReject[0]?.firstName} {candidatesToReject[0]?.lastName}
				</div>
			);
		}
	}, [candidatesToReject, multipleCandidates]);

	const renderSubjectText = useCallback(() => {
		const subjectText = rejectionTemplate?.subject;
		if (subjectText) return generateEmailText(subjectText, emailTextData, false);
	}, [emailTextData, rejectionTemplate]);

	const renderBodyText = useCallback(() => {
		const bodyText = rejectionTemplate?.body;
		if (bodyText) return generateEmailText(bodyText, emailTextData);
	}, [emailTextData, rejectionTemplate]);

	const renderRejectionEmailEditor = useMemo(() => {
		return (
			<>
				<TypographyText className={styles.description}>
					This template is defined in your account settings.
				</TypographyText>

				<div className={styles['email-editor']}>
					<div className={styles['subject-editor']}>
						<div className={styles.label}>Subject</div>

						<TextEditorWithTextInsert
							toolbarHidden
							displayAsInput
							valueAsPlainText
							inputWidth={'100%'}
							defaultValue={renderSubjectText()}
							registerFormItem={register}
							formItemName="subject"
							isRequired
							formErrors={formErrors?.subject}
						/>
					</div>

					<div className={styles['body-editor']}>
						<TextEditorWithTextInsert
							updateDefaultValue={calendarLinkSuccess}
							defaultValue={renderBodyText()}
							registerFormItem={register}
							formItemName="body"
							isRequired
							formErrors={formErrors?.body}
						/>
					</div>
				</div>
			</>
		);
	}, [calendarLinkSuccess, formErrors, register, renderBodyText, renderSubjectText]);

	const renderTemplateSection = useMemo(() => {
		return (
			<>
				<div className={styles.switcher}>
					<Switcher
						id="sendEmail"
						label={`Send the following email to the rejected candidate${
							multipleCandidates ? 's' : ''
						}`}
						inputSettings={{
							id: 'sendEmail',
							checked: sendRejectionEmail,
							onChange: onSwitcherChange,
						}}
					/>
				</div>

				{sendRejectionEmail ? renderRejectionEmailEditor : null}
			</>
		);
	}, [multipleCandidates, onSwitcherChange, renderRejectionEmailEditor, sendRejectionEmail]);

	useEffect(() => {
		if (calendarLinkSuccess && !openCalendarLinkModal) setHasCalendarLink(true);
	}, [calendarLinkSuccess, openCalendarLinkModal]);

	useEffect(() => {
		(async () => {
			if (location?.state) {
				//@ts-ignore
				const { candidates, source, jobId } = location?.state;

				setRejectionSource(source);

				if (jobId) {
					const job = await dispatch(getJobById(jobId));
					//@ts-ignore
					setJobInfo(job);
				}

				if (candidates?.length) setCandidatesToReject(candidates);

				const result = await dispatch(getRejectionReasons());
				//@ts-ignore
				const reasons = result?.reasons;
				if (reasons) {
					const reasonsOptions = [];

					for (let i = 0; i < reasons.length; i++) {
						reasonsOptions.push({
							section: reasons.slice(i, i + 5),
						});
						i += 4;
					}

					setRejectionReasonOptions(reasonsOptions);
				}
			} else navigate(-1);
		})();
	}, [dispatch, location?.state, navigate]);

	useEffect(() => {
		(async () => {
			if (activeCompanyId) {
				const template = await dispatch(
					getCommunicationTemplateById(CompanyEmailTemplates.rejection, activeCompanyId),
				);
				//@ts-ignore
				if (template) setRejectionTemplate(template);
			}
		})();
	}, [activeCompanyId, dispatch]);

	return (
		<section className={styles['candidate-rejection']}>
			{renderHeader}

			{candidatesToReject.length && rejectionReasonOptions.length ? (
				<>
					<Container
						maxWidth={device === 'mobile' ? '85%' : '552px'}
						className={styles.container}
					>
						{calendarLinkSuccess ? (
							<div className={styles['calendar-link-success']}>
								Your calendar link was saved to your profile.
							</div>
						) : null}

						<div
							className={classNames(
								styles.title,
								calendarLinkSuccess ? styles['with-top-section'] : '',
							)}
						>
							Are you sure you wish to reject{' '}
							{multipleCandidates ? 'these candidates' : 'this candidate'}?
						</div>

						<div className={styles['names-section']}>{renderNamesSection}</div>

						<div className={styles.reason}>
							<SelectWithSections
								id="reason"
								label="Reason of rejection"
								optionsBySections={rejectionReasonOptions}
								onOptionPickHandler={setRejectionReason}
							/>
						</div>

						{candidatesToReject.length && activeCompany?.sendEmailOnRejection
							? renderTemplateSection
							: null}

						<FormActions centeredContent className={styles.actions}>
							<Button
								minWidth={216}
								maxWidth={216}
								reversedStyles
								className={styles.btn}
								onClick={() => navigate(-1)}
							>
								Cancel
							</Button>

							<Button
								minWidth={216}
								maxWidth={216}
								onClick={handleSubmit(rejectSubmitHandler)}
							>
								{`Reject Candidate${multipleCandidates ? 's' : ''}`}
							</Button>
						</FormActions>
					</Container>

					{!hasCalendarLink ? (
						<CalendarLinkSettingPopup
							open={openCalendarLinkModal}
							openStateHandler={setOpenCalendarLinkModal}
							onSuccess={onCalendarLinkSuccess}
						/>
					) : null}
				</>
			) : (
				<Loader thin minHeight={20} minWidth={20} className={styles.loader} />
			)}
		</section>
	);
};

export default React.memo(CandidateRejection);
