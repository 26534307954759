import React, { FC } from 'react';
import classNames from 'classnames';

import TableBodyInterface from './table-body.interface';

import styles from './TableBody.module.scss';

const TableBody: FC<TableBodyInterface> = ({ className, children, ...rest }) => {
	return (
		<div className={classNames(styles['table-body'], className)} {...rest}>
			{children}
		</div>
	);
};

export default TableBody;
