import React, { FC } from 'react';
import classNames from 'classnames';

import DetailLayoutWithSidebarInterface from './detail-layout-with-sidebar.interface';

import styles from './DetailLayoutWithSidebar.module.scss';

const DetailLayoutWithSidebar: FC<DetailLayoutWithSidebarInterface> = ({
	dropdownContent: content,
	sidebar,
	className,
	...rest
}) => {
	return (
		<div className={classNames(styles['detail-layout'], className)} {...rest}>
			<main className={styles.content}>{content}</main>

			<aside className={styles.sidebar}>{sidebar}</aside>
		</div>
	);
};

export default DetailLayoutWithSidebar;
