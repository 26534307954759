import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import {
	clearFacilitiesList,
	deleteFacility,
	FacilitiesListSliceInterface,
	FacilityInterface,
	getFacilitiesList,
} from '../../../../store/slices/facilities.slice';
import { CompaniesSliceInterface } from '../../../../store/slices/companies.slice';
import FacilitiesInterface from './facilities.interface';
import FacilityScreen from '../facility-screen';
import ModalWindow from '../../modal-window/ModalWindow';
import Button from '../../../atoms/buttons/button/Button';
import Container from '../../../templates/container';
import TableHead from '../../../molecules/table/table-head/TableHead';
import TableCell from '../../../atoms/table/table-cell/TableCell';
import TableBody from '../../../molecules/table/table-body/TableBody';
import Loader from '../../../molecules/loader';
import Table from '../../table/Table';
import TableRow from '../../../molecules/table/table-row/TableRow';
import IconButton from '../../../atoms/buttons/icon-button/IconButton';
import TopBar from '../../top-bar/TopBar';
import Title from '../../../atoms/title/Title';
import useActiveCompany from '../../../../helpers/hooks/use-active-company/useActiveCompany';

import styles from './Facilities.module.scss';
import ResizeDeviceContext from '../../../../contexts/resize-device-context';
import TextEllipsis from '../../../molecules/ui/text-ellipsis';

const Facilities: FC<FacilitiesInterface> = ({ className }) => {
	const dispatch = useDispatch();
	const [errorState, setErrorState] = useState<string>();
	const [facilityFormScreenState, setIsFacilityFormScreenOpen] = useState<{
		open: boolean;
		facilityId?: string | number | null;
	}>({
		open: false,
	});
	const {
		facilities: { loading: facilitiesLoading, items: facilitiesItems },
		companies: { activeCompanyId, items },
	} = useSelector(
		(state: { facilities: FacilitiesListSliceInterface; companies: CompaniesSliceInterface }) =>
			state,
	);
	const activeCompany = useActiveCompany(activeCompanyId, items);
	const device = useContext(ResizeDeviceContext);

	const getChosenFacilityById = useMemo(() => {
		if (facilitiesItems?.length && facilityFormScreenState.facilityId) {
			return facilitiesItems.find((item) => item.id === facilityFormScreenState.facilityId);
		}
	}, [facilitiesItems, facilityFormScreenState.facilityId]);

	const deleteFacilityItem = useCallback(
		(id: string | number) => {
			if (activeCompany && activeCompany.id) {
				dispatch<any>(deleteFacility(id, activeCompany.id))
					.then(() => setErrorState(undefined))
					.catch((error: any) => setErrorState(error.message));
			}
		},
		[activeCompany, dispatch],
	);

	useEffect((): any => {
		if (activeCompany && activeCompany.id) {
			dispatch(getFacilitiesList(activeCompany.id));
		}

		return () => dispatch(clearFacilitiesList());
	}, [dispatch, activeCompany]);

	const rowsElement = useMemo(
		() =>
			facilitiesItems &&
			facilitiesItems.map(({ id, name, state, city }: FacilityInterface) => {
				return (
					<TableRow key={id}>
						<TableCell minWidth="49.5%">
							<TextEllipsis text={name || `${city}${state && `, ${state}`}`} />
						</TableCell>
						<TableCell minWidth="19%">
							<TextEllipsis text={city} />
						</TableCell>
						<TableCell width="19%">
							<TextEllipsis text={state} />
						</TableCell>
						<TableCell className={styles.actions}>
							<IconButton
								icon="edit"
								onClick={() =>
									setIsFacilityFormScreenOpen({ open: true, facilityId: id })
								}
							/>
							<IconButton icon="delete" onClick={() => deleteFacilityItem(id)} />
						</TableCell>
					</TableRow>
				);
			}),
		[deleteFacilityItem, facilitiesItems],
	);

	return (
		<Container
			maxWidth={device !== 'desktop' ? '95%' : '73.8%'}
			className={classNames(styles.wrap, className)}
		>
			<TopBar
				viewSelectFilter={null}
				searchFilter={null}
				rightTopBarContent={
					<Button
						small
						onClick={() => {
							setErrorState(undefined);
							setIsFacilityFormScreenOpen({ open: true });
						}}
					>
						Add facility
					</Button>
				}
				className={styles['top-bar']}
			/>

			<Table>
				<TableHead>
					<TableCell minWidth="49.5%" headCell>
						facility
					</TableCell>
					<TableCell minWidth="19%" headCell>
						city
					</TableCell>
					<TableCell width="19%" headCell>
						state
					</TableCell>
					<TableCell headCell>
						<span>actions</span>
					</TableCell>
				</TableHead>

				<TableBody className={styles['table-body']}>
					{facilitiesLoading ? <Loader className={styles.preloader} /> : rowsElement}
				</TableBody>
			</Table>

			<FacilityScreen
				open={facilityFormScreenState.open}
				close={() => setIsFacilityFormScreenOpen({ open: false, facilityId: null })}
				backHandler={() => setIsFacilityFormScreenOpen({ open: false, facilityId: null })}
				facilityItem={getChosenFacilityById}
			/>

			<ModalWindow
				contentClassName={styles['error-popup-content']}
				open={!!errorState}
				close={() => setErrorState(undefined)}
				opacityAnimation
			>
				<Title component="h3">{errorState}</Title>
			</ModalWindow>
		</Container>
	);
};

export default React.memo(Facilities);
