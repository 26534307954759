import React, { FC } from 'react';
import classNames from 'classnames';
import TooltipInterface from './tooltip.interface';
import styles from './Tooltip.module.scss';

const Tooltip: FC<TooltipInterface> = ({ text, className }) => {
	return (
		<div className={classNames(styles.tooltip, className)}>
			{text}
		</div>
	);
};

export default Tooltip;
