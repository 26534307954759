import classNames from 'classnames';
import React, { FC, useCallback } from 'react';
import Button from '../../../atoms/buttons/button/Button';
import FormActions from '../../../molecules/form/form-actions';
import ModalWindow from '../../modal-window';

import styles from './ConfirmationPopup.module.scss';
import ConfirmationPopupInterface from './confirmation-popup.interface';
import Loader from '../../../molecules/loader';

const ConfirmationPopup: FC<ConfirmationPopupInterface> = ({
	title = 'Are you sure?',
	titleClassName,
	description = 'Are you sure you want to close this window?',
	descriptionClassName,
	backButtonText = 'No',
	confirmButtonText = 'Yes',
	className,
	open,
	popupWidth,
	btnsReversedOrder,
	openStateHandler,
	nonScrollable,
	onConfirmAndClose,
	onlyConfirmButton,
	displayLoading,
	onClosePopup,
}) => {
	const closeOnly = useCallback(() => {
		openStateHandler(false);
	}, [openStateHandler]);

	const closeWithoutConfirmation = useCallback(() => {
		if (onClosePopup) onClosePopup();

		closeOnly();
	}, [closeOnly, onClosePopup]);

	return (
		<ModalWindow
			className={styles['modal-wrapper']}
			contentClassName={classNames(styles.popup)}
			contentPopupWidth={popupWidth}
			nonScrollable={nonScrollable}
			open={open}
			close={closeWithoutConfirmation}
			opacityAnimation
		>
			<div className={classNames(styles.wrapper, className)}>
				<div className={classNames(styles.title, titleClassName)}>{title}</div>
				<div className={classNames(styles.description, descriptionClassName)}>
					{description}
				</div>

				{displayLoading ? (
					<Loader thin maxWidth={30} maxHeight={30} className={styles.loader} />
				) : (
					<FormActions
						centeredContent
						className={classNames(
							styles['modal-actions'],
							btnsReversedOrder ? styles.reversed : '',
						)}
					>
						{!onlyConfirmButton ? (
							<Button
								type="submit"
								minWidth={300}
								maxWidth={300}
								reversedStyles={btnsReversedOrder}
								className={styles.btn}
								onClick={closeWithoutConfirmation}
							>
								{backButtonText}
							</Button>
						) : null}

						<Button
							type="button"
							minWidth={300}
							maxWidth={300}
							reversedStyles={!btnsReversedOrder}
							className={styles.btn}
							onClick={() => {
								closeOnly();
								onConfirmAndClose();
							}}
						>
							{confirmButtonText}
						</Button>
					</FormActions>
				)}
			</div>
		</ModalWindow>
	);
};

export default React.memo(ConfirmationPopup);
