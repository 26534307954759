import { Dispatch } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getCompanyStatistic } from './companies.slice';
import { JobInterface } from './job.slice';
import jobsListApi from '../../api/jobs.api';

export interface JobListRequestSettingsInterface {
	title?: string;
	facilityId?: number;
	jobsType?: string;
	orderBy?: string;
	pageSize?: number;
	pageNumber?: number;
}

export interface JobsListSliceInterface {
	items?: JobInterface[];
	loading: boolean;
	deleteJobLoadingIds: string[];
}

const initialState: JobsListSliceInterface = {
	loading: false,
	deleteJobLoadingIds: [],
};

export const jobsListSlice = createSlice({
	name: 'jobs',
	initialState,
	reducers: {
		loadingJobsList(state) {
			state.loading = true;
		},
		getJobsListSuccess(state, { payload }: PayloadAction<JobInterface[]>) {
			state.items = payload;
			state.loading = false;
		},
		clearJobsList(state) {
			state.items = [];
		},
		getJobsListFailed(state, action: PayloadAction<string>) {
			state.loading = false;
			console.log(action);
		},
		cloneFailed(state, action: PayloadAction<string>) {
			state.loading = false;
			console.log(action);
		},
		deleteFailed(state, action: PayloadAction<string>) {
			state.loading = false;
			console.log(action);
		},
		addDeletingJobLoadingIds(state, { payload }: PayloadAction<string>) {
			state.deleteJobLoadingIds = [...state.deleteJobLoadingIds, payload];
		},
		removeDeletingJobLoadingIds(state, { payload }: PayloadAction<string>) {
			state.deleteJobLoadingIds = [
				...state.deleteJobLoadingIds.filter((item) => item !== payload),
			];
		},
	},
});

export const getJobsList =
	(companyId: string, settings?: JobListRequestSettingsInterface, clearItemsList?: boolean) =>
	async (dispatch: Dispatch<any>, getState: () => any) => {
		dispatch(loadingJobsList());

		const jobsListHandler = async (clearItemsList?: boolean) => {
			try {
				const response = await jobsListApi.getJobsList(companyId, settings);

				if (clearItemsList) {
					dispatch(getJobsListSuccess(response));
				} else {
					dispatch(getJobsListSuccess([...getState().jobs.items, ...response]));
				}
			} catch (error) {
				dispatch(getJobsListFailed(error.toString()));
			}
		};

		if (clearItemsList) {
			try {
				await dispatch(clearJobsList());

				jobsListHandler(clearItemsList);
			} catch (error) {
				console.error(error);
			}
		} else {
			jobsListHandler();
		}
	};

export const getJobsOnly =
	(companyId: string, pageSize?: number, pageNumber?: number) => async () => {
		try {
			const response = await jobsListApi.getJobsList(companyId, { pageSize, pageNumber });

			return response;
		} catch (error) {
			return error;
		}
	};

export const cloneJobItem =
	(companyId: string, jobId: string, settings?: JobListRequestSettingsInterface) =>
	async (dispatch: Dispatch<any>) => {
		dispatch(loadingJobsList());

		try {
			await jobsListApi.cloneJobItem(jobId);
			const response = await jobsListApi.getJobsList(companyId, settings);

			dispatch(getJobsListSuccess(response));
		} catch (error) {
			dispatch(cloneFailed(error.toString()));
		}
	};

export const deleteJobItem =
	(companyId: string, jobId: string, settings?: JobListRequestSettingsInterface) =>
	async (dispatch: Dispatch<any>, getState: () => any) => {
		dispatch(addDeletingJobLoadingIds(jobId));

		try {
			await jobsListApi.deleteJobItem(jobId);
			const response = await jobsListApi.getJobsList(companyId, settings);

			dispatch(getCompanyStatistic(getState().companies.activeCompanyId));
			dispatch(getJobsListSuccess(response));
		} catch (error) {
			dispatch(deleteFailed(error.toString()));

			throw error;
		} finally {
			dispatch(removeDeletingJobLoadingIds(jobId));
		}
	};

export const {
	getJobsListSuccess,
	getJobsListFailed,
	loadingJobsList,
	removeDeletingJobLoadingIds,
	addDeletingJobLoadingIds,
	cloneFailed,
	deleteFailed,
	clearJobsList,
} = jobsListSlice.actions;
export default jobsListSlice.reducer;
