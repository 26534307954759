import axios from 'axios';

import {
	COST_PER_APPLICANTS,
	JOBS,
	JOBS_VALIDATE,
	COMPANIES,
	JOB_DESCRIPTION,
	COMPUTE_POWER_PROFILE,
} from './api.path';
import {
	BoostJobDataInterface,
	JobAnalyticsDataInterface,
	JobInterface,
	MarketCPAInterface,
	PaymentLinkData,
	PaymentResultData,
	PowerProfileComputeData,
} from '../store/slices/job.slice';
import { getUrlParamsStringFromObject } from '../helpers';
import { GeneratedJobDescriptionInterface } from '../helpers/custom/job';
import { JobTemplateInterface } from '../store/slices/jobs-templates.slice';

const jobApi = {
	getJob: (jobId: string | number) => {
		const url = `${JOBS}/${jobId}`;

		return axios.get(url);
	},
	getJobStatistic: (jobId: string | number) => {
		const url = `${JOBS}/${jobId}/statistics`;

		return axios.get(url);
	},
	createJob: (data: Partial<JobInterface>) => {
		const url = `${JOBS}`;

		return axios.post(url, data);
	},
	validateJobData: (data: Partial<JobInterface>) => {
		const url = `${JOBS_VALIDATE}`;

		return axios.post(url, data);
	},
	editJob: (urlId: string, data: Partial<JobInterface>) => {
		const url = `${JOBS}/${urlId}`;

		return axios.patch(url, data);
	},
	changeJobStatus: (id: string, data: { status: number }) => {
		const url = `${JOBS}/${id}/status`;

		return axios.put(url, data);
	},
	boostJob: (data: Partial<BoostJobDataInterface>) => {
		const { jobId, numberOfOpenings, creditsAmount } = data || {};
		const url = `${JOBS}/${jobId}/boost`;

		return axios.post(url, {
			numberOfOpenings: numberOfOpenings,
			credits: creditsAmount,
		});
	},
	getJobAnalytics: (jobId: string | number): Promise<JobAnalyticsDataInterface[]> => {
		const url = `${JOBS}/${jobId}/boost-analytics`;

		return axios.get(url);
	},
	getMarketCPA: (cpaParams: Partial<MarketCPAInterface>): Promise<MarketCPAInterface> => {
		const url = `${COST_PER_APPLICANTS}`;

		return axios.get(url + `?${getUrlParamsStringFromObject(cpaParams)}`);
	},
	getJobPaymentLink: (data: PaymentLinkData): Promise<string> => {
		const url = `${COMPANIES}/${data.companyId}/checkoutsession`;

		return axios.post(url, data);
	},
	confirmJobPayment: (data: PaymentResultData): Promise<any> => {
		return axios.post(`${COMPANIES}/${data.companyId}/paymentsuccess`, {
			sessionId: data.sessionId,
		});
	},
	createJobDescription: (
		data: GeneratedJobDescriptionInterface,
	): Promise<GeneratedJobDescriptionInterface> => {
		const url = `${JOB_DESCRIPTION}/generate`;

		return axios.post(url, data);
	},
	getJobDescriptionByUuid: (uuid: string): Promise<GeneratedJobDescriptionInterface> => {
		const url = `${JOB_DESCRIPTION}/${uuid}`;

		return axios.get(url);
	},
	getComputedPowerProfile: (
		data: PowerProfileComputeData,
	): Promise<Partial<JobTemplateInterface>> => {
		const url = `${COMPUTE_POWER_PROFILE}`;

		return axios.post(url, data);
	},
};

export default jobApi;
