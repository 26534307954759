export const getMinMaxWidthStyles = (minWidth?: number | string, maxWidth?: number | string) => ({
	minWidth: minWidth,
	maxWidth: maxWidth,
});

export const getMinMaxHeightStyles = (
	minHeight?: number | string,
	maxHeight?: number | string,
) => ({
	minHeight: minHeight,
	maxHeight: maxHeight,
});

export const getStartEndColors = (score: string) => {
	const startColor = score === 'high' ? '#37DBE0' : score === 'low' ? '#FFBDB3' : '#FFD893';
	const endColor = score === 'high' ? '#4986A1' : score === 'low' ? '#ED7370' : '#EEBB62';

	return { startColor, endColor };
};
