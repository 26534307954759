import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
	AppProducts,
	BrandingNames,
	BrandingSettings,
	QueryParametersKey,
	SignUpOnDemandSteps,
	StorageItemKeys,
} from '../../helpers/constants';
import UserInfoStep from './user-info-step/UserInfoStep';
import ValidateEmailStep from './validate-email-step/ValidateEmailStep';
import CompanyDetailsStep from './company-details-step/CompanyDetailsStep';
import NestedPopupHeader from '../../components/organisms/popup-headers/nested-popup-header/NestedPopupHeader';
import { AutoLoginActions } from '../../helpers/custom/common';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../components/molecules/loader';
import { useDispatch } from 'react-redux';
import {
	UserAndCodeInfoInterface,
	changeUserInfo,
	getValidationCodeFromLink,
	logoutUser,
} from '../../store/slices/user.slice';
import {
	sendValidationCode,
	setAuthorizationInfoSuccess,
} from '../../store/slices/authorization.slice';
import { ExternalErrorType } from '../../helpers/axios/axios-global';
import useExternalFormErrors from '../../helpers/hooks/use-external-form-errors/useExternalFormErrors';
import { APP_URLS } from '../../helpers/routes/routes';
import useUser from '../../helpers/hooks/use-user/useUser';
import useCompanies from '../../helpers/hooks/use-companies/useCompanies';
import Error from '../../components/molecules/ui/error';
import { getTargetMessageByProperty } from '../../helpers/forms/forms';
import UtmParamsContext from '../../contexts/utm-params-context';
import useAuthorization from '../../helpers/hooks/use-authorization/useAuthorization';
import SparkeSpeech from '../../components/molecules/custom/sparke-speech/SparkeSpeech';
import styles from './SignUpOnDemand.module.scss';

const SignUpOnDemand: FC = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const [activeStep, setActiveStep] = useState(SignUpOnDemandSteps.getStarted);
	const [brandingConfig, setBrandingConfig] = useState(BrandingSettings.cinc);
	const [brandingName, setBrandingName] = useState(BrandingNames.cinc);

	const [externalErrors, setExternalErrors] = useState<Partial<ExternalErrorType> | null>();

	const isFromWidget = useMemo(
		() => location.search.includes(QueryParametersKey.fromWidget),
		[location.search],
	);

	const normalizedExternalErrors = useExternalFormErrors(externalErrors);
	const { authorized, info, loading: authLoading } = useUser();
	const { info: authInfo } = useAuthorization();
	const { companies } = useCompanies();

	const {
		state: { utmSource: originTag },
	} = useContext(UtmParamsContext);

	const [autoLoginDataLoading, setAutoLoginDataLoading] = useState(
		location.search.includes(QueryParametersKey.autologin),
	);

	const [loadingState, setLoadingState] = useState(isFromWidget);

	const onStepChange = useCallback((step: number) => setActiveStep(step), []);

	const onNextHandler = useCallback(() => {
		setExternalErrors(null);
		onStepChange(activeStep + 1);
	}, [activeStep, onStepChange]);

	const flowSteps = useMemo(
		() => [
			{
				number: SignUpOnDemandSteps.getStarted,
				name: 'Get Started',
				component: <UserInfoStep {...{ brandingName, isFromWidget, onNextHandler }} />,
			},
			{
				number: SignUpOnDemandSteps.validateEmail,
				name: 'Validate Email',
				component: <ValidateEmailStep {...{ brandingName }} />,
			},
			{
				number: SignUpOnDemandSteps.companyDetails,
				name: 'Company Details',
				component: <CompanyDetailsStep {...{ brandingName, isFromWidget }} />,
			},
		],
		[brandingName, isFromWidget, onNextHandler],
	);

	const renderHeader = useMemo(() => {
		return (
			<NestedPopupHeader
				titleLogo={brandingConfig.logo}
				backgroundColor={brandingConfig.color}
				withoutLogo
				withoutClose
			/>
		);
	}, [brandingConfig.color, brandingConfig.logo]);

	const renderStep = useMemo(() => {
		const currentStep = flowSteps.find((step) => step.number === activeStep);

		setLoadingState(false);
		return currentStep?.component;
	}, [activeStep, flowSteps]);

	const speechBubbleText = useMemo(() => {
		switch (activeStep) {
			case SignUpOnDemandSteps.getStarted:
				return 'Ok! Just fill in these few fields so we can start finding great candidates for this job.';
			case SignUpOnDemandSteps.validateEmail:
				if (location.search.includes(QueryParametersKey.autologin))
					return `Let's validate your email.`;
				return `Let's validate your email. I sent you a verification code - check your inbox`;
			case SignUpOnDemandSteps.companyDetails:
				return 'Give me a little more context so I can create the best company profile for you.';
			default:
				return '';
		}
	}, [activeStep, location.search]);

	useEffect(() => {
		const urlParams = new URLSearchParams(location.search);
		const brandingName = urlParams.get(QueryParametersKey.branding);

		if (brandingName) {
			const configs = BrandingSettings[brandingName as keyof typeof BrandingSettings];

			if (configs) {
				setBrandingConfig(configs);
				setBrandingName(BrandingNames[brandingName as keyof typeof BrandingNames]);
			}
		}
	}, [location.search]);

	useEffect(() => {
		if (authorized && info && info.email && companies) {
			setLoadingState(true);

			if (info.profile) {
				const firstName =
					authInfo?.firstName || localStorage.getItem(StorageItemKeys.firstName);
				const lastName =
					authInfo?.lastName || localStorage.getItem(StorageItemKeys.lastName);

				if (
					(firstName && firstName !== info.profile?.firstName) ||
					(lastName && lastName !== info.profile?.lastName)
				) {
					dispatch(
						changeUserInfo({
							firstName: firstName || '',
							lastName: lastName || '',
						}),
					);
				}

				localStorage.removeItem(StorageItemKeys.firstName);
				localStorage.removeItem(StorageItemKeys.lastName);
			}

			if (!companies.length) {
				if (info.needEmailValidation) {
					const autologinData = {
						action: AutoLoginActions.signupValidation,
						destinationUrl: `${APP_URLS.signup}${location.search}`,
					};

					dispatch(
						sendValidationCode(
							{ email: info.email },
							{
								...autologinData,
								product: AppProducts.postJob,
								...(originTag && { originTag }),
							},
						),
					);

					setActiveStep(SignUpOnDemandSteps.validateEmail);
					dispatch(logoutUser());
					return;
				}

				setActiveStep(SignUpOnDemandSteps.companyDetails);
			} else {
				if (localStorage.getItem(StorageItemKeys.newInPostAJob) || isFromWidget) {
					let createJobUrl = '';

					if (isFromWidget) {
						createJobUrl = `${APP_URLS.createJob}${location.search}`;
					} else {
						createJobUrl = `${APP_URLS.createJob}${location.search}${
							location.search ? '&' : '?'
						}${QueryParametersKey.postAJob}=yes`;
					}

					localStorage.removeItem(StorageItemKeys.newInPostAJob);
					navigate(createJobUrl);
				} else {
					navigate(APP_URLS.jobs);
				}
			}

			setLoadingState(false);
		}
	}, [
		authInfo?.firstName,
		authInfo?.lastName,
		authorized,
		companies,
		dispatch,
		info,
		isFromWidget,
		location.search,
		navigate,
		originTag,
	]);

	useEffect(() => {
		const urlParams = new URLSearchParams(location.search);
		const token = urlParams.get(QueryParametersKey.autologin);
		const action = urlParams.get(QueryParametersKey.action);

		if (token && action && action === AutoLoginActions.signupValidation) {
			(async () => {
				const result = await dispatch(getValidationCodeFromLink(token));

				//@ts-ignore
				if (result && !result.errors && result.user) {
					const { user, validationCode } = result as unknown as UserAndCodeInfoInterface;

					if (user?.email && validationCode) {
						await dispatch(
							setAuthorizationInfoSuccess({ ...user, code: Number(validationCode) }),
						);

						setActiveStep(SignUpOnDemandSteps.validateEmail);

						setLoadingState(false);
						setAutoLoginDataLoading(false);
					}
				} else {
					//@ts-ignore
					const tokenError = getTargetMessageByProperty('tokenId', result?.errors);

					navigate(
						`${APP_URLS.expiredAutologin}${
							tokenError ? `?${QueryParametersKey.invalid}` : ''
						}`,
					);
				}
			})();
		} else {
			setAutoLoginDataLoading(false);
		}
	}, [brandingName, dispatch, location.search, navigate]);

	if (autoLoginDataLoading || authLoading) return <Loader wide />;

	return (
		<div className={styles.signup}>
			{loadingState ? <Loader wide /> : null}

			{renderHeader}

			{normalizedExternalErrors?.errors ? (
				<Error style={{ marginTop: 32 }}>
					{normalizedExternalErrors?.globalMessage
						? normalizedExternalErrors?.globalMessage
						: 'Something went wrong. Please try again.'}
				</Error>
			) : null}

			{isFromWidget ? (
				<SparkeSpeech
					small
					withInfoIcon
					className={styles.sparke}
					content={speechBubbleText}
					maxWidth="270px"
				/>
			) : null}

			{renderStep}
		</div>
	);
};

export default SignUpOnDemand;
