import { useSelector } from 'react-redux';
import { CandidatesListSliceInterface } from '../../../store/slices/candidates.slice';

export default function useCandidates() {
	const {
		candidates: {
			hiringWithWurkCandidateId,
			items: candidatesItems,
			loading,
			count: candidateItemsCount,
		},
	} = useSelector((store: { candidates: CandidatesListSliceInterface }) => store);

	return {
		hiringWithWurkCandidateId,
		candidatesItems,
		loading,
		candidateItemsCount,
	};
}
