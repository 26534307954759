import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import IconLinkButtonInterface from './icon-link-button.interface';
import Icon from '../../icons/icon';

import styles from './IconLinkButton.module.scss';

const IconLinkButton: FC<IconLinkButtonInterface> = ({
	icon,
	iconSettings,
	imageIcon,
	imageIconClassName,
	className,
	...rest
}) => {
	return (
		<Link className={classNames(styles.wrap, className)} {...rest}>
			{imageIcon ? (
				<img
					src={imageIcon}
					className={classNames(styles['custom-icon'], imageIconClassName)}
				/>
			) : (
				<Icon icon={icon!} {...iconSettings} />
			)}
		</Link>
	);
};

export default IconLinkButton;
