import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useController, useForm } from 'react-hook-form';

import styles from './CompanyDetailsStep.module.scss';
import InputWithFloatLabel from '../../../components/organisms/form-elemets/input-with-float-label/InputWithFloatLabel';
import {
	requireValidation,
	spacesValidation,
	transformNumericFieldsOfPayload,
} from '../../../helpers/forms/forms';
import Button from '../../../components/atoms/buttons/button/Button';
import CompanyDetailsStepInterface from './company-details-step.interface';
import Title from '../../../components/atoms/title/Title';
import TypographyText from '../../../components/atoms/ui/typography-text';
import SelectWithFloatLabel from '../../../components/organisms/form-elemets/select-with-float-label/SelectWithFloatLabel';
import {
	hasATSOptions,
	hiringNeedsOptions,
	referralOptions,
	titleOptions,
} from '../../../helpers/custom/data-options/options';
import { useDispatch } from 'react-redux';
import { ExternalErrorType } from '../../../helpers/axios/axios-global';
import useExternalFormErrors from '../../../helpers/hooks/use-external-form-errors/useExternalFormErrors';
import UtmParamsContext from '../../../contexts/utm-params-context';
import { CreateCompanyInterface, createCompany } from '../../../store/slices/companies.slice';
import Loader from '../../../components/molecules/loader';
import Error from '../../../components/molecules/ui/error';
import { getUserInfo } from '../../../store/slices/user.slice';
import {
	AppProducts,
	BEEventTypes,
	QueryParametersKey,
	StorageItemKeys,
} from '../../../helpers/constants';
import { dispatchEventToBackend } from '../../../api/events.api';
import useUser from '../../../helpers/hooks/use-user/useUser';
import { companyCreatedGA4Event } from '../../../helpers/google-analytics-4/events';
import {
	GAContentGroup,
	GAContentId,
	GAContentType,
} from '../../../helpers/google-analytics-4/index.constants';
import { transformTextToRegularCaseStyle } from '../../../helpers/custom/common';
import { GeneratedJobDescriptionInterface } from '../../../helpers/custom/job';
import { getJobDescriptionByUuid } from '../../../store/slices/job.slice';
import { useLocation } from 'react-router-dom';

const CompanyDetailsStep: FC<CompanyDetailsStepInterface> = ({ brandingName, isFromWidget }) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const { register, errors, handleSubmit, control } = useForm();
	const fieldCommonProps = useMemo(() => ({ rules: requireValidation(), control }), [control]);
	const { field: userTitleProps } = useController({ name: 'userTitle', ...fieldCommonProps });
	const { field: hiringNeedsProps } = useController({ name: 'hiringNeeds', ...fieldCommonProps });
	const { field: hasATSProps } = useController({ name: 'hasATS', ...fieldCommonProps });
	const { field: referralProps } = useController({ name: 'referral', ...fieldCommonProps });

	const { info } = useUser();

	const [loadingState, setLoadingState] = useState(false);
	const [externalErrors, setExternalErrors] = useState<Partial<ExternalErrorType>>();
	const [prefilledCompanyData, setPrefilledCompanyData] =
		useState<Partial<CreateCompanyInterface>>();

	const normalizedExternalErrors = useExternalFormErrors(externalErrors);

	const {
		state: { utmSource: originTag },
	} = useContext(UtmParamsContext);

	const renderTopSection = useMemo(() => {
		return (
			<>
				<Title
					component="h2"
					marginBottom={24}
					fontSize={48}
					fontWeight={300}
					className={styles.title}
				>
					Add company details
				</Title>

				<TypographyText className={styles.desc}>
					Enter the info below to create a public profile.
				</TypographyText>
			</>
		);
	}, []);

	const getCompanySizeFromWidget = useCallback(() => {
		if (prefilledCompanyData?.size) return prefilledCompanyData?.size;
	}, [prefilledCompanyData?.size]);

	const onFormSubmitHandler = useCallback(
		async (data: CreateCompanyInterface) => {
			setLoadingState(true);

			const transformedData = transformNumericFieldsOfPayload<
				Partial<CreateCompanyInterface>
			>(data, ['hiringNeeds', 'hasATS', 'referral']);

			transformedData.allowsCannabis = true;
			const companySize = getCompanySizeFromWidget();

			if (companySize) transformedData.size = getCompanySizeFromWidget();

			const response = await dispatch(
				createCompany(transformedData as CreateCompanyInterface),
			);

			//@ts-ignore
			if (response && !response?.errors?.length && !response.message) {
				setLoadingState(false);

				companyCreatedGA4Event(
					{
						companyData: {
							//@ts-ignore
							companyId: response.id,
							companyName: response.name,
							//@ts-ignore
							companyType: response.plan?.uniqueName,
						},
						contentData: {
							id: GAContentId.postAJob,
							group: GAContentGroup.postAJob,
							type: GAContentType.companyInfo,
						},
					},
					brandingName,
				);

				await dispatch(getUserInfo());
			} else {
				if (!response) {
					setExternalErrors({
						message: 'Validation error',
					});
				} else {
					//@ts-ignore
					setExternalErrors(response);
				}
			}

			setLoadingState(false);
		},
		[brandingName, dispatch, getCompanySizeFromWidget],
	);

	const renderErrorMessage = useMemo(() => {
		return (
			<Error className={styles.error}>
				{normalizedExternalErrors?.globalMessage
					? `${transformTextToRegularCaseStyle(
							normalizedExternalErrors?.globalMessage,
					  )}. Please check your responses and try again.`
					: 'There are errors or information missing. Please check your responses and try again.'}
			</Error>
		);
	}, [normalizedExternalErrors?.globalMessage]);

	const renderFrom = useMemo(() => {
		return (
			<form className={styles.form} onSubmit={handleSubmit(onFormSubmitHandler)}>
				<section className={styles.inputs}>
					<div className={styles.group}>
						{' '}
						<SelectWithFloatLabel
							className={styles.input}
							minWidth="100%"
							error={
								errors?.userTitle?.message ||
								normalizedExternalErrors?.errors?.userTitle
							}
							selectSettings={{
								options: titleOptions,
								name: 'userTitle',
							}}
							id="userTitle"
							label="Your title"
							{...userTitleProps}
						/>
						<InputWithFloatLabel
							className={styles.input}
							minWidth="100%"
							ref={(ref) =>
								register(ref, {
									...requireValidation(),
									...spacesValidation,
								})
							}
							inputSettings={{
								type: 'text',
								name: 'name',
								defaultValue: prefilledCompanyData?.name,
							}}
							error={errors?.name?.message || normalizedExternalErrors?.errors?.name}
							id="name"
							label="Company name"
						/>
						<InputWithFloatLabel
							className={styles.input}
							minWidth="100%"
							ref={(ref) =>
								register(ref, {
									...requireValidation(),
									...spacesValidation,
								})
							}
							inputSettings={{
								type: 'text',
								name: 'websiteUrl',
								defaultValue: prefilledCompanyData?.websiteUrl,
							}}
							error={
								errors?.websiteUrl?.message ||
								normalizedExternalErrors?.errors?.websiteUrl ||
								(normalizedExternalErrors?.globalMessage &&
									normalizedExternalErrors?.globalMessage.includes('website'))
							}
							id="websiteUrl"
							label="Company website"
						/>
					</div>

					<div className={styles.group}>
						<SelectWithFloatLabel
							className={styles.input}
							minWidth="100%"
							error={
								errors?.hiringNeeds?.message ||
								normalizedExternalErrors?.errors?.hiringNeeds
							}
							selectSettings={{
								options: hiringNeedsOptions,
								name: 'hiringNeeds',
							}}
							id="hiringNeeds"
							label="Hiring needs"
							{...hiringNeedsProps}
						/>

						<SelectWithFloatLabel
							className={styles.input}
							minWidth="100%"
							error={
								errors?.hasATS?.message || normalizedExternalErrors?.errors?.hasATS
							}
							selectSettings={{
								options: hasATSOptions,
								name: 'hasATS',
							}}
							id="hasATS"
							label="Do you have an ATS?"
							{...hasATSProps}
						/>

						<SelectWithFloatLabel
							className={styles.input}
							minWidth="100%"
							error={
								errors?.referral?.message ||
								normalizedExternalErrors?.errors?.referral
							}
							selectSettings={{
								options: referralOptions,
								name: 'referral',
							}}
							id="referral"
							label="How did you hear about us?"
							{...referralProps}
						/>
					</div>
				</section>

				{normalizedExternalErrors?.globalMessage || normalizedExternalErrors?.errors?.length
					? renderErrorMessage
					: null}

				<Button
					type="submit"
					small
					minWidth={260}
					minHeight={56}
					className={styles.btn}
					disabled={loadingState}
				>
					{loadingState ? <Loader thin maxHeight={20} maxWidth={20} /> : 'Continue'}
				</Button>
			</form>
		);
	}, [
		errors?.hasATS?.message,
		errors?.hiringNeeds?.message,
		errors?.name?.message,
		errors?.referral?.message,
		errors?.userTitle?.message,
		errors?.websiteUrl?.message,
		handleSubmit,
		hasATSProps,
		hiringNeedsProps,
		loadingState,
		normalizedExternalErrors?.errors?.hasATS,
		normalizedExternalErrors?.errors?.hiringNeeds,
		normalizedExternalErrors?.errors?.length,
		normalizedExternalErrors?.errors?.name,
		normalizedExternalErrors?.errors?.referral,
		normalizedExternalErrors?.errors?.userTitle,
		normalizedExternalErrors?.errors?.websiteUrl,
		normalizedExternalErrors?.globalMessage,
		onFormSubmitHandler,
		prefilledCompanyData?.name,
		prefilledCompanyData?.websiteUrl,
		referralProps,
		register,
		renderErrorMessage,
		userTitleProps,
	]);

	useEffect(() => {
		(async () => {
			if (isFromWidget) {
				const jobDescriptionUuid = new URLSearchParams(location.search).get(
					QueryParametersKey.descriptionUuid,
				);

				if (jobDescriptionUuid) {
					const jobDescription = (await dispatch(
						getJobDescriptionByUuid(jobDescriptionUuid),
					)) as unknown as GeneratedJobDescriptionInterface;

					const { companyName, companyUrl, size } = jobDescription || {};

					setPrefilledCompanyData({
						name: companyName,
						websiteUrl: companyUrl,
						size,
					});
				}
			}
		})();
	}, [dispatch, isFromWidget, location.search]);

	useEffect(() => {
		if (info?.email) {
			dispatchEventToBackend({
				email: info?.email,
				eventType: BEEventTypes.customerRegistration,
				product: AppProducts.postJob,
				originTag: originTag || '',
			});
		}
	}, [info?.email, originTag]);

	useEffect(() => {
		localStorage.setItem(StorageItemKeys.newInPostAJob, 'yes');
	}, []);

	return (
		<div className={styles.company}>
			{renderTopSection}

			{renderFrom}
		</div>
	);
};

export default React.memo(CompanyDetailsStep);
