import React, { FC } from 'react';
import classNames from 'classnames';
import useCandidate from '../../../../helpers/hooks/use-candidate/useCandidate';
import CertificateBadges from '../../ui/certificate-badges';
import Tooltip from '../../../atoms/ui/tooltip';
import CandidateExperienceBriefInterface from './candidate-experience-brief.interface';
import styles from './CandidateExperienceBrief.module.scss';
import education from '../../../../assets/icons/education.svg';
import yearsOfExperience from '../../../../assets/icons/years-of-experiance.svg';

const CandidateExperienceBrief: FC<CandidateExperienceBriefInterface> = ({
	className,
	totalWorkExperience,
	highestDegreeOfEducation,
}) => {
	const { candidateInfo } = useCandidate();

	return (
		<div className={classNames(className, styles.wrapper)}>
			<div className={styles.experience}>
				{totalWorkExperience ? (
					<div className={classNames(styles.item, styles['item-with-tooltip'])}>
						<img
							src={yearsOfExperience}
							alt="Years of experience"
							className={styles.icon}
						/>
						{totalWorkExperience}

						<Tooltip className={styles['item-tooltip']} text={'Years of experience'} />
					</div>
				) : null}

				<div className={classNames(styles.item, styles['item-with-tooltip'])}>
					<img src={education} alt="Education" className={styles.icon} />

					{highestDegreeOfEducation}

					<Tooltip className={styles['item-tooltip']} text={'Education'} />
				</div>

				{candidateInfo?.badges?.length ? (
					<div className={classNames(styles.item, styles.certificates)}>
						<CertificateBadges
							className={styles['badges']}
							popoverClassName={styles['popover-position']}
							badges={candidateInfo.badges}
						/>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default CandidateExperienceBrief;
