import OptionInterface from '../../components/atoms/form/option/option.interface';
import { JobStatusType } from '../custom/common';

export const getEmploymentTypeLabel = (value: number) => {
	let employmentTypeLabel;

	switch (value) {
		case 0:
			employmentTypeLabel = 'Full-time';
			break;
		case 10:
			employmentTypeLabel = 'Part-time';
			break;
	}

	return employmentTypeLabel;
};

export const getJobStatusLabel = (value: number) => {
	let jobStatusLabel;

	switch (value) {
		case JobStatusType.inactive:
			jobStatusLabel = 'Inactive';
			break;
		case JobStatusType.active:
			jobStatusLabel = 'Active';
			break;
	}

	return jobStatusLabel;
};

export const getLabelAccordingToValue = (array: OptionInterface[], value: string | number) => {
	let result;

	array.forEach(({ value: optionValue, text }) => {
		if (optionValue === value) {
			result = text;
		}
	});

	if (result) {
		return result;
	}
};
