import React, { FC, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import { useSelector } from 'react-redux';

import {
	expectedCannabisIndustrySeniorityMarks,
	expectedSeniorityMarks,
} from '../../../../helpers/constants';
import { getObjectByValue } from '../../../../helpers/forms/forms';
import Title from '../../../atoms/title/Title';
import TypographyText from '../../../atoms/ui/typography-text';
import UnorderedList from '../../../molecules/ui/unordered-list/UnorderedList';
import UnorderedListItem from '../../../atoms/ui/unordered-list-item/UnorderedListItem';
import DetailLayoutWithSidebar from '../../../templates/detail-layout-with-sidebar';

import styles from './JobDetail.module.scss';
import { RootStateType } from '../../../../store/reducers/root-reducer';
import useActiveCompany from '../../../../helpers/hooks/use-active-company/useActiveCompany';
import { renderSalaryInfo, shouldBeDisplayed } from '../../../../helpers/custom/common';
import ExternalLink from '../../../atoms/navigation/external-link/ExternalLink';
import JobDetailInterface from './job-detail.interface';

const JobDetail: FC<JobDetailInterface> = ({ jobInfo, context }) => {
	const {
		job: { info },
		companies: { activeCompanyId, items },
	} = useSelector((state: RootStateType) => state);

	const activeCompany = useActiveCompany(activeCompanyId, items);

	const {
		summary,
		companyDescription,
		mustHaveSkills,
		niceToHaveSkills,
		benefits,
		minSalaryValue,
		maxSalaryValue,
		salaryUnit,
		eoe,
		requireResumeLabel,
		requireCoverLabel,
		workSeniority,
		cannabisSeniority,
		facility,
		hasExternalApply,
		jobUrl,
	} = jobInfo || info;

	const transformATSLink = useCallback(
		(jobUrl?: string) => jobUrl?.replace('<source>', 'engin'),
		[],
	);

	const companyDescriptionValue = useMemo(
		() =>
			companyDescription ||
			info?.facility?.company?.description ||
			activeCompany?.description,
		[activeCompany?.description, companyDescription, info?.facility?.company?.description],
	);

	const benefitsValue = useMemo(
		() => benefits || info?.facility?.company?.benefits || activeCompany?.benefits,
		[activeCompany?.benefits, benefits, info?.facility?.company?.benefits],
	);

	const eoeValue = useMemo(
		() => eoe || info?.facility?.company?.eoe || activeCompany?.eoe,
		[activeCompany?.eoe, eoe, info?.facility?.company?.eoe],
	);

	return (
		<div className={classNames(styles.detail, styles.container)}>
			<DetailLayoutWithSidebar
				dropdownContent={
					<>
						{hasExternalApply ? (
							<div className={styles['external-apply']}>
								This job is distributed by engin, but candidates apply directly on
								your ATS via this{' '}
								<ExternalLink href={transformATSLink(jobUrl)} target='_blank'>link</ExternalLink>.
							</div>
						) : null}

						{shouldBeDisplayed(hasExternalApply, summary) ? (
							<div className={styles.item}>
								<Title
									fontSize={20}
									fontWeight={600}
									marginBottom={10}
									component="h2"
								>
									Overview
								</Title>
								<TypographyText>{ReactHtmlParser(summary || '')}</TypographyText>

								{(minSalaryValue || maxSalaryValue) && salaryUnit && (
									<div className={classNames(styles.item, styles.salary)}>
										<TypographyText>
											<strong>Pay range: </strong>{' '}
											{renderSalaryInfo(
												salaryUnit,
												minSalaryValue,
												maxSalaryValue,
											)}
										</TypographyText>
									</div>
								)}
							</div>
						) : null}

						{shouldBeDisplayed(hasExternalApply, mustHaveSkills) ? (
							<div className={styles.item}>
								<Title
									fontSize={20}
									fontWeight={600}
									marginBottom={10}
									component="h2"
								>
									Required skills
								</Title>

								<TypographyText>
									{ReactHtmlParser(mustHaveSkills || '')}
								</TypographyText>
							</div>
						) : null}

						{shouldBeDisplayed(hasExternalApply, niceToHaveSkills) ? (
							<div className={styles.item}>
								<Title
									fontSize={20}
									fontWeight={600}
									marginBottom={10}
									component="h2"
								>
									Desired skills
								</Title>

								<TypographyText>
									{ReactHtmlParser(niceToHaveSkills || '')}
								</TypographyText>
							</div>
						) : null}

						{(workSeniority !== undefined || cannabisSeniority !== undefined) && (
							<div className={styles.item}>
								<Title
									fontSize={20}
									fontWeight={600}
									marginBottom={10}
									component="h2"
								>
									Preferred seniority
								</Title>

								<TypographyText>
									{workSeniority !== undefined && (
										<div className={styles['bottom-indent']}>
											{' '}
											<strong>Overall: </strong>{' '}
											{
												getObjectByValue(
													expectedSeniorityMarks,
													workSeniority,
												)?.label
											}{' '}
											{workSeniority !== 0 && 'or higher'}
										</div>
									)}

									{activeCompany?.allowsCannabis &&
										cannabisSeniority !== undefined && (
											<div className={styles['bottom-indent']}>
												<strong>Cannabis industry: </strong>{' '}
												{
													getObjectByValue(
														expectedCannabisIndustrySeniorityMarks,
														cannabisSeniority,
													)?.label
												}{' '}
												or higher
											</div>
										)}
								</TypographyText>
							</div>
						)}

						{context !== 'preview' ? (
							<div className={styles.item}>
								<Title
									fontSize={20}
									fontWeight={600}
									marginBottom={10}
									component="h2"
								>
									Required elements
								</Title>

								<UnorderedList>
									<UnorderedListItem>
										Resume: {requireResumeLabel && requireResumeLabel}
									</UnorderedListItem>
									<UnorderedListItem>
										Cover letter: {requireCoverLabel && requireCoverLabel}
									</UnorderedListItem>
								</UnorderedList>
							</div>
						) : null}
					</>
				}
				sidebar={
					<>
						{info?.facility?.company?.logoUrl || activeCompany?.logoUrl ? (
							<div className={styles.item}>
								<picture>
									<img
										className={styles['company__logo']}
										src={
											info.facility?.company?.logoUrl ||
											activeCompany?.logoUrl
										}
										alt={
											info.facility?.company?.name ||
											activeCompany?.name ||
											''
										}
									/>
								</picture>
							</div>
						) : null}

						{shouldBeDisplayed(
							hasExternalApply,
							companyDescription,
							info?.facility?.company?.description || activeCompany?.description,
						) &&
						(facility?.company?.name || activeCompany?.name) ? (
							<div className={styles.item}>
								<Title
									fontSize={20}
									fontWeight={600}
									marginBottom={10}
									component="h2"
								>
									About {facility?.company?.name || activeCompany?.name}
								</Title>
								<TypographyText>
									{ReactHtmlParser(companyDescriptionValue || '')}
								</TypographyText>
							</div>
						) : null}

						{shouldBeDisplayed(
							hasExternalApply,
							benefits,
							info?.facility?.company?.benefits || activeCompany?.benefits,
						) ? (
							<div className={styles.item}>
								<Title
									fontSize={20}
									fontWeight={600}
									marginBottom={10}
									component="h2"
								>
									Benefits
								</Title>

								<TypographyText>
									{ReactHtmlParser(benefitsValue || '')}
								</TypographyText>
							</div>
						) : null}

						{shouldBeDisplayed(
							hasExternalApply,
							eoe,
							info?.facility?.company?.eoe || activeCompany?.eoe,
						) ? (
							<div className={styles.item}>
								<Title
									fontSize={20}
									fontWeight={600}
									marginBottom={10}
									component="h2"
								>
									Equal opportunity employer
								</Title>

								<TypographyText>{ReactHtmlParser(eoeValue || '')}</TypographyText>
							</div>
						) : null}
					</>
				}
			/>
		</div>
	);
};

export default JobDetail;
