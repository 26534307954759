import { useSelector } from 'react-redux';

import { CompaniesSliceInterface } from '../../../store/slices/companies.slice';

export default function useCompanies() {
	const {
		companies: {
			loading,
			items: companies,
			activeCompanyId,
			activeCompanyStatistic,
			activeCompanyCanBoost,
			activeCompanyCanSeeAnalytics,
			activeCompanyCanSeeBilling,
			activeCompanyHasPaymentTransactions,
			activeCompanyBalance,
			activeCompanyPrepaidJobsBalance,
			creditsRefillLoading,
			balanceUpdateLoading,
			prepaidJobsBalanceUpdateLoading,
		},
	} = useSelector((store: { companies: CompaniesSliceInterface }) => store);

	return {
		loading,
		companies,
		activeCompanyId,
		activeCompanyStatistic,
		activeCompanyCanBoost,
		activeCompanyCanSeeAnalytics,
		activeCompanyCanSeeBilling,
		activeCompanyHasPaymentTransactions,
		activeCompanyBalance,
		activeCompanyPrepaidJobsBalance,
		creditsRefillLoading,
		balanceUpdateLoading,
		prepaidJobsBalanceUpdateLoading,
	};
}
