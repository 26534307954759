import React, { FC } from 'react';
import classNames from 'classnames';

import TableCellInterface from './table-cell.interface';
import { getMinMaxWidthStyles } from '../../../../helpers';

import styles from './TableCell.module.scss';

const TableCell: FC<TableCellInterface> = ({
	children,
	className,
	centeredContent,
	headCell,
	width,
	minWidth = 'auto',
	maxWidth = 'auto',
	...rest
}) => {
	const inlineStyles = width
		? getMinMaxWidthStyles(width, width)
		: getMinMaxWidthStyles(minWidth, maxWidth);

	return (
		<div
			style={inlineStyles}
			className={classNames(
				styles['table-cell'],
				headCell && styles['table-head__cell'],
				centeredContent && styles['centered-content'],
				className,
			)}
			{...rest}
		>
			{children}
		</div>
	);
};

export default TableCell;
