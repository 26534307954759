import React, { FC, useEffect } from 'react';
import classNames from 'classnames';

import LoaderInterface from './loader.interface';
import Spinner from '../../atoms/ui/spinner';
import { getMinMaxWidthStyles, getMinMaxHeightStyles } from '../../../helpers';

import styles from './Loader.module.scss';
import { nonScrollablePageOff, nonScrollablePageOn } from '../../../helpers/custom/common';

const Loader: FC<LoaderInterface> = ({
	className,
	minWidth,
	maxWidth,
	minHeight,
	maxHeight,
	thin,
	color,
	transparentColor,
	wide,
	withOverlay,
	...rest
}) => {
	const inlineStyles = {
		...getMinMaxWidthStyles(minWidth, maxWidth),
		...getMinMaxHeightStyles(minHeight, maxHeight),
	};

	const spinnerInlineStyles =
		color && transparentColor
			? {
					borderColor: color,
					borderTopColor: transparentColor,
			  }
			: {};

	useEffect(() => {
		if (wide) nonScrollablePageOn();
		return () => nonScrollablePageOff();
	}, [wide]);

	return (
		<div
			style={inlineStyles}
			className={classNames(
				styles.loader,
				className,
				wide ? styles.wide : '',
				withOverlay ? styles.shadow : '',
			)}
			{...rest}
		>
			<Spinner style={spinnerInlineStyles} thin={thin} width={maxWidth} height={maxHeight} />
		</div>
	);
};

export default Loader;
