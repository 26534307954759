import React, { FC } from 'react';
import classNames from 'classnames';

import { getMinMaxWidthStyles } from '../../../../helpers';
import DownloadLinkButtonInterface from './download-link-button.interface';

import styles from './DownloadLinkButton.module.scss';

const DownloadLinkButton: FC<DownloadLinkButtonInterface> = ({
	children,
	className,
	reversedStyles,
	small,
	minWidth,
	maxWidth,
	download = true,
	...rest
}) => {
	const inlineStyles = getMinMaxWidthStyles(minWidth, maxWidth);

	return (
		<a
			style={inlineStyles}
			className={classNames(
				styles.button,
				reversedStyles && styles.reversed,
				small && styles.small,
				className,
			)}
			{...{ download, ...rest }}
		>
			{children}
		</a>
	);
};

export default DownloadLinkButton;
