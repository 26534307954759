import React, { FC } from 'react';

import { calculatePercent } from '../../../../../helpers/charts';
import HorizontalProgressBarInterface from './horizontal-progress-bar.interface';

import styles from './HorizontalRail.module.scss';

const HorizontalProgressBar: FC<HorizontalProgressBarInterface> = ({ maxItemValue, maxValue }) => {
	return (
		<span
			className={styles.bar}
			style={maxItemValue ? { width: `${calculatePercent(maxItemValue, maxValue)}%` } : {}}
		/>
	);
};

export default HorizontalProgressBar;
