import React, { FC } from 'react';
import classNames from 'classnames';

import TopPageLayoutInterface from './top-page-layout.interface';
import Container from '../container';

import styles from './TopPageLayout.module.scss';

const TopPageLayout: FC<TopPageLayoutInterface> = ({
	children,
	className,
	containerClassName,
	...rest
}) => {
	return (
		<div className={classNames(styles['top-page-layout'], className)} {...rest}>
			<Container flex className={classNames(styles.container, containerClassName)}>
				{children}
			</Container>
		</div>
	);
};

export default TopPageLayout;
