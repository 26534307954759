import React, { FC, useMemo, useRef } from 'react';
import styles from './GenerationLoadingScreen.module.scss';
import GenerationLoadingScreenInterface from './GenerationLoadingScreenInterface';
import SparkeSpeech from '../../../molecules/custom/sparke-speech/SparkeSpeech';
import classNames from 'classnames';
import enginLogo from '../../../../assets/icons/powered-by-engin-logo-white.svg';
import { createPortal } from 'react-dom';

const GenerationLoadingScreen: FC<GenerationLoadingScreenInterface> = ({
	className,
	text,
	show,
	withAnimation,
}) => {
	const documentElement = useRef(document.getElementById('root'));

	const loadingContent = useMemo(() => {
		return (
			<>
				<div className={styles['loading-animation']}></div>

				<div className={styles['bubble-content']}>
					{text ||
						`I'm Spark-e - hang on a minute while I generate a job description that will help
					you create a compelling job post.`}
				</div>
			</>
		);
	}, [text]);

	return (
		documentElement.current &&
		createPortal(
			<div
				className={classNames(
					styles.loading,
					className,
					show ? styles.show : styles.hide,
					withAnimation ? styles['with-animation'] : '',
				)}
			>
				<SparkeSpeech
					className={styles.sparke}
					medium
					minWidth={'272px'}
					maxWidth={'272px'}
					content={loadingContent}
				/>

				<img className={styles.logo} src={enginLogo} />
			</div>,
			documentElement.current,
		)
	);
};

export default GenerationLoadingScreen;
