import React, { FC } from 'react';
import classNames from 'classnames';

import InputInterface from './input.interface';

import styles from './Input.module.scss';

const Input: FC<InputInterface> = React.forwardRef(
	({ className, disabled, type, style, visibleDisableStyles = false, ...rest }, ref) => {
		return (
			<input
				{...{ type, style, ref, disabled, ...rest }}
				className={classNames(
					styles.input,
					disabled && styles.disabled,
					visibleDisableStyles && styles['visible-disable-styles'],
					className,
				)}
			/>
		);
	},
);

export default React.memo(Input);
