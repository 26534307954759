import axios from 'axios';
import { ProductInterface } from '../store/slices/products.slice';

import { PRODUCTS } from './api.path';

const productsApi = {
	getProductsList: (): Promise<ProductInterface[]> => {
		const url = `${PRODUCTS}`;

		return axios.get(url);
	},
};

export default productsApi;
