import React, { FC } from 'react';
import classNames from 'classnames';

import HorizontalRailInterface from './horizontal-rail.interface';

import styles from './HorizontalRail.module.scss';

const HorizontalRail: FC<HorizontalRailInterface> = ({ children, className, ...rest }) => {
	return (
		<div className={classNames(styles.wrap, className)} {...rest}>
			{children}
		</div>
	);
};

export default HorizontalRail;
