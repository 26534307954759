import { ReactElement } from 'react';

export const clickedOutside = (
	parent: ReactElement | HTMLElement | null,
	element: EventTarget | null,
): boolean => {
	let tempParent: HTMLElement | null = element as HTMLElement;

	while (
		tempParent &&
		parent !== tempParent &&
		tempParent.parentElement &&
		tempParent.parentElement.tagName.toLowerCase() !== 'body'
	) {
		tempParent = tempParent && tempParent.parentElement;
	}

	return tempParent !== parent;
};

export const getFocusableElements = (parent: HTMLElement | Document = document): HTMLElement[] => {
	return Array.from(parent.querySelectorAll('button, [href], input, select, textarea'));
};
