import React, { FC } from 'react';
import classNames from 'classnames';

import ChartLabelInterface from './chart-label.interface';

import styles from './ChartLabel.module.scss';

const ChartLabel: FC<ChartLabelInterface> = ({ className, children, ...rest }) => {
	return (
		<span className={classNames(styles.label, className)} {...rest}>
			{children}
		</span>
	);
};

export default ChartLabel;
