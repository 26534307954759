import { Dispatch } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootStateType } from '../reducers/root-reducer';
import { getCandidateSuccess, changeCandidateStatusSuccess } from './candidate.slice';
import candidatesListApi, { CandidatesStatusesPayloadInterface } from '../../api/candidates.api';
import axios from 'axios';
import wurkApi from '../../api/wurk.api';
import { getSecondarySortSettings, SortProperty } from '../../helpers/custom/common';

export interface CandidatesCount {
	all?: number;
	relevant?: number;
	open?: number;
	needreview?: number;
	shortlisted?: number;
	contacted?: number;
	interviewed?: number;
	offered?: number;
	hired?: number;
	rejected?: number;
	notrelevant?: number;
}

export type CandidateHiredInWurkType = { hiredAt: 'string'; ein: 'string'; startAt: 'string' };

export type LastCandidatesExportType = {
	filter: keyof CandidatesCount;
	candidates: number[];
	size: number;
	status: string;
	url: string;
	updatedAt: string;
};

export interface CandidatesListSliceInterface {
	items: any[] | undefined;
	hiringWithWurkCandidateId: string | number | undefined;
	loading: boolean;
	candidatesCountLoading: boolean;
	wurkHiringLoading: boolean;
	bigData?: {
		showModalWindow?: boolean;
		loading?: boolean;
	};
	exportCandidatesLoading: boolean;
	lastCandidatesExportLoading?: boolean;
	newExportedCandidateLink?: string;
	lastExportedCandidateInfo?: LastCandidatesExportType;
	count: CandidatesCount;
}

const initialState: CandidatesListSliceInterface = {
	items: undefined,
	loading: false,
	candidatesCountLoading: false,
	wurkHiringLoading: false,
	exportCandidatesLoading: false,
	hiringWithWurkCandidateId: undefined,
	count: {},
};

export const candidatesListSlice = createSlice({
	name: 'candidates',
	initialState,
	reducers: {
		loading(state) {
			state.loading = true;
		},
		lastCandidatesExportLoading(state) {
			state.lastCandidatesExportLoading = true;
		},
		lastCandidatesExportLoadingSuccess(state) {
			state.lastCandidatesExportLoading = false;
		},
		setWurkHiringLoading(state, { payload }: PayloadAction<boolean>) {
			state.wurkHiringLoading = payload;
		},
		exportCandidatesLoading(state) {
			state.exportCandidatesLoading = true;
		},
		exportCandidatesLoadingSuccess(state) {
			state.exportCandidatesLoading = false;
		},
		getCandidatesListSuccess(state, { payload }: any) {
			state.items = payload;
			state.loading = false;
		},
		getCandidatesListFailed(state, action: PayloadAction<string>) {
			state.loading = false;
			console.log(action);
		},
		changeCandidatesStatusSuccess(state) {
			state.loading = false;
		},
		clearCandidatesStatusSuccess(state) {
			state.items = undefined;
		},
		changeCandidatesStatusFailed(state, action: PayloadAction<string>) {
			state.loading = false;
			console.log(action);
		},
		changeMultipleCandidatesStatusFailed(state, action: PayloadAction<string>) {
			state.loading = false;
			console.log(action);
		},
		candidatesCountLoading(state, { payload }: PayloadAction<boolean>) {
			state.candidatesCountLoading = payload;
		},
		getCandidatesCountSuccess(state, { payload }: any) {
			state.count = payload;
			state.candidatesCountLoading = false;
		},
		getCandidatesCountFailed(state, action: PayloadAction<string>) {
			state.candidatesCountLoading = false;
			console.log(action);
		},
		exportCandidatesSuccess(state, action: PayloadAction<string>) {
			state.loading = false;
			console.log(action);
		},
		setNewExportedCandidateSuccess(state, { payload }: PayloadAction<string>) {
			state.newExportedCandidateLink = payload;
		},
		setLastExportedCandidateInfoSuccess(
			state,
			{ payload }: PayloadAction<LastCandidatesExportType>,
		) {
			state.lastExportedCandidateInfo = payload;
		},
		setBigExportedCandidateDataStatus(state) {
			state.bigData = { showModalWindow: true, loading: true };
		},
		setBigExportedCandidateDataLoadingStatus(
			state,
			{ payload }: PayloadAction<{ showModalWindow?: boolean; loading?: boolean }>,
		) {
			state.bigData = { ...state.bigData, ...payload };
		},
		clearBigExportedCandidateDataStatus(state) {
			state.bigData = undefined;
		},
		clearExportedCandidateLinks(state) {
			state.newExportedCandidateLink = undefined;
			state.lastExportedCandidateInfo = undefined;
		},
		setHiringWithWurkCandidateId: (state, { payload }: PayloadAction<string | undefined>) => {
			state.hiringWithWurkCandidateId = payload;
		},
	},
});

export const getCandidatesList =
	(
		jobId: string | number,
		settings?: {
			title?: string;
			filter?: string;
			orderBy?: string;
			pageSize?: number;
			pageNumber?: number;
		},
		clearItemsList?: boolean,
		recountCandidates?: boolean,
	) =>
	async (dispatch: Dispatch<any>, getState: () => any) => {
		dispatch(loading());

		const { candidates, job: jobInfo } = getState();
		const jobHasPowerProfile = jobInfo?.info?.hasPowerProfile;

		if (settings) {
			if (settings.orderBy) {
				const order = settings?.orderBy.replace(' ', '+');
				settings.orderBy = `${order}${getSecondarySortSettings(order, jobHasPowerProfile)}`;
			} else {
				if (jobHasPowerProfile)
					settings.orderBy = `score+desc${getSecondarySortSettings(
						SortProperty.score,
						jobHasPowerProfile,
					)}`;
				else
					settings.orderBy = `createdAt+desc${getSecondarySortSettings(
						SortProperty.createdAt,
						jobHasPowerProfile,
					)}`;
			}
		}

		const candidatesListHandler = async (clearItemsList?: boolean) => {
			try {
				const response = await candidatesListApi.getCandidatesList(jobId, settings);

				if (recountCandidates)
					dispatch(
						getCandidatesCount(
							jobId as string,
							settings?.filter || (jobHasPowerProfile ? 'relevant' : 'all'),
							settings?.title,
						),
					);

				if (clearItemsList) {
					dispatch(getCandidatesListSuccess(response));
				} else {
					const mergedData: any = [...candidates?.items, ...response];

					dispatch(
						getCandidatesListSuccess(mergedData),
					);
				}
			} catch (err) {
				dispatch(getCandidatesListFailed(err.toString()));
			}
		};

		if (clearItemsList) {
			try {
				await dispatch(clearCandidatesStatusSuccess());

				candidatesListHandler(clearItemsList);
			} catch (error) {
				console.error(error);
			}
		} else {
			candidatesListHandler();
		}
	};

export const changeCandidateStatus =
	(
		companyId: string,
		status?: string,
		rejectionReason?: number,
		subject?: string,
		body?: string,
	) =>
	async (dispatch: Dispatch<any>) => {
		try {
			const response = await candidatesListApi.changeCandidatesStatus(
				companyId,
				status,
				rejectionReason,
				subject,
				body,
			);
			dispatch(
				changeCandidateStatusSuccess({
					status: response?.status,
					statusLabel: response?.statusLabel,
				}),
			);
		} catch (err) {
			dispatch(getCandidatesListFailed(err.toString()));
		}
	};

let exportCandidatesSource: any;

export const exportCandidates =
	(params: { jobId: string | number; filter?: string; applications?: (string | number)[] }) =>
	async (dispatch: Dispatch<any>) => {
		if (exportCandidatesSource && exportCandidatesSource.cancel) {
			exportCandidatesSource.cancel('Canceled previous request');
		}

		dispatch(
			setBigExportedCandidateDataLoadingStatus({ loading: false, showModalWindow: false }),
		);

		dispatch(exportCandidatesLoading());

		exportCandidatesSource = axios?.CancelToken?.source();
		const config = { cancelToken: exportCandidatesSource?.token };

		let checkingCount = 1;
		let exportData: LastCandidatesExportType | null = null;

		const checkingStatusHandler = () => {
			if (checkingCount === 1 || (checkingCount <= 3 && exportData?.status === 'progress')) {
				checkingCount += 1;

				Promise.all([dispatch(getLastExportCandidates(params.jobId, false))]).then(
					(data: any) => {
						exportData = data[0];
					},
				);
			} else {
				switch (exportData?.status) {
					case 'progress':
						dispatch(setBigExportedCandidateDataStatus());
						break;
					case 'completed':
						dispatch(setNewExportedCandidateSuccess(exportData?.url));
						dispatch(exportCandidatesLoadingSuccess());
						dispatch(setBigExportedCandidateDataLoadingStatus({ loading: false }));
						break;
				}

				clearInterval(intervalId);
			}
		};

		const intervalId = setInterval(checkingStatusHandler, 3000);

		try {
			await candidatesListApi.exportCandidates(params, config);
		} catch (err) {
			dispatch(exportCandidatesLoadingSuccess());

			throw err;
		}
	};

export const getLastExportCandidates =
	(jobId: string | number, displayLoading: boolean = true) =>
	async (dispatch: Dispatch<any>) => {
		if (displayLoading) {
			dispatch(lastCandidatesExportLoading());
		}

		try {
			const result = await candidatesListApi.getLasExportCandidates(jobId);

			dispatch(setLastExportedCandidateInfoSuccess(result || {}));
			if (displayLoading) {
				dispatch(lastCandidatesExportLoadingSuccess());
			}

			return result;
		} catch (err) {
			if (displayLoading) {
				dispatch(lastCandidatesExportLoadingSuccess());
			}
			throw err;
		}
	};

export const hireCandidateWithWurk =
	(data: CandidateHiredInWurkType) =>
	async (dispatch: Dispatch<any>, getState: () => RootStateType) => {
		dispatch(setWurkHiringLoading(true));

		const {
			candidates: { hiringWithWurkCandidateId: id, items: candidates },
			candidate: { info: candidateInfo },
		} = getState();
		try {
			return id
				? await wurkApi.hireWithWurk(id, data).then((response) => {
						const normalizedCandidates = candidates?.map((item) => {
							if (item.id === response.id) {
								return response;
							}

							return item;
						});

						if (candidateInfo && Object.keys(candidateInfo).length) {
							dispatch(getCandidateSuccess({ ...candidateInfo, ...response }));
						}

						if (normalizedCandidates) {
							dispatch(getCandidatesListSuccess(normalizedCandidates));
						}

						dispatch(setWurkHiringLoading(false));
				  })
				: null;
		} catch (error) {
			dispatch(setWurkHiringLoading(false));
			throw error;
		}
	};

export const changeMultipleCandidatesStatuses =
	(
		jobId: string | number,
		body: CandidatesStatusesPayloadInterface,
		settings?: {
			title?: string;
			filter?: string;
			pageSize?: number;
			pageNumber?: number;
			orderBy?: string;
		},
	) =>
	async (dispatch: Dispatch<any>, getState: () => any) => {
		dispatch(loading());

		const { job: jobInfo } = getState();
		const jobHasPowerProfile = jobInfo?.info?.hasPowerProfile;

		if (settings) {
			if (settings.orderBy) {
				const order = settings?.orderBy.replace(' ', '+');
				settings.orderBy = `${order}${getSecondarySortSettings(order, jobHasPowerProfile)}`;
			} else {
				if (jobHasPowerProfile) {
					settings.orderBy = `score+desc${getSecondarySortSettings(
						SortProperty.score,
						jobHasPowerProfile,
					)}`;
				} else {
					settings.orderBy = `createdAt+desc${getSecondarySortSettings(
						SortProperty.createdAt,
						jobHasPowerProfile,
					)}`;
				}
			}
		}

		try {
			await candidatesListApi.changeMultipleCandidatesStatuses(body);

			try {
				await dispatch(clearCandidatesStatusSuccess());
				const response = await candidatesListApi.getCandidatesList(jobId, settings);

				dispatch(getCandidatesListSuccess(response));
			} catch (error) {
				console.error(error);
			}
		} catch (err) {
			dispatch(changeMultipleCandidatesStatusFailed(err.toString()));
		}
	};

export const getCandidatesCount =
	(jobId: string, filterItem: string = 'relevant', text?: string) =>
	async (dispatch: Dispatch<any>) => {
		dispatch(candidatesCountLoading(true));

		try {
			const response = await candidatesListApi.getCandidatesCount(jobId, filterItem, text);

			dispatch(getCandidatesCountSuccess(response));
		} catch (err) {
			dispatch(getCandidatesCountFailed(err.toString()));
		}
	};

export const getRejectionReasons = () => async () => {
	try {
		return await candidatesListApi.getRejectionReasons();
	} catch (err) {
		return err;
	}
};

export const {
	loading,
	lastCandidatesExportLoading,
	lastCandidatesExportLoadingSuccess,
	setWurkHiringLoading,
	exportCandidatesLoading,
	setHiringWithWurkCandidateId,
	exportCandidatesLoadingSuccess,
	setNewExportedCandidateSuccess,
	setBigExportedCandidateDataStatus,
	clearBigExportedCandidateDataStatus,
	setLastExportedCandidateInfoSuccess,
	setBigExportedCandidateDataLoadingStatus,
	getCandidatesListSuccess,
	getCandidatesListFailed,
	changeMultipleCandidatesStatusFailed,
	clearCandidatesStatusSuccess,
	candidatesCountLoading,
	getCandidatesCountSuccess,
	getCandidatesCountFailed,
	clearExportedCandidateLinks,
} = candidatesListSlice.actions;
export default candidatesListSlice.reducer;
