import React, { FC } from 'react';
import classNames from 'classnames';

import FormItemInterface from './form-item.interface';

import styles from './FormItem.module.scss';
import { getMinMaxWidthStyles } from '../../../../helpers';

const FormItem: FC<FormItemInterface> = ({ children, className, minWidth, maxWidth, ...rest }) => {
	const inlineStyles = getMinMaxWidthStyles(minWidth, maxWidth);

	return (
		<div style={inlineStyles} className={classNames(styles['form-item'], className)} {...rest}>
			{children}
		</div>
	);
};

export default FormItem;
