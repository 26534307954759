import React, { FC } from 'react';
import classNames from 'classnames';

import CompanyInfoTextInterface from './company-info-text.interface';
import TypographyText from '../../../atoms/ui/typography-text';
import useActiveCompany from '../../../../helpers/hooks/use-active-company/useActiveCompany';

import styles from './CompanyInfoText.module.scss';
import useCompanies from '../../../../helpers/hooks/use-companies/useCompanies';

const CompanyInfoText: FC<CompanyInfoTextInterface> = ({ className, ...rest }) => {
	const { activeCompanyId, companies } = useCompanies();
	const activeCompany = useActiveCompany(activeCompanyId, companies);

	if (activeCompany?.defaultJobBoardUrl) {
		return (
			<TypographyText className={classNames(styles.wrap, className)} {...rest}>
				Your company page details are below. This information appears on your{' '}
				<a href={activeCompany?.defaultJobBoardUrl} target="_blank">
					public company page{' '}
				</a>{' '}
				on the job seeker site, as well as in job listings.
			</TypographyText>
		);
	}

	return null;
};

export default CompanyInfoText;
