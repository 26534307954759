import React, { FC, useCallback, useMemo } from 'react';
import classNames from 'classnames';

import StatisticTopBarInterface from './statistic-top-bar.interface';
import StatisticTopBarItem from '../../molecules/custom/statistic-top-bar-item';
import Container from '../../templates/container';
import TextButton from '../../atoms/buttons/text-button/TextButton';

import styles from './StatisticTopBar.module.scss';
import { APP_URLS } from '../../../helpers/routes/routes';
import { createJobIntentGA4Event } from '../../../helpers/google-analytics-4/events';
import useCompanies from '../../../helpers/hooks/use-companies/useCompanies';
import useActiveCompany from '../../../helpers/hooks/use-active-company/useActiveCompany';
import { GAContentGroup, GAContentId } from '../../../helpers/google-analytics-4/index.constants';
import { getCompanyDataForGA } from '../../../helpers/google-analytics-4/helpers';

const StatisticTopBar: FC<StatisticTopBarInterface> = ({ items, className, ...rest }) => {
	const { activeCompanyId, companies } = useCompanies();
	const activeCompany = useActiveCompany(activeCompanyId, companies);

	const elements = useMemo(
		() => items.map((props, index) => <StatisticTopBarItem key={index} {...props} />),
		[items],
	);

	const onCreateClick = useCallback(() => {
		if (activeCompany) {
			createJobIntentGA4Event({
				companyData: getCompanyDataForGA(activeCompany),
				contentData: {
					id: GAContentId.jobList,
					group: GAContentGroup.jobList,
				},
			});
		}
	}, [activeCompany]);

	return (
		<div className={classNames(styles.statistic, className)} {...rest}>
			<Container flex className={styles.container}>
				<div className={styles.left}>{elements}</div>

				<div className={styles.right}>
					<TextButton
						to={APP_URLS.createJob}
						small
						minWidth={159}
						onClick={onCreateClick}
					>
						Create job
					</TextButton>
				</div>
			</Container>
		</div>
	);
};

export default StatisticTopBar;
