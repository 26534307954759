import React, { ChangeEvent, FC, useEffect, useState } from 'react';

import SearchInputFilter from '../../../molecules/filters/search-input-filter/SearchInputFilter';
import CandidatesListSearchFilterInterface from './candidates-list-search-filter.interface';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	FilterParamsType,
	parseUrlParams,
	stringifyParamsForUrl,
} from '../../../../helpers/custom/url';
import { useDebounce } from '../../../../helpers';

const CandidatesListSearchFilter: FC<CandidatesListSearchFilterInterface> = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [text, setText] = useState<string>();
	const payload = useDebounce(text);

	useEffect(() => {
		const { text } = parseUrlParams<FilterParamsType>(location.search);

		setText(text);
	}, [location.search]);

	useEffect(() => {
		if (payload || payload === '') {
			const filterParams = parseUrlParams<FilterParamsType>(location.search);
			filterParams.text = payload;

			const filterParamsAsString = stringifyParamsForUrl(filterParams);

			const newUrl = location.pathname + '?' + filterParamsAsString;
			const windowUrl = window.location.pathname + window.location.search;

			if (newUrl !== windowUrl) {
				navigate({
					pathname: location.pathname,
					search: filterParamsAsString,
				});
			}
		}
	}, [location.pathname, location.search, navigate, payload]);

	return (
		<SearchInputFilter
			inputSettings={{
				defaultValue: text || '',
				value: text || '',
			}}
			onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
				setText(value);
			}}
		/>
	);
};

export default CandidatesListSearchFilter;
