import React, { FC } from 'react';
import classNames from 'classnames';

import { getMinMaxWidthStyles } from '../../../../helpers';
import ProgressScoreInterface from './progress-score.interface';

import styles from './ProgressScore.module.scss';

const ProgressScore: FC<ProgressScoreInterface> = ({
	className,
	score,
	maxWidth,
	minWidth,
	...rest
}) => {
	const inlineProgressStyle = {
		left: `calc(${score}% - 5px)`,
	};

	const inlineStyles = getMinMaxWidthStyles(minWidth, maxWidth);

	return (
		<div
			className={classNames(styles.wrapper, className)}
			{...rest}
		>
			<span style={inlineStyles} className={styles.score}>
				{score ? <span style={inlineProgressStyle} className={styles.progress} /> : null}
			</span>
		</div>
	);
};

export default ProgressScore;
