import React, { FC, useCallback, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { getJobStatusLabel } from '../../../../helpers/response-comparison';
import InfoListItemInterface from '../../../molecules/list/info-list-item/info-list-item.interface';
import Title from '../../../atoms/title/Title';
import Warnings from '../warnings/warnings';
import InfoList from '../../list/info-list/InfoList';
import TextButton from '../../../atoms/buttons/text-button/TextButton';
import TopPageLayout from '../../../templates/top-page-layout/TopPageLayout';
import dayjs from 'dayjs';
import styles from './CompanyInfo.module.scss';
import Loader from '../../../molecules/loader';
import { LocationRequirementsValues, QueryParametersKey } from '../../../../helpers/constants';
import IconLinkButton from '../../../../components/atoms/buttons/icon-link-button/IconLinkButton';
import classNames from 'classnames';
import promoteIconActive from '../../../../assets/icons/loudspeaker-active.svg';
import promoteIconInactive from '../../../../assets/icons/loudspeaker-inactive.svg';
import Tooltip from '../../../atoms/ui/tooltip';
import {
	JobStatusType,
	checkIfJobNeedsPayment,
} from '../../../../helpers/custom/common';
import { dateInMDYFormat, getPeriodInDays } from '../../../../helpers/custom/datetime';
import InfoPopover from '../../../molecules/ui/info-popover';
import { checkJobIsBoosted } from '../../../../helpers/custom/job';
import useCompanies from '../../../../helpers/hooks/use-companies/useCompanies';
import useJob from '../../../../helpers/hooks/use-job/useJob';
import { BoostItemInterface } from '../../../../store/slices/job.slice';
import { boostJobIntentGA4Event } from '../../../../helpers/google-analytics-4/events';
import {
	GAContentGroup,
	GAContentId,
	GAContentType,
} from '../../../../helpers/google-analytics-4/index.constants';
import {
	getCompanyDataForGA,
	getJobDataForGA,
} from '../../../../helpers/google-analytics-4/helpers';
import useActiveCompany from '../../../../helpers/hooks/use-active-company/useActiveCompany';

const CompanyInfo: FC = () => {
	const location = useLocation();
	const { activeCompanyCanBoost, activeCompanyId, companies, activeCompanyPrepaidJobsBalance } = useCompanies();
	const { info, statistic, statusLoading } = useJob();
	const {
		title,
		numberOfOpenings,
		facility,
		employmentTypeLabel,
		status,
		publishedAt,
		hasPowerProfile,
		locationType,
		locationTypeLabel,
		hasExternalApply,
		boostId,
		jobBoosts,
	} = info;

	const { id } = useParams();

	const activeTab = useMemo(
		() => new URLSearchParams(location.search).get(QueryParametersKey.tab) || 'candidates',
		[location.search],
	);
	const activeCompany = useActiveCompany(activeCompanyId, companies);

	const jobNeedsPayment = useMemo(
		() => checkIfJobNeedsPayment(info, activeCompany, activeCompanyPrepaidJobsBalance),
		[activeCompany, info, activeCompanyPrepaidJobsBalance],
	);

	const boosts = useMemo(() => {
		return jobBoosts?.map((boostItem: BoostItemInterface) => {
			const { id, jobId, startedAt, endedAt, credits, numberOfOpenings, cancelledAt } =
				boostItem;

			return {
				id,
				jobId,
				startedAt: dateInMDYFormat(startedAt),
				endedAt: dateInMDYFormat(endedAt),
				cancelledAt: dateInMDYFormat(cancelledAt),
				credits,
				numberOfOpenings,
			};
		});
	}, [jobBoosts]);

	const isBoostedJob = useMemo(
		() => checkJobIsBoosted(boostId, boosts, status),
		[boostId, boosts, status],
	);
	const canBeBoosted = useMemo(
		() => activeCompanyCanBoost && !hasExternalApply,
		[activeCompanyCanBoost, hasExternalApply],
	);

	const getActiveTimestampString = useCallback((datetime: string) => {
		let result = '';

		const daysDifference = Math.abs(dayjs(datetime).diff(Date.now(), 'days'));

		if (daysDifference > 30)
			result = 'since ' + dayjs(datetime).format('D MMM YYYY').toLowerCase();
		else if (daysDifference === 1) {
			return '1 day';
		} else result = daysDifference + ' days';

		return result;
	}, []);

	const getBoostedTimestampString = useCallback(() => {
		let result = '';

		if (boostId && boosts?.length) {
			const currentBoost = boosts?.find((boost) => boost.id === boostId);

			if (currentBoost) {
				const days = getPeriodInDays(currentBoost.startedAt);

				if (days !== undefined)
					result = `${days === 0 ? '1' : days} day${days <= 1 ? '' : 's'}`;
			}
		}

		return result;
	}, [boostId, boosts]);

	const renderJobTimestamp = useMemo(() => {
		if (isBoostedJob) {
			return <div className={styles['job-timestamp']}>{getBoostedTimestampString()}</div>;
		}

		if (!statusLoading && status === JobStatusType.active && publishedAt) {
			const formatted = getActiveTimestampString(publishedAt);
			return <div className={styles['job-timestamp']}>{formatted}</div>;
		}
	}, [
		getActiveTimestampString,
		getBoostedTimestampString,
		isBoostedJob,
		publishedAt,
		status,
		statusLoading,
	]);

	const renderJobStatus = useMemo(() => {
		if (statusLoading)
			return (
				<div className={styles['loader-wrap']}>
					<Loader className={styles.loader} thin maxWidth={25} maxHeight={25} />
				</div>
			);

		if (isBoostedJob) return 'Boosting';
		if (status !== undefined) return getJobStatusLabel(status);
	}, [isBoostedJob, status, statusLoading]);

	const getValueForApplicants = useMemo(() => {
		if (hasExternalApply) return 'N/A';

		return hasPowerProfile
			? `${statistic?.relevantApplicationsCount || 0}/${statistic?.applicationsCount || 0}`
			: `${statistic?.applicationsCount || 0}`;
	}, [
		hasExternalApply,
		hasPowerProfile,
		statistic?.applicationsCount,
		statistic?.relevantApplicationsCount,
	]);

	const infoListItems: InfoListItemInterface[] = useMemo(
		() => [
			{
				keyString: 'Status',
				value: renderJobStatus,
				additionalInfo: renderJobTimestamp,
			},
			{
				keyString: 'Openings',
				value: numberOfOpenings,
			},
			{
				keyString: hasPowerProfile ? 'Relevant applicants' : 'Applicants',
				value: getValueForApplicants,
			},
		],
		[
			getValueForApplicants,
			hasPowerProfile,
			numberOfOpenings,
			renderJobStatus,
			renderJobTimestamp,
		],
	);

	const getLocationRequirement = useMemo(() => {
		if (locationType === LocationRequirementsValues.onSite) return;
		else return locationTypeLabel ? ` (${locationTypeLabel})` : '';
	}, [locationType, locationTypeLabel]);

	const triggerBoostIntentGA4Event = useCallback(() => {
		boostJobIntentGA4Event({
			jobData: getJobDataForGA(info),
			companyData: getCompanyDataForGA(activeCompany),
			contentData: {
				id: GAContentId.job,
				type: GAContentType[activeTab as keyof typeof GAContentType],
				group: GAContentGroup.job,
			},
		});
	}, [activeCompany, activeTab, info]);

	const boostBtn = useMemo(
		() => (
			<TextButton
				to={`/boost-job/${id}`}
				state={{ hasHistoryBackContext: true }}
				small
				minWidth={99}
				className={classNames(
					styles.btn,
					styles.boost,
					isBoostedJob || status === JobStatusType.inactive ? styles.disabled : '',
				)}
				onClick={triggerBoostIntentGA4Event}
			>
				Boost
			</TextButton>
		),
		[id, isBoostedJob, status, triggerBoostIntentGA4Event],
	);

	const renderBoostInfoBoxContent = useMemo(() => {
		return (
			<div className={styles['info-content']}>
				<div className={styles.title}>Boost your job</div>
				<div className={styles.text}>To boost your job, activate it first in Settings.</div>
			</div>
		);
	}, []);

	const renderBoostBtn = useMemo(() => {
		if (!canBeBoosted) return null;

		if (status === JobStatusType.inactive) {
			return (
				<InfoPopover
					className={styles['info-box']}
					trigger={boostBtn}
					contentClassName={styles['popover-width']}
				>
					{renderBoostInfoBoxContent}
				</InfoPopover>
			);
		}

		return boostBtn;
	}, [boostBtn, canBeBoosted, renderBoostInfoBoxContent, status]);

	const renderBoostLabel = useMemo(() => {
		if (canBeBoosted && !isBoostedJob) {
			return (
				<div className={classNames(styles['boost-label'], styles['not-boosting'])}>
					Not Boosting
				</div>
			);
		}

		if (isBoostedJob) {
			return (
				<div className={classNames(styles['boost-label'], styles.boosting)}>Boosting</div>
			);
		}
	}, [canBeBoosted, isBoostedJob]);

	const renderPromoteBtn = useMemo(
		() => (
			<div className={classNames(styles.btn, styles['item-with-tooltip'])}>
				<IconLinkButton
					className={classNames(
						styles.promote,
						status === JobStatusType.inactive ? styles.disabled : '',
					)}
					imageIcon={
						status === JobStatusType.active ? promoteIconActive : promoteIconInactive
					}
					to={`/promote-job/${id}`}
					state={{ hasHistoryBackContext: true }}
				/>

				<Tooltip className={styles['item-tooltip']} text={'Promote'} />
			</div>
		),
		[id, status],
	);

	const renderEditBtn = useMemo(
		() => (
			<div className={classNames(styles.btn, styles['item-with-tooltip'])}>
				<IconLinkButton
					className={styles.edit}
					icon="edit"
					iconSettings={{ icon: 'edit', large: true }}
					to={`/edit-job/${id}`}
					state={{ hasHistoryBackContext: true }}
				/>

				<Tooltip className={styles['item-tooltip']} text={'Edit'} />
			</div>
		),
		[id],
	);

	const renderTitlesBarContent = useMemo(
		() => (
			<div className={styles.titles}>
				{renderBoostLabel}

				<Title
					component="h2"
					fontSize={26}
					fontWeight={600}
					marginBottom={8}
					className={styles['job-title']}
				>
					{title}
				</Title>

				<Title component="h3" fontSize={14} fontWeight={700} className={styles.subtitle}>
					{facility?.name
						? facility?.name
						: facility?.city || facility?.state
						? `${facility?.city}, ${facility?.state}`
						: null}

					{getLocationRequirement}
				</Title>

				<Title
					component="h3"
					fontSize={14}
					fontWeight={700}
					className={classNames(styles.subtitle, styles['type-label'])}
				>
					{employmentTypeLabel}
				</Title>
			</div>
		),
		[
			employmentTypeLabel,
			facility?.city,
			facility?.name,
			facility?.state,
			getLocationRequirement,
			renderBoostLabel,
			title,
		],
	);

	return (
		<>
			<Warnings showPaymentRequiredCTA={jobNeedsPayment} />

			<TopPageLayout
				className={classNames(
					styles['company-info-top'],
					isBoostedJob ? styles.boosted : '',
				)}
				containerClassName={styles.container}
			>
				{renderTitlesBarContent}

				<InfoList
					items={infoListItems}
					className={styles.center}
					itemsClassName={styles.item}
				/>

				<div className={styles.right}>
					<div className={styles.actions}>
						{renderBoostBtn}

						{renderPromoteBtn}

						{renderEditBtn}
					</div>
				</div>
			</TopPageLayout>
		</>
	);
};

export default React.memo(CompanyInfo);
