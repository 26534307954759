import React, { FC } from 'react';
import classNames from 'classnames';

import CertificationBadgeInterface from './certification-badge.interface';
import styles from './CertificationBadge.module.scss';

const CertificationBadge: FC<CertificationBadgeInterface> = ({
	badgeImageUrl,
	className,
	...rest
}) => {
	return (
		<span className={classNames(styles.badge, className)} {...rest}>
			{badgeImageUrl ? (
				<img src={badgeImageUrl} alt="B" width={20} />
			) : (
				<span className={styles.alt}>B</span>
			)}
		</span>
	);
};

export default CertificationBadge;
