export const showInfoBox = (
	e?: React.MouseEvent,
	refElement?: React.MutableRefObject<HTMLDivElement | null>,
	device?: "mobile" | "tablet" | "desktop" | null,
) => {
	const x = e?.clientX;
	const y = e?.clientY;

	if (refElement?.current && x && y) {
		refElement.current.style.zIndex = '50';
		refElement.current.style.visibility = 'visible';
		refElement.current.style.opacity = '1';
		refElement.current.style.top = device !== 'mobile' ? `${y + 5}px` : '10px';
		refElement.current.style.left = device !== 'mobile' ? `${x + 5}px` : 'calc(50% - 85px)';
	}
};

export const hideInfoBox = (refElement?: React.MutableRefObject<HTMLDivElement | null>) => {
	if (refElement?.current) {
		refElement.current.style.zIndex = '-1';
		refElement.current.style.visibility = 'hidden';
		refElement.current.style.opacity = '0';
	}
};

export const checkIfAreaInfoBox = (e: any, elementClassName: string) => {
    return e?.relatedTarget?.id && e?.relatedTarget?.id?.includes(elementClassName);
};

export const checkIfReferenceArea = (e: any, elementClassName: string) => {
    return !!(
        e?.relatedTarget?.parentElement?.classList &&
        e?.relatedTarget?.parentElement?.classList?.contains(elementClassName)
    );
};
