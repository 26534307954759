import React, { FC, useCallback, useMemo, useState } from 'react';

import styles from './TransactionsHistoryWithPagination.module.scss';
import {
	JobPaymentsHistoryRecord,
	getPaymentTransactionsHistoryForPage,
} from '../../../../store/slices/companies.slice';
import Table from '../../table/Table';
import TableHead from '../../../molecules/table/table-head/TableHead';
import TableCell from '../../../atoms/table/table-cell/TableCell';
import TableBody from '../../../molecules/table/table-body/TableBody';
import TableRow from '../../../molecules/table/table-row/TableRow';
import { TRANSACTIONS_PAGINATION_PAGE_SIZE } from '../../../../helpers/constants';
import Pagination from '../../../molecules/pagination';
import Loader from '../../../molecules/loader';
import TypographyText from '../../../atoms/ui/typography-text';
import TextEllipsis from '../../../molecules/ui/text-ellipsis';
import classNames from 'classnames';
import { dateInMDYFormat } from '../../../../helpers/custom/datetime';
import { UserCoreInfoType } from '../../../../store/slices/user.slice';
import usePagination from '../../../../helpers/hooks/custom/use-pagination/use-pagination';
import { TransactionsType, formatStringAsAbsDecimal } from '../../../../helpers/custom/common';
import TransactionsHistoryWithPaginationInterface from './transactions-history-with-pagination.interface';

const TransactionsHistoryWithPagination: FC<TransactionsHistoryWithPaginationInterface> = ({
	activeCompanyId,
}) => {
	const [dataInitialLoading, setDataInitialLoading] = useState(true);

	const getPageItems = useCallback(
		async (pageNumber: number) => {
			if (activeCompanyId) {
				const itemsResult = await getPaymentTransactionsHistoryForPage(
					activeCompanyId,
					pageNumber,
					TRANSACTIONS_PAGINATION_PAGE_SIZE,
				);

				if (pageNumber === 1) setDataInitialLoading(false);

				return itemsResult;
			}
		},
		[activeCompanyId],
	);

	const { paginationState, loading, moveToNextPage, moveToPrevPage } = usePagination(
		getPageItems,
		activeCompanyId,
		TRANSACTIONS_PAGINATION_PAGE_SIZE,
	);

	const renderUserName = useCallback((user: Partial<UserCoreInfoType> | null) => {
		if (user && user.firstName && user.lastName) {
			return <TextEllipsis text={`${user.firstName} ${user.lastName}`} />;
		}

		return '-';
	}, []);

	const getTransactionType = useCallback((type?: string) => {
		if (!type) return '';

		if (type === 'purchase') return 'SparkCredits';

		return TransactionsType[type as keyof typeof TransactionsType];
	}, []);

	const rowsElement = useMemo(
		() =>
			paginationState.currentPage &&
			paginationState.currentPage.items &&
			paginationState.currentPage.items.map(
				({ type, createdAt, amount, user }: JobPaymentsHistoryRecord) => {
					return (
						<TableRow key={createdAt}>
							<TableCell width={'25%'}>
								<TextEllipsis text={getTransactionType(type)} />
							</TableCell>
							<TableCell width={'25%'}>
								<TextEllipsis text={`$${formatStringAsAbsDecimal(amount)}`} />
							</TableCell>
							<TableCell width={'25%'}>
								<TextEllipsis text={dateInMDYFormat(createdAt)} />
							</TableCell>
							<TableCell>{renderUserName(user)}</TableCell>
						</TableRow>
					);
				},
			),
		[getTransactionType, paginationState.currentPage, renderUserName],
	);

	const renderTable = useMemo(() => {
		return (
			<Table>
				<TableHead>
					<TableCell width={'25%'} headCell className={styles['without-border']}>
						<TextEllipsis text={'Type'} />
					</TableCell>
					<TableCell width={'25%'} headCell className={styles['without-border']}>
						<TextEllipsis text={'Amount'} />
					</TableCell>
					<TableCell
						width={'25%'}
						className={classNames(styles['custom-weight'], styles['without-border'])}
						headCell
					>
						<TextEllipsis text={'Date'} />
					</TableCell>
					<TableCell headCell className={styles['without-border']}>
						<TextEllipsis text={'Authorized by'} />
					</TableCell>
				</TableHead>

				<TableBody className={styles.body}>{rowsElement}</TableBody>
			</Table>
		);
	}, [rowsElement]);

	const renderPaginationSection = useMemo(() => {
		return (
			<Pagination
				className={styles.pagination}
				loading={loading}
				moveToNextPage={moveToNextPage}
				moveToPrevPage={moveToPrevPage}
				hasNextPage={paginationState?.nextPage !== null}
				currentPageNumber={
					paginationState?.currentPage ? paginationState?.currentPage.pageNumber : null
				}
			/>
		);
	}, [loading, moveToNextPage, moveToPrevPage, paginationState]);

	const renderHistory = useMemo(() => {
		if (dataInitialLoading)
			return <Loader className={styles.loader} thin maxHeight={20} maxWidth={20} />;

		if (paginationState?.currentPage && paginationState?.currentPage?.items) {
			return (
				<>
					{paginationState.currentPage.items.length ? (
						<>
							{renderTable}
							{renderPaginationSection}
						</>
					) : (
						<TypographyText className={styles.empty}>
							No transactions have been made yet.
						</TypographyText>
					)}
				</>
			);
		}
	}, [dataInitialLoading, paginationState, renderPaginationSection, renderTable]);

	return <div className={styles.history}>{renderHistory}</div>;
};

export default TransactionsHistoryWithPagination;
