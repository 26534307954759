import React, { FC } from 'react';
import classNames from 'classnames';

import SlideFormLayoutInterface from './slide-form-layout.interface';
import SlideFormHeading from '../../../molecules/custom/slide-form-heading/SlideFormHeading';

import styles from './SlideFormLayout.module.scss';

const SlideFormLayout: FC<SlideFormLayoutInterface> = ({
	children,
	title,
	description,
	headerClassName,
	className,
	titleSettings,
	descriptionSettings,
	...rest
}) => {
	return (
		<section className={classNames(styles['slide-form'], className)} {...rest}>
			<SlideFormHeading
				className={headerClassName}
				{...{ title, description, titleSettings, descriptionSettings }}
			/>

			<div>{children}</div>
		</section>
	);
};

export default SlideFormLayout;
