import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { getMinMaxWidthStyles } from '../../../../helpers';
import SelectInterface from './select.interface';
import Option from '../../../atoms/form/option/Option';
import FormItem from '../form-item/FormItem';
import Icon from '../../../atoms/icons/icon';
import Loader from '../../loader';

import styles from './Select.module.scss';

const Select: FC<SelectInterface> = React.forwardRef(
	(
		{
			options,
			loading,
			className,
			formItemClassName,
			useInternalState,
			id,
			value,
			visibleDisableStyles,
			disabled,
			onChange,
			minWidth,
			maxWidth,
			defaultValue,
			iconBefore = <Icon icon="chevron" className={styles.icon} />,
			emptyOption,
			...rest
		},
		ref,
	) => {
		const [selectValue, setSelectValue] = useState<string | number>('');

		const getIndent = useCallback(() => {
			const selectedOptionIndex = options.findIndex((item) => item.value === selectValue);

			if (selectedOptionIndex !== -1) {
				return options[selectedOptionIndex].indent;
			}
		}, [options, selectValue]);

		const inlineStyles = {
			...getMinMaxWidthStyles(minWidth, maxWidth),
			textIndent: `${
				getIndent() && navigator.userAgent.indexOf('Firefox') !== -1
					? `-${getIndent()! * 6}px`
					: getIndent()
					? `-${getIndent()! * 12}px`
					: 0
			}`,
		};

		useEffect(() => {
			if (defaultValue !== undefined) {
				setSelectValue(defaultValue as string);
			}
		}, [setSelectValue, defaultValue]);

		useEffect(() => {
			if (value !== undefined) {
				setSelectValue(value as string);
			}
		}, [setSelectValue, value]);

		const optionElements = useMemo(
			() =>
				options.map(({ text, disabled, ...rest }, index) => (
					<Option key={`${id}${index}`} disabled={text === '' || disabled} {...rest}>
						{text}
					</Option>
				)),
			[id, options],
		);

		return (
			<FormItem
				className={classNames(
					styles['form-item'],
					formItemClassName,
					disabled && styles.disabled,
					visibleDisableStyles && styles['visible-disable-styles'],
				)}
			>
				{loading && <Loader className={styles.loader} maxWidth={20} maxHeight={20} />}
				<select
					{...{ id, ref, disabled, ...rest }}
					style={inlineStyles}
					className={classNames(styles.select, className)}
					value={selectValue}
					onChange={(event) => {
						if (useInternalState || value === undefined) {
							setSelectValue(event.target.value);
						}

						if (onChange) {
							onChange(event);
						}
					}}
				>
					{emptyOption ? (
						<option value="" disabled>
							{emptyOption}
						</option>
					) : null}

					{optionElements}
				</select>

				{iconBefore}
			</FormItem>
		);
	},
);

export default React.memo(Select);
