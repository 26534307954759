import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './CandidateExperienceList.module.scss';
import CandidateExperienceListInterface from './candidate-experience-list.interface';
import ShowMoreStaticContent from '../../../organisms/show-more-static-content';
import { WorkHistoryType } from '../../../../store/slices/candidate.slice';

const CandidateExperienceList: FC<CandidateExperienceListInterface> = ({
	className,
	candidateWorkHistory,
	minHeight,
}) => {
	return (
		<div className={classNames(className, styles.wrapper)}>
			<ShowMoreStaticContent countItemsToDisplay={2} minHeight={minHeight || 80}>
				{candidateWorkHistory?.map(
					({
						id,
						jobTitle,
						companyName,
						stillWorks,
						startDate,
						endDate,
					}: WorkHistoryType) => {
						return (
							<div key={id} className={styles.item}>
								<strong>{jobTitle}</strong>,
								{` ${companyName} ${startDate} - ${
									stillWorks ? 'Present' : endDate
								}`}
							</div>
						);
					},
				)}
			</ShowMoreStaticContent>
		</div>
	);
};

export default CandidateExperienceList;
