import React, { FC, useCallback } from 'react';
import classNames from 'classnames';

import styles from './ScoreGauge.module.scss';
import ScoreGaugeInterface from './score-gauge.interface';

const ScoreGauge: FC<ScoreGaugeInterface> = ({ score, className, smallSize }) => {
	const getScoreDegree = useCallback(() => {
		if (score) {
			const degs = (180 / 100) * (score - 50);
			return `rotateZ(${degs}deg)`;
		}
	}, [score]);

	return (
		<div className={classNames(styles.arc, className, smallSize ? styles.small : '')}>
			<div
				className={styles.gauge}
				style={{ transform: getScoreDegree() }}
			></div>
		</div>
	);
};

export default ScoreGauge;
