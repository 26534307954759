import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Dispatch, SetStateAction } from 'react';
import { BoostItemInterface, JobInterface } from '../../../store/slices/job.slice';
import { JobStatusType } from '../common';
import { dateInMDYFormat } from '../datetime';

dayjs.extend(utc);

export interface GeneratedJobDescriptionInterface {
	uuid?: string;
	jobTitle: string;
	department: number;
	level: number;
	city: string;
	state: string;
	zip: string;
	companyName: string;
	companyUrl: string;
	companyKeywords: string;
	size?: string;
	summary?: string;
	mustHaveSkills?: string;
	niceToHaveSkills?: string;
	companyDescription?: string;
	deviceId?: string;
	generatedAt?: string;
	firstName?: string;
	lastName?: string;
	email?: string;
}

export interface JobFormModel {
	jobProfileId?: number | null;
	jobProfileLabel?: string;
	title?: string;
	employmentType?: number;
	numberOfOpenings?: number;
	facilityId?: number;
	facilityName?: string;
	locationType?: number;
	relocationAllowed?: boolean;
	minSalaryValue?: number | null;
	maxSalaryValue?: number | null;
	salaryUnit?: string;
	department?: number;
	departmentLabel?: string;
	departmentKey?: string;
	level?: number;

	workSeniority?: 0 | 2 | 5;
	cannabisSeniority?: 0 | 1 | 3;
	requireResume?: number;
	requireCover?: number;

	summary?: string;
	mustHaveSkills?: string;
	niceToHaveSkills?: string;
	companyDescription?: string;
	benefits?: string;
	eoe?: string;
}

export interface JobFormStep {
	formState: JobFormModel;
	setFormState: Dispatch<SetStateAction<JobFormModel>>;
	register?: any;
	trigger?: any;
	errors?: any;
	className?: string;
	brandingName?: string;
	isEditMode?: boolean;
}

export const JobFormCreateSteps = {
	profileType: 1,
	basics: 2,
	requirements: 3,
	jobDetails: 4,
	companyDetails: 5,
	jobPreview: 6,
};

export const JobFormCreateFromWidgetSteps = {
	basics: 1,
	requirements: 2,
	jobDetails: 3,
	companyDetails: 4,
	jobPreview: 5,
};

export const JobFormEditSteps = {
	basics: 1,
	requirements: 2,
	jobDetails: 3,
	companyDetails: 4,
};

export const jobModelNumericFields: string[] = [
	'jobProfileId',
	'facilityId',
	'minSalaryValue',
	'maxSalaryValue',
	'employmentType',
	'minScore',
	'numberOfOpenings',
	'requireCover',
	'requireResume',
	'workSeniority',
	'cannabisSeniority',
	'locationType',
	'department',
	'level',
];

export const transformJobData = (formState: JobFormModel): Partial<JobInterface> => {
	let body: Partial<JobInterface> = {};

	if (formState && jobModelNumericFields && body) {
		for (const key in formState) {
			const keyItem = key as keyof typeof formState;

			if (jobModelNumericFields.indexOf(key) !== -1 && formState[keyItem]) {
				body = {
					...body,
					[key]: Number(formState[keyItem]),
				};
			} else if (formState[keyItem] !== undefined) {
				body = {
					...body,
					[key]: formState[keyItem],
				};
			}
		}
	}

	return body;
};

export const checkJobIsBoosted = (
	boostId?: number,
	jobBoosts?: BoostItemInterface[],
	status?: number,
) => {
	if (!boostId || status === JobStatusType.inactive) return false;

	const currentBoost = jobBoosts?.find((boost) => boost.id === boostId);

	if (!currentBoost?.cancelledAt && !currentBoost?.endedAt) return true;

	const now = dayjs(dateInMDYFormat(Date.now()));
	const end = dateInMDYFormat(currentBoost?.cancelledAt || currentBoost?.endedAt);
	const differenceInSeconds = dayjs(end).diff(now, 'second');

	return differenceInSeconds >= 0;
};

export const checkIfJobCanBeBoosted = (job?: Partial<JobInterface>) => {
	if (!job) return false;

	const isAlreadyBoosted = checkJobIsBoosted(job.boostId, job.jobBoosts, job.status);

	return !job?.hasExternalApply && job?.status === JobStatusType.active && !isAlreadyBoosted;
};

export const checkIfBeforeDate = (checkDate: string, endDate: string) => {
	const end = dayjs(endDate);
	const dateToCheck = dayjs(checkDate);
	const differenceInSeconds = end.diff(dateToCheck, 'second');

	return differenceInSeconds >= 0;
};
