import React, { FC } from 'react';
import classNames from 'classnames';

import TypographyTextPreviewInterface from './typography-text-preview.interface';

import styles from './TypographyTextPreview.module.scss';

const TypographyTextPreview: FC<TypographyTextPreviewInterface> = React.forwardRef(
	({ className, text, ...rest }, ref) => {
		return (
			<span className={classNames(styles['typography-text-preview'], className)} {...rest}>
				<input className={styles.input} ref={ref} value={text} onChange={() => {}} />
			</span>
		);
	},
);

export default TypographyTextPreview;
