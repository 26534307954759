import React, { FC } from 'react';
import classNames from 'classnames';

import TileInterface from './tile.interface';

import styles from './Tile.module.scss';

const Tile: FC<TileInterface> = ({ className, children, ...rest }) => {
	return (
		<div className={classNames(styles.tile, className)} {...rest}>
			{children}
		</div>
	);
};

export default Tile;
