import { Dispatch } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import promotionJobLinksApi from '../../api/promotion-job-links.api';

export interface PromotionJobLinkInterface {
	name: string;
	link: string;
}

export interface promotionJobLinksSliceInterface {
	links?: PromotionJobLinkInterface[];
	loading: boolean;
}

const initialState: promotionJobLinksSliceInterface = {
	loading: false,
};

export const promotionJobLinksSlice = createSlice({
	name: 'jobs',
	initialState,
	reducers: {
		loadingPromotionJobLinksApi(state) {
			state.loading = true;
		},
		getPromotionJobLinksApiSuccess(
			state,
			{ payload }: { payload: PromotionJobLinkInterface[] },
		) {
			state.links = payload;
			state.loading = false;
		},
		getPromotionJobLinksApiFailed(state, action: PayloadAction<string>) {
			state.loading = false;
			console.log(action);
		},
	},
});

export const getPromotionJobLinks = (companyId: string) => async (dispatch: Dispatch<any>) => {
	dispatch(loadingPromotionJobLinksApi());

	try {
		const response = await promotionJobLinksApi.getJobPromotionLinks(companyId);
		dispatch(getPromotionJobLinksApiSuccess(response));
	} catch (err) {
		dispatch(getPromotionJobLinksApiFailed(err.toString()));
	}
};

export const createNewJobPromotionLink =
	(jobId: string, originTag: string) => async (dispatch: Dispatch<any>) => {
		dispatch(loadingPromotionJobLinksApi());

		try {
			await promotionJobLinksApi.createNewJobPromotionLink(jobId, originTag);

			dispatch(getPromotionJobLinks(jobId));
		} catch (err) {
			dispatch(getPromotionJobLinksApiFailed(err.toString()));
		}
	};

export const {
	getPromotionJobLinksApiSuccess,
	getPromotionJobLinksApiFailed,
	loadingPromotionJobLinksApi,
} = promotionJobLinksSlice.actions;
export default promotionJobLinksSlice.reducer;
