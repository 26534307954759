import React, { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';

import PromoteJobFormInterface from './promote-job-form.interface';
import InputWithFloatLabel from '../../form-elemets/input-with-float-label/InputWithFloatLabel';
import Button from '../../../atoms/buttons/button/Button';

import styles from './PromoteJobForm.module.scss';
import { createNewJobPromotionLink } from '../../../../store/slices/promotion-job-links.slice';
import ResizeDeviceContext from '../../../../contexts/resize-device-context';

const PromoteJobForm: FC<PromoteJobFormInterface> = ({ className, jobId, ...rest }) => {
	const dispatch = useDispatch();
	const { register, handleSubmit, errors } = useForm();

	const device = useContext(ResizeDeviceContext);

	const formSubmitHandler = ({ originTag }: { originTag?: string }) => {
		if (originTag && jobId) {
			dispatch(createNewJobPromotionLink(jobId, originTag));
		}
	};

	return (
		<form
			className={classNames(styles.form, className)}
			onSubmit={handleSubmit(formSubmitHandler)}
			{...rest}
		>
			<InputWithFloatLabel
				id="generate-promote-link"
				label="Source"
				ref={register({
					required: true,
				})}
				error={errors.originTag && '"Website link" field is required'}
				inputSettings={{
					name: 'originTag',
				}}
				minWidth={270}
				className={styles.input}
			/>

			<Button
				small
				reversedStyles
				minWidth={device !== 'desktop' ? 60 : 104}
				maxWidth={104}
				className={styles.btn}
			>
				Generate
			</Button>
		</form>
	);
};

export default PromoteJobForm;
