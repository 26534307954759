import OptionInterface from '../../../components/atoms/form/option/option.interface';

export const statesOptions: OptionInterface[] = [
	{
		text: 'AL',
		value: 'AL',
	},
	{
		text: 'AK',
		value: 'AK',
	},
	{
		text: 'AS',
		value: 'AS',
	},
	{
		text: 'AZ',
		value: 'AZ',
	},
	{
		text: 'AR',
		value: 'AR',
	},
	{
		text: 'CA',
		value: 'CA',
	},
	{
		text: 'CO',
		value: 'CO',
	},
	{
		text: 'CT',
		value: 'CT',
	},
	{
		text: 'DE',
		value: 'DE',
	},
	{
		text: 'DC',
		value: 'DC',
	},
	{
		text: 'FM',
		value: 'FM',
	},
	{
		text: 'FL',
		value: 'FL',
	},
	{
		text: 'GA',
		value: 'GA',
	},
	{
		text: 'GU',
		value: 'GU',
	},
	{
		text: 'HI',
		value: 'HI',
	},
	{
		text: 'ID',
		value: 'ID',
	},
	{
		text: 'IL',
		value: 'IL',
	},
	{
		text: 'IN',
		value: 'IN',
	},
	{
		text: 'IA',
		value: 'IA',
	},
	{
		text: 'KS',
		value: 'KS',
	},
	{
		text: 'KY',
		value: 'KY',
	},
	{
		text: 'LA',
		value: 'LA',
	},
	{
		text: 'ME',
		value: 'ME',
	},
	{
		text: 'MH',
		value: 'MH',
	},
	{
		text: 'MD',
		value: 'MD',
	},
	{
		text: 'MA',
		value: 'MA',
	},
	{
		text: 'MI',
		value: 'MI',
	},
	{
		text: 'MN',
		value: 'MN',
	},
	{
		text: 'MS',
		value: 'MS',
	},
	{
		text: 'MO',
		value: 'MO',
	},
	{
		text: 'MT',
		value: 'MT',
	},
	{
		text: 'NE',
		value: 'NE',
	},
	{
		text: 'NV',
		value: 'NV',
	},
	{
		text: 'NH',
		value: 'NH',
	},
	{
		text: 'NJ',
		value: 'NJ',
	},
	{
		text: 'NM',
		value: 'NM',
	},
	{
		text: 'NY',
		value: 'NY',
	},
	{
		text: 'NC',
		value: 'NC',
	},
	{
		text: 'ND',
		value: 'ND',
	},
	{
		text: 'MP',
		value: 'MP',
	},
	{
		text: 'OH',
		value: 'OH',
	},
	{
		text: 'OK',
		value: 'OK',
	},
	{
		text: 'OR',
		value: 'OR',
	},
	{
		text: 'PW',
		value: 'PW',
	},
	{
		text: 'PA',
		value: 'PA',
	},
	{
		text: 'PR',
		value: 'PR',
	},
	{
		text: 'RI',
		value: 'RI',
	},
	{
		text: 'SC',
		value: 'SC',
	},
	{
		text: 'SD',
		value: 'SD',
	},
	{
		text: 'TN',
		value: 'TN',
	},
	{
		text: 'TX',
		value: 'TX',
	},
	{
		text: 'UT',
		value: 'UT',
	},
	{
		text: 'VT',
		value: 'VT',
	},
	{
		text: 'VI',
		value: 'VI',
	},
	{
		text: 'VA',
		value: 'VA',
	},
	{
		text: 'WA',
		value: 'WA',
	},
	{
		text: 'WV',
		value: 'WV',
	},
	{
		text: 'WI',
		value: 'WI',
	},
	{
		text: 'WY',
		value: 'WY',
	},
];

export const titleOptions: OptionInterface[] = [
	{
		text: 'Admin',
		value: 'Admin',
	},
	{
		text: 'HR / Talent Professional',
		value: 'HR / Talent Professional',
	},
	{
		text: 'Manager / Head of Department',
		value: 'Manager / Head of Department',
	},
	{
		text: 'Director / VP',
		value: 'Director / VP',
	},
	{
		text: 'C-Level',
		value: 'C-Level',
	},
	{
		text: 'Business Owner',
		value: 'Business Owner',
	},
	{
		text: 'Recruiting / Staffing Firm',
		value: 'Recruiting / Staffing Firm',
	},
	{
		text: 'Other',
		value: 'Other',
	},
];

export const hiringNeedsOptions: OptionInterface[] = [
	{ value: 1, text: '1 to 2 jobs' },
	{ value: 2, text: '2 to 5 jobs' },
	{ value: 5, text: '5 to 10 jobs' },
	{ value: 10, text: '10+ jobs' },
];

export const hasATSOptions: OptionInterface[] = [
	{ value: 1, text: 'Yes' },
	{ value: -1, text: 'No' },
	{ value: 0, text: "I don't know" },
];

export const referralOptions: OptionInterface[] = [
	{ value: 1, text: 'Google / Search Engine' },
	{ value: 2, text: 'LinkedIn' },
	{ value: 3, text: 'YouTube' },
	{ value: 4, text: 'Podcast' },
	{ value: 7, text: 'Green Flower / GFI' },
	{ value: 5, text: 'FlowerHire' },
	{ value: 6, text: 'Email' },
	{ value: 0, text: 'Other' },
];

export const generateNumberOptions = (optionsNumber: number) => {
	return Array.from(
		new Array(optionsNumber),
		(x, i) =>
			({
				text: (i + 1).toString(),
				value: i + 1,
			} as OptionInterface),
	);
};
