import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { StorageItemKeys } from '../../constants';
import StorageClearHocInterface from './storageClearHoc.interface';
import { APP_URLS } from '../../routes/routes';

const StorageClearHoc: FC<StorageClearHocInterface> = ({ children }) => {
	const location = useLocation();

	useEffect(() => {
		const jobPattern = /\/jobs\/\d+/;
		const isJobPage = location?.pathname.match(jobPattern);

		if (
			!location?.pathname.includes('/boost-job/') &&
			!isJobPage &&
			!location?.pathname.includes(APP_URLS.addCredits)
		) {
			if (sessionStorage.getItem(StorageItemKeys.boostOpenings))
				sessionStorage.removeItem(StorageItemKeys.boostOpenings);
			if (sessionStorage.getItem(StorageItemKeys.boostCredits))
				sessionStorage.removeItem(StorageItemKeys.boostCredits);
		}
	}, [location?.pathname]);

	return <>{children}</>;
};

export default StorageClearHoc;
