import { Dispatch } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import candidateListApi from '../../api/candidates.api';

type AnswerType = {
	questionId: string;
	category: string;
	text: string;
	value: boolean | string | number;
	type: string;
	group?: string;
	groupLabel?: string;
	values: any[];
	valueLabels: string[];
	summaryText: string;
	summaryAnswer: string;
	step: string;
	section?: string;
};

export interface WorkHistoryType {
	id: string;
	jobTitle: string;
	companyName: string;
	stillWorks: boolean;
	startDate: string;
	endDate: string | null;
}

export interface BadgeTemplateInterface {
	id: number;
	imageUrl?: string;
	name: string;
	organization?: string;
	source?: string;
}

export interface BadgeInterface {
	id: number;
	state?: string;
	expiresAt?: number;
    issuedAt?: number;
	badgeUrl?: string;
	badgeTemplate: BadgeTemplateInterface;
}

export interface ViewInterface {
	firstName: string;
	lastName: string;
	createdAt: number;
}

export interface CandidateInterface {
	id: number | string;
	userId: string | number;
	score: number;
	hardScore: number;
	softScore: number;
	commuteScore: number;
	scoreClass: string;
	hardScoreClass: string;
	softScoreClass: string;
	commuteScoreClass: string;
	cover: string;
	status: number;
	notes: string;
	email: string;
	firstName: string;
	lastName: string;
	gender?: string;
	city: string;
	state: string;
	zip: string;
	country: string;
	phoneCountryCode: number;
	phoneNumber: string;
	degree: number;
	age21: boolean;
	hiredInWurk: boolean;
	originTag: string;
	personality?: string;
	createdAt: number;
	linkedInUrl: string;
	authorizedNoSponsorship: boolean;
	resumeUrl: string;
	convertedResumeUrl?: string;
	statusLabel: string;
	degreeLabel: string;
	longitude: number | null;
	latitude: number | null;
	answers: AnswerType[];
	workHistory: WorkHistoryType[];
	badges?: BadgeInterface[];
	views?: ViewInterface[];
}

export interface CandidateSliceInterface {
	info?: CandidateInterface;
	loading: boolean;
}

const initialState: CandidateSliceInterface = {
	loading: false,
};

export const candidateListSlice = createSlice({
	name: 'candidate',
	initialState,
	reducers: {
		loading(state) {
			state.loading = true;
		},
		getCandidateSuccess(state, { payload }: any) {
			state.info = payload;
			state.loading = false;
		},
		changeCandidateStatusSuccess(
			state,
			{
				payload: { status, statusLabel },
			}: PayloadAction<Pick<CandidateInterface, 'status' | 'statusLabel'>>,
		) {
			if (state?.info?.status !== undefined) {
				state.info = {
					...state?.info,
					status,
					statusLabel,
				};
			}
		},
		getCandidateFailed(state, action: PayloadAction<string>) {
			state.loading = false;
			console.log(action);
		},
		clearCandidate(state) {
			state.info = undefined;
		},
	},
});

export const getCandidate = (candidateId: string | number) => async (dispatch: Dispatch<any>) => {
	dispatch(loading());

	try {
		const response = await candidateListApi.getCandidate(candidateId);

		dispatch(getCandidateSuccess(response));
	} catch (err) {
		dispatch(getCandidateFailed(err.toString()));
	}
};

export const getCandidatesByIds =
	(candidateIds: string[] | number[]) => async () => {
		try {
			const result = [];

			for (let i = 0; i < candidateIds.length; i++) {
				const response = await candidateListApi.getCandidate(candidateIds[i]);
				if (response) result.push(response);
			}

			return result;
		} catch (err) {
			return err;
		}
	};

export const {
	loading,
	getCandidateSuccess,
	getCandidateFailed,
	clearCandidate,
	changeCandidateStatusSuccess,
} = candidateListSlice.actions;
export default candidateListSlice.reducer;
