export const setSpecificPagination = (page: number, dispatchPagination: React.Dispatch<any>) => {
	dispatchPagination({
		type: 'SET_PAGINATION_NUMBER',
		payload: {
			pagination: page,
		},
	});
};

export type PaginationType = {
	pageNumber?: number;
	pageSize?: number;
	orderBy?: number;
};
