import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { JobTemplateInterface } from '../../../../store/slices/jobs-templates.slice';
import JobForm from '../job-form';
import NestedPopupHeader from '../../popup-headers/nested-popup-header/NestedPopupHeader';

import styles from './CreateJob.module.scss';
import ConfirmationPopup from '../confirmation-popup';
import { useLocation, useNavigate } from 'react-router-dom';
import { JobFormModel } from '../../../../helpers/custom/job';
import {
	BrandingNames,
	BrandingSettings,
	LocationRequirementsValues,
	QueryParametersKey,
} from '../../../../helpers/constants';
import useActiveCompany from '../../../../helpers/hooks/use-active-company/useActiveCompany';
import { clearErrors } from '../../../../store/slices/job.slice';
import useJobsTemplates from '../../../../helpers/hooks/use-jobs-templates/useJobsTemplates';
import useCompanies from '../../../../helpers/hooks/use-companies/useCompanies';
import { APP_URLS } from '../../../../helpers/routes/routes';
import CreateJobInterface from './create-job.interface';
import { checkJobIsWithPaymentByCompany } from '../../../../helpers/custom/common';
import { cancelGA4Event } from '../../../../helpers/google-analytics-4/events';
import {
	GAContentGroup,
	GAContentId,
	GAContentType,
	GAMethod,
} from '../../../../helpers/google-analytics-4/index.constants';
import { getCompanyDataForGA } from '../../../../helpers/google-analytics-4/helpers';
import useJob from '../../../../helpers/hooks/use-job/useJob';

const CreateJob: FC<CreateJobInterface> = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const isPostAJobFlow = useMemo(
		() => !!new URLSearchParams(location.search).get(QueryParametersKey.postAJob),
		[location.search],
	);

	const { items } = useJobsTemplates();
	const { activeCompanyId, companies } = useCompanies();
	const activeCompany = useActiveCompany(activeCompanyId, companies);
	const { createdJobId } = useJob();

	const [jobTitle, setJobTitle] = useState('');
	const [jobProfile, setJobProfile] = useState<number | undefined | null>(null);

	const [openModal, setOpenModal] = useState(false);
	const [closureConfirmed, setClosureConfirmed] = useState(false);
	const [brandingConfig, setBrandingConfig] = useState(BrandingSettings.cinc);
	const [brandingName, setBrandingName] = useState('');

	const isJobWithPayment = useMemo(
		() => checkJobIsWithPaymentByCompany(activeCompany),
		[activeCompany],
	);

	const isPostAJobBrandedFlow = useMemo(
		() => !!(isJobWithPayment && isPostAJobFlow),
		[isJobWithPayment, isPostAJobFlow],
	);

	const {
		title,
		summary,
		mustHaveSkills,
		niceToHaveSkills,
		department,
		level,
	}: JobTemplateInterface = useMemo(
		() =>
			(jobProfile &&
				items &&
				items.find(({ id }: { id: number | string }) => id === jobProfile)) ||
			{},
		[items, jobProfile],
	);

	const [createFormState, setCreateFormState] = useState<JobFormModel>({
		jobProfileId: null,
		title,
		summary,
		mustHaveSkills,
		niceToHaveSkills,
		minSalaryValue: null,
		maxSalaryValue: null,
		salaryUnit: 'hourly',
		locationType: LocationRequirementsValues.onSite,
		companyDescription: activeCompany?.description,
		benefits: activeCompany?.benefits,
		eoe: activeCompany?.eoe,

		requireCover: 0,
		requireResume: 3,
		workSeniority: 0,
		relocationAllowed: false,
		cannabisSeniority: 0,

		department,
		level,
	});

	const navigateToJobs = useCallback(() => {
		navigate(APP_URLS.jobs);
	}, [navigate]);

	const onCloseHandler = useCallback(() => {
		if (!createdJobId) setOpenModal(true);
		else navigateToJobs();
	}, [createdJobId, navigateToJobs]);

	const renderHeader = useMemo(() => {
		if (isPostAJobBrandedFlow)
			return (
				<NestedPopupHeader
					titleLogo={brandingConfig.logo}
					backgroundColor={brandingConfig.color}
					withoutLogo
					withoutClose={!createdJobId}
					close={navigateToJobs}
				/>
			);

		return (
			<NestedPopupHeader
				title={
					createFormState?.jobProfileId === -1
						? 'Create custom job listing'
						: `Create job${createFormState?.jobProfileId ? `: ${jobTitle}` : ''}`
				}
				backgroundColor={brandingConfig.color}
				withoutLogo
				close={onCloseHandler}
			/>
		);
	}, [
		isPostAJobBrandedFlow,
		brandingConfig.logo,
		brandingConfig.color,
		createdJobId,
		navigateToJobs,
		createFormState?.jobProfileId,
		jobTitle,
		onCloseHandler,
	]);

	const renderForm = useMemo(() => {
		return (
			<JobForm
				context="create"
				hasPowerProfile={
					!!(createFormState?.jobProfileId && createFormState?.jobProfileId !== -1)
				}
				title={jobTitle}
				setJobTitle={setJobTitle}
				formState={createFormState}
				setFormState={setCreateFormState}
				onFormLeave={onCloseHandler}
				isJobWithPayment={isJobWithPayment}
				brandingName={brandingName}
			/>
		);
	}, [createFormState, jobTitle, onCloseHandler, isJobWithPayment, brandingName]);

	const renderPopup = useMemo(() => {
		return (
			<ConfirmationPopup
				title="Cancel new job?"
				description={
					"Are you sure you want to cancel your new job? You'll lose all the job details you've added."
				}
				popupWidth={364}
				backButtonText="No, I want to continue"
				confirmButtonText={'Yes, cancel new job'}
				open={openModal}
				openStateHandler={setOpenModal}
				onConfirmAndClose={() => {
					if (activeCompany) {
						cancelGA4Event(
							{
								companyData: getCompanyDataForGA(activeCompany),
								contentData: {
									id: brandingName ? GAContentId.postAJob : GAContentId.job,
									group: brandingName
										? GAContentGroup.postAJob
										: GAContentGroup.job,
									type: GAContentType.create,
								},
							},
							{
								method: GAMethod.popup,
							},
						);
					}

					dispatch(clearErrors());
					setClosureConfirmed(true);
				}}
			/>
		);
	}, [activeCompany, brandingName, dispatch, openModal]);

	useEffect(() => {
		if (isPostAJobBrandedFlow) {
			const queryParams = new URLSearchParams(location.search);
			const brandingName = queryParams.get(QueryParametersKey.branding);

			if (brandingName) {
				setBrandingConfig(BrandingSettings[brandingName as keyof typeof BrandingSettings]);
				setBrandingName(BrandingNames[brandingName as keyof typeof BrandingNames]);
			} else {
				setBrandingName(BrandingNames.cinc);
			}
		}
	}, [isPostAJobBrandedFlow, location.search]);

	useEffect(() => {
		if (createFormState?.jobProfileId) setJobProfile(createFormState?.jobProfileId);
	}, [createFormState?.jobProfileId]);

	useEffect(() => {
		if (!jobTitle) setJobTitle(title || '');
	}, [jobTitle, title]);

	useEffect(() => window.scrollTo(0, 0), []);

	useEffect(() => {
		if (closureConfirmed) return navigate(APP_URLS.jobs);
	}, [closureConfirmed, navigate]);

	return (
		<section className={styles['create-job']}>
			{renderHeader}

			{renderForm}

			{renderPopup}
		</section>
	);
};

export default React.memo(CreateJob);
