import { CompanyInterface } from '../../store/slices/companies.slice';
import { JobInterface } from '../../store/slices/job.slice';
import { JobCategories } from '../custom/common';
import { JobDataInterface } from './index.interface';

export const getJobCategory = (
	isEnginJob?: boolean,
	hasExternalApply?: boolean,
	companyId?: string,
) => {
	if (isEnginJob === undefined) return '';
	if (isEnginJob && companyId === 'careersincannabis') return JobCategories.prospective;

	return hasExternalApply ? JobCategories.customerExternal : JobCategories.customerInternal;
};

export const getJobDataForGA = (jobInfo?: Partial<JobInterface>) => {
	return {
		jobId: jobInfo?.id || '',
		jobCategory: getJobCategory(
			jobInfo?.isEnginJob,
			jobInfo?.hasExternalApply,
			jobInfo?.facility?.company?.id,
		),
		jobProfile: jobInfo?.jobProfileUniqueName,
		jobState: jobInfo?.facility?.state,
		jobDepartment:
			jobInfo?.department !== 0 && jobInfo?.departmentKey ? jobInfo?.departmentKey : 'other',
		jobLevel: jobInfo?.level !== 0 && jobInfo?.levelKey ? jobInfo?.levelKey : 'other',
	} as JobDataInterface;
};

export const getCompanyDataForGA = (activeCompany?: Partial<CompanyInterface>) => {
	const companyType = activeCompany?.plan?.uniqueName;

	return {
		companyId: activeCompany?.id || '',
		companyName: activeCompany?.name || '',
		...(companyType && { companyType }),
	};
};
