import React, { FC, useCallback, useEffect, useState } from 'react';

import LogoutButton from '../logout-button';
import ChangeCompany from '../../../organisms/custom/change-company';
import { Link, useLocation } from 'react-router-dom';
import styles from './AccountMenu.module.scss';
import AccountMenuInterfaceInterface from './account-menu.interface';
import { addCreditsIntentGA4Event } from '../../../../helpers/google-analytics-4/events';
import useCompanies from '../../../../helpers/hooks/use-companies/useCompanies';
import {
	getCompanyDataForGA,
	getJobDataForGA,
} from '../../../../helpers/google-analytics-4/helpers';
import useActiveCompany from '../../../../helpers/hooks/use-active-company/useActiveCompany';
import { getContentDataForGA } from '../../../../helpers/custom/common';
import useJob from '../../../../helpers/hooks/use-job/useJob';
import { GAMethod } from '../../../../helpers/google-analytics-4/index.constants';
import { APP_URLS } from '../../../../helpers/routes/routes';
import { COMPANIES_DROPDOWN_MAX_NUMBER, StorageItemKeys } from '../../../../helpers/constants';

const AccountMenu: FC<AccountMenuInterfaceInterface> = ({
	hideSparkCredits,
	dropdownOpenState,
	setDropdownOpenState,
}) => {
	const location = useLocation();
	const { activeCompanyId, companies } = useCompanies();
	const activeCompany = useActiveCompany(activeCompanyId, companies);
	const { info } = useJob();

	const [openCompaniesList, setOpenCompaniesList] = useState(false);

	const triggerCreditsIntentGAEvent = useCallback(() => {
		addCreditsIntentGA4Event(
			{
				...(info && info?.id ? { jobData: getJobDataForGA(info) } : {}),
				companyData: getCompanyDataForGA(activeCompany),
				contentData: getContentDataForGA(location),
			},
			{
				method: GAMethod.header,
			},
		);
	}, [activeCompany, info, location]);

	const toggleOpenCompaniesList = useCallback(() => {
		setOpenCompaniesList((prevState) => !prevState);
	}, []);

	useEffect(() => {
		if (companies && companies.length > COMPANIES_DROPDOWN_MAX_NUMBER) {
			const recentCompanies = localStorage.getItem(StorageItemKeys.recentCompanies);

			if (!recentCompanies && activeCompanyId)
				localStorage.setItem(StorageItemKeys.recentCompanies, `${activeCompanyId}`);
		}
	}, [activeCompanyId, companies]);

	useEffect(() => {
		if (!dropdownOpenState) setOpenCompaniesList(false);
	}, [dropdownOpenState, toggleOpenCompaniesList]);

	return (
		<>
			{openCompaniesList ? (
				<ChangeCompany
					setDropdownOpenState={setDropdownOpenState}
					toggleOpenCompaniesList={toggleOpenCompaniesList}
				/>
			) : (
				<>
					{!hideSparkCredits ? (
						<Link
							className={styles.link}
							to={APP_URLS.addCredits}
							state={{ hasHistoryBackContext: true }}
							onClick={triggerCreditsIntentGAEvent}
						>
							Add credits
						</Link>
					) : null}

					{companies && companies.length > 1 ? (
						<button
							type="button"
							className={styles.btn}
							onClick={toggleOpenCompaniesList}
						>
							Change company
						</button>
					) : null}

					<LogoutButton className={styles.logout} />
				</>
			)}
		</>
	);
};

export default React.memo(AccountMenu);
