import React, { FC, useMemo } from 'react';
import classNames from 'classnames';

import { getMinMaxHeightStyles, getMinMaxWidthStyles } from '../../../helpers';
import AuthorAvatarInterface from './author-avatar.interface';

import styles from './AuthorAvatar.module.scss';

const AuthorAvatar: FC<AuthorAvatarInterface> = ({
	text,
	minWidth = 36,
	maxWidth = minWidth,
	minHeight = 36,
	maxHeight = minHeight,
	color = '#70C9C2',
	className,
	...rest
}) => {
	const stylesInline = useMemo(
		() => ({
			...getMinMaxWidthStyles(minWidth, maxWidth),
			...getMinMaxHeightStyles(minHeight, maxHeight),
			backgroundColor: color,
		}),
		[minHeight, maxHeight, minWidth, maxWidth, color],
	);

	return (
		<div style={stylesInline} className={classNames(styles.wrap, className)} {...rest}>
			{text}
		</div>
	);
};

export default AuthorAvatar;
