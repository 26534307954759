import React, { FC } from 'react';
import classNames from 'classnames';

import NoteListInterface from './note-list.interface';
import Title from '../../../atoms/title/Title';

import styles from './NoteList.module.scss';

const NoteList: FC<NoteListInterface> = ({ children, title = 'All notes', className, ...rest }) => {
	return (
		<ul className={classNames(styles.wrap, className)} {...rest}>
			{title && (
				<Title component="h2" fontSize={20} fontWeight={600} marginBottom={27}>
					{title}
				</Title>
			)}

			{children}
		</ul>
	);
};

export default NoteList;
