import React, { FC, useMemo } from 'react';

import { getMinMaxWidthStyles } from '../../../helpers';
import Title from '../../atoms/title/Title';
import EmptyStateInterface from './empty-state.interface';
import TypographyText from '../../atoms/ui/typography-text';

import styles from './EmptyState.module.scss';
import classNames from 'classnames';

const EmptyState: FC<EmptyStateInterface> = ({
	title,
	description,
	minWidth,
	maxWidth,
	titleClassName,
	descriptionClassName,
}) => {
	const inlineStyles = useMemo(
		() => ({
			...getMinMaxWidthStyles(minWidth, maxWidth),
		}),
		[minWidth, maxWidth],
	);

	return (
		<div style={inlineStyles} className={styles.wrap}>
			{title && (
				<Title
					component="h3"
					marginBottom={16}
					className={classNames(styles.title, titleClassName)}
					fontWeight={900}
				>
					{title}
				</Title>
			)}

			<TypographyText className={classNames(styles.desc, descriptionClassName)}>
				{description}
			</TypographyText>
		</div>
	);
};

export default EmptyState;
