import { useSelector } from 'react-redux';
import { JobsListSliceInterface } from '../../../store/slices/jobs.slice';

export default function useJobs() {
	const {
		jobs: { items: jobsItems, loading: jobsLoading, deleteJobLoadingIds },
	} = useSelector((store: { jobs: JobsListSliceInterface }) => store);

	return {
		jobsLoading,
		jobsItems,
		deleteJobLoadingIds,
	};
}
