import React, { FC } from 'react';

import ViewSelectInterface from './view-select.interface';
import Select from '../../form/select/Select';

import styles from './ViewSelect.module.scss';

const ViewSelectFilter: FC<ViewSelectInterface> = ({ minWidth = 158, maxWidth = 158, ...rest }) => {
	return <Select className={styles.select} minWidth={minWidth} maxWidth={maxWidth} {...rest} />;
};

export default ViewSelectFilter;
