import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
	LineChart,
	Line,
	CartesianGrid,
	XAxis,
	YAxis,
	Tooltip,
	ReferenceArea,
	ResponsiveContainer,
	ReferenceLine,
} from 'recharts';

import styles from './JobApplicantsChart.module.scss';
import JobApplicantsChartInterface from './job-applicants-chart.interface';
import {
	dateInMDYFormat,
	getDatesInRange,
	getPeriodInDays,
} from '../../../../helpers/custom/datetime';
import useJob from '../../../../helpers/hooks/use-job/useJob';
import { JobAnalyticsDataInterface } from '../../../../store/slices/job.slice';
import dayjs from 'dayjs';
import {
	ChartDotPropsInterface,
	JobChartBoostStatus,
	formatStringAsAbsDecimal,
} from '../../../../helpers/custom/common';
import classNames from 'classnames';
import { checkIfBeforeDate } from '../../../../helpers/custom/job';
import { BoostItemInterface } from '../../../../store/slices/job.slice';
import { calculateAcquiredApplicants } from '../../../../helpers/custom/calculations';
import {
	checkIfAreaInfoBox,
	checkIfReferenceArea,
	hideInfoBox,
	showInfoBox,
} from '../../../../helpers/custom/charts';
import useDevice from '../../../../helpers/hooks/use-device';

const JobApplicantsChart: FC<JobApplicantsChartInterface> = ({ analyticsData }) => {
	const {
		info: { jobBoosts, publishedAt },
	} = useJob();

	const device = useDevice();

	const gridLinesColor = '#f0f0f0';
	const lineColor = '#6DD692';
	const dotsColor = '#EE846D';
	const areaColor = '#70C9C2';
	const tickTextColor = '#A3A3A3';

	const [areaBoxDefaultHtml, setAreaBoxDefaultHtml] = useState('');
	const [dotBoxDefaultHtml, setDotBoxDefaultHtml] = useState('');

	const areaTooltipRef = useRef<HTMLDivElement | null>(null);
	const dotTooltipRef = useRef<HTMLDivElement | null>(null);

	const nowFromattedDate = useMemo(() => dateInMDYFormat(Date.now()), []);

	const boosts = useMemo(() => {
		if (jobBoosts) {
			const sortedBoosts = [...jobBoosts].sort(
				(boost1: BoostItemInterface, boost2: BoostItemInterface) => boost1.id - boost2.id,
			);

			return sortedBoosts?.map((boostItem: BoostItemInterface) => {
				const { id, startedAt, endedAt, cancelledAt, credits, numberOfOpenings } =
					boostItem;

				return {
					id,
					startedAt: dateInMDYFormat(startedAt),
					...(endedAt && { endedAt: dateInMDYFormat(endedAt) }),
					...(cancelledAt && { cancelledAt: dateInMDYFormat(cancelledAt) }),
					credits,
					numberOfOpenings,
				};
			});
		}
	}, [jobBoosts]);

	const firstChartDate = useMemo(() => {
		const latestPublishDate = dateInMDYFormat(publishedAt);
		const firstApplicantDate = dateInMDYFormat(analyticsData[0]?.date);
		const firstBoostDate = dateInMDYFormat(boosts?.[0]?.startedAt);

		const allDates = [latestPublishDate, firstApplicantDate, firstBoostDate].filter(
			(date) => date,
		);
		const sortedDates = allDates.sort((date1: string, date2: string) => {
			return Date.parse(date1) - Date.parse(date2);
		});

		return sortedDates[0];
	}, [analyticsData, boosts, publishedAt]);

	const jobBoostsDates = useMemo(() => {
		const dates: string[] = [];

		if (boosts?.length) {
			for (let i = 0; i < boosts.length; i++) {
				const { startedAt, endedAt, cancelledAt } = boosts[i];

				let actualEnd = nowFromattedDate;

				if (cancelledAt) actualEnd = cancelledAt;
				else if (endedAt) {
					const endIsBeforeNow = checkIfBeforeDate(endedAt, nowFromattedDate);

					actualEnd = endIsBeforeNow ? endedAt : nowFromattedDate;
				}

				if (!dates.find((item) => item === startedAt)) dates.push(startedAt);
				if (!dates.find((item) => item === actualEnd)) dates.push(actualEnd);
			}
		}

		return dates;
	}, [boosts, nowFromattedDate]);

	const getRelevantDatesForChart = useMemo(() => {
		if (firstChartDate === nowFromattedDate) return [firstChartDate];

		const dates: string[] = [];

		dates.push(firstChartDate);

		if (jobBoostsDates?.length) {
			for (let i = 0; i < jobBoostsDates.length; i++) {
				if (!dates.find((item) => item === jobBoostsDates[i]))
					dates.push(jobBoostsDates[i]);
			}
		}

		if (!dates.find((item) => item === nowFromattedDate)) dates.push(nowFromattedDate);

		return dates;
	}, [firstChartDate, jobBoostsDates, nowFromattedDate]);

	const getChartTicks = useMemo(() => {
		if (firstChartDate === nowFromattedDate) return [firstChartDate];
		return [firstChartDate, nowFromattedDate];
	}, [firstChartDate, nowFromattedDate]);

	const generateApplicantsData = useMemo(() => {
		const result: JobAnalyticsDataInterface[] = [];

		if (getRelevantDatesForChart.length === 1) {
			const date = getRelevantDatesForChart[0];

			let applicantsTillDate = 0;

			analyticsData.forEach((dataItem: JobAnalyticsDataInterface) => {
				if (checkIfBeforeDate(dataItem.date, date)) {
					applicantsTillDate += Number(dataItem.numberOfApplications);
				}
			});

			result.push({
				date: date,
				numberOfApplications: applicantsTillDate || 0,
			});

			// for having visually 1 dot on chart, the second one is hidden
			result.push({
				date: dayjs(getRelevantDatesForChart[1]).add(1, 'day').format('M/D/YYYY'),
				numberOfApplications: NaN, // for not showing this point on the chart
			});
		}

		if (getRelevantDatesForChart.length > 1) {
			const lastChartDate = nowFromattedDate;

			const allPeriodRangeDates = getDatesInRange(firstChartDate, lastChartDate);

			allPeriodRangeDates.push(firstChartDate);
			allPeriodRangeDates.push(lastChartDate);

			allPeriodRangeDates?.forEach((date) => {
				let applicantsTillDate = 0;

				analyticsData.forEach((dataItem: JobAnalyticsDataInterface) => {
					if (checkIfBeforeDate(dataItem.date, date)) {
						applicantsTillDate += Number(dataItem.numberOfApplications);
					}
				});

				result.push({
					date: date,
					numberOfApplications: applicantsTillDate,
				});
			});
		}

		const sortedByDateResult = [...result].sort(
			(item1: JobAnalyticsDataInterface, item2: JobAnalyticsDataInterface) => {
				return new Date(item1.date).getTime() - new Date(item2.date).getTime();
			},
		);

		return sortedByDateResult;
	}, [analyticsData, firstChartDate, getRelevantDatesForChart, nowFromattedDate]);

	const referenceLineData = useMemo(() => {
		return boosts
			?.filter(
				(boost) =>
					boost.startedAt === boost.cancelledAt ||
					boost.startedAt === boost.endedAt ||
					boost.startedAt === nowFromattedDate,
			)
			.map((boostItem) => {
				const isBoostActive =
					(!boostItem.cancelledAt && !boostItem.endedAt) ||
					(!boostItem.cancelledAt &&
						boostItem.endedAt &&
						checkIfBeforeDate(nowFromattedDate, boostItem.endedAt));

				return {
					x: boostItem.startedAt,
					status: isBoostActive ? JobChartBoostStatus.active : JobChartBoostStatus.ended,
					credits: `${formatStringAsAbsDecimal(boostItem.credits)}`,
					period: '1 day',
					startDate: boostItem.startedAt,
					endDate: boostItem.cancelledAt || boostItem.endedAt,
					numberOfApplicants: calculateAcquiredApplicants(
						analyticsData,
						boostItem.startedAt,
						boostItem.startedAt,
					),
				};
			});
	}, [analyticsData, boosts, nowFromattedDate]);

	const referenceAreasData = useMemo(() => {
		const ticks = getChartTicks;

		const checkAndReturnBoostEnd = (
			boostEndedAt?: string | null,
			boostCancelledAt?: string | null,
		) => {
			if (ticks) {
				const lastDate = dayjs(ticks[ticks.length - 1]);

				const end = dayjs(boostCancelledAt || boostEndedAt || nowFromattedDate);
				const differenceInSeconds = end.diff(lastDate, 'second');

				if (differenceInSeconds > 0) return lastDate.format('M/D/YYYY');

				return end.format('M/D/YYYY');
			}
		};

		return boosts?.map((boostItem) => {
			let endDate: string | undefined = nowFromattedDate;

			if (boostItem.endedAt || boostItem.cancelledAt) {
				endDate = checkAndReturnBoostEnd(boostItem.endedAt, boostItem.cancelledAt);
			}

			const isBoostActive = !!(
				(!boostItem.cancelledAt && !boostItem.endedAt) ||
				(!boostItem.cancelledAt &&
					boostItem.endedAt &&
					checkIfBeforeDate(nowFromattedDate, boostItem.endedAt))
			);

			const getPeriod = (isBoostActive: boolean, startedAt: string, endedAt?: string) => {
				const numberOfDays = isBoostActive
					? getPeriodInDays(startedAt)
					: getPeriodInDays(startedAt, endedAt);

				if (numberOfDays !== undefined)
					return `${numberOfDays === 0 ? '1' : numberOfDays} day${
						numberOfDays <= 1 ? '' : 's'
					}`;
			};

			return {
				x1: boostItem.startedAt,
				x2: endDate,
				status: isBoostActive ? JobChartBoostStatus.active : JobChartBoostStatus.ended,
				credits: `${formatStringAsAbsDecimal(boostItem.credits)}`,
				period: getPeriod(isBoostActive, boostItem.startedAt, endDate),
				startDate: boostItem.startedAt,
				endDate: endDate,
				numberOfApplicants: calculateAcquiredApplicants(
					analyticsData,
					boostItem.startedAt,
					endDate,
				),
			};
		});
	}, [getChartTicks, boosts, nowFromattedDate, analyticsData]);

	const onMouseEnterDot = useCallback(
		(e?: React.MouseEvent, payload?: { date: string; numberOfApplications: number }) => {
			const { date = '', numberOfApplications = 0 } = payload || {};

			if (dotTooltipRef?.current) {
				const newHtml = dotBoxDefaultHtml
					?.replace('--date--', date)
					.replace('--applicantsNumber--', `${numberOfApplications}`)
					.replace(
						'--applicationsWordForm--',
						numberOfApplications === 1 ? 'application' : 'applications',
					);

				dotTooltipRef.current.innerHTML = newHtml;
			}

			showInfoBox(e, dotTooltipRef, device);
		},
		[dotBoxDefaultHtml, device],
	);

	const onMouseEnterArea = useCallback(
		(
			e?: React.MouseEvent,
			boostParams?: {
				status?: string;
				credits?: string;
				period?: string;
				startDate?: string;
				endDate?: string;
				numberOfApplicants?: number;
			},
		) => {
			if (checkIfAreaInfoBox(e, 'ref-area-info-box')) return;

			if (areaTooltipRef?.current) {
				const { period, credits, numberOfApplicants, startDate, endDate, status } =
					boostParams || {};

				const newHtml = areaBoxDefaultHtml
					?.replace('--period--', period || '')
					.replace('--status--', `${status}` || '')
					.replace('--credits--', `${credits}` || '')
					.replace('--applicants--', `${numberOfApplicants}` || '')
					.replace(
						'--startDate--',
						status === JobChartBoostStatus.active ? 'Start date: ' + startDate : '',
					)
					.replace(
						'--endDate--',
						status === JobChartBoostStatus.ended ? 'End date: ' + endDate : '',
					);

				areaTooltipRef.current.innerHTML = newHtml;
			}

			showInfoBox(e, areaTooltipRef, device);
		},
		[areaBoxDefaultHtml, device],
	);

	const onMouseLeaveArea = useCallback((e: React.MouseEvent) => {
		if (checkIfAreaInfoBox(e, 'ref-area-info-box')) return;

		hideInfoBox(areaTooltipRef);
	}, []);

	const onMouseLeaveReferenceAreaInfoBox = useCallback((e: React.MouseEvent) => {
		if (checkIfReferenceArea(e, 'boost-reference-area')) return;

		hideInfoBox(areaTooltipRef);
	}, []);

	const onMouseLeaveDot = useCallback(() => hideInfoBox(dotTooltipRef), []);

	const CustomizedChartDot = useCallback(
		(props: ChartDotPropsInterface) => {
			const { cx, cy, payload } = props || {};

			if (
				payload.date &&
				(jobBoostsDates.includes(payload.date) ||
					(getRelevantDatesForChart.length === 1 &&
						getRelevantDatesForChart.includes(payload.date)))
			) {
				return (
					<circle
						style={{ cursor: 'pointer' }}
						cx={cx}
						cy={cy}
						r="4"
						stroke={'transparent'}
						strokeWidth={24}
						fill={dotsColor}
						onMouseEnter={(e: React.MouseEvent) => onMouseEnterDot(e, payload)}
						onMouseLeave={onMouseLeaveDot}
					/>
				);
			}

			return null;
		},
		[getRelevantDatesForChart, jobBoostsDates, onMouseEnterDot, onMouseLeaveDot],
	);

	const renderAreaInfoBoxContent = useMemo(
		() => (
			<div className={styles['info-content']}>
				<div className={styles.info}>
					<div className={styles.text}>Status: --status--</div>
					<div className={styles.text}>Boosted for: --period--</div>
					<div className={styles.text}>Credits applied: --credits--</div>
					<div className={styles.text}>Acquired applicants: --applicants--</div>
					<div className={styles.text}>--startDate--</div>
					<div className={styles.text}>--endDate--</div>
				</div>
			</div>
		),
		[],
	);

	const renderDotInfoBoxContent = useMemo(
		() => (
			<div className={styles['info-content']}>
				<div className={styles.title}>--date--</div>
				<div className={styles.info}>
					<div className={styles.text}>
						--applicantsNumber-- --applicationsWordForm-- total
					</div>
				</div>
			</div>
		),
		[],
	);

	const getYaxisLastTick = useMemo(() => {
		const dataMaxValue = analyticsData.reduce(
			(acc: number, data: JobAnalyticsDataInterface) => {
				return acc + Number(data.numberOfApplications);
			},
			0,
		);

		if (dataMaxValue === 0) return 25;

		return Math.ceil(dataMaxValue / 5) * 5;
	}, [analyticsData]);

	const renderReferenceAreas = useMemo(() => {
		return referenceAreasData?.map((dataItem) => {
			return (
				<ReferenceArea
					className={'boost-reference-area'}
					isFront={true}
					style={{ cursor: 'pointer' }}
					x1={dataItem.x1}
					x2={dataItem.x2}
					y1={0}
					y2={getYaxisLastTick}
					stroke={areaColor}
					fill={areaColor}
					strokeOpacity={0.3}
					fillOpacity={0.3}
					onMouseEnter={(e: React.MouseEvent) =>
						onMouseEnterArea(e, {
							status: dataItem.status,
							credits: dataItem.credits,
							period: dataItem.period,
							startDate: dataItem.startDate,
							endDate: dataItem.endDate,
							numberOfApplicants: dataItem.numberOfApplicants,
						})
					}
					onMouseLeave={onMouseLeaveArea}
				/>
			);
		});
	}, [referenceAreasData, getYaxisLastTick, onMouseLeaveArea, onMouseEnterArea]);

	// for 1-day boost periods
	const renderReferenceLineData = useMemo(() => {
		return referenceLineData?.map((dataItem) => {
			return (
				<ReferenceLine
					className={'boost-reference-area'}
					isFront={true}
					style={{ cursor: 'pointer' }}
					x={dataItem.x}
					y1={0}
					y2={getYaxisLastTick}
					stroke={areaColor}
					strokeWidth={10}
					fill={areaColor}
					strokeOpacity={0.3}
					fillOpacity={0.3}
					onMouseEnter={(e: React.MouseEvent) =>
						onMouseEnterArea(e, {
							status: dataItem.status,
							credits: dataItem.credits,
							period: dataItem.period,
							startDate: dataItem.startDate,
							endDate: dataItem.endDate,
							numberOfApplicants: dataItem.numberOfApplicants,
						})
					}
					onMouseLeave={onMouseLeaveArea}
				/>
			);
		});
	}, [getYaxisLastTick, onMouseEnterArea, onMouseLeaveArea, referenceLineData]);

	const renderLineChart = useMemo(() => {
		return (
			<>
				<div style={{ height: 240, marginLeft: '-40px' }}>
					<ResponsiveContainer>
						<LineChart data={generateApplicantsData}>
							<CartesianGrid stroke={gridLinesColor} horizontal vertical={false} />
							<XAxis
								dataKey="date"
								stroke="transparent"
								tick={{ fill: tickTextColor, fontSize: 10 }}
								tickLine={false}
								ticks={getChartTicks}
								tickMargin={10}
							/>
							<YAxis
								tick={{ fill: tickTextColor, fontSize: 10 }}
								tickSize={10}
								tickLine={false}
								stroke="transparent"
								domain={[0, getYaxisLastTick]}
								tickCount={6}
							/>
							<Tooltip
								cursor={false}
								wrapperStyle={{ display: 'none', width: 0 }}
								active={false}
							/>

							{renderReferenceAreas}

							{renderReferenceLineData}

							<Line
								type="monotone"
								dataKey="numberOfApplications"
								stroke={lineColor}
								strokeWidth={3}
								dot={<CustomizedChartDot />}
								activeDot={false}
								isAnimationActive={getRelevantDatesForChart.length > 1}
							/>
						</LineChart>
					</ResponsiveContainer>
				</div>

				<div
					id="ref-area-info-box"
					ref={areaTooltipRef}
					className={styles['info-box']}
					onMouseLeave={onMouseLeaveReferenceAreaInfoBox}
				>
					{renderAreaInfoBoxContent}
				</div>

				<div ref={dotTooltipRef} className={styles['info-box']}>
					{renderDotInfoBoxContent}
				</div>
			</>
		);
	}, [
		CustomizedChartDot,
		generateApplicantsData,
		getChartTicks,
		getRelevantDatesForChart.length,
		getYaxisLastTick,
		onMouseLeaveReferenceAreaInfoBox,
		renderAreaInfoBoxContent,
		renderDotInfoBoxContent,
		renderReferenceAreas,
		renderReferenceLineData,
	]);

	useEffect(() => {
		if (areaTooltipRef?.current) {
			setAreaBoxDefaultHtml(areaTooltipRef?.current.innerHTML);
		}
	}, [areaTooltipRef]);

	useEffect(() => {
		if (dotTooltipRef?.current) {
			setDotBoxDefaultHtml(dotTooltipRef?.current.innerHTML);
		}
	}, [dotTooltipRef]);

	return (
		<div
			className={classNames(
				styles['applicants-chart'],
				getRelevantDatesForChart.length === 1 ? styles['one-item'] : '',
			)}
		>
			{renderLineChart}
		</div>
	);
};

export default React.memo(JobApplicantsChart);
