import React, { FC } from 'react';
import classNames from 'classnames';

import PrivateLayoutInterface from './private-layout.interface';
import Header from '../../organisms/header/Header';
import Container from '../container';

import styles from './PrivateLayout.module.scss';

const PrivateLayout: FC<PrivateLayoutInterface> = ({
	children,
	hasHeader = true,
	navLinks,
	topLayoutContent,
}) => {
	return (
		<>
			{hasHeader ? <Header navLinks={navLinks} /> : null}

			{topLayoutContent}

			<Container className={classNames(styles.content, hasHeader && styles['with-header'])}>
				{children}
			</Container>
		</>
	);
};

export default React.memo(PrivateLayout);
