import React, { FC, useEffect, useState } from 'react';

import { getMinMaxWidthStyles } from '../../../../helpers';
import SwitcherInterface from './switcher.interface';
import FormItem from '../form-item/FormItem';

import Label from '../../../atoms/form/label/Label';
import styles from './Switcher.module.scss';
import classNames from 'classnames';

const Switcher: FC<SwitcherInterface> = React.forwardRef(
	({ id, label, minWidth, maxWidth, inputSettings, toggleClassName }, ref) => {
		const inlineStyles = getMinMaxWidthStyles(minWidth, maxWidth);
		const [checkedState, setCheckedState] = useState<boolean>(false);

		useEffect(() => {
			if (inputSettings && inputSettings.checked) {
				setCheckedState(inputSettings.checked);
			}
		}, [inputSettings]);

		return (
			<FormItem style={inlineStyles} className={styles['switcher__wrap']}>
				<input
					type="checkbox"
					id={id}
					className={styles.input}
					ref={ref}
					{...inputSettings}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						setCheckedState(event.target.checked);

						if (inputSettings && inputSettings.onChange) {
							inputSettings.onChange(event);
						}
					}}
					checked={checkedState}
				/>

				<Label htmlFor={id} className={styles.label}>
					{label}

					<span className={classNames(styles.switcher, toggleClassName)} />
				</Label>
			</FormItem>
		);
	},
);

export default Switcher;
