import React, { FC, useCallback, useMemo } from 'react';

import styles from './CheckChip.module.scss';
import CheckChipInterface from './check-chip.interface';
import CheckboxWithLabel from '../../../molecules/form/checkbox-with-label/CheckboxWithLabel';

const CheckChip: FC<CheckChipInterface> = ({ text, isChecked, onClickHandler }) => {
	const onChipClick = useCallback(() => {
		if (onClickHandler) onClickHandler(text);
	}, [onClickHandler, text]);

	const inputName = useMemo(() => text.toLowerCase(), [text]);

	return (
		<div className={styles.chip} onClick={onChipClick}>
			<CheckboxWithLabel
				className={styles.checkbox}
				isWhiteTheme
				id={inputName}
				name={inputName}
				checked={isChecked}
				label=""
				onChange={onChipClick}
			/>

			<div className={styles.text}>{text}</div>
		</div>
	);
};

export default CheckChip;
