import React from 'react';
import { motion } from 'framer-motion/dist/framer-motion';
import { ForwardedRef, PropsWithChildren, forwardRef } from 'react';
import styles from './AnimatedScreenWrapper.module.scss';
import classNames from 'classnames';
import { MotionAnimationType } from '../../custom/common';

export const AnimatedScreenWrapper = forwardRef<HTMLDivElement, PropsWithChildren<any>>(
	(props: PropsWithChildren<any>, ref: ForwardedRef<HTMLDivElement>) => {
		const pageVariants: MotionAnimationType = {
			in: {},
			out: {},
		};

		if (props?.opacityAnimation) {
			pageVariants.in = {
				opacity: 1,
			};

			pageVariants.out = {
				opacity: 0,
				transition: { duration: 0 }
			}
		} else {
			pageVariants.in = {
				y: 0,
				transition: { duration: 0.35 }
			};

			pageVariants.out = {
				y: '+100%',
				transition: { duration: 0.3 }
			}
		}

		const pageTransition = {
			type: 'spring', bounce: 0.15
		};

		return (
			<motion.div style={{ originY: 0.5 }}
				className={classNames(styles.wrapper, props.asModal ? styles['as-modal'] : '')}
				ref={ref}
				initial="out"
				exit="out"
				animate="in"
				variants={pageVariants}
				transition={pageTransition}
				{...props}
			/>
		);
	},
);
