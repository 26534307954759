import React, { FC, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { RootStateType } from '../../../../store/reducers/root-reducer';
import {
	CompanyInterface,
	editCompanyDeleteImages,
	editCompanyUploadImages,
} from '../../../../store/slices/companies.slice';
import EditableViewForm from '../../editable-view-form/EditableViewForm';
import UploadedImagePreview from '../../uploaded-image-preview';
import useActiveCompany from '../../../../helpers/hooks/use-active-company/useActiveCompany';

import styles from './EditCompanyLogoSection.module.scss';

const EditCompanyLogoSection: FC = () => {
	const { handleSubmit } = useForm();
	const dispatch = useDispatch();
	const {
		companies: { activeCompanyId, items, imageLoading },
	} = useSelector((state: RootStateType) => state);
	const activeCompany = useActiveCompany(activeCompanyId, items);

	const [imageErrors, setImageErrors] = useState<string | undefined>();

	const onSubmit = useCallback(
		async (data: Partial<CompanyInterface>, ...rest: any) => {
			if (activeCompany)
				await dispatch(editCompanyUploadImages(activeCompany.id, data, rest[0]));
		},
		[activeCompany, dispatch],
	);

	const removeImage = useCallback(
		async (target: string) => {
			setImageErrors(undefined);

			if (activeCompanyId)
				return await dispatch(editCompanyDeleteImages(activeCompanyId, target));
		},
		[activeCompanyId, dispatch],
	);

	return (
		<>
			<EditableViewForm
				addEditableViewFormItemClass={false}
				hasEditButton={false}
				sectionTitle="Logo"
				formClassName={styles.form}
				desc="Upload an image that is 350px (width) or smaller."
				onSubmit={handleSubmit(onSubmit)}
			>
				<UploadedImagePreview
					inputSettings={{ name: 'logoId', id: 'logoId' }}
					image={activeCompany?.logoUrl}
					className={styles.item}
					tileClassName={styles.tile}
					text="Add logo"
					uploadHandler={onSubmit}
					onRemoveButtonClick={() => removeImage('logoId')}
					loading={!!imageLoading.find((item: any) => item.target === 'logoId')}
					errorsHandler={setImageErrors}
				/>
			</EditableViewForm>

			{imageErrors ? <div className={styles.error}>{imageErrors}</div> : null}
		</>
	);
};

export default EditCompanyLogoSection;
