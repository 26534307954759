import React, { FC } from 'react';
import classNames from 'classnames';

import TemplateItemTileInterface from './template-item-tile.interface';
import Tile from '../../../molecules/ui/tile';
import Title from '../../../atoms/title/Title';
import TypographyText from '../../../atoms/ui/typography-text';

import styles from './TemplateItemTile.module.scss';

const TemplateItemTile: FC<TemplateItemTileInterface> = ({
	title,
	subtitle,
	description,
	className,
	isActive,
	...rest
}) => {
	return (
		<Tile
			className={classNames(styles.tile, className, isActive ? styles.active : '')}
			{...rest}
		>
			<Title
				component="h3"
				fontSize={16}
				fontWeight={700}
				marginBottom={8}
				className={styles.title}
			>
				{title}
			</Title>

			<Title
				component="h4"
				fontSize={14}
				fontWeight={400}
				className={styles.subtitle}
			>
				{subtitle}
			</Title>

			<TypographyText className={styles.desc}>{description}</TypographyText>
		</Tile>
	);
};

export default TemplateItemTile;
