import { useSelector } from 'react-redux';

import { FacilitiesListSliceInterface } from '../../../store/slices/facilities.slice';

export default function useFacilities() {
	const {
		facilities: { items: facilitiesItems, hasBeenLoaded: hasBeenFacilitiesLoaded },
	} = useSelector((store: { facilities: FacilitiesListSliceInterface }) => store);

	return {
		facilitiesItems,
		hasBeenFacilitiesLoaded,
	};
}
