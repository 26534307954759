import React, { FC, useCallback, useMemo, useState } from 'react';

import InfoWarning from '../../../../molecules/info-warning';
import JobPaymentWarningInterface from './job-payment-warning.interface';
import styles from './JobPaymentWarning.module.scss';
import { useParams } from 'react-router-dom';
import PaymentConfirmationPopup from '../../payment-confirmation-popup';
import { generatePaymentData } from '../../../../../helpers/custom/common';
import { getJobPaymentLink } from '../../../../../store/slices/job.slice';
import useCompanies from '../../../../../helpers/hooks/use-companies/useCompanies';
import { useDispatch } from 'react-redux';
import useActiveCompany from '../../../../../helpers/hooks/use-active-company/useActiveCompany';
import { paymentIntentGA4Event } from '../../../../../helpers/google-analytics-4/events';
import {
	GAContentGroup,
	GAContentId,
	GAMethod,
} from '../../../../../helpers/google-analytics-4/index.constants';
import {
	getCompanyDataForGA,
	getJobDataForGA,
} from '../../../../../helpers/google-analytics-4/helpers';
import useJob from '../../../../../helpers/hooks/use-job/useJob';
import ErrorNotification from '../../../../molecules/ui/errors-notification';
import Loader from '../../../../molecules/loader';

const JobPaymentWarning: FC<JobPaymentWarningInterface> = () => {
	const dispatch = useDispatch();
	const { id } = useParams();
	const [openPaymentConfirmationModal, setOpenPaymentConfirmationModal] = useState(false);
	const [paymentLinkLoading, setPaymentLinkLoading] = useState(false);
	const [paymentLinkError, setPaymentLinkError] = useState(false);

	const { activeCompanyId, companies } = useCompanies();
	const activeCompany = useActiveCompany(activeCompanyId, companies);
	const { info } = useJob();

	const gaEventData = useMemo(
		() => ({
			jobData: getJobDataForGA(info),
			companyData: getCompanyDataForGA(activeCompany),
			contentData: {
				id: GAContentId.job,
				group: GAContentGroup.job,
			},
		}),
		[activeCompany, info],
	);
	const onPaymentConfirm = useCallback(async () => {
		setPaymentLinkLoading(true);

		if (activeCompany && id) {
			paymentIntentGA4Event(gaEventData, '', GAMethod.popup);
		}

		const dataForLink = generatePaymentData(Number(id), `${activeCompanyId}`);
		const generatedLink = await dispatch(getJobPaymentLink(dataForLink));

		if (generatedLink) window.location.href = `${generatedLink}`;
		else setPaymentLinkError(true);

		setPaymentLinkLoading(false);
	}, [activeCompany, activeCompanyId, dispatch, gaEventData, id]);

	const renderPaymentConfirmationPopup = useMemo(() => {
		return (
			<PaymentConfirmationPopup
				openState={openPaymentConfirmationModal}
				openStateHandler={setOpenPaymentConfirmationModal}
				onConfirmHandler={onPaymentConfirm}
				loading={paymentLinkLoading}
				gaEventData={gaEventData}
			/>
		);
	}, [gaEventData, onPaymentConfirm, openPaymentConfirmationModal, paymentLinkLoading]);

	const onWarningBlockClick = useCallback(() => {
		setOpenPaymentConfirmationModal(true);
	}, []);

	return (
		<>
			<InfoWarning
				text={
					<div className={styles.wrap} onClick={onWarningBlockClick}>
						<span className={styles.warning}>Payment required.</span>{' '}
						<span className={styles.link}>Pay and publish job</span>
					</div>
				}
				hidden={false}
			/>

			{renderPaymentConfirmationPopup}

			{paymentLinkError ? <ErrorNotification message="Sorry, something went wrong." /> : null}

			{paymentLinkLoading ? <Loader wide withOverlay /> : null}
		</>
	);
};

export default React.memo(JobPaymentWarning);
