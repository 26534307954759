import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { RootStateType } from '../../../../store/reducers/root-reducer';
import { CandidateInterface } from '../../../../store/slices/candidate.slice';
import { setHiringWithWurkCandidateId } from '../../../../store/slices/candidates.slice';
import HiredInWurkScreenInterface from './hired-in-wurk-screen.interface';
import ModalWindow from '../../modal-window/ModalWindow';
import Container from '../../../templates/container';
import NestedPopupHeader from '../../popup-headers/nested-popup-header/NestedPopupHeader';
import JobNameAndLocationInfo from '../job-name-and-location-info';
import HiredWithWurkForm from '../hired-with-wurk-form';
import WurkEnginInfo from '../../../molecules/custom/wurk-engin-info';
import Title from '../../../atoms/title/Title';
import TypographyText from '../../../atoms/ui/typography-text';

import styles from './HiredInWurkScreen.module.scss';
import Loader from '../../../molecules/loader';
import WurkEnginLogoImage from '../../../../assets/icons//wurk_engin_logo.svg';

const HiredInWurkScreen: FC<HiredInWurkScreenInterface> = ({
	className,
	contentClassName,
	afterStatusChange,
	...rest
}) => {
	const dispatch = useDispatch();
	const [candidate, setCandidate] = useState<CandidateInterface>();
	const {
		einNumber,
		candidates: { items: candidates, hiringWithWurkCandidateId },
	} = useSelector((state: RootStateType) => state);

	useEffect(() => {
		if (hiringWithWurkCandidateId && candidates) {
			setCandidate(candidates.find((item) => item.id === hiringWithWurkCandidateId));
		}
	}, [hiringWithWurkCandidateId, candidates]);

	const close = () => {
		dispatch(setHiringWithWurkCandidateId(undefined));
	};

	return (
		<ModalWindow
			hasCloseButton={false}
			className={classNames(styles.wrap, className)}
			contentClassName={classNames(styles['wrap__content'], contentClassName)}
			{...rest}
		>
			<NestedPopupHeader
				title="Hired in Würk"
				backgroundColor="#9977A5"
				className={styles.header}
				titleClassName={styles['header-title']}
				{...{ close }}
			>
				<JobNameAndLocationInfo />
			</NestedPopupHeader>

			<Container maxWidth="27%" className={styles.container}>
				{einNumber?.loading === 'rejected' ? (
					<>
						<div className={styles['error-message']}>
							Sorry, something went wrong. Please try again later.
						</div>

						<img
							className={styles['engin-wurk']}
							src={WurkEnginLogoImage}
							alt="Würk engin logo"
						/>
					</>
				) : (
					<>
						{einNumber?.loading === 'pending' && <Loader wide />}

						<WurkEnginInfo className={styles.logo} />

						<div>
							{candidate?.firstName || candidate?.lastName ? (
								<Title
									robotoFont
									component="h2"
									marginBottom={12}
									className={styles.title}
								>
									{candidate?.firstName} {candidate?.lastName}
								</Title>
							) : null}

							<div className={styles['user-info']}>
								{candidate?.email ? (
									<div className={styles['user-info__item']}>
										<a
											target="_blank"
											className={styles.link}
											href={`mailto:${candidate.email}`}
										>
											{candidate.email}
										</a>
									</div>
								) : null}
								{candidate?.phoneNumber ? (
									<div className={styles['user-info__item']}>
										<a
											target="_blank"
											className={styles.link}
											href={`tel:${candidate.phoneNumber}`}
										>
											{candidate.phoneNumber}
										</a>
									</div>
								) : null}
							</div>

							<TypographyText robotoFont className={styles.description}>
								Specify the details below to hire this candidate in Würk.
							</TypographyText>
						</div>

						<HiredWithWurkForm {...{ afterStatusChange }} />
					</>
				)}
			</Container>
		</ModalWindow>
	);
};

export default React.memo(HiredInWurkScreen);
