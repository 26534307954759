import React, { FC } from 'react';

import CustomJobListingInterface from './custom-job-listing.interface';
import TemplateItemTile from '../../template-item-tile';
import styles from './CustomJobListing.module.scss';
import TypographyText from '../../../../atoms/ui/typography-text';

const CustomJobListing: FC<CustomJobListingInterface> = () => {
	return (
		<div className={styles['custom-wrap']}>
			<TypographyText>
				You selected Custom Job Listing. Click CONTINUE above to create a custom job listing
				and fill in all the details of your job listing.
			</TypographyText>

			<TemplateItemTile
				className={styles.tile}
				key={'generic'}
				title={'Your custom job'}
				subtitle={''}
				description={
					'Start from scratch and  define all the details of your  listing to meet your needs.'
				}
				isActive={true}
			/>
		</div>
	);
};

export default React.memo(CustomJobListing);
