interface filtrationSettings {
	text?: string;
	filterItem?: string;
	filterOrder?: string;
	filterType?: number;
}

export const setSpecificFiltration = (
	settings: filtrationSettings,
	dispatchFiltration: React.Dispatch<any>,
) => {
	dispatchFiltration({
		type: 'SET_FILTER',
		payload: {
			filters: settings,
		},
	});
};
