import React from 'react';

export type DomainNameContextType = string | undefined;

const initialState: DomainNameContextType = undefined;

const DomainNameContext = React.createContext(initialState as DomainNameContextType);

export const { Provider: DomainNameProvider, Consumer: ProviderConsumer } = DomainNameContext;
export default DomainNameContext;
