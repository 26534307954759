import classNames from 'classnames';
import React, { FC } from 'react';

import ExternalLinkInterface from './external-link.interface';

import styles from './ExternalLink.module.scss';

const ExternalLink: FC<ExternalLinkInterface> = ({ className, children, ...rest }) => {
	return (
		<a className={classNames(styles['nav-link'], className)} {...rest}>
			{children}
		</a>
	);
};

export default ExternalLink;
