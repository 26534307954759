import { ErrorTargetType, ExternalErrorType } from '../../axios/axios-global';
import { getErrorTargetByName } from '../../index';
// import { getErrorTargetByName } from '../../index';

export default function useExternalFormErrors(
	error?: Partial<ExternalErrorType> | null,
): { errors?: any; globalMessage?: string } | null {
	if (error?.errors) {
		const specificFieldErrors: { errors: any } = {
			errors: {},
		};
		
		error.errors.forEach((item: ErrorTargetType) => {
			const errorTarget = getErrorTargetByName({
				targets: error?.errors,
				name: item.property,
			});

			if (errorTarget) {
				// @ts-ignore
				specificFieldErrors.errors[item.property] = Object.values(
					errorTarget.constraints,
				).join(', ');
			}
		});

		if (specificFieldErrors) {
			return specificFieldErrors;
		}
	} else if (!error?.errors && error?.message) {
		return { globalMessage: error.message };
	}

	return null;
}
