import { ContentBlock } from 'draft-js';
import { EDITOR_EMPTY_STATE } from '../constants';

export const findInBlockWithRegex = (regex: RegExp, contentBlock: ContentBlock, callback: any) => {
	const blockText = contentBlock.getText();

	let matchArr, start;
	while ((matchArr = regex.exec(blockText)) !== null) {
		start = matchArr.index;
		callback(start, start + matchArr[0].length);
	}
};

export const decoratorStrategy = (contentBlock: ContentBlock, callback: any) => {
	let pattern = '';

	emailTagValues.forEach((tag, idx) => {
		if (idx === 0) pattern += '\\[' + tag + '\\]';
		else {
			pattern += '|\\[' + tag + '\\]';
		}
	});

	const tagsRx = new RegExp(`${pattern}`, 'g');

	findInBlockWithRegex(tagsRx, contentBlock, callback);
};

export const TemplateTags = {
	candidateFirstName: 'CANDIDATE_FIRST_NAME',
	candidateLastName: 'CANDIDATE_LAST_NAME',
	jobTitle: 'JOB_TITLE',
	jobLocation: 'JOB_LOCATION',
	yourFirstName: 'YOUR_FIRST_NAME',
	yourLastName: 'YOUR_LAST_NAME',
	yourCalendarLink: 'YOUR_CALENDAR_LINK',
	yourPhoneNumber: 'YOUR_PHONE_NUMBER',
};

export const emailTagValues = [
	TemplateTags.candidateFirstName,
	TemplateTags.candidateLastName,
	TemplateTags.jobTitle,
	TemplateTags.jobLocation,
	TemplateTags.yourFirstName,
	TemplateTags.yourLastName,
	TemplateTags.yourCalendarLink,
	TemplateTags.yourPhoneNumber,
];

export const emailTagRegexPattern = {
	candidateFirstName: new RegExp(`\\[${TemplateTags.candidateFirstName}\\]`, 'g'),
	candidateLastName: new RegExp(`\\[${TemplateTags.candidateLastName}\\]`, 'g'),
	yourCalendarLink: new RegExp(`\\[${TemplateTags.yourCalendarLink}\\]`, 'g'),
};

export const linkEntityTransform = (entity: any, text: string) => {
	if (entity.type === 'LINK' && entity?.data?.targetOption === '_blank') {
		return `<a href="${entity.data.url}" target="_blank">${text}</a>`;
	}
};

export interface EmailTextData {
	userFirstName?: string;
	userLastName?: string;
	jobTitle?: string;
	facility?: any;
	multipleCandidates?: boolean;
	candidateFirstName?: string;
	candidateLastName?: string;
	phoneNumber?: string;
	calendarLink?: string;
}

export const generateEmailText = (
	emailText: string,
	data: EmailTextData,
	insertLinkTag: boolean = true,
) => {
	const {
		userFirstName,
		userLastName,
		jobTitle,
		facility,
		multipleCandidates,
		candidateFirstName,
		candidateLastName,
		phoneNumber,
		calendarLink,
	} = data;

	const userFirstNameText = userFirstName ? userFirstName : '-';
	const userLastNameText = userLastName ? userLastName : '-';
	const jobTitleText = jobTitle ? jobTitle : '-';
	const jobLocationText = facility?.city ? `${facility?.city}, ${facility?.state}` : '-';
	const phoneNumberText = phoneNumber ? phoneNumber : '-';
	const calendarLinkText = calendarLink ? calendarLink : `[${TemplateTags.yourCalendarLink}]`;

	emailTagValues.forEach((tag) => {
		const pattern = new RegExp(`\\[${tag}\\]`, 'g');

		switch (tag) {
			case TemplateTags.candidateFirstName:
				if (!multipleCandidates) {
					emailText = emailText?.replace(pattern, candidateFirstName!);
				}
				break;
			case TemplateTags.candidateLastName:
				if (!multipleCandidates) {
					emailText = emailText?.replace(pattern, candidateLastName!);
				}
				break;
			case TemplateTags.jobTitle:
				emailText = emailText?.replace(pattern, jobTitleText);
				break;
			case TemplateTags.jobLocation:
				emailText = emailText?.replace(pattern, jobLocationText);
				break;
			case TemplateTags.yourFirstName:
				emailText = emailText?.replace(pattern, userFirstNameText);
				break;
			case TemplateTags.yourLastName:
				emailText = emailText?.replace(pattern, userLastNameText);
				break;
			case TemplateTags.yourPhoneNumber:
				emailText = emailText?.replace(pattern, phoneNumberText);
				break;
			case TemplateTags.yourCalendarLink:
				if (insertLinkTag) {
					emailText = emailText.replace(pattern, (match, idx) => {
						const hasTagBefore =
							emailText.substring(idx - 'href="'.length, idx) === 'href="';

						if (!hasTagBefore) {
							return `<a href="${calendarLinkText}" target="_blank">${calendarLinkText}</a>`;
						}

						return calendarLinkText;
					});
				} else {
					emailText = emailText?.replace(pattern, calendarLinkText);
				}

				break;
			default:
				break;
		}
	});

	return emailText;
};

export const isEditorValueEmpty = (value?:  string) => {
	return !value || value === EDITOR_EMPTY_STATE;
}