import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import FocusTrap from 'focus-trap-react';

import { getFocusableElements } from '../../../helpers/dom';
import { AuthorizationSliceInterface } from '../../../store/slices/authorization.slice';
import SlideStepperInterface from './slide-stepper.interface';

import styles from './SlideStepper.module.scss';
import Error from '../../molecules/ui/error';

const SlideStepper: FC<SlideStepperInterface> = ({
	steps,
	infinite = false,
	arrows = false,
	draggable = false,
	speed = 500,
	allowedListeners = true,
	afterActiveSliderNumberSet,
	isError,
	...rest
}) => {
	const sliderRef = useRef<Slider | null>(null);
	const [nextHandler, setNextHandlerState] = useState<(() => void) | null | undefined>();
	const [activeSliderNumberState, setActiveSliderNumberState] = useState<number | undefined>();
	const { loading } = useSelector(
		({ authorization }: { authorization: AuthorizationSliceInterface }) => authorization,
	);

	const setNextHandler = (callback: () => void) => {
		return setNextHandlerState(callback);
	};

	const onEnterClickHandler = React.useCallback(
		(event: KeyboardEvent) => {
			if (
				event.key &&
				event.key.toLocaleLowerCase() === 'enter' &&
				!loading &&
				nextHandler &&
				!['button', 'a', 'textarea'].find(
					(item) => item === document?.activeElement?.nodeName.toLowerCase(),
				)
			) {
				event.preventDefault();
				nextHandler();
			}
		},
		[nextHandler, loading],
	);

	useEffect(() => {
		if (document && allowedListeners) {
			document.addEventListener('keypress', onEnterClickHandler);
		}

		return () => document.removeEventListener('keypress', onEnterClickHandler);
	}, [nextHandler, allowedListeners, onEnterClickHandler]);

	const generateSlide = useMemo(() => {
		return steps.map(({ component }, index) => {
			return (
				<FocusTrap
					key={index}
					focusTrapOptions={{
						allowOutsideClick: true,
						escapeDeactivates: false,
					}}
					active={index === activeSliderNumberState}
				>
					<div
						className={styles.item}
						ref={(ref) => {
							if (
								index === activeSliderNumberState &&
								ref &&
								getFocusableElements(ref)[0]
							) {
								getFocusableElements(ref)[0].focus();
							}
						}}
					>
						{sliderRef &&
							React.cloneElement(component, {
								sliderRef,
								setNextHandler,
								nextHandler,
								activeSliderNumberState,
								sliderNumber: index,
							})}
					</div>
				</FocusTrap>
			);
		});
	}, [activeSliderNumberState, nextHandler, steps]);

	return (
		<>
			<Slider
				ref={(slider) => sliderRef && (sliderRef.current = slider)}
				onInit={() => {
					setActiveSliderNumberState(0);

					if (afterActiveSliderNumberSet) {
						afterActiveSliderNumberSet(0);
					}
				}}
				beforeChange={(currentSlide, nextSlide) => {
					if (afterActiveSliderNumberSet) {
						afterActiveSliderNumberSet(nextSlide);
					}

					if (speed) {
						setTimeout(() => {
							setActiveSliderNumberState(nextSlide);
						}, speed + 200);
					}
				}}
				{...{ infinite, arrows, draggable, speed, ...rest }}
			>
				{generateSlide}
			</Slider>

			{isError ? (
				<Error>
					There are errors or information missing. Please check your email and try again.
				</Error>
			) : null}
		</>
	);
};

export default React.memo(SlideStepper);
