import React, { FC } from 'react';
import ReactHtmlParser from 'react-html-parser';

import TextEditor from '../form-elemets/text-editor';
import EditableTextEditorInterface from './editable-text-editor.interface';

import styles from './EditableTextEditor.module.scss';

const EditableTextEditor: FC<EditableTextEditorInterface> = ({
	disabled,
	text,
	textareaSettings,
}) => {
	return (
		<div className={styles['editable-text-editor']}>
			{disabled ? (
				<>{text && ReactHtmlParser(text)}</>
			) : (
				<TextEditor defaultValue={text} {...{ textareaSettings }} />
			)}
		</div>
	);
};

export default EditableTextEditor;
