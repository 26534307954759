import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { addNote, editNote, NoteInterface } from '../../../../store/slices/notes.slice';
import NotesInterface from './notes.interface';
import NoteField from '../note-field';
import NoteItem from '../note-item';
import NoteList from '../note-list';
import Loader from '../../../molecules/loader';
import Overlay from '../../../molecules/ui/overlay';

import styles from '../../../../pages/CandidateInfo/CandidateInfo.module.scss';
import useCompanies from '../../../../helpers/hooks/use-companies/useCompanies';
import useUser from '../../../../helpers/hooks/use-user/useUser';
import useJob from '../../../../helpers/hooks/use-job/useJob';
import useCandidate from '../../../../helpers/hooks/use-candidate/useCandidate';
import useNotes from '../../../../helpers/hooks/use-notes/useNotes';

const Notes: FC<NotesInterface> = () => {
	const dispatch = useDispatch();
	const [authorSchema, setAuthorSchema] = useState<{ authorId: number; color: string }[]>([]);
	const [editNoteId, setEditNoteId] = useState<number>();
	const [authorIds, setAuthorIds] = useState<number[]>([]);
	const [counter, setCounter] = useState(0);
	const [colorCounter, setColorCounter] = useState(0);

	const { activeCompanyId } = useCompanies();
	const { info: userInfo } = useUser();
	const { info: jobInfo } = useJob();
	const { candidateInfo } = useCandidate();
	const { noteItems, notesLoading, addingNote } = useNotes();

	const authorColors = useMemo(() => ['#EE846D', '#E6AD67', '#4986A1', '#EE7470', '#525668'], []);

	const clearAuthorSchema = useCallback(() => {
		if (userInfo?.id && noteItems?.length) {
			setAuthorIds(Array.from(new Set(noteItems.map(({ author }) => author.id))));
		}
		setAuthorSchema([]);
		setCounter(0);
		setColorCounter(0);
	}, [noteItems, userInfo?.id]);

	const editHandler = useCallback((id?: number) => setEditNoteId(id), []);

	const onSubmit = useCallback(
		(data: { note: string }) => {
			if (candidateInfo?.userId && jobInfo?.id && activeCompanyId) {
				const extendedData = {
					userId: candidateInfo?.userId,
					companyId: activeCompanyId,
					jobId: jobInfo.id,
					...data,
				};

				if (editNoteId) {
					dispatch<any>(editNote({ ...extendedData, noteId: editNoteId })).then(() => {
						setEditNoteId(undefined);
					});
				} else {
					dispatch(addNote(extendedData));
				}
			}
		},
		[activeCompanyId, candidateInfo?.userId, dispatch, editNoteId, jobInfo.id],
	);

	useEffect(() => clearAuthorSchema(), [clearAuthorSchema]);

	useEffect(() => {
		if (counter < authorIds?.length) {
			if (counter !== 0 && !(counter % authorColors.length)) {
				if (userInfo?.id !== authorIds[counter]) {
					setAuthorSchema([
						...authorSchema,
						{
							authorId: authorIds[counter],
							color: authorColors[colorCounter],
						},
					]);
					setColorCounter((prevState) =>
						prevState !== authorColors.length - 1 ? prevState + 1 : 0,
					);
				}

				setCounter((prevState) => prevState + 1);
			} else if (
				!authorSchema.find(
					({ authorId: authorSchemaId }) => authorSchemaId === authorIds[counter],
				)
			) {
				if (userInfo?.id !== authorIds[counter]) {
					setAuthorSchema([
						...authorSchema,
						{
							authorId: authorIds[counter],
							color: authorColors[colorCounter],
						},
					]);

					setColorCounter((prevState) =>
						prevState !== authorColors.length - 1 ? prevState + 1 : 0,
					);
				} else {
					setAuthorSchema([
						...authorSchema,
						{
							authorId: authorIds[counter],
							color: '#70C9C2',
						},
					]);
				}

				setCounter((prevState) => prevState + 1);
			}
		}
	}, [authorColors, authorIds, authorSchema, colorCounter, counter, userInfo?.id]);

	const notes = useMemo(
		() => (
			<>
				{noteItems?.length && authorSchema?.length ? (
					<NoteList>
						{noteItems.map(({ id, ...item }: NoteInterface) => {
							const color = authorSchema.find(
								({ authorId }) => authorId === item.author.id,
							)?.color;

							if (candidateInfo?.userId) {
								return (
									<NoteItem
										key={id}
										editMode={editNoteId === id}
										candidateId={candidateInfo?.userId}
										loading={addingNote}
										{...{ ...item, id, color, editHandler, onSubmit }}
									/>
								);
							} else return null;
						})}
					</NoteList>
				) : null}
			</>
		),
		[
			noteItems,
			authorSchema,
			candidateInfo?.userId,
			editNoteId,
			addingNote,
			editHandler,
			onSubmit,
		],
	);

	const content = useMemo(
		() =>
			candidateInfo && (
				<div className={styles['note-content']}>
					<Overlay open={!!editNoteId} />

					<NoteField
						{...{ onSubmit }}
						loading={!editNoteId && addingNote}
						className={styles['add-field']}
					/>

					{notes}
				</div>
			),
		[addingNote, candidateInfo, editNoteId, notes, onSubmit],
	);

	if (notesLoading) return <Loader className={styles.loader} />;

	return content;
};

export default React.memo(Notes);
