import React from 'react';
import { UseFormMethods } from 'react-hook-form/dist/types';

type LoginFormContextState = Partial<UseFormMethods<any>>

export interface LoginFormContextInterface extends LoginFormContextState {
}

const initialState: LoginFormContextInterface = {
};

const LoginFormContext = React.createContext(initialState);

export const { Provider: LoginFormProvider, Consumer: LoginFormConsumer } = LoginFormContext;
export default LoginFormContext;
