import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

import { setSpecificFiltration, setSpecificPagination } from '../../../../helpers';
import {
	CandidatesCount,
	getCandidatesCount,
	getCandidatesList,
	setHiringWithWurkCandidateId,
} from '../../../../store/slices/candidates.slice';
import CandidatesInterface, { CheckedCandidatesInterface } from './candidates.interface';
import HiredInWurkScreen from '../hired-in-wurk-screen';
import CandidatesViewSelectFilter from '../../filters/candidates-view-select-filter/CandidatesViewSelectFilter';
import TopBar from '../../top-bar/TopBar';
import CandidatesListSearchFilter from '../../filters/candidates-list-search-filter';
import Table from '../../table/Table';
import TableHead from '../../../molecules/table/table-head/TableHead';
import TableCell from '../../../atoms/table/table-cell/TableCell';
import TableBody from '../../../molecules/table/table-body/TableBody';
import TableRow from '../../../molecules/table/table-row/TableRow';
import CheckboxWithLabel from '../../../molecules/form/checkbox-with-label/CheckboxWithLabel';
import CandidateSelectStatus from '../candidate-select-status';
import MultipleCandidatesSelectStatuses from '../multiple-candidates-select-statuses';
import DashIcon from '../../../atoms/ui/dash-icon';
import ProgressScore from '../../../atoms/ui/progress-score';
import OrderByFilter from '../../filters/order-by-filter';
import EmptyState from '../../empty-state';
import InfiniteScroll from '../../infinite-scroll/InfiniteScroll';
import PaginationContext from '../../../../contexts/pagination-context';
import Button from '../../../atoms/buttons/button/Button';
import CandidateExportModalWindow from '../candidate-export-modal-window';
import { CandidatesStatusValues, QueryParametersKey } from '../../../../helpers/constants';
import InfoPopover from '../../../molecules/ui/info-popover';
import CertificateBadges from '../../../molecules/ui/certificate-badges';

import styles from './Candidates.module.scss';
import ExternalLink from '../../../atoms/navigation/external-link/ExternalLink';
import classNames from 'classnames';
import { ViewInterface } from '../../../../store/slices/candidate.slice';
import { FilterParamsType, parseUrlParams } from '../../../../helpers/custom/url';
import { getRelativeTimeString } from '../../../../helpers/custom/datetime';
import FilterContext from '../../../../contexts/filter-context';
import MessageCandidatesScreen from '../message-candidates-screen';
import { getJobPaymentLink, getJobStatistic } from '../../../../store/slices/job.slice';
import CommuteScore from '../commute-score';
import { checkJobIsBoosted } from '../../../../helpers/custom/job';
import useJob from '../../../../helpers/hooks/use-job/useJob';
import useCompanies from '../../../../helpers/hooks/use-companies/useCompanies';
import useCandidates from '../../../../helpers/hooks/use-candidates/useCandidates';
import { APP_URLS } from '../../../../helpers/routes/routes';
import {
	JobStatusType,
	checkIfJobNeedsPayment,
	generatePaymentData,
	getSourceTitleByOriginTag,
} from '../../../../helpers/custom/common';
import ResizeDeviceContext from '../../../../contexts/resize-device-context';
import TextEllipsis from '../../../molecules/ui/text-ellipsis';
import PaymentConfirmationPopup from '../payment-confirmation-popup';
import useActiveCompany from '../../../../helpers/hooks/use-active-company/useActiveCompany';
import { paymentIntentGA4Event } from '../../../../helpers/google-analytics-4/events';
import {
	GAContentGroup,
	GAContentId,
	GAContentType,
	GAMethod,
} from '../../../../helpers/google-analytics-4/index.constants';
import {
	getCompanyDataForGA,
	getJobDataForGA,
} from '../../../../helpers/google-analytics-4/helpers';
import ErrorNotification from '../../../molecules/ui/errors-notification';
import Loader from '../../../molecules/loader';

const Candidates: FC<CandidatesInterface> = ({ jobId, pageSize, notShowLoader }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const device = useContext(ResizeDeviceContext);

	const { info: jobInfo, statistic } = useJob();
	const { activeCompanyId, activeCompanyCanBoost, companies, activeCompanyPrepaidJobsBalance } =
		useCompanies();
	const activeCompany = useActiveCompany(activeCompanyId, companies);
	const { hiringWithWurkCandidateId, candidatesItems, loading, candidateItemsCount } =
		useCandidates();

	const { dispatch: filterContextDispatch } = useContext(FilterContext);
	const {
		state: { pagination: pageNumber },
		dispatch: dispatchPaginationNumber,
	} = useContext(PaginationContext);

	const [candidateExportState, setCandidateExportState] = useState<{
		open: boolean;
		filter?: string;
	}>({
		open: false,
	});
	const [checkedCandidates, setCheckedCandidates] = useState<CheckedCandidatesInterface[]>([]);
	const [currentFilterSettings, setCurrentFilterSettings] = useState<FilterParamsType>();

	const [messageCandidates, setMessageCandidates] = useState(false);
	const [renderMessageScreen, setRenderMessageScreen] = useState(false);
	const [isMessageSent, setIsMessageSent] = useState(false);
	const [defaultFiltering, setDefaultFiltering] = useState('');
	const [candidatesLoading, setCandidatesLoading] = useState(true);
	const [openPaymentConfirmationModal, setOpenPaymentConfirmationModal] = useState(false);
	const [paymentLinkLoading, setPaymentLinkLoading] = useState(false);
	const [paymentLinkError, setPaymentLinkError] = useState(false);

	const candidatesRecordsRef = useRef({});

	const jobNeedsPayment = useMemo(
		() => checkIfJobNeedsPayment(jobInfo, activeCompany, activeCompanyPrepaidJobsBalance),
		[activeCompany, jobInfo, activeCompanyPrepaidJobsBalance],
	);

	const candidatesIds = useMemo(
		() => checkedCandidates?.map(({ id }) => id),
		[checkedCandidates],
	);

	const applicationsCount = useMemo(
		//@ts-ignore
		() => statistic?.applicationsCount,
		//@ts-ignore
		[statistic?.applicationsCount],
	);

	const jobHasPowerProfile = useMemo(() => jobInfo?.hasPowerProfile, [jobInfo?.hasPowerProfile]);

	const isBoosted = useMemo(
		() => checkJobIsBoosted(jobInfo?.boostId, jobInfo?.jobBoosts, jobInfo?.status),
		[jobInfo?.boostId, jobInfo?.jobBoosts, jobInfo?.status],
	);

	const showBoostForm = useMemo(() => {
		return (
			activeCompanyCanBoost &&
			!isBoosted &&
			applicationsCount !== undefined &&
			!applicationsCount
		);
	}, [activeCompanyCanBoost, applicationsCount, isBoosted]);

	const totalCandidatesNumber = useMemo(
		() =>
			currentFilterSettings?.filter
				? candidateItemsCount[currentFilterSettings?.filter as keyof CandidatesCount]
				: candidateItemsCount[defaultFiltering as keyof typeof candidateItemsCount],
		[candidateItemsCount, currentFilterSettings?.filter, defaultFiltering],
	);

	const updateCandidatesList = useCallback(() => {
		const { filter, sort, text } = parseUrlParams<FilterParamsType>(location.search);

		if (filter || sort || text) {
			setCurrentFilterSettings({ text, filter, sort });

			setSpecificPagination(1, dispatchPaginationNumber);
			// for CandidateInfo page (back navigation, candidates count, etc.)
			setSpecificFiltration(
				{
					...(filter && { filterItem: filter }),
					...(sort && { filterOrder: sort?.replace(' ', '+') }),
					...(text && { text }),
				},
				filterContextDispatch,
			);
		}

		if (jobInfo?.id) {
			(async () => {
				const defaultFilter = jobInfo?.hasPowerProfile ? 'relevant' : 'all';

				dispatch(
					getCandidatesList(
						jobInfo?.id!,
						{
							pageNumber: 1,
							pageSize: pageSize || 1,
							title: text,
							filter: filter || defaultFilter,
							orderBy: sort,
						},
						true,
						true,
					),
				);

				setCandidatesLoading(false);
			})();
		}
	}, [
		dispatch,
		dispatchPaginationNumber,
		filterContextDispatch,
		jobInfo?.hasPowerProfile,
		jobInfo?.id,
		location.search,
		pageSize,
	]);

	const clearChosenCandidates = useCallback(() => setCheckedCandidates([]), []);

	useEffect(() => {
		if (jobHasPowerProfile !== undefined) {
			setDefaultFiltering(jobHasPowerProfile ? 'relevant' : 'all');
		}
	}, [jobHasPowerProfile]);

	useEffect(() => {
		if (jobInfo?.id) {
			updateCandidatesList();
		}
	}, [jobInfo?.id, updateCandidatesList]);

	useEffect(() => {
		const { filter, sort, text } = parseUrlParams<FilterParamsType>(location.search);

		// if param with empty value passed in url (?text=)
		if (location.search && (location.search.endsWith('=') || location.search.includes('=&'))) {
			const urlConfigs = new URLSearchParams(location.search);
			if (!filter) urlConfigs.delete(QueryParametersKey.filter);
			if (!sort) urlConfigs.delete(QueryParametersKey.sort);
			if (!text) urlConfigs.delete(QueryParametersKey.text);

			const paramsStr = urlConfigs.toString();
			window.history.replaceState(
				null,
				'',
				`${location.pathname}${paramsStr ? '?' : ''}${paramsStr}`,
			);
		}
	}, [location.pathname, location.search]);

	useEffect(() => {
		if (jobId) {
			const { filter, text } = currentFilterSettings || {};

			dispatch(getCandidatesCount(jobId.toString(), filter || defaultFiltering, text));
			if (localStorage.getItem('filterSettings')) localStorage.removeItem('filterSettings');

			dispatch(getJobStatistic(jobId.toString(), false));
		}
	}, [currentFilterSettings, defaultFiltering, dispatch, jobId]);

	useEffect(() => {
		if (isMessageSent) {
			updateCandidatesList();
			setIsMessageSent(false);
		}
	}, [isMessageSent, updateCandidatesList]);

	useEffect(() => {
		if (
			pageSize &&
			candidatesItems &&
			!(candidatesItems.length % pageSize) &&
			totalCandidatesNumber &&
			candidatesItems.length < totalCandidatesNumber
		) {
			const page = candidatesItems.length / pageSize + 1;

			setSpecificPagination(page, dispatchPaginationNumber);
		}
	}, [candidatesItems, dispatchPaginationNumber, pageSize, totalCandidatesNumber]);

	useEffect(() => {
		if (!messageCandidates) clearChosenCandidates();
	}, [clearChosenCandidates, messageCandidates]);

	const closeCandidateExportModalWindow = useCallback(() => {
		setCandidateExportState({
			open: false,
		});
	}, []);

	const isCandidateChecked = useCallback(
		(specificCandidateId: string | number) => {
			return !!checkedCandidates.find(({ id }) => specificCandidateId === id);
		},
		[checkedCandidates],
	);

	const afterStatusChange = useCallback(() => {
		dispatch(setHiringWithWurkCandidateId(undefined));
	}, [dispatch]);

	const onStatusChangeHandler = useCallback(
		(
			event: React.ChangeEvent<HTMLSelectElement>,
			id: string | number,
			firstName: string,
			lastName: string,
		) => {
			//@ts-ignore
			const currentId = candidatesRecordsRef?.current[id];

			const value = event.target.value;

			if (value === CandidatesStatusValues.rejected.toString()) {
				navigate(APP_URLS.candidateRejection, {
					state: { candidates: [{ id, firstName, lastName }], source: 'list', jobId },
				});
			} else if (value !== CandidatesStatusValues.applied.toString()) {
				currentId?.classList?.remove(styles.highlighted);
			} else currentId?.classList?.add(styles.highlighted);
		},
		[jobId, navigate],
	);

	const renderCertificationsInfoBoxContent = useMemo(() => {
		return (
			<div className={styles['info-content']}>
				<div className={styles.title}>About certifications</div>
				<div className={styles.text}>
					engin vets and collaborates with best-in-breed third-party certificate providers
					to provide validated education and skills badges giving you peace of mind that
					you are hiring people with verified skills.
				</div>

				<ExternalLink
					className={styles['learn-more']}
					href="https://www.careersincannabis.com/partners"
					target="_blank"
					rel="noopener noreferrer"
				>
					Learn more
				</ExternalLink>
			</div>
		);
	}, []);

	const renderCommuteInfoBoxContent = useMemo(() => {
		return (
			<div className={styles['info-content']}>
				<div className={styles.title}>Commute score</div>
				<div className={styles.text}>
					Commute score blends candidate preference and distance to job location.
				</div>
			</div>
		);
	}, []);

	const renderAppliedInfoBoxContent = useMemo(() => {
		return (
			<div className={styles['info-content']}>
				<div className={styles.title}>Applied</div>
				<div className={styles.text}>
					Red timestamps indicate that a candidate's status has not been updated in 2 days
					since applying.
					<div className={styles.remark}>
						Data shows that after 2 days, a candidate is likely to have moved on to
						another opportunity.
					</div>
				</div>
			</div>
		);
	}, []);

	const showAppliedAsHighlighted = useCallback((createdAt: number) => {
		const daysDifference = Math.abs(dayjs(createdAt, 'x').diff(Date.now(), 'days', true));
		return daysDifference > 2;
	}, []);

	const renderViewsText = useCallback((views: number) => {
		return (
			<div className={classNames(styles.views, views === 0 ? styles.regular : '')}>
				<TextEllipsis text={`${views} view${views !== 1 ? 's' : ''}`} />
			</div>
		);
	}, []);

	const renderViewsAndPopover = useCallback(
		(views: ViewInterface[]) => {
			if (!views || !views.length) return <>{renderViewsText(0)}</>;
			else {
				return (
					<InfoPopover
						trigger={renderViewsText(views.length)}
						className={styles['views-info']}
						contentClassName={classNames(styles['content-box-position'])}
					>
						<div className={styles['views-content']}>
							<div className={styles.title}>Candidate viewed by:</div>

							{views.map((view: ViewInterface) => {
								return (
									<div key={view.createdAt} className={styles.view}>
										<div className={classNames(styles['view-item'])}>
											{view.firstName} {view.lastName}
										</div>
										<div
											className={classNames(styles['view-item'], styles.time)}
										>
											{getRelativeTimeString(view.createdAt)}
										</div>
									</div>
								);
							})}
						</div>
					</InfoPopover>
				);
			}
		},
		[renderViewsText],
	);

	const candidates = useMemo(
		() =>
			candidatesItems?.length
				? candidatesItems?.map(
						({
							id,
							firstName,
							lastName,
							originTag,
							score,
							createdAt,
							status,
							statusLabel,
							hiredInWurk,
							badges,
							views,
							commuteScoreClass,
						}: any) => (
							<TableRow key={id}>
								<TableCell centeredContent minWidth={48} maxWidth={48}>
									<CheckboxWithLabel
										id={id}
										defaultChecked={isCandidateChecked(id)}
										onChange={() =>
											setCheckedCandidates((prevState) => {
												const hasItem = prevState.find(
													({ id: itemId }) => itemId === id,
												);

												if (hasItem) {
													return prevState.filter(
														({ id }) => id !== hasItem.id,
													);
												} else {
													return [
														...prevState,
														{ id, firstName, lastName },
													];
												}
											})
										}
									/>
								</TableCell>
								<TableCell
									minWidth="18%"
									maxWidth={device === 'mobile' ? '25%' : '18%'}
								>
									<TextEllipsis
										text={`${lastName}, ${firstName}`}
										linkUrl={`/candidate/${jobId}/${id}`}
										noLinkStyles
									/>
								</TableCell>

								{device !== 'mobile' ? (
									<TableCell width="14%">
										<TextEllipsis
											text={getSourceTitleByOriginTag(
												activeCompanyId,
												originTag,
											)}
										/>
									</TableCell>
								) : null}

								{device !== 'mobile' ? (
									<TableCell width="15%">
										{badges?.length ? (
											<CertificateBadges badges={badges} />
										) : null}
									</TableCell>
								) : null}

								{jobHasPowerProfile ? (
									<TableCell
										minWidth="14%"
										maxWidth={device === 'mobile' ? '25%' : '14%'}
									>
										<ProgressScore score={score} />
									</TableCell>
								) : (
									<TableCell
										minWidth="10%"
										maxWidth={device === 'mobile' ? '20%' : '10%'}
										centeredContent
									>
										<CommuteScore scoreClass={commuteScoreClass} mediumSize />
									</TableCell>
								)}

								<TableCell
									minWidth="8%"
									maxWidth={device === 'mobile' ? '20%' : '8%'}
								>
									{renderViewsAndPopover(views)}
								</TableCell>

								<TableCell
									minWidth="12%"
									maxWidth={device === 'mobile' ? '20%' : '12%'}
								>
									{createdAt ? (
										<div
											ref={(el) => {
												if (candidatesRecordsRef?.current && el)
													//@ts-ignore
													candidatesRecordsRef.current[id.toString()] =
														el;
											}}
											className={classNames(
												status === CandidatesStatusValues.applied &&
													showAppliedAsHighlighted(createdAt)
													? styles.highlighted
													: '',
												styles.time,
											)}
										>
											<TextEllipsis text={getRelativeTimeString(createdAt)} />
										</div>
									) : null}
								</TableCell>

								{device !== 'mobile' ? (
									<TableCell minWidth={125}>
										<CandidateSelectStatus
											{...{ statusLabel, hiredInWurk }}
											candidateId={id}
											defaultValue={status}
											onChange={(e) =>
												onStatusChangeHandler(e, id, firstName, lastName)
											}
										/>
									</TableCell>
								) : null}
							</TableRow>
						),
				  )
				: null,
		[
			activeCompanyId,
			candidatesItems,
			device,
			isCandidateChecked,
			jobHasPowerProfile,
			jobId,
			onStatusChangeHandler,
			renderViewsAndPopover,
			showAppliedAsHighlighted,
		],
	);

	const gaEventData = useMemo(
		() => ({
			jobData: getJobDataForGA(jobInfo),
			companyData: getCompanyDataForGA(activeCompany),
			contentData: {
				id: GAContentId.job,
				group: GAContentGroup.job,
				type: GAContentType.candidates,
			},
		}),
		[activeCompany, jobInfo],
	);

	const onPaymentConfirm = useCallback(async () => {
		setPaymentLinkLoading(true);

		if (activeCompany) {
			paymentIntentGA4Event(gaEventData, '', GAMethod.popup);
		}

		const dataForLink = generatePaymentData(Number(jobInfo?.id), `${activeCompanyId}`);
		const generatedLink = await dispatch(getJobPaymentLink(dataForLink));

		if (generatedLink) window.location.href = `${generatedLink}`;
		else setPaymentLinkError(true);

		setPaymentLinkLoading(false);
	}, [activeCompany, activeCompanyId, dispatch, gaEventData, jobInfo?.id]);

	const renderPaymentConfirmationPopup = useMemo(() => {
		return (
			<PaymentConfirmationPopup
				openState={openPaymentConfirmationModal}
				openStateHandler={setOpenPaymentConfirmationModal}
				onConfirmHandler={onPaymentConfirm}
				loading={paymentLinkLoading}
				gaEventData={gaEventData}
			/>
		);
	}, [gaEventData, onPaymentConfirm, openPaymentConfirmationModal, paymentLinkLoading]);

	const renderEmptyStateForInactiveJob = useMemo(() => {
		if (jobId) {
			if (jobNeedsPayment) {
				return (
					<>
						<EmptyState
							title="Job inactive"
							description={
								<div className={styles['empty-info']}>
									Your job is inactive and requires payment to be published. Pay
									now to publish your job and start acquiring candidates.
									<Button
										small
										minWidth={191}
										reversedStyles
										className={styles['publish-btn']}
										onClick={() => setOpenPaymentConfirmationModal(true)}
									>
										Pay & Publish job
									</Button>
								</div>
							}
						/>

						{renderPaymentConfirmationPopup}
					</>
				);
			} else
				return (
					<EmptyState
						title="Woops, one more step."
						description={
							<div className={styles['empty-info']}>
								Your job is inactive - you just need to activate it and get the word
								out. After you activate, check back here for candidates as they
								apply.
								<br />
								<br />
								Go to <Link to={`/jobs/${jobId}?tab=settings`}>settings</Link> to
								activate it now!
							</div>
						}
					/>
				);
		}
	}, [jobId, jobNeedsPayment, renderPaymentConfirmationPopup]);

	const renderEmptyStateForActiveJob = useMemo(() => {
		return (
			<EmptyState
				title="It's a brand new day!"
				description={
					isBoosted ? (
						<div className={styles['empty-info']}>
							Your job is active and being boosted! This job doesn't have candidates
							yet but they will soon come rolling in - usually this takes about 48
							hours. Check back here for new candidates as they apply.
						</div>
					) : (
						<div className={styles['empty-info']}>
							Your job is active and is already being distributed through a wide range
							of channels. Check back here for candidates as they apply. <br />
							<br /> You can help attract more candidates by getting the word out in
							your favorite places.{' '}
							<Link to={`/promote-job/${jobId}`}>Promote your job</Link> now!
						</div>
					)
				}
			/>
		);
	}, [isBoosted, jobId]);

	const renderCandidatesEmptyState = useMemo(() => {
		if (jobInfo.status === JobStatusType.inactive) return renderEmptyStateForInactiveJob;
		else if (jobInfo.status === JobStatusType.active) return renderEmptyStateForActiveJob;
	}, [jobInfo.status, renderEmptyStateForActiveJob, renderEmptyStateForInactiveJob]);

	const blockInfiniteScrolling = useMemo(
		() =>
			loading ||
			!!(pageSize && candidatesItems && candidatesItems?.length % pageSize) ||
			Object.values(candidateItemsCount)[0] === candidatesItems?.length ||
			(candidatesItems !== undefined && !candidatesItems.length) ||
			!totalCandidatesNumber ||
			(!!candidatesItems &&
				!!totalCandidatesNumber &&
				candidatesItems.length >= totalCandidatesNumber),
		[candidateItemsCount, candidatesItems, loading, pageSize, totalCandidatesNumber],
	);

	const refreshInfiniteScroll = useCallback(() => {
		const { filter, sort, text } = currentFilterSettings || {};

		if (jobId)
			dispatch(
				getCandidatesList(
					jobId,
					{
						pageNumber,
						pageSize,
						title: text,
						filter: filter || defaultFiltering,
						orderBy: sort,
					},
					undefined,
					true,
				),
			);
	}, [currentFilterSettings, defaultFiltering, dispatch, jobId, pageNumber, pageSize]);

	const onMessageClick = useCallback(() => {
		setRenderMessageScreen(true);
		setMessageCandidates(true);
	}, []);

	const sortedByCreatedAt = useMemo(() => {
		if (jobHasPowerProfile) return currentFilterSettings?.sort?.startsWith('createdAt');

		return !currentFilterSettings?.sort || currentFilterSettings?.sort?.startsWith('createdAt');
	}, [currentFilterSettings?.sort, jobHasPowerProfile]);

	const renderTopBar = useMemo(() => {
		if (applicationsCount) {
			return (
				<TopBar
					className={styles['top-bar']}
					viewSelectFilter={
						<CandidatesViewSelectFilter
							itemsToExclude={jobHasPowerProfile ? [] : ['relevant', 'notrelevant']}
							decreaseIndent={!jobHasPowerProfile}
							defaultFiltering={defaultFiltering}
							{...{ jobId, pageNumber, pageSize }}
						/>
					}
					searchFilter={
						<CandidatesListSearchFilter {...{ jobId, pageNumber, pageSize }} />
					}
					rightTopBarContent={
						<div className={styles.right}>
							<Button
								small
								reversedStyles
								className={styles.btn}
								onClick={() =>
									setCandidateExportState({
										open: true,
									})
								}
							>
								Download
							</Button>

							<Button
								small
								reversedStyles
								className={styles.btn}
								disabled={!checkedCandidates.length}
								onClick={onMessageClick}
							>
								Message
							</Button>

							{jobId ? (
								<div className={styles['status-select']}>
									<MultipleCandidatesSelectStatuses
										defaultFiltering={defaultFiltering}
										{...{ pageSize, jobId }}
										disabled={!checkedCandidates.length}
										candidates={checkedCandidates}
										onChange={() => setCheckedCandidates([])}
									/>
								</div>
							) : null}
						</div>
					}
				/>
			);
		}
	}, [
		applicationsCount,
		checkedCandidates,
		defaultFiltering,
		jobHasPowerProfile,
		jobId,
		onMessageClick,
		pageNumber,
		pageSize,
	]);

	const renderCandidatesTable = useMemo(() => {
		return (
			<Table>
				<TableHead>
					<TableCell centeredContent minWidth={48} maxWidth={48} headCell>
						<CheckboxWithLabel
							id="all"
							disabled={!checkedCandidates.length}
							icon={DashIcon}
							defaultChecked={!!checkedCandidates.length}
							onChange={clearChosenCandidates}
							className={styles.checkbox}
						/>
					</TableCell>

					<TableCell
						minWidth="18%"
						maxWidth={device === 'mobile' ? '25%' : '18%'}
						headCell
						className={styles.sortable}
					>
						<TextEllipsis text={'Candidate'} />
						<OrderByFilter
							orderBy="lastName"
							className={
								!currentFilterSettings?.sort?.startsWith('lastName')
									? styles.hidden
									: ''
							}
							hidden={!currentFilterSettings?.sort?.startsWith('lastName')}
						/>
					</TableCell>

					{device !== 'mobile' ? (
						<TableCell width="14%" headCell className={styles.sortable}>
							<TextEllipsis text={'Source'} />
							<OrderByFilter
								orderBy="originTag"
								className={
									!currentFilterSettings?.sort?.startsWith('originTag')
										? styles.hidden
										: ''
								}
								hidden={!currentFilterSettings?.sort?.startsWith('originTag')}
							/>
						</TableCell>
					) : null}

					{device !== 'mobile' ? (
						<TableCell width="15%" headCell className={styles.sortable}>
							<TextEllipsis text={'Certifications'} />
							<InfoPopover
								className={styles['info-box']}
								defaultTrigger
								contentClassName={styles['popover-width']}
							>
								{renderCertificationsInfoBoxContent}
							</InfoPopover>
						</TableCell>
					) : null}

					{jobHasPowerProfile ? (
						<TableCell
							minWidth="14%"
							maxWidth={device === 'mobile' ? '25%' : '14%'}
							headCell
							className={classNames(styles['ordinary-text'], styles.sortable)}
						>
							<TextEllipsis text={'FitScore ™'} />
							<OrderByFilter
								orderBy="score"
								className={
									currentFilterSettings?.sort &&
									!currentFilterSettings?.sort?.startsWith('score')
										? styles.hidden
										: ''
								}
								hidden={
									!!currentFilterSettings?.sort &&
									!currentFilterSettings?.sort?.startsWith('score')
								}
							/>
						</TableCell>
					) : (
						<TableCell
							minWidth="10%"
							maxWidth={device === 'mobile' ? '20%' : '10%'}
							headCell
							className={styles.sortable}
						>
							<TextEllipsis text={'Commute'} />
							<InfoPopover
								className={styles['info-box']}
								defaultTrigger
								contentClassName={styles['popover-width']}
							>
								{renderCommuteInfoBoxContent}
							</InfoPopover>
							<OrderByFilter
								orderBy="commuteScore"
								className={classNames(
									!currentFilterSettings?.sort?.startsWith('commuteScore')
										? styles.hidden
										: '',
									styles['intent-from-info'],
								)}
								hidden={!currentFilterSettings?.sort?.startsWith('commuteScore')}
							/>
						</TableCell>
					)}

					<TableCell
						minWidth="8%"
						maxWidth={device === 'mobile' ? '20%' : '8%'}
						headCell
						className={styles.sortable}
					>
						<TextEllipsis text={'Views'} />
						<OrderByFilter
							orderBy="views"
							className={
								!currentFilterSettings?.sort?.startsWith('views')
									? styles.hidden
									: ''
							}
							hidden={!currentFilterSettings?.sort?.startsWith('views')}
						/>
					</TableCell>

					<TableCell
						minWidth="12%"
						maxWidth={device === 'mobile' ? '20%' : '12%'}
						headCell
						className={styles.sortable}
					>
						<TextEllipsis text={'Applied'} />

						{device !== 'mobile' ? (
							<InfoPopover
								className={styles['info-box']}
								defaultTrigger
								contentClassName={styles['popover-width']}
							>
								{renderAppliedInfoBoxContent}
							</InfoPopover>
						) : null}

						<OrderByFilter
							orderBy="createdAt"
							className={classNames(
								sortedByCreatedAt ? '' : styles.hidden,
								styles['intent-from-info'],
							)}
							hidden={!sortedByCreatedAt}
						/>
					</TableCell>

					{device !== 'mobile' ? (
						<TableCell headCell className={styles.sortable} minWidth={125}>
							<TextEllipsis text={'Status'} />

							<OrderByFilter
								orderBy="status"
								className={
									!currentFilterSettings?.sort?.startsWith('status')
										? styles.hidden
										: ''
								}
								hidden={!currentFilterSettings?.sort?.startsWith('status')}
							/>
						</TableCell>
					) : null}
				</TableHead>{' '}
				<TableBody>{candidates}</TableBody>
			</Table>
		);
	}, [
		candidates,
		checkedCandidates.length,
		clearChosenCandidates,
		currentFilterSettings?.sort,
		device,
		jobHasPowerProfile,
		renderAppliedInfoBoxContent,
		renderCertificationsInfoBoxContent,
		renderCommuteInfoBoxContent,
		sortedByCreatedAt,
	]);

	const renderCandidatesMainContent = useMemo(() => {
		if (!candidatesLoading) {
			if (applicationsCount) return renderCandidatesTable;

			return renderCandidatesEmptyState;
		}
	}, [applicationsCount, candidatesLoading, renderCandidatesEmptyState, renderCandidatesTable]);

	const renderExportModal = useMemo(() => {
		return (
			<CandidateExportModalWindow
				jobId={Number(jobId)}
				applications={checkedCandidates.length ? candidatesIds : undefined}
				open={candidateExportState.open}
				status={
					!checkedCandidates.length
						? currentFilterSettings?.filter || defaultFiltering
						: ''
				}
				close={closeCandidateExportModalWindow}
				{...{ totalCandidatesNumber }}
			/>
		);
	}, [
		candidateExportState.open,
		candidatesIds,
		checkedCandidates.length,
		closeCandidateExportModalWindow,
		currentFilterSettings?.filter,
		defaultFiltering,
		jobId,
		totalCandidatesNumber,
	]);

	return (
		<section className={styles.candidates}>
			{renderTopBar}

			{renderCandidatesMainContent}

			{renderExportModal}

			<InfiniteScroll
				scrollDependances={[
					pageNumber,
					pageSize,
					candidatesItems,
					loading,
					candidateItemsCount,
				]}
				blockScrollingHandling={blockInfiniteScrolling}
				displayLoader={
					!notShowLoader &&
					!showBoostForm &&
					candidatesItems &&
					candidatesItems?.length < Object.values(candidateItemsCount)[0]
				}
				firstLoad={!candidatesItems?.length}
				refreshFunction={refreshInfiniteScroll}
				{...{ loading }}
			/>

			<HiredInWurkScreen
				open={!!hiringWithWurkCandidateId}
				close={() => dispatch(setHiringWithWurkCandidateId(undefined))}
				{...{ afterStatusChange }}
			/>

			{renderMessageScreen ? (
				<MessageCandidatesScreen
					candidatesDataToMessage={checkedCandidates.map((c) => ({
						id: c.id,
						firstName: c.firstName,
						lastName: c.lastName,
					}))}
					open={messageCandidates}
					close={() => setMessageCandidates(false)}
					afterMessageSentHandler={() => setIsMessageSent(true)}
				/>
			) : null}

			{paymentLinkError ? <ErrorNotification message="Sorry, something went wrong." /> : null}
			{paymentLinkLoading ? <Loader wide withOverlay /> : null}
		</section>
	);
};

export default React.memo(Candidates);
