import React from 'react';
import { PaginationReducerInterface } from '../../store/reducers/pagination-reducer';

type PaginationContextState = {
	state: PaginationReducerInterface;
};

export interface PaginationContextInterface extends PaginationContextState {
	dispatch: React.Dispatch<any>;
}

const initialState: PaginationContextInterface = {
	state: { pagination: undefined },
	dispatch: () => {},
};

const PaginationContext = React.createContext(initialState);

export const { Provider: PaginationProvider, Consumer: ProviderConsumer } = PaginationContext;
export default PaginationContext;
