import React, { FC } from 'react';
import classNames from 'classnames';

import ButtonLinkInterface from './button-link.interface';

import styles from './ButtonLink.module.scss';

const ButtonLink: FC<ButtonLinkInterface> = ({ children, className, ...rest }) => {
	return children ? (
		<button className={classNames(styles.button, className)} {...rest}>
			{children}
		</button>
	) : null;
};

export default ButtonLink;
