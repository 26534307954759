const getYearsFromCurrent = (number: number = 101) => {
	const currentYear = new Date().getFullYear();

	const years = new Array(number).fill('');

	return years.map((item: string, index: number) => {
		return currentYear - index;
	});
};

export { getYearsFromCurrent };
