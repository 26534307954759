import React, { FC, useContext } from 'react';
import classNames from 'classnames';

import EditCompanyVideoSection from '../edit-company-video-section';
import EditCompanyDetailsSection from '../edit-company-details-section';
import CompanyEditScreenInterface from './company-edit-screen.interface';
import Container from '../../../templates/container';
import CompanyInfoText from '../../../molecules/custom/company-info-text';
import CompanyPhotosGrid from '../company-photos-grid';
import EditCompanyEoeSection from '../edit-company-eoe-section';
import EditCompanyLogoSection from '../edit-company-logo-section';
import EditCompanyBenefitsSection from '../edit-company-benefits-section';
import EditCompanyDescriptionSection from '../edit-company-description-section/EditCompanyDescriptionSection';
import ResizeDeviceContext from '../../../../contexts/resize-device-context';

const CompanyEditScreen: FC<CompanyEditScreenInterface> = ({ className, ...rest }) => {
	const device = useContext(ResizeDeviceContext);

	return (
		<Container
			maxWidth={device !== 'desktop' ? '95%' : '58.8%'}
			className={classNames(className)}
			{...rest}
		>
			<CompanyInfoText />

			<EditCompanyDetailsSection />

			<EditCompanyVideoSection />

			<CompanyPhotosGrid />

			<EditCompanyLogoSection />

			<EditCompanyDescriptionSection />

			<EditCompanyBenefitsSection />

			<EditCompanyEoeSection />
		</Container>
	);
};

export default CompanyEditScreen;
