import { v4 as uuidv4 } from 'uuid';
import { CookieNames, QueryParametersKey, StorageItemKeys } from '../../../constants';
import { useLocation } from 'react-router-dom';
import { getCookie, setCookie } from '../../../custom/common';

const useCookies = () => {
	const location = useLocation();

	const urlConfigs = new URLSearchParams(location.search);
	const deviceIdFromWidget = urlConfigs.get(QueryParametersKey.widgetDeviceId);

	const deviceIdCookie = getCookie(CookieNames.deviceId);
	const deviceIdFromStorage = localStorage.getItem(StorageItemKeys.deviceId);

	const twoYears = new Date(+new Date() + 2 * 365 * 24 * 60 * 60 * 1000);

	if (!deviceIdCookie) {
		if (deviceIdFromWidget && !deviceIdFromStorage) {
			setCookie(CookieNames.deviceId, deviceIdFromWidget, twoYears);
			localStorage.setItem(StorageItemKeys.deviceId, deviceIdFromWidget);
		} else {
			const deviceId = deviceIdFromStorage ? deviceIdFromStorage : uuidv4();

			setCookie(CookieNames.deviceId, deviceId, twoYears);

			//backup for devices that remove cookies
			if (!deviceIdFromStorage) localStorage.setItem(StorageItemKeys.deviceId, deviceId);
		}
	} else if (deviceIdCookie !== deviceIdFromStorage) {
		localStorage.setItem(StorageItemKeys.deviceId, deviceIdCookie);
	}
};

export default useCookies;
