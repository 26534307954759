import React, { FC, useEffect, useRef } from 'react';
import classNames from 'classnames';

import { createPortal } from 'react-dom';
import ModalWindowInterface from './modal-window.interface';
import Close from '../../atoms/close';
import { AnimatePresence } from 'framer-motion/dist/framer-motion';

import styles from './ModalWindow.module.scss';
import { AnimatedScreenWrapper } from '../../../helpers/hocs/transition/AnimatedScreenWrapper';
import { nonScrollablePageOff, nonScrollablePageOn } from '../../../helpers/custom/common';

const ModalWindow: FC<ModalWindowInterface> = ({
	open,
	className,
	closeClassName,
	contentClassName,
	contentPopupWidth,
	children,
	hasCloseButton = true,
	close,
	nonScrollable = true,
	opacityAnimation = false,
	...rest
}) => {
	const documentElement = useRef(document.getElementById('root'));

	useEffect(() => {
		if (nonScrollable && open) nonScrollablePageOn();
		else nonScrollablePageOff();
	}, [nonScrollable, open]);

	const onEscapeClickHandler = React.useCallback(
		(event: KeyboardEvent) => {
			if (event.key && event.key.toLocaleLowerCase() === 'escape') {
				event.preventDefault();

				close();
			}
		},
		[close],
	);

	useEffect(() => {
		if (document && open) {
			document.addEventListener('keydown', onEscapeClickHandler);
		}

		return () => document.removeEventListener('keydown', onEscapeClickHandler);
	}, [open, onEscapeClickHandler]);

	return (
		<>
			{documentElement.current &&
				createPortal(
					<AnimatePresence mode="wait">
						{open && (
							<AnimatedScreenWrapper asModal opacityAnimation={opacityAnimation}>
								<div
									className={classNames(styles['modal-window'], className)}
									{...rest}
								>
									<div
										className={classNames(styles.content, contentClassName)}
										style={{
											...(contentPopupWidth && { width: contentPopupWidth }),
										}}
									>
										{hasCloseButton && (
											<Close
												onClick={close}
												className={classNames(styles.close, closeClassName)}
											/>
										)}

										{children}
									</div>
								</div>
							</AnimatedScreenWrapper>
						)}
					</AnimatePresence>,
					documentElement.current,
				)}
		</>
	);
};

export default React.memo(ModalWindow);
