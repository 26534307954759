import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import {
	CandidatesListSliceInterface,
	exportCandidates,
	getLastExportCandidates,
	clearExportedCandidateLinks,
	setBigExportedCandidateDataLoadingStatus,
	exportCandidatesLoadingSuccess,
} from '../../../../store/slices/candidates.slice';
import { candidatesViewFilterLabels } from '../../../../helpers/constants';
import Button from '../../../atoms/buttons/button/Button';
import CandidateExportModalWindowInterface from './candidate-export-modal-window.interface';
import ModalWindow from '../../modal-window/ModalWindow';
import CandidateExportItem from '../candidate-export-item/CandidateExportItem';
import ModalDownloadItemProcessing from '../../modal-download-item-processing';
import DownloadItem from '../../../templates/download-item/DownloadItem';
import DownloadLinkButton from '../../../atoms/buttons/download-link-button/DownloadLinkButton';

import styles from './CandidateExportModalWindow.module.scss';

const CandidateExportModalWindow: FC<CandidateExportModalWindowInterface> = ({
	jobId,
	applications,
	status,
	close,
	totalCandidatesNumber,
	...rest
}) => {
	const dispatch = useDispatch();
	const downloadRefLink = useRef<HTMLAnchorElement>(null);
	const {
		bigData: bigCandidatesExportedData,
		exportCandidatesLoading,
		newExportedCandidateLink,
		lastExportedCandidateInfo,
		lastCandidatesExportLoading,
	} = useSelector(({ candidates }: { candidates: CandidatesListSliceInterface }) => candidates);

	const getStatusText = useCallback(
		(status: string | undefined) =>
			status && candidatesViewFilterLabels.find(({ value }) => value === status)?.text,
		[],
	);

	const getExportedCandidates = useCallback(
		(applications: (string | number)[] | undefined, filter?: string) => {
			dispatch(
				exportCandidates({
					jobId,
					applications,
					filter: filter || status,
				}),
			);
		},
		[dispatch, jobId, status],
	);

	const lastExportedCandidateInfoUpdatedDate = useMemo(
		() =>
			lastExportedCandidateInfo?.updatedAt
				? new Date(lastExportedCandidateInfo.updatedAt)
				: null,
		[lastExportedCandidateInfo?.updatedAt],
	);

	const containingDataItems = useMemo(
		() =>
			[
				`${applications?.length || `All ${totalCandidatesNumber}`} ${
					applications?.length === 1 || totalCandidatesNumber === 1
						? 'candidate'
						: 'candidates'
				}`,
				getStatusText(status),
			].filter((item) => item),
		[applications?.length, getStatusText, status, totalCandidatesNumber],
	);

	const containingLastExportDataItems = useMemo(
		() =>
			[
				`${lastExportedCandidateInfo?.size || `All ${totalCandidatesNumber}`} candidates`,
				getStatusText(lastExportedCandidateInfo?.filter),
			].filter((item) => item),
		[
			getStatusText,
			lastExportedCandidateInfo?.filter,
			lastExportedCandidateInfo?.size,
			totalCandidatesNumber,
		],
	);

	const date = useMemo(
		() =>
			`${lastExportedCandidateInfoUpdatedDate?.getDate()} ${lastExportedCandidateInfoUpdatedDate?.toLocaleString(
				'default',
				{ month: 'long' },
			)} ${lastExportedCandidateInfoUpdatedDate?.getFullYear()}`,
		[lastExportedCandidateInfoUpdatedDate],
	);

	useEffect(() => {
		if (rest.open) {
			dispatch(getLastExportCandidates(jobId));
			dispatch(
				setBigExportedCandidateDataLoadingStatus({
					showModalWindow: false,
				}),
			);
		}

		dispatch(clearExportedCandidateLinks());
	}, [dispatch, jobId, rest.open]);

	useEffect(() => {
		if (
			newExportedCandidateLink &&
			!bigCandidatesExportedData?.showModalWindow &&
			!exportCandidatesLoading &&
			downloadRefLink.current
		) {
			downloadRefLink.current.click();
		}
		// eslint-disable-next-line
	}, [
		newExportedCandidateLink,
		// eslint-disable-next-line
		!bigCandidatesExportedData?.showModalWindow,
		exportCandidatesLoading,
	]);

	const link = useMemo(
		() => (
			<a ref={downloadRefLink} href={newExportedCandidateLink}>
				Download now
			</a>
		),
		[newExportedCandidateLink],
	);

	const content = useMemo(
		() =>
			bigCandidatesExportedData?.showModalWindow && exportCandidatesLoading ? (
				<CandidateExportItem
					title="Download data"
					description="Your export will take a while. Come back later to download your data."
					bottomElement={
						<Button
							minWidth="100%"
							className={styles.btn}
							onClick={() => {
								close();
								dispatch(exportCandidatesLoadingSuccess());
							}}
						>
							Ok
						</Button>
					}
					{...{ close }}
				/>
			) : lastCandidatesExportLoading ? (
				<>
					<ModalDownloadItemProcessing />
				</>
			) : !lastCandidatesExportLoading &&
			  !bigCandidatesExportedData?.loading &&
			  exportCandidatesLoading ? (
				<>
					<ModalDownloadItemProcessing
						title="Processing"
						description="Your request is being processed. Please wait until it is complete."
					/>
				</>
			) : lastExportedCandidateInfo?.status && !newExportedCandidateLink ? (
				<>
					<CandidateExportItem
						autoHeight={lastExportedCandidateInfo.status === 'progress'}
						title="Download data"
						description={
							lastExportedCandidateInfo.status === 'progress'
								? `Data you requested on ${date} is still being prepared.`
								: lastExportedCandidateInfo.status === 'completed'
								? `Data you previously exported on 
						${date} is available for download:`
								: `Data you requested on ${date} failed to download.`
						}
						containingDataItems={containingLastExportDataItems as (number | string)[]}
						bottomElement={
							<>
								{!exportCandidatesLoading &&
								lastExportedCandidateInfo.status === 'completed' ? (
									<DownloadLinkButton
										minWidth="100%"
										className={styles.btn}
										href={lastExportedCandidateInfo.url}
									>
										Download this data
									</DownloadLinkButton>
								) : lastExportedCandidateInfo.status === 'failed' ? (
									<Button
										minWidth="100%"
										className={styles.btn}
										onClick={() =>
											getExportedCandidates(
												lastExportedCandidateInfo.candidates,
												lastExportedCandidateInfo.filter,
											)
										}
									>
										Retry download
									</Button>
								) : null}
							</>
						}
						{...{ close }}
					/>

					<CandidateExportItem
						description="Export will contain:"
						bottomElement={
							<Button
								minWidth="100%"
								className={styles.btn}
								onClick={() => {
									getExportedCandidates(applications);
								}}
							>
								Start a new download
							</Button>
						}
						containingDataItems={containingDataItems as (number | string)[]}
						{...{ close }}
					/>
				</>
			) : newExportedCandidateLink && !bigCandidatesExportedData?.showModalWindow ? (
				<DownloadItem
					centeredContent
					title="Data ready"
					description={
						<>Your data is ready to download. Didn't start automatically? {link}</>
					}
					bottomElement={
						<Button minWidth="100%" className={styles.btn} onClick={close}>
							Done
						</Button>
					}
				/>
			) : lastExportedCandidateInfo ? (
				<CandidateExportItem
					title="Download data"
					description="Export will contain:"
					bottomElement={
						<Button
							minWidth="100%"
							className={styles.btn}
							onClick={() => getExportedCandidates(applications)}
						>
							Export data
						</Button>
					}
					containingDataItems={containingDataItems as (number | string)[]}
					{...{ close }}
				/>
			) : null,
		[
			applications,
			bigCandidatesExportedData?.loading,
			bigCandidatesExportedData?.showModalWindow,
			close,
			containingDataItems,
			containingLastExportDataItems,
			date,
			dispatch,
			exportCandidatesLoading,
			getExportedCandidates,
			lastCandidatesExportLoading,
			lastExportedCandidateInfo,
			link,
			newExportedCandidateLink,
		],
	);
	return (
		<ModalWindow
			{...rest}
			close={() => {
				close();

				dispatch(exportCandidatesLoadingSuccess());
			}}
			contentClassName={classNames(
				styles.wrap,
				styles.window,
				lastCandidatesExportLoading && styles['loader-only'],
			)}
			opacityAnimation
		>
			{content}
		</ModalWindow>
	);
};

export default React.memo(CandidateExportModalWindow);
