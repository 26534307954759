import React, { ChangeEvent, Children, FC, useCallback, useRef, useState } from 'react';
import { fileSizeValidation, PICTURE_SIZE_LIMIT } from '../../custom/validation';

import UploadedImagePreviewHocInterface from './uploaded-image-preview.interface-hoc';
import { compressImage } from '../../custom/common';

const UploadedImagePreviewHoc: FC<UploadedImagePreviewHocInterface> = ({
	className,
	inputSettings,
	image,
	video,
	uploadHandler,
	loading,
	onRemoveButtonClick,
	errorsHandler,
	children,
	...rest
}) => {
	const inputRef = useRef<HTMLInputElement | null>(null);
	const [imageUrl, setImageUrl] = useState<string | undefined>();
	const [imageCompressionInProgress, setImageCompressionInProgress] = useState(false);

	const processFile = useCallback(
		(fileToSave: File) => {
			const formData = new FormData();
			formData.append('target', inputSettings.name);
			formData.append('file', fileToSave);

			if (uploadHandler) uploadHandler(formData, inputSettings.name);

			setImageUrl(URL.createObjectURL(fileToSave));
			setImageCompressionInProgress(false);
		},
		[inputSettings.name, uploadHandler],
	);

	const handleChange = useCallback(
		async (event: ChangeEvent<HTMLInputElement>) => {
			if (event?.target?.files?.length) {
				const uploadedFile = event?.target?.files[0];
				const errorMessage = fileSizeValidation(uploadedFile, PICTURE_SIZE_LIMIT);
				if (errorsHandler) errorsHandler(errorMessage);

				if (!errorMessage) {
					if (uploadHandler) {
						try {
							setImageCompressionInProgress(true);

							const processedFile = await compressImage(uploadedFile);

							processFile(processedFile);
						} catch (error) {
							console.warn(
								`Error while compressing image. File name: "${uploadedFile?.name}"`,
								error,
							);

							processFile(uploadedFile);
						}
					} else {
						setImageUrl(URL.createObjectURL(uploadedFile));
					}
				}
			}
		},
		[errorsHandler, processFile, uploadHandler],
	);

	const clearImageUrl = useCallback(() => {
		setImageUrl(undefined);
		if (inputRef.current) {
			inputRef.current.value = '';
		}
		if (errorsHandler) errorsHandler(undefined);
	}, [errorsHandler]);

	const onRemoveClickHandler = useCallback(async () => {
		if (onRemoveButtonClick) await onRemoveButtonClick();

		clearImageUrl();
	}, [clearImageUrl, onRemoveButtonClick]);

	return (
		<>
			<div className={className} {...rest}>
				<input
					{...inputSettings}
					type="file"
					ref={inputRef}
					accept="image/*"
					disabled={!!imageUrl}
					onChange={handleChange}
				/>

				{!imageUrl && !video ? (
					<label htmlFor={inputSettings?.name}>{inputSettings?.name}</label>
				) : null}

				{Children.map(children, (child) => {
					if (React.isValidElement(child)) {
						return React.cloneElement(child, {
							loading: loading || imageCompressionInProgress,
							imageUrl: imageUrl || image,
							videoUrl: video,
							onRemoveButtonClick: onRemoveClickHandler,
						});
					}
				})}
			</div>
		</>
	);
};

export default React.memo(UploadedImagePreviewHoc);
