/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useMemo } from 'react';
import { QueryParametersKey, StorageItemKeys } from '../../../constants';

import useUser from '../../use-user/useUser';
import { useDispatch } from 'react-redux';
import { setActiveCompany } from '../../../../store/slices/companies.slice';
import useCompanies from '../../use-companies/useCompanies';
import { processCompanyChange } from '../../../custom/common';
import { useLocation, useNavigate } from 'react-router-dom';

export const useCompanyFromUrl = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const { authorized } = useUser();
	const { companies } = useCompanies();

	const companyUniqueName = useMemo(() => {
		const urlConfigs = new URLSearchParams(location.search);
		return urlConfigs.get(QueryParametersKey.companyName);
	}, [location.search]);

	useEffect(() => {
		if (companyUniqueName && authorized) {
			const activeCompany = companies?.find(({ id: itemId }) => itemId === companyUniqueName);

			if (activeCompany) {
				dispatch(setActiveCompany(activeCompany));

				processCompanyChange(activeCompany?.id);
			}

			navigate(location.pathname);
		}
	}, [authorized, companies, companyUniqueName, dispatch, location.pathname, navigate]);
};

export const useUpdateActiveCompanyInStorage = () => {
	const location = useLocation();
	const { authorized } = useUser();

	useEffect(() => {
		if (authorized) {
			const urlConfigs = new URLSearchParams(location.search);
			const companyName = urlConfigs.get(QueryParametersKey.companyName);

			if (companyName) {
				localStorage.setItem(
					StorageItemKeys.activeCompany,
					JSON.stringify({ id: companyName }),
				);
			}
		}
	}, [authorized, location.search]);
};
