import { useReducer } from 'react';

import { paginationReducer } from '../../../store/reducers/pagination-reducer';

const usePaginationParams = () => {
	const paginationReducerInitialState = {
		pagination: undefined,
	};

	const [state, dispatch] = useReducer(paginationReducer, paginationReducerInitialState);

	return { state, dispatch };
};

export default usePaginationParams;
