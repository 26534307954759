import React, { FC, useCallback, useMemo } from 'react';
import classNames from 'classnames';

import PaginationInterface from './pagination.interface';

import styles from './Pagination.module.scss';
import PageItem from '../../atoms/page-item';

const Pagination: FC<PaginationInterface> = ({
	currentPageNumber,
	moveToPrevPage,
	moveToNextPage,
	hasNextPage,
	className,
	loading,
}) => {
	const prevDisabled = useMemo(
		() => loading || currentPageNumber === 1,
		[currentPageNumber, loading],
	);
	const nextDisabled = useMemo(() => loading || !hasNextPage, [hasNextPage, loading]);

	const onPrevClick = useCallback(() => {
		if (!prevDisabled) moveToPrevPage();
	}, [moveToPrevPage, prevDisabled]);

	const onNextClick = useCallback(() => {
		if (!nextDisabled) moveToNextPage();
	}, [moveToNextPage, nextDisabled]);

	return (
		<div className={classNames(styles.pagination, className)}>
			<PageItem navigationType="prev" disabled={prevDisabled} onClick={onPrevClick} />

			<PageItem text={'Page ' + currentPageNumber} navigationType="pageNumber" />

			<PageItem navigationType="next" disabled={nextDisabled} onClick={onNextClick} />
		</div>
	);
};

export default React.memo(Pagination);
