import React, { FC, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
	CompanyInterface,
	editCompanyDeleteImages,
	editCompanyUploadImages,
} from '../../../../store/slices/companies.slice';
import { RootStateType } from '../../../../store/reducers/root-reducer';
import EditableViewForm from '../../editable-view-form';
import useActiveCompany from '../../../../helpers/hooks/use-active-company/useActiveCompany';
import UploadedImagePreview from '../../uploaded-image-preview';

import styles from './CompanyPhotosGrid.module.scss';

const CompanyPhotosGrid: FC = () => {
	const { handleSubmit } = useForm();
	const dispatch = useDispatch();
	const {
		companies: { activeCompanyId, items, imageLoading },
	} = useSelector((state: RootStateType) => state);
	const activeCompany = useActiveCompany(activeCompanyId, items);

	const [imageErrors, setImageErrors] = useState<string | undefined>();

	const onSubmit = useCallback(
		async (data: Partial<CompanyInterface>, ...rest: any) => {
			if (activeCompany)
				await dispatch(editCompanyUploadImages(activeCompany.id, data, rest[0]));
		},
		[activeCompany, dispatch],
	);

	const removeImage = useCallback(
		async (target: string) => {
			setImageErrors(undefined);

			if (activeCompanyId)
				return await dispatch(editCompanyDeleteImages(activeCompanyId, target));
		},
		[activeCompanyId, dispatch],
	);

	const isTargetLoading = useCallback(
		(target: string) => !!imageLoading.find((item: any) => item.target === target),
		[imageLoading],
	);

	return (
		<>
			<EditableViewForm
				formClassName={styles.wrap}
				addEditableViewFormItemClass={false}
				hasEditButton={false}
				sectionTitle="Photos"
				desc="Upload images with recommended dimensions."
				onSubmit={handleSubmit(onSubmit)}
			>
				<UploadedImagePreview
					inputSettings={{ name: 'image1Id', id: 'image1Id' }}
					image={activeCompany?.image1Url}
					video={activeCompany?.videoUrl}
					description="750px x 460px or larger"
					className={styles.item}
					uploadHandler={onSubmit}
					onRemoveButtonClick={removeImage.bind(null, 'image1Id')}
					loading={isTargetLoading('image1Id')}
					errorsHandler={setImageErrors}
				/>

				<UploadedImagePreview
					inputSettings={{ name: 'image2Id', id: 'image2Id' }}
					image={activeCompany?.image2Url}
					className={styles.item}
					uploadHandler={onSubmit}
					description={'326px x 225px <br/>or larger'}
					onRemoveButtonClick={removeImage.bind(null, 'image2Id')}
					loading={isTargetLoading('image2Id')}
					errorsHandler={setImageErrors}
				/>

				<UploadedImagePreview
					inputSettings={{ name: 'image3Id', id: 'image3Id' }}
					image={activeCompany?.image3Url}
					className={styles.item}
					description={'326px x 225px <br/>or larger'}
					uploadHandler={onSubmit}
					onRemoveButtonClick={removeImage.bind(null, 'image3Id')}
					loading={isTargetLoading('image3Id')}
					errorsHandler={setImageErrors}
				/>
			</EditableViewForm>

			{imageErrors ? <div className={styles.error}>{imageErrors}</div> : null}
		</>
	);
};

export default CompanyPhotosGrid;
