import React, { FC, useContext, useMemo } from 'react';

import { calculatePercent } from '../../../../../helpers/charts';
import YearsExperienceChartInterface from './years-experience-chart.interface';
import ResizeDeviceContext from '../../../../../contexts/resize-device-context';
import Title from '../../../../atoms/title/Title';
import HorizontalRangeBar from '../../../../atoms/ui/chart-elements/horizontal-range-bar';
import HorizontalProgressBar from '../../../../atoms/ui/chart-elements/horizontal-progress-bar';
import ChartLabel from '../../../../atoms/ui/chart-elements/chart-label';
import HorizontalRail from '../../../../molecules/ui/charts/horizontal-rail';

import styles from './YearsExperienceChart.module.scss';

const YearsExperienceChart: FC<YearsExperienceChartInterface> = ({
	title,
	rangeLabel,
	maxValue,
	labels,
	valueLabels,
	customValueLabels,
	values,
	crossing,
}) => {
	const device = useContext(ResizeDeviceContext);

	const valueLabelElements = useMemo(
		() =>
			customValueLabels
				? customValueLabels?.map((item, index) => (
						<span
							key={index}
							className={styles['value-label']}
							style={{
								position: 'absolute',
								left: `${calculatePercent(Number(valueLabels[index]), maxValue)}%`,
							}}
						>
							{item}
						</span>
				  ))
				: valueLabels?.map((item, index) => (
						<span
							key={index}
							className={styles['value-label']}
							style={{
								position: 'absolute',
								left: `${calculatePercent(Number(item), maxValue)}%`,
							}}
						>
							{item}
						</span>
				  )),
		[customValueLabels, maxValue, valueLabels],
	);

	return (
		<section>
			{(title || crossing) && (
				<div className={styles.top}>
					{title && (
						<Title
							component="h3"
							fontSize={14}
							fontWeight={600}
							className={styles['answer-section__title']}
						>
							{title}
						</Title>
					)}

					{rangeLabel && device !== 'mobile' && device !== 'tablet' && (
						<div className={styles['range-info']}>
							<HorizontalRangeBar preview {...{ maxValue }} />{' '}
							<span className={styles['range-info__label']}>{rangeLabel}</span>
						</div>
					)}
				</div>
			)}

			<div className={styles.wrap}>
				<div className={styles.labels}>
					{labels?.map((item, index) => (
						<ChartLabel className={styles.label} key={index}>
							{item}
						</ChartLabel>
					))}
				</div>

				<div className={styles.body}>
					<div className={styles.chart}>
						{values?.map((item, index) => (
							<div key={index} className={styles.row}>
								{device !== null && device !== 'desktop' && (
									<div className={styles.top}>
										<ChartLabel className={styles.label}>
											{labels[index]}
										</ChartLabel>

										{rangeLabel && (
											<div className={styles['range-info']}>
												<HorizontalRangeBar preview {...{ maxValue }} />{' '}
												<span className={styles['range-info__label']}>
													{rangeLabel}
												</span>
											</div>
										)}
									</div>
								)}

								<HorizontalRail>
									<HorizontalProgressBar maxItemValue={item} {...{ maxValue }} />

									{crossing && (
										<HorizontalRangeBar
											maxItemValue={item}
											{...{ maxValue, crossing }}
										/>
									)}
								</HorizontalRail>
							</div>
						))}
					</div>

					<div className={styles['labels-wrap']}>{valueLabelElements}</div>
				</div>
			</div>
		</section>
	);
};

export default YearsExperienceChart;
