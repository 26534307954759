import React, { FC, useMemo } from 'react';
import classNames from 'classnames';

import DownloadItemInterface from './download-item.interface';
import Title from '../../atoms/title/Title';
import TypographyText from '../../atoms/ui/typography-text';

import styles from './DownloadItem.module.scss';

const DownloadItem: FC<DownloadItemInterface> = ({
	centeredContent,
	title,
	description,
	autoHeight,
	bottomElement,
	containingDataItems,
}) => {
	const containingDataItemElements = useMemo(
		() =>
			containingDataItems?.map((item, index) => (
				<li className={styles['list-item']} key={index}>
					{item}
				</li>
			)),
		[containingDataItems],
	);

	return (
		<div
			className={classNames(
				styles.wrap,
				autoHeight && styles['auto-height'],
				centeredContent && styles['centered-content'],
			)}
		>
			<Title
				className={styles.title}
				component="h3"
				marginBottom={10}
				fontSize={20}
				fontWeight={600}
			>
				{title}
			</Title>

			{description && <TypographyText className={styles.desc}>{description}</TypographyText>}

			{containingDataItemElements && (
				<ul className={styles.list}>{containingDataItemElements}</ul>
			)}

			<div className={styles.bottom}>{bottomElement}</div>
		</div>
	);
};

export default DownloadItem;
