import OptionInterface from '../../components/atoms/form/option/option.interface';
import { SliderElementWithDynamicStateDisplayingMarkType } from '../../components/organisms/form-elemets/slider-element-with-dynamic-state-displaying/slider-element-with-dynamic-state-displaying.interface';
import cincLogo from '../../assets/icons/logo-cinc-by-engin.svg';
import enginLogo from '../../assets/icons/multisite/hire-engin/engin-logo-colored.svg';

export const BADGES_DISPLAY_COUNT = 3;
export const PAGE_SIZE = 15;
export const CREDITS_MIN_VALUE = 150;
export const CREDITS_MAX_VALUE = 1200;
export const CREDITS_STEP = 50;
export const CREDITS_CALCULATIONS_STEP = 150;
export const MAX_OPENINGS_NUMBER = 10;
export const TRANSACTIONS_PAGINATION_PAGE_SIZE = 5;
export const BOOST_PAGINATION_PAGE_SIZE = 5;
export const DEFAULT_APPLICATIONS_NUMBER = 25;
export const APPLICANTS_PER_HIRE_COEF = 0.8;

export const COMPANIES_DROPDOWN_MIN_NUMBER = 5;
export const COMPANIES_DROPDOWN_MAX_NUMBER = 10;

export const salaryUnits: OptionInterface[] = [
	{
		text: 'Hourly',
		value: 'hourly',
	},
	{
		text: 'Daily',
		value: 'daily',
	},
	{
		text: 'Weekly',
		value: 'weekly',
	},
	{
		text: 'Monthly',
		value: 'monthly',
	},
	{
		text: 'Yearly',
		value: 'yearly',
	},
];

export const CandidatesStatusValues = {
	applied: 0,
	rejected: 5,
	shortListed: 10,
	contacted: 15,
	interviewed: 20,
	offered: 25,
	hired: 30,
};

export const candidatesStatusLabels: OptionInterface[] = [
	{
		text: 'Applied',
		value: CandidatesStatusValues.applied,
	},
	{
		text: 'Rejected',
		value: CandidatesStatusValues.rejected,
	},
	{
		text: 'Short-listed',
		value: CandidatesStatusValues.shortListed,
	},
	{
		text: 'Contacted',
		value: CandidatesStatusValues.contacted,
	},
	{
		text: 'Interviewed',
		value: CandidatesStatusValues.interviewed,
	},
	{
		text: 'Offered',
		value: CandidatesStatusValues.offered,
	},
	{
		text: 'Hired',
		value: CandidatesStatusValues.hired,
	},
];

export const candidatesViewFilterLabels = [
	{
		text: 'All candidates',
		value: 'all',
	},
	{
		text: 'All relevant',
		value: 'relevant',
	},
	{
		text: 'All open',
		value: 'open',
		indent: 1,
	},
	{
		text: 'Needs review',
		value: 'needreview',
		indent: 2,
	},
	{
		text: 'Short-listed',
		value: 'shortlisted',
		indent: 2,
	},
	{
		text: 'Contacted',
		value: 'contacted',
		indent: 2,
	},
	{
		text: 'Interviewed',
		value: 'interviewed',
		indent: 2,
	},
	{
		text: 'Offered',
		value: 'offered',
		indent: 2,
	},
	{
		text: 'Hired',
		value: 'hired',
		indent: 1,
	},
	{
		text: 'Rejected',
		value: 'rejected',
		indent: 1,
	},
	{
		text: 'Not relevant',
		value: 'notrelevant',
	},
];

export type UsaStatesType = {
	text: string;
	value: string;
	centerCoordinates: any[];
	zoom?: number;
};

export const usaStates: UsaStatesType[] = [
	{
		text: 'Alabama',
		value: 'AL',
		centerCoordinates: [-86.902298, 32.718231],
		zoom: 6,
	},
	{
		text: 'Alaska',
		value: 'AK',
		centerCoordinates: [-154.493062, 63.588753],
		zoom: 5,
	},
	{
		text: 'American Samoa',
		value: 'AS',
		centerCoordinates: [],
	},
	{
		text: 'Arizona',
		value: 'AZ',
		centerCoordinates: [-111.893731, 34.248928],
		zoom: 5,
	},
	{
		text: 'Arkansas',
		value: 'AR',
		centerCoordinates: [-92.531833, 34.80105],
		zoom: 8.6,
	},
	{
		text: 'California',
		value: 'CA',
		centerCoordinates: [-119.417932, 37.378261],
		zoom: 2.8,
	},
	{
		text: 'Colorado',
		value: 'CO',
		centerCoordinates: [-105.682067, 39.050051],
		zoom: 6.8,
	},
	{
		text: 'Connecticut',
		value: 'CT',
		centerCoordinates: [-72.787749, 41.603221],
		zoom: 19,
	},
	{
		text: 'Delaware',
		value: 'DE',
		centerCoordinates: [-75.52767, 39.150832],
		zoom: 22,
	},
	{
		text: 'District of Columbia',
		value: 'DC',
		centerCoordinates: [-77.033418, 38.901085],
		zoom: 140,
	},
	{
		text: 'Florida',
		value: 'FL',
		centerCoordinates: [-83.915754, 27.964827],
		zoom: 5,
	},
	{
		text: 'Georgia',
		value: 'GA',
		centerCoordinates: [-83.507123, 32.857435],
		zoom: 6,
	},
	{
		text: 'Hawaii',
		value: 'HI',
		centerCoordinates: [-155.665857, 19.898682],
		zoom: 8,
	},
	{
		text: 'Idaho',
		value: 'ID',
		centerCoordinates: [-114.742041, 45.368202],
		zoom: 4,
	},
	{
		text: 'Illinois',
		value: 'IL',
		centerCoordinates: [-89.398528, 39.733125],
		zoom: 5,
	},
	{
		text: 'Indiana',
		value: 'IN',
		centerCoordinates: [-86.502364, 39.751217],
		zoom: 7,
	},
	{
		text: 'Iowa',
		value: 'IA',
		centerCoordinates: [-93.697702, 41.878003],
		zoom: 7.5,
	},
	{
		text: 'Kansas',
		value: 'KS',
		centerCoordinates: [-98.484246, 38.411902],
		zoom: 7,
	},
	{
		text: 'Kentucky',
		value: 'KY',
		centerCoordinates: [-85.8975626570789, 37.61653172573641],
		zoom: 7,
	},
	{
		text: 'Louisiana',
		value: 'LA',
		centerCoordinates: [-91.69263545847072, 31.084096790489017],
		zoom: 7.5,
	},
	{
		text: 'Maine',
		value: 'ME',
		centerCoordinates: [-69.43221482183442, 45.20375026242542],
		zoom: 6.8,
	},
	{
		text: 'Maryland',
		value: 'MD',
		centerCoordinates: [-77.25152674867803, 38.99795567448747],
		zoom: 11,
	},
	{
		text: 'Massachusetts',
		value: 'MA',
		centerCoordinates: [-71.55719812128746, 42.165081224150875],
		zoom: 15,
	},
	{
		text: 'Michigan',
		value: 'MI',
		centerCoordinates: [-85.69988648852181, 44.81979874102984],
		zoom: 5,
	},
	{
		text: 'Minnesota',
		value: 'MN',
		centerCoordinates: [-94.29287516557103, 46.259142588532924],
		zoom: 5,
	},
	{
		text: 'Mississippi',
		value: 'MS',
		centerCoordinates: [-89.49591989701614, 32.669045856838935],
		zoom: 6,
	},
	{
		text: 'Missouri',
		value: 'MO',
		centerCoordinates: [-92.60634881588616, 38.38947868397046],
		zoom: 6.5,
	},
	{
		text: 'Montana',
		value: 'MT',
		centerCoordinates: [-109.66737261015693, 47.04516669535411],
		zoom: 5,
	},
	{
		text: 'Nebraska',
		value: 'NE',
		centerCoordinates: [-99.901813, 41.492537],
		zoom: 7,
	},
	{
		text: 'Nevada',
		value: 'NV',
		centerCoordinates: [-116.59379894563054, 38.69485375638463],
		zoom: 4,
	},
	{
		text: 'New Hampshire',
		value: 'NH',
		centerCoordinates: [-71.5077902088036, 43.920826635971],
		zoom: 11,
	},
	{
		text: 'New Jersey',
		value: 'NJ',
		centerCoordinates: [-74.78734121316744, 40.124014960452634],
		zoom: 12,
	},
	{
		text: 'New Mexico',
		value: 'NM',
		centerCoordinates: [-105.83692645937823, 34.4588700356266],
		zoom: 5.5,
	},
	{
		text: 'New York',
		value: 'NY',
		centerCoordinates: [-75.48635519595015, 43.05676071963428],
		zoom: 7,
	},
	{
		text: 'North Carolina',
		value: 'NC',
		centerCoordinates: [-79.73914812755952, 35.566369164785975],
		zoom: 5.5,
	},
	{
		text: 'North Dakota',
		value: 'ND',
		centerCoordinates: [-100.32251514256565, 47.371506508741916],
		zoom: 8,
	},
	{
		text: 'Commonwealth of the Northern Mariana Islands',
		value: 'MP',
		centerCoordinates: [],
	},
	{
		text: 'Ohio',
		value: 'OH',
		centerCoordinates: [-82.92236852184541, 40.33493102411387],
		zoom: 8,
	},
	{
		text: 'Oklahoma',
		value: 'OK',
		centerCoordinates: [-98.67545579202813, 35.35961257568161],
		zoom: 6,
	},
	{
		text: 'Oregon',
		value: 'OR',
		centerCoordinates: [-120.77875123356924, 44.061245504539926],
		zoom: 6,
	},
	{
		text: 'Pennsylvania',
		value: 'PA',
		centerCoordinates: [-77.7204465194313, 40.75286876504929],
		zoom: 9,
	},
	{
		text: 'Puerto Rico',
		value: 'PR',
		centerCoordinates: [-66.664513, 18.200178],
	},
	{
		text: 'Rhode Island',
		value: 'RI',
		centerCoordinates: [-71.477429, 41.580095],
		zoom: 32,
	},
	{
		text: 'South Carolina',
		value: 'SC',
		centerCoordinates: [-81.18554763805969, 33.6365750414912],
		zoom: 10,
	},
	{
		text: 'South Dakota',
		value: 'SD',
		centerCoordinates: [-100.0501929859964, 44.2136902112126],
		zoom: 7,
	},
	{
		text: 'Tennessee',
		value: 'TN',
		centerCoordinates: [-86.13516800891307, 35.726409912506895],
		zoom: 6,
	},
	{
		text: 'Texas',
		value: 'TX',
		centerCoordinates: [-99.98735564620175, 31.321565676614405],
		zoom: 2.7,
	},
	{
		text: 'Utah',
		value: 'UT',
		centerCoordinates: [-111.61181570985828, 39.47738394282882],
		zoom: 5.5,
	},
	{
		text: 'Vermont',
		value: 'VT',
		centerCoordinates: [-72.7417045291913, 43.95583509240666],
		zoom: 12,
	},
	{
		text: 'Virginia',
		value: 'VA',
		centerCoordinates: [-79.39343031147394, 37.625019900303926],
		zoom: 6,
	},
	{
		text: 'Washington',
		value: 'WA',
		centerCoordinates: [-120.65521407731576, 47.307244157723424],
		zoom: 7,
	},
	{
		text: 'West Virginia',
		value: 'WV',
		centerCoordinates: [-80.45532369218073, 38.79974889481805],
		zoom: 8,
	},
	{
		text: 'Wisconsin',
		value: 'WI',
		centerCoordinates: [-89.88766499457672, 44.73360402223607],
		zoom: 6,
	},
	{
		text: 'Wyoming',
		value: 'WY',
		centerCoordinates: [-107.6875266153104, 43.0410941923623],
		zoom: 6,
	},
	{
		text: 'Guam',
		value: 'GU',
		centerCoordinates: [],
	},
	{
		text: 'United States Virgin Islands',
		value: 'VI',
		centerCoordinates: [],
	},
];

export const remoteUSAStates = ['AS', 'GU', 'MP', 'PR', 'VI', 'AK', 'HI'];

export const expectedSeniorityMarks: SliderElementWithDynamicStateDisplayingMarkType[] = [
	{
		number: 10,
		label: 'Entry level (less than 2 years)',
		rangeLabel: '0-2 expected',
		range: [0, 2],
		value: 0,
	},
	{
		number: 50,
		range: [2, 5],
		rangeLabel: '2-5 expected',
		label: 'Mid-level (2-5 years)',
		value: 2,
	},
	{
		number: 90,
		label: 'Senior (5+ years)',
		rangeLabel: '5+ expected',
		range: [5, 20],
		value: 5,
	},
];

export const expectedCannabisIndustrySeniorityMarks: SliderElementWithDynamicStateDisplayingMarkType[] =
	[
		{
			number: 10,
			label: 'None',
			rangeLabel: 'None expected',
			value: 0,
		},
		{
			number: 50,
			label: 'Some (1-3 years)',
			rangeLabel: 'Some expected',
			range: [1, 3],
			value: 1,
		},
		{
			number: 90,
			label: 'A lot (3+ years)',
			rangeLabel: 'A lot expected',
			range: [3, 5],
			value: 3,
		},
	];

export type StatusOptionType = {
	label: string;
	status: number;
};

export const LocationRequirementsValues = {
	onSite: 0,
	hybrid: 5,
	remote: 10,
};

export const JobsTypesValues = {
	engin: 'engin',
	external: 'external',
	all: 'all',
};

export const BoostPeriod = {
	twoWeeks: [14, 'day'],
	oneMonth: [1, 'month'],
};

//if text in editor was deleted, there is still empty paragraph added as a value
export const EDITOR_EMPTY_STATE = '<p></p>\n';

export const FitScoreLabelsText = {
	personality: 'Soft skills',
	skills: 'Experience',
	commute: 'Commute',
};

export const BrandingSettings = {
	cinc: {
		color: '#4986A1',
		logo: cincLogo,
	},
	engin: {
		color: '#000000',
		logo: enginLogo,
	},
};

export const BrandingNames = {
	cinc: 'carreers_in_cannabis',
	engin: 'engin',
};

export const SignUpOnDemandSteps = {
	getStarted: 1,
	validateEmail: 2,
	companyDetails: 3,
};

export const BEEventTypes = {
	customerRegistration: 'customer_registration',
};

export const AppProducts = {
	postJob: 'post-a-job',
};

export const PaymentResultStatus = {
	success: 10,
	cancel: 20,
};

export const QueryParametersKey = {
	tab: 'tab',
	paymentResult: 'p_result',
	sessionId: 'session_id',
	utmSource: 'utm_source',
	branding: 'branding',
	autologin: 'autologin',
	action: 'action',
	companyName: 'company',
	secondaryFilter: 'secondary_filter',
	filter: 'filter',
	sort: 'sort',
	text: 'text',
	invalid: 'invalid',
	postAJob: 'post_job',
	completePostAJob: 'complete_post',
	fromWidget: 'from_widget',
	descriptionUuid: 'gen_desc_i',
	widgetDeviceId: 'gen_desc_did',
};

export const StorageItemKeys = {
	boostOpenings: 'boost-openings',
	boostCredits: 'boost-credits',
	originTag: 'originTag',
	redirection: 'redirection',
	filterSettings: 'filterSettings',
	activeCompany: 'activeCompany',
	recentCompanies: 'recentCompanies',
	authToken: 'auth-token',
	firstName: 'en_f_name',
	lastName: 'en_l_name',
	newInPostAJob: 'en_new_post',
	sawSparke: 'saw_sparke',
	companyKeywords: 'c_keywords',
	deviceId: 'en_did',
};

export const CookieNames = {
	deviceId: 'en_did',
};
