import React, { FC } from 'react';

import StatisticTopBarItemInterface from './statistic-top-bar-item.interface';
import TypographyText from '../../../atoms/ui/typography-text';
import ColoredNumber from '../../../atoms/ui/colored-number';
import Loader from '../../loader';

import styles from './StatisticTopBarItem.module.scss';

const StatisticTopBarItem: FC<StatisticTopBarItemInterface> = ({
	number,
	text,
	numberColor,
	loading,
}) => {
	return (
		<div className={styles['statistic-top-bar-item']}>
			{loading || numberColor === undefined ? (
				<Loader color={numberColor} transparentColor="#525668" />
			) : (
				<ColoredNumber color={numberColor}>{number}</ColoredNumber>
			)}

			<TypographyText className={styles.text}>{text}</TypographyText>
		</div>
	);
};

export default StatisticTopBarItem;
