import React, { FC } from 'react';
import classNames from 'classnames';

import TextareaInterface from './textarea.interface';

import styles from './Textarea.module.scss';

const Textarea: FC<TextareaInterface> = React.forwardRef(({ className, ...rest }, ref) => {
	return <textarea className={classNames(styles.textarea, className)} ref={ref} {...rest} />;
});

export default Textarea;
