import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import Fieldset from '../../../form-elemets/fieldset';
import Legend from '../../../../atoms/form/legend';
import TextEditor from '../../../form-elemets/text-editor';
import JobDetailsInterface from './job-details.interface';
import { editorEmptyValidation, requireValidation } from '../../../../../helpers/forms/forms';
import Error from '../../../../molecules/ui/error';
import styles from './JobDetails.module.scss';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { setErrors } from '../../../../../store/slices/job.slice';
import { getErrorsByProperty, removeErrorsForProperty } from '../../../../../helpers/custom/common';
import Container from '../../../../templates/container';
import { jobDetailsGA4Event } from '../../../../../helpers/google-analytics-4/events';
import useJob from '../../../../../helpers/hooks/use-job/useJob';
import useCompanies from '../../../../../helpers/hooks/use-companies/useCompanies';
import useActiveCompany from '../../../../../helpers/hooks/use-active-company/useActiveCompany';
import {
	GAContentGroup,
	GAContentId,
	GAContentType,
} from '../../../../../helpers/google-analytics-4/index.constants';
import { getCompanyDataForGA } from '../../../../../helpers/google-analytics-4/helpers';
import Button from '../../../../atoms/buttons/button/Button';
import { GeneratedJobDescriptionInterface } from '../../../../../helpers/custom/job';

const JobDetails: FC<JobDetailsInterface> = ({
	register,
	className,
	setFormState,
	formState,
	errors,
	trigger,
	brandingName,
	isEditMode,
	regenerateJobDescription,
}) => {
	const dispatch = useDispatch();

	const { apiErrors } = useJob();
	const { activeCompanyId, companies } = useCompanies();
	const activeCompany = useActiveCompany(activeCompanyId, companies);

	const [summaryCustomError, setSummaryCustomError] = useState(false);
	const [clear, setClear] = useState(false);
	const [localFieldsState, setLocalFieldsState] =
		useState<Partial<GeneratedJobDescriptionInterface>>();

	const [emptyData, setEmptyData] = useState(false);

	const defaultValues = useMemo(
		() => {
			return {
				summary: formState?.summary,
				mustHaveSkills: formState?.mustHaveSkills,
				niceToHaveSkills: formState?.niceToHaveSkills,
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	const fieldsErrors = useMemo(
		() => (apiErrors?.length ? getErrorsByProperty(apiErrors) : {}),
		[apiErrors],
	);

	const renderError = useCallback((errorMessage: string, errorConstraints?: {}) => {
		return (
			<Error className={styles.error}>
				{errorMessage || (errorConstraints && Object.values(errorConstraints))}
			</Error>
		);
	}, []);

	const clearJobDescription = useCallback(() => {
		setFormState((prev) => ({
			...prev,
			summary: '',
			mustHaveSkills: '',
			niceToHaveSkills: '',
		}));

		setLocalFieldsState({
			summary: '',
			mustHaveSkills: '',
			niceToHaveSkills: '',
		});

		setClear(true);
		setSummaryCustomError(true);
	}, [setFormState]);

	useEffect(() => {
		if (errors && errors.summary) setSummaryCustomError(true);
	}, [errors]);

	useEffect(() => {
		if (activeCompany) {
			jobDetailsGA4Event(
				{
					companyData: getCompanyDataForGA(activeCompany),
					contentData: {
						id: brandingName ? GAContentId.postAJob : GAContentId.job,
						group: brandingName ? GAContentGroup.postAJob : GAContentGroup.job,
						type: isEditMode ? GAContentType.update : GAContentType.create,
					},
				},
				brandingName,
			);
		}
	}, [activeCompany, brandingName, isEditMode]);

	useEffect(() => {
		if (clear) setSummaryCustomError(true);
	}, [clear]);

	useEffect(() => {
		const { summary, mustHaveSkills, niceToHaveSkills } = defaultValues || {};
		const {
			summary: localSummary,
			mustHaveSkills: localMustHaveSkills,
			niceToHaveSkills: localNiceToHaveSkills,
		} = localFieldsState || {};

		if (
			!summary &&
			!mustHaveSkills &&
			!niceToHaveSkills &&
			!localSummary &&
			!localMustHaveSkills &&
			!localNiceToHaveSkills
		)
			setEmptyData(true);
		else setEmptyData(false);
	}, [localFieldsState, defaultValues]);

	const renderForm = useMemo(() => {
		return (
			<>
				<Fieldset>
					<Fieldset
						className={classNames(
							styles['job-description'],
							summaryCustomError || (errors && errors.summary)
								? styles['with-error']
								: '',
						)}
					>
						<Legend extraSmall>Job description</Legend>

						<TextEditor
							clear={clear}
							placeholder="Enter a description of the job."
							defaultValue={localFieldsState?.summary || defaultValues.summary}
							onChangeHandler={(value) => {
								setClear(false);
								setSummaryCustomError(false);

								if (errors?.summary) {
									trigger('summary').then((validated: boolean) => {
										setSummaryCustomError(!validated);
									});
								}

								const newErrors = removeErrorsForProperty('summary', apiErrors);
								if (newErrors) dispatch(setErrors(newErrors));

								setFormState((prev) => ({ ...prev, summary: value }));
							}}
							textareaSettings={{
								ref: (ref) => {
									register(ref, {
										...requireValidation(),
										validate: editorEmptyValidation,
									});
								},
								name: 'summary',
							}}
						/>

						{summaryCustomError ||
						(fieldsErrors?.summary?.constraints &&
							Object.values(fieldsErrors?.summary?.constraints))
							? renderError(
									'This is a required field',
									fieldsErrors?.summary?.constraints,
							  )
							: null}
					</Fieldset>

					<Fieldset>
						<Legend extraSmall>Required skills</Legend>

						<TextEditor
							clear={clear}
							placeholder="Enter the skills required for this job. Skills will be listed with bullets."
							defaultValue={
								localFieldsState?.mustHaveSkills || defaultValues.mustHaveSkills
							}
							onChangeHandler={(value) => {
								setClear(false);

								setFormState((prev) => ({
									...prev,
									mustHaveSkills: value,
								}));
							}}
							textareaSettings={{
								ref: register,
								name: 'mustHaveSkills',
							}}
						/>

						{fieldsErrors?.mustHaveSkills?.constraints &&
						Object.values(fieldsErrors?.mustHaveSkills?.constraints)
							? renderError(fieldsErrors?.mustHaveSkills?.constraints)
							: null}
					</Fieldset>

					<Fieldset className={styles['desired-skills']}>
						<Legend extraSmall>Desired skills</Legend>

						<TextEditor
							clear={clear}
							placeholder="Enter the skills desired for this job. Skills will be listed with bullets."
							defaultValue={
								localFieldsState?.mustHaveSkills || defaultValues.niceToHaveSkills
							}
							onChangeHandler={(value) => {
								setClear(false);

								setFormState((prev) => ({
									...prev,
									niceToHaveSkills: value,
								}));
							}}
							textareaSettings={{
								ref: register,
								name: 'niceToHaveSkills',
							}}
						/>

						{fieldsErrors?.niceToHaveSkills?.constraints &&
						Object.values(fieldsErrors?.niceToHaveSkills?.constraints)
							? renderError(fieldsErrors?.niceToHaveSkills?.constraints)
							: null}
					</Fieldset>
				</Fieldset>
			</>
		);
	}, [
		apiErrors,
		clear,
		defaultValues.mustHaveSkills,
		defaultValues.niceToHaveSkills,
		defaultValues.summary,
		dispatch,
		errors,
		fieldsErrors?.mustHaveSkills?.constraints,
		fieldsErrors?.niceToHaveSkills?.constraints,
		fieldsErrors?.summary?.constraints,
		localFieldsState?.mustHaveSkills,
		localFieldsState?.summary,
		register,
		renderError,
		setFormState,
		summaryCustomError,
		trigger,
	]);

	return (
		<Container maxWidth={572} className={classNames(styles['job-details'], className)}>
			{renderForm}

			<div className={styles.actions}>
				<Button
					type="button"
					minWidth={300}
					maxWidth={300}
					minHeight={56}
					maxHeight={56}
					className={classNames(styles.btn, styles.sparkling)}
					onClick={() => {
						if (regenerateJobDescription) regenerateJobDescription();
					}}
				>
					{emptyData ? 'Generate it' : 'Regenerate it'}
				</Button>

				<Button
					disabled={emptyData}
					type="button"
					minWidth={300}
					maxWidth={300}
					minHeight={56}
					maxHeight={56}
					reversedStyles
					className={styles.btn}
					onClick={clearJobDescription}
				>
					Clear it
				</Button>
			</div>
		</Container>
	);
};

export default React.memo(JobDetails);
