import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import EditableViewForm from '../../editable-view-form';
import EditableAccountCommunicationsFormInterface from './editable-account-communications-form.interface';
import Switcher from '../../../molecules/form/switcher';
import {
	changeCommunicationPreferences,
	CommunicationPreferencesType,
	getCommunicationPreferences,
} from '../../../../store/slices/user.slice';

import styles from './EditableAccountCommunicationsForm.module.scss';

const EditableAccountCommunicationsForm: FC<EditableAccountCommunicationsFormInterface> = ({
	activeCompanyId,
	activeCompanyName,
}) => {
	const dispatch = useDispatch();

	const [communicationPreferences, setCommunicationPreferences] =
		useState<CommunicationPreferencesType>();

	useEffect(() => {
		if (activeCompanyId) {
			(async () => {
				const data = await dispatch(getCommunicationPreferences(activeCompanyId));
				if (data) setCommunicationPreferences(data as CommunicationPreferencesType);
			})();
		}
	}, [activeCompanyId, dispatch]);

	const onWeeklyDigestChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			if (activeCompanyId)
				dispatch(
					changeCommunicationPreferences(activeCompanyId, {
						weeklyDigest: event.currentTarget.checked,
					}),
				);
		},
		[activeCompanyId, dispatch],
	);

	return (
		<EditableViewForm
			hasEditButton={false}
			formClassName={styles.communications}
			addEditableViewFormItemClass={false}
			sectionTitle="Communications"
			headerClassName={styles['section-header']}
		>
			<div className={styles.switcher}>
				<Switcher
					id="weeklyDigest"
					label="Weekly digest email"
					inputSettings={{
						id: 'weeklyDigest',
						checked: communicationPreferences?.weeklyDigest,
						onChange: onWeeklyDigestChange,
					}}
					toggleClassName={styles.toggle}
				/>
			</div>

			<div className={styles.description}>
				The weekly digest email includes news and updates on all currently active jobs
				{activeCompanyName ? ` for ${activeCompanyName}` : ''}.
			</div>
		</EditableViewForm>
	);
};

export default React.memo(EditableAccountCommunicationsForm);
