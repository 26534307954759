import { configureStore } from '@reduxjs/toolkit';

import reducer from './reducers/root-reducer';

const env = process.env.REACT_APP_ENV;

const store = configureStore({
	reducer,
	devTools: env !== 'production',
});

export default store;
