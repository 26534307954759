import React, { useEffect, useRef } from 'react';

import styles from './TemplateTagDecorator.module.scss';

const TemplateTagDecorator = (props: any) => {
	const spanRef = useRef<HTMLSpanElement>(null);

	const selectAllTag = (e: React.MouseEvent<HTMLSpanElement>) => {
		const selection = window.getSelection();
		if (selection) selection.selectAllChildren(e.currentTarget);
	};

	const onPartialSelection = () => {
		const selection = window.getSelection();
		const range = new Range();

		const anchorNode = selection?.anchorNode;
		const focusNode = selection?.focusNode;

		if (selection && anchorNode && focusNode) {
			range.setStart(anchorNode, selection.anchorOffset);
			//@ts-ignore
			range.setEnd(focusNode, focusNode.length);

			if (range.collapsed) {
				range.setStart(focusNode, 0);
				range.setEnd(anchorNode, selection.anchorOffset);
			}

			selection.removeAllRanges();
			selection.addRange(range);
		}
	};

	useEffect(() => {
		if (spanRef.current) spanRef.current.onselectstart = () => false;
	});

	if (props?.children?.length > 1) return <span>{props.children}</span>;

	return (
		<span
			className={styles.item}
			ref={spanRef}
			onClick={selectAllTag}
			onMouseUp={onPartialSelection}
		>
			{props.children}
		</span>
	);
};

export default TemplateTagDecorator;
