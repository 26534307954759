import React, { useMemo } from 'react';

import { SlideStepperStepsType } from '../../components/organisms/slide-stepper/slide-stepper.interface';
import SlideFormLayout from '../../components/templates/custom/slide-form-layout';
import SlideStepper from '../../components/organisms/slide-stepper';
import Logo from '../../components/atoms/icons/logo';
import ChangeAuthorizedUserPasswordForm from '../../components/organisms/custom/change-authorized-user-password-form';

import styles from './ChangeAuthorizedUserPassword.module.scss';

const ChangeAuthorizedUserPassword = () => {
	const steps: SlideStepperStepsType[] = useMemo(
		() => [
			{
				stepName: 'email',
				component: <ChangeAuthorizedUserPasswordForm />,
			},
		],
		[],
	);

	return (
		<section className={styles.wrap}>
			<Logo type="simple" className={styles.logo} />

			<SlideFormLayout
				title="Change password"
				description="Enter your new password below. Passwords must be 8 or more characters long."
				className={styles.box}
			>
				<SlideStepper steps={steps} />
			</SlideFormLayout>
		</section>
	);
};

export default ChangeAuthorizedUserPassword;
