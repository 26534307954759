import React, { FC } from 'react';
import classNames from 'classnames';

import TypographyTextInterface from './typography-text.interface';

import styles from './TypographyText.module.scss';

const TypographyText: FC<TypographyTextInterface> = ({
	children,
	robotoFont,
	className,
	...rest
}) => {
	return (
		<div className={classNames(styles.text, robotoFont && styles.roboto, className)} {...rest}>
			{children}
		</div>
	);
};

export default TypographyText;
