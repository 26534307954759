import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CompaniesSliceInterface } from '../../../../store/slices/companies.slice';
import {
	getFacilitiesList,
	clearFacilitiesList,
	FacilitiesListSliceInterface,
	FacilityInterface,
} from '../../../../store/slices/facilities.slice';
import OptionInterface from '../../../atoms/form/option/option.interface';
import ViewSelectFilter from '../../../molecules/filters/view-select-filter/ViewSelectFilter';
import JobsListSearchFilterInterface from '../jobs-list-search-filter/jobs-list-search-filter.interface';
import {
	FilterParamsType,
	parseUrlParams,
	stringifyParamsForUrl,
} from '../../../../helpers/custom/url';
import { useLocation, useNavigate } from 'react-router-dom';

const JobsViewSelectFilter: FC<JobsListSearchFilterInterface> = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const [filter, setFilter] = useState<string | undefined>();
	const [options, setOptions] = useState<OptionInterface[]>([]);

	const {
		companies: { activeCompanyId },
		facilities: { loading: facilitiesLoading, items: facilitiesItems },
	} = useSelector(
		(store: { companies: CompaniesSliceInterface; facilities: FacilitiesListSliceInterface }) =>
			store,
	);

	useEffect(() => {
		if (activeCompanyId) dispatch(getFacilitiesList(activeCompanyId as string));
	}, [dispatch, activeCompanyId]);

	useEffect((): any => {
		return () => dispatch(clearFacilitiesList());
	}, [dispatch]);

	useEffect(() => {
		const { filter } = parseUrlParams<FilterParamsType>(location.search);

		setFilter(filter);
	}, [location.search]);

	const getOptions = useCallback(() => {
		const receivedOptions: OptionInterface[] =
			facilitiesItems &&
			facilitiesItems.map(({ id, city, state, name }: FacilityInterface) => ({
				text: name || `${city}${state && `, ${state}`}`,
				value: id,
			}));

		receivedOptions.unshift({
			text: 'All locations',
			value: '',
		});

		setOptions(receivedOptions);
	}, [setOptions, facilitiesItems]);

	const changeOption = useCallback(
		({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
			const filterParams = parseUrlParams<FilterParamsType>(location.search);
			filterParams.filter = value;

			navigate({
				pathname: location.pathname,
				search: stringifyParamsForUrl(filterParams),
			});
		},
		[location.pathname, location.search, navigate],
	);

	useEffect(() => getOptions(), [getOptions]);

	return (
		<ViewSelectFilter
			defaultValue={filter || ''}
			value={filter || ''}
			loading={facilitiesLoading}
			options={!facilitiesLoading ? options : []}
			onChange={changeOption}
		/>
	);
};

export default React.memo(JobsViewSelectFilter);
