import React, { FC } from 'react';
import classNames from 'classnames';

import DropdownToggleInterface from './dropdown-toggle.interface';
import Back from '../../back';

import styles from './DropdownToggle.module.scss';

const DropdownToggle: FC<DropdownToggleInterface> = ({ children, className, open, ...rest }) => {
	return (
		<span
			className={classNames(styles['dropdown-toggle'], open ? styles.open : '', className)}
			{...rest}
		>
			{children}

			<Back className={styles.chevron} />
		</span>
	);
};

export default DropdownToggle;
