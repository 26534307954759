import OptionInterface from '../../components/atoms/form/option/option.interface';
import { EDITOR_EMPTY_STATE } from '../constants';

const preventMinusEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
	const inputKeyCode = event.keyCode || event.which;

	if (inputKeyCode === 45) event.preventDefault();
};

const getPayloadWithoutEmptyAndSpecificFields = <T>(data: T, except?: string[]): T => {
	const result = {};

	for (const key in data) {
		// @ts-ignore
		if (data[key] && except && !except.find((item) => item === key)) {
			// @ts-ignore
			result[key] = data[key];
			// @ts-ignore
		} else if (data[key]) {
			// @ts-ignore
			result[key] = data[key];
		}
	}

	return result as T;
};

export const transformNumericFieldsOfPayload = <T>(data: T, numericFieldNames: string[]): T => {
	const response = {};

	for (const key in data) {
		if (Object.prototype.hasOwnProperty.call(data, key)) {
			if (numericFieldNames.indexOf(key) !== -1 && data[key]) {
				// @ts-ignore
				response[key] = Number(data[key]);
			} else if (numericFieldNames.indexOf(key) !== -1 && !data[key]) {
				// @ts-ignore
				response[key] = null;
			} else {
				// @ts-ignore
				response[key] = data[key];
			}
		}
	}

	return response as T;
};

export type ErrorType = { constraints: object; property: string };

export interface ErrorTargetByName {
	name: string;
	targets?: ErrorType[];
}

// eslint-disable-next-line no-unused-vars

const getObjectByNumberPosition = (array: any[], number: number) => {
	return array?.find(({ number: chosenNumber }) => chosenNumber === number);
};

const getObjectByValue = (array: any[], value: number) => {
	return array?.find(({ value: chosenValue }) => chosenValue === value);
};

const getErrorTargetByName = ({ targets, name }: ErrorTargetByName) => {
	return targets ? targets.find((item) => item.property === name) : undefined;
};

// Validation

const spacesValidation = {
	pattern: {
		value: /.*\S.*/,
		message: 'The field should have at least one letter',
	},
};

const requireValidation = (message: string = 'This is a required field') => ({
	required: {
		value: true,
		message,
	},
});

const editorEmptyValidation = (value: string) => value !== EDITOR_EMPTY_STATE;

const emailValidation = (message: string = 'Enter a valid e-mail address') => ({
	pattern: {
		value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
		message,
	},
});

const minLengthValidation = (
	value: number = 8,
	message: string = 'Field must be at least 8 characters long',
) => ({
	minLength: {
		value,
		message,
	},
});

const transformIntoSelectOptions = (data: Partial<OptionInterface>[]) => {
	const options: OptionInterface[] = [];

	for (let i = 0; i < data.length; i++) {
		const option: OptionInterface = {
			value: data[i].value,
			label: data[i].label,
			text: data[i].label,
		};
		options.push(option);
	}

	return options;
};

export type TargetErrorType = {
	constraints: {
		[key: string]: string;
	};
	property: string;
};

export const getTargetMessageByProperty = (
	property: string,
	errors?: TargetErrorType[],
): string | undefined => {
	const target = errors?.find((item) => item.property === property);

	if (target?.constraints) {
		return Object.values(target!.constraints).join(', ');
	}
};

export {
	preventMinusEnter,
	spacesValidation,
	requireValidation,
	emailValidation,
	minLengthValidation,
	getErrorTargetByName,
	getPayloadWithoutEmptyAndSpecificFields,
	getObjectByNumberPosition,
	getObjectByValue,
	transformIntoSelectOptions,
	editorEmptyValidation,
};
