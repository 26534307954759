import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { FacilityInterface, getFacilitiesList } from '../../../../../store/slices/facilities.slice';
import {
	LocationRequirementsValues,
	MAX_OPENINGS_NUMBER,
	salaryUnits,
} from '../../../../../helpers/constants';
import { preventMinusEnter } from '../../../../../helpers';
import { requireValidation, transformIntoSelectOptions } from '../../../../../helpers/forms/forms';
import useActiveCompany from '../../../../../helpers/hooks/use-active-company/useActiveCompany';
import InputWithFloatLabel from '../../../form-elemets/input-with-float-label/InputWithFloatLabel';
import SelectWithFloatLabel from '../../../form-elemets/select-with-float-label/SelectWithFloatLabel';
import OptionInterface from '../../../../atoms/form/option/option.interface';
import FacilityScreen from '../../facility-screen';
import Fieldset from '../../../form-elemets/fieldset';
import Loader from '../../../../molecules/loader';
import Legend from '../../../../atoms/form/legend';
import ButtonLink from '../../../../atoms/buttons/button-link';

import styles from './Basics.module.scss';
import CheckboxWithLabel from '../../../../molecules/form/checkbox-with-label/CheckboxWithLabel';
import BasicsInterface from './basics.interface';
import { JobFormModel } from '../../../../../helpers/custom/job';
import { getErrorsByProperty, removeErrorsForProperty } from '../../../../../helpers/custom/common';
import Error from '../../../../molecules/ui/error';
import { setErrors } from '../../../../../store/slices/job.slice';
import useJobsTemplates from '../../../../../helpers/hooks/use-jobs-templates/useJobsTemplates';
import useCompanies from '../../../../../helpers/hooks/use-companies/useCompanies';
import useJob from '../../../../../helpers/hooks/use-job/useJob';
import useFacilities from '../../../../../helpers/hooks/use-facilities/useFacilities';
import Container from '../../../../templates/container';
import { jobBasicsGA4Event } from '../../../../../helpers/google-analytics-4/events';
import {
	GAContentGroup,
	GAContentId,
	GAContentType,
} from '../../../../../helpers/google-analytics-4/index.constants';
import { getCompanyDataForGA } from '../../../../../helpers/google-analytics-4/helpers';

const Basics: FC<BasicsInterface> = ({
	setJobTitle,
	register,
	errors,
	trigger,
	className,
	setFormState,
	formState,
	hasPowerProfile,
	brandingName,
	isEditMode,
}) => {
	const dispatch = useDispatch();

	const [departmentsAndLevelsOptions, setDepartmentsAndLevelsOptions] = useState({
		departments: [] as OptionInterface[],
		levels: [] as OptionInterface[],
	});

	const [isAddFacilityScreenOpen, setIsAddFacilityScreenOpen] = useState(false);
	const [relocationAllowed, setRelocationAllowed] = useState(
		formState?.relocationAllowed || false,
	);
	const [relocationOptionDisabled, setRelocationOptionDisabled] = useState(
		formState?.locationType !== undefined &&
			+formState?.locationType === LocationRequirementsValues.remote,
	);

	const { departmentsAndLevels } = useJobsTemplates();
	const { activeCompanyId, companies } = useCompanies();
	const { apiErrors } = useJob();
	const { facilitiesItems, hasBeenFacilitiesLoaded } = useFacilities();

	const activeCompany = useActiveCompany(activeCompanyId, companies);

	const locationRequirementOptions = useMemo(
		() => [
			{
				text: 'On site',
				value: LocationRequirementsValues.onSite,
			},
			{
				text: 'Hybrid',
				value: LocationRequirementsValues.hybrid,
			},
			{
				text: 'Remote',
				value: LocationRequirementsValues.remote,
			},
		],
		[],
	);

	useEffect(() => {
		if (activeCompany && activeCompany.id) {
			dispatch(getFacilitiesList(activeCompany.id));
		}
	}, [dispatch, activeCompany]);

	useEffect(() => {
		if (departmentsAndLevels) {
			const selectOptions = {
				departments: departmentsAndLevels?.departments
					? transformIntoSelectOptions(departmentsAndLevels?.departments)
					: [],
				levels: departmentsAndLevels?.levels
					? transformIntoSelectOptions(departmentsAndLevels?.levels)
					: [],
			};

			setDepartmentsAndLevelsOptions(selectOptions);
		}
	}, [departmentsAndLevels]);

	useEffect(() => {
		if (activeCompany) {
			jobBasicsGA4Event(
				{
					companyData: getCompanyDataForGA(activeCompany),
					contentData: {
						id: brandingName ? GAContentId.postAJob : GAContentId.job,
						group: brandingName ? GAContentGroup.postAJob : GAContentGroup.job,
						type: isEditMode ? GAContentType.update : GAContentType.create,
					},
				},
				brandingName,
			);
		}
	}, [activeCompany, brandingName, isEditMode]);

	const fieldsErrors = useMemo(
		() => (apiErrors?.length ? getErrorsByProperty(apiErrors) : {}),
		[apiErrors],
	);

	const getFacilityOptions = useMemo(() => {
		return (
			facilitiesItems &&
			facilitiesItems.map(
				({ id, city, state, name }: FacilityInterface): OptionInterface => ({
					value: id,
					text: name || `${city}${state && `, ${state}`}`,
				}),
			)
		);
	}, [facilitiesItems]);

	const getFacilityLocations = useMemo(() => {
		return (
			facilitiesItems &&
			facilitiesItems.map(
				({ id, city, state }: FacilityInterface): OptionInterface => ({
					value: id,
					text: `${city}${state && `, ${state}`}`,
				}),
			)
		);
	}, [facilitiesItems]);

	const getDepartmentsAndLevelsError = useMemo(() => {
		if (fieldsErrors?.jobProfileId?.constraints) {
			const errorConstraints = Object.values(fieldsErrors.jobProfileId?.constraints);

			if (errorConstraints) {
				if (errorConstraints.length > 1) {
					return (
						<Error className={styles.error}>
							Incorrect combination of jobProfile and department and level
						</Error>
					);
				} else {
					return (
						<Error className={styles.error}>
							{Object.values(fieldsErrors.jobProfileId?.constraints)}
						</Error>
					);
				}
			}
		}
	}, [fieldsErrors?.jobProfileId?.constraints]);

	const getOpeningsOptions = useMemo(() => {
		return Array.from(
			new Array(MAX_OPENINGS_NUMBER),
			(x, i) =>
				({
					text: (i + 1).toString(),
					value: i + 1,
				} as OptionInterface),
		);
	}, []);

	const basicsForm = useMemo(() => {
		const minSalaryError =
			errors?.minSalaryValue?.message ||
			//@ts-ignore
			(fieldsErrors.minSalaryValue &&
				//@ts-ignore
				Object.values(fieldsErrors.minSalaryValue.constraints));

		return (
			<Fieldset>
				<Fieldset>
					<InputWithFloatLabel
						className={classNames(
							'form-item',
							'small-bottom-margin',
							errors.title ? styles['with-error'] : '',
						)}
						minWidth="calc(50% - 5px)"
						maxWidth="calc(50% - 5px)"
						id="job-title"
						ref={register(requireValidation())}
						label="Job title"
						error={
							errors?.title?.message ||
							//@ts-ignore
							(fieldsErrors.title && Object.values(fieldsErrors.title.constraints))
						}
						inputSettings={{
							name: 'title',
							defaultValue: formState?.title,
							onChange: ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
								if (setJobTitle) setJobTitle(value);

								setFormState((prev: JobFormModel) => ({
									...prev,
									title: value,
								}));
								if (errors?.title) trigger('title');

								const newErrors = removeErrorsForProperty('title', apiErrors);
								if (newErrors) dispatch(setErrors(newErrors));
							},
						}}
					/>

					<SelectWithFloatLabel
						className={classNames('form-item')}
						minWidth="calc(50% - 5px)"
						maxWidth="calc(50% - 5px)"
						ref={register(requireValidation())}
						error={
							errors?.employmentType?.message ||
							//@ts-ignore
							(fieldsErrors.employmentType &&
								//@ts-ignore
								Object.values(fieldsErrors.employmentType.constraints))
						}
						selectSettings={{
							useInternalState: true,
							options: [
								{
									text: 'Full-time',
									value: 0,
								},
								{
									text: 'Part-time',
									value: 10,
								},
							],
							name: 'employmentType',
							defaultValue: formState?.employmentType,
							value: formState?.employmentType,
							onChange: (event) => {
								setFormState((prev: any) => ({
									...prev,
									employmentType: event.target.value,
								}));

								if (errors?.employmentType) trigger('employmentType');

								const newErrors = removeErrorsForProperty(
									'employmentType',
									apiErrors,
								);
								if (newErrors) dispatch(setErrors(newErrors));
							},
						}}
						id="employment-type"
						label="Job type"
					/>

					<SelectWithFloatLabel
						className={classNames('form-item', 'disable-bottom-margin')}
						minWidth="calc(50% - 5px)"
						maxWidth="calc(50% - 5px)"
						id="number-of-openings"
						ref={register({
							min: {
								value: 1,
								message: 'The value should be greater than 0',
							},
							max: {
								value: MAX_OPENINGS_NUMBER,
								message: `The value should be equal or less than ${MAX_OPENINGS_NUMBER}`,
							},
						})}
						label="Number of openings"
						error={
							errors?.numberOfOpenings?.message ||
							//@ts-ignore
							(fieldsErrors.numberOfOpenings &&
								//@ts-ignore
								Object.values(fieldsErrors.numberOfOpenings.constraints))
						}
						selectSettings={{
							options: getOpeningsOptions,
							name: 'numberOfOpenings',
							defaultValue: formState?.numberOfOpenings,
							onChange: (event) => {
								setFormState((prev: any) => ({
									...prev,
									numberOfOpenings: event.target.value || undefined,
								}));
								if (errors?.numberOfOpenings) trigger('numberOfOpenings');

								const newErrors = removeErrorsForProperty(
									'numberOfOpenings',
									apiErrors,
								);
								if (newErrors) dispatch(setErrors(newErrors));
							},
						}}
					/>
				</Fieldset>

				<Fieldset>
					<Legend extraSmall className={styles['form-section']}>
						Location
					</Legend>

					<SelectWithFloatLabel
						className={classNames('form-item', 'small-bottom-margin')}
						minWidth="calc(50% - 5px)"
						maxWidth="calc(50% - 5px)"
						ref={register(requireValidation())}
						selectSettings={{
							options: getFacilityOptions,
							value: formState?.facilityId,
							name: 'facilityId',
							onChange: (event) => {
								setFormState((prev: any) => {
									const selectedValue = event.target.value;
									const facilityName = getFacilityLocations.find(
										(opt) => opt.value === +selectedValue,
									)?.text;
									return {
										...prev,
										facilityId: selectedValue,
										facilityName: facilityName,
									};
								});
								if (errors?.facilityId) trigger('facilityId');

								const newErrors = removeErrorsForProperty('facilityId', apiErrors);
								if (newErrors) dispatch(setErrors(newErrors));
							},
						}}
						id="facility"
						error={
							errors?.facilityId?.message ||
							//@ts-ignore
							(fieldsErrors.facilityId &&
								//@ts-ignore
								Object.values(fieldsErrors.facilityId.constraints))
						}
						label="Facility"
					/>

					<SelectWithFloatLabel
						className={classNames('form-item', 'disable-bottom-margin')}
						minWidth="calc(50% - 5px)"
						maxWidth="calc(50% - 5px)"
						ref={register(requireValidation())}
						error={
							errors?.locationType?.message ||
							//@ts-ignore
							(fieldsErrors.locationType &&
								//@ts-ignore
								Object.values(fieldsErrors.locationType.constraints))
						}
						selectSettings={{
							useInternalState: true,
							options: locationRequirementOptions,
							name: 'locationType',
							defaultValue: formState?.locationType,
							value: formState?.locationType,
							onChange: (e) => {
								const isRemote =
									e.target.value === LocationRequirementsValues.remote.toString();

								if (isRemote) {
									setRelocationAllowed(false);

									setFormState((prev: any) => ({
										...prev,
										relocationAllowed: false,
									}));
								}
								setRelocationOptionDisabled(isRemote);

								setFormState((prev: any) => ({
									...prev,
									locationType: e.target.value,
								}));

								if (errors?.locationType) trigger('locationType');

								const newErrors = removeErrorsForProperty(
									'locationType',
									apiErrors,
								);
								if (newErrors) dispatch(setErrors(newErrors));
							},
						}}
						id="location-type"
						label="Location Requirement"
					/>

					{facilitiesItems.length ? (
						<div className={styles['add-facility']}>
							Can't find the facility you're looking for?{' '}
							<ButtonLink
								type="button"
								onClick={() => setIsAddFacilityScreenOpen(true)}
							>
								Add a new one
							</ButtonLink>
						</div>
					) : (
						<div className={styles['add-facility']}>
							In order to specify the location of this job, you need a facility.{' '}
							<ButtonLink
								type="button"
								onClick={() => setIsAddFacilityScreenOpen(true)}
							>
								Add one now
							</ButtonLink>
						</div>
					)}

					<CheckboxWithLabel
						label={'Relocation allowed'}
						id="relocation-allowed"
						name="relocationAllowed"
						className={styles.checkbox}
						disabled={relocationOptionDisabled}
						defaultChecked={relocationAllowed}
						onChange={(e) => {
							setRelocationAllowed(e.target.checked);

							setFormState((prev: JobFormModel) => ({
								...prev,
								relocationAllowed: e.target.checked,
							}));
						}}
					/>
				</Fieldset>

				<Fieldset>
					<Legend extraSmall className={styles['form-section']}>
						Pay range
					</Legend>

					<div className={styles['salary-wrap']}>
						<InputWithFloatLabel
							className={classNames(
								'disable-bottom-margin',
								minSalaryError ? styles['with-error'] : '',
							)}
							minWidth="calc(20% - 20px)"
							maxWidth="calc(20% - 20px)"
							id="min-salary-value"
							ref={register({
								min: {
									value: 1,
									message: 'The value should be greater than 0',
								},
							})}
							label="Min: $"
							error={minSalaryError}
							inputSettings={{
								name: 'minSalaryValue',
								type: 'number',
								defaultValue: formState?.minSalaryValue || undefined,
								min: '0',
								step: 'any',
								onChange: (event) => {
									setFormState((prev: any) => ({
										...prev,
										minSalaryValue: event.target.value || null,
									}));
									if (errors?.minSalaryValue) trigger('minSalaryValue');

									const newErrors = removeErrorsForProperty(
										'minSalaryValue',
										apiErrors,
									);
									if (newErrors) dispatch(setErrors(newErrors));
								},
							}}
							onKeyPress={preventMinusEnter}
						/>

						<span>to</span>

						<InputWithFloatLabel
							className={classNames('disable-bottom-margin')}
							minWidth="calc(20% - 20px)"
							maxWidth="calc(20% - 20px)"
							id="max-salary-value"
							ref={register({
								min: {
									value: 1,
									message: 'The value should be greater than 0',
								},
							})}
							label="Max: $"
							error={
								errors?.maxSalaryValue?.message ||
								//@ts-ignore
								(fieldsErrors.maxSalaryValue &&
									//@ts-ignore
									Object.values(fieldsErrors.maxSalaryValue.constraints))
							}
							inputSettings={{
								name: 'maxSalaryValue',
								type: 'number',
								defaultValue: formState?.maxSalaryValue || undefined,
								min: '0',
								step: 'any',
								onChange: (event) => {
									setFormState((prev: any) => ({
										...prev,
										maxSalaryValue: event.target.value || null,
									}));
									if (errors?.maxSalaryValue) trigger('maxSalaryValue');

									const newErrors = removeErrorsForProperty(
										'maxSalaryValue',
										apiErrors,
									);
									if (newErrors) dispatch(setErrors(newErrors));
								},
							}}
							onKeyPress={preventMinusEnter}
						/>

						<SelectWithFloatLabel
							className={classNames('form-item', 'disable-bottom-margin')}
							minWidth="calc(20% - 20px)"
							maxWidth="calc(20% - 20px)"
							ref={register()}
							error={
								//@ts-ignore
								fieldsErrors.salaryUnit &&
								//@ts-ignore
								Object.values(fieldsErrors.salaryUnit.constraints)
							}
							selectSettings={{
								name: 'salaryUnit',
								options: salaryUnits,
								defaultValue: formState?.salaryUnit,
								onChange: (event) => {
									setFormState((prev: JobFormModel) => ({
										...prev,
										salaryUnit: event.target.value,
									}));
								},
							}}
							id="salaryUnit"
							label=""
						/>
					</div>
				</Fieldset>

				<Fieldset className={styles.department}>
					<Legend extraSmall className={styles['form-section']}>
						Department & Level
					</Legend>

					<SelectWithFloatLabel
						disabled={hasPowerProfile}
						className={classNames('form-item', 'disable-bottom-margin')}
						minWidth="calc(50% - 5px)"
						maxWidth="calc(50% - 5px)"
						ref={register(requireValidation())}
						error={
							errors?.department?.message ||
							//@ts-ignore
							(fieldsErrors.department &&
								//@ts-ignore
								Object.values(
									fieldsErrors.department.constraints['IsValidDepartment'],
								))
						}
						selectSettings={{
							useInternalState: true,
							options: departmentsAndLevelsOptions.departments,
							name: 'department',
							defaultValue:
								formState?.department === 0 ? undefined : formState?.department,
							onChange: (event) => {
								setFormState((prev: any) => ({
									...prev,
									department: Number(event.target.value),
								}));
								if (errors?.department) trigger('department');

								const newErrors = removeErrorsForProperty('department', apiErrors);
								if (newErrors) dispatch(setErrors(newErrors));
							},
						}}
						id="department"
						label="Department"
					/>

					<SelectWithFloatLabel
						disabled={hasPowerProfile}
						className={classNames('form-item', 'disable-bottom-margin')}
						minWidth="calc(50% - 5px)"
						maxWidth="calc(50% - 5px)"
						ref={register(requireValidation())}
						error={
							errors?.level?.message ||
							//@ts-ignore
							(fieldsErrors.level &&
								Object.values(fieldsErrors.level.constraints['IsValidLevel']))
						}
						selectSettings={{
							useInternalState: true,
							options: departmentsAndLevelsOptions.levels,
							name: 'level',
							defaultValue: formState?.level === 0 ? undefined : formState?.level,
							onChange: (event) => {
								setFormState((prev: any) => ({
									...prev,
									level: Number(event.target.value),
								}));
								if (errors?.level) trigger('level');

								const newErrors = removeErrorsForProperty('level', apiErrors);
								if (newErrors) dispatch(setErrors(newErrors));
							},
						}}
						id="level"
						label="Level"
					/>

					{fieldsErrors.jobProfileId ? getDepartmentsAndLevelsError : null}
				</Fieldset>
			</Fieldset>
		);
	}, [
		apiErrors,
		departmentsAndLevelsOptions?.departments,
		departmentsAndLevelsOptions?.levels,
		dispatch,
		errors?.department,
		errors?.employmentType,
		errors?.facilityId,
		errors?.level,
		errors?.locationType,
		errors?.maxSalaryValue,
		errors?.minSalaryValue,
		errors?.numberOfOpenings,
		errors.title,
		facilitiesItems?.length,
		fieldsErrors?.department,
		fieldsErrors?.employmentType,
		fieldsErrors?.facilityId,
		fieldsErrors?.jobProfileId,
		fieldsErrors?.level,
		fieldsErrors?.locationType,
		fieldsErrors?.maxSalaryValue,
		fieldsErrors?.minSalaryValue,
		fieldsErrors?.numberOfOpenings,
		fieldsErrors?.salaryUnit,
		fieldsErrors?.title,
		formState?.department,
		formState?.employmentType,
		formState?.facilityId,
		formState?.level,
		formState?.locationType,
		formState?.maxSalaryValue,
		formState?.minSalaryValue,
		formState?.numberOfOpenings,
		formState?.salaryUnit,
		formState?.title,
		getDepartmentsAndLevelsError,
		getFacilityLocations,
		getFacilityOptions,
		getOpeningsOptions,
		hasPowerProfile,
		locationRequirementOptions,
		register,
		relocationAllowed,
		relocationOptionDisabled,
		setFormState,
		setJobTitle,
		trigger,
	]);

	return (
		<div className={className}>
			{!hasBeenFacilitiesLoaded || !departmentsAndLevels?.departments.length ? (
				<Loader thin minHeight={20} minWidth={20} className={styles.loader} />
			) : (
				<Container maxWidth={572}>
					{basicsForm}

					<FacilityScreen
						open={isAddFacilityScreenOpen}
						backHandler={() => setIsAddFacilityScreenOpen(false)}
						close={() => setIsAddFacilityScreenOpen(false)}
						setCreatedFacilityState={(value, name) => {
							setFormState((prev: JobFormModel) => ({
								...prev,
								facilityId: value,
								facilityName: name,
							}));
						}}
					/>
				</Container>
			)}
		</div>
	);
};

export default React.memo(Basics);
