import React, { FC } from 'react';
import classNames from 'classnames';

import RadioInterface from './radio.interface';
import FormItem from '../form-item/FormItem';
import Label from '../../../atoms/form/label/Label';

import styles from './Radio.module.scss';

const Radio: FC<RadioInterface> = React.forwardRef(({ className, label, id, ...rest }, ref) => {
	return (
		<FormItem className={classNames(styles['radio__wrap'], className)}>
			<input type="radio" id={id} className={classNames(styles.radio)} {...rest} ref={ref} />
			<Label htmlFor={id} className={styles.label}>
				<span className={styles.text}>{label}</span>
			</Label>
		</FormItem>
	);
});

export default Radio;
