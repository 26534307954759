import React, { FC, useMemo } from 'react';

import CompanyMissingDataWarning from '../company-missing-data-warning';
import useActiveCompany from '../../../../../helpers/hooks/use-active-company/useActiveCompany';
import WarningsInterface from './warnings.interface';
import { EDITOR_EMPTY_STATE } from '../../../../../helpers/constants';
import LearnAboutBoostCTA from '../learn-about-boost-cta/LearnAboutBoostCTA';
import useCompanies from '../../../../../helpers/hooks/use-companies/useCompanies';
import JobPaymentWarning from '../job-payment-warning/JobPaymentWarning';
import { checkCompanyIsOnJobsWithPaymentPlan } from '../../../../../helpers/custom/common';

const Warnings: FC<WarningsInterface> = (props) => {
	const { activeCompanyId, companies } = useCompanies();
	const activeCompany = useActiveCompany(activeCompanyId, companies);

	const hasCompanyMissingDataWarning = useMemo(
		() =>
			checkCompanyIsOnJobsWithPaymentPlan(activeCompany) &&
			(!activeCompany?.logoUrl ||
				!activeCompany?.description ||
				activeCompany?.description === EDITOR_EMPTY_STATE),
		[activeCompany],
	);

	if (props.showPaymentRequiredCTA) return <JobPaymentWarning />;

	if (hasCompanyMissingDataWarning) return <CompanyMissingDataWarning />;

	if (props.showLearnAboutBoostCTA) return <LearnAboutBoostCTA />;

	return null;
};

export default Warnings;
