import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import CertificateBadgesInterface from './certificate-badges.interface';
import InfoPopover from '../info-popover';
import CertificationBadge from '../../../atoms/certification-badge';

import styles from './CertificateBadges.module.scss';
import { BADGES_DISPLAY_COUNT } from '../../../../helpers/constants';
import ExternalLink from '../../../atoms/navigation/external-link/ExternalLink';

const CertificateBadges: FC<CertificateBadgesInterface> = ({
	badges,
	popoverClassName,
	className,
	...rest
}) => {
	const trigger = useMemo(
		() => (
			<>
				{badges.slice(0, BADGES_DISPLAY_COUNT).map((badge) => (
					<CertificationBadge
						key={badge.id}
						badgeImageUrl={badge.badgeTemplate.imageUrl}
					/>
				))}

				{badges.length > BADGES_DISPLAY_COUNT ? (
					<span className={styles['other-certificates']}>
						+{badges.length - BADGES_DISPLAY_COUNT}
					</span>
				) : null}
			</>
		),
		[badges],
	);

	return (
		<div className={classNames(className, styles['certificate-badges'])} {...rest}>
			<InfoPopover
				trigger={trigger}
				className={styles.info}
				contentClassName={classNames(styles['content-box-position'], popoverClassName)}
			>
				<div className={styles['badges-content']}>
					<div className={styles.title}>Verified certifications ({badges.length})</div>

					{badges.map((badge) => {
						return (
							<div key={badge.id} className={styles.subtitle}>
								<CertificationBadge badgeImageUrl={badge.badgeTemplate.imageUrl} />

								{badge.badgeUrl ? (
									<ExternalLink
										className={classNames(styles.text, styles.link)}
										href={badge.badgeUrl}
										target="_blank"
										rel="noopener noreferrer"
									>
										{badge.badgeTemplate.organization} -{' '}
										{badge.badgeTemplate.name}
									</ExternalLink>
								) : (
									<span className={styles.text}>
										{badge.badgeTemplate.organization} -{' '}
										{badge.badgeTemplate.name}
									</span>
								)}
							</div>
						);
					})}
				</div>
			</InfoPopover>
		</div>
	);
};

export default CertificateBadges;
