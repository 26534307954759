import React, { FC } from 'react';
import classNames from 'classnames';

import TabButtonInterface from './tab.interface';

import styles from './Tab.module.scss';

const Tab: FC<TabButtonInterface> = ({
	children,
	className,
	isActive,
	activeTabClassName,
	...rest
}) => {
	return (
		<button
			type="button"
			className={classNames(
				styles.tab,
				className,
				isActive ? classNames(styles.active, activeTabClassName) : '',
			)}
			{...rest}
			tabIndex={isActive ? -1 : 0}
		>
			{children}
		</button>
	);
};

export default Tab;
