import React, { FC } from 'react';
import classNames from 'classnames';

import InfoPopoverInterface from './info-popover.interface';

import styles from './InfoPopover.module.scss';

const InfoPopover: FC<InfoPopoverInterface> = ({
	defaultTrigger,
	trigger,
	contentClassName,
	className,
	children,
	...rest
}) => {
	return (
		<div
			className={classNames(
				styles['info-popover'],
				defaultTrigger ? styles['default-trigger'] : '',
				className,
			)}
			{...rest}
		>
			{!defaultTrigger ? <div className={styles.trigger}>{trigger}</div> : null}

			<div className={classNames(styles['content-box'], contentClassName)}>{children}</div>
		</div>
	);
};

export default InfoPopover;
