import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import Slider from 'rc-slider';

import 'rc-slider/assets/index.css';
import SliderElementInterface, {
	SliderElementMark,
	SliderElementMarks,
} from './slider-element.interface';

import styles from './SliderElement.module.scss';
import { getSliderNumberValue } from '../../../../helpers/custom/common';

const SliderElement: FC<SliderElementInterface> = ({
	hideMarks,
	step = null,
	id,
	marks,
	style,
	value,
	valueFromState,
	className,
	onChange,
	inputSettings,
	onValueChange,
	...rest
}) => {
	const [sliderValue, setSliderValue] = useState<string | number | undefined>('');
	const [sliderNumber, setSliderNumber] = useState<string | number | undefined>();

	const labelStyles = useMemo(
		() => ({
			fontFamily: 'Roboto, sans-serif',
			fontSize: 12,
			fontWeight: 300,
			opacity: hideMarks ? 0 : 1,
		}),
		[hideMarks],
	);

	useEffect(() => {
		if (sliderValue === '' && inputSettings?.value !== undefined) {
			setSliderValue(inputSettings?.value as number);
		} else if (sliderValue === '' && inputSettings?.value === undefined) {
			setSliderValue(marks![0].value as number);
		}
	}, [setSliderValue, inputSettings, sliderValue, marks]);

	useEffect(() => {
		if (valueFromState && marks) {
			setSliderValue(valueFromState as number);
			setSliderNumber(getSliderNumberValue(marks, valueFromState));
		}
	}, [valueFromState, marks]);

	const getStyledMarks = useCallback(
		(marks?: SliderElementMark[]): SliderElementMarks => {
			const styledMarks: SliderElementMarks = {};

			marks &&
				marks.forEach(({ number, label }) => {
					// @ts-ignore
					styledMarks[number] = {
						style: labelStyles,
						label,
					};
				});

			return styledMarks;
		},
		[labelStyles],
	);

	return (
		<div style={style} className={classNames(styles['slider__wrap'], className)}>
			<input
				type="text"
				id={id}
				className={styles.input}
				onChange={() => {}}
				{...inputSettings}
				value={sliderValue}
			/>

			<Slider
				step={step}
				marks={getStyledMarks(marks)}
				value={(sliderNumber as number) || (value as number)}
				onChange={(number) => {
					setSliderNumber(number);

					const value = marks?.find((item) => item.number === number)?.value;
					setSliderValue(value);

					if (onChange) onChange(number as number);
					if (onValueChange && value !== undefined) onValueChange(+value);
				}}
				railStyle={{ height: 1, backgroundColor: '#666' }}
				trackStyle={{ height: 1, backgroundColor: '#666' }}
				className={styles.slider}
				handleStyle={{
					borderColor: 'transparent',
					height: 21,
					width: 21,
					marginTop: -9,
					backgroundColor: '#70C9C2',
				}}
				dotStyle={{ display: 'none' }}
				{...rest}
			/>
		</div>
	);
};

export default React.memo(SliderElement);
