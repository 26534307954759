import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { setupAxios } from './helpers';
import store from './store';
import App from './App';
import useGoogleAnalytics4 from './helpers/google-analytics-4';
import * as serviceWorker from './serviceWorker';

import './index.scss';
import { BrowserRouter } from 'react-router-dom';

// eslint-disable-next-line react-hooks/rules-of-hooks
useGoogleAnalytics4();

setupAxios();

const container = document.getElementById('root');

const root = createRoot(container!);

root.render(
	<Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>,
);

serviceWorker.unregister();
