import React, { FC } from 'react';

import ColoredNumberInterface from './colored-number.interface';

import styles from './ColoredNumber.module.scss';

const ColoredNumber: FC<ColoredNumberInterface> = ({ children, color }) => {
	return (
		<span style={{ color }} className={styles['colored-number']}>
			{children}
		</span>
	);
};

export default ColoredNumber;
