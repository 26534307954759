import React, { FC } from 'react';
import classNames from 'classnames';

import FormActionsInterface from './form-actions.interface';

import styles from './FormActions.module.scss';

const FormActions: FC<FormActionsInterface> = ({
	children,
	className,
	centeredContent,
	...rest
}) => {
	return (
		<div
			className={classNames(
				styles['form-actions'],
				className,
				centeredContent && styles['centered-content'],
			)}
			{...rest}
		>
			{children}
		</div>
	);
};

export default FormActions;
