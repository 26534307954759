import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';

import {
	AuthorizationPayloadInterface,
	getAuthorizationInfo,
} from '../../../../store/slices/authorization.slice';
import { LoginFormProvider } from '../../../../contexts/login-form-context';
import LoginFormInterface from './login-form.interface';

import styles from './LoginForm.module.scss';

const LoginForm: FC<LoginFormInterface> = ({ children, className, ...rest }) => {
	const dispatch = useDispatch();
	const formSettings = useForm({
		mode: 'onSubmit',
	});

	const onFormSubmitHandler = (data: Partial<AuthorizationPayloadInterface>) => {
		data.acceptedCustomerTerms = data.acceptedCustomerTerms ? 1 : 0;

		dispatch(getAuthorizationInfo(data));
	};

	return (
		<LoginFormProvider value={formSettings}>
			<form
				className={classNames(styles['login-form'], className)}
				{...rest}
				noValidate
				onSubmit={formSettings.handleSubmit(onFormSubmitHandler)}
			>
				{children}
			</form>
		</LoginFormProvider>
	);
};

export default React.memo(LoginForm);
