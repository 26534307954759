import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
	getPromotionJobLinks,
	promotionJobLinksSliceInterface,
} from '../../store/slices/promotion-job-links.slice';
import TextGrabber from '../../components/molecules/ui/text-grabber';
import Loader from '../../components/molecules/loader';
import PromoteJobForm from '../../components/organisms/custom/promote-job-form';
import NestedPopupHeader from '../../components/organisms/popup-headers/nested-popup-header/NestedPopupHeader';
import Container from '../../components/templates/container';
import TypographyText from '../../components/atoms/ui/typography-text';
import Title from '../../components/atoms/title/Title';

import styles from './PromoteJob.module.scss';
import ResizeDeviceContext from '../../contexts/resize-device-context';

const PromoteJob: FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const { id } = useParams();
	const { loading, links } = useSelector(
		({ promotionJobLinks }: { promotionJobLinks: promotionJobLinksSliceInterface }) =>
			promotionJobLinks,
	);

	const device = useContext(ResizeDeviceContext);

	// if user came to this page by direct link there can be troubles with history.goBack() navigation in some browsers
	//@ts-ignore
	const hasHistoryBackContext = location?.state?.hasHistoryBackContext;

	useEffect(() => {
		if (id) dispatch(getPromotionJobLinks(id));
	}, [dispatch, id]);

	const linksElement = useMemo(
		() =>
			links?.map(({ name, link }, index) => (
				<TextGrabber
					key={index}
					title={name}
					text={link}
					className={styles['link__item']}
				/>
			)),
		[links],
	);

	const onClose = useCallback(() => {
		if (hasHistoryBackContext) return navigate(-1);
		navigate(`/jobs/${id}`);
	}, [hasHistoryBackContext, id, navigate]);

	const mainContent = useMemo(
		() => (
			<Container maxWidth={device !== 'desktop' ? '95%' : 597} className={styles.content}>
				<TypographyText className={styles['common__desc']}>
					Grab the link below for the platform on which you'd like to promote your job. Or
					create one for any platform not included here.
				</TypographyText>

				<Title component="h3" fontSize={20} fontWeight={600} marginBottom={20}>
					Create a custom link
				</Title>

				<TypographyText className={styles.desc}>
					Enter a source (website, campaign...) below where you'd like to promote your job
					and we'll generate a shareable link for you to easily copy and paste.
				</TypographyText>

				<PromoteJobForm className={styles.form} jobId={id} />

				{linksElement}
			</Container>
		),
		[device, id, linksElement],
	);

	return (
		<div className={styles['promote-job']}>
			<>
				<NestedPopupHeader
					logo={{
						type: 'white',
						className: styles.logo,
					}}
					title={`<strong>Promote job</strong>`}
					className={styles.header}
					close={onClose}
				/>

				{loading || linksElement === undefined ? (
					<Loader thin minHeight={20} minWidth={20} className={styles.loader} />
				) : (
					mainContent
				)}
			</>
		</div>
	);
};

export default PromoteJob;
