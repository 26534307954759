import { useSelector } from 'react-redux';

import { AuthorizationSliceInterface } from '../../../store/slices/authorization.slice';

export default function useAuthorization() {
	const {
		authorization: { info, loading, error },
	} = useSelector((store: { authorization: AuthorizationSliceInterface }) => store);

	return {
		info,
		loading,
		error,
	};
}
