import axios from 'axios';

import {
	USER,
	AUTHORIZED_CHANGE_USER_PASSWORD,
	USER_UPDATE,
	USER_COMPANY_SETTINGS,
	USER_JOB_SETTINGS,
	JOB_SETTINGS,
	VALIDATION_CODE_FROM_LINK,
} from './api.path';
import {
	CommunicationPreferencesType,
	JobSettingsType,
	ProfileType,
	UserAndCodeInfoInterface,
	UserInfoInterface,
} from '../store/slices/user.slice';

export type changeAuthorizedUserPasswordPayloadType = {
	currentPassword: string;
	newPassword: string;
};

const userApi = {
	getUserInfo: (): Promise<UserInfoInterface> => {
		const url = `${USER}`;

		return axios.get(url);
	},
	changeUserInfo: (data: Partial<ProfileType>): Promise<UserInfoInterface> => {
		return axios.patch(USER_UPDATE, data);
	},
	changeUserEmail: (data: Pick<UserInfoInterface, 'email'>): Promise<UserInfoInterface> => {
		return axios.post(`${USER_UPDATE}/change-email`, data);
	},
	changeAuthorizedUserPassword: (
		data: changeAuthorizedUserPasswordPayloadType,
	): Promise<UserInfoInterface> => {
		// TODO: call the "patch" method
		return axios.post(AUTHORIZED_CHANGE_USER_PASSWORD, data);
	},
	getCommunicationPreferences: (companyId: string | number): Promise<any> => {
		const url = `${USER_COMPANY_SETTINGS}/${companyId}`;

		return axios.get(url);
	},
	changeCommunicationPreferences: (
		companyId: string | number,
		data: CommunicationPreferencesType,
	): Promise<any> => {
		const url = `${USER_COMPANY_SETTINGS}/${companyId}`;

		return axios.post(url, data);
	},
	getJobNotificationsSettings: (jobId: number): Promise<any> => {
		const url = `${USER_JOB_SETTINGS}/${jobId}`;

		return axios.get(url);
	},
	changeJobNotificationsSettings: (
		jobId: number | string,
		data: JobSettingsType[],
	): Promise<any> => {
		const url = `${JOB_SETTINGS}/${jobId}`;

		return axios.post(url, { ...{ data } });
	},
	getJobSubscribers: (jobId: number | string, settingsName: string, showActive?: boolean) => {
		const additionalParams = showActive !== undefined ? `&showActive=${showActive}` : '';

		const url =
			`${USER_JOB_SETTINGS}/${jobId}/subscribers?settingsName=${settingsName}` +
			additionalParams;

		return axios.get(url);
	},
	getValidationCodeFromLink: (tokenId: string): Promise<UserAndCodeInfoInterface> => {
		return axios.get(`${VALIDATION_CODE_FROM_LINK}?tokenId=${tokenId}`);
	},
};

export default userApi;
