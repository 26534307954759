import React, { FC } from 'react';
import classNames from 'classnames';

import OptionInterface from '../../atoms/form/option/option.interface';
import TopBarInterface from './top-bar.interface';
import Container from '../../templates/container';
import ViewSelectFilter from '../../molecules/filters/view-select-filter/ViewSelectFilter';
import SearchInputFilter from '../../molecules/filters/search-input-filter/SearchInputFilter';

import styles from './TopBar.module.scss';

export const viewSelectFilterOptions: OptionInterface[] = [
	{
		text: 'All options',
		value: 'all-options',
	},
	{
		text: 'Value',
		value: 'value',
	},
];

const TopBar: FC<TopBarInterface> = ({
	className,
	searchFilter = <SearchInputFilter />,
	viewSelectFilter = <ViewSelectFilter options={viewSelectFilterOptions} />,
	typesSelectFilter,
	rightTopBarContent,
	centerTopBarContent,
	companyHasExternalJobs,
	...rest
}) => {
	return (
		<Container className={classNames(styles['top-bar'], className)} {...rest}>
			<div className={styles.left}>
				{viewSelectFilter}

				{companyHasExternalJobs ? typesSelectFilter : null}
				
				{searchFilter}
			</div>

			{centerTopBarContent && <div className={styles.center}>{centerTopBarContent}</div>}

			{rightTopBarContent && <div className={styles.right}>{rightTopBarContent}</div>}
		</Container>
	);
};

export default React.memo(TopBar);
