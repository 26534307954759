import classNames from 'classnames';
import React, { FC } from 'react';
import Close from '../../close';
import TextChipInterface from './text-chip.interface';

import styles from './TextChip.module.scss';

const TextChip: FC<TextChipInterface> = ({ item, onRemoveHandler, removeClassName }) => {
	return (
		<div className={styles.chip}>
			<div className={styles.text}>{item.text}</div>

			{onRemoveHandler ? (
				<Close
					className={classNames(styles.remove, removeClassName)}
					onClick={() => onRemoveHandler(item.id)}
				/>
			) : null}
		</div>
	);
};

export default TextChip;
