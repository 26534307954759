/* eslint-disable no-useless-escape */
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, Modifier, convertToRaw, ContentState, CompositeDecorator } from 'draft-js';
import TextEditorWithTextInsertInterface from './text-editor-with-text-insert.interface';
import { Editor } from 'react-draft-wysiwyg';
import { draftToHtml } from 'react-wysiwyg-typescript';
import Textarea from '../../../atoms/form/textarea/Textarea';
import { decoratorStrategy, linkEntityTransform } from '../../../../helpers/custom/editors';
import TemplateTagDecorator from '../../../atoms/ui/template-tag-decorator';

import styles from './TextEditorWithTextInsert.module.scss';
import CalendarLinkCustomItem from '../../../atoms/editor/custom-toolbar-items/calendar-link/CalendarLinkCustomItem';
import classNames from 'classnames';
import Error from '../../../molecules/ui/error';
import { EDITOR_EMPTY_STATE } from '../../../../helpers/constants';

const toolbarSettings: any = {
	options: ['inline', 'list', 'link', 'remove', 'history'],
	inline: {
		inDropdown: false,
		className: styles['toolbar__item'],
		options: ['bold', 'italic', 'underline'],
	},
	list: {
		inDropdown: false,
		className: styles['toolbar__item'],
		options: ['unordered', 'ordered'],
	},
	link: {
		className: styles['toolbar__item'],
		defaultTargetOption: '_blank',
	},
	remove: {
		className: styles['toolbar__item'],
	},
};

const TextEditorWithTextInsert: FC<TextEditorWithTextInsertInterface> = ({
	toolbarHidden,
	displayAsInput,
	valueAsPlainText,
	inputWidth = '100%',
	defaultValue,
	getValueHandler,
	isLoadingHandler,
	tagToInsert,
	afterTagInserted,
	onFocusHandler,
	registerFormItem,
	isRequired,
	formItemName,
	formErrors,
	updateDefaultValue,
}) => {
	const [editorState, setEditorState] = useState(EditorState.createEmpty());
	const [value, setValue] = useState<string | undefined>(defaultValue);

	const editorRef = useRef<any>();
	const setEditorReference = (ref: object) => (editorRef.current = ref);

	const templateTagDecorator = useMemo(
		() => [{ strategy: decoratorStrategy, component: TemplateTagDecorator }],
		[],
	);

	const replaceDefaultWithTags = useCallback(() => {
		if (isLoadingHandler) isLoadingHandler(true);

		const decorator = new CompositeDecorator(templateTagDecorator);

		const defaultAsEditorState = EditorState.createWithContent(
			ContentState.createFromBlockArray(
				// @ts-ignore
				htmlToDraft(defaultValue),
			),
			decorator,
		);

		const currentContent = defaultAsEditorState.getCurrentContent();

		const newState = EditorState.push(
			defaultAsEditorState,
			currentContent,
			'insert-characters',
		);

		if (isLoadingHandler) isLoadingHandler(false);
		return newState;
	}, [defaultValue, isLoadingHandler, templateTagDecorator]);

	const insertTemplateTag = useCallback(
		(text: string) => {
			let currentContent = editorState.getCurrentContent();
			const currentSelection = editorState.getSelection();

			try {
				currentContent = Modifier.insertText(currentContent, currentSelection, text);
			} catch (err) {
				currentContent = Modifier.replaceText(currentContent, currentSelection, text);
			}

			let newState = EditorState.push(editorState, currentContent, 'insert-characters');

			const textToInsertSelection = currentSelection.set(
				'focusOffset',
				currentSelection.getFocusOffset() + text.length,
			);

			newState = EditorState.forceSelection(
				newState,
				//@ts-ignore
				textToInsertSelection.set(
					'anchorOffset',
					//@ts-ignores
					textToInsertSelection.getAnchorOffset() + text.length,
				),
			);

			return newState;
		},
		[editorState],
	);

	const onEditorChange = useCallback(
		(editorState: EditorState) => {
			setEditorState(editorState);

			if (valueAsPlainText) setValue(editorState.getCurrentContent().getPlainText());
			else {
				setValue(
					draftToHtml(
						convertToRaw(editorState.getCurrentContent()),
						null,
						false,
						linkEntityTransform,
					),
				);
			}

			document
				.querySelector('.rdw-link-modal-btn')
				?.addEventListener('click', () => editorRef.current.focus());
		},
		[valueAsPlainText],
	);

	useEffect(() => {
		if (defaultValue) setEditorState(replaceDefaultWithTags());
		else setEditorState(EditorState.createEmpty());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateDefaultValue]);

	useEffect(() => {
		if (getValueHandler) getValueHandler(value || '');
	}, [getValueHandler, value]);

	useEffect(() => {
		if (tagToInsert) {
			const toInsert = insertTemplateTag(tagToInsert);

			setEditorState(toInsert);

			if (valueAsPlainText) setValue(toInsert.getCurrentContent().getPlainText());
			else setValue(draftToHtml(convertToRaw(toInsert.getCurrentContent())));

			if (afterTagInserted) afterTagInserted();
		}
	}, [afterTagInserted, insertTemplateTag, tagToInsert, valueAsPlainText]);

	const editorEmptyValidation = useCallback((value: any) => {
		if (!value || value === EDITOR_EMPTY_STATE) return 'The field should not be empty';
	}, []);

	const formItemRefSetting = useCallback(
		(ref: HTMLTextAreaElement | null) => {
			if (registerFormItem) {
				if (isRequired)
					return registerFormItem(ref, {
						validate: {
							emptyValidation: (value: string) => editorEmptyValidation(value),
						},
					});
				else registerFormItem(ref);
			}
		},
		[editorEmptyValidation, isRequired, registerFormItem],
	);

	return (
		<>
			<Editor
				wrapperStyle={displayAsInput && inputWidth ? { width: inputWidth } : {}}
				editorClassName={classNames(
					styles['editor-wrapper'],
					displayAsInput ? styles['as-input'] : '',
				)}
				editorRef={setEditorReference}
				toolbarHidden={toolbarHidden}
				toolbar={toolbarSettings}
				editorState={editorState}
				onEditorStateChange={onEditorChange}
				customDecorators={templateTagDecorator}
				stripPastedStyles
				toolbarCustomButtons={[
					<CalendarLinkCustomItem
						editorState={editorState}
						setEditorStateHandler={setEditorState}
					/>,
				]}
				onFocus={onFocusHandler}
			/>

			{formErrors && formItemName ? (
				<Error className={styles['form-error']}>{formErrors.message}</Error>
			) : null}

			<Textarea
				className={styles.hidden}
				value={value}
				id={formItemName}
				name={formItemName ? formItemName : ''}
				ref={formItemRefSetting}
			/>
		</>
	);
};

export default TextEditorWithTextInsert;
