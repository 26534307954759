import React, { FC } from 'react';
import classNames from 'classnames';

import WurkEnginLogoImage from '../../../../assets/icons/wurk_engin_logo.svg';
import WurkEnginInfoInterface from './wurk-engin-info.interface';
import TypographyText from '../../../atoms/ui/typography-text';

import styles from './WurkEnginInfo.module.scss';

const WurkEnginInfo: FC<WurkEnginInfoInterface> = ({ className, ...rest }) => {
	return (
		<div className={classNames(className)} {...rest}>
			<img src={WurkEnginLogoImage} alt="Würk engin logo" />

			<TypographyText className={styles.description}>
				Recruitment, onboarding, payroll —streamlined.
			</TypographyText>
		</div>
	);
};

export default WurkEnginInfo;
