import { PayloadAction } from '@reduxjs/toolkit';

export interface PaginationReducerInterface {
	pagination: number | undefined;
}

export const paginationReducer = (
	state: PaginationReducerInterface,
	{ type, payload: { pagination } }: PayloadAction<PaginationReducerInterface>,
) => {
	switch (type) {
		case 'SET_PAGINATION_NUMBER':
			return {
				...state,
				pagination,
			};
		default:
			throw new Error();
	}
};
