import React, { FC } from 'react';
import classNames from 'classnames';

import LabelInterface from './label.interface';

import styles from './Label.module.scss';

const Label: FC<LabelInterface> = ({ children, className, ...rest }) => {
	return (
		<label className={classNames(styles.label, className)} {...rest}>
			{children}
		</label>
	);
};

export default Label;
