import React, { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { CompanyInterface, setActiveCompany } from '../../../../store/slices/companies.slice';
import useActiveCompany from '../../../../helpers/hooks/use-active-company/useActiveCompany';

import styles from './ChangeCompany.module.scss';
import { APP_URLS } from '../../../../helpers/routes/routes';
import Back from '../../../atoms/back';
import useCompanies from '../../../../helpers/hooks/use-companies/useCompanies';
import ChangeCompanyInterface from './change-company.interface';
import CompaniesSearch from '../../filters/companies-search/CompaniesSearch';
import {
	COMPANIES_DROPDOWN_MAX_NUMBER,
	COMPANIES_DROPDOWN_MIN_NUMBER,
	StorageItemKeys,
} from '../../../../helpers/constants';
import { processCompanyChange } from '../../../../helpers/custom/common';

const ChangeCompany: FC<ChangeCompanyInterface> = ({
	toggleOpenCompaniesList,
	setDropdownOpenState,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [companySearch, setCompanySearch] = useState('');
	const { activeCompanyId, companies } = useCompanies();
	const activeCompany = useActiveCompany(activeCompanyId, companies);

	const companiesMoreThenMax = useMemo(
		() => companies && companies.length > COMPANIES_DROPDOWN_MAX_NUMBER,
		[companies],
	);

	const onCompanyClick = useCallback(
		(id: string) => () => {
			if (activeCompany && activeCompany.id !== id) {
				const activeCompany = companies?.find(({ id: itemId }) => itemId === id);

				if (companies && companies.length > COMPANIES_DROPDOWN_MAX_NUMBER)
					processCompanyChange(activeCompany?.id);

				dispatch(setActiveCompany(activeCompany));

				toggleOpenCompaniesList();
				if (setDropdownOpenState) setDropdownOpenState(false);

				navigate(APP_URLS.jobs);
			}
		},
		[
			activeCompany,
			companies,
			dispatch,
			toggleOpenCompaniesList,
			setDropdownOpenState,
			navigate,
		],
	);

	const getCompaniesToDisplay = useCallback(
		(companySearch?: string) => {
			if (!companies) return [];

			if (companySearch)
				return companies.filter((company) =>
					company.name.toLowerCase().includes(companySearch.toLowerCase()),
				);

			const companiesFromStorage = localStorage.getItem(StorageItemKeys.recentCompanies);
			let companyIdsArr: string[] = [];
			let companiesToDisplay: CompanyInterface[] = [];

			if (companiesFromStorage) companyIdsArr = companiesFromStorage.split(',');

			if (companyIdsArr.length) {
				companyIdsArr.forEach((companyId: string) => {
					const companyToAdd = companies.find((company) => company.id === companyId);
					if (companyToAdd) companiesToDisplay.push(companyToAdd);
				});
			} else {
				companiesToDisplay = companies.slice(0, COMPANIES_DROPDOWN_MIN_NUMBER);
			}

			return companiesToDisplay.sort((a, b) => a.id.localeCompare(b.id));
		},
		[companies],
	);

	const elements = useMemo(() => {
		let companiesToProcess = companies;

		if (companiesMoreThenMax) companiesToProcess = getCompaniesToDisplay(companySearch);

		const searchResultsExceeded = !!(
			companySearch &&
			companiesToProcess &&
			companiesToProcess.length > 10
		);

		const emptyResults = !!(
			companySearch &&
			companiesToProcess &&
			companiesToProcess.length === 0
		);

		if (emptyResults)
			return (
				<div className={classNames(styles.note, styles.empty)}>
					No results matching your search input
				</div>
			);

		return (
			<ul className={classNames(styles.list, searchResultsExceeded ? styles.scroll : '')}>
				{companiesToProcess &&
					companiesToProcess?.map(({ id, name }) => (
						<>
							<li key={id} className={styles['list__item']}>
								<button
									className={classNames(
										styles['btn-item'],
										activeCompany && activeCompany.id === id && styles.active,
									)}
									onClick={onCompanyClick(id)}
								>
									{name}
								</button>
							</li>
						</>
					))}
			</ul>
		);
	}, [
		activeCompany,
		companies,
		companiesMoreThenMax,
		getCompaniesToDisplay,
		onCompanyClick,
		companySearch,
	]);

	return (
		<div className={styles['companies-list']}>
			<div className={styles.topbar}>
				<div className={styles.title}>Change company</div>

				<Back className={styles.back} onClick={toggleOpenCompaniesList} />
			</div>

			{companiesMoreThenMax ? <CompaniesSearch onTextInput={setCompanySearch} /> : null}

			{elements}
		</div>
	);
};

export default React.memo(ChangeCompany);
