import React, { FC, useEffect, useState } from 'react';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import Draft, { draftToHtml } from 'react-wysiwyg-typescript';
import htmlToDraft from 'html-to-draftjs';

import Textarea from '../../../atoms/form/textarea/Textarea';
import TextEditorInterface from './text-editor.interface';

import styles from './TextEditor.module.scss';
import { linkEntityTransform } from '../../../../helpers/custom/editors';

const toolbarSettings: any = {
	options: ['inline', 'list', 'link', 'remove', 'history'],
	inline: {
		inDropdown: false,
		className: styles['toolbar__item'],
		options: ['bold', 'italic', 'underline'],
	},
	list: {
		inDropdown: false,
		className: styles['toolbar__item'],
		options: ['unordered', 'ordered'],
	},
	link: {
		className: styles['toolbar__item'],
		defaultTargetOption: '_blank',
	},
	remove: {
		className: styles['toolbar__item'],
	},
};

const TextEditor: FC<TextEditorInterface> = ({
	defaultValue,
	clear = false,
	placeholder,
	textareaSettings,
	onChangeHandler,
}) => {
	const [clearState, setClearState] = useState<boolean>(clear);
	const [value, setValue] = useState<string | undefined>(defaultValue);
	const [editorState, setEditorState] = useState(EditorState.createEmpty());

	useEffect(() => {
		if (clearState) {
			setValue('');
			setEditorState(EditorState.createEmpty());
		}
	}, [clearState]);

	useEffect(() => {
		setClearState(clear);
	}, [clear]);

	useEffect(() => {
		if (!value && defaultValue) {
			setValue(defaultValue);
		}
	}, [setValue, value, defaultValue]);

	useEffect(() => {
		if (defaultValue) {
			setEditorState(
				EditorState.createWithContent(
					ContentState.createFromBlockArray(
						// @ts-ignore
						htmlToDraft(defaultValue),
					),
				),
			);
		} else {
			setEditorState(EditorState.createEmpty());
		}
	}, [defaultValue]);

	const onStateChange = (editorState: any) => {
		const editorSourceHTML = draftToHtml(
			convertToRaw(editorState.getCurrentContent()),
			null,
			false,
			linkEntityTransform,
		);

		setEditorState(editorState);
		setValue(editorSourceHTML);

		if (onChangeHandler) onChangeHandler(editorSourceHTML);
	};

	return (
		<div>
			<Draft
				{...{ placeholder }}
				editorState={editorState}
				toolbar={toolbarSettings}
				onEditorStateChange={onStateChange}
				stripPastedStyles
			/>

			<Textarea
				{...textareaSettings}
				className={styles.hidden}
				value={value}
				onChange={textareaSettings?.onChange}
			/>
		</div>
	);
};

export default TextEditor;
