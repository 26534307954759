import React, { FC, useContext, useMemo } from 'react';
import MessageCandidatesFormInterface from './message-candidates-form.interface';

import styles from './MessageCandidatesForm.module.scss';
import TextEditorWithTextInsert from '../../form-elemets/text-editor-with-html';
import TypographyText from '../../../atoms/ui/typography-text';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../../../store/reducers/root-reducer';
import { generateEmailText } from '../../../../helpers/custom/editors';
import ResizeDeviceContext from '../../../../contexts/resize-device-context';

const MessageCandidatesForm: FC<MessageCandidatesFormInterface> = ({
	template,
	formErrors,
	registerFormItem,
	multipleCandidates,
	singleCandidateData,
	updateTemplate,
}) => {
	const {
		user: { info: userInfo },
		job: { info: jobInfo },
	} = useSelector((state: RootStateType) => state);

	const device = useContext(ResizeDeviceContext);

	const emailTextData = useMemo(
		() => ({
			userFirstName: userInfo?.profile?.firstName,
			userLastName: userInfo?.profile?.lastName,
			jobTitle: jobInfo?.title,
			facility: jobInfo?.facility,
			multipleCandidates,
			...(!multipleCandidates &&
				singleCandidateData && { candidateFirstName: singleCandidateData.firstName }),
			...(!multipleCandidates &&
				singleCandidateData && { candidateLastName: singleCandidateData.lastName }),
			phoneNumber: userInfo?.profile?.phoneNumber
				? `+${userInfo?.profile?.phoneCountryCode}${userInfo?.profile?.phoneNumber}`
				: '',
			calendarLink: userInfo?.profile?.calendarLink,
		}),
		[
			jobInfo?.facility,
			jobInfo?.title,
			multipleCandidates,
			singleCandidateData,
			userInfo?.profile?.calendarLink,
			userInfo?.profile?.firstName,
			userInfo?.profile?.lastName,
			userInfo?.profile?.phoneCountryCode,
			userInfo?.profile?.phoneNumber,
		],
	);

	const renderSubjectText = useMemo(() => {
		if (template?.subject) {
			return generateEmailText(template.subject, emailTextData, false);
		}
	}, [emailTextData, template.subject]);

	const renderBodyText = useMemo(() => {
		if (template?.body) {
			return generateEmailText(template.body, emailTextData);
		}
	}, [emailTextData, template.body]);

	return (
		<>
			<div className={styles['subject-editor']}>
				<div className={styles.label}>Subject</div>

				<TextEditorWithTextInsert
					toolbarHidden
					displayAsInput
					valueAsPlainText
					inputWidth={device === 'mobile' ? '100%' : '328px'}
					defaultValue={renderSubjectText}
					registerFormItem={registerFormItem}
					formItemName="subject"
					isRequired
					formErrors={formErrors?.subject}
					updateDefaultValue={updateTemplate}
				/>
			</div>

			<div className={styles['body-editor']}>
				<TextEditorWithTextInsert
					defaultValue={renderBodyText}
					registerFormItem={registerFormItem}
					formItemName="body"
					isRequired
					formErrors={formErrors?.body}
					updateDefaultValue={updateTemplate}
				/>
			</div>

			<TypographyText className={styles.description}>
				Templates are configured in your account.
			</TypographyText>
		</>
	);
};

export default React.memo(MessageCandidatesForm);
