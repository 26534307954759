import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

dayjs.extend(customParseFormat);
dayjs.extend(updateLocale);
dayjs.extend(utc);

const thresholds = [
	{ l: 's', r: 59 },
	{ l: 'mm', r: 59, d: 'minute' },
	{ l: 'h', r: 1 },
	{ l: 'hh', r: 23, d: 'hour' },
	{ l: 'd', r: 1 },
	{ l: 'dd', r: 30, d: 'day' },
];

dayjs.extend(relativeTime, { thresholds });

dayjs.updateLocale('en', {
	relativeTime: {
		future: 'in %s',
		past: '%s ago',
		s: 'a few seconds',
		m: '1 minute',
		mm: '%d minutes',
		h: '1 hour',
		hh: '%d hours',
		d: '1 day',
		dd: '%d days',
		M: '1 month',
		MM: '%d months',
		y: '1 year',
		yy: '%d years',
	},
});

export const getRelativeTimeString = (
	datetime: number,
	inputFormat: string = 'x',
	fullDateFormat: string = 'M/D/YYYY',
) => {
	let result = '';

	const monthsDifference = Math.abs(
		dayjs(datetime, inputFormat).diff(Date.now(), 'months', true),
	);

	if (monthsDifference >= 0.98) result = dayjs(datetime, inputFormat).format(fullDateFormat);
	else result = dayjs(datetime).fromNow();

	return result;
};

export const getExpirationTimestamp = (datetime?: string) => {
	if (datetime) {
		const daysDifference = Math.abs(dayjs(datetime).diff(Date.now(), 'day', true));

		if (daysDifference <= 1) return 'today';
		else return `in ${Math.floor(daysDifference)} days`;
	}
};

// not used for now (after boost downgrade)
export const getBoostPeriod = (startDate?: string, endDate?: string) => {
	if (!startDate || !endDate) return;

	const end = dayjs(endDate);
	const differenceInWeeks = end.diff(startDate, 'week', true);

	return differenceInWeeks > 2 ? '1 month' : '2 weeks';
};

export const getPeriodInDays = (startDate?: string, endDate?: string | null) => {
	if (!startDate) return;

	const start = dayjs(dateInMDYFormat(startDate));
	const end = dayjs(dateInMDYFormat(endDate || Date.now()));

	const differenceInDays = end.diff(start, 'day');

	return differenceInDays;
};

export const getLeftPeriodInDays = (endDate?: string | null) => {
	if (!endDate) return;

	const now = dayjs.utc().format('M/D/YYYY');

	const end = dayjs(endDate);
	const differenceInDaysTillNow = end.diff(now, 'day');

	return differenceInDaysTillNow;
};

export const dateInMDYFormat = (date?: string | number | null) => {
	if (!date) return '';

	if (date?.toString().includes('T')) {
		const dateResult = date?.toString().split('T')[0];

		return dayjs(dateResult).format('M/D/YYYY');
	}

	return dayjs(date).format('M/D/YYYY');
};

export const getDatesInRange = (startDate?: string, endDate?: string) => {
	if (!startDate || !endDate) return [];

	const rangeDates: string[] = [];

	const daysCount = getPeriodInDays(startDate, endDate) || 0;

	if (daysCount && daysCount > 1) {
		for (let i = 1; i < daysCount; i++) {
			rangeDates.push(dayjs(startDate).add(i, 'day').format('M/D/YYYY'));
		}
	}

	return rangeDates;
};
