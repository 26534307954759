import { Dispatch } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getCandidatesListSuccess } from './candidates.slice';
import { FacilityInterface } from './facilities.slice';
import jobApi from '../../api/job.api';
import candidatesListApi from '../../api/candidates.api';
import { CompanyInterface, getAvailableCompanies } from './companies.slice';
import { clearJobsTemplatesListSuccess } from './jobs-templates.slice';
import { ErrorType } from '../../helpers/forms/forms';
import {
	createAndActivateJobGA4Event,
	createJobGA4Event,
} from '../../helpers/google-analytics-4/events';
import { getCompanyDataForGA, getJobDataForGA } from '../../helpers/google-analytics-4/helpers';
import {
	GAContentGroup,
	GAContentId,
	GAContentType,
} from '../../helpers/google-analytics-4/index.constants';
import { JobStatusType } from '../../helpers/custom/common';
import { GeneratedJobDescriptionInterface } from '../../helpers/custom/job';

export interface PowerProfileComputeData {
	companyId: string;
	jobTitle: string;
	state: string;
	summary: string;
}

export interface PowerProfileComputedResponse {
	jobProfile: string;
	department: number;
	level: number;
	workSeniority: 0 | 2 | 5;
	cannabisSeniority: 0 | 1 | 3;
}

export interface PaymentResultData {
	companyId: string;
	sessionId: string;
}

export interface PaymentLinkData {
	jobId?: number;
	productUniqueName?: string;
	companyId?: string;
	successUrl: string;
	cancelUrl: string;
}

export interface MarketCPAInterface {
	id: number;
	state: string;
	city: string;
	department?: number;
	level?: number;
	jobProfileId?: string | number | null;
	value: number;
	createdAt: string;
	updatedAt: string;
}

export interface JobAnalyticsDataInterface {
	date: string;
	numberOfApplications: string | number;
}

export interface BoostJobDataInterface {
	jobId: string;
	numberOfOpenings: number;
	creditsAmount: number;
}

export interface BoostItemInterface {
	id: number;
	jobId: number;
	startedAt: string;
	endedAt?: string | null;
	cancelledAt?: string | null;
	credits: string;
	numberOfOpenings: number;
}

export interface JobSliceInterface {
	info: Partial<JobInterface>;
	statistic: object;
	loading: boolean;
	statusLoading?: boolean;
	boostLoading?: boolean;
	createdJobId?: string | number;
	errors?: ErrorType[];
}

export interface JobInterface {
	id: string;
	facilityId: number;
	jobProfileId: number | string | null;
	status?: number;
	employmentType?: number;
	employmentTypeLabel?: string;
	minScore?: number;
	numberOfOpenings?: number;
	requireResume?: number;
	requireResumeLabel?: string;
	requireCover?: number;
	requireCoverLabel?: string;
	expiresAt?: number | string;
	title?: string;
	summary?: string;
	companyDescription?: string;
	mustHaveSkills?: string;
	niceToHaveSkills?: string;
	benefits?: string;
	eoe?: string;
	facility: FacilityInterface;
	applicationsCount?: string;
	lastAppliedAt?: string;
	statusLabel?: string;
	workSeniority: 0 | 2 | 5;
	cannabisSeniority: 0 | 1 | 3;
	minSalaryValue?: number | null;
	maxSalaryValue?: number | null;
	salaryUnit?: string;
	publishedAt?: string;
	hasPowerProfile?: boolean;
	jobProfileTitle?: string;
	jobProfileUniqueName?: string;
	locationType?: number;
	locationTypeLabel?: string;
	relocationAllowed?: boolean;
	department?: number;
	departmentKey?: string;
	departmentLabel?: string;
	level?: number;
	levelKey?: string;
	levelLabel?: string;
	hasExternalApply?: boolean;
	isEnginJob?: boolean;
	jobUrl?: string;
	boostId?: number;
	jobBoosts?: BoostItemInterface[];
}

const initialState: JobSliceInterface = {
	info: {},
	statistic: {},
	loading: false,
	statusLoading: false,
	boostLoading: false,
	createdJobId: '',
	errors: [],
};

export const jobsListSlice = createSlice({
	name: 'job',
	initialState,
	reducers: {
		loading(state) {
			state.loading = true;
		},
		statusLoading(state, { payload }: PayloadAction<boolean>) {
			state.statusLoading = payload;
		},
		boostLoading(state, { payload }: PayloadAction<boolean>) {
			state.boostLoading = payload;
		},
		getJobInfoSuccess(state, { payload }: any) {
			state.info = payload;
			state.loading = false;
		},
		getJobInfoFailed(state, action: PayloadAction<string>) {
			state.loading = false;
			console.log(action);
		},
		getJobStatisticSuccess(state, { payload }: any) {
			state.statistic = payload;
			state.loading = false;
		},
		getJobStatisticFailed(state, action: PayloadAction<string>) {
			state.loading = false;
			console.log(action);
		},
		createJobSuccess(state, { payload }: PayloadAction<string | number>) {
			state.loading = false;
			state.createdJobId = payload;
		},
		createJobFailed(state, action: PayloadAction<string>) {
			state.loading = false;
			console.log(action);
		},
		validateJobFailed(state, { payload }: PayloadAction<any[]>) {
			state.errors = payload;
		},
		clearErrors(state) {
			state.errors = [];
		},
		setErrors(state, { payload }: PayloadAction<any[]>) {
			state.errors = payload;
		},
		clearJob(state) {
			state.info = {};
			state.statistic = {};
			state.createdJobId = undefined;
		},
	},
});

export const getJobInfo =
	(companyId: string, withLoading?: boolean) => async (dispatch: Dispatch<any>) => {
		if (withLoading) dispatch(loading());

		try {
			const response = await jobApi.getJob(companyId);
			dispatch(getJobInfoSuccess(response));
		} catch (error: any) {
			dispatch(getJobInfoFailed(error.toString()));
		}
	};

export const getJobStatistic =
	(companyId: string, triggerLoading: boolean = true) =>
	async (dispatch: Dispatch<any>) => {
		if (triggerLoading) dispatch(loading());

		try {
			const response = await jobApi.getJobStatistic(companyId);
			dispatch(getJobStatisticSuccess(response));
		} catch (error: any) {
			dispatch(getJobStatisticFailed(error.toString()));
		}
	};

export const createJob =
	(
		data: Partial<JobInterface>,
		analyticsData: {
			activeCompany?: CompanyInterface;
			jobProfile?: string;
			state?: string;
			brandingName?: string;
		},
		withLoading: boolean = true,
	) =>
	async (dispatch: Dispatch<any>) => {
		if (withLoading) dispatch(loading());

		try {
			const job: Partial<JobInterface> = await jobApi.createJob(data);

			await dispatch(getAvailableCompanies());

			if (job?.id) {
				dispatch(createJobSuccess(job?.id));
				dispatch(clearJobsTemplatesListSuccess());

				const { activeCompany, jobProfile, state, brandingName } = analyticsData;

				const gaData = {
					jobData: getJobDataForGA(job),
					companyData: getCompanyDataForGA(activeCompany),
					contentData: {
						id: brandingName ? GAContentId.postAJob : GAContentId.job,
						group: brandingName ? GAContentGroup.postAJob : GAContentId.job,
						type: GAContentType.create,
					},
				};

				gaData.jobData.jobProfile = jobProfile;
				gaData.jobData.jobState = state;

				if (job?.status === JobStatusType.active)
					createAndActivateJobGA4Event(gaData, job?.numberOfOpenings, brandingName);
				else {
					createJobGA4Event(gaData, job?.numberOfOpenings, brandingName);
				}
			}
		} catch (error: any) {
			dispatch(createJobFailed(error.toString()));
			return error;
		}
	};

export const editJob =
	(urlId: string, data: Partial<JobInterface>) => async (dispatch: Dispatch<any>) => {
		dispatch(loading());

		try {
			const { id }: Partial<JobInterface> = await jobApi.editJob(urlId, data);

			if (id) {
				dispatch(createJobSuccess(id));
				dispatch(clearJobsTemplatesListSuccess());
			}
		} catch (error: any) {
			dispatch(createJobFailed(error.toString()));
		}
	};

export const validateJobData = (data: Partial<JobInterface>) => async (dispatch: Dispatch<any>) => {
	try {
		await jobApi.validateJobData(data);
	} catch (error: any) {
		dispatch(validateJobFailed(error.errors));
		return error.errors;
	}
};

export const changeJobStatus = (id: string, status: number) => async (dispatch: Dispatch<any>) => {
	dispatch(statusLoading(true));

	try {
		const updateResponse: Partial<JobInterface> = await jobApi.editJob(id, { status });

		if (updateResponse?.id) {
			const response = await jobApi.getJob(updateResponse.id);
			dispatch(getJobInfoSuccess(response));
		}
	} catch (error: any) {
		dispatch(getJobInfoFailed(error.toString()));
		return error;
	} finally {
		dispatch(statusLoading(false));
	}
};

export const activateJob = (id: string) => async () => {
	try {
		const activateResponse: Partial<JobInterface> = await jobApi.changeJobStatus(id, {
			status: JobStatusType.active,
		});

		return activateResponse;
	} catch (error) {
		return error;
	}
};

export const deactivateJob = (id: string) => async () => {
	try {
		const activateResponse: Partial<JobInterface> = await jobApi.changeJobStatus(id, {
			status: JobStatusType.inactive,
		});

		return activateResponse;
	} catch (error) {
		return error;
	}
};

export const getJob =
	(
		jobId: string,
		settings?: {
			pageSize?: number;
			pageNumber?: number;
			status?: string;
		},
	) =>
	async (dispatch: Dispatch<any>) => {
		dispatch(loading());

		try {
			const [info, statistic, candidates] = await Promise.all([
				jobApi.getJob(jobId),
				jobApi.getJobStatistic(jobId),
				candidatesListApi.getCandidatesList(jobId, settings),
			]);
			dispatch(getJobInfoSuccess(info));
			dispatch(getCandidatesListSuccess(candidates));
			dispatch(getJobStatisticSuccess(statistic));
		} catch (error) {
			if (error) {
				dispatch(getJobInfoFailed(error.toString()));
			}
		}
	};

export const getJobOnly = (jobId: string) => async (dispatch: Dispatch<any>) => {
	dispatch(loading());

	try {
		const result = await jobApi.getJob(jobId);
		dispatch(getJobInfoSuccess(result));
	} catch (err: any) {
		dispatch(getJobInfoFailed(err.toString()));
	}
};

export const getJobById = (jobId: string) => async () => {
	try {
		return await jobApi.getJob(jobId);
	} catch (error) {
		return error;
	}
};

export const boostJob =
	(data: Partial<BoostJobDataInterface>) => async (dispatch: Dispatch<any>) => {
		dispatch(boostLoading(true));

		try {
			const result = await jobApi.boostJob(data);
			dispatch(boostLoading(false));

			return result;
		} catch (error) {
			dispatch(boostLoading(false));
			throw new Error();
		}
	};

export const getJobAnalytics = async (jobId: string): Promise<JobAnalyticsDataInterface[]> => {
	try {
		return await jobApi.getJobAnalytics(jobId);
	} catch (error) {
		return [];
	}
};

// not used for now (after boost downgrade)
export const getMarketCPAForJob = async (
	cpaParams: Partial<MarketCPAInterface>,
): Promise<number> => {
	try {
		const result = await jobApi.getMarketCPA(cpaParams);

		return result.value || 0;
	} catch (error) {
		return 0;
	}
};

export const getJobPaymentLink = (linkData: PaymentLinkData) => async (): Promise<string> => {
	try {
		return await jobApi.getJobPaymentLink(linkData);
	} catch (error) {
		return '';
	}
};

export const confirmJobPayment = (data: PaymentResultData) => async (): Promise<any> => {
	try {
		return await jobApi.confirmJobPayment(data);
	} catch (error) {
		return error;
	}
};

export const createJobDescription =
	(data: GeneratedJobDescriptionInterface) =>
	async (): Promise<GeneratedJobDescriptionInterface | null> => {
		try {
			return await jobApi.createJobDescription(data);
		} catch (error) {
			return null;
		}
	};

export const getJobDescriptionByUuid =
	(uuid: string) => async (): Promise<GeneratedJobDescriptionInterface | null> => {
		try {
			return await jobApi.getJobDescriptionByUuid(uuid);
		} catch (error) {
			return null;
		}
	};

export const getComputedPowerProfile =
	(data: PowerProfileComputeData) =>
	async (): Promise<Partial<PowerProfileComputedResponse> | null> => {
		try {
			return await jobApi.getComputedPowerProfile(data);
		} catch (error) {
			return null;
		}
	};

export const {
	loading,
	statusLoading,
	boostLoading,
	getJobInfoSuccess,
	getJobInfoFailed,
	createJobSuccess,
	createJobFailed,
	getJobStatisticSuccess,
	getJobStatisticFailed,
	validateJobFailed,
	clearJob,
	clearErrors,
	setErrors,
} = jobsListSlice.actions;
export default jobsListSlice.reducer;
