import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { RootStateType } from '../../../../store/reducers/root-reducer';
import JobNameAndLocationInfoInterface from './job-name-and-location-info.interface';
import TypographyText from '../../../atoms/ui/typography-text';

import styles from './JobNameAndLocationInfo.module.scss';

const JobNameAndLocationInfo: FC<JobNameAndLocationInfoInterface> = ({ className, ...rest }) => {
	const { job: jobInfo } = useSelector((state: RootStateType) => state);

	return (
		<div className={classNames(styles.wrap, className)} {...rest}>
			{jobInfo?.info?.title && (
				<TypographyText className={styles['job-name']}>
					{jobInfo?.info?.title}
				</TypographyText>
			)}

			{jobInfo?.info?.facility?.state || jobInfo?.info?.facility?.city ? (
				<TypographyText className={styles['job-location']}>
					{jobInfo?.info?.facility?.city}
					{jobInfo?.info?.facility?.state && `, ${jobInfo?.info?.facility?.state}`}
				</TypographyText>
			) : null}
		</div>
	);
};

export default JobNameAndLocationInfo;
