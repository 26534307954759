import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ReactPlayer from 'react-player';

import {
	clearJobsList,
	cloneJobItem,
	deleteJobItem,
	getJobsList,
} from '../../store/slices/jobs.slice';
import { getFormattedDate, setSpecificFiltration, setSpecificPagination } from '../../helpers';
import { JobInterface, clearJob } from '../../store/slices/job.slice';
import Table from '../../components/organisms/table/Table';
import Title from '../../components/atoms/title/Title';
import Loader from '../../components/molecules/loader';
import TableRow from '../../components/molecules/table/table-row/TableRow';
import TableBody from '../../components/molecules/table/table-body/TableBody';
import TableHead from '../../components/molecules/table/table-head/TableHead';
import TableCell from '../../components/atoms/table/table-cell/TableCell';
import EmptyState from '../../components/organisms/empty-state';
import IconButton from '../../components/atoms/buttons/icon-button/IconButton';
import IconLinkButton from '../../components/atoms/buttons/icon-link-button/IconLinkButton';
import useActiveCompany from '../../helpers/hooks/use-active-company/useActiveCompany';
import InfiniteScroll from '../../components/organisms/infinite-scroll';
import PaginationContext from '../../contexts/pagination-context';
import TopBar from '../../components/organisms/top-bar/TopBar';
import JobsViewSelectFilter from '../../components/organisms/filters/jobs-view-select-filter';
import JobsListSearchFilter from '../../components/organisms/filters/jobs-list-search-filter';
import ModalWindow from '../../components/organisms/modal-window';

import styles from './Jobs.module.scss';
import Tooltip from '../../components/atoms/ui/tooltip';
import classNames from 'classnames';
import JobsTypeSelectFilter from '../../components/organisms/filters/jobs-type-select-filter/JobsTypeSelectFilter';
import { JobsTypesValues, PAGE_SIZE, QueryParametersKey } from '../../helpers/constants';
import { FilterParamsType, parseUrlParams } from '../../helpers/custom/url';
import SparkBoostStatus from '../../components/atoms/ui/spark-boost-status';
import { JobStatusType } from '../../helpers/custom/common';
import boostIconActive from '../../assets/icons/spark-in-square-active.svg';
import boostIconInactive from '../../assets/icons/spark-in-square-inactive.svg';
import { checkJobIsBoosted } from '../../helpers/custom/job';
import useCompanies from '../../helpers/hooks/use-companies/useCompanies';
import useJobs from '../../helpers/hooks/use-jobs/useJobs';
import { boostJobIntentGA4Event, deleteJobGA4Event } from '../../helpers/google-analytics-4/events';
import { getCompanyDataForGA, getJobDataForGA } from '../../helpers/google-analytics-4/helpers';
import { GAContentGroup, GAContentId } from '../../helpers/google-analytics-4/index.constants';
import FilterContext from '../../contexts/filter-context';
import { clearCandidatesStatusSuccess } from '../../store/slices/candidates.slice';
import TextEllipsis from '../../components/molecules/ui/text-ellipsis';
import { APP_URLS } from '../../helpers/routes/routes';
import ConfirmationPopup from '../../components/organisms/custom/confirmation-popup';

const Jobs: FC = () => {
	const dispatch = useDispatch();
	const location = useLocation();

	const { jobsItems, jobsLoading, deleteJobLoadingIds } = useJobs();
	const { activeCompanyId, companies, activeCompanyStatistic, activeCompanyCanBoost } =
		useCompanies();
	const activeCompany = useActiveCompany(activeCompanyId, companies);

	const [errorState, setErrorState] = useState<string>();
	const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);
	const [deleteConfirmed, setDeleteConfirmed] = useState(false);
	const [jobToDeleteData, setJobToDeleteData] = useState<{
		id: string;
		deleteIsForbidden?: boolean;
	} | null>(null);

	const {
		state: { pagination: pageNumber },
		dispatch: dispatchPaginationNumber,
	} = useContext(PaginationContext);

	const { dispatch: contextDispatch } = useContext(FilterContext);

	const clearErrorState = useCallback(() => setErrorState(undefined), []);

	const updateJobsList = useCallback(async () => {
		const { filter, sort, text, secondary_filter } = parseUrlParams<FilterParamsType>(
			location.search,
		);

		setSpecificPagination(1, dispatchPaginationNumber);

		if (activeCompany) {
			await dispatch(
				getJobsList(
					activeCompany.id,
					{
						...(filter !== undefined && filter && { facilityId: Number(filter) }),
						title: text,
						orderBy: sort,
						pageSize: PAGE_SIZE,
						pageNumber: 1,
						jobsType:
							activeCompany?.hasExternalJobs && !secondary_filter
								? JobsTypesValues.engin
								: secondary_filter,
					},
					true,
				),
			);
		}
	}, [activeCompany, dispatch, dispatchPaginationNumber, location.search]);

	const cloneJob = useCallback(
		(jobId: string, forbidden?: boolean) => () => {
			if (forbidden) return;

			const { filter, sort, text, secondary_filter } = parseUrlParams<FilterParamsType>(
				location.search,
			);

			return (
				activeCompany &&
				dispatch(
					cloneJobItem(activeCompany.id, jobId, {
						title: text,
						...(filter !== undefined && { facilityId: Number(filter) }),
						orderBy: sort,
						pageSize: PAGE_SIZE,
						pageNumber: 1,
						jobsType:
							activeCompany?.hasExternalJobs && !secondary_filter
								? JobsTypesValues.engin
								: secondary_filter,
					}),
				)
			);
		},
		[activeCompany, dispatch, location.search],
	);

	const deleteJob = useCallback(
		async (jobId: string, forbidden?: boolean) => {
			if (forbidden) return;

			window.scrollTo(0, 0);

			const { filter, sort, text, secondary_filter } = parseUrlParams<FilterParamsType>(
				location.search,
			);

			if (activeCompany) {
				try {
					await dispatch(
						deleteJobItem(activeCompany.id, jobId, {
							title: text,
							...(filter !== undefined && { facilityId: Number(filter) }),
							orderBy: sort,
							pageSize: PAGE_SIZE,
							pageNumber: 1,
							jobsType:
								activeCompany?.hasExternalJobs && !secondary_filter
									? JobsTypesValues.engin
									: secondary_filter,
						}),
					);

					const currentJob = jobsItems?.find((job) => job.id === jobId);
					deleteJobGA4Event(
						{
							jobData: getJobDataForGA(currentJob),
							companyData: getCompanyDataForGA(activeCompany),
							contentData: {
								id: GAContentId.jobList,
								group: GAContentGroup.jobList,
							},
						},
						currentJob?.numberOfOpenings,
						currentJob?.statusLabel?.toLowerCase(),
					);

					clearErrorState();
				} catch (error: any) {
					setErrorState(error?.message);
				}
			}
		},
		[activeCompany, clearErrorState, dispatch, jobsItems, location.search],
	);

	const triggerBoostGA4Event = useCallback(
		(jobId: string) => () => {
			boostJobIntentGA4Event({
				jobData: getJobDataForGA(jobsItems?.find((job) => job.id === jobId)),
				companyData: getCompanyDataForGA(activeCompany),
				contentData: {
					id: GAContentId.jobList,
					group: GAContentGroup.jobList,
				},
			});
		},
		[activeCompany, jobsItems],
	);

	const rowsElement = useMemo(
		() =>
			jobsItems?.map(
				({
					id,
					title,
					facility: { city, state, name },
					applicationsCount,
					status,
					statusLabel,
					lastAppliedAt,
					hasExternalApply,
					boostId,
					jobBoosts,
				}: JobInterface) => {
					const hasApplicants = !!(applicationsCount && +applicationsCount > 0);
					const isBoosted = checkJobIsBoosted(boostId, jobBoosts, status);
					const deleteIsForbidden = hasExternalApply || hasApplicants || isBoosted;

					const renderDeleteTooltipText = () => {
						if (hasExternalApply) return 'External-apply jobs cannot be deleted.';
						else if (hasApplicants)
							return 'Jobs that have applicants cannot be deleted.';
						else if (isBoosted) return 'Boosted jobs cannot be deleted.';
						return 'Delete';
					};

					const renderJobStatus = () => {
						if (activeCompanyCanBoost && isBoosted) return <SparkBoostStatus />;
						return <TextEllipsis text={statusLabel || ''} />;
					};

					const boostActionDisabled =
						status === JobStatusType.inactive || isBoosted || hasExternalApply;

					const getBoostTooltipText = () => {
						if (status === JobStatusType.inactive || hasExternalApply)
							return 'This job cannot be boosted at this time.';
						if (isBoosted) return 'This job is currently being boosted.';
						return 'SparkBoost increases your job post visibility to get more applicants faster.';
					};

					const renderActions = () => {
						return (
							<>
								{activeCompanyCanBoost ? (
									<div className={styles['item-with-tooltip']}>
										<IconLinkButton
											className={classNames(
												styles.boost,
												boostActionDisabled ? styles.disabled : '',
											)}
											imageIcon={
												boostActionDisabled
													? boostIconInactive
													: boostIconActive
											}
											to={`/boost-job/${id}`}
											state={{ hasHistoryBackContext: true }}
											onClick={triggerBoostGA4Event(id)}
										/>

										<Tooltip
											className={styles['item-tooltip']}
											text={getBoostTooltipText()}
										/>
									</div>
								) : null}

								<div className={styles['item-with-tooltip']}>
									<IconLinkButton
										icon="edit"
										to={`/edit-job/${id}`}
										state={{ hasHistoryBackContext: true }}
									/>
									<Tooltip className={styles['item-tooltip']} text={'Edit'} />
								</div>

								<IconButton
									icon="duplicate"
									className={styles['item-with-tooltip']}
									disabled={hasExternalApply}
									onClick={cloneJob(id, hasExternalApply)}
								>
									{hasExternalApply ? (
										<Tooltip
											className={styles['item-tooltip']}
											text={'External-apply jobs cannot be duplicated.'}
										/>
									) : (
										<Tooltip
											className={styles['item-tooltip']}
											text={'Duplicate'}
										/>
									)}
								</IconButton>

								<IconButton
									icon="delete"
									className={styles['item-with-tooltip']}
									disabled={deleteIsForbidden}
									onClick={() => {
										setOpenDeleteConfirmationModal(true);
										setJobToDeleteData({ id, deleteIsForbidden });
									}}
								>
									{deleteIsForbidden ? (
										<Tooltip
											className={classNames(
												styles['item-tooltip'],
												styles['left-sided'],
											)}
											text={renderDeleteTooltipText()}
										/>
									) : (
										<Tooltip
											className={styles['item-tooltip']}
											text={'Delete'}
										/>
									)}
								</IconButton>
							</>
						);
					};

					const jobLocation = name || `${city}, ${state}`;
					const jobLastApplied = hasExternalApply
						? 'N/A'
						: lastAppliedAt && getFormattedDate(lastAppliedAt);

					return (
						<TableRow key={id} className={isBoosted ? styles.boosted : ''}>
							<TableCell width="25%">
								<TextEllipsis
									text={title || ''}
									linkUrl={`/jobs/${id}`}
									noLinkStyles
								/>
							</TableCell>
							<TableCell width="18%">
								<TextEllipsis text={jobLocation} />
							</TableCell>
							<TableCell width="10%">
								{hasExternalApply ? 'N/A' : applicationsCount}
							</TableCell>
							<TableCell width="14%">
								<TextEllipsis text={jobLastApplied || ''} />
							</TableCell>
							<TableCell width="12%">{renderJobStatus()}</TableCell>
							<TableCell className={styles.actions}>
								{!deleteJobLoadingIds.find((item) => item === id) ? (
									renderActions()
								) : (
									<Loader
										className={styles['remove-loader']}
										maxWidth={25}
										maxHeight={25}
									/>
								)}
							</TableCell>
						</TableRow>
					);
				},
			),
		[activeCompanyCanBoost, cloneJob, deleteJobLoadingIds, jobsItems, triggerBoostGA4Event],
	);

	const refreshInfiniteScroll = useCallback(() => {
		const { filter, sort, text, secondary_filter } = parseUrlParams<FilterParamsType>(
			location.search,
		);

		if (activeCompany?.id) {
			dispatch(
				getJobsList(activeCompany.id, {
					pageNumber,
					pageSize: PAGE_SIZE,
					title: text,
					...(filter !== undefined && { facilityId: Number(filter) }),
					orderBy: sort,
					jobsType:
						activeCompany?.hasExternalJobs && !secondary_filter
							? JobsTypesValues.engin
							: secondary_filter,
				}),
			);
		}
	}, [activeCompany?.hasExternalJobs, activeCompany?.id, dispatch, location.search, pageNumber]);

	const renderEmptyState = useMemo(() => {
		return (
			<EmptyState
				title="Welcome"
				titleClassName={styles['empty-state-title']}
				descriptionClassName={styles['empty-state-description']}
				description={
					<>
						Our state-of-the-art virtual talent management system equips both job <br />{' '}
						candidates and companies with the tools and insights necessary to build{' '}
						<br />a robust, quality workforce. <br /> <br /> Get started building your
						workforce, <Link to={APP_URLS.createJob}>create a job </Link>
						now.
						<br /> <br />
						<ReactPlayer url="https://www.youtube.com/embed/ZtxKCuilJ04" />
					</>
				}
			/>
		);
	}, []);

	const renderJobsSection = useMemo(() => {
		if (
			!jobsLoading &&
			(!jobsItems || !jobsItems?.length) &&
			Number(activeCompanyStatistic?.jobsCount) === 0
		)
			return renderEmptyState;

		return (
			<>
				<TopBar
					className={styles['top-bar']}
					viewSelectFilter={<JobsViewSelectFilter {...{ pageSize: PAGE_SIZE }} />}
					typesSelectFilter={<JobsTypeSelectFilter {...{ pageSize: PAGE_SIZE }} />}
					searchFilter={<JobsListSearchFilter {...{ pageSize: PAGE_SIZE }} />}
					companyHasExternalJobs={activeCompany?.hasExternalJobs}
				/>

				<Table>
					<TableHead>
						<TableCell width="25%" headCell>
							<TextEllipsis text={'Job title'} />
						</TableCell>
						<TableCell width="18%" headCell>
							<TextEllipsis text={'Facility'} />
						</TableCell>
						<TableCell width="10%" headCell>
							<TextEllipsis text={'Applicants'} />
						</TableCell>
						<TableCell width="14%" headCell>
							<TextEllipsis text={'Last Submission'} />
						</TableCell>
						<TableCell width="12%" headCell>
							<TextEllipsis text={'Status'} />
						</TableCell>
						<TableCell headCell>
							<TextEllipsis text={'Actions'} />
						</TableCell>
					</TableHead>

					<TableBody className={styles.body}>
						{!jobsLoading && (!jobsItems || !jobsItems?.length)
							? renderEmptyState
							: rowsElement}
					</TableBody>
				</Table>

				<InfiniteScroll
					scrollDependances={[pageNumber, jobsItems?.length]}
					blockScrollingHandling={
						(jobsItems &&
							!(
								!(jobsItems.length % PAGE_SIZE) &&
								jobsItems?.length !== Number(activeCompanyStatistic?.jobsCount)
							)) ||
						(!jobsItems !== undefined && !jobsItems?.length)
					}
					firstLoad={!jobsItems?.length}
					refreshFunction={refreshInfiniteScroll}
					loading={jobsLoading}
				/>
			</>
		);
	}, [
		activeCompany?.hasExternalJobs,
		activeCompanyStatistic?.jobsCount,
		jobsItems,
		jobsLoading,
		pageNumber,
		refreshInfiniteScroll,
		renderEmptyState,
		rowsElement,
	]);

	const renderDeleteConfirmationPopup = useMemo(() => {
		return (
			<ConfirmationPopup
				title="Are you sure?"
				titleClassName={styles['modal-title']}
				description={'You are about to delete your job posting permanently.'}
				descriptionClassName={styles['modal-description']}
				popupWidth={387}
				backButtonText="Cancel"
				confirmButtonText="Yes, delete job"
				btnsReversedOrder
				open={openDeleteConfirmationModal}
				openStateHandler={setOpenDeleteConfirmationModal}
				onConfirmAndClose={() => setDeleteConfirmed(true)}
			/>
		);
	}, [openDeleteConfirmationModal]);

	useEffect(() => {
		(async () => {
			if (deleteConfirmed && jobToDeleteData) {
				await deleteJob(jobToDeleteData.id, jobToDeleteData.deleteIsForbidden);

				setJobToDeleteData(null);
				setDeleteConfirmed(false);
			}
		})();
	}, [deleteConfirmed, deleteJob, jobToDeleteData]);

	useEffect(() => {
		(async () => {
			if (activeCompany) await updateJobsList();

			const { filter, sort, text, secondary_filter } = parseUrlParams<FilterParamsType>(
				location.search,
			);

			// if param with empty value passed in url (?text=)
			if (
				location.search &&
				(location.search.endsWith('=') || location.search.includes('=&'))
			) {
				const urlConfigs = new URLSearchParams(location.search);
				if (!filter) urlConfigs.delete(QueryParametersKey.filter);
				if (!secondary_filter) urlConfigs.delete(QueryParametersKey.secondaryFilter);
				if (!sort) urlConfigs.delete(QueryParametersKey.sort);
				if (!text) urlConfigs.delete(QueryParametersKey.text);

				const paramsStr = urlConfigs.toString();
				window.history.replaceState(
					null,
					'',
					`${location.pathname}${paramsStr ? '?' : ''}${paramsStr}`,
				);
			}
		})();
	}, [location.pathname, activeCompany, updateJobsList, location.search]);

	useEffect(() => {
		if (jobsItems && !(jobsItems.length % PAGE_SIZE)) {
			const page = jobsItems.length / PAGE_SIZE + 1;

			setSpecificPagination(page, dispatchPaginationNumber);
		}
	}, [dispatchPaginationNumber, jobsItems]);

	useEffect(() => {
		dispatch(clearJob());
		dispatch(clearJobsList());
		setSpecificPagination(1, dispatchPaginationNumber);
		setSpecificFiltration({}, contextDispatch);
		dispatch(clearCandidatesStatusSuccess());
	}, [contextDispatch, dispatch, dispatchPaginationNumber]);

	return (
		<div className={styles.jobs}>
			{renderJobsSection}

			<ModalWindow
				contentClassName={styles['error-popup-content']}
				open={!!errorState}
				close={clearErrorState}
			>
				<Title component="h3">{errorState}</Title>
			</ModalWindow>

			{renderDeleteConfirmationPopup}
		</div>
	);
};

export default Jobs;
