import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import NavigationInterface from './navigation.interface';
import NavLink from '../../atoms/navigation/nav-link/NavLink';

import styles from './Navigation.module.scss';

const Navigation: FC<NavigationInterface> = ({ links }) => {
	const { id } = useParams();

	const linksElement =
		links &&
		links.map(({ title, path: to, ...rest }, index) => (
			<NavLink key={index} to={to} {...rest} childRoute={!!id}>
				{title}
			</NavLink>
		));

	return linksElement ? <nav className={styles.navigation}>{linksElement}</nav> : null;
};

export default React.memo(Navigation);
