import React, { FC, useMemo } from 'react';
import classNames from 'classnames';

import InfoListInterface from './info-list.interface';
import InfoListItem from '../../../molecules/list/info-list-item/InfoListItem';

import styles from './InfoList.module.scss';

const InfoList: FC<InfoListInterface> = ({ className, items, itemsClassName, ...rest }) => {
	const itemsElement = useMemo(
		() =>
			items.map((props, index) => (
				<InfoListItem
					key={`info-list-item-${index}`}
					className={itemsClassName}
					{...props}
					{...rest}
				/>
			)),
		[items, itemsClassName, rest],
	);

	return <ul className={classNames(styles['info-list'], className)}>{itemsElement}</ul>;
};

export default InfoList;
