import React, { FC, useMemo } from 'react';

import { getMinMaxHeightStyles } from '../../../../helpers';
import ConditionContentWithEmptyState from '../../condition-content-with-empty-state/ConditionContentWithEmptyState';
import NoWorkHistoryContentInterface from './no-work-history-content.interface';

import styles from './NoWorkHistoryContent.module.scss';

const NoWorkHistoryContent: FC<NoWorkHistoryContentInterface> = ({
	linkedInUrl,
	resumeUrl,
	onResumeClick,
	minHeight,
	maxHeight,
}) => {
	const staticText = "You can find this candidate's work history in";
	const linkedinLink = useMemo(
		() => (
			<a href={linkedInUrl} target="_blank">
				LinkedIn profile
			</a>
		),
		[linkedInUrl],
	);
	const resumeLink = useMemo(
		() => (
			<div className={styles['resume-link']} onClick={onResumeClick}>
				resume
			</div>
		),
		[onResumeClick],
	);
	const inlineStyles = {
		...getMinMaxHeightStyles(minHeight, maxHeight),
	};

	const settings = useMemo(
		() => [
			{
				condition: !linkedInUrl && !resumeUrl,
				content: 'No work history was provided for this candidate.',
			},
			{
				condition: linkedInUrl && resumeUrl,
				content: (
					<>
						{staticText} either the provided {linkedinLink} or {resumeLink}.
					</>
				),
			},
			{
				condition: linkedInUrl && !resumeUrl,
				content: (
					<>
						{staticText} the provided {linkedinLink}.
					</>
				),
			},
			{
				condition: !linkedInUrl && resumeUrl,
				content: (
					<>
						{staticText} the provided {resumeLink}.
					</>
				),
			},
		],
		[linkedInUrl, resumeUrl, linkedinLink, resumeLink],
	);

	return (
		<ConditionContentWithEmptyState
			{...{ settings }}
			className={styles.content}
			style={inlineStyles}
		/>
	);
};

export default NoWorkHistoryContent;
