import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import einNumbersApi from '../../api/ein-numbers.api';

export interface EinNumberInterface {
	label: string;
	value: string;
}

export interface EinNumberReduxStateInterface {
	loading?: 'fulfilled' | 'pending' | 'rejected';
	items?: EinNumberInterface[];
}

const initialState: EinNumberReduxStateInterface = {};

export const getEinNumbers = createAsyncThunk(
	'users/fetchByIdStatus',
	async (companyId: string, { rejectWithValue }) => {
		try {
			return await einNumbersApi.getEinNumbers(companyId);
		} catch (error: any) {
			return rejectWithValue(error.message);
		}
	},
);

export const einNumberSlice = createSlice({
	name: 'ein',
	initialState,
	reducers: {
		clearEinNumber: (state) => {
			state.items = undefined;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getEinNumbers.pending, (state) => {
			state.loading = 'pending';
		});
		builder.addCase(getEinNumbers.fulfilled, (state, { payload }) => {
			state.loading = 'fulfilled';
			state.items = payload;
		});
		builder.addCase(getEinNumbers.rejected, (state) => {
			state.loading = 'rejected';
		});
	},
});

export const { clearEinNumber } = einNumberSlice.actions;

export default einNumberSlice.reducer;
