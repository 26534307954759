import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './SparkBoostStatus.module.scss';
import SparkBoostStatusInterface from './spark-boost-status.interface';
import TextEllipsis from '../../../molecules/ui/text-ellipsis';

const SparkBoostStatus: FC<SparkBoostStatusInterface> = ({ className }) => {
	return (
		<div className={classNames(className, styles['status-wrap'])}>
			<div className={classNames(styles['boost-status'], styles.active)}>
				<TextEllipsis text="Boosting" />
			</div>
		</div>
	);
};

export default SparkBoostStatus;
