import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import {
	clearErrors,
	clearJob,
	createJob,
	editJob,
	getComputedPowerProfile,
	createJobDescription,
	getJobById,
	getJobPaymentLink,
	JobInterface,
	PowerProfileComputedResponse,
	validateJobData,
	getJobDescriptionByUuid,
} from '../../../../store/slices/job.slice';
import {
	clearFacilitiesList,
	createFacility,
	FacilityInterface,
} from '../../../../store/slices/facilities.slice';
import JobFormInterface from './job-form.interface';

import styles from './JobForm.module.scss';
import Basics from '../job-form-steps/basics';
import Requirements from '../job-form-steps/requirements';
import JobDetails from '../job-form-steps/job-details/JobDetails';
import CompanyDetails from '../job-form-steps/company-details/CompanyDetails';
import JobPreview from '../job-form-steps/job-preview';
import {
	JobFormModel,
	JobFormCreateSteps,
	transformJobData,
	JobFormEditSteps,
	JobFormCreateFromWidgetSteps,
	GeneratedJobDescriptionInterface,
} from '../../../../helpers/custom/job';
import Container from '../../../templates/container';
import StepSectionWithProgress from '../steps-bar-with-progress';
import ErrorNotification from '../../../molecules/ui/errors-notification';
import Loader from '../../../molecules/loader';
import useJobsTemplates from '../../../../helpers/hooks/use-jobs-templates/useJobsTemplates';
import useJob from '../../../../helpers/hooks/use-job/useJob';
import useCompanies from '../../../../helpers/hooks/use-companies/useCompanies';
import useActiveCompany from '../../../../helpers/hooks/use-active-company/useActiveCompany';
import useFacilities from '../../../../helpers/hooks/use-facilities/useFacilities';
import JobProfileType from '../job-form-steps/job-profile-type';
import {
	generatePaymentData,
	checkJobIsWithPaymentByCompany,
	JobStatusType,
	transformGeneratedText,
	getCookie,
	nonScrollablePageOn,
	nonScrollablePageOff,
} from '../../../../helpers/custom/common';
import PaymentConfirmationPopup from '../payment-confirmation-popup';
import {
	editCompany,
	getActiveCompanyPrepaidJobsBalance,
} from '../../../../store/slices/companies.slice';
import PrepaidPublishConfirmationPopup from '../prepaid-publish-confirmation-popup';
import {
	CookieNames,
	EDITOR_EMPTY_STATE,
	QueryParametersKey,
	StorageItemKeys,
} from '../../../../helpers/constants';
import {
	jobProfileSelectionGA4Event,
	paymentIntentGA4Event,
} from '../../../../helpers/google-analytics-4/events';
import {
	GAContentGroup,
	GAContentId,
	GAContentType,
	GAMethod,
} from '../../../../helpers/google-analytics-4/index.constants';
import {
	getCompanyDataForGA,
	getJobDataForGA,
} from '../../../../helpers/google-analytics-4/helpers';
import SparkeSpeech from '../../../molecules/custom/sparke-speech/SparkeSpeech';
import {
	getDepartmentData,
	getJobsTemplatesList,
} from '../../../../store/slices/jobs-templates.slice';
import GenerationLoadingScreen from '../generation-loading-screen/GenerationLoadingScreen';
import ResizeDeviceContext from '../../../../contexts/resize-device-context';
import CompanyKeywordsScreen from '../company-keywords-screen/CompanyKeywordsScreen';
import useUser from '../../../../helpers/hooks/use-user/useUser';

const JobForm: FC<JobFormInterface> = ({
	context,
	formState,
	setFormState,
	title,
	setJobTitle,
	urlId,
	info,
	hasPowerProfile,
	onFormLeave,
	isJobWithPayment,
	brandingName,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { register, errors, trigger, getValues } = useForm({
		reValidateMode: 'onChange',
	});
	const location = useLocation();
	const device = useContext(ResizeDeviceContext);

	const { activeCompanyId, companies, activeCompanyPrepaidJobsBalance } = useCompanies();
	const activeCompany = useActiveCompany(activeCompanyId, companies);

	const { facilitiesItems, hasBeenFacilitiesLoaded } = useFacilities();
	const { items: templateItems, departmentsAndLevels } = useJobsTemplates();
	const { createdJobId, apiErrors, loading } = useJob();
	const { info: userInfo } = useUser();

	const [showSparkeLoader, setShowSparkeLoader] = useState(false);
	const [showSparkeCompanyKeywords, setShowSparkeCompanyKeywords] = useState(false);
	const [createdJobInfo, setCreatedJobInfo] = useState(null);
	const [currentStep, setCurrentStep] = useState(1);
	const [validationInProgress, setValidationInProgress] = useState(false);
	const [creationInProgress, setCreationInProgress] = useState(false);
	const [paymentRelatedError, setPaymentRelatedError] = useState(false);
	const [creationError, setCreationError] = useState(false);

	const [openPaymentConfirmationModal, setOpenPaymentConfirmationModal] = useState(false);
	const [paymentConfirmationLoading, setPaymentConfirmationLoading] = useState(false);

	const [prefilledDataLoading, setPrefilledDataLoading] = useState(true);

	const [paymentLinkLoading, setPaymentLinkLoading] = useState(false);
	const [openPrepaidPublishConfirmationModal, setOpenPrepaidPublishConfirmationModal] =
		useState(false);

	const [isGenerationError, setIsGenerationError] = useState(false);

	const hasCompanyDescription = useMemo(() => {
		return !!(
			(activeCompany?.description && activeCompany?.description !== EDITOR_EMPTY_STATE) ||
			(formState?.companyDescription && formState?.companyDescription !== EDITOR_EMPTY_STATE)
		);
	}, [activeCompany?.description, formState?.companyDescription]);

	const companyDescription = useMemo(() => {
		if (formState?.companyDescription && formState?.companyDescription !== EDITOR_EMPTY_STATE)
			return formState?.companyDescription;

		if (activeCompany?.description && activeCompany?.description !== EDITOR_EMPTY_STATE)
			return activeCompany?.description;

		return '';
	}, [activeCompany?.description, formState?.companyDescription]);

	const isPostAJobFlow = useMemo(
		() => location.search.includes(QueryParametersKey.postAJob),
		[location.search],
	);

	const isFromWidget = useMemo(
		() => location.search.includes(QueryParametersKey.fromWidget),
		[location.search],
	);

	const isEditMode = useMemo(
		() => !!(context === 'edit' && info && urlId),
		[context, info, urlId],
	);

	const jobFormStepsByMode = useMemo(() => {
		if (isEditMode) return JobFormEditSteps;
		if (isFromWidget) return JobFormCreateFromWidgetSteps;
		return JobFormCreateSteps;
	}, [isEditMode, isFromWidget]);

	const jobNeedsPayment = useMemo(
		() =>
			!!(
				isJobWithPayment &&
				!isEditMode &&
				(activeCompanyPrepaidJobsBalance === undefined ||
					activeCompanyPrepaidJobsBalance === 0)
			),
		[activeCompanyPrepaidJobsBalance, isEditMode, isJobWithPayment],
	);

	const renderSparkeLoader = useMemo(() => {
		return (
			<GenerationLoadingScreen
				withAnimation={
					hasCompanyDescription || !!localStorage.getItem(StorageItemKeys.companyKeywords)
				}
				show={showSparkeLoader}
				text={
					localStorage.getItem(StorageItemKeys.sawSparke) || isFromWidget
						? `Hang on a minute while I generate a job description that will help
					you create a compelling job post.`
						: ''
				}
			/>
		);
	}, [hasCompanyDescription, isFromWidget, showSparkeLoader]);

	const getDescriptionGenerationResults = useCallback(
		async (uuid: string) => {
			return new Promise((resolve) => {
				const timerId = setTimeout(() => {
					const intervalId = setInterval(async () => {
						const result = (await dispatch(
							getJobDescriptionByUuid(uuid),
						)) as unknown as GeneratedJobDescriptionInterface;

						if (!result || result?.generatedAt) {
							clearInterval(intervalId);
							clearTimeout(timerId);

							if (result?.summary) resolve(result);
							resolve(null);
						}
					}, 2000);
				}, 2000);
			});
		},
		[dispatch],
	);

	const getAndProcessGeneratedDescription = useCallback(
		async (uuid: string) => {
			const data = await getDescriptionGenerationResults(uuid);

			if (!data) return setIsGenerationError(true);
			return data as GeneratedJobDescriptionInterface;
		},
		[getDescriptionGenerationResults],
	);

	const regenerateJobDescription = useCallback(
		async (newKeywords?: string) => {
			setIsGenerationError(false);
			window.scrollTo(0, 0);

			let keywords = '';
			const localKeywords = localStorage.getItem(StorageItemKeys.companyKeywords);

			if (newKeywords) keywords = newKeywords;
			else if (localKeywords) keywords = localKeywords;
			else {
				if (hasCompanyDescription) keywords = companyDescription;
				else {
					setShowSparkeCompanyKeywords(true);
					return;
				}
			}

			setShowSparkeCompanyKeywords(false);
			setShowSparkeLoader(true);

			const currentFacility = facilitiesItems.find(
				(item) => item.id === Number(formState.facilityId),
			);

			const createdDescription = (await dispatch(
				createJobDescription({
					jobTitle: formState?.title || '',
					department: Number(formState?.department),
					level: Number(formState?.level),
					size: activeCompany?.size,
					companyName: `${activeCompany?.name}`,
					companyKeywords: keywords,
					companyUrl: activeCompany?.websiteUrl || '',
					city: currentFacility?.city || '',
					state: currentFacility?.state || '',
					zip: currentFacility?.zip || '',
					deviceId: getCookie(CookieNames.deviceId),
					firstName: userInfo?.profile?.firstName,
					lastName: userInfo?.profile?.lastName,
					email: userInfo?.email,
				}),
			)) as unknown as GeneratedJobDescriptionInterface;

			if (createdDescription.uuid) {
				const jobDescription = await getAndProcessGeneratedDescription(
					createdDescription.uuid,
				);

				const { summary, mustHaveSkills, niceToHaveSkills, companyDescription } =
					jobDescription || {};

				setFormState((prev) => ({
					...prev,
					summary: transformGeneratedText(summary || ''),
					mustHaveSkills: transformGeneratedText(mustHaveSkills || ''),
					niceToHaveSkills: transformGeneratedText(niceToHaveSkills || ''),
					...(!hasCompanyDescription && {
						companyDescription: transformGeneratedText(companyDescription || ''),
					}),
				}));
			} else {
				setIsGenerationError(true);
			}

			localStorage.setItem(StorageItemKeys.sawSparke, 'yes');
			if (newKeywords) localStorage.setItem(StorageItemKeys.companyKeywords, newKeywords);
			setShowSparkeLoader(false);
		},
		[
			facilitiesItems,
			dispatch,
			formState?.title,
			formState?.department,
			formState?.level,
			formState.facilityId,
			activeCompany?.size,
			activeCompany?.name,
			activeCompany?.websiteUrl,
			userInfo?.profile?.firstName,
			userInfo?.profile?.lastName,
			userInfo?.email,
			hasCompanyDescription,
			companyDescription,
			getAndProcessGeneratedDescription,
			setFormState,
		],
	);

	const renderSparkeKeywordsScreen = useMemo(() => {
		return (
			<CompanyKeywordsScreen
				show={showSparkeCompanyKeywords}
				regenerateJobDescription={regenerateJobDescription}
				onClose={() => setShowSparkeCompanyKeywords(false)}
			/>
		);
	}, [showSparkeCompanyKeywords, regenerateJobDescription]);

	const onPaymentConfirm = useCallback(async () => {
		setPaymentLinkLoading(true);

		const dataForLink = generatePaymentData(
			Number(createdJobId),
			`${activeCompanyId}`,
			!!isPostAJobFlow,
		);

		const generatedLink = await dispatch(getJobPaymentLink(dataForLink));

		if (generatedLink) window.location.href = `${generatedLink}`;
		else setPaymentRelatedError(true);

		setPaymentLinkLoading(false);
		setPaymentConfirmationLoading(false);
	}, [createdJobId, activeCompanyId, isPostAJobFlow, dispatch]);

	const onJobCreate = useCallback(
		async (activate?: boolean, skipPopup: boolean = false) => {
			if (
				//@ts-ignore
				currentStep === jobFormStepsByMode.jobPreview &&
				isJobWithPayment &&
				!jobNeedsPayment &&
				activate &&
				!skipPopup
			) {
				setOpenPrepaidPublishConfirmationModal(true);
				return;
			}

			const body: Partial<JobInterface> = {
				...transformJobData(formState),
				jobProfileId: hasPowerProfile ? formState?.jobProfileId : null,
				status: activate ? 10 : 0,
			};

			const jobProfileName =
				templateItems.find((item) => item.id === formState?.jobProfileId)?.uniqueName ||
				'generic';
			const state = facilitiesItems.find((item) => item.id === body?.facilityId)?.state;

			if (!jobNeedsPayment) setCurrentStep(0);

			const result = await dispatch(
				createJob(
					body,
					{ activeCompany, jobProfile: jobProfileName, state, brandingName },
					!jobNeedsPayment,
				),
			);

			//@ts-ignore
			if (result?.message) {
				//@ts-ignore
				setCurrentStep(jobFormStepsByMode.jobPreview);
				setCreationError(true);
			}
		},
		[
			currentStep,
			//@ts-ignore
			jobFormStepsByMode.jobPreview,
			isJobWithPayment,
			jobNeedsPayment,
			formState,
			hasPowerProfile,
			templateItems,
			facilitiesItems,
			dispatch,
			activeCompany,
			brandingName,
		],
	);

	const onJobEditSave = useCallback(() => {
		if (isEditMode && urlId) {
			const body: Partial<JobInterface> = {
				...transformJobData(formState),
				jobProfileId: info?.jobProfileId,
			};
			dispatch(editJob(urlId, body));
		}
	}, [dispatch, formState, info, isEditMode, urlId]);

	const setValuesFromState = useMemo(() => {
		const formValues = getValues();

		Object.keys(formValues).forEach((key) => {
			//@ts-ignore
			formValues[key] = formState[key];
		});

		return formValues;
	}, [formState, getValues]);

	const onPrevClick = useCallback(() => {
		dispatch(clearErrors());

		if (currentStep === 1) {
			if (onFormLeave) onFormLeave();
			return;
		}

		setCurrentStep((prevNumber: number) => {
			if (prevNumber > 1) return prevNumber - 1;
			else return 1;
		});
	}, [currentStep, dispatch, onFormLeave]);

	const onNextClick = useCallback(async () => {
		//@ts-ignore
		if (!isEditMode && currentStep === jobFormStepsByMode.profileType) {
			if (activeCompany) {
				jobProfileSelectionGA4Event(
					{
						jobData: {
							jobProfile: formState.jobProfileLabel,
							jobDepartment: formState.departmentKey,
						},
						companyData: getCompanyDataForGA(activeCompany),
						contentData: {
							id: brandingName ? GAContentId.postAJob : GAContentId.job,
							group: brandingName ? GAContentGroup.postAJob : GAContentGroup.job,
							type: GAContentType.create,
						},
					},
					brandingName,
				);
			}

			setCurrentStep((prevNumber: number) => prevNumber + 1);
			return;
		}

		//@ts-ignore
		if (!isEditMode && currentStep === jobFormStepsByMode.jobPreview && jobNeedsPayment) {
			setCreationInProgress(true);
			setOpenPaymentConfirmationModal(true);

			if (!createdJobId) await onJobCreate(false);

			setCreationInProgress(false);
			return;
		}

		if (
			currentStep === jobFormStepsByMode.jobDetails &&
			!formState?.summary &&
			getValues().summary
		)
			return;

		if (
			!isEditMode &&
			//@ts-ignore
			currentStep === jobFormStepsByMode.jobPreview &&
			isJobWithPayment &&
			!jobNeedsPayment
		) {
			setOpenPrepaidPublishConfirmationModal(true);
			return;
		}

		setValidationInProgress(true);

		const formFieldsValues = transformJobData(setValuesFromState);

		const dataToValidate =
			'department' in formFieldsValues
				? { ...formFieldsValues, jobProfileId: formFieldsValues?.jobProfileId }
				: formFieldsValues;

		const resultErrors = await dispatch(validateJobData(dataToValidate));

		if (isEditMode && currentStep === jobFormStepsByMode.companyDetails) {
			const validationPassed = await trigger();

			if (validationPassed && !resultErrors?.length) onJobEditSave();
		} else {
			const validationPassed = await trigger();

			if (validationPassed && !resultErrors?.length && currentStep) {
				dispatch(clearErrors());
				setCurrentStep((prevNumber: number) => prevNumber + 1);
			}
		}

		setValidationInProgress(false);
	}, [
		isEditMode,
		currentStep,
		//@ts-ignore
		jobFormStepsByMode.profileType,
		//@ts-ignore
		jobFormStepsByMode.jobPreview,
		jobFormStepsByMode.jobDetails,
		jobFormStepsByMode.companyDetails,
		jobNeedsPayment,
		formState?.summary,
		formState.jobProfileLabel,
		formState.departmentKey,
		getValues,
		isJobWithPayment,
		setValuesFromState,
		dispatch,
		activeCompany,
		brandingName,
		createdJobId,
		onJobCreate,
		trigger,
		onJobEditSave,
	]);

	const updateCompanySize = useCallback(
		(companySize: string) => {
			if (activeCompanyId && !activeCompany?.size) {
				try {
					dispatch(
						editCompany(`${activeCompanyId}`, {
							size: companySize,
						}),
					);
				} catch (error) {
					console.log(`Failed to update company. Error: ${error}`);
				}
			}
		},
		[activeCompany?.size, activeCompanyId, dispatch],
	);

	const createFacilityFromWidgetData = useCallback(
		async (city: string, zip: string, state: string) => {
			try {
				if (activeCompanyId)
					return await dispatch(
						createFacility(
							{
								city,
								zip,
								state,
							},
							false,
						),
					);
			} catch (error) {
				console.log(`Failed to create facility. Error: ${error}`);
			}
		},
		[activeCompanyId, dispatch],
	);

	const getOrCreateNewFacility = useCallback(
		async (city: string, zip: string, state: string) => {
			const facilityWithSimilarLocation = facilitiesItems?.find(
				(item) => item.zip === zip && item.state === state && item.city === city,
			);

			if (facilityWithSimilarLocation) return facilityWithSimilarLocation;

			const facility = (await createFacilityFromWidgetData(
				city,
				zip,
				state,
			)) as unknown as FacilityInterface;

			return facility;
		},
		[createFacilityFromWidgetData, facilitiesItems],
	);

	const formSteps = useMemo(
		() => [
			...(!isEditMode && !isFromWidget
				? [
						{
							//@ts-ignore
							number: jobFormStepsByMode.profileType,
							name: 'Job type',
							component: (
								<JobProfileType
									{...{
										formState,
										setFormState,
										brandingName,
									}}
								/>
							),
						},
				  ]
				: []),
			{
				number: jobFormStepsByMode.basics,
				name: 'Basics',
				component: (
					<Basics
						{...{
							formState,
							setFormState,
							setJobTitle,
							register,
							errors,
							trigger,
							isEditMode,
							hasPowerProfile,
							brandingName,
						}}
					/>
				),
			},
			{
				number: jobFormStepsByMode.requirements,
				name: 'Requirements',
				component: (
					<Requirements
						{...{ formState, setFormState, register, brandingName, isEditMode }}
					/>
				),
			},
			{
				number: jobFormStepsByMode.jobDetails,
				name: 'Job Details',
				component: (
					<JobDetails
						{...{
							formState,
							setFormState,
							register,
							errors,
							trigger,
							brandingName,
							isEditMode,
							regenerateJobDescription,
						}}
					/>
				),
			},
			{
				number: jobFormStepsByMode.companyDetails,
				name: 'Company Details',
				component: (
					<CompanyDetails
						{...{ formState, setFormState, register, brandingName, isEditMode }}
					/>
				),
			},
			...(!isEditMode
				? [
						{
							//@ts-ignore
							number: jobFormStepsByMode.jobPreview,
							name: 'Preview',
							component: (
								<JobPreview
									{...{ formState, setFormState, isJobWithPayment, brandingName }}
									createHandler={onJobCreate}
									backHandler={onPrevClick}
									nextHandler={onNextClick}
									cancelHandler={onFormLeave}
									needsPayment={jobNeedsPayment}
								/>
							),
						},
				  ]
				: []),
		],
		[
			regenerateJobDescription,
			isEditMode,
			isFromWidget,
			//@ts-ignore
			jobFormStepsByMode.profileType,
			jobFormStepsByMode.basics,
			jobFormStepsByMode.requirements,
			jobFormStepsByMode.jobDetails,
			jobFormStepsByMode.companyDetails,
			//@ts-ignore
			jobFormStepsByMode.jobPreview,
			formState,
			setFormState,
			brandingName,
			setJobTitle,
			register,
			errors,
			trigger,
			hasPowerProfile,
			isJobWithPayment,
			onJobCreate,
			onPrevClick,
			onNextClick,
			onFormLeave,
			jobNeedsPayment,
		],
	);

	const renderStepsWithProgress = useMemo(() => {
		//@ts-ignore
		if ((!isEditMode && currentStep !== jobFormStepsByMode.jobPreview) || isEditMode)
			return (
				<StepSectionWithProgress
					lastStepNumber={jobFormStepsByMode.companyDetails}
					stepNumber={currentStep}
					stepName={formSteps.find((step) => step.number === currentStep)?.name}
					prevTitle={currentStep === 1 ? 'Cancel' : 'Back'}
					nextTitle={
						currentStep === jobFormStepsByMode.companyDetails && isEditMode
							? 'Save Job'
							: 'Continue'
					}
					prevStepAction={onPrevClick}
					nextStepAction={onNextClick}
					showLoader={validationInProgress}
					disableNextStep={
						!!(
							!isEditMode &&
							//@ts-ignore
							currentStep === jobFormStepsByMode.profileType &&
							!formState?.jobProfileId
						)
					}
				/>
			);
	}, [
		currentStep,
		formState?.jobProfileId,
		formSteps,
		isEditMode,
		jobFormStepsByMode.companyDetails,
		//@ts-ignore
		jobFormStepsByMode.jobPreview,
		//@ts-ignore
		jobFormStepsByMode.profileType,
		onNextClick,
		onPrevClick,
		validationInProgress,
	]);

	const renderPaymentConfirmationPopup = useMemo(() => {
		const gaEventData = {
			//@ts-ignore
			...(createdJobInfo && { jobData: getJobDataForGA(createdJobInfo) }),
			companyData: getCompanyDataForGA(activeCompany),
			contentData: {
				id: brandingName ? GAContentId.postAJob : GAContentId.job,
				group: brandingName ? GAContentGroup.postAJob : GAContentGroup.job,
				type: GAContentType.create,
			},
		};

		const triggerConfirmGAEvent = () => {
			if (activeCompany && createdJobId) {
				paymentIntentGA4Event(gaEventData, brandingName, GAMethod.popup);
			}
		};

		return (
			<PaymentConfirmationPopup
				description="You're being redirected to payment for your new engin job. Your job details have been saved."
				openState={openPaymentConfirmationModal}
				openStateHandler={setOpenPaymentConfirmationModal}
				onConfirmHandler={() => {
					triggerConfirmGAEvent();

					setPaymentConfirmationLoading(true);
					onPaymentConfirm();
				}}
				loading={paymentLinkLoading || loading || creationInProgress}
				gaEventData={gaEventData}
				brandingName={brandingName}
			/>
		);
	}, [
		activeCompany,
		brandingName,
		createdJobId,
		createdJobInfo,
		creationInProgress,
		loading,
		onPaymentConfirm,
		openPaymentConfirmationModal,
		paymentLinkLoading,
	]);

	const renderPrepaidPublishConfirmationPopup = useMemo(() => {
		return (
			<PrepaidPublishConfirmationPopup
				openState={openPrepaidPublishConfirmationModal}
				openStateHandler={setOpenPrepaidPublishConfirmationModal}
				onConfirmHandler={() => onJobCreate(true, true)}
			/>
		);
	}, [onJobCreate, openPrepaidPublishConfirmationModal]);

	const speechBubbleTextBySteps = useMemo(() => {
		switch (currentStep) {
			case jobFormStepsByMode.basics:
				return 'Enter the basics about your post. I filled in what I already know - you do the rest!';
			case jobFormStepsByMode.requirements:
				return 'Ok - so what are the job requirements? What does the candidate need to apply?';
			case jobFormStepsByMode.jobDetails:
				return (
					<span>
						Hey again! I filled in the fields below with the description I generated.
						You can <strong>edit it</strong> here, <strong>clear it</strong> or{' '}
						<strong>generate a new one</strong>.
					</span>
				);
			case jobFormStepsByMode.companyDetails:
				return 'Ok - so just a few more details to attract the best candidates for the job.';
			//@ts-ignore
			case jobFormStepsByMode.jobPreview:
				return 'Wow - this is looking great to me. This is how job seekers will see your post.';
			default:
				return '';
		}
	}, [
		currentStep,
		jobFormStepsByMode.basics,
		jobFormStepsByMode.companyDetails,
		jobFormStepsByMode.jobDetails,
		//@ts-ignore
		jobFormStepsByMode.jobPreview,
		jobFormStepsByMode.requirements,
	]);

	const renderStepsSparkeSpeech = useMemo(() => {
		return (
			<SparkeSpeech
				asPortal={device === 'desktop'}
				small
				withInfoIcon
				className={styles.sparke}
				content={speechBubbleTextBySteps}
				maxWidth="270px"
			/>
		);
	}, [device, speechBubbleTextBySteps]);

	useEffect(() => {
		if (showSparkeLoader || showSparkeCompanyKeywords) nonScrollablePageOn();
		else nonScrollablePageOff();
	}, [showSparkeLoader, showSparkeCompanyKeywords]);

	useEffect(() => {
		if (templateItems && !templateItems.length) {
			dispatch(
				getJobsTemplatesList({
					excludeCannabis: !activeCompany?.allowsCannabis,
				}),
			);
		}
	}, [activeCompany, dispatch, templateItems]);

	useEffect(() => {
		if (!departmentsAndLevels && isFromWidget) dispatch(getDepartmentData());
	}, [departmentsAndLevels, dispatch, isFromWidget]);

	useEffect(() => {
		(async () => {
			if (
				isFromWidget &&
				departmentsAndLevels &&
				hasBeenFacilitiesLoaded &&
				templateItems.length &&
				!createdJobId
			) {
				const jobDescriptionUuid = new URLSearchParams(location.search).get(
					QueryParametersKey.descriptionUuid,
				);

				if (jobDescriptionUuid) {
					const jobDescription = (await dispatch(
						getJobDescriptionByUuid(jobDescriptionUuid),
					)) as unknown as GeneratedJobDescriptionInterface;

					if (jobDescription) {
						const {
							jobTitle,
							department,
							level,
							city,
							zip,
							state,
							size,
							summary,
							mustHaveSkills,
							niceToHaveSkills,
							companyDescription,
							companyKeywords,
						} = jobDescription as GeneratedJobDescriptionInterface;

						if (companyKeywords) {
							localStorage.setItem(StorageItemKeys.companyKeywords, companyKeywords);
						}

						const departmentLabel = departmentsAndLevels?.departments.find(
							(dept) => dept.value === department,
						)?.label;

						if (size && size !== activeCompany?.size) updateCompanySize(size);
						const facility = await getOrCreateNewFacility(city, zip, state);
						const powerProfileResult: PowerProfileComputedResponse = await dispatch(
							getComputedPowerProfile({
								companyId: `${activeCompanyId}`,
								jobTitle,
								state,
								summary: summary || '',
							}) as unknown as PowerProfileComputedResponse,
						);

						const currentPowerProfile =
							//@ts-ignore
							templateItems?.find(
								(item) => item.uniqueName === powerProfileResult?.jobProfile,
							) || 'generic';

						//@ts-ignore
						const { id: profileId = -1, title: profileLabel = '' } =
							currentPowerProfile || {};

						setFormState((prev) => ({
							...prev,
							jobProfileId: profileId,
							jobProfileLabel: profileLabel,
							title: jobTitle,
							summary: transformGeneratedText(summary || ''),
							mustHaveSkills: transformGeneratedText(mustHaveSkills || ''),
							niceToHaveSkills: transformGeneratedText(niceToHaveSkills || ''),
							department: Number(department),
							departmentLabel: departmentLabel || '',
							level: Number(level),
							facilityId: facility?.id,
							facilityName: facility?.name || '',
							companyDescription: transformGeneratedText(companyDescription || ''),
							workSeniority: powerProfileResult?.workSeniority,
							cannabisSeniority: powerProfileResult?.cannabisSeniority,
						}));

						if (jobTitle && jobTitle !== title && setJobTitle) setJobTitle(jobTitle);
					}
				}

				setPrefilledDataLoading(false);
			}

			if (!isFromWidget && templateItems.length) setPrefilledDataLoading(false);
		})();
	}, [
		activeCompanyId,
		departmentsAndLevels,
		dispatch,
		getOrCreateNewFacility,
		hasBeenFacilitiesLoaded,
		isFromWidget,
		location.search,
		setFormState,
		setJobTitle,
		templateItems,
		updateCompanySize,
		createdJobId,
		activeCompany?.size,
		title,
	]);

	useEffect(() => {
		if (
			activeCompanyId &&
			checkJobIsWithPaymentByCompany(activeCompany) &&
			!isEditMode &&
			//@ts-ignore
			currentStep === jobFormStepsByMode.jobPreview
		) {
			dispatch(getActiveCompanyPrepaidJobsBalance(activeCompanyId));
		}
	}, [
		activeCompany,
		activeCompanyId,
		currentStep,
		dispatch,
		isEditMode,
		//@ts-ignore
		jobFormStepsByMode.jobPreview,
	]);

	useEffect(() => {
		setIsGenerationError(false);
	}, [currentStep]);

	useEffect(() => {
		if (context === 'edit' && title && !formState?.title)
			setFormState((prev: JobFormModel) => ({ ...prev, title }));
	}, [context, formState?.title, setFormState, title]);

	useEffect(() => {
		if (
			createdJobId &&
			createdJobInfo &&
			//@ts-ignore
			(createdJobInfo?.status === JobStatusType.active || !jobNeedsPayment || creationError)
		) {
			dispatch(clearJob());
			return navigate(`/jobs/${createdJobId}?tab=candidates`);
		}
	}, [
		createdJobId,
		dispatch,
		jobNeedsPayment,
		navigate,
		onPaymentConfirm,
		creationError,
		createdJobInfo,
	]);

	useEffect(() => {
		return () => {
			dispatch(clearFacilitiesList());
			dispatch(clearErrors());
		};
	}, [dispatch]);

	useEffect(() => {
		(async () => {
			if (activeCompany && createdJobId) {
				const jobData = await dispatch(getJobById(`${createdJobId}`));

				//@ts-ignore
				if (jobData) setCreatedJobInfo(jobData);
			}
		})();
	}, [activeCompany, createdJobId, dispatch]);

	// if (prefilledDataLoading) return <Loader wide />;

	if (loading || currentStep === 0)
		return <Loader thin minHeight={20} minWidth={20} className={styles.loader} />;

	return (
		<>
			{prefilledDataLoading ? <Loader wide /> : null}

			{renderStepsWithProgress}

			{isFromWidget && !showSparkeLoader && !prefilledDataLoading
				? renderStepsSparkeSpeech
				: null}

			{!showSparkeLoader ? (
				<Container maxWidth={'100%'} className={styles.content}>
					<form>{formSteps.find((item) => item.number === currentStep)?.component}</form>
				</Container>
			) : null}

			{isJobWithPayment && !jobNeedsPayment ? renderPrepaidPublishConfirmationPopup : null}
			{jobNeedsPayment ? renderPaymentConfirmationPopup : null}

			{Object.keys(errors).length || apiErrors?.length ? (
				<ErrorNotification message="Please check the required fields to continue." />
			) : null}

			{paymentRelatedError || creationError || isGenerationError ? (
				<ErrorNotification message="Sorry, something went wrong." />
			) : null}

			{paymentLinkLoading || paymentConfirmationLoading ? <Loader wide withOverlay /> : null}

			{renderSparkeLoader}

			{!hasCompanyDescription ? renderSparkeKeywordsScreen : null}
		</>
	);
};

export default React.memo(JobForm);
