import { useSelector } from 'react-redux';
import { JobSliceInterface } from '../../../store/slices/job.slice';

export default function useJob() {
	const {
		job: {
			info,
			createdJobId,
			errors: apiErrors,
			loading,
			statistic,
			statusLoading,
			boostLoading,
		},
	} = useSelector((store: { job: JobSliceInterface }) => store);

	return {
		info,
		loading,
		createdJobId,
		apiErrors,
		statistic,
		statusLoading,
		boostLoading,
	};
}
