import React from 'react';

const initialState: null | 'desktop' | 'tablet' | 'mobile' = null;

const ResizeDeviceContext = React.createContext<null | 'desktop' | 'tablet' | 'mobile'>(
	initialState,
);

export const { Provider: ResizeDeviceProvider, Consumer: ResizeDeviceConsumer } =
	ResizeDeviceContext;

export default ResizeDeviceContext;
