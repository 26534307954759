import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import OptionInterface from '../../../atoms/form/option/option.interface';
import ViewSelectFilter from '../../../molecules/filters/view-select-filter/ViewSelectFilter';
import JobsListSearchFilterInterface from '../jobs-list-search-filter/jobs-list-search-filter.interface';
import { JobsTypesValues } from '../../../../helpers/constants';
import {
	FilterParamsType,
	parseUrlParams,
	stringifyParamsForUrl,
} from '../../../../helpers/custom/url';
import { useLocation, useNavigate } from 'react-router-dom';

const JobsTypeSelectFilter: FC<JobsListSearchFilterInterface> = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const [type, setType] = useState<string>();

	const options: OptionInterface[] = useMemo(
		() => [
			{
				text: 'engin apply',
				value: JobsTypesValues.engin,
			},
			{
				text: 'External apply',
				value: JobsTypesValues.external,
			},
			{
				text: 'All jobs',
				value: JobsTypesValues.all,
			},
		],
		[],
	);

	useEffect(() => {
		const { secondary_filter } = parseUrlParams<FilterParamsType>(location.search);

		setType(secondary_filter);
	}, [location.search]);

	const changeOption = useCallback(
		({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
			const filterParams = parseUrlParams<FilterParamsType>(location.search);

			if (value === JobsTypesValues.engin) filterParams.secondary_filter = '';
			else filterParams.secondary_filter = value;

			navigate({
				pathname: location.pathname,
				search: stringifyParamsForUrl(filterParams),
			});
		},
		[location.pathname, location.search, navigate],
	);

	return (
		<ViewSelectFilter
			value={type || ''}
			defaultValue={type || ''}
			options={options}
			onChange={changeOption}
		/>
	);
};

export default React.memo(JobsTypeSelectFilter);
