import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';

import Button from '../../../atoms/buttons/button/Button';

import styles from './AddCreditsForm.module.scss';
import RadioInterface from '../../../molecules/form/radio/radio.interface';
import Container from '../../../templates/container';
import AddCreditsFormInterface from './add-credits-form.interface';
import { addCredits, CreditsRefillInterface } from '../../../../store/slices/companies.slice';
import RadioGroup from '../../form-elemets/radio-group/RadioGroup';
import InfoPopover from '../../../molecules/ui/info-popover';
import ExternalLink from '../../../atoms/navigation/external-link/ExternalLink';
import Loader from '../../../molecules/loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProductInterface } from '../../../../store/slices/products.slice';
import {
	addCreditsGA4Event,
	learnMoreGA4Event,
} from '../../../../helpers/google-analytics-4/events';
import {
	GAContentGroup,
	GAContentId,
	GAItemBrand,
	GAMethod,
} from '../../../../helpers/google-analytics-4/index.constants';
import { getCompanyDataForGA } from '../../../../helpers/google-analytics-4/helpers';
import useCompanies from '../../../../helpers/hooks/use-companies/useCompanies';
import useActiveCompany from '../../../../helpers/hooks/use-active-company/useActiveCompany';
import { APP_URLS } from '../../../../helpers/routes/routes';

const AddCreditsForm: FC<AddCreditsFormInterface> = ({
	companyId,
	products,
	creditsRefillLoading,
	className,
	containerClassName,
	userEmail,
	onSuccessHandler,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const { activeCompanyId, companies } = useCompanies();
	const activeCompany = useActiveCompany(activeCompanyId, companies);

	const { handleSubmit } = useForm();
	const [refillSuccess, setRefillSuccess] = useState(false);
	const [refillError, setRefillError] = useState(false);

	const [selectedProduct, setSelectedProduct] = useState<ProductInterface>();

	const formSubmitHandler = useCallback(async () => {
		if (selectedProduct && companyId) {
			const baseData = {
				productId: selectedProduct.id,
				type: 'purchase',
			} as CreditsRefillInterface;

			try {
				await dispatch(addCredits(companyId, { ...baseData }));
				setRefillSuccess(true);
				if (onSuccessHandler) onSuccessHandler();
			} catch (error) {
				setRefillError(true);
			}
		}
	}, [companyId, dispatch, onSuccessHandler, selectedProduct]);

	const radioButtons: Omit<RadioInterface, 'name'>[] = useMemo(() => {
		if (!products?.length) return [];

		return products?.map((product, idx) => ({
			id: product.credits,
			label: (
				<span className={styles['product-label']}>
					<span className={styles.highlighted}>${Number(product.price).toFixed(0)}</span>{' '}
					buys {Number(product.credits).toFixed(0)} SparkCredits
				</span>
			),
			value: product.id,
			defaultChecked: idx === 1,
			onChange: () => setSelectedProduct(product),
		}));
	}, [products]);

	useEffect(() => {
		if (products?.length) setSelectedProduct(products[1]);
	}, [products]);

	useEffect(() => {
		return () => {
			setRefillSuccess(false);
			setRefillError(false);
		};
	}, []);

	useEffect(() => {
		if (refillSuccess) {
			addCreditsGA4Event(
				{
					companyData: getCompanyDataForGA(activeCompany),
					contentData: {
						id: GAContentId.addCredits,
						group: GAContentGroup.addCredits,
					},
				},
				selectedProduct?.id || 0,
				Number(selectedProduct?.price).toFixed(2),
			);
		}
	}, [activeCompany, refillSuccess, selectedProduct]);

	const renderInfoBoxContent = useMemo(() => {
		const boostKnowledgeBaseUrl = process.env.REACT_APP_BOOST_KNOWLEDGE_BASE_URL;

		const triggerLearnMoreGA4Event = () => {
			learnMoreGA4Event(
				{
					companyData: getCompanyDataForGA(activeCompany),
					contentData: {
						id: GAContentId.addCredits,
						group: GAContentGroup.addCredits,
					},
				},
				{
					itemBrand: GAItemBrand.sparkBoost,
					method: GAMethod.popup,
				},
			);
		};

		return (
			<div className={styles['info-content']}>
				<div className={styles.title}>About Credits</div>
				<div className={styles.text}>
					SparkCredits are the engin platform's currency system that calculates your
					dollar spend that can be applied to boosting select jobs.
				</div>

				{boostKnowledgeBaseUrl ? (
					<ExternalLink
						className={styles['learn-more']}
						href={boostKnowledgeBaseUrl}
						target="_blank"
						rel="noopener"
						onClick={triggerLearnMoreGA4Event}
					>
						Learn more
					</ExternalLink>
				) : null}
			</div>
		);
	}, [activeCompany]);

	const renderSuccessMessage = useMemo(() => {
		return (
			<div className={styles['success-message']}>
				<div className={styles.title}>Success!</div>

				<div className={styles.description}>
					You've added {Number(selectedProduct?.credits).toFixed(0)} SparkCredits to your
					account. You'll receive an invoice at{' '}
					<span className={styles.link}>{userEmail}</span>. Contact us with any questions.
				</div>

				<Button
					className={styles.btn}
					onClick={() => {
						// @ts-ignore
						const { hasHistoryBackContext } = location?.state || {};

						if (hasHistoryBackContext) return navigate(-1);
						return navigate(APP_URLS.jobs);
					}}
				>
					Continue
				</Button>
			</div>
		);
	}, [location?.state, navigate, selectedProduct?.credits, userEmail]);

	const renderErrorMessage = useMemo(() => {
		return (
			<div className={styles['error-message']}>
				<div className={styles.title}>Oops!</div>

				<div className={styles.description}>
					Something went wrong. Please try again later.
				</div>
			</div>
		);
	}, []);

	const renderAddCreditsForm = useMemo(() => {
		return (
			<form
				className={classNames(styles.form, className)}
				onSubmit={handleSubmit(formSubmitHandler)}
			>
				<div className={classNames(styles.title, styles['with-popup'])}>
					Choose the amount of Credits to add.
					
					<InfoPopover
						className={classNames(styles['info-box'], styles.inline)}
						defaultTrigger
						contentClassName={styles['popover-width']}
					>
						{renderInfoBoxContent}
					</InfoPopover>
				</div>

				<div className={styles['form-item']}>
					<RadioGroup
						name="radio"
						radioButtons={radioButtons}
						className={styles['products-group']}
					/>
				</div>

				<Button
					className={styles.btn}
					minWidth={272}
					maxWidth={272}
					disabled={creditsRefillLoading}
				>
					{creditsRefillLoading ? (
						<Loader thin maxHeight={20} maxWidth={20} />
					) : (
						'Add credits'
					)}
				</Button>

				<div className={styles.note}>You will be billed later for this purchase.</div>
			</form>
		);
	}, [
		className,
		creditsRefillLoading,
		formSubmitHandler,
		handleSubmit,
		radioButtons,
		renderInfoBoxContent,
	]);

	const renderContent = useMemo(() => {
		if (refillSuccess) return renderSuccessMessage;
		if (refillError) return renderErrorMessage;

		return renderAddCreditsForm;
	}, [
		refillError,
		refillSuccess,
		renderAddCreditsForm,
		renderErrorMessage,
		renderSuccessMessage,
	]);

	return (
		<Container maxWidth={368} className={classNames(styles.content, containerClassName)}>
			{renderContent}
		</Container>
	);
};

export default React.memo(AddCreditsForm);
