import axios from 'axios';
import { JobsTypesValues } from '../helpers/constants';

import { JOBS } from './api.path';

const jobsListApi = {
	getJobsList: (
		companyId: string | number,
		settings?: {
			title?: string;
			facilityId?: number;
			orderBy?: string;
			pageSize?: number;
			pageNumber?: number;
			jobsType?: string;
		},
	): Promise<[]> => {
		const baseUrl = `${JOBS}?companyId=${companyId}`;

		const getJobTypeQuery = () => {
			if (settings?.jobsType !== undefined) {
				switch (settings?.jobsType) {
					case JobsTypesValues.all:
						return '';
					case JobsTypesValues.external:
						return '&hasExternalApply=true';
					default:
						return '&hasExternalApply=false';
				}
			} else return '';
		};

		const pagination = `${settings?.pageSize ? `&pageSize=${settings.pageSize}` : ''}${
			settings?.pageNumber ? `&pageNumber=${settings.pageNumber}` : ''
		}${settings?.orderBy ? `&orderBy=${settings.orderBy}` : ''}`;

		const filtering = `${settings?.title ? `&title=${settings.title}` : ''}${
			settings?.facilityId ? `&facilityId=${settings.facilityId}` : ''
		}`;

		const url = `${baseUrl}${filtering}${pagination}${getJobTypeQuery()}`;

		return axios.get(url);
	},
	cloneJobItem: (companyId: string) => {
		const url = `${JOBS}/${companyId}/clone`;

		return axios.post(url);
	},
	deleteJobItem: (companyId: string) => {
		const url = `${JOBS}/${companyId}`;

		return axios.delete(url);
	},
};

export default jobsListApi;
