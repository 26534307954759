import { useSelector } from 'react-redux';
import { ProductsListSliceInterface } from '../../../store/slices/products.slice';

export default function useProducts() {
	const {
		products: { items: productItems, loading },
	} = useSelector((store: { products: ProductsListSliceInterface }) => store);

	return {
		productItems,
		loading,
	};
}
