import React, { FC } from 'react';
import classNames from 'classnames';

import CloseInterface from './close.interface';

import styles from './Close.module.scss';

const Close: FC<CloseInterface> = ({ className, contrast, ...rest }) => {
	return (
		<button
			className={classNames(styles.close, contrast && styles.contrast, className)}
			type="button"
			{...rest}
		>
			Close
		</button>
	);
};

export default Close;
