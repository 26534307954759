import React, { FC, useEffect, useState } from 'react';

import styles from './SpeechBubble.module.scss';
import SpeechBubbleInterface from './speech-bubble.interface';
import classNames from 'classnames';

const SpeechBubble: FC<SpeechBubbleInterface> = ({
	className,
	content,
	maxWidth,
	minWidth,
	small,
	medium,
	large,
	dark,
	rightSide,
	withInfoIcon,
	showDelay = 300,
}) => {
	const [show, setShow] = useState(false);

	useEffect(() => {
		const timeout = setTimeout(() => setShow(true), showDelay);
		return () => clearTimeout(timeout);
	});

	return (
		<div
			style={{ maxWidth: maxWidth ? maxWidth : '100%', minWidth: minWidth ? minWidth : '' }}
			className={classNames(
				styles['speech-bubble'],
				className,
				show ? styles.show : '',
				small ? styles.small : '',
				medium ? styles.medium : '',
				withInfoIcon ? styles['with-info-icon'] : '',
				rightSide ? styles.right : '',
				dark ? styles.dark : '',
				large ? styles.large : '',
			)}
		>
			{content}
		</div>
	);
};

export default SpeechBubble;
