import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { APP_URLS } from '../../../routes/routes';
import useUser from '../../../hooks/use-user/useUser';
import useCompanies from '../../../hooks/use-companies/useCompanies';
import useRedirectionErrors from '../../../hooks/use-redirection-errors/useRedirectionErrors';
import FlowAbandonmentRedirectionHocInterface from './post-flow-abandonment-redirection-hoc.interface';
import { QueryParametersKey } from '../../../constants';
import { useDispatch } from 'react-redux';
import { getJobsOnly } from '../../../../store/slices/jobs.slice';
import Loader from '../../../../components/molecules/loader';

const PostFlowAbandonmentRedirectionHoc: FC<FlowAbandonmentRedirectionHocInterface> = ({
	children,
}) => {
	const [loadingState, setLoadingState] = useState(true);
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();

	const { authorized } = useUser();
	const { activeCompanyId, companies } = useCompanies();
	const { error } = useRedirectionErrors();

	useEffect(() => {
		(async () => {
			if (authorized && companies !== undefined) {
				if (
					!error &&
					companies?.length &&
					activeCompanyId &&
					location.search.includes(QueryParametersKey.completePostAJob)
				) {
					const jobsItems = await dispatch(getJobsOnly(`${activeCompanyId}`, 1, 1));

					if (jobsItems?.length) return navigate(APP_URLS.jobs);
					else return navigate(APP_URLS.createJob);
				}

				setLoadingState(false);
			}
		})();
	}, [
		authorized,
		companies,
		error,
		location.pathname,
		location.search,
		navigate,
		dispatch,
		activeCompanyId,
	]);

	if (loadingState) return <Loader wide />;

	return <>{!companies?.length ? null : children}</>;
};

export default PostFlowAbandonmentRedirectionHoc;
