import React, { FC, useMemo } from 'react';
import ErrorPageLayout from '../../components/templates/custom/error-page-layout/ErrorPageLayout';
import styles from './ExpiredAutoLogin.module.scss';
import { useLocation } from 'react-router-dom';
import { QueryParametersKey } from '../../helpers/constants';

const ExpiredAutoLogin: FC = () => {
	const location = useLocation();

	const tokenIsInvalid = useMemo(
		() => location.search.includes(QueryParametersKey.invalid),
		[location.search],
	);
	const errorText = useMemo(
		() =>
			`It looks like that link has expired. ${
				tokenIsInvalid ? '' : "We've just sent you a new one to continue your journey."
			}`,
		[tokenIsInvalid],
	);

	return <ErrorPageLayout withLogo className={styles.wrap} title="Whoops." text={errorText} />;
};

export default ExpiredAutoLogin;
