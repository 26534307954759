import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import Container from '../../../templates/container';
import BillingScreenInterface from './billing-screen.interface';
import styles from './BillingScreen.module.scss';
import TextButton from '../../../atoms/buttons/text-button/TextButton';
import BoostsHistoryWithPagination from '../boosts-history-with-pagination';
import CreditsHistoryWithPagination from '../credits-history-with-pagination';
import useCompanies from '../../../../helpers/hooks/use-companies/useCompanies';
import { addCreditsIntentGA4Event } from '../../../../helpers/google-analytics-4/events';
import { getCompanyDataForGA } from '../../../../helpers/google-analytics-4/helpers';
import useActiveCompany from '../../../../helpers/hooks/use-active-company/useActiveCompany';
import { getContentDataForGA } from '../../../../helpers/custom/common';
import { GAMethod } from '../../../../helpers/google-analytics-4/index.constants';
import { APP_URLS } from '../../../../helpers/routes/routes';
import ResizeDeviceContext from '../../../../contexts/resize-device-context';
import SelectWithFloatLabel from '../../form-elemets/select-with-float-label/SelectWithFloatLabel';
import OptionInterface from '../../../atoms/form/option/option.interface';
import Title from '../../../atoms/title/Title';
import TypographyText from '../../../atoms/ui/typography-text';
import TransactionsHistoryWithPagination from '../transactions-history-with-pagination';

const BillingScreen: FC<BillingScreenInterface> = ({ className }) => {
	const {
		activeCompanyId,
		activeCompanyBalance,
		companies,
		activeCompanyHasPaymentTransactions,
		activeCompanyCanBoost,
	} = useCompanies();
	const activeCompany = useActiveCompany(activeCompanyId, companies);

	const device = useContext(ResizeDeviceContext);

	const [selectedTable, setSelectedTable] = useState('');

	useEffect(() => {
		if (activeCompanyCanBoost) return setSelectedTable('creditsHistory');
		if (activeCompanyHasPaymentTransactions) return setSelectedTable('transactionsHistory');
	}, [activeCompanyCanBoost, activeCompanyHasPaymentTransactions]);

	const triggerCreditsIntentGAEvent = useCallback(() => {
		addCreditsIntentGA4Event(
			{
				companyData: getCompanyDataForGA(activeCompany),
				contentData: getContentDataForGA(location),
			},
			{
				method: GAMethod.button,
			},
		);
	}, [activeCompany]);

	const balanceSection = useMemo(
		() => (
			<div className={styles['balance-section']}>
				<div className={styles.balance}>
					{activeCompanyBalance?.toLocaleString()} SparkCredits
				</div>

				<TextButton
					to={APP_URLS.addCredits}
					state={{ hasHistoryBackContext: true }}
					small
					minWidth={147}
					onClick={triggerCreditsIntentGAEvent}
				>
					Add credits
				</TextButton>
			</div>
		),
		[activeCompanyBalance, triggerCreditsIntentGAEvent],
	);

	const creditsHistorySection = useMemo(
		() => <CreditsHistoryWithPagination activeCompanyId={activeCompanyId} />,
		[activeCompanyId],
	);

	const balanceHistorySection = useMemo(
		() => <BoostsHistoryWithPagination activeCompanyId={activeCompanyId} />,
		[activeCompanyId],
	);

	const transactionsHistorySection = useMemo(
		() => <TransactionsHistoryWithPagination activeCompanyId={activeCompanyId} />,
		[activeCompanyId],
	);

	const billingTables = useMemo(
		() => ({
			...(activeCompanyHasPaymentTransactions && {
				transactionsHistory: transactionsHistorySection,
			}),
			...(activeCompanyCanBoost && {
				balanceHistory: balanceHistorySection,
				creditsHistory: creditsHistorySection,
			}),
		}),
		[
			activeCompanyCanBoost,
			activeCompanyHasPaymentTransactions,
			balanceHistorySection,
			creditsHistorySection,
			transactionsHistorySection,
		],
	);

	const billingOptions: OptionInterface[] = useMemo(
		() => [
			...(activeCompanyCanBoost
				? [
						{
							text: 'Credits added',
							value: 'creditsHistory',
						},
						{
							text: 'Credits spent',
							value: 'balanceHistory',
						},
				  ]
				: []),
			...(activeCompanyHasPaymentTransactions
				? [
						{
							text: 'Transactions',
							value: 'transactionsHistory',
						},
				  ]
				: []),
		],
		[activeCompanyCanBoost, activeCompanyHasPaymentTransactions],
	);

	const renderTitle = useMemo(() => {
		if (billingOptions) {
			const title =
				billingOptions.find((option) => option?.value === selectedTable)?.text || '';

			return (
				<Title component="h2" fontSize={32} marginBottom={34} className={styles.title}>
					{title}
				</Title>
			);
		}
	}, [billingOptions, selectedTable]);

	return (
		<Container maxWidth={device !== 'desktop' ? '90%' : 883} className={className}>
			{activeCompanyCanBoost ? balanceSection : null}

			<div className={styles['section-wrap']}>
				{billingOptions?.length ? (
					<>
						{billingOptions.length > 1 ? (
							<div className={styles.select}>
								<div className={styles.pretext}>View:</div>

								<SelectWithFloatLabel
									className={styles.input}
									minWidth="150px"
									id="billing"
									label=""
									noEmptyValue
									selectSettings={{
										options: billingOptions,
										name: 'billing',
										defaultValue: selectedTable,
										onChange: (event) => setSelectedTable(event.target.value),
									}}
								/>
							</div>
						) : null}

						{billingOptions.length === 1 ? renderTitle : null}

						{billingTables[selectedTable as keyof typeof billingTables]}
					</>
				) : (
					<TypographyText className={styles.empty}>
						No transactions have been made yet.
					</TypographyText>
				)}
			</div>
		</Container>
	);
};

export default React.memo(BillingScreen);
