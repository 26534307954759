import { Dispatch } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CompanyInterface } from './companies.slice';
import facilitiesApi from '../../api/facilities.api';

export interface FacilityInterface {
	id: number;
	name: string;
	isPrimary: boolean;
	address1: string;
	address2: string;
	city: string;
	state: string;
	zip: string;
	country: string;
	companyId: number;
	longitude: number;
	latitude: number;
	ein?: string;
	company?: CompanyInterface;
}

export interface FacilitiesListSliceInterface {
	items: FacilityInterface[];
	loading: boolean;
	hasBeenLoaded: boolean;
}

const initialState: FacilitiesListSliceInterface = {
	items: [],
	loading: false,
	hasBeenLoaded: false,
};

export const facilitiesListSlice = createSlice({
	name: 'facilities',
	initialState,
	reducers: {
		loading(state) {
			state.loading = true;
		},
		loaded(state) {
			state.loading = false;
		},
		getFacilitiesListSuccess(state, { payload }: any) {
			state.items = payload;
			state.loading = false;
			state.hasBeenLoaded = true;
		},
		getFacilitiesListFailed(state, action: PayloadAction<string>) {
			state.loading = false;
			console.error(action);
		},
		clearFacilitiesList(state) {
			state.items = [];
			state.hasBeenLoaded = false;
		},
	},
});

export const getFacilitiesList = (companyId: string) => async (dispatch: Dispatch<any>) => {
	dispatch(loading());

	try {
		const response = await facilitiesApi.getFacilitiesList(companyId);
		dispatch(getFacilitiesListSuccess(response));
	} catch (err: any) {
		dispatch(getFacilitiesListFailed(err.toString()));
	}
};

export const deleteFacility =
	(id: string | number, companyId: string) => async (dispatch: Dispatch<any>) => {
		dispatch(loading());

		try {
			await facilitiesApi.deleteFacility(id);

			dispatch(getFacilitiesList(companyId));
		} catch (err: any) {
			dispatch(getFacilitiesListFailed(err.toString()));

			throw err;
		}
	};

export const updateFacility =
	(id: string | number, data: object) => async (dispatch: Dispatch<any>, getState: () => any) => {
		const state = getState();
		dispatch(loading());

		try {
			const response = await facilitiesApi.updateFacility(id, data);

			if (response) {
				dispatch(getFacilitiesList(state.companies.activeCompanyId));
			}
		} catch (err) {
			dispatch(loaded());
			throw err;
		}
	};

export const createFacility =
	(data: object, withLoader: boolean = true) =>
	async (dispatch: Dispatch<any>, getState: () => any) => {
		const state = getState();
		if (withLoader) dispatch(loading());

		try {
			const response = await facilitiesApi.createFacility({
				...data,
				companyId: state.companies.activeCompanyId,
			});

			if (response) {
				dispatch(getFacilitiesList(state.companies.activeCompanyId));
				return response;
			}
		} catch (err) {
			dispatch(loaded());
			throw err;
		}
	};

export const {
	loading,
	loaded,
	getFacilitiesListSuccess,
	getFacilitiesListFailed,
	clearFacilitiesList,
} = facilitiesListSlice.actions;
export default facilitiesListSlice.reducer;
