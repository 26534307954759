import axios, { AxiosRequestConfig } from 'axios';

import { CANDIDATES, REJECTION_REASONS } from './api.path';
import { LastCandidatesExportType } from '../store/slices/candidates.slice';
import { getUrlParamsStringFromObject } from '../helpers';
import { CandidateInterface } from '../store/slices/candidate.slice';

export interface CandidatesStatusesPayloadInterface {
	applications: {
		id: number;
		status: number;
		rejectionReason?: number;
		subject?: string;
		body?: string;
		firstName?: string;
		lastName?: string;
	}[];
}

const candidatesListApi = {
	getCandidatesList: (
		jobId: string | number,
		settings?: {
			title?: string;
			filter?: string;
			orderBy?: string;
			pageSize?: number;
			pageNumber?: number;
		},
	): Promise<[]> => {
		const baseUrl = `${CANDIDATES}?jobId=${jobId}`;
		const params = { ...settings, name: settings?.title };

		const url = `${baseUrl}${settings ? `&${getUrlParamsStringFromObject(params)}` : ''}`;

		return axios.get(url);
	},
	getCandidate: (candidateId: string | number) => {
		const url = `${CANDIDATES}/${candidateId}`;

		return axios.get(url);
	},
	getCandidatesCount: (jobId: string, filterItem?: string, text?: string) => {
		const params = {
			jobId,
			filter: filterItem,
			name: text,
		};
		const url = `${CANDIDATES}/counts${params ? '?' : ''}${getUrlParamsStringFromObject(
			params,
		)}`;

		return axios.get(url);
	},
	getLasExportCandidates: (jobId: string | number): Promise<LastCandidatesExportType> => {
		const url = `${CANDIDATES}/export?jobId=${jobId}`;

		return axios.get(url);
	},
	exportCandidates: (
		data: {
			jobId: string | number;
			filter?: string;
			applications?: (string | number)[];
		},
		config?: AxiosRequestConfig,
	): Promise<{ link: string }> => {
		const url = `${CANDIDATES}/export?jobId=${data.jobId}${
			data.filter && `&filter=${data.filter}`
		}`;

		return axios.post(
			url,
			{
				applications: data.applications,
			},
			config,
		);
	},
	changeCandidatesStatus: (
		companyId: string | number,
		status?: string,
		rejectionReason?: number,
		messageSubject?: string,
		messageBody?: string
	): Promise<CandidateInterface> => {
		const url = `${CANDIDATES}/${companyId}`;

		const body = status && {
			status: parseInt(status, 10),
			rejectionReason,
			subject: messageSubject,
			body: messageBody
		};

		return axios.patch(url, body);
	},
	changeMultipleCandidatesStatuses: (body: CandidatesStatusesPayloadInterface) => {
		const url = `${CANDIDATES}`;

		return axios.patch(url, body);
	},
	getRejectionReasons: () => {
		const url = `${REJECTION_REASONS}`;

		return axios.get(url);
	},
};

export default candidatesListApi;
