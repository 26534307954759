import classNames from 'classnames';
import { RichUtils } from 'draft-js';
import React, { FC, useCallback } from 'react';
import CalendarLinkCustomItemInterface from './calendar-link-customer-item';
import calendarItemIcon from '../../../../../assets/icons/calendar-toolbar-item.svg';
import styles from './CalendarLinkCustomItem.module.scss';
import { TemplateTags } from '../../../../../helpers/custom/editors';

const CalendarLinkCustomItem: FC<CalendarLinkCustomItemInterface> = ({
	editorState,
	setEditorStateHandler,
}) => {
	const addCalendarLink = useCallback(() => {
		const contentState = editorState.getCurrentContent();
		const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {
			url: `[${TemplateTags.yourCalendarLink}]`,
			target: '_blank',
		});
		const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

		const newState = RichUtils.toggleLink(editorState, editorState.getSelection(), entityKey);
		if (newState) setEditorStateHandler(newState);
	}, [editorState, setEditorStateHandler]);

	return (
		<div
			className={classNames(styles['custom-item'], 'rdw-storybook-custom-option')}
			onClick={addCalendarLink}
		>
			<img src={calendarItemIcon} width={20} height={20} />
		</div>
	);
};

export default CalendarLinkCustomItem;
