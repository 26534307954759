export const PICTURE_EXTENSIONS = ['jpeg', 'jpg', 'png'];
export const PICTURE_SIZE_LIMIT = 10485760; // 10MB

export const PREVIEW_RESUME_FILE_EXTENSIONS = ['pdf', 'jpeg', 'jpg', 'png'];
export const RESUME_FILE_EXTENSIONS = ['pdf', 'docx', 'doc', 'jpeg', 'jpg', 'png'];
export const RESUME_FILE_SIZE_LIMIT = 10485760; // 10MB

export const fileSizeValidation = (
	file: File,
	maxSize: number,
	sizeText: string = '10Mb.',
	errorMessage: string = 'Image cannot be uploaded. Please choose a file that is less than',
) => {
	if (!file) return;

	const size = file && file.size;
	let message;

	if (size > maxSize) message = errorMessage + ' ' + sizeText;

	return message;
};
