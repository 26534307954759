import React, { CSSProperties, FC } from 'react';
import classNames from 'classnames';

import TitleInterface from './title.interface';

import styles from './Title.module.scss';

const Title: FC<TitleInterface> = ({
	children,
	component: Component,
	fontSize,
	fontWeight,
	marginBottom = '0',
	className,
	robotoFont,
	...rest
}) => {
	const inlineStyles: CSSProperties = {
		fontSize: fontSize ? `${fontSize}px` : '20px',
		fontWeight: fontWeight || 400,
		marginBottom: marginBottom,
	};

	return children ? (
		<Component
			style={inlineStyles}
			className={classNames(styles.title, robotoFont && styles.roboto, className)}
			{...rest}
		>
			{children}
		</Component>
	) : null;
};

export default Title;
