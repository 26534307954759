import React, { FC } from 'react';
import classNames from 'classnames';

import AddImageTile from '../../molecules/form/add-image-tile/AddImageTile';
import UploadedImagePreviewInterface from './uploaded-image-preview.interface';
import UploadedImagePreviewHoc from '../../../helpers/hocs/uploaded-image-preview-hoc';

import styles from './UploadedImagePreview.module.scss';
import { getMinMaxHeightStyles, getMinMaxWidthStyles } from '../../../helpers';

const UploadedImagePreview: FC<UploadedImagePreviewInterface> = ({
	className,
	inputSettings,
	loading,
	tileClassName,
	text,
	minWidth,
	maxWidth,
	minHeight,
	maxHeight,
	description,
	errorsHandler,
	...rest
}) => {
	const inlineStyles = {
		...getMinMaxWidthStyles(minWidth, maxWidth),
		...getMinMaxHeightStyles(minHeight, maxHeight),
	};

	return (
		<div
			className={classNames(styles.wrap, className, loading && styles.loading)}
			style={inlineStyles}
		>
			<UploadedImagePreviewHoc
				className={styles.preview}
				errorsHandler={errorsHandler}
				inputSettings={{ ...inputSettings, className: styles.input }}
				{...{ ...rest, loading }}
			>
				<AddImageTile
					target={inputSettings.name}
					className={classNames(styles.tile, tileClassName)}
					{...{ text, description, style: inlineStyles }}
				/>
			</UploadedImagePreviewHoc>
		</div>
	);
};

export default UploadedImagePreview;
