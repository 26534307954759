import React, { FC } from 'react';
import classNames from 'classnames';

import PageItemInterface from './page-item.interface';

import styles from './PageItem.module.scss';

const PageItem: FC<PageItemInterface> = ({
	text,
	className,
	navigationType,
	disabled,
	...rest
}) => {
	return (
		<span
			{...rest}
			className={classNames(
				styles['page-item'],
				className,
				styles[navigationType],
				disabled ? styles.disabled : '',
			)}
		>
			{text}
		</span>
	);
};

export default PageItem;
