import React from 'react';
import { LoginRedirectionPathInterface } from '../../store/reducers/login-redirection-path-reducer';

export interface LoginRedirectionContextInterface {
	state: LoginRedirectionPathInterface;
	dispatch: React.Dispatch<any>;
}

const initialState: LoginRedirectionContextInterface = {
	state: { pathname: null },
	dispatch: () => {},
};

const LoginRedirectionContext = React.createContext(initialState);

export const {
	Provider: LoginRedirectionProvider,
	Consumer: LoginRedirectionConsumer,
} = LoginRedirectionContext;
export default LoginRedirectionContext;
