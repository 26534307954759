import React, { FC, useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { RouteHOCInterface } from '../routes.interface';
import { APP_URLS, redirectTo } from '../routes';
import PublicLayout from '../../../components/templates/public-layout';
import ErrorRedirectionHoc from '../../hocs/errorRedirectionHoc/ErrorRedirectionHoc';
import BrowserStorageCrudHoc from '../../hocs/browser-storage-crud-hoc/BrowserStorageCrudHoc';
import LoginRedirectionContext from '../../../contexts/login-redirection-context';
import { setLoginRedirectionState } from '../../../store/reducers/login-redirection-path-reducer';
import StorageClearHoc from '../../hocs/storageClearHoc/StorageClearHoc';
import useUser from '../../hooks/use-user/useUser';
import useCompanies from '../../hooks/use-companies/useCompanies';

const PublicRoute: FC<RouteHOCInterface> = ({ component: Component }) => {
	const location = useLocation();
	const { authorized } = useUser();
	const { companies } = useCompanies();

	const {
		state: { pathname: loginRedirectionPath, search: loginRedirectionSearch },
		dispatch: dispatchLoginRedirection,
	} = useContext(LoginRedirectionContext);

	useEffect(() => {
		return () => {
			if (authorized) {
				dispatchLoginRedirection(setLoginRedirectionState(null, null));
			}
		};
	}, [authorized, dispatchLoginRedirection, location?.pathname]);

	const renderComponent = useMemo(() => {
		return (
			<ErrorRedirectionHoc>
				<PublicLayout>
					<BrowserStorageCrudHoc>
						<StorageClearHoc>
							<Component />
						</StorageClearHoc>
					</BrowserStorageCrudHoc>
				</PublicLayout>
			</ErrorRedirectionHoc>
		);
	}, [Component]);

	if (authorized && !location.pathname.includes(APP_URLS.signup)) {
		if (loginRedirectionPath) return redirectTo(loginRedirectionPath, loginRedirectionSearch);

		if (companies) {
			if (companies.length) return redirectTo(APP_URLS.jobs);
			return redirectTo(APP_URLS.signup);
		}
	}

	return renderComponent;
};

export default PublicRoute;
