import classNames from 'classnames';
import React, { FC } from 'react';

import PopupInterface from './popup.interface';
import styles from './Popup.module.scss';
import useUser from '../../../helpers/hooks/use-user/useUser';

const Popup: FC<PopupInterface> = ({ children, className }) => {
	const { info } = useUser();

	return (
		<>{info ? <div className={classNames(styles.popup, className)}>{children}</div> : null}</>
	);
};

export default Popup;
