import { QueryParametersKey } from "../constants";

export type FilterParamsType = {
	filter?: string;
	secondary_filter?: string;
	sort?: string;
	text?: string;
};

export function parseUrlParams<T>(queryString: string): T {
	const params = new URLSearchParams(queryString);

	const resultObj = {};

	if (params) {
		// @ts-ignore
		for (const key of params.keys()) {
			if (params.getAll(key).length > 1) {
				// @ts-ignore
				resultObj[key] = params.getAll(key);
			} else {
				// @ts-ignore
				resultObj[key] = params.get(key);
			}
		}
	}

	return resultObj as T;
}

export function getQueryForFilters(filterParameters?: FilterParamsType) {
	let queryStr = '';

	const { filter, secondary_filter, sort, text } = filterParameters || {};

	if (filter) queryStr += `${QueryParametersKey.filter}=` + filter;
	if (secondary_filter) queryStr += `${QueryParametersKey.secondaryFilter}=` + secondary_filter;
	if (sort) queryStr += `${queryStr ? '&' : ''}` + `${QueryParametersKey.sort}=` + sort;
	if (text) queryStr += `${queryStr ? '&' : ''}` + `${QueryParametersKey.text}=` + text;

	return queryStr;
}

export const stringifyParamsForUrl = (queryObject: any) => {
	const stringified = Object.keys(queryObject)
		.map((key) => queryObject[key] && key + '=' + queryObject[key].replace(/ /g, '+'))
		.filter((item) => item)
		.join('&');

	return stringified;
};
