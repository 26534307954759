import { Dispatch } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import notesApi from '../../api/notes.api';
import { PaginationType } from '../../helpers/pagination';
import { RootStateType } from '../reducers/root-reducer';

export const NoteStatusDisplay = {
	applied: 'Applied',
	rejected: 'Rejected',
	shortlisted: 'Short-listed',
	contacted: 'Contacted',
	interviewed: 'Interviewed',
	offered: 'Offered',
	hired: 'Hired',
};

export const NoteActions = {
	rejectionEmailSent: 'rejection-email-sent',
	applicationStatusChanged: 'application-status-changed',
	onsiteInterviewRequest: 'message-onsite-interview-request',
	phoneInterviewRequest: 'message-phone-interview-request',
	additionalInformationRequest: 'message-additional-information-request',
};

export interface AuthorInterface {
	id: number;
	firstName: string;
	lastName: string;
}

export interface NoteInterface {
	id: number;
	note?: string;
	author: AuthorInterface;
	user: AuthorInterface;
	job: {
		id: number;
		title: string;
		location: {
			city: string;
			state: string;
		};
	};
	createdAt: string;
	updatedAt: string;
	action?: string;
	newStatus?: string;
	oldStatus?: string;
	label?: string;
}

export interface NotesSliceInterface {
	userId?: number;
	items: NoteInterface[] | undefined;
	loading?: boolean;
	adding?: boolean;
	deletingIds: (string | number)[];
}

const initialState: NotesSliceInterface = {
	items: undefined,
	deletingIds: [],
};

export const notesSlice = createSlice({
	name: 'notes',
	initialState,
	reducers: {
		loading(state) {
			state.loading = true;
		},
		loaded(state) {
			state.loading = false;
		},
		adding(state, { payload }: PayloadAction<boolean | undefined>) {
			state.adding = payload;
		},
		deleting(state, { payload }: PayloadAction<(string | number)[]>) {
			state.deletingIds = payload;
		},
		getNotesListSuccess(state, { payload }: PayloadAction<NoteInterface[]>) {
			state.items = payload;
		},
		setNotesListUser(state, { payload }: PayloadAction<number>) {
			state.userId = payload;
		},
		getNotesListFailed(state, action: PayloadAction<string>) {
			throw action;
		},
		clearNotesList(state) {
			state.userId = undefined;
			state.items = undefined;
		},
	},
});

export type NoteParamsType = {
	userId: number | string;
	companyId: number | string;
	showLogs?: boolean;
};

export const getNotesList =
	(params: NoteParamsType & PaginationType, loadingState: boolean = true) =>
	async (dispatch: Dispatch<any>) => {
		if (loadingState) {
			dispatch(loading());
		}

		try {
			const response = await notesApi.getNotesList(params);
			dispatch(setNotesListUser(Number(params.userId)));
			dispatch(getNotesListSuccess(response));

			if (loadingState) {
				dispatch(loaded());
			}
		} catch (err) {
			dispatch(getNotesListFailed(err.toString()));

			if (loadingState) {
				dispatch(loaded());
			}
		}
	};

export const getNotesForCandidate = (params: NoteParamsType) => async () => {
	try {
		return await notesApi.getNotesList(params);
	} catch (err) {
		return err;
	}
};

export const addNote =
	(params: NoteParamsType & PaginationType) => async (dispatch: Dispatch<any>) => {
		dispatch(adding(true));

		try {
			await notesApi.addNote(params);

			await dispatch(
				getNotesList(
					{
						userId: params.userId,
						companyId: params.companyId,
						showLogs: true,
						pageSize: 1000,
					},
					false,
				),
			);

			dispatch(adding(false));
		} catch (err) {
			dispatch(getNotesListFailed(err.toString()));
		}
	};

export const editNote =
	(params: NoteParamsType & { noteId: number }) => async (dispatch: Dispatch<any>) => {
		dispatch(adding(true));

		try {
			await notesApi.editNote(params);

			await dispatch(
				getNotesList(
					{
						userId: params.userId,
						companyId: params.companyId,
						showLogs: true,
						pageSize: 1000,
					},
					false,
				),
			);

			dispatch(adding(false));
		} catch (err) {
			dispatch(getNotesListFailed(err.toString()));
		}
	};

export const deleteNote =
	(params: NoteParamsType & PaginationType & { nodeId: number }) =>
	async (dispatch: Dispatch<any>, getState: () => RootStateType) => {
		dispatch(deleting([...getState().notes.deletingIds, params.nodeId]));

		try {
			await notesApi.deleteNote(params.nodeId);

			await dispatch(
				getNotesList(
					{
						userId: params.userId,
						companyId: params.companyId,
						showLogs: true,
						pageSize: 1000,
					},
					false,
				),
			);

			dispatch(
				deleting(getState().notes.deletingIds.filter((item) => item !== params.nodeId)),
			);
		} catch (err) {
			dispatch(
				deleting(getState().notes.deletingIds.filter((item) => item !== params.nodeId)),
			);
		}
	};

export const {
	getNotesListSuccess,
	getNotesListFailed,
	setNotesListUser,
	clearNotesList,
	loading,
	adding,
	loaded,
	deleting,
} = notesSlice.actions;
export default notesSlice.reducer;
