import React, { FC } from 'react';
import classNames from 'classnames';

import FieldsetInterface from './fieldset.interface';

import styles from './Fieldset.module.scss';

const Fieldset: FC<FieldsetInterface> = ({ children, className, ...rest }) => {
	return (
		<fieldset className={classNames(styles.fieldset, className)} {...rest}>
			{children}
		</fieldset>
	);
};

export default Fieldset;
