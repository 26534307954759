import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';

import { getMinMaxWidthStyles } from '../../../../helpers';
import SelectWithFloatLabelInterface from './select-with-float-label.interface';
import FormItem from '../../../molecules/form/form-item/FormItem';
import Label from '../../../atoms/form/label/Label';
import Error from '../../../molecules/ui/error';
import Select from '../../../molecules/form/select/Select';

import styles from './SelectWithLabel.module.scss';

const SelectWithFloatLabel: FC<SelectWithFloatLabelInterface> = React.forwardRef(
	(
		{
			id,
			label,
			labelSettings,
			className,
			selectSettings,
			minWidth,
			maxWidth,
			disabled,
			visibleDisableStyles = true,
			error,
			cancelLabelState,
			iconClassName,
			noEmptyValue,
			...rest
		},
		ref,
	) => {
		const [isLabelUp, setLabelUp] = useState<boolean>(false);
		const inlineStyles = getMinMaxWidthStyles(minWidth, maxWidth);

		useEffect(() => {
			if (
				(selectSettings?.defaultValue !== undefined &&
					selectSettings?.defaultValue !== null) ||
				(selectSettings?.value !== undefined && selectSettings?.value !== '')
			) {
				setLabelUp(true);
			}
		}, [selectSettings]);

		useEffect(() => {
			if (cancelLabelState) {
				setLabelUp(false);
			}
		}, [cancelLabelState, selectSettings]);

		const isEmptyHandler = ({ target: { value } }: { target: { value: string | any[] } }) => {
			if (!value.length) {
				setLabelUp(false);
			} else setLabelUp(true);
		};

		return (
			<FormItem
				style={inlineStyles}
				data-testid="select-with-float-label"
				className={classNames(
					styles['form-item'],
					className,
					error && styles['error__wrap'],
					isLabelUp && styles['up-label'],
					disabled && styles.disabled,
					visibleDisableStyles && styles['visible-disable-styles'],
				)}
				{...rest}
			>
				<Label
					{...{
						...labelSettings,
						className: classNames(styles.label),
					}}
					htmlFor={id}
				>
					{label}
				</Label>

				<Select
					{...{
						id,
						ref,
						...{
							...selectSettings,
							defaultValue:
								selectSettings?.defaultValue !== undefined ||
								selectSettings?.defaultValue !== null
									? selectSettings?.defaultValue?.toString()
									: undefined,
							className: styles.select,
							formItemClassName: styles['select-form-item'],
							iconBefore: <span className={classNames(styles.icon, iconClassName)} />,
							disabled: disabled || selectSettings?.disabled,
							options: [
								...(!noEmptyValue
									? [
											{
												text: '',
												value: '',
											},
									  ]
									: []),
								...selectSettings.options,
							],
						},
					}}
					onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
						isEmptyHandler(event);

						if (selectSettings?.onChange) {
							selectSettings.onChange(event);
						}
					}}
					onFocus={isEmptyHandler}
					onBlur={isEmptyHandler}
				/>

				<Error className={styles.error}>{error}</Error>
			</FormItem>
		);
	},
);

export default React.memo(SelectWithFloatLabel);
