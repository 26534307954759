import React, { FC } from 'react';
import classNames from 'classnames';

import SpecificFitScoreInterface from './specific-fit-score.interface';

import styles from './SpecificFitScore.module.scss';
import SpecificScoreBar from '../../molecules/ui/specific-score-bar';

const SpecificFitScore: FC<SpecificFitScoreInterface> = ({
	className,
	text,
	score,
	width = 100,
	height = 16,
}) => {
	return (
		<div
			className={classNames(
				styles['specific-fit-score'],
				className,
			)}
		>
			<SpecificScoreBar {...{ score, width, height }} />

			<span className={styles.text}>{text}</span>
		</div>
	);
};

export default SpecificFitScore;