const getFormattedDate = (dateString: string | number, monthIncreaser?: number): string => {
	const date = new Date(dateString);

	if (monthIncreaser) {
		date.setMonth(date.getMonth() + monthIncreaser);
	}

	return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

const getFormattedDateWithShortMonth = (dateString: string | number): string => {
	const date = new Date(dateString);

	return `${date.getDate()} ${date.toLocaleString('default', {
		month: 'short',
	})} ${date.getFullYear()}`;
};

const getFormattedDateWithLongMonth = (dateString: string | number): string => {
	const date = new Date(dateString);

	return `${date.getDate()} ${date.toLocaleString('default', {
		month: 'long',
	})} ${date.getFullYear()}`;
};

const getDifferenceInTime = (firstDate: Date, secondDate: Date) =>
	firstDate.getTime() - secondDate.getTime();

const getDifferenceInDays = (firstDate: Date, secondDate: Date) =>
	Math.round(getDifferenceInTime(firstDate, secondDate) / (1000 * 3600 * 24));

export {
	getFormattedDate,
	getFormattedDateWithShortMonth,
	getDifferenceInTime,
	getDifferenceInDays,
	getFormattedDateWithLongMonth,
};
