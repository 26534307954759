import React, { FC, useRef, ReactElement, useEffect, useCallback } from 'react';

interface IClickAwayListener {
	onClickAway: () => void;
	children: ReactElement;
}

const getOwnerDocument = (node: Node | null | undefined): Document => {
	return (node && node.ownerDocument) || document;
};

const ClickAwayListener: FC<IClickAwayListener> = ({ onClickAway, children }) => {
	const childrenRef = useRef<any>(null);

	const clickHandler = useCallback(
		(e: MouseEvent) => {
			const ignoreElement = childrenRef.current;
			const clickOutside = !ignoreElement?.contains(e.target);

			if (clickOutside) onClickAway();
		},
		[onClickAway],
	);

	useEffect(() => {
		const ownerDocument = getOwnerDocument(childrenRef.current);
		ownerDocument.addEventListener('click', clickHandler, { capture: true });

		return () => ownerDocument.removeEventListener('click', clickHandler, { capture: true });
	}, [clickHandler]);

	return <React.Fragment>{React.cloneElement(children, { ref: childrenRef })}</React.Fragment>;
};

export default ClickAwayListener;
