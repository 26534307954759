import React, { FC, useMemo } from 'react';
import classNames from 'classnames';

import styles from './CandidateInfoHeading.module.scss';
import CandidateInfoHeadingInterface from './candidate-info-heading.interface';
import Tooltip from '../../../atoms/ui/tooltip';
import GrabLink from '../../grab-link';
import Icon from '../../../atoms/icons/icon';
import notesIcon from '../../../../assets/icons/note.svg';
import messageCandidateIcon from '../../../../assets/icons/email-message.svg';
import useCandidate from '../../../../helpers/hooks/use-candidate/useCandidate';

const CandidateInfoHeading: FC<CandidateInfoHeadingInterface> = ({
	onMessageClick,
	onNotesClick,
	hasCustomerNotes,
	className,
}) => {
	const { candidateInfo } = useCandidate();

	const renderActions = useMemo(() => {
		return (
			<div className={styles['quick-actions']}>
				<div
					className={classNames(styles.message, styles['item-with-tooltip'])}
					onClick={onMessageClick}
				>
					<img src={messageCandidateIcon} alt="Message" />

					<Tooltip className={styles['item-tooltip']} text={'Message candidate'} />
				</div>

				<GrabLink
					link={window.location.href}
					className={classNames(styles['grab-link'], styles['item-with-tooltip'])}
					copyTextOnTop={hasCustomerNotes}
				>
					<Icon icon="link" />

					<Tooltip className={styles['item-tooltip']} text={'Copy link'} />
				</GrabLink>

				{hasCustomerNotes ? (
					<div
						className={classNames(styles.notes, styles['item-with-tooltip'])}
						onClick={onNotesClick}
					>
						<img src={notesIcon} alt="Notes" />

						<Tooltip className={styles['item-tooltip']} text={'View notes'} />
					</div>
				) : null}
			</div>
		);
	}, [hasCustomerNotes, onMessageClick, onNotesClick]);

	return candidateInfo ? (
		<h2 className={classNames(styles.title, className)}>
			{candidateInfo.firstName} {candidateInfo.lastName}
			{renderActions}
		</h2>
	) : null;
};

export default CandidateInfoHeading;
