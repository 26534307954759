import React, { FC, useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { candidatesStatusLabels, setSpecificPagination } from '../../../../helpers';
import { changeMultipleCandidatesStatuses } from '../../../../store/slices/candidates.slice';
import { CandidatesStatusesPayloadInterface } from '../../../../api/candidates.api';
import StatusSelect from '../../../molecules/status-select/StatusSelect';
import MultipleCandidatesSelectStatusesInterface from './multiple-candidates-select-statuses.interface';
import FilterContext from '../../../../contexts/filter-context';
import PaginationContext from '../../../../contexts/pagination-context';
import { RootStateType } from '../../../../store/reducers/root-reducer';
import useActiveCompany from '../../../../helpers/hooks/use-active-company/useActiveCompany';
import OptionInterface from '../../../atoms/form/option/option.interface';
import { useNavigate } from 'react-router-dom';
import { CandidatesStatusValues } from '../../../../helpers/constants';
import { APP_URLS } from '../../../../helpers/routes/routes';

const MultipleCandidatesSelectStatuses: FC<MultipleCandidatesSelectStatusesInterface> = ({
	jobId,
	onChange,
	pageSize,
	candidates,
	defaultFiltering,
	...rest
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { dispatch: dispatchPaginationNumber } = useContext(PaginationContext);
	const {
		state: {
			filters: { text, filterItem, filterOrder },
		},
	} = useContext(FilterContext);
	const {
		companies: { activeCompanyId, items: companies },
	} = useSelector((state: RootStateType) => state);
	const activeCompany = useActiveCompany(activeCompanyId, companies);
	const candidatesStatusOptions = Array.from(candidatesStatusLabels, (item: OptionInterface) => ({
		...item,
	}));
	const hiredText = 'Hired in Würk';

	if (activeCompany?.hasWurk) {
		candidatesStatusOptions[
			candidatesStatusOptions.findIndex((item) => item.value === CandidatesStatusValues.hired)
		].text = hiredText;

		candidatesStatusOptions[
			candidatesStatusOptions.findIndex((item) => item.value === CandidatesStatusValues.hired)
		].disabled = true;
	}

	const changeMultipleStatuses = useCallback(
		(payload: CandidatesStatusesPayloadInterface) => {
			const settings = {
				pageNumber: 1,
				pageSize,
				title: text,
				filter: filterItem || defaultFiltering,
				orderBy: filterOrder,
			};

			if (payload.applications[0].status === CandidatesStatusValues.rejected) {
				const candidatesData = payload.applications.map((candidate: any) => ({
					id: candidate.id,
					firstName: candidate.firstName,
					lastName: candidate.lastName,
				}));
				navigate(APP_URLS.candidateRejection, {
					state: { candidates: candidatesData, source: 'list', jobId, settings },
				});
				return;
			}

			setSpecificPagination(1, dispatchPaginationNumber);

			dispatch(changeMultipleCandidatesStatuses(jobId, payload, settings));
		},
		[
			defaultFiltering,
			dispatch,
			dispatchPaginationNumber,
			filterItem,
			filterOrder,
			jobId,
			navigate,
			pageSize,
			text,
		],
	);

	return (
		<StatusSelect
			value={''}
			emptyOption={'Set status'}
			options={candidatesStatusOptions}
			visibleDisableStyles
			onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
				const applications = candidates.map(({ id, firstName, lastName }) => {
					return {
						id: parseInt(id),
						status: parseInt(event.target.value),
						firstName,
						lastName,
					};
				});

				const body: CandidatesStatusesPayloadInterface = {
					applications,
				};

				changeMultipleStatuses(body);

				if (onChange) {
					onChange(event);
				}
			}}
			{...rest}
		/>
	);
};

export default React.memo(MultipleCandidatesSelectStatuses);
