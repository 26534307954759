import React, { FC, useMemo } from 'react';

import ConfirmationPopup from '../confirmation-popup';
import PrepaidPublishConfirmationPopupInterface from './prepaid-publish-confirmation-popup.interface';
import useCompanies from '../../../../helpers/hooks/use-companies/useCompanies';

const PrepaidPublishConfirmationPopup: FC<PrepaidPublishConfirmationPopupInterface> = ({
	openState,
	openStateHandler,
	onConfirmHandler,
}) => {
	const { activeCompanyPrepaidJobsBalance } = useCompanies();

	const jobsRemainingNumber = useMemo(() => {
		if (!activeCompanyPrepaidJobsBalance || activeCompanyPrepaidJobsBalance <= 0) return 0;

		return activeCompanyPrepaidJobsBalance - 1;
	}, [activeCompanyPrepaidJobsBalance]);

	return (
		<ConfirmationPopup
			title="Publishing job"
			description={`You are using one of your purchased jobs for this post. After publishing, you will have ${jobsRemainingNumber} job${
				jobsRemainingNumber !== 1 ? 's' : ''
			} remaining.`}
			popupWidth={364}
			confirmButtonText="Continue"
			onlyConfirmButton
			btnsReversedOrder
			open={openState}
			openStateHandler={openStateHandler}
			onConfirmAndClose={onConfirmHandler}
		/>
	);
};

export default React.memo(PrepaidPublishConfirmationPopup);
