import React, { FC, useCallback, useEffect, useMemo } from 'react';

import EinNumberFieldInterface from './ein-number-field.interface';
import {
	clearEinNumber,
	EinNumberInterface,
	getEinNumbers,
} from '../../../../store/slices/ein-numbers.slice';
import useActiveCompany from '../../../../helpers/hooks/use-active-company/useActiveCompany';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../../../store/reducers/root-reducer';
import SelectWithFloatLabel from '../../form-elemets/select-with-float-label/SelectWithFloatLabel';
import OptionInterface from '../../../atoms/form/option/option.interface';

const EinNumberField: FC<EinNumberFieldInterface> = ({
	label = 'Ein',
	id = 'ein',
	selectSettings,
	...rest
}) => {
	const dispatch = useDispatch();
	const {
		companies: { activeCompanyId, items },
		einNumber,
	} = useSelector((state: RootStateType) => state);

	const activeCompany = useActiveCompany(activeCompanyId, items);

	useEffect(() => {
		if (
			activeCompany?.id &&
			activeCompany.hasWurk &&
			einNumber.loading !== ('pending' || 'fulfilled') &&
			!einNumber.items
		) {
			dispatch(getEinNumbers(activeCompany?.id));
		}
	}, [activeCompany, dispatch, einNumber]);

	useEffect((): any => {
		return () => dispatch(clearEinNumber());
	}, [dispatch]);

	const getEinNumbersOptions = useCallback(() => {
		if (einNumber?.items) {
			return einNumber.items.map(
				({ label, value }: EinNumberInterface): OptionInterface => ({
					value,
					text: label,
				}),
			);
		}

		return [];
	}, [einNumber.items]);

	const options = useMemo(() => getEinNumbersOptions(), [getEinNumbersOptions]);

	return (
		<SelectWithFloatLabel
			{...{ label, id, ...rest }}
			selectSettings={{
				...selectSettings,
				name: 'ein',
				options,
			}}
		/>
	);
};

export default EinNumberField;
