import React, { FC } from 'react';

import styles from './InfoKey.module.scss';
import InfoKeyInterface from './info-key.interface';

const InfoKey: FC<InfoKeyInterface> = ({ children }) => {
	return <div className={styles['info-key']}>{children}</div>;
};

export default InfoKey;
