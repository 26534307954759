import React, { FC, useRef } from 'react';
import classNames from 'classnames';

import styles from './ErrorNotification.module.scss';
import ErrorNotificationInterface from './error-notification.interface';
import { createPortal } from 'react-dom';

const ErrorNotification: FC<ErrorNotificationInterface> = ({ message, className, ...rest }) => {
	const documentElement = useRef(document.getElementById('root'));

	return message && documentElement.current
		? createPortal(
				<div className={classNames(styles['error-notification'], className)} {...rest}>
					<span className={styles.message}>{message}</span>
				</div>,
				documentElement.current,
		  )
		: null;
};

export default ErrorNotification;
