import React, { FC, useEffect, useMemo } from 'react';

import styles from './JobPreview.module.scss';
import JobDetail from '../../job-detail/JobDetail';
import JobPreviewInterface from './job-preview.interface';
import TypographyText from '../../../../atoms/ui/typography-text';
import Button from '../../../../atoms/buttons/button/Button';
import TopPageLayout from '../../../../templates/top-page-layout/TopPageLayout';
import Title from '../../../../atoms/title/Title';
import { getEmploymentTypeLabel } from '../../../../../helpers';
import useCompanies from '../../../../../helpers/hooks/use-companies/useCompanies';
import Loader from '../../../../molecules/loader';
import { QueryParametersKey } from '../../../../../helpers/constants';
import useActiveCompany from '../../../../../helpers/hooks/use-active-company/useActiveCompany';
import { jobPreviewGA4Event } from '../../../../../helpers/google-analytics-4/events';
import {
	GAContentGroup,
	GAContentId,
	GAContentType,
} from '../../../../../helpers/google-analytics-4/index.constants';
import { useLocation } from 'react-router-dom';
import { getCompanyDataForGA } from '../../../../../helpers/google-analytics-4/helpers';
import useJob from '../../../../../helpers/hooks/use-job/useJob';
import classNames from 'classnames';

const JobPreview: FC<JobPreviewInterface> = ({
	formState,
	createHandler,
	isJobWithPayment,
	needsPayment,
	backHandler,
	nextHandler,
	cancelHandler,
	brandingName,
}) => {
	const location = useLocation();
	const isPostAJobFlow = useMemo(
		() => !!new URLSearchParams(location.search).get(QueryParametersKey.postAJob),
		[location.search],
	);

	const { createdJobId } = useJob();
	const { activeCompanyId, companies, prepaidJobsBalanceUpdateLoading } = useCompanies();
	const activeCompany = useActiveCompany(activeCompanyId, companies);

	const renderTopBar = useMemo(() => {
		let actions = null;

		if (needsPayment)
			actions = (
				<div className={classNames(styles.actions, styles['post-job'])}>
					<Button
						type="button"
						reversedStyles
						small
						minWidth={106}
						maxWidth={106}
						minHeight={40}
						maxHeight={40}
						className={styles.btn}
						disabled={!!(createdJobId && isPostAJobFlow)}
						onClick={isPostAJobFlow ? backHandler : cancelHandler}
					>
						{isPostAJobFlow ? 'Back' : 'Cancel'}
					</Button>

					<Button
						type="button"
						small
						minWidth={106}
						maxWidth={106}
						minHeight={40}
						maxHeight={40}
						className={styles.btn}
						onClick={nextHandler}
					>
						Next
					</Button>
				</div>
			);
		else {
			actions = (
				<div className={styles.actions}>
					<Button
						type="button"
						reversedStyles
						small
						className={styles.btn}
						onClick={() => createHandler(false)}
					>
						Publish later
					</Button>

					<Button
						type="button"
						small
						className={styles.btn}
						onClick={() => createHandler(true)}
					>
						Publish now
					</Button>
				</div>
			);
		}

		return (
			<div className={styles['top-bar']}>
				<div className={styles.name}>Preview</div>

				{!isJobWithPayment ? (
					<TypographyText className={styles.description}>
						Your job is ready to be created. You can publish your job now or choose to
						publish it later in the job settings. Unpublished jobs are not open to
						candidate applications.
					</TypographyText>
				) : null}

				{actions}
			</div>
		);
	}, [
		needsPayment,
		createdJobId,
		isPostAJobFlow,
		backHandler,
		cancelHandler,
		nextHandler,
		isJobWithPayment,
		createHandler,
	]);

	const renderJobPreview = useMemo(() => {
		return (
			<div className={styles.preview}>
				<TopPageLayout className={styles['top-page']} containerClassName={styles.container}>
					<div className={styles.titles}>
						<Title component="h2" fontSize={26} fontWeight={600}>
							{formState.title}
						</Title>

						{formState.facilityName ? (
							<Title
								component="h3"
								fontSize={18}
								fontWeight={300}
								className={styles.subtitle}
							>
								{formState.facilityName}
							</Title>
						) : null}

						{formState.employmentType !== undefined ? (
							<Title
								component="h3"
								fontSize={18}
								fontWeight={300}
								className={styles.subtitle}
							>
								{getEmploymentTypeLabel(Number(formState.employmentType))}
							</Title>
						) : null}
					</div>
				</TopPageLayout>

				<div className={styles.details}>
					<JobDetail jobInfo={formState} context={'preview'} />
				</div>
			</div>
		);
	}, [formState]);

	useEffect(() => {
		window.scrollTo(0, 0);

		if (activeCompany && !createdJobId) {
			jobPreviewGA4Event(
				{
					companyData: getCompanyDataForGA(activeCompany),
					contentData: {
						id: brandingName ? GAContentId.postAJob : GAContentId.job,
						group: brandingName ? GAContentGroup.postAJob : GAContentGroup.job,
						type: GAContentType.create,
					},
				},
				brandingName,
			);
		}
	}, [activeCompany, brandingName, createdJobId]);

	return (
		<>
			{renderTopBar}

			{renderJobPreview}

			{prepaidJobsBalanceUpdateLoading ? <Loader wide withOverlay /> : null}
		</>
	);
};

export default JobPreview;
