import React, { FC } from 'react';
import classNames from 'classnames';

import IconInterface from './icon.interface';

import styles from './Icon.module.scss';

const Icon: FC<IconInterface> = ({ icon, color, disabled, large, className }) => {
	const inlineStyles = { color: color };

	return (
		<i
			style={inlineStyles}
			className={classNames(
				styles.icon,
				icon && `icon-${icon}`,
				className,
				disabled ? styles.disabled : '',
				large ? styles.large : '',
			)}
		>
			{icon}
		</i>
	);
};

export default Icon;
