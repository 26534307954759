import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import styles from './UserInfoStep.module.scss';
import InputWithFloatLabel from '../../../components/organisms/form-elemets/input-with-float-label/InputWithFloatLabel';
import {
	emailValidation,
	getTargetMessageByProperty,
	minLengthValidation,
	requireValidation,
	spacesValidation,
	transformNumericFieldsOfPayload,
} from '../../../helpers/forms/forms';
import Button from '../../../components/atoms/buttons/button/Button';
import UserInfoStepInterface from './user-info-step.interface';
import Title from '../../../components/atoms/title/Title';
import TypographyText from '../../../components/atoms/ui/typography-text';
import CheckboxWithLabel from '../../../components/molecules/form/checkbox-with-label/CheckboxWithLabel';
import ButtonLink from '../../../components/atoms/buttons/button-link';
import { useLocation, useNavigate } from 'react-router-dom';
import { AutoLoginActions, ConsumerAppUrlByEnv } from '../../../helpers/custom/common';
import { useDispatch } from 'react-redux';
import {
	SignUpInterface,
	quickSignUp,
	sendValidationCode,
	setAuthorizationInfoSuccess,
} from '../../../store/slices/authorization.slice';
import useExternalFormErrors from '../../../helpers/hooks/use-external-form-errors/useExternalFormErrors';
import useAuthorization from '../../../helpers/hooks/use-authorization/useAuthorization';
import Loader from '../../../components/molecules/loader';
import UtmParamsContext from '../../../contexts/utm-params-context';
import { ExternalErrorType } from '../../../helpers/axios/axios-global';
import ExternalLink from '../../../components/atoms/navigation/external-link/ExternalLink';
import { AppProducts, QueryParametersKey, StorageItemKeys } from '../../../helpers/constants';
import { APP_URLS } from '../../../helpers/routes/routes';
import Error from '../../../components/molecules/ui/error';
import {
	browseJobsGA4Event,
	loginIntentGA4Event,
	registeredGA4Event,
	termsGA4Event,
} from '../../../helpers/google-analytics-4/events';
import {
	GAContentGroup,
	GAContentId,
	GAContentType,
} from '../../../helpers/google-analytics-4/index.constants';
import { getJobDescriptionByUuid } from '../../../store/slices/job.slice';
import { GeneratedJobDescriptionInterface } from '../../../helpers/custom/job';

const UserInfoStep: FC<UserInfoStepInterface> = ({ onNextHandler, brandingName, isFromWidget }) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const { register, errors: formErrors, handleSubmit } = useForm();

	const [errors, setErrors] = useState<Partial<ExternalErrorType> | null>();
	const [loadingAfterWidget, setLoadingAfterWidget] = useState(isFromWidget);
	const [loading, setLoading] = useState(false);
	const [prefilledUserData, setPrefilledUserData] = useState<Partial<SignUpInterface>>();

	const env = process.env.REACT_APP_ENV;
	const externalErrors = useExternalFormErrors(errors);
	const { loading: authorizationLoading } = useAuthorization();

	const {
		state: { utmSource: originTag },
	} = useContext(UtmParamsContext);

	const customerTermsUrl = useMemo(
		() => process.env.REACT_APP_CUSTOMER_TERMS_URL || 'https://engin.co/terms-and-conditions',
		[],
	);

	const renderTopSection = useMemo(() => {
		const onSigninClick = () => {
			loginIntentGA4Event(
				{
					contentData: {
						id: GAContentId.postAJob,
						group: GAContentGroup.postAJob,
						type: GAContentType.userInfo,
					},
				},
				brandingName,
			);

			navigate('/login');
		};

		return (
			<>
				<Title
					component="h2"
					marginBottom={24}
					fontSize={48}
					fontWeight={300}
					className={styles.title}
				>
					Get started
				</Title>

				<TypographyText className={styles.desc}>
					Enter your name and email to create an engin account below. Already have an
					engin account?{' '}
					<ButtonLink type="button" className={styles.link} onClick={onSigninClick}>
						Sign in
					</ButtonLink>
				</TypographyText>
			</>
		);
	}, [brandingName, navigate]);

	const renderRemarkSection = useMemo(() => {
		const websiteUrl = ConsumerAppUrlByEnv[env as keyof typeof ConsumerAppUrlByEnv];

		const onBrowseClick = () => {
			browseJobsGA4Event(
				{
					contentData: {
						id: GAContentId.postAJob,
						group: GAContentGroup.postAJob,
						type: GAContentType.userInfo,
					},
				},
				brandingName,
			);
		};

		return (
			<div className={styles.remark}>
				Actually looking for job opportunities?
				<br />
				<ExternalLink
					className={styles.link}
					href={websiteUrl}
					target="_blank"
					rel="noopener noreferrer"
					onClick={onBrowseClick}
				>
					Browse jobs
				</ExternalLink>{' '}
				on Careers in Cannabis
			</div>
		);
	}, [brandingName, env]);

	const onFormSubmitHandler = useCallback(
		async (data: Partial<SignUpInterface>) => {
			setLoading(true);

			const formattedData = transformNumericFieldsOfPayload<Partial<SignUpInterface>>(data, [
				'acceptedCustomerTerms',
			]);

			const autologinData = {
				action: AutoLoginActions.signupValidation,
				destinationUrl: `${APP_URLS.signup}${location.search}`,
			};

			localStorage.setItem(StorageItemKeys.firstName, formattedData.firstName || '');
			localStorage.setItem(StorageItemKeys.lastName, formattedData.lastName || '');

			const result = await dispatch(
				quickSignUp({
					...formattedData,
					...autologinData,
					product: AppProducts.postJob,
					originTag: originTag || '',
				}),
			);

			//@ts-ignore
			if (result?.errors?.length || result?.message) {
				//@ts-ignore
				const emailError = getTargetMessageByProperty('email', result?.errors);

				if (emailError && formattedData.email) {
					delete formattedData['password'];
					await dispatch(setAuthorizationInfoSuccess(formattedData));

					dispatch(
						sendValidationCode(
							{ email: formattedData.email },
							{
								...autologinData,
								product: AppProducts.postJob,
								...(originTag && { originTag }),
							},
						),
					);

					setErrors(null);
					if (onNextHandler) onNextHandler();
					return;
				}

				setErrors(result as Partial<ExternalErrorType>);
			} else {
				setErrors(null);

				registeredGA4Event(
					{
						contentData: {
							id: GAContentId.postAJob,
							group: GAContentGroup.postAJob,
							type: GAContentType.userInfo,
						},
					},
					brandingName,
				);

				if (onNextHandler) onNextHandler();
			}

			setLoading(false);
		},
		[brandingName, dispatch, location.search, onNextHandler, originTag],
	);

	const renderForm = useMemo(() => {
		const onTermsClick = () => {
			termsGA4Event(
				{
					contentData: {
						id: GAContentId.postAJob,
						group: GAContentGroup.postAJob,
						type: GAContentType.userInfo,
					},
				},
				brandingName,
			);
		};

		return (
			<form className={styles.form} onSubmit={handleSubmit(onFormSubmitHandler)}>
				<div className={styles.inputs}>
					<div className={styles.group}>
						<InputWithFloatLabel
							className={styles.input}
							ref={register({
								...requireValidation(),
								...spacesValidation,
							})}
							inputSettings={{
								type: 'text',
								name: 'firstName',
								defaultValue: prefilledUserData?.firstName,
							}}
							hasErrorStyles={
								formErrors?.firstName?.message || externalErrors?.errors?.firstName
							}
							error={
								(formErrors?.firstName?.type !== 'required'
									? formErrors?.firstName?.message
									: '') ||
								externalErrors?.errors?.firstName ||
								''
							}
							id="first-name"
							label="First name"
						/>

						<InputWithFloatLabel
							className={styles.input}
							ref={register({
								...requireValidation(),
								...spacesValidation,
							})}
							inputSettings={{
								type: 'text',
								name: 'lastName',
								defaultValue: prefilledUserData?.lastName,
							}}
							hasErrorStyles={
								formErrors?.lastName?.message || externalErrors?.errors?.lastName
							}
							error={
								(formErrors?.lastName?.type !== 'required'
									? formErrors?.lastName?.message
									: '') ||
								externalErrors?.errors?.lastName ||
								''
							}
							id="last-name"
							label="Last name"
						/>
					</div>

					<div className={styles.group}>
						<InputWithFloatLabel
							className={styles.input}
							ref={register({
								...requireValidation(),
								...emailValidation(),
							})}
							inputSettings={{
								type: 'email',
								name: 'email',
								defaultValue: prefilledUserData?.email,
							}}
							hasErrorStyles={
								formErrors?.email?.message || externalErrors?.errors?.email
							}
							error={
								(formErrors?.email?.type !== 'required'
									? formErrors?.email?.message
									: '') ||
								externalErrors?.errors?.email ||
								''
							}
							id="email"
							label="Email"
						/>

						<InputWithFloatLabel
							className={styles.input}
							ref={register({
								...requireValidation(),
								...minLengthValidation(
									8,
									'Password must be at least 8 characters long',
								),
							})}
							inputSettings={{
								type: 'password',
								autoComplete: 'new-password',
								id: 'password',
								name: 'password',
							}}
							hasErrorStyles={
								formErrors?.password?.message || externalErrors?.errors?.email
							}
							error={
								(formErrors?.password?.type !== 'required'
									? formErrors?.password?.message
									: '') ||
								externalErrors?.errors?.email ||
								''
							}
							id="password"
							label="Password"
						/>
					</div>
				</div>

				<CheckboxWithLabel
					id="acceptedCustomerTermsTroubleSignInFlow"
					name="acceptedCustomerTerms"
					ref={register({
						required: true,
					})}
					defaultChecked
					error={
						formErrors?.acceptedCustomerTerms &&
						"The 'Terms & Conditions' field is required"
					}
					label={
						<span className={styles.terms}>
							By signing up, you agree to our{' '}
							<a
								href={customerTermsUrl}
								target="_blank"
								rel="noopener noreferrer"
								onClick={onTermsClick}
							>
								Terms & Conditions
							</a>
							.
						</span>
					}
				/>

				{Object.keys(formErrors).length ||
				(externalErrors && Object.keys(externalErrors).length) ? (
					<Error className={styles.error}>
						There are errors or information missing. Please check your responses and try
						again.
					</Error>
				) : null}

				<Button
					className={styles.btn}
					type="submit"
					small
					minWidth={300}
					minHeight={56}
					disabled={authorizationLoading}
				>
					{authorizationLoading || loading ? (
						<Loader thin maxHeight={20} maxWidth={20} />
					) : (
						'Create account'
					)}
				</Button>
			</form>
		);
	}, [
		authorizationLoading,
		brandingName,
		customerTermsUrl,
		externalErrors,
		formErrors,
		handleSubmit,
		loading,
		onFormSubmitHandler,
		prefilledUserData?.email,
		prefilledUserData?.firstName,
		prefilledUserData?.lastName,
		register,
	]);

	useEffect(() => {
		(async () => {
			if (isFromWidget) {
				const jobDescriptionUuid = new URLSearchParams(location.search).get(
					QueryParametersKey.descriptionUuid,
				);

				if (jobDescriptionUuid) {
					const jobDescription = (await dispatch(
						getJobDescriptionByUuid(jobDescriptionUuid),
					)) as unknown as GeneratedJobDescriptionInterface;

					const { firstName, lastName, email } = jobDescription || {};

					setPrefilledUserData({
						firstName,
						lastName,
						email,
					});
				}

				setLoadingAfterWidget(false);
			}
		})();
	}, [dispatch, isFromWidget, location.search]);

	if (loadingAfterWidget) return <Loader wide />;
	
	return (
		<div className={styles['get-started']}>
			{renderTopSection}

			{renderForm}

			{renderRemarkSection}
		</div>
	);
};

export default UserInfoStep;
