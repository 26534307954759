import React, { FC } from 'react';
import classNames from 'classnames';

import PopupWithSidebarHeaderInterface from './popup-with-sidebar-header.interface';

import styles from './PopupWithSidebarHeader.module.scss';

const PopupWithSidebarHeader: FC<PopupWithSidebarHeaderInterface> = ({
	children,
	className,
	...rest
}) => {
	return (
		<header className={classNames(styles.header, className)} {...rest}>
			{children}
		</header>
	);
};

export default PopupWithSidebarHeader;
