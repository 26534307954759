import React, { ChangeEvent, FC, useEffect, useState } from 'react';

import SearchInputFilter from '../../../molecules/filters/search-input-filter/SearchInputFilter';

import { useDebounce } from '../../../../helpers';
import styles from './CompaniesSearch.module.scss';
import CompaniesSearchInterface from './companies-search.interface';

const CompaniesSearch: FC<CompaniesSearchInterface> = ({ onTextInput }) => {
	const [text, setText] = useState('');
	const payload = useDebounce(text);

	useEffect(() => {
		if (payload || payload === '') onTextInput(payload);
	}, [onTextInput, payload]);

	return (
		<SearchInputFilter
			label=""
			withClear={!!text}
			onClearClickHandler={() => setText('')}
			className={styles.search}
			inputSettings={{
				defaultValue: text || '',
				value: text || '',
				placeholder: 'Company name',
			}}
			onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
				setText(value);
			}}
		/>
	);
};

export default CompaniesSearch;
