import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import ReactHtmlParser from 'react-html-parser';
import { emailTagValues } from '../../../../helpers/custom/editors';
import TemplateValueTag from '../../../atoms/ui/template-value-tag';
import TypographyText from '../../../atoms/ui/typography-text';
import TextEditorWithTextInsert from '../../form-elemets/text-editor-with-html';
import EditableCompanyTemplateFormInterface from './editable-company-template-form.interface';

import styles from './EditableCompanyTemplateForm.module.scss';
import ResizeDeviceContext from '../../../../contexts/resize-device-context';

const EditableCompanyTemplateForm: FC<EditableCompanyTemplateFormInterface> = ({
	template,
	disabled,
	cancelLabelState,
	registerFormItem,
	formErrors,
}) => {
	const [templateSubjectValue, setTemplateSubjectValue] = useState(template?.subject);
	const [templateBodyValue, setTemplateBodyValue] = useState(template?.body);

	const [subjectTagToInsert, setSubjectTagToInsert] = useState('');
	const [bodyTagToInsert, setBodyTagToInsert] = useState('');

	const [focusedComponent, setFocusedComponent] = useState<'subject' | 'body'>();

	const device = useContext(ResizeDeviceContext);

	const sendTagToInsert = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			//@ts-ignore
			const tagValue = event.target.innerText;

			if (focusedComponent === 'subject') setSubjectTagToInsert(tagValue);
			else setBodyTagToInsert(tagValue);
		},
		[focusedComponent],
	);

	const renderTemplateConfiguration = useMemo(() => {
		return (
			<>
				<div className={styles['subject-editor']}>
					<div className={styles.label}>Subject</div>

					<TextEditorWithTextInsert
						toolbarHidden
						displayAsInput
						valueAsPlainText
						inputWidth={device !== 'desktop' ? '90%' : 'calc(50% - 32px)'}
						getValueHandler={setTemplateSubjectValue}
						defaultValue={templateSubjectValue}
						tagToInsert={subjectTagToInsert}
						afterTagInserted={() => setSubjectTagToInsert('')}
						onFocusHandler={() => setFocusedComponent('subject')}
						registerFormItem={registerFormItem}
						formItemName="subject"
						isRequired
						formErrors={formErrors?.subject}
					/>
				</div>

				<div className={styles['body-editor']}>
					<TextEditorWithTextInsert
						getValueHandler={setTemplateBodyValue}
						defaultValue={templateBodyValue}
						tagToInsert={bodyTagToInsert}
						afterTagInserted={() => setBodyTagToInsert('')}
						onFocusHandler={() => setFocusedComponent('body')}
						registerFormItem={registerFormItem}
						formItemName="body"
						isRequired
						formErrors={formErrors?.body}
					/>
				</div>

				<div className={styles.placeholders}>
					<TypographyText className={styles.description}>
						The following placeholders are at your disposal for use in this template.
						Placeholders will be automatically populated at the time the email is sent.
						To add, position the cursor where you would like it and click the desired
						placeholder below.
					</TypographyText>

					<div className={styles['template-values']}>
						{emailTagValues &&
							emailTagValues.map((tagValue) => {
								return (
									<TemplateValueTag
										key={tagValue}
										className={styles['value-item']}
										onClickHandler={(e) => sendTagToInsert(e)}
									>
										{tagValue}
									</TemplateValueTag>
								);
							})}
					</div>

					<div className={styles['calendar-link']}>
						<TypographyText className={styles['link-title']}>
							How to use calendar links
						</TypographyText>

						<TypographyText className={styles['link-description']}>
							Calendar links are available for use in templates. To add, highlight
							text and click on the calendar icon in the editor toolbar. Your personal
							calendar link can be set and edited in MY PROFILE.
						</TypographyText>
					</div>
				</div>
			</>
		);
	}, [
		bodyTagToInsert,
		device,
		formErrors?.body,
		formErrors?.subject,
		registerFormItem,
		sendTagToInsert,
		subjectTagToInsert,
		templateBodyValue,
		templateSubjectValue,
	]);

	useEffect(() => {
		if (cancelLabelState) {
			setTemplateSubjectValue(template?.subject);
			setTemplateBodyValue(template?.body);
		}
	}, [cancelLabelState, template]);

	useEffect(() => {
		setSubjectTagToInsert('');
		setBodyTagToInsert('');
	}, [disabled]);

	const styleTags = useCallback((htmlStr: string, tag: string): string => {
		const tagRgx = new RegExp(`\\[${tag}\\]`, 'g');

		return htmlStr.replace(tagRgx, (match, idx) => {
			const hasTagsBefore = htmlStr.substring(idx - 'href="'.length, idx) === 'href="';

			if (!hasTagsBefore) {
				return `<span class="tag">${match}</span>`;
			}

			return match;
		});
	}, []);

	const getStyledParsedText = useCallback(
		(strValue: string) => {
			emailTagValues.forEach((tag) => {
				strValue = styleTags(strValue, tag);
			});

			return ReactHtmlParser(strValue);
		},
		[styleTags],
	);

	return (
		<div className={styles.templates}>
			{disabled ? (
				<>
					<TypographyText className={styles.subject}>
						<span className={styles.title}>Subject: </span>
						{templateSubjectValue ? getStyledParsedText(templateSubjectValue) : null}
					</TypographyText>

					<TypographyText className={styles.body}>
						{templateBodyValue ? getStyledParsedText(templateBodyValue) : null}
					</TypographyText>
				</>
			) : (
				renderTemplateConfiguration
			)}
		</div>
	);
};

export default React.memo(EditableCompanyTemplateForm);
