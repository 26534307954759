import React, { FC, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import SelectWithSectionsInterface from './select-with-sections.interface';
import ClickAwayListener from '../../../../helpers/hocs/click-away-listener/ClickAwayListener';

import styles from './SelectWithSections.module.scss';
import { StatusOptionType } from '../../../../helpers/constants';

const SelectWithSections: FC<SelectWithSectionsInterface> = ({
	id,
	label,
	className,
	optionsBySections,
	onOptionPickHandler,
}) => {
	const [value, setValue] = useState('');
	const [openStatus, setOpenStatus] = useState(false);

	const onOptionClickHandler = useCallback(
		(option: StatusOptionType) => {
			onOptionPickHandler(option.status);
			setValue(option.label);
			setOpenStatus(false);
		},
		[onOptionPickHandler],
	);

	const renderOptions = useMemo(() => {
		return (
			<>
				{optionsBySections.map((item, idx) => {
					return (
						<div key={idx} className={styles.section}>
							{item.section.map((option: StatusOptionType) => {
								return (
									<div
										key={option.status}
										className={styles.option}
										onClick={() => onOptionClickHandler(option)}
									>
										{option.label}
									</div>
								);
							})}
						</div>
					);
				})}
			</>
		);
	}, [onOptionClickHandler, optionsBySections]);

	const onTriggerClick = useCallback(() => {
		setOpenStatus((previousState) => !previousState);
	}, []);

	return (
		<ClickAwayListener onClickAway={setOpenStatus.bind(null, false)}>
			<div className={classNames(styles['select-with-sections'], className)}>
				<input id={id} name={id} value={value} hidden />

				<div className={styles.trigger} onClick={onTriggerClick}>
					<div className={classNames(styles.value, value ? '' : styles.empty)}>
						<div className={styles.label}>{label}</div>
						{value}
					</div>

					<span
						className={classNames(
							styles.icon,
							openStatus ? styles.opened : styles.closed,
						)}
					/>
				</div>

				<div
					className={classNames(
						styles.dropdown,
						openStatus ? styles.show : '',
						value ? '' : styles['empty-value'],
					)}
				>
					<div className={styles.content}>{renderOptions}</div>
				</div>
			</div>
		</ClickAwayListener>
	);
};

export default React.memo(SelectWithSections);
