import React, { FC, useState } from 'react';

import DatePickerFieldInterface from './date-picker-field.interface';
import DatePicker from 'react-datepicker';

import styles from './DatePickerField.module.scss';

const DatePickerField: FC<DatePickerFieldInterface> = React.forwardRef(
	({ inputSettings, required, ...rest }, ref) => {
		const [startDate, setStartDate] = useState<Date | undefined>(
			required ? new Date() : undefined,
		);

		return (
			<>
				<DatePicker
					{...rest}
					ref={undefined}
					dateFormat="MM/dd/yyyy"
					selected={startDate}
					onChange={(date: Date) => setStartDate(date)}
					className={styles['date-picker']}
				/>

				<input
					hidden
					{...{ ref, ...inputSettings }}
					type="text"
					value={startDate ? startDate?.toISOString() : ''}
					onChange={() => {}}
				/>
			</>
		);
	},
);

export default React.memo(DatePickerField);
