import { Dispatch } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import jobsTemplatesApi from '../../api/job-templates.api';

export interface DepartmentsAndLevelsData {
	departments: DepartmentsAndLevelsType[];
	levels: DepartmentsAndLevelsType[];
}

export type DepartmentsAndLevelsType = {
	value: number;
	key?: string;
	label: string;
};

export interface JobsTemplatesSliceInterface {
	items: any[];
	templateId?: string | number | null;
	loading: boolean;
	departmentsAndLevels?: DepartmentsAndLevelsData;
}

const initialState: JobsTemplatesSliceInterface = {
	items: [],
	loading: false,
};

export interface JobTemplateInterface {
	id?: number | string;
	title: string;
	summary: string;
	mustHaveSkills: string;
	niceToHaveSkills: string;
	subtitle: string;
	description: string;
	color: string;
	uniqueName: string;
	department?: number;
	departmentLabel?: string;
	departmentKey?: string;
	level?: number;
}

export const jobsTemplatesSlice = createSlice({
	name: 'jobs templates',
	initialState,
	reducers: {
		loading(state) {
			state.loading = true;
		},
		getJobsTemplatesListSuccess(state, { payload }: PayloadAction<JobTemplateInterface[]>) {
			state.items = payload;
			state.loading = false;
		},
		getJobsTemplatesListFailed(state, action: PayloadAction<string>) {
			state.loading = false;
			console.log(action);
		},
		clearJobsTemplatesListSuccess(state) {
			state.items = [];
		},
		setDepartmentDataSuccess: (state, { payload }: PayloadAction<DepartmentsAndLevelsData>) => {
			state.departmentsAndLevels = payload;
		},
	},
});

export const getJobsTemplatesList =
	(params: { excludeCannabis?: boolean }) => async (dispatch: Dispatch<any>) => {
		dispatch(loading());

		try {
			const response = await jobsTemplatesApi.getJobsTemplatesList(params);
			dispatch(getJobsTemplatesListSuccess(response));
		} catch (err) {
			dispatch(getJobsTemplatesListFailed(err.toString()));
		}
	};

export const getJobTemplateById = (id: string | number) => async () => {
	try {
		return await jobsTemplatesApi.getJobTemplateById(id);
	} catch (err) {
		return err;
	}
};

export const getDepartmentData = () => async (dispatch: Dispatch<any>) => {
	try {
		const result = await jobsTemplatesApi.getDepartmentsAndLevels()

		dispatch(setDepartmentDataSuccess(result));
	} catch (error) {
		return error;
	}
};

export const {
	getJobsTemplatesListSuccess,
	getJobsTemplatesListFailed,
	clearJobsTemplatesListSuccess,
	setDepartmentDataSuccess,
	loading,
} = jobsTemplatesSlice.actions;
export default jobsTemplatesSlice.reducer;
