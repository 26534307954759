import React, { FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../../../store/reducers/root-reducer';

import EditCompanyBenefitsSectionInterface from './edit-company-benefits-section.interface';
import EditableViewForm from '../../editable-view-form';
import EditableTextEditor from '../../editable-text-editor';
import { useForm } from 'react-hook-form';
import useActiveCompany from '../../../../helpers/hooks/use-active-company/useActiveCompany';
import { CompanyInterface, editCompany } from '../../../../store/slices/companies.slice';

const EditCompanyBenefitsSection: FC<EditCompanyBenefitsSectionInterface> = () => {
	const { register, handleSubmit } = useForm();
	const dispatch = useDispatch();
	const [editModeState, setEditModeState] = useState<boolean>();

	const {
		companies: { activeCompanyId, items, loading },
	} = useSelector((state: RootStateType) => state);
	const activeCompany = useActiveCompany(activeCompanyId, items);

	const onSubmit = useCallback(
		(data: Partial<CompanyInterface>) => {
			setEditModeState(true);

			if (activeCompany) {
				dispatch<any>(editCompany(activeCompany.id, data)).then(() =>
					setEditModeState(false),
				);
			}
		},
		[activeCompany, dispatch],
	);

	return (
		<EditableViewForm
			{...{ editModeState, loading }}
			onSubmit={handleSubmit(onSubmit)}
			sectionTitle="Benefits"
		>
			<EditableTextEditor
				text={activeCompany?.benefits}
				textareaSettings={{ name: 'benefits', ref: register() }}
			/>
		</EditableViewForm>
	);
};

export default EditCompanyBenefitsSection;
