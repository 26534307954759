import React, { FC } from 'react';

import Select from '../form/select/Select';
import StatusSelectInterface from './status-select.interface';

const StatusSelect: FC<StatusSelectInterface> = ({ emptyOption, minWidth = 125, ...rest }) => {
	return <Select emptyOption={emptyOption} minWidth={minWidth} {...rest} />;
};

export default React.memo(StatusSelect);
