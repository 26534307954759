import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { setLoginRedirectionState } from '../../../../store/reducers/login-redirection-path-reducer';
import LoginRedirectionContext from '../../../../contexts/login-redirection-context';
import Cookies from 'js-cookie';
import { APP_URLS } from '../../../routes/routes';
import useUser from '../../use-user/useUser';
import { StorageItemKeys } from '../../../constants';

const useLoginRedirection = () => {
	const location = useLocation();

	const { authorized } = useUser();

	const { dispatch: dispatchCancelRedirection } = useContext(LoginRedirectionContext);

	useEffect(() => {
		const onlyValidUrls =
			location?.pathname !== APP_URLS.login &&
			location?.pathname !== '/' &&
			!location?.pathname?.startsWith('/4'); // for errors pages

		if (onlyValidUrls) {
			const authToken = Cookies.get(StorageItemKeys.authToken);

			if (!authorized || (authorized && !authToken))
				dispatchCancelRedirection(
					setLoginRedirectionState(location.pathname, location.search),
				);
		}
	}, [authorized, dispatchCancelRedirection, location.pathname, location.search]);
};

export default useLoginRedirection;
