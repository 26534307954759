import React, { FC } from 'react';
import classNames from 'classnames';

import ContainerInterface from './container.interface';

import styles from './Container.module.scss';
import { getMinMaxWidthStyles } from '../../../helpers';

const Container: FC<ContainerInterface> = ({
	children,
	className,
	minWidth,
	maxWidth,
	flex,
	fluid,
}) => {
	const inlineStyles = getMinMaxWidthStyles(minWidth, maxWidth);

	return (
		<div
			style={inlineStyles}
			className={classNames(
				styles.container,
				fluid && styles.fluid,
				flex && styles.flex,
				className,
			)}
		>
			{children}
		</div>
	);
};

export default Container;
