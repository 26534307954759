import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styles from './CompanyKeywordsScreen.module.scss';
import SparkeSpeech from '../../../molecules/custom/sparke-speech/SparkeSpeech';
import classNames from 'classnames';
import enginLogo from '../../../../assets/icons/powered-by-engin-logo-white.svg';
import CompanyKeywordsScreenInterface from './CompanyKeywordsScreenInterface';
import useActiveCompany from '../../../../helpers/hooks/use-active-company/useActiveCompany';
import useCompanies from '../../../../helpers/hooks/use-companies/useCompanies';
import SpeechBubble from '../../../atoms/speech-bubble/SpeechBubble';
import Button from '../../../atoms/buttons/button/Button';
import Close from '../../../atoms/close';
import { createPortal } from 'react-dom';
import ResizeDeviceContext from '../../../../contexts/resize-device-context';

const CompanyKeywordsScreen: FC<CompanyKeywordsScreenInterface> = ({
	show,
	onClose,
	className,
	regenerateJobDescription,
}) => {
	const [disabledBtn, setDisabledBtn] = useState(false);
	const [keywordsValue, setKeywordsValue] = useState('');
	const keywordsRef = useRef<HTMLTextAreaElement | null>(null);
	const device = useContext(ResizeDeviceContext);

	const { activeCompanyId, companies } = useCompanies();
	const activeCompany = useActiveCompany(activeCompanyId, companies);

	const documentElement = useRef(document.getElementById('root'));

	const speechBubbleText = useMemo(() => {
		return (
			<>
				<div className={styles['speech-text']}>
					I'm Spark-e - help me to craft a compelling job description. Please describe{' '}
					{activeCompany?.name} in a few keywords that explain why it is a great company
					to work for. Feel free to use your own terms or consider these for inspiration.
				</div>

				<div className={styles['speech-text']}>
					<strong>Keywords:</strong> knowledgeable associates, friendly staff, team
					expansion, innovative approach, mission, community service, sustainable
					cultivation, premium dispensary, advanced manufacturing, high-tech, career
					development, growth opportunities, wellness program, cultural experience,
					compassionate care, high-quality products...
				</div>
			</>
		);
	}, [activeCompany?.name]);

	const onKeywordsInput = useCallback(() => {
		if (keywordsRef.current) {
			const initialHeight = device === 'desktop' ? 47 : 36;

			const valueToIncrement = keywordsRef.current.scrollHeight === 0 ? initialHeight : 10;

			keywordsRef.current.style.height = '0px';
			keywordsRef.current.style.height =
				keywordsRef.current.scrollHeight + valueToIncrement + 'px';
		}
	}, [device]);

	const companyForm = useMemo(
		() => (
			<>
				<div className={styles.text}>
					<span className={styles.substring}>
						The best words that describe the hiring company are:{' '}
					</span>

					<textarea
						ref={keywordsRef}
						name="companyKeywords"
						className={styles.textarea}
						onChange={(e) => setKeywordsValue(e.target.value)}
					/>
				</div>
			</>
		),
		[],
	);

	const onGenerateClick = useCallback(() => {
		if (keywordsValue) {
			regenerateJobDescription(keywordsValue);
		}
	}, [keywordsValue, regenerateJobDescription]);

	useEffect(() => {
		const currentKeywordsRef = keywordsRef.current;

		if (currentKeywordsRef) {
			currentKeywordsRef.addEventListener('input', onKeywordsInput);
			onKeywordsInput();
		}

		return () => {
			if (currentKeywordsRef)
				currentKeywordsRef.removeEventListener('input', onKeywordsInput);
		};
	}, [onKeywordsInput]);

	useEffect(() => {
		if (!keywordsValue) setDisabledBtn(true);
		else setDisabledBtn(false);
	}, [keywordsValue]);

	return (
		documentElement.current &&
		createPortal(
			<div
				className={classNames(
					className,
					styles['company-form'],
					show ? styles.show : styles.hide,
				)}
			>
				<Close contrast className={styles.close} onClick={onClose} />

				<div className={styles.form}>
					<SparkeSpeech
						medium
						className={styles.sparke}
						contentClassName={styles['bubble-content']}
						content={speechBubbleText}
						maxWidth="700px"
					/>

					<SpeechBubble
						large
						rightSide
						dark
						showDelay={700}
						maxWidth="1054px"
						className={styles['company-bubble']}
						content={<div className={styles['company-info']}>{companyForm}</div>}
					/>

					<Button
						disabled={disabledBtn}
						className={classNames(styles.btn, disabledBtn ? styles.disabled : '')}
						small
						minWidth={225}
						maxWidth={225}
						onClick={onGenerateClick}
					>
						Generate it
					</Button>
				</div>

				<img className={styles.logo} src={enginLogo} />
			</div>,
			documentElement.current,
		)
	);
};

export default CompanyKeywordsScreen;
