import React, { FC } from 'react';
import classNames from 'classnames';

import { getMinMaxHeightStyles, getMinMaxWidthStyles } from '../../../../helpers';
import ButtonInterface from './button.interface';

import styles from './Button.module.scss';

const Button: FC<ButtonInterface> = React.forwardRef(
	(
		{
			children,
			className,
			minWidth,
			maxWidth,
			minHeight,
			maxHeight,
			reversedStyles,
			white,
			small,
			disabled,
			...rest
		},
		ref,
	) => {
		const inlineStyles = {
			...getMinMaxWidthStyles(minWidth, maxWidth),
			...getMinMaxHeightStyles(minHeight, maxHeight),
		};

		return (
			<button
				style={inlineStyles}
				className={classNames(
					styles.button,
					!white && reversedStyles && styles.reversed,
					white && styles.white,
					small && styles.small,
					disabled && classNames(styles.disabled, 'disabled'),
					className,
				)}
				ref={ref}
				disabled={disabled}
				{...rest}
			>
				{children}
			</button>
		);
	},
);

export default Button;
