import axios from 'axios';
import Cookies from 'js-cookie';

import { setError } from '../../store/slices/error-redirection.slice';
import { logoutUser } from '../../store/slices/user.slice';
import {
	COMMUNICATION_TEMPLATES,
	COMPANIES,
	COST_PER_APPLICANTS,
	JOB_DESCRIPTION,
} from '../../api/api.path';
import { StorageItemKeys } from '../constants';

export type ErrorTargetType = {
	children: [];
	constraints: { [key: string]: string };
	property: string;
	target: object;
	value: any;
};

export type ExternalErrorType = {
	errors?: ErrorTargetType[];
	message: string;
	name: string;
	stack: string;
};

export type ExternalErrorItemType = {
	messages: string[];
	name: string;
};

const setupAxios = () => {
	setBaseUrl();
	setInterceptors();
};

const setBaseUrl = () => {
	const env = process.env.REACT_APP_ENV;
	const port = process.env.REACT_APP_LOCAL_DEVELOPMENT_PORT || 4000;

	const url = {
		localDevelopment: `http://localhost:${port}/api/v1/`,
		development: 'https://dev-api.engin.co/api/v1/',
		staging: 'https://staging-api.engin.co/api/v1/',
		sandbox: 'https://sandbox-api.engin.co/api/v1/',
		production: 'https://api.engin.co/api/v1/',
	};

	// @ts-ignore
	axios.defaults.baseURL = (env && url[env]) || url.production;
};

const setInterceptors = () => {
	axios.interceptors.response.use(
		(response) => {
			const { data } = response;

			return data;
		},
		(error) => {
			if (!error?.response.config.avoidRedirectionCheck && error?.response?.status) {
				const status = error?.response?.status;

				if (
					status === 404 &&
					(error?.response.config.url.includes(COMMUNICATION_TEMPLATES) ||
						error?.response.config.url.includes(COST_PER_APPLICANTS) ||
						error?.response.config.url.includes(JOB_DESCRIPTION))
				)
					return;

				if (status === 401) {
					// @ts-ignore
					process.dispatch(setError(status));
					// @ts-ignore
					process.dispatch(logoutUser());
				} else {
					if (error?.response.config.url !== COMPANIES) {
						// @ts-ignore
						process.dispatch(setError(status));
					}
				}
			}

			if (error?.response?.status === 402) {
				// @ts-ignore
				process.dispatch(setError(error?.response?.status));
				return Promise.reject(error?.response);
			} else if (error?.response?.data) {
				return Promise.reject(error?.response?.data);
			}
		},
	);

	axios.interceptors.request.use(
		(config) => {
			const token = Cookies.get(StorageItemKeys.authToken);

			if (token) {
				config.headers.Authorization = `Bearer ${token}`;
			}

			return config;
		},
		(error) => {
			return Promise.reject(error);
		},
	);
};

export default setupAxios;
