import React, { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import ButtonLink from '../../atoms/buttons/button-link';
import GrabLinkInterface from './grab-link.interface';

import styles from './GrabLink.module.scss';

const GrabLink: FC<GrabLinkInterface> = ({
	children,
	className,
	text = 'Link copied',
	onClick,
	link,
	copyTextOnTop,
	...rest
}) => {
	const grabLinkRef = useRef<HTMLInputElement>(null);
	const [textState, setTextState] = useState(false);

	useEffect(() => {
		if (textState) {
			setTimeout(() => setTextState(false), 2000);
		}
	}, [textState]);

	const copyLink = () => {
		if (grabLinkRef.current && document && document.execCommand) {
			grabLinkRef.current.select();
			document.execCommand('copy');
			setTextState(true);
		}
	};

	return (
		<div className={classNames(styles.wrap, textState && styles['has-text'])}>
			<ButtonLink
				className={classNames(styles.btn, className)}
				onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
					if (onClick) {
						onClick(event);
					}

					copyLink();
				}}
				{...rest}
			>
				{children}

				<input
					className={styles.input}
					tabIndex={-1}
					type="text"
					defaultValue={link}
					ref={grabLinkRef}
				/>
			</ButtonLink>

			<span className={classNames(styles.text, copyTextOnTop ? styles.top : '')}>{text}</span>
		</div>
	);
};

export default React.memo(GrabLink);
