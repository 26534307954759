import classNames from 'classnames';
import React, { FC } from 'react';
import TableRowInterface from './table-row.interface';

import styles from './TableRow.module.scss';

const TableRow: FC<TableRowInterface> = ({ children, className }) => {
	return <div className={classNames(styles['table-row'], className)}>{children}</div>;
};

export default TableRow;
