import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { logoutUser } from '../../../../store/slices/user.slice';
import LogoutButtonInterface from './logout-button.interface';
import Button from '../../../atoms/buttons/button/Button';
import { StorageItemKeys } from '../../../../helpers/constants';

const LogoutButton: FC<LogoutButtonInterface> = ({ className, ...rest }) => {
	const dispatch = useDispatch();

	const logoutHandle = useCallback(() => {
		sessionStorage.setItem(StorageItemKeys.redirection, 'true');
		dispatch(logoutUser());
	}, [dispatch]);

	return (
		<Button
			reversedStyles
			small
			minWidth={159}
			className={className}
			onClick={logoutHandle}
			{...rest}
		>
			Sign out
		</Button>
	);
};

export default LogoutButton;
