import React, { FC } from 'react';
import classNames from 'classnames';

import InfoWarningInterface from './info-warning.interface';

import styles from './InfoWarning.module.scss';

const InfoWarning: FC<InfoWarningInterface> = ({ text, hidden = true, className, ...rest }) => {
	return (
		<div className={classNames(styles.wrap, hidden && styles.hidden, className)} {...rest}>
			<div className={styles.text}>{text}</div>
		</div>
	);
};

export default InfoWarning;
