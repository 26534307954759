import React, { FC } from 'react';
import classNames from 'classnames';

import ErrorInterface from './error.interface';

import styles from './Error.module.scss';

const Error: FC<ErrorInterface> = ({ children, className, ...rest }) => {
	return children ? (
		<span className={classNames('error', styles.error, className)} {...rest}>
			{children}
		</span>
	) : null;
};

export default Error;
