import React, { FC, useMemo } from 'react';

import ConditionContentWithEmptyStateInterface from './condition-content-with-empty-state.interface';

const ConditionContentWithEmptyState: FC<ConditionContentWithEmptyStateInterface> = ({
	settings,
	className,
	...rest
}) => {
	const content = useMemo(
		() =>
			settings
				.filter(({ condition }) => condition)
				.map((item, index) => {
					return (
						<div key={index} {...{ className, ...rest }}>
							{item.content}
						</div>
					);
				}),
		[className, rest, settings],
	);

	return <>{content}</>;
};

export default ConditionContentWithEmptyState;
