import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import LinkButtonInterface from './link-button.interface';
import { getMinMaxHeightStyles, getMinMaxWidthStyles } from '../../../../helpers';

import styles from './LinkButton.module.scss';

const LinkButton: FC<LinkButtonInterface> = ({
	children,
	className,
	reversedStyles,
	small,
	minWidth,
	maxWidth,
	minHeight,
	maxHeight,
	...rest
}) => {
	const inlineStyles = {
		...getMinMaxWidthStyles(minWidth, maxWidth),
		...getMinMaxHeightStyles(minHeight, maxHeight),
	};

	return (
		<Link
			style={inlineStyles}
			className={classNames(
				styles.button,
				reversedStyles && styles.reversed,
				small && styles.small,
				className,
			)}
			{...rest}
		>
			{children}
		</Link>
	);
};

export default LinkButton;
