import React, { FC, useState } from 'react';
import classNames from 'classnames';
import ShowMoreDropdownInterface from './show-more-dropdown.interface';
import Back from '../../atoms/back';

import styles from './ShowMoreDropdown.module.scss';

const ShowMoreDropdown: FC<ShowMoreDropdownInterface> = ({
	trigger,
	dropdownContent: content,
	className,
	contentClassName,
	...rest
}) => {
	const [openState, setOpenState] = useState(false);

	return (
		<div className={classNames(styles['show-more-dropdown'], className)} {...rest}>
			<div
				className={classNames(styles.trigger, openState ? styles.opened : '')}
				onClick={() => setOpenState((prevState) => !prevState)}
			>
				{trigger}

				<Back className={styles.chevron} />
			</div>

			<div className={classNames(contentClassName, styles.content, openState ? styles.opened : '')}>
				{content}
			</div>
		</div>
	);
};

export default React.memo(ShowMoreDropdown);
