import React, { FC, useMemo, useRef } from 'react';
import classNames from 'classnames';

import styles from './SparkeSpeech.module.scss';
import SparkeSpeechInterface from './sparke-speech.interface';
import SpeechBubble from '../../../atoms/speech-bubble/SpeechBubble';
import { createPortal } from 'react-dom';

const SparkeSpeech: FC<SparkeSpeechInterface> = ({
	className,
	content,
	contentClassName,
	maxWidth,
	minWidth,
	small,
	medium,
	withInfoIcon,
	asPortal,
}) => {
	const documentElement = useRef(document.getElementById('root'));

	const bubbleContent = useMemo(() => {
		return <div className={classNames(styles.content, contentClassName)}>{content}</div>;
	}, [content, contentClassName]);

	const speechBox = useMemo(() => {
		return (
			<div
				className={classNames(
					styles['sparke-speech'],
					className,
					small ? styles.small : '',
					medium ? styles.medium : '',
				)}
			>
				<SpeechBubble
					className={styles.bubble}
					{...{ maxWidth, minWidth, small, medium, withInfoIcon }}
					content={bubbleContent}
				/>
			</div>
		);
	}, [bubbleContent, className, maxWidth, medium, minWidth, small, withInfoIcon]);

	if (asPortal && documentElement.current)
		return createPortal(speechBox, documentElement.current);

	return speechBox;
};

export default React.memo(SparkeSpeech);
