import React, { FC } from 'react';
import classNames from 'classnames';

import DropdownContentInterface from './dropdown-content.interface';

import styles from './DropdownContent.module.scss';

const DropdownContent: FC<DropdownContentInterface> = React.forwardRef(
	({ children, open, className }, ref) => {
		return (
			<div
				className={classNames(
					styles['dropdown-content'],
					className,
					open ? styles.open : '',
				)}
				ref={ref}
			>
				{children}
			</div>
		);
	},
);

export default DropdownContent;
