import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import Loader from '../../components/molecules/loader';
import AddCreditsForm from '../../components/organisms/custom/add-credits-form';
import NestedPopupHeader from '../../components/organisms/popup-headers/nested-popup-header/NestedPopupHeader';
import { backGA4Event, cancelGA4Event } from '../../helpers/google-analytics-4/events';
import { getCompanyDataForGA } from '../../helpers/google-analytics-4/helpers';
import { GAContentGroup, GAContentId } from '../../helpers/google-analytics-4/index.constants';
import useActiveCompany from '../../helpers/hooks/use-active-company/useActiveCompany';
import useCompanies from '../../helpers/hooks/use-companies/useCompanies';
import useProducts from '../../helpers/hooks/use-products/useProducts';
import useUser from '../../helpers/hooks/use-user/useUser';
import { getProductsList } from '../../store/slices/products.slice';

import styles from './AddCredits.module.scss';
import { APP_URLS } from '../../helpers/routes/routes';
import usePlansAndProducts from '../../helpers/hooks/custom/use-plans-and-products';

const AddCredits: FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();

	const { activeCompanyId, activeCompanyCanBoost, creditsRefillLoading, companies } =
		useCompanies();
	const { info } = useUser();
	const { productItems, loading } = useProducts();

	const activeCompany = useActiveCompany(activeCompanyId, companies);

	const [productsError, setProductsError] = useState(false);
	const [showBackNavigation, setShowBackNavigation] = useState(
		// @ts-ignore
		location?.state?.fromBoostFlow,
	);

	usePlansAndProducts();

	const onClose = useCallback(
		(context?: string) => {
			const dataForGA = {
				companyData: getCompanyDataForGA(activeCompany),
				contentData: {
					id: GAContentId.addCredits,
					group: GAContentGroup.addCredits,
				},
			};

			if (context === 'close') cancelGA4Event(dataForGA);
			else backGA4Event(dataForGA);

			// @ts-ignore
			if (location?.state?.hasHistoryBackContext) return navigate(-1);
			return navigate(APP_URLS.jobs);
		},
		[activeCompany, location?.state, navigate],
	);

	const hideBackNavigation = useCallback(() => setShowBackNavigation(false), []);

	useEffect(() => {
		if (!productItems?.length && !productsError) {
			(async () => {
				try {
					await dispatch(getProductsList());
				} catch (error) {
					setProductsError(true);
				}
			})();
		}
	}, [dispatch, productItems, productsError]);

	useEffect(() => {
		if (!activeCompanyCanBoost) navigate(APP_URLS.jobs);

		return () => setProductsError(false);
	}, [activeCompanyCanBoost, navigate]);

	return (
		<div className={styles['add-credits']}>
			<>
				<NestedPopupHeader
					withoutLogo
					title={'Add credits'}
					className={styles.header}
					onBackClick={showBackNavigation && onClose}
					close={() => onClose('close')}
				/>

				{loading ? (
					<Loader thin minWidth={20} minHeight={20} className={styles.loader} />
				) : (
					<>
						{productsError ? (
							<div className={styles['error-message']}>
								Oops! Something went wrong. Please try again later.
							</div>
						) : (
							<AddCreditsForm
								className={styles.form}
								companyId={activeCompanyId}
								products={productItems}
								creditsRefillLoading={creditsRefillLoading}
								userEmail={info?.email}
								onSuccessHandler={hideBackNavigation}
							/>
						)}
					</>
				)}
			</>
		</div>
	);
};

export default React.memo(AddCredits);
