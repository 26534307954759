import React, { FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import {
	AuthorizationSliceInterface,
	validateCode,
} from '../../../../../store/slices/authorization.slice';
import TroubleSignInValidationCodeStepInterface from './trouble-sign-in-validation-code-step.interface';
import InputWithFloatLabel from '../../../form-elemets/input-with-float-label/InputWithFloatLabel';
import Button from '../../../../atoms/buttons/button/Button';
import troubleFormStyles from '../../login-trouble/LoginTrouble.module.scss';
import SlideFormHeading from '../../../../molecules/custom/slide-form-heading';

import styles from './TroubleSignInValidationCodeStep.module.scss';

const TroubleSignInValidationCodeStep: FC<TroubleSignInValidationCodeStepInterface> = ({
	title,
	description,
	sliderRef,
	activeSliderNumberState,
	nextHandler,
	setNextHandler,
}) => {
	const dispatch = useDispatch();
	const { register, errors, handleSubmit } = useForm();
	const codeRef = useRef<HTMLInputElement | null>(null);
	const [validationErrorState, setValidationErrorState] = useState<boolean>();

	useEffect(() => {
		if (activeSliderNumberState === 1 && codeRef.current) {
			codeRef.current.focus();
		}
	}, [activeSliderNumberState]);

	const { error, loading } = useSelector(
		({ authorization }: { authorization: AuthorizationSliceInterface }) => authorization,
	);

	const onFormSubmitHandler = (data: any) => {
		dispatch<any>(validateCode(data))
			.then(() => {
				setValidationErrorState(false);

				if (sliderRef) {
					// @ts-ignore
					sliderRef.current.slickNext();
				}
			})
			.catch(() => {
				setValidationErrorState(true);
			});
	};

	const prevStep = () => {
		if (sliderRef) {
			// @ts-ignore
			sliderRef.current.slickPrev();
		}
	};

	const formHandleSubmit = handleSubmit(onFormSubmitHandler);

	useEffect(() => {
		if (
			activeSliderNumberState === 1 &&
			setNextHandler &&
			nextHandler === undefined
		) {
			setNextHandler(() => formHandleSubmit);
		}

		// eslint-disable-next-line
	}, [activeSliderNumberState]);

	return (
		<form
			className={classNames(troubleFormStyles.step, styles.form)}
			onSubmit={formHandleSubmit}
		>
			<SlideFormHeading className={styles.heading} {...{ title, description }} />

			<InputWithFloatLabel
				className={troubleFormStyles.input}
				loading={loading}
				ref={(event) => {
					register(event, {
						required: {
							value: true,
							message: 'This field is required',
						},
					});

					codeRef.current = event;
				}}
				inputSettings={{
					type: 'code',
					name: 'code',
				}}
				error={
					errors && errors.code
						? errors.code.message
						: validationErrorState
						? 'The code is invalid'
						: error
				}
				id="code"
				label="Validation code"
			/>

			<div className={troubleFormStyles.actions}>
				<Button
					small
					reversedStyles
					type="button"
					className={styles.btn}
					minWidth={100}
					onClick={prevStep}
				>
					Back
				</Button>

				<Button type="submit" small minWidth={100} maxWidth={100}>
					Next
				</Button>
			</div>
		</form>
	);
};

export default TroubleSignInValidationCodeStep;
