import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { setHiringWithWurkCandidateId } from '../../store/slices/candidates.slice';
import {
	getActiveCompanyPrepaidJobsBalance,
	getCompanyById,
	setActiveCompany,
} from '../../store/slices/companies.slice';
import { activateJob, confirmJobPayment, getJob, getJobOnly } from '../../store/slices/job.slice';
import Candidates from '../../components/organisms/custom/candidates';
import Tabs from '../../components/molecules/ui/tabs/Tabs';
import JobDetail from '../../components/organisms/custom/job-detail';

import styles from './JobInfo.module.scss';
import JobSettings from '../../components/organisms/custom/job-settings';
import JobAnalytics from '../../components/organisms/custom/job-analytics';
import useJob from '../../helpers/hooks/use-job/useJob';
import useCompanies from '../../helpers/hooks/use-companies/useCompanies';
import Loader from '../../components/molecules/loader';
import { JobStatusType, checkJobIsWithPaymentByCompany } from '../../helpers/custom/common';
import { PaymentResultStatus, QueryParametersKey } from '../../helpers/constants';
import useActiveCompany from '../../helpers/hooks/use-active-company/useActiveCompany';
import {
	paymentCancelGA4Event,
	paymentSuccessGA4Event,
} from '../../helpers/google-analytics-4/events';
import { GAContentGroup, GAContentId } from '../../helpers/google-analytics-4/index.constants';
import { getCompanyDataForGA, getJobDataForGA } from '../../helpers/google-analytics-4/helpers';

const JobInfo: FC = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const location = useLocation();

	const { loading, info, statistic } = useJob();
	const { activeCompanyId, activeCompanyCanSeeAnalytics, companies } = useCompanies();
	const activeCompany = useActiveCompany(activeCompanyId, companies);

	const [jobActivationInProcess, setJobActivationInProcess] = useState(false);

	const activeTab = useMemo(
		() => new URLSearchParams(location.search).get(QueryParametersKey.tab),
		[location.search],
	);

	const showAnalyticsTab = useMemo(() => {
		return (
			(!info?.hasExternalApply && activeCompanyCanSeeAnalytics) || activeTab === 'analytics'
		);
	}, [activeCompanyCanSeeAnalytics, activeTab, info]);

	const showLoader = useMemo(() => {
		return (!Object.keys(info).length && !Object.keys(statistic).length) || loading;
	}, [info, statistic, loading]);

	const defaultActiveTab = useMemo(() => {
		if (activeTab) return activeTab;
		if (info?.hasExternalApply) return 'details';

		return 'candidates';
	}, [activeTab, info?.hasExternalApply]);

	const updateJob = useCallback(() => {
		if (id)
			dispatch(
				getJob(id, {
					pageSize: 15,
					pageNumber: 1,
				}),
			);
	}, [dispatch, id]);

	const tabs = useMemo(() => {
		return (
			<Tabs
				tabsWrapClassName={styles['tabs-wrap']}
				className={styles.tabs}
				defaultActiveTab={defaultActiveTab}
				tabs={[
					...(!info?.hasExternalApply
						? [
								{
									key: 'candidates',
									text: 'Candidates',
									component: (
										<Candidates
											jobId={id}
											pageSize={15}
											notShowLoader={jobActivationInProcess}
										/>
									),
								},
						  ]
						: []),
					...(showAnalyticsTab
						? [
								{
									key: 'analytics',
									text: 'Analytics',
									component: <JobAnalytics />,
								},
						  ]
						: []),
					{
						key: 'details',
						text: 'Details',
						component: <JobDetail />,
					},
					{
						key: 'settings',
						text: 'Settings',
						component: <JobSettings />,
					},
				]}
			/>
		);
	}, [defaultActiveTab, info?.hasExternalApply, id, jobActivationInProcess, showAnalyticsTab]);

	useEffect(() => {
		(async () => {
			const searchParams = new URLSearchParams(location.search);
			const paymentResult = searchParams.get(QueryParametersKey.paymentResult);
			const paymentSessionId = searchParams.get(QueryParametersKey.sessionId);
			const fromPostAJob = searchParams.get(QueryParametersKey.postAJob);

			const gaEventData = {
				jobData: getJobDataForGA(info),
				companyData: getCompanyDataForGA(activeCompany),
				contentData: {
					id: fromPostAJob ? GAContentId.postAJob : GAContentId.job,
					group: fromPostAJob ? GAContentGroup.postAJob : GAContentGroup.job,
				},
			};

			if (
				activeCompany &&
				id &&
				paymentResult &&
				info &&
				info?.status === JobStatusType.inactive
			) {
				if (Number(paymentResult) === PaymentResultStatus.success && paymentSessionId) {
					setJobActivationInProcess(true);

					paymentSuccessGA4Event(gaEventData);

					await dispatch(
						confirmJobPayment({
							companyId: `${activeCompanyId}`,
							sessionId: paymentSessionId,
						}),
					);

					await dispatch(activateJob(id));
					await dispatch(getJobOnly(id));

					setJobActivationInProcess(false);

					return;
				}

				if (Number(paymentResult) === PaymentResultStatus.cancel) {
					paymentCancelGA4Event(gaEventData);
				}
			}
		})();
	}, [activeCompany, activeCompanyId, dispatch, id, info, info?.status, location.search]);

	useEffect(() => {
		if (activeCompanyId && checkJobIsWithPaymentByCompany(activeCompany)) {
			dispatch(getActiveCompanyPrepaidJobsBalance(activeCompanyId));
		}
	}, [activeCompany, activeCompanyId, dispatch, info?.status]);

	useEffect(() => {
		if (id && id !== info?.id?.toString()) updateJob();
	}, [id, info?.id, updateJob]);

	useEffect(() => {
		(async () => {
			const companyId = info.facility?.company?.id;
			
			if (companyId) {
				const companyInfo = await dispatch(getCompanyById(companyId));

				await dispatch(setActiveCompany(companyInfo));
			}
		})();
	}, [dispatch, info.facility?.company]);

	useEffect(() => {
		window.scrollTo(0, 0);

		return () => {
			dispatch(setHiringWithWurkCandidateId(undefined));
		};
	}, [dispatch]);

	if (showLoader && !jobActivationInProcess) return <Loader wide />;

	return (
		<div className={styles.job}>
			{jobActivationInProcess ? <Loader wide withOverlay className={styles.loader} /> : null}

			{tabs}
		</div>
	);
};

export default React.memo(JobInfo);
