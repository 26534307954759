import React, { FC, useCallback, useMemo, useState } from 'react';

import styles from './BoostsHistoryWithPagination.module.scss';
import BoostsHistoryWithPaginationInterface from './boosts-history-with-pagination.interface';
import {
	BoostHistoryRecord,
	getJobBoostsHistoryForPage,
} from '../../../../store/slices/companies.slice';
import Table from '../../table/Table';
import TableHead from '../../../molecules/table/table-head/TableHead';
import TableCell from '../../../atoms/table/table-cell/TableCell';
import TableBody from '../../../molecules/table/table-body/TableBody';
import TableRow from '../../../molecules/table/table-row/TableRow';
import { BOOST_PAGINATION_PAGE_SIZE } from '../../../../helpers/constants';
import Pagination from '../../../molecules/pagination';
import Loader from '../../../molecules/loader';
import TypographyText from '../../../atoms/ui/typography-text';
import TextEllipsis from '../../../molecules/ui/text-ellipsis';
import classNames from 'classnames';
import { dateInMDYFormat, getPeriodInDays } from '../../../../helpers/custom/datetime';
import { UserCoreInfoType } from '../../../../store/slices/user.slice';
import usePagination from '../../../../helpers/hooks/custom/use-pagination/use-pagination';
import { formatStringAsAbsDecimal } from '../../../../helpers/custom/common';
import { checkIfBeforeDate } from '../../../../helpers/custom/job';

const BoostsHistoryWithPagination: FC<BoostsHistoryWithPaginationInterface> = ({
	activeCompanyId,
}) => {
	const [dataInitialLoading, setDataInitialLoading] = useState(true);

	const getPageItems = useCallback(
		async (pageNumber: number) => {
			if (activeCompanyId) {
				const itemsResult = await getJobBoostsHistoryForPage(
					activeCompanyId,
					pageNumber,
					BOOST_PAGINATION_PAGE_SIZE,
				);

				if (pageNumber === 1) setDataInitialLoading(false);

				return itemsResult;
			}
		},
		[activeCompanyId],
	);

	const {
		paginationState,
		loading: pageItemsLoading,
		moveToNextPage,
		moveToPrevPage,
	} = usePagination(getPageItems, activeCompanyId, BOOST_PAGINATION_PAGE_SIZE);

	const renderUserName = useCallback((user: Partial<UserCoreInfoType> | null) => {
		if (user && user.firstName && user.lastName) {
			return <TextEllipsis text={`${user.firstName} ${user.lastName}`} />;
		}

		return '-';
	}, []);

	const renderBoostDuration = useCallback((startedAt: string, endedAt?: string) => {
		let days: number | undefined;

		if (endedAt) {
			//for old cases support when we always had endedAt
			const now = dateInMDYFormat(Date.now());
			const endedAtDate = dateInMDYFormat(endedAt);

			const endIsBeforeNow = checkIfBeforeDate(endedAtDate, now);

			days = endIsBeforeNow
				? getPeriodInDays(startedAt, endedAt)
				: getPeriodInDays(startedAt);
		} else {
			days = getPeriodInDays(startedAt);
		}

		if (days !== undefined) return `${days === 0 ? '1' : days} day${days <= 1 ? '' : 's'}`;
	}, []);

	const renderEndDateOrStatus = useCallback((endedAt?: string) => {
		if (!endedAt) return 'Boosting';

		const now = dateInMDYFormat(Date.now());
		const endedAtDate = dateInMDYFormat(endedAt);

		const endIsBeforeNow = checkIfBeforeDate(endedAtDate, now);
		return endIsBeforeNow ? dateInMDYFormat(endedAt) : 'Boosting';
	}, []);

	const rowsElement = useMemo(
		() =>
			paginationState.currentPage &&
			paginationState.currentPage.items &&
			paginationState.currentPage.items.map(
				({ jobId, jobName, location, amount, user, boostDate }: BoostHistoryRecord) => {
					const formattedStartDate = dateInMDYFormat(boostDate.startedAt);
					const formattedEndDate = renderEndDateOrStatus(boostDate.endedAt);
					const boostDuration = renderBoostDuration(
						boostDate.startedAt,
						boostDate.endedAt,
					);
					const recordKey = `${boostDate.startedAt}-${amount}`;

					return (
						<TableRow key={recordKey}>
							<TableCell width={'20%'}>
								<TextEllipsis text={jobName} linkUrl={`/jobs/${jobId}`} />
							</TableCell>
							<TableCell width={'20%'}>
								<TextEllipsis text={location} />
							</TableCell>
							<TableCell width={'12%'}>
								<TextEllipsis text={formattedStartDate} />
							</TableCell>
							<TableCell width={'12%'}>
								<TextEllipsis text={formattedEndDate} />
							</TableCell>
							<TableCell width={'12%'}>
								<TextEllipsis text={boostDuration || ''} />
							</TableCell>
							<TableCell width={'10%'}>
								<TextEllipsis
									text={formatStringAsAbsDecimal(amount).toString() || ''}
								/>
							</TableCell>
							<TableCell width={'12%'}>{renderUserName(user)}</TableCell>
						</TableRow>
					);
				},
			),
		[paginationState.currentPage, renderEndDateOrStatus, renderBoostDuration, renderUserName],
	);

	const renderTable = useMemo(() => {
		return (
			<Table>
				<TableHead>
					<TableCell
						width={'20%'}
						headCell
						className={classNames(styles['custom-weight'], styles['without-border'])}
					>
						<TextEllipsis text={'Job'} />
					</TableCell>
					<TableCell
						width={'20%'}
						headCell
						className={classNames(styles['custom-weight'], styles['without-border'])}
					>
						<TextEllipsis text={'Location'} />
					</TableCell>
					<TableCell
						width={'12%'}
						className={classNames(styles['custom-weight'], styles['without-border'])}
						headCell
					>
						<TextEllipsis text={'Start'} />
					</TableCell>
					<TableCell
						width={'12%'}
						className={classNames(styles['custom-weight'], styles['without-border'])}
						headCell
					>
						<TextEllipsis text={'End'} />
					</TableCell>
					<TableCell
						width={'12%'}
						headCell
						className={classNames(styles['custom-weight'], styles['without-border'])}
					>
						<TextEllipsis text={'Duration'} />
					</TableCell>
					<TableCell
						width={'10%'}
						headCell
						className={classNames(styles['custom-weight'], styles['without-border'])}
					>
						<TextEllipsis text={'Spend'} />
					</TableCell>
					<TableCell
						width={'12%'}
						headCell
						className={classNames(styles['custom-weight'], styles['without-border'])}
					>
						<TextEllipsis text={'Authorized by'} />
					</TableCell>
				</TableHead>

				<TableBody className={styles.body}>{rowsElement}</TableBody>
			</Table>
		);
	}, [rowsElement]);

	const renderPaginationSection = useMemo(() => {
		return (
			<Pagination
				className={styles.pagination}
				loading={pageItemsLoading}
				moveToNextPage={moveToNextPage}
				moveToPrevPage={moveToPrevPage}
				hasNextPage={paginationState?.nextPage !== null}
				currentPageNumber={
					paginationState?.currentPage ? paginationState?.currentPage.pageNumber : null
				}
			/>
		);
	}, [moveToNextPage, moveToPrevPage, pageItemsLoading, paginationState]);

	const renderHistory = useMemo(() => {
		if (dataInitialLoading)
			return <Loader className={styles.loader} thin maxHeight={20} maxWidth={20} />;

		if (paginationState?.currentPage && paginationState?.currentPage?.items) {
			return (
				<>
					{paginationState.currentPage.items.length ? (
						<>
							{renderTable}
							{renderPaginationSection}
						</>
					) : (
						<TypographyText className={styles.empty}>
							No credits have been spent yet.
						</TypographyText>
					)}
				</>
			);
		}
	}, [paginationState, renderPaginationSection, renderTable, dataInitialLoading]);

	return <div className={styles.history}>{renderHistory}</div>;
};

export default BoostsHistoryWithPagination;
