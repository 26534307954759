import { useMemo } from 'react';

import { CompanyInterface } from '../../../store/slices/companies.slice';

export default function useActiveCompany(
	activeCompanyId?: string | number,
	items?: CompanyInterface[],
) {
	const getCompany = (): CompanyInterface | undefined => {
		return items?.find(({ id }) => id === activeCompanyId);
	};

	return useMemo(getCompany, [items, activeCompanyId]);
}
