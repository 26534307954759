import React, { FC, useEffect, useMemo } from 'react';
import Fieldset from '../../../form-elemets/fieldset';
import Legend from '../../../../atoms/form/legend';
import TextEditor from '../../../form-elemets/text-editor';
import CompanyDetailsInterface from './CompanyDetails.interface';
import { JobFormModel } from '../../../../../helpers/custom/job';
import { getErrorsByProperty } from '../../../../../helpers/custom/common';
import Error from '../../../../molecules/ui/error';
import styles from './CompanyDetails.module.scss';
import Container from '../../../../templates/container';
import useJob from '../../../../../helpers/hooks/use-job/useJob';
import useCompanies from '../../../../../helpers/hooks/use-companies/useCompanies';
import useActiveCompany from '../../../../../helpers/hooks/use-active-company/useActiveCompany';
import { jobCompanyDetailsGA4Event } from '../../../../../helpers/google-analytics-4/events';
import {
	GAContentGroup,
	GAContentId,
	GAContentType,
} from '../../../../../helpers/google-analytics-4/index.constants';
import { getCompanyDataForGA } from '../../../../../helpers/google-analytics-4/helpers';

const CompanyDetails: FC<CompanyDetailsInterface> = ({
	register,
	className,
	setFormState,
	formState,
	brandingName,
	isEditMode,
}) => {
	const localState = useMemo(
		() => ({
			companyDescription: formState?.companyDescription,
			benefits: formState?.benefits,
			eoe: formState?.eoe,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	const { apiErrors } = useJob();
	const { activeCompanyId, companies } = useCompanies();
	const activeCompany = useActiveCompany(activeCompanyId, companies);

	const fieldsErrors = useMemo(
		() => (apiErrors?.length ? getErrorsByProperty(apiErrors) : {}),
		[apiErrors],
	);

	useEffect(() => {
		if (activeCompany) {
			jobCompanyDetailsGA4Event(
				{
					companyData: getCompanyDataForGA(activeCompany),
					contentData: {
						id: brandingName ? GAContentId.postAJob : GAContentId.job,
						group: brandingName ? GAContentGroup.postAJob : GAContentGroup.job,
						type: isEditMode ? GAContentType.update : GAContentType.create,
					},
				},
				brandingName,
			);
		}
	}, [activeCompany, brandingName, isEditMode]);

	return (
		<Container maxWidth={572} className={className}>
			<Fieldset>
				<Fieldset>
					<Legend extraSmall>Company description</Legend>

					<TextEditor
						defaultValue={localState.companyDescription}
						onChangeHandler={(value) => {
							setFormState((prev: JobFormModel) => ({
								...prev,
								companyDescription: value,
							}));
						}}
						textareaSettings={{
							ref: register,
							name: 'companyDescription',
						}}
					/>

					{fieldsErrors.companyDescription &&
					Object.values(fieldsErrors.companyDescription?.constraints) ? (
						<Error className={styles.error}>
							{Object.values(fieldsErrors.companyDescription.constraints)}
						</Error>
					) : null}
				</Fieldset>

				<Fieldset>
					<Legend extraSmall>Benefits</Legend>

					<TextEditor
						defaultValue={localState.benefits}
						onChangeHandler={(value) => {
							setFormState((prev: JobFormModel) => ({ ...prev, benefits: value }));
						}}
						textareaSettings={{
							ref: register,
							name: 'benefits',
						}}
					/>

					{fieldsErrors.benefits && Object.values(fieldsErrors.benefits?.constraints) ? (
						<Error className={styles.error}>
							{Object.values(fieldsErrors.benefits.constraints)}
						</Error>
					) : null}
				</Fieldset>

				<Fieldset className={styles.eoe}>
					<Legend extraSmall>Equal opportunity employer</Legend>

					<TextEditor
						defaultValue={localState.eoe}
						onChangeHandler={(value) => {
							setFormState((prev: JobFormModel) => ({ ...prev, eoe: value }));
						}}
						textareaSettings={{
							ref: register,
							name: 'eoe',
						}}
					/>

					{fieldsErrors.eoe && Object.values(fieldsErrors.eoe?.constraints) ? (
						<Error className={styles.error}>
							{Object.values(fieldsErrors.eoe.constraints)}
						</Error>
					) : null}
				</Fieldset>
			</Fieldset>
		</Container>
	);
};

export default React.memo(CompanyDetails);
