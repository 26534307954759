import React, { FC, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import Tabs from '../../components/molecules/ui/tabs/Tabs';
import Facilities from '../../components/organisms/custom/facilities';
import MyProfileScreen from '../../components/organisms/custom/my-profile-screen';
import CompanyEditScreen from '../../components/organisms/custom/company-edit-screen/CompanyEditScreen';

import styles from './Account.module.scss';
import CompanySettingsScreen from '../../components/organisms/custom/company-settings-screen/CompanySettingsScreen';
import CompanyTemplatesScreen from '../../components/organisms/custom/company-templates-screen';
import BillingScreen from '../../components/organisms/custom/billing-screen';
import useCompanies from '../../helpers/hooks/use-companies/useCompanies';
import { useDispatch } from 'react-redux';
import { clearJob } from '../../store/slices/job.slice';
import { QueryParametersKey } from '../../helpers/constants';

const Account: FC = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const activeTab = useMemo(
		() => new URLSearchParams(location.search).get(QueryParametersKey.tab),
		[location.search],
	);

	const { activeCompanyCanSeeBilling } = useCompanies();

	const showBillingTab = activeTab === 'billing' || activeCompanyCanSeeBilling;

	const tabs = useMemo(
		() => (
			<Tabs
				tabsWrapClassName={styles['tabs-wrap']}
				className={styles.tabs}
				defaultActiveTab={activeTab || 'my-profile'}
				tabs={[
					{
						key: 'my-profile',
						text: 'My Profile',
						component: <MyProfileScreen className={styles.screen} />,
					},
					{
						key: 'facilities',
						text: 'Facilities',
						component: <Facilities className={styles.screen} />,
					},
					{
						key: 'company',
						text: 'Company',
						component: <CompanyEditScreen className={styles.screen} />,
					},
					...(showBillingTab
						? [
								{
									key: 'billing',
									text: 'Billing',
									component: <BillingScreen className={styles.screen} />,
								},
						  ]
						: []),
					{
						key: 'settings',
						text: 'Settings',
						component: <CompanySettingsScreen className={styles.screen} />,
					},
					{
						key: 'templates',
						text: 'Templates',
						component: <CompanyTemplatesScreen className={styles.screen} />,
					},
				]}
			/>
		),
		[activeTab, showBillingTab],
	);

	useEffect((): (() => any) => {
		return () => dispatch(clearJob());
	}, [dispatch]);

	return tabs;
};

export default React.memo(Account);
