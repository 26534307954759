import React, { FC } from 'react';
import classNames from 'classnames';

import TableInterface from './table.interface';

import styles from './Table.module.scss';

const Table: FC<TableInterface> = ({ children, className, ...rest }) => {
	return (
		<div className={classNames(styles.table, className)} {...rest}>
			{children}
		</div>
	);
};

export default Table;
