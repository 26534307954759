import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import NoteFieldInterface from './note-field.interface';
import Button from '../../../atoms/buttons/button/Button';
import TextEditor from '../../form-elemets/text-editor';
import Loader from '../../../molecules/loader';

import styles from './NoteField.module.scss';
import classNames from 'classnames';
import { isEditorValueEmpty } from '../../../../helpers/custom/editors';
import { useDispatch } from 'react-redux';
import { adding } from '../../../../store/slices/notes.slice';

const NoteField: FC<NoteFieldInterface> = ({
	onSubmit,
	loading,
	className,
	noteDefaultValue,
	actionElements,
	onValueChangeHandler,
	...rest
}) => {
	const dispatch = useDispatch();
	const [clearState, setClearState] = useState(false);
	const [emptyValue, setEmptyValue] = useState(true);
	const { handleSubmit, register } = useForm();

	useEffect(() => {
		if (loading !== undefined && !loading) {
			if (!noteDefaultValue) {
				setClearState(true);
				setEmptyValue(true);
			}

			if (loading === false) dispatch(adding(undefined));
		} else {
			setClearState(false);
		}
	}, [dispatch, loading, noteDefaultValue]);

	const onNoteValueChange = useCallback(
		(value: string) => {
			setEmptyValue(isEditorValueEmpty(value));

			if (onValueChangeHandler) onValueChangeHandler(value);
		},
		[onValueChangeHandler],
	);

	const renderActionElements = useMemo(() => {
		if (!actionElements && !loading) {
			if (!actionElements) {
				return (
					<Button
						className={styles.btn}
						minWidth={143}
						minHeight={33}
						maxHeight={33}
						reversedStyles
						disabled={emptyValue}
					>
						Add note
					</Button>
				);
			}

			return null;
		}

		return actionElements;
	}, [actionElements, emptyValue, loading]);

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className={classNames(styles.wrap, className)}
			{...rest}
		>
			<TextEditor
				clear={clearState}
				placeholder="Add a note..."
				defaultValue={noteDefaultValue}
				textareaSettings={{
					ref: register(),
					name: 'note',
				}}
				onChangeHandler={onNoteValueChange}
			/>

			<footer className={styles.bottom}>
				{renderActionElements}

				{loading ? (
					<Loader thin maxWidth={20} maxHeight={20} className={styles.loader} />
				) : null}
			</footer>
		</form>
	);
};

export default React.memo(NoteField);
