import React, { FC, useRef } from 'react';
import classNames from 'classnames';

import TextGrabberInterface from './text-grabber.interface';
import TypographyTextPreview from '../../../atoms/ui/typography-text-preview';
import Button from '../../../atoms/buttons/button/Button';
import Title from '../../../atoms/title/Title';

import styles from './TextGrabber.module.scss';

const TextGrabber: FC<TextGrabberInterface> = ({ title, text, className, ...rest }) => {
	const grabberRef = useRef(null);

	const copyBtnClickHandler = () => {
		if (grabberRef) {
			(grabberRef.current as any).select();
			document.execCommand('copy');
		}
	};

	return (
		<div className={classNames(styles['text-grabber'], className)} {...rest}>
			<Title component="h4" fontSize={20} fontWeight={900} marginBottom={16}>
				{title}
			</Title>

			<div className={styles.actions}>
				<TypographyTextPreview className={styles.grabber} ref={grabberRef} text={text} />

				<Button
					small
					minWidth={104}
					maxWidth={104}
					className={styles.btn}
					onClick={copyBtnClickHandler}
				>
					Copy
				</Button>
			</div>
		</div>
	);
};

export default TextGrabber;
