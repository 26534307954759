import React, { FC } from 'react';

import OverlayInterface from './overlay.interface';

import styles from './Overlay.module.scss';

const Overlay: FC<OverlayInterface> = ({ open }) => {
	return open ? <span className={styles.overlay} /> : null;
};

export default Overlay;
