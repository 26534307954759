import React, { FC } from 'react';
import classNames from 'classnames';

import BackInterface from './back.interface';

import styles from './Back.module.scss';

const Back: FC<BackInterface> = ({ className, ...rest }) => {
	return <button className={classNames(styles.back, className)} {...rest} />;
};

export default Back;
