import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import {
	AuthorizationSliceInterface,
	sendValidationCode,
} from '../../../../store/slices/authorization.slice';
import { SlideStepperStepsType } from '../../slide-stepper/slide-stepper.interface';
import LoginTroubleInterface from './login-trouble.interface';
import SlideFormLayout from '../../../templates/custom/slide-form-layout';
import SlideStepper from '../../slide-stepper';
import TroubleSignInEmailStep from '../trouble-sign-in-steps/trouble-sign-in-email-step';
import TroubleSignInValidationCodeStep from '../trouble-sign-in-steps/trouble-sign-in-validation-code-step';
import TroubleSignInResetPasswordStep from '../trouble-sign-in-steps/trouble-sign-in-reset-password-step';
import ButtonLink from '../../../atoms/buttons/button-link';

import styles from './LoginTrouble.module.scss';
import { AutoLoginActions, TokenTypes } from '../../../../helpers/custom/common';
import { APP_URLS } from '../../../../helpers/routes/routes';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	UserAndCodeInfoInterface,
	getValidationCodeFromLink,
} from '../../../../store/slices/user.slice';
import Loader from '../../../molecules/loader';
import { QueryParametersKey } from '../../../../helpers/constants';
import { getTargetMessageByProperty } from '../../../../helpers/forms/forms';

const LoginTrouble: FC<LoginTroubleInterface> = ({ closeModalWindow }) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const authorizationInfo = useSelector(
		({ authorization }: { authorization: AuthorizationSliceInterface }) => authorization,
	);

	const isAutologinFlow = useMemo(
		() => location.search.includes(QueryParametersKey.autologin),
		[location.search],
	);
	const [loading, setLoading] = useState(isAutologinFlow);
	const [validationData, setValidationData] = useState<UserAndCodeInfoInterface>();

	const [isError, setIsError] = useState(false);
	const [isResendClicked, setIsResendClicked] = useState(false);

	useEffect(() => {
		(async () => {
			if (isAutologinFlow) {
				const urlParams = new URLSearchParams(location.search);
				const token = urlParams.get(QueryParametersKey.autologin);
				const action = urlParams.get(QueryParametersKey.action);

				if (token && action === AutoLoginActions.troubleSignin && !validationData) {
					const result = await dispatch(getValidationCodeFromLink(token));

					//@ts-ignore
					if (result && !result.errors && Object.keys(result).length) {
						setIsError(false);

						setValidationData({
							//@ts-ignore
							user: result.user,
							//@ts-ignore
							validationCode: result.validationCode,
						});
					} else {
						setIsError(true);

						//@ts-ignore
						const tokenError = getTargetMessageByProperty('tokenId', result?.errors);

						navigate(
							`${APP_URLS.expiredAutologin}${
								tokenError ? `?${QueryParametersKey.invalid}` : ''
							}`,
						);
					}

					setLoading(false);
				}
			}
		})();
	}, [dispatch, isAutologinFlow, location.search, navigate, validationData]);

	const resendValidationCode = useCallback(() => {
		if (authorizationInfo.info.email) {
			setIsResendClicked(true);

			const autologinData = {
				tokenType: TokenTypes.validationCode,
				action: AutoLoginActions.troubleSignin,
				destinationUrl: `${APP_URLS.login}${location.search}`,
			};

			dispatch(sendValidationCode({ email: authorizationInfo.info.email }, autologinData));
		}
	}, [authorizationInfo.info.email, location.search, dispatch]);

	useEffect(() => {
		if (isResendClicked) {
			setTimeout(() => setIsResendClicked(false), 1500);
		}
	}, [isResendClicked]);

	const steps: SlideStepperStepsType[] = useMemo(
		() => [
			...(!isAutologinFlow
				? [
						{
							stepName: 'email',
							component: (
								<TroubleSignInEmailStep
									title="Trouble Signing In"
									description="Enter your email below and we'll send you a validation code."
									{...{ closeModalWindow }}
								/>
							),
						},
				  ]
				: []),
			...(!isAutologinFlow
				? [
						{
							stepName: 'validationCode',
							component: (
								<TroubleSignInValidationCodeStep
									title="Sign in"
									description={
										<>
											We sent a code to {authorizationInfo.info.email} to sign
											in. Didn't get the code?{' '}
											<ButtonLink
												className={classNames(
													styles.link,
													isResendClicked ? styles.clicked : '',
												)}
												type="button"
												onClick={resendValidationCode}
											>
												Resend email
											</ButtonLink>
										</>
									}
								/>
							),
						},
				  ]
				: []),

			{
				stepName: 'resetPassword',
				component: (
					<TroubleSignInResetPasswordStep
						title="Reset Password"
						description="Reset your password below. Your password must be a minimum of 8 characters."
						validationData={validationData}
					/>
				),
			},
		],
		[
			authorizationInfo.info.email,
			closeModalWindow,
			isAutologinFlow,
			isResendClicked,
			resendValidationCode,
			validationData,
		],
	);

	if (loading) return <Loader wide />;

	return (
		<SlideFormLayout className={classNames(styles.wrap, styles.box)}>
			<SlideStepper steps={steps} isError={isError} />
		</SlideFormLayout>
	);
};

export default LoginTrouble;
