import React, { FC, useCallback, useEffect, useMemo } from 'react';
import {
	expectedCannabisIndustrySeniorityMarks,
	expectedSeniorityMarks,
} from '../../../../../helpers/constants';
import { SliderElementMark } from '../../../../molecules/form/slider-element/slider-element.interface';
import { RootStateType } from '../../../../../store/reducers/root-reducer';
import useActiveCompany from '../../../../../helpers/hooks/use-active-company/useActiveCompany';
import SliderElement from '../../../../molecules/form/slider-element';
import RadioGroup from '../../../form-elemets/radio-group/RadioGroup';
import RadioInterface from '../../../../molecules/form/radio/radio.interface';
import SliderElementWithDynamicStateDisplaying from '../../../form-elemets/slider-element-with-dynamic-state-displaying';
import Fieldset from '../../../form-elemets/fieldset';
import Legend from '../../../../atoms/form/legend';

import styles from './Requirements.module.scss';
import RequirementsInterface from './requirements.interface';
import { useSelector } from 'react-redux';
import { JobFormModel } from '../../../../../helpers/custom/job';
import { getErrorsByProperty, getSliderNumberValue } from '../../../../../helpers/custom/common';
import Error from '../../../../molecules/ui/error';
import Container from '../../../../templates/container';
import { jobRequirementsGA4Event } from '../../../../../helpers/google-analytics-4/events';
import { GAContentGroup, GAContentId, GAContentType } from '../../../../../helpers/google-analytics-4/index.constants';
import { getCompanyDataForGA } from '../../../../../helpers/google-analytics-4/helpers';

const marks: SliderElementMark[] = [
	{
		number: 10,
		label: <span>Don't Ask</span>,
		value: 0,
	},
	{
		number: 50,
		label: <span>Ask</span>,
		value: 1,
	},
	{
		number: 90,
		label: <span>Required</span>,
		value: 2,
	},
];

const Requirements: FC<RequirementsInterface> = ({
	register,
	className,
	setFormState,
	formState,
	brandingName,
	isEditMode,
}) => {
	const {
		companies: { activeCompanyId, items },
		job: { errors: apiErrors },
	} = useSelector((state: RootStateType) => state);
	const activeCompany = useActiveCompany(activeCompanyId, items);

	const onRadioButtonChange = useCallback(
		(value: number) => {
			setFormState((prev: JobFormModel) => ({ ...prev, requireResume: value }));
		},
		[setFormState],
	);

	const radioButtons: Omit<RadioInterface, 'name'>[] = useMemo(
		() => [
			{
				id: 'resume',
				label: 'Resume',
				value: 3,
				defaultChecked:
					!formState ||
					typeof formState.requireResume === 'undefined' ||
					formState.requireResume === 3,
				onChange: () => onRadioButtonChange(3),
			},
			{
				id: 'linkedIn',
				label: 'Resume OR LinkedIn profile',
				value: 2,
				defaultChecked:
					formState &&
					typeof formState.requireResume !== 'undefined' &&
					formState.requireResume === 2,
				onChange: () => onRadioButtonChange(2),
			},
			{
				id: 'manually',
				label: 'Resume OR LinkedIn OR manually-entered',
				value: 1,
				defaultChecked:
					formState &&
					typeof formState.requireResume !== 'undefined' &&
					formState.requireResume === 1,
				onChange: () => onRadioButtonChange(1),
			},
			{
				id: 'none',
				label: 'None required',
				value: 0,
				defaultChecked:
					formState &&
					typeof formState.requireResume !== 'undefined' &&
					formState.requireResume === 0,
				onChange: () => onRadioButtonChange(0),
			},
		],
		[formState, onRadioButtonChange],
	);

	const fieldsErrors = useMemo(
		() => (apiErrors?.length ? getErrorsByProperty(apiErrors) : {}),
		[apiErrors],
	);

	useEffect(() => {
		if (activeCompany) {
			jobRequirementsGA4Event(
				{
					companyData: getCompanyDataForGA(activeCompany),
					contentData: {
						id: brandingName ? GAContentId.postAJob : GAContentId.job,
						group: brandingName ? GAContentGroup.postAJob : GAContentGroup.job,
						type: isEditMode ? GAContentType.update : GAContentType.create,
					},
				},
				brandingName,
			);
		}
	}, [activeCompany, brandingName, isEditMode]);

	return (
		<Container maxWidth={572} className={className}>
			<Fieldset>
				<Fieldset>
					<Legend extraSmall>Expected seniority</Legend>

					<SliderElementWithDynamicStateDisplaying
						id="work-seniority"
						marks={expectedSeniorityMarks}
						value={getSliderNumberValue(
							expectedSeniorityMarks,
							formState?.workSeniority,
						)}
						onChangeHandler={(data) => {
							setFormState((prev: JobFormModel) => ({
								...prev,
								workSeniority: data.value,
							}));
						}}
						inputSettings={{
							name: 'workSeniority',
							ref: register,
							value: formState?.workSeniority,
						}}
						className={styles['seniority-item']}
					/>

					{fieldsErrors.workSeniority &&
					Object.values(fieldsErrors.workSeniority?.constraints) ? (
						<Error className={styles.error}>
							{Object.values(fieldsErrors.workSeniority.constraints)}
						</Error>
					) : null}
				</Fieldset>

				<Fieldset>
					<Legend extraSmall>Expected cannabis experience</Legend>

					{activeCompany?.allowsCannabis && (
						<SliderElementWithDynamicStateDisplaying
							id="cannabis-seniority"
							marks={expectedCannabisIndustrySeniorityMarks}
							value={getSliderNumberValue(
								expectedCannabisIndustrySeniorityMarks,
								formState?.cannabisSeniority,
							)}
							onChangeHandler={(data) => {
								setFormState((prev: JobFormModel) => ({
									...prev,
									cannabisSeniority: data.value,
								}));
							}}
							inputSettings={{
								name: 'cannabisSeniority',
								ref: register,
								value: formState?.cannabisSeniority,
							}}
							className={styles['seniority-item']}
						/>
					)}

					{fieldsErrors.cannabisSeniority &&
					Object.values(fieldsErrors.cannabisSeniority?.constraints) ? (
						<Error className={styles.error}>
							{Object.values(fieldsErrors.cannabisSeniority.constraints)}
						</Error>
					) : null}
				</Fieldset>
			</Fieldset>

			<Fieldset>
				<Fieldset>
					<Legend extraSmall>Work history requirements</Legend>

					<RadioGroup name="requireResume" ref={register} radioButtons={radioButtons} />
				</Fieldset>

				<Fieldset className={styles.cover}>
					<Legend extraSmall>Cover letter requirement</Legend>

					<SliderElement
						marks={marks}
						id="require-cover"
						value={getSliderNumberValue(marks, formState?.requireCover)}
						style={{ maxWidth: 'calc(50% - 5px)' }}
						onValueChange={(value) => {
							setFormState((prev: JobFormModel) => ({
								...prev,
								requireCover: value,
							}));
						}}
						inputSettings={{
							name: 'requireCover',
							ref: register,
							value: formState?.requireCover,
						}}
					/>

					{fieldsErrors.requireCover &&
					Object.values(fieldsErrors.requireCover?.constraints) ? (
						<Error className={styles.error}>
							{Object.values(fieldsErrors.requireCover.constraints)}
						</Error>
					) : null}
				</Fieldset>
			</Fieldset>
		</Container>
	);
};

export default React.memo(Requirements);
