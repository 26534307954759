import React, { FC, useMemo, useState } from 'react';

import { SlideStepperStepsType } from '../../components/organisms/slide-stepper/slide-stepper.interface';
import LoginForm from '../../components/organisms/custom/login-form/LoginForm';
import ModalWindow from '../../components/organisms/modal-window/ModalWindow';
import Container from '../../components/templates/container';
import Logo from '../../components/atoms/icons/logo';
import SlideStepper from '../../components/organisms/slide-stepper';
import SlideFormLayout from '../../components/templates/custom/slide-form-layout';
import EmailStep from '../../components/organisms/custom/sign-in-steps/email-step';
import PasswordStep from '../../components/organisms/custom/sign-in-steps/password-step';
import LoginTroubleForm from '../../components/organisms/custom/login-trouble';

import styles from './Login.module.scss';
import { QueryParametersKey } from '../../helpers/constants';
import { useLocation } from 'react-router-dom';

const Login: FC = () => {
	const location = useLocation();
	const [troubleSigningInState, setTroubleSigningInState] = useState(
		location.search.includes(QueryParametersKey.autologin) &&
			location.search.includes(QueryParametersKey.action),
	);

	const steps: SlideStepperStepsType[] = useMemo(
		() => [
			{
				stepName: 'email',
				component: <EmailStep {...{ setTroubleSigningInState }} />,
			},
			{
				stepName: 'password',
				component: <PasswordStep {...{ setTroubleSigningInState }} />,
			},
		],
		[setTroubleSigningInState],
	);

	return (
		<div className={styles.login}>
			<Container maxWidth={378} className={styles.container}>
				<div className={styles.wrap}>
					{!troubleSigningInState ? (
						<>
							<Logo type="light" className={styles.logo} />

							<LoginForm>
								<SlideFormLayout
									title="Sign in"
									headerClassName={styles.header}
									description={
										'You are using the industy’s first virtual talent management system. <a href="https://www.careersincannabis.com/" target="_blank">Learn more</a>'
									}
								>
									<SlideStepper
										allowedListeners={!troubleSigningInState}
										steps={steps}
									/>
								</SlideFormLayout>
							</LoginForm>
						</>
					) : null}

					<ModalWindow
						open={troubleSigningInState}
						hasCloseButton={false}
						close={() => setTroubleSigningInState(false)}
						contentClassName={styles['modal-content']}
						closeClassName={styles.close}
					>
						<Logo type="simple" className={styles.logo} />

						<LoginTroubleForm
							closeModalWindow={() => setTroubleSigningInState(false)}
						/>
					</ModalWindow>
				</div>
			</Container>
		</div>
	);
};

export default Login;
