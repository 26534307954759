import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import styles from './PowerProfiles.module.scss';
import PowerProfilesInterface from './power-profiles.interface';
import useJobsTemplates from '../../../../../helpers/hooks/use-jobs-templates/useJobsTemplates';
import { JobTemplateInterface } from '../../../../../store/slices/jobs-templates.slice';
import TemplateItemTile from '../../template-item-tile';
import Loader from '../../../../molecules/loader';
import TypographyText from '../../../../atoms/ui/typography-text';
import UnorderedListItem from '../../../../atoms/ui/unordered-list-item/UnorderedListItem';
import UnorderedList from '../../../../molecules/ui/unordered-list/UnorderedList';
import InfoPopover from '../../../../molecules/ui/info-popover';
import CheckChipsGroup from '../../../../molecules/ui/check-chips-group/CheckChipsGroup';
import CheckChip from '../../../../atoms/ui/check-chip/CheckChip';
import ExtendedFitScore from '../../../../molecules/ui/extended-fit-score';

const PowerProfiles: FC<PowerProfilesInterface> = ({ formState, setFormState }) => {
	const { loading, items } = useJobsTemplates();
	const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);

	const departmentsWithProfiles = useMemo(() => {
		if (items?.length) {
			const departments: any = {};

			items.forEach((item) => {
				if (!Object.keys(departments).includes(item.departmentLabel)) {
					departments[item.departmentLabel] = [item.id];
				} else {
					departments[item.departmentLabel].push(item.id);
				}
			});

			setSelectedDepartments(Object.keys(departments));
			return departments;
		}
	}, [items]);

	const templates = useMemo(() => {
		const profilesToDisplay = items.filter((item) =>
			selectedDepartments.includes(item.departmentLabel),
		);

		const onTemplateClick = (template: JobTemplateInterface) => () => {
			const { id, title, summary, mustHaveSkills, niceToHaveSkills, department, level } =
				template;

			setFormState((prev: any) => {
				return {
					...prev,
					jobProfileId: id,
					jobProfileLabel: template.uniqueName,
					title,
					summary,
					mustHaveSkills,
					niceToHaveSkills,
					department,
					departmentLabel: template.departmentLabel,
					departmentKey: template.departmentKey,
					level,
				};
			});
		};

		return (
			profilesToDisplay &&
			profilesToDisplay.map((template: JobTemplateInterface) => {
				const { id, title, subtitle, description } = template;

				return (
					<TemplateItemTile
						key={id}
						title={title}
						subtitle={subtitle}
						description={description}
						onClick={onTemplateClick(template)}
						className={styles['tile-item']}
						isActive={formState?.jobProfileId === id}
					/>
				);
			})
		);
	}, [formState?.jobProfileId, items, selectedDepartments, setFormState]);

	const onDepartmentClick = useCallback((departmentName: string) => {
		setSelectedDepartments((prev: string[]) => {
			if (!prev.includes(departmentName)) {
				return [...prev, departmentName];
			} else {
				const departmentProfiles = departmentsWithProfiles[departmentName];

				if (departmentProfiles.includes(formState.jobProfileId)) {
					setFormState((prev: any) => {
						return {
							...prev,
							jobProfileId: null,
							jobProfileLabel: '',
							title: '',
							summary: '',
							mustHaveSkills: '',
							niceToHaveSkills: '',
							department: undefined,
							departmentLabel: '',
							departmentKey: '',
							level: undefined,
						};
					});
				};

				return prev.filter((item) => item !== departmentName);
			}
		});
	}, [departmentsWithProfiles, formState.jobProfileId, setFormState]);

	const departments = useMemo(() => {
		if (departmentsWithProfiles) {
			const items = Object.keys(departmentsWithProfiles).map((item) => {
				return (
					<CheckChip
						text={item}
						key={item}
						isChecked={selectedDepartments.includes(item)}
						onClickHandler={onDepartmentClick}
					/>
				);
			});
			return <CheckChipsGroup>{items}</CheckChipsGroup>;
		}
	}, [departmentsWithProfiles, onDepartmentClick, selectedDepartments]);

	const renderFitscoreInfoBox = useMemo(() => {
		return (
			<div className={styles['info-content']}>
				<div className={styles.title}>FitScore™</div>

				<div className={styles.text}>
					The FitScore is engin's breakthrough HR predictive technology tool that
					calculates how well a candidate fits for a job based on soft and hard skills,
					and commute.
				</div>
				<div className={styles.text}>
					engin's FitScore helps employers to improve employee engagement, reduce turnover
					risk, and save money.
				</div>

				<div className={styles.fitscore}>
					<ExtendedFitScore
						className={styles['score-content']}
						score={65}
						hardScore={75}
						softScore={75}
						commuteScoreClassName={'high'}
						smallSize
					/>
				</div>
			</div>
		);
	}, []);

	useEffect(() => window.scrollTo(0, 0), []);

	return (
		<section className={styles.profiles}>
			{loading ? (
				<Loader thin minHeight={20} minWidth={20} className={styles.loader} />
			) : (
				<div className={styles['profiles-wrap']}>
					<div className={styles.desc}>
						<TypographyText>
							<strong>PowerProfile™</strong> is a professionally-curated job template
							with:
						</TypographyText>

						<UnorderedList className={styles.list}>
							<UnorderedListItem checkStyle>
								Prefilled editable job description
							</UnorderedListItem>
							<UnorderedListItem checkStyle>
								Optimized for search engines
							</UnorderedListItem>
							<UnorderedListItem checkStyle>
								Candidate <strong>FitScore™</strong>{' '}
								<InfoPopover
									className={styles['info-box']}
									defaultTrigger
									contentClassName={styles['popover-width']}
								>
									{renderFitscoreInfoBox}
								</InfoPopover>
							</UnorderedListItem>
						</UnorderedList>
					</div>

					<div className={styles.departments}>{departments}</div>

					{selectedDepartments?.length ? (
						<div className={styles.tiles}>{templates}</div>
					) : (
						<TypographyText className={styles['empty-text']}>
							No Power Profiles found.
						</TypographyText>
					)}
				</div>
			)}
		</section>
	);
};

export default React.memo(PowerProfiles);
