import axios from 'axios';

import { NOTES } from './api.path';
import { NoteInterface, NoteParamsType } from '../store/slices/notes.slice';
import { getUrlParamsStringFromObject } from '../helpers';

const notesApi = {
	getNotesList: (params: NoteParamsType): Promise<NoteInterface[]> => {
		return axios.get(`${NOTES}${params ? '?' : ''}${getUrlParamsStringFromObject(params)}`);
	},
	addNote: (params: NoteParamsType): Promise<NoteInterface> => {
		return axios.post(NOTES, params);
	},
	editNote: (params: NoteParamsType & { noteId: number }): Promise<NoteInterface> => {
		return axios.patch(`${NOTES}/${params.noteId}`, params);
	},
	deleteNote: (noteId: number): Promise<NoteInterface> => {
		return axios.delete(`${NOTES}/${noteId}`);
	},
};

export default notesApi;
