import React, { FC } from 'react';

import styles from './TextChipsGroup.module.scss';
import TextChipsGroupInterface from './text-chips-group.interface';

const TextChipsGroup: FC<TextChipsGroupInterface> = ({ children }) => {
	return <div className={styles.chips}>{children}</div>;
};

export default TextChipsGroup;
