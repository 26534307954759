import React, { FC, useCallback } from 'react';

import PaymentConfirmationPopupInterface from './payment-confirmation-popup.interface';
import ConfirmationPopup from '../confirmation-popup';
import { paymentIntentCancelGA4Event } from '../../../../helpers/google-analytics-4/events';
import { GAMethod } from '../../../../helpers/google-analytics-4/index.constants';

const PaymentConfirmationPopup: FC<PaymentConfirmationPopupInterface> = ({
	description,
	openState,
	openStateHandler,
	onConfirmHandler,
	loading,
	gaEventData,
	brandingName,
}) => {
	const onClosePopup = useCallback(() => {
		if (gaEventData) {
			paymentIntentCancelGA4Event(gaEventData, brandingName || '', GAMethod.popup);
		}
	}, [gaEventData, brandingName]);

	return (
		<ConfirmationPopup
			title="Redirecting to payment"
			description={
				description || "You're being redirected to payment for your new engin job."
			}
			popupWidth={364}
			confirmButtonText="Continue"
			onlyConfirmButton
			btnsReversedOrder
			open={openState}
			openStateHandler={openStateHandler}
			onConfirmAndClose={onConfirmHandler}
			onClosePopup={onClosePopup}
			displayLoading={loading}
		/>
	);
};

export default React.memo(PaymentConfirmationPopup);
