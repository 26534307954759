import React, { FC } from 'react';
import classNames from 'classnames';

import CandidatesInternalNavigationInterface from './candidates-internal-navigation.interface';
import Back from '../../../atoms/back';

import styles from './CandidatesInternalNavigation.module.scss';

const CandidatesInternalNavigation: FC<CandidatesInternalNavigationInterface> = ({
	candidatesCount,
	candidateSerialNumber,
	nextHandler,
	backHandler,
	className,
}) => {
	return (
		<nav className={classNames(styles.nav, className)}>
			<Back
				className={classNames(styles.back, candidateSerialNumber === 1 && styles.disabled)}
				onClick={backHandler}
			/>

			{candidateSerialNumber && candidatesCount ? (
				<div className={styles.counter}>
					{`${candidateSerialNumber} of ${candidatesCount}`}
				</div>
			) : null}

			<Back
				className={classNames(
					styles.next,
					candidateSerialNumber &&
						candidateSerialNumber === candidatesCount &&
						styles.disabled,
				)}
				onClick={nextHandler}
			/>
		</nav>
	);
};

export default CandidatesInternalNavigation;
