import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import MessageCandidatesScreenInterface from './message-candidates-screen.interface';
import ModalWindow from '../../modal-window/ModalWindow';
import Container from '../../../templates/container';
import NestedPopupHeader from '../../popup-headers/nested-popup-header/NestedPopupHeader';
import MessageCandidatesForm from '../message-candidates-form';
import ShowMoreDropdown from '../../show-more-dropdown';
import InputWithFloatLabel from '../../form-elemets/input-with-float-label/InputWithFloatLabel';
import {
	EmailTemplateType,
	getCommunicationTemplates,
	sendMessageToCandidates,
} from '../../../../store/slices/companies.slice';
import SelectWithFloatLabel from '../../form-elemets/select-with-float-label/SelectWithFloatLabel';
import FormActions from '../../../molecules/form/form-actions';
import Button from '../../../atoms/buttons/button/Button';
import { useForm } from 'react-hook-form';
import { emailTagRegexPattern, TemplateTags } from '../../../../helpers/custom/editors';
import { RootStateType } from '../../../../store/reducers/root-reducer';
import TypographyText from '../../../atoms/ui/typography-text';
import CalendarLinkSettingPopup from '../calendar-link-setting-popup';
import OptionInterface from '../../../atoms/form/option/option.interface';

import styles from './MessageCandidatesScreen.module.scss';
import Error from '../../../molecules/ui/error';
import { CompanyEmailTemplates, NameType } from '../../../../helpers/custom/common';
import { CheckedCandidatesInterface } from '../candidates/candidates.interface';
import ResizeDeviceContext from '../../../../contexts/resize-device-context';

const MessageCandidatesScreen: FC<MessageCandidatesScreenInterface> = ({
	className,
	contentClassName,
	candidatesDataToMessage,
	open,
	close,
	afterMessageSentHandler,
	...rest
}) => {
	const {
		companies: { activeCompanyId },
		user: { info: userInfo, loading: userEditLoading },
	} = useSelector((state: RootStateType) => state);

	const dispatch = useDispatch();
	const [candidatesToMessage, setCandidatesToMessage] =
		useState<Partial<CheckedCandidatesInterface>[]>();
	const [currentTemplateId, setCurrentTemplateId] = useState('');
	const [currentTemplate, setCurrentTemplate] = useState<EmailTemplateType | null>();
	const [openCalendarLinkModal, setOpenCalendarLinkModal] = useState(false);
	const [messageIsSent, setMessageIsSent] = useState(false);
	const [sendingFailed, setSendingFailed] = useState(false);
	const [calendarLinkSuccess, setCalendarLinkSuccess] = useState(false);

	const [companyTemplates, setCompanyTemplates] = useState<EmailTemplateType[]>([]);
	const [templateOptions, setTemplateOptions] = useState<OptionInterface[]>([]);

	const device = useContext(ResizeDeviceContext);

	const { register, handleSubmit, errors: formErrors } = useForm();

	const multipleCandidates = useMemo(
		() => candidatesDataToMessage.length > 1,
		[candidatesDataToMessage.length],
	);

	const onClose = useCallback(() => {
		setCurrentTemplateId('');
		setCurrentTemplate(null);
		setMessageIsSent(false);
		close();
		
		if (afterMessageSentHandler) afterMessageSentHandler()
	}, [afterMessageSentHandler, close]);

	const onCalendarLinkSuccess = useCallback(() => {
		setCalendarLinkSuccess(true);
		if (!userEditLoading) setOpenCalendarLinkModal(false);
	}, [userEditLoading]);

	const onFormSubmitHandler = useCallback(
		async (data: Partial<EmailTemplateType>) => {
			if (
				data?.body?.includes(`[${TemplateTags.yourCalendarLink}]`) &&
				!userInfo?.profile?.calendarLink
			) {
				setOpenCalendarLinkModal(true);

				if (currentTemplate)
					//@ts-ignore
					setCurrentTemplate((prevState) => ({ ...prevState, body: data?.body }));
			} else if (!formErrors || Object.keys(formErrors).length === 0) {
				const subjectText = data.subject;
				const bodyText = data.body;

				if (multipleCandidates && candidatesToMessage?.length) {
					const payload = candidatesToMessage.map((candidate: any) => {
						const newSubjectText = subjectText
							?.replace(emailTagRegexPattern.candidateFirstName, candidate?.firstName)
							.replace(emailTagRegexPattern.candidateLastName, candidate?.lastName);

						const newBodyText = bodyText
							?.replace(emailTagRegexPattern.candidateFirstName, candidate?.firstName)
							.replace(emailTagRegexPattern.candidateLastName, candidate?.lastName)
							.replace(
								emailTagRegexPattern.yourCalendarLink,
								userInfo?.profile?.calendarLink || '',
							);

						return {
							applicationId: candidate.id,
							uniqueName: currentTemplateId,
							subject: newSubjectText || '',
							body: newBodyText || '',
						};
					});

					const result = await dispatch(sendMessageToCandidates(payload));

					//@ts-ignore
					if (result && result.failed) {
						setSendingFailed(true);
						return;
					}
				} else if (candidatesToMessage && candidatesToMessage[0].id) {
					const newBodyText = bodyText?.replace(
						emailTagRegexPattern.yourCalendarLink,
						userInfo?.profile?.calendarLink || '',
					);

					const payload = {
						applicationId: candidatesToMessage[0].id,
						uniqueName: currentTemplateId,
						subject: subjectText || '',
						body: newBodyText || '',
					};

					const result = await dispatch(sendMessageToCandidates([payload]));
					//@ts-ignore
					if (result && result.failed) {
						setSendingFailed(true);
						return;
					}
				}
				setMessageIsSent(true);
			}
		},
		[candidatesToMessage, currentTemplate, currentTemplateId, dispatch, formErrors, multipleCandidates, userInfo?.profile?.calendarLink],
	);

	const renderNamesSection = useCallback(
		(forSuccessSection?: boolean) => {
			if (candidatesToMessage?.length) {
				if (multipleCandidates) {
					return (
						<div
							className={classNames(
								styles['candidates'],
								forSuccessSection ? styles['for-success'] : '',
							)}
						>
							<div className={styles.label}>To</div>

							<ShowMoreDropdown
								trigger={
									<>
										<span className={styles.candidate}>
											{candidatesToMessage[0].firstName}{' '}
											{candidatesToMessage[0].lastName} and{' '}
											{candidatesToMessage.length - 1} others
										</span>
									</>
								}
								dropdownContent={
									<div className={styles['others-names']}>
										{candidatesToMessage.map((candidate: any) => {
											return (
												<div key={candidate.id} className={styles.name}>
													{candidate.firstName} {candidate.lastName}
												</div>
											);
										})}
									</div>
								}
							/>
						</div>
					);
				} else {
					return (
						<div
							className={classNames(
								styles['candidate'],
								forSuccessSection ? styles['for-success'] : '',
							)}
						>
							{forSuccessSection ? (
								<div>
									{candidatesToMessage[0].firstName}{' '}
									{candidatesToMessage[0].lastName}
								</div>
							) : (
								<InputWithFloatLabel
									className={classNames('form-item')}
									minWidth={'100%'}
									id="name"
									label="To"
									inputSettings={{
										name: 'name',
										value: `${candidatesToMessage[0].firstName} ${candidatesToMessage[0].lastName}`,
										readOnly: true,
									}}
								/>
							)}
						</div>
					);
				}
			}
		},
		[candidatesToMessage, multipleCandidates],
	);

	const renderTemplatesForm = useMemo(() => {
		return currentTemplate ? (
			<form>
				<MessageCandidatesForm
					key={currentTemplate?.id}
					updateTemplate={calendarLinkSuccess}
					template={currentTemplate}
					registerFormItem={register}
					formErrors={formErrors}
					multipleCandidates={multipleCandidates}
					singleCandidateData={
						!multipleCandidates && candidatesToMessage?.length
							? ({
									firstName: candidatesToMessage[0].firstName,
									lastName: candidatesToMessage[0].lastName,
							  } as NameType)
							: null
					}
				/>
			</form>
		) : null;
	}, [
		calendarLinkSuccess,
		candidatesToMessage,
		currentTemplate,
		formErrors,
		multipleCandidates,
		register,
	]);

	const renderSuccessScreen = useMemo(() => {
		return (
			<div className={styles.success}>
				<div className={styles.info}>
					<div className={styles.message}>Your message was sent successfully to:</div>

					{renderNamesSection(true)}
				</div>

				<Button
					type="button"
					minWidth={216}
					maxWidth={216}
					className={styles.btn}
					onClick={onClose}
				>
					Done
				</Button>
			</div>
		);
	}, [onClose, renderNamesSection]);

	const renderTopSection = useMemo(() => {
		return (
			<div
				className={classNames(
					styles['top-section'],
					!currentTemplate ? styles['with-separator'] : '',
				)}
			>
				{calendarLinkSuccess ? (
					<TypographyText className={styles['link-success']}>
						Your calendar link was saved to your profile.
					</TypographyText>
				) : null}

				{candidatesToMessage?.length ? (
					<div
						className={classNames(
							styles['names-section'],
							multipleCandidates ? styles.multiple : '',
						)}
					>
						{renderNamesSection()}
					</div>
				) : null}

				<SelectWithFloatLabel
					className={styles['template-options']}
					minWidth="100%"
					maxWidth="100%"
					id="message-type"
					visibleDisableStyles={false}
					label={currentTemplateId ? 'Message type' : 'Select message template'}
					selectSettings={{
						options: templateOptions,
						name: 'message-type',
						onChange: ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
							setCurrentTemplateId(value);
						},
					}}
				/>
			</div>
		);
	}, [
		calendarLinkSuccess,
		candidatesToMessage?.length,
		currentTemplate,
		currentTemplateId,
		multipleCandidates,
		renderNamesSection,
		templateOptions,
	]);

	useEffect(() => {
		(async () => {
			if (activeCompanyId && !companyTemplates?.length) {
				const templates = await dispatch(
					getCommunicationTemplates(activeCompanyId, CompanyEmailTemplates.rejection),
				);

				if (templates) {
					//@ts-ignore
					setCompanyTemplates(templates);

					setTemplateOptions(
						//@ts-ignore
						templates.map((template) => {
							return { value: template.id, text: template.label };
						}),
					);
				}
			}
		})();
	}, [activeCompanyId, companyTemplates?.length, dispatch]);

	useEffect(() => {
		(async () => {
			if (candidatesDataToMessage?.length) setCandidatesToMessage(candidatesDataToMessage);
		})();
	}, [candidatesDataToMessage]);

	useEffect(() => {
		if (currentTemplateId) {
			const template = companyTemplates.find((temp) => temp.id === currentTemplateId);
			setCurrentTemplate(template);
		}
	}, [currentTemplateId, calendarLinkSuccess, companyTemplates]);

	return (
		<>
			<ModalWindow
				className={classNames(styles.wrap, className)}
				contentClassName={classNames(styles['wrap__content'], contentClassName)}
				open={open}
				close={onClose}
				hasCloseButton={false}
				{...rest}
			>
				<NestedPopupHeader
					logo={{
						type: 'white',
					}}
					title={`Message candidate${multipleCandidates ? 's' : ''}`}
					backgroundColor="#525668"
					className={styles.header}
					titleClassName={styles['header-title']}
					close={onClose}
				/>

				<Container
					maxWidth={device !== 'desktop' ? "85%" : "552px"}
					className={classNames(
						styles.container,
						calendarLinkSuccess ? styles['with-link-success'] : '',
					)}
				>
					{messageIsSent ? (
						renderSuccessScreen
					) : (
						<div className={styles.messaging}>
							<div className={styles.wrapper}>
								{renderTopSection}
								{renderTemplatesForm}
							</div>

							{sendingFailed ? (
								<Error className={styles.error}>Sorry, something went wrong.</Error>
							) : null}

							<FormActions centeredContent className={styles.actions}>
								<Button
									type="button"
									minWidth={device !== 'desktop' ? 150 : 216}
									maxWidth={216}
									reversedStyles
									className={styles.btn}
									onClick={onClose}
								>
									Cancel
								</Button>

								<Button
									minWidth={device !== 'desktop' ? 150 : 216}
									maxWidth={216}
									className={styles.btn}
									disabled={!currentTemplate}
									onClick={handleSubmit(onFormSubmitHandler)}
								>
									Send
								</Button>
							</FormActions>
						</div>
					)}
				</Container>
			</ModalWindow>

			{!userInfo?.profile?.calendarLink ? (
				<CalendarLinkSettingPopup
					open={openCalendarLinkModal}
					openStateHandler={setOpenCalendarLinkModal}
					onSuccess={onCalendarLinkSuccess}
					nonScrollable={false}
				/>
			) : null}
		</>
	);
};

export default React.memo(MessageCandidatesScreen);
