import axios from 'axios';

import { COMMUNICATION_TEMPLATES, COMPANIES, CUSTOMER_COMPANY } from './api.path';
import {
	BoostHistoryRecord,
	CompanyInterface,
	CompanyStatisticInterface,
	CreateCompanyInterface,
	CreditsHistoryRecord,
	CreditsRefillInterface,
	EmailTemplateType,
	JobPaymentsHistoryRecord,
	MessagingDataType,
} from '../store/slices/companies.slice';

const companiesApi = {
	getUserAvailableCompanies: (): Promise<CompanyInterface[]> => {
		const url = `${COMPANIES}`;

		return axios.get(url);
	},
	getCompanyById: (id: string | number): Promise<CompanyInterface> => {
		const url = `${COMPANIES}/${id}`;

		return axios.get(url);
	},
	getCompanyStatistic: (companyId: string | number): Promise<CompanyStatisticInterface> => {
		const url = `${COMPANIES}/${companyId}/statistics`;

		return axios.get(url);
	},
	createCompany: (data: CreateCompanyInterface): Promise<any> => {
		return axios.post(CUSTOMER_COMPANY, data);
	},
	editCompany: (
		companyId: string | number,
		data: Partial<CompanyInterface>,
	): Promise<CompanyInterface> => {
		const url = `${COMPANIES}/${companyId}`;
		return axios.patch(url, data);
	},
	editCompanyUploadImages: (
		companyId: string | number,
		data: Partial<CompanyInterface>,
	): Promise<CompanyInterface> => {
		const url = `${COMPANIES}/${companyId}/upload`;
		return axios.patch(url, data);
	},
	editCompanyDeleteImages: (
		companyId: string | number,
		target: string,
	): Promise<CompanyInterface> => {
		const url = `${COMPANIES}/${companyId}/upload?target=${target}`;
		return axios.delete(url);
	},
	getCommunicationTemplates: (companyId: string | number, excludeTemplateIds?: string) => {
		const url = `${COMMUNICATION_TEMPLATES}?companyId=${companyId}${
			excludeTemplateIds ? `&excludeIds=${excludeTemplateIds}` : ''
		}`;
		return axios.get(url);
	},
	getCommunicationTemplateById: (templateId: string, companyId: string | number) => {
		const url = `${COMMUNICATION_TEMPLATES}/${templateId}?companyId=${companyId}`;
		return axios.get(url);
	},
	editCommunicationTemplate: (templateId: string, data: Partial<EmailTemplateType>) => {
		const url = `${COMMUNICATION_TEMPLATES}/${templateId}`;
		return axios.patch(url, data);
	},
	sendMessageToCandidates: (data: MessagingDataType[]) => {
		const url = `${COMMUNICATION_TEMPLATES}/send-message`;
		return axios.post(url, { data });
	},
	addCredits: (companyId: string | number, data: CreditsRefillInterface) => {
		const url = `${COMPANIES}/${companyId}/balance`;
		return axios.patch(url, { productId: data.productId, type: data.type });
	},
	getJobBoostsHistory: (
		companyId: string | number,
		pageNumber: number,
		pageSize: number,
	): Promise<BoostHistoryRecord[]> => {
		const url = `${COMPANIES}/${companyId}/sparkboosts-history`;

		const queryParams = `?pageNumber=${pageNumber}&pageSize=${pageSize}`;
		return axios.get(url + queryParams);
	},
	getTransactionsHistory: (
		companyId: string | number,
		pageNumber: number,
		pageSize: number,
	): Promise<CreditsHistoryRecord[]> => {
		const url = `${COMPANIES}/${companyId}/transactions-report`;

		const queryParams = `?pageNumber=${pageNumber}&pageSize=${pageSize}`;
		return axios.get(url + queryParams);
	},
	getPaymentTransactionsHistory: (
		companyId: string | number,
		pageNumber: number,
		pageSize: number,
	): Promise<JobPaymentsHistoryRecord[]> => {
		const url = `${COMPANIES}/${companyId}/transactions-report`;

		const queryParams = `?pageNumber=${pageNumber}&pageSize=${pageSize}&reportType=transactions`;
		return axios.get(url + queryParams);
	},
};

export default companiesApi;
