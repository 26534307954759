import React, { FC, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import OrderByFilterInterface from './order-by-filter.interface';
import IconButton from '../../../atoms/buttons/icon-button/IconButton';

import styles from './OrderByFilter.module.scss';
import { DefaultSortOrder, SortOrder, SortProperty } from '../../../../helpers/custom/common';
import {
	FilterParamsType,
	parseUrlParams,
	stringifyParamsForUrl,
} from '../../../../helpers/custom/url';
import { useLocation, useNavigate } from 'react-router-dom';

const OrderByFilter: FC<OrderByFilterInterface> = ({ orderBy, className, hidden }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [isArrowDown, setIsArrowDown] = useState(true);

	const onSortButtonClick = useCallback(() => {
		const filterParams = parseUrlParams<FilterParamsType>(location.search);

		let sortMethod = '';

		//default sorting change type
		if (
			(orderBy === SortProperty.score || orderBy === SortProperty.createdAt) &&
			!filterParams?.sort
		)
			sortMethod = SortOrder.asc;
		else if (filterParams?.sort?.startsWith(orderBy)) {
			if (filterParams?.sort?.endsWith(SortOrder.asc)) sortMethod = SortOrder.desc;
			else sortMethod = SortOrder.asc;
		} else
			sortMethod =
				DefaultSortOrder[orderBy as keyof typeof DefaultSortOrder] || SortOrder.desc;

		filterParams.sort = `${orderBy}+${sortMethod}`;

		navigate({
			pathname: location.pathname,
			search: stringifyParamsForUrl(filterParams),
		});

		if (sortMethod === DefaultSortOrder[orderBy as keyof typeof DefaultSortOrder]) {
			setIsArrowDown(true);
		} else {
			setIsArrowDown(false);
		}
	}, [location.pathname, location.search, navigate, orderBy]);

	useEffect(() => {
		const { sort } = parseUrlParams<FilterParamsType>(location.search);

		if (hidden) setIsArrowDown(true);
		else if (sort) {
			const sortProperty = sort.split(' ')[0];
			const sortOrder = sort.split(' ')[1];
			setIsArrowDown(
				sortOrder === DefaultSortOrder[sortProperty as keyof typeof DefaultSortOrder],
			);
		}
	}, [hidden, location.search]);

	return (
		<IconButton
			icon="chevron"
			className={classNames(styles['sort-icon'], isArrowDown ? '' : styles.open, className)}
			onClick={onSortButtonClick}
		/>
	);
};

export default OrderByFilter;
