import React, { FC, useMemo } from 'react';
import classNames from 'classnames';

import styles from './StepSectionWithProgress.module.scss';
import StepSectionWithProgressInterface from './step-section-with-progress.interface';
import Button from '../../../atoms/buttons/button/Button';
import Loader from '../../../molecules/loader';

const StepSectionWithProgress: FC<StepSectionWithProgressInterface> = ({
	className,
	lastStepNumber,
	stepNumber,
	stepName,
	prevTitle = 'Back',
	nextTitle = 'Next',
	prevStepAction,
	nextStepAction,
	showLoader,
	disableNextStep,
}) => {
	const disabledNextStep = useMemo(
		() => !!(disableNextStep || showLoader),
		[disableNextStep, showLoader],
	);

	return (
		<div className={classNames(styles['step-section-wrapper'], className)}>
			<div className={styles['step-section']}>
				<div className={styles['step-name']}>{stepName}</div>
				<div className={styles['step-numbers']}>
					{stepNumber}/{lastStepNumber}
				</div>

				<div className={styles.actions}>
					<Button
						type="button"
						minWidth={106}
						maxWidth={106}
						minHeight={40}
						maxHeight={40}
						reversedStyles
						className={styles.btn}
						onClick={prevStepAction}
					>
						{prevTitle}
					</Button>

					<Button
						type="button"
						minWidth={106}
						maxWidth={106}
						minHeight={40}
						maxHeight={40}
						className={classNames(styles.btn, disabledNextStep ? styles.disabled : '')}
						onClick={nextStepAction}
						disabled={disabledNextStep}
					>
						{showLoader ? (
							<Loader className={styles.loader} thin maxHeight={20} maxWidth={20} />
						) : (
							nextTitle
						)}
					</Button>
				</div>
			</div>

			<div
				className={styles['progress-bar']}
				style={{
					width: `${(100 / lastStepNumber) * stepNumber}%`,
					background: `linear-gradient(240deg, #37DBE0 ${-(
						100 -
						(stepNumber * 25 - 25)
					)}%, #4986A1 100%)`,
				}}
			></div>
		</div>
	);
};

export default StepSectionWithProgress;
