import { useCallback, useEffect, useState } from 'react';
import {
	initialPaginationState,
	PaginationState,
} from '../../../../components/molecules/pagination/pagination.interface';

export default function usePagination(
	// eslint-disable-next-line no-unused-vars
	getItemsHandler: (pageNumber: number) => Promise<any[] | undefined>,
	dependencyParameter: any,
	pageSize: number = 15,
) {
	const [paginationState, setPaginationState] = useState<PaginationState>(initialPaginationState);
	const [loading, setLoading] = useState(true);

	const getNextPage = useCallback(
		async (nextNumber?: number) => {
			if (paginationState.currentPage) {
				const nextPageNumber = nextNumber || paginationState.currentPage.pageNumber + 2;
				const nextPageItems = await getItemsHandler(nextPageNumber);

				return nextPageItems && nextPageItems.length > 0
					? {
							pageNumber: nextPageNumber,
							items: nextPageItems,
					  }
					: null;
			}

			return null;
		},
		[getItemsHandler, paginationState.currentPage],
	);

	const getPrevPage = useCallback(
		async (prevNumber?: number) => {
			if (paginationState.currentPage) {
				const prevPageNumber = prevNumber || paginationState.currentPage.pageNumber - 2;

				if (prevPageNumber === 0) return null;

				const prevPageItems = await getItemsHandler(prevPageNumber);

				return prevPageItems && prevPageItems.length > 0
					? {
							pageNumber: prevPageNumber,
							items: prevPageItems,
					  }
					: null;
			}

			return null;
		},
		[getItemsHandler, paginationState.currentPage],
	);

	const moveToNextPage = useCallback(async () => {
		setLoading(true);

		if (!paginationState) return;
		if (!paginationState.nextPage) return;
		if (!paginationState.currentPage || paginationState.currentPage.items.length < pageSize)
			return;

		setPaginationState((prevState: PaginationState) => {
			return {
				...prevState,
				prevPage: prevState.currentPage,
				currentPage: prevState.nextPage,
			};
		});

		const nextPage = await getNextPage();

		setPaginationState((prevState: PaginationState) => {
			return {
				...prevState,
				nextPage,
			};
		});

		setLoading(false);
	}, [getNextPage, pageSize, paginationState]);

	const moveToPrevPage = useCallback(async () => {
		setLoading(true);

		if (!paginationState) return;
		if (!paginationState.prevPage) return;

		if (paginationState.currentPage) {
			setPaginationState((prevState: PaginationState) => {
				return {
					...prevState,
					currentPage: prevState.prevPage,
					nextPage: prevState.currentPage,
				};
			});

			const prevPage = await getPrevPage();

			setPaginationState((prevState: PaginationState) => {
				return {
					...prevState,
					prevPage,
				};
			});
		}

		setLoading(false);
	}, [getPrevPage, paginationState]);

	useEffect(() => {
		(async () => {
			if (dependencyParameter) {
				const result = await getItemsHandler(1);

				if (result?.length) {
					setPaginationState({
						prevPage: null,
						currentPage: {
							pageNumber: 1,
							items: result,
						},
						nextPage: null,
					});
				}

				setLoading(false);
			}
		})();
	}, [dependencyParameter, getItemsHandler]);

	useEffect(() => {
		(async () => {
			if (
				paginationState.currentPage?.pageNumber === 1 &&
				paginationState.currentPage?.items.length
			) {
				setLoading(true);

				const nextPage = await getNextPage(2);

				//@ts-ignore
				setPaginationState((prevState) => {
					return {
						...prevState,
						nextPage,
					};
				});

				setLoading(false);
			}
		})();
	}, [
		getNextPage,
		paginationState.currentPage?.items.length,
		paginationState.currentPage?.pageNumber,
	]);

	return {
		paginationState,
		loading,
		moveToPrevPage,
		moveToNextPage,
	};
}
