import React, { FC, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import classNames from 'classnames';

import NestedPopupHeaderInterface from './nested-popup-header.interface';
import Logo from '../../../atoms/icons/logo';
import Title from '../../../atoms/title/Title';
import Close from '../../../atoms/close';
import Back from '../../../atoms/back';

import styles from './NestedPopupHeader.module.scss';
import ResizeDeviceContext from '../../../../contexts/resize-device-context';

const NestedPopupHeader: FC<NestedPopupHeaderInterface> = ({
	title,
	titleLogo,
	onBackClick,
	logo,
	className,
	close,
	backgroundColor,
	titleClassName,
	children,
	withoutLogo = false,
	withoutClose = false,
	...rest
}) => {
	const navigate = useNavigate();
	const closePopup = () => navigate(-1);

	const device = useContext(ResizeDeviceContext);

	const renderLogoSection = useMemo(() => {
		if (onBackClick) return <Back className={styles.back} onClick={onBackClick} />;

		if (children) return children;

		if (!withoutLogo)
			return (
				<Logo
					type={logo?.type || 'white'}
					className={classNames(styles.logo, logo?.className)}
				/>
			);

		return null;
	}, [children, logo?.className, logo?.type, onBackClick, withoutLogo]);

	const renderTitleSection = useMemo(() => {
		if (titleLogo)
			return (
				<div className={styles['title-logo']}>
					<img src={titleLogo}/>
				</div>
			);

		return (
			<Title
				component="h2"
				fontSize={device !== 'desktop' ? 20 : 31}
				className={classNames(styles.title, titleClassName)}
			>
				{title && ReactHtmlParser(title)}
			</Title>
		);
	}, [device, title, titleClassName, titleLogo]);

	return (
		<header
			style={{ backgroundColor }}
			className={classNames(
				styles['popup-header'],
				!onBackClick && styles['has-logo'],
				className,
			)}
			{...rest}
		>
			{renderLogoSection}

			{renderTitleSection}

			{!withoutClose ? (
				<Close className={styles.close} onClick={close || closePopup} />
			) : null}
		</header>
	);
};

export default NestedPopupHeader;
