import React, { FC, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getEinNumbers } from '../../../../store/slices/ein-numbers.slice';
import { RootStateType } from '../../../../store/reducers/root-reducer';
import ModalWindow from '../../modal-window/ModalWindow';
import FacilityScreenInterface from './facility-screen.interface';
import FacilityForm from '../facility-form/FacilityForm';
import useActiveCompany from '../../../../helpers/hooks/use-active-company/useActiveCompany';
import NestedPopupHeader from '../../popup-headers/nested-popup-header/NestedPopupHeader';
import Container from '../../../templates/container';
import Loader from '../../../molecules/loader';

import styles from './FacilityScreen.module.scss';
import ResizeDeviceContext from '../../../../contexts/resize-device-context';

const FacilityScreen: FC<FacilityScreenInterface> = ({
	hasCloseButton = false,
	setCreatedFacilityState,
	backHandler,
	close,
	facilityItem,
	open,
	...rest
}) => {
	const dispatch = useDispatch();
	const {
		einNumber,
		companies: { activeCompanyId, items: companies },
	} = useSelector((state: RootStateType) => state);

	const activeCompany = useActiveCompany(activeCompanyId, companies);
	const device = useContext(ResizeDeviceContext);

	useEffect(() => {
		if (open && activeCompany?.id && activeCompany?.hasWurk) {
			dispatch(getEinNumbers(activeCompany?.id));
		}
	}, [open, activeCompany, dispatch]);

	if (activeCompany?.hasWurk && einNumber?.loading === 'pending') {
		return <Loader wide />;
	}

	return (
		<ModalWindow
			{...{
				open:
					!!(open && activeCompany?.hasWurk && einNumber?.loading === 'fulfilled') ||
					(!activeCompany?.hasWurk && open),
				hasCloseButton,
				className: styles['facility-screen'],
				contentClassName: styles.content,
				...rest,
			}}
			close={close || backHandler}
		>
			<NestedPopupHeader
				backgroundColor="#525668"
				onBackClick={backHandler}
				title={facilityItem ? 'Edit Facility' : 'Add Facility'}
				{...{ close }}
			/>

			<Container
				maxWidth={device !== 'desktop' ? '95%' : '52.5%'}
				className={styles.container}
			>
				<FacilityForm {...{ setCreatedFacilityState, facilityItem, backHandler, close }} />
			</Container>
		</ModalWindow>
	);
};

export default React.memo(FacilityScreen);
