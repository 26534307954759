import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './CommuteScore.module.scss';
import CommuteScoreInterface from './commute-score.interface';

const CommuteScore: FC<CommuteScoreInterface> = ({
	className,
	scoreClass,
	extraSmallSize,
	smallSize,
	mediumSize,
	largeSize,
	text,
}) => {
	return (
		<div className={classNames(styles['commute-score'], className)}>
			<div
				className={classNames(
					styles['specific-emoji-commute-score'],
					className,
					scoreClass ? styles[`${scoreClass}`] : '',
					extraSmallSize ? styles['extra-small-size'] : '',
					smallSize ? styles['small-size'] : '',
					mediumSize ? styles['medium-size'] : '',
					largeSize ? styles['large-size'] : '',
				)}
			></div>

			{text ? <span className={styles.text}>{text}</span> : null}
		</div>
	);
};

export default CommuteScore;
