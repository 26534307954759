import React, { FC, useCallback, useContext, useMemo, useState } from 'react';
import classNames from 'classnames';
import Container from '../../../templates/container';
import CompanySettingsScreenInterface from './company-settings-screen.interface';
import EditableViewForm from '../../editable-view-form';

import Switcher from '../../../molecules/form/switcher';
import useActiveCompany from '../../../../helpers/hooks/use-active-company/useActiveCompany';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../../../store/reducers/root-reducer';
import { editCompany } from '../../../../store/slices/companies.slice';

import styles from './CompanySettingsScreen.module.scss';
import ResizeDeviceContext from '../../../../contexts/resize-device-context';

const CompanySettingsScreen: FC<CompanySettingsScreenInterface> = ({ className }) => {
	const dispatch = useDispatch();
	const {
		companies: { activeCompanyId, items },
	} = useSelector((state: RootStateType) => state);
	const activeCompany = useActiveCompany(activeCompanyId, items);

	const device = useContext(ResizeDeviceContext);

	const [sendRejectionEmail, setSendRejectionEmail] = useState(
		activeCompany?.sendEmailOnRejection,
	);

	const onSwitcherChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const switcherValue = event.currentTarget.checked;
			setSendRejectionEmail(switcherValue);

			dispatch(
				editCompany(activeCompanyId as string, { sendEmailOnRejection: switcherValue }),
			);
		},
		[activeCompanyId, dispatch],
	);

	const renderSwitcher = useMemo(() => {
		return (
			<div className={styles['content-wrapper']}>
				<div className={styles.switcher}>
					<Switcher
						id="sendEmail"
						label="Send email when rejecting candidates"
						inputSettings={{
							id: 'sendEmail',
							checked: sendRejectionEmail,
							onChange: onSwitcherChange,
						}}
					/>
				</div>
			</div>
		);
	}, [onSwitcherChange, sendRejectionEmail]);

	return (
		<Container
			maxWidth={device !== 'desktop' ? '95%' : '720px'}
			className={classNames(className, styles['company-settings'])}
		>
			<EditableViewForm
				addEditableViewFormItemClass={false}
				hasEditButton={false}
				sectionTitle="Communications"
			>
				{renderSwitcher}
			</EditableViewForm>
		</Container>
	);
};

export default React.memo(CompanySettingsScreen);
