import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import StatisticTopBar from '../../statistic-top-bar';
import { getCompanyStatistic } from '../../../../store/slices/companies.slice';
import Warnings from '../warnings/warnings';
import useCompanies from '../../../../helpers/hooks/use-companies/useCompanies';
import useJobs from '../../../../helpers/hooks/use-jobs/useJobs';
import useActiveCompany from '../../../../helpers/hooks/use-active-company/useActiveCompany';
import { checkJobIsBoosted } from '../../../../helpers/custom/job';

const CompanyStatisticTopBar: FC = () => {
	const dispatch = useDispatch();

	const { loading, companies, activeCompanyId, activeCompanyStatistic, activeCompanyCanBoost } =
		useCompanies();
	const { jobsItems, jobsLoading } = useJobs();

	const activeCompany = useActiveCompany(activeCompanyId, companies);

	const [showBoostCTA, setShowBoostCTA] = useState(false);
	//need to take into account only the first page of jobs
	const [boostCheckDone, setBoostCheckDone] = useState(false);

	const items = useMemo(
		() => [
			{
				number: activeCompanyStatistic?.activeJobsCount,
				text: 'Active job listings',
				numberColor: '#ee846d',
				loading,
			},
			{
				number: activeCompanyStatistic?.applicationsCount,
				text: 'Applicants',
				numberColor: '#70c9c2',
				loading,
			},
			{
				number: activeCompanyStatistic?.facilitiesCount,
				text: 'Facilities',
				numberColor: '#efd35e',
				loading,
			},
		],
		[activeCompanyStatistic, loading],
	);

	useEffect(() => {
		if (activeCompany && activeCompany.id) {
			dispatch(getCompanyStatistic(activeCompany.id));
		}
	}, [dispatch, activeCompany]);

	useEffect(() => {
		if (!jobsLoading && !boostCheckDone) {
			if (jobsItems && jobsItems.length) {
				const hasBoostedJobs = jobsItems.some((job) => {
					const isBoosted = checkJobIsBoosted(job?.boostId, job?.jobBoosts, job?.status);
					return isBoosted;
				});

				setBoostCheckDone(true);
				setShowBoostCTA(!hasBoostedJobs);
				return;
			}

			setBoostCheckDone(true);
			setShowBoostCTA(true);
		}
	}, [jobsItems, jobsLoading, boostCheckDone]);

	useEffect(() => {
		setBoostCheckDone(false);
		setShowBoostCTA(false);
	}, [activeCompanyId]);

	const showLearnAboutBoostCTA = useMemo(
		() =>
			!!(
				activeCompanyCanBoost &&
				process.env.REACT_APP_BOOST_KNOWLEDGE_BASE_URL &&
				showBoostCTA
			),
		[activeCompanyCanBoost, showBoostCTA],
	);

	return (
		<>
			<Warnings showLearnAboutBoostCTA={showLearnAboutBoostCTA} />

			<StatisticTopBar items={items} />
		</>
	);
};

export default CompanyStatisticTopBar;
