import React, { FC, useEffect, useRef } from 'react';
import classNames from 'classnames';

import InfiniteScrollInterface from './infinite-scroll.interface';
import Loader from '../../molecules/loader';

import styles from './InfiniteScroll.module.scss';

const InfiniteScroll: FC<InfiniteScrollInterface> = ({
	refreshFunction,
	blockScrollingHandling,
	loading,
	firstLoad,
	displayLoader = true,
	scrollDependances = [],
}) => {
	const InfiniteScrollRef = useRef<HTMLSpanElement>(null);

	const isBottom = () => {
		if (
			InfiniteScrollRef &&
			InfiniteScrollRef.current &&
			Math.round(
				InfiniteScrollRef.current.getBoundingClientRect().top +
					InfiniteScrollRef.current.getBoundingClientRect().height,
			) === window.innerHeight &&
			!loading &&
			!blockScrollingHandling
		) {
			refreshFunction();
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', isBottom);

		return () => window.removeEventListener('scroll', isBottom);
		// eslint-disable-next-line
	}, [...scrollDependances, loading]);

	return (
		<span ref={InfiniteScrollRef} className={styles['infinite-scroll']}>
			{loading && displayLoader ? (
				<Loader className={classNames(firstLoad && styles.loader)} />
			) : null}
		</span>
	);
};

export default React.memo(InfiniteScroll);
