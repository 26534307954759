import React, { FC } from 'react';

import useUtmSource from '../../hooks/custom/use-utm-source/useUtmSource';
import BrowserStorageCrudHocInterface from './browser-storage-crud-hoc.interface';
import useCookies from '../../hooks/custom/use-cookies';
import { useCompanyFromUrl } from '../../hooks/custom/use-company-from-url/useCompanyFromUrl';

const BrowserStorageCrudHoc: FC<BrowserStorageCrudHocInterface> = ({ children }) => {
	useUtmSource();
	useCompanyFromUrl();
	useCookies();

	return <>{children}</>;
};

export default BrowserStorageCrudHoc;
