import React, { FC } from 'react';

import RadioGroupInterface from './radio-group.interface';
import FormItem from '../../../molecules/form/form-item/FormItem';
import Radio from '../../../molecules/form/radio';
import RadioInterface from '../../../molecules/form/radio/radio.interface';

import styles from './RadioGroup.module.scss';
import classNames from 'classnames';

const RadioGroup: FC<RadioGroupInterface> = React.forwardRef(
	({ name, radioButtons, className }, ref) => {
		const radioItems =
			radioButtons &&
			radioButtons.length &&
			radioButtons.map(({ id, ...rest }: Omit<RadioInterface, 'name'>) => {
				return (
					<Radio
						key={id}
						className={classNames(styles.radio)}
						{...{ name, id, ref, ...rest }}
					/>
				);
			});

		return <>{radioItems && <FormItem className={className}>{radioItems}</FormItem>}</>;
	},
);

export default React.memo(RadioGroup);
