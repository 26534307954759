import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';

import { getMinMaxWidthStyles } from '../../../../helpers';
import InputWithFloatLabelInterface from './input-with-float-label.interface';
import Icon from '../../../atoms/icons/icon';
import Label from '../../../atoms/form/label/Label';
import Error from '../../../molecules/ui/error';
import FormItem from '../../../atoms/form/form-item/FormItem';
import InputWithSpinner from '../input-with-spinner/InputWithSpinner';
import DatePickerField from '../../date-picker-field';

import styles from './InputWithLabel.module.scss';
import 'react-datepicker/dist/react-datepicker.css';

const InputWithFloatLabel: FC<InputWithFloatLabelInterface> = React.forwardRef(
	(
		{
			id,
			label,
			error,
			hasErrorStyles,
			labelSettings,
			inputSettings,
			iconSettings,
			minWidth,
			visibleDisableStyles = true,
			maxWidth,
			className,
			disabled,
			iconBefore,
			iconAfter,
			loading,
			required,
			cancelLabelState,
			...rest
		},
		ref,
	) => {
		const [isLabelUp, setLabelUp] = useState<boolean>(false);
		const inlineStyles = getMinMaxWidthStyles(minWidth, maxWidth);
		const [dirtyState, setDirtyState] = useState(false);

		useEffect(() => {
			if (
				inputSettings?.defaultValue ||
				inputSettings?.value ||
				(inputSettings?.type === 'date' && required)
			) {
				liftLabelUp();
			}
		}, [inputSettings, required]);

		useEffect(() => {
			if (cancelLabelState) {
				liftLabelDown();
			}
		}, [cancelLabelState, inputSettings]);

		useEffect(() => {
			setDirtyState(true);
		}, []);

		const liftLabelDown = () => {
			setLabelUp(false);
		};

		const liftLabelUp = () => {
			setLabelUp(true);
		};

		const onInputBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
			if (event.target.value.length) {
				liftLabelUp();
			} else {
				liftLabelDown();
			}
		};

		return (
			<FormItem
				style={inlineStyles}
				className={classNames(
					styles['form-item'],
					isLabelUp && styles['up-label'],
					iconBefore && styles['has-icon-before'],
					iconBefore || iconAfter ? styles['has-icon'] : '',
					iconAfter && styles['has-icon-after'],
					error && styles['error__wrap'],
					hasErrorStyles && styles['error__wrap'],
					inputSettings?.disabled || disabled ? styles.disabled : '',
					visibleDisableStyles && styles['visible-disable-styles'],
					!dirtyState && styles['first-render'],
					className,
				)}
				data-testid="input-with-float-label"
				{...rest}
			>
				{iconBefore && (
					<Icon
						icon={iconBefore}
						className={classNames(styles.icon, styles.before)}
						{...iconSettings}
					/>
				)}

				{inputSettings?.type === 'date' ? (
					<DatePickerField
						onFocus={liftLabelUp}
						onBlur={onInputBlurHandler}
						{...{ ref, required, inputSettings }}
					/>
				) : (
					<InputWithSpinner
						{...{
							id,
							loading,
							ref,
							...{
								inputSettings: {
									...inputSettings,
									disabled: disabled || inputSettings?.disabled,
									visibleDisableStyles,
								},
							},
						}}
						onFocus={liftLabelUp}
						onBlur={onInputBlurHandler}
					/>
				)}

				<Label
					htmlFor={id}
					{...{
						...labelSettings,
						className: classNames(
							styles.label,
							iconBefore && styles['label-has-icon-before'],
							iconAfter && styles['label-has-icon-after'],
						),
					}}
				>
					{label}
				</Label>

				{iconAfter && <Icon icon={iconAfter} className={styles.icon} {...iconSettings} />}

				<Error className={styles.error}>{error}</Error>
			</FormItem>
		);
	},
);

export default React.memo(InputWithFloatLabel);
