import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import styles from './ValidateEmailStep.module.scss';
import InputWithFloatLabel from '../../../components/organisms/form-elemets/input-with-float-label/InputWithFloatLabel';
import { requireValidation } from '../../../helpers/forms/forms';
import Button from '../../../components/atoms/buttons/button/Button';
import ValidateEmailStepInterface from './validate-email-step.interface';
import Title from '../../../components/atoms/title/Title';
import TypographyText from '../../../components/atoms/ui/typography-text';
import ButtonLink from '../../../components/atoms/buttons/button-link';
import useAuthorization from '../../../helpers/hooks/use-authorization/useAuthorization';
import { useDispatch } from 'react-redux';
import {
	loginWithEmailAndCode,
	sendValidationCode,
} from '../../../store/slices/authorization.slice';
import useExternalFormErrors from '../../../helpers/hooks/use-external-form-errors/useExternalFormErrors';
import Loader from '../../../components/molecules/loader';
import { getAvailableCompanies } from '../../../store/slices/companies.slice';
import { ExternalErrorType } from '../../../helpers/axios/axios-global';
import { AutoLoginActions, TokenTypes } from '../../../helpers/custom/common';
import { useLocation } from 'react-router-dom';
import { APP_URLS } from '../../../helpers/routes/routes';
import classNames from 'classnames';
import { resendCodeGA4Event, validatedGA4Event } from '../../../helpers/google-analytics-4/events';
import {
	GAContentGroup,
	GAContentId,
	GAContentType,
} from '../../../helpers/google-analytics-4/index.constants';
import { AppProducts, QueryParametersKey } from '../../../helpers/constants';

const ValidateEmailStep: FC<ValidateEmailStepInterface> = ({ brandingName }) => {
	const dispatch = useDispatch();
	const location = useLocation();

	const { register, errors, handleSubmit } = useForm();
	const { info: authInfo } = useAuthorization();

	const [code, setCode] = useState('');
	const [loadingState, setLoadingState] = useState(false);
	const [externalErrors, setExternalErrors] = useState<Partial<ExternalErrorType> | null>();
	const [isResendClicked, setIsResendClicked] = useState(false);

	const normalizedExternalErrors = useExternalFormErrors(externalErrors);

	const onFormSubmitHandler = useCallback(
		async (data: { code: string }) => {
			setLoadingState(true);

			if (authInfo.email && data.code) {
				const loginResult = await dispatch(
					loginWithEmailAndCode(authInfo.email, Number(data.code), 1),
				);

				//@ts-ignore
				if (loginResult?.errors) {
					setExternalErrors(loginResult as Partial<ExternalErrorType>);
					setLoadingState(false);
				} else {
					//@ts-ignore
					if (loginResult?.token) {
						validatedGA4Event(
							{
								contentData: {
									id: GAContentId.postAJob,
									group: GAContentGroup.postAJob,
									type: GAContentType.validateEmail,
								},
							},
							brandingName,
						);

						await dispatch(getAvailableCompanies());

						if (location.search.includes(QueryParametersKey.autologin)) {
							const urlConfigs = new URLSearchParams(location.search);

							urlConfigs.delete(QueryParametersKey.autologin);
							urlConfigs.delete(QueryParametersKey.action);

							const paramsStr = urlConfigs.toString();

							window.history.replaceState(
								null,
								'',
								`${APP_URLS.signup}${paramsStr ? '?' : ''}${paramsStr}`,
							);
						}

						setLoadingState(false);
					}
				}
			}
		},
		[authInfo.email, brandingName, dispatch, location.search],
	);

	const btnIsDisabled = useMemo(() => !!(!code || loadingState), [code, loadingState]);

	const renderTopSection = useMemo(() => {
		return (
			<>
				<Title
					component="h2"
					marginBottom={24}
					fontSize={48}
					fontWeight={300}
					className={styles.title}
				>
					Validate email
				</Title>

				<TypographyText className={styles.desc}>
					Enter the code sent to <strong>{authInfo?.email}</strong> below or click on the
					link in the email to continue.
				</TypographyText>
			</>
		);
	}, [authInfo?.email]);

	const renderRemarkSection = useMemo(() => {
		const resendValidationCode = () => {
			if (authInfo.email) {
				setIsResendClicked(true);

				resendCodeGA4Event(
					{
						contentData: {
							id: GAContentId.postAJob,
							group: GAContentGroup.postAJob,
							type: GAContentType.validateEmail,
						},
					},
					brandingName,
				);

				const autologinData = {
					tokenType: TokenTypes.validationCode,
					action: AutoLoginActions.signupValidation,
					destinationUrl: `${APP_URLS.signup}${location.search}`,
					product: AppProducts.postJob,
				};

				dispatch(sendValidationCode({ email: authInfo.email }, autologinData));
			}
		};

		return (
			<div className={styles.remark}>
				Didn't get the code?{' '}
				<ButtonLink
					type="button"
					className={classNames(styles.link, isResendClicked ? styles.clicked : '')}
					onClick={resendValidationCode}
				>
					Resend email
				</ButtonLink>
			</div>
		);
	}, [authInfo.email, brandingName, dispatch, isResendClicked, location.search]);

	const renderForm = useMemo(() => {
		return (
			<form className={styles.form} onSubmit={handleSubmit(onFormSubmitHandler)}>
				<section className={styles.inputs}>
					<InputWithFloatLabel
						minWidth="100%"
						ref={(event) => {
							register(event, {
								...requireValidation(),
							});
						}}
						inputSettings={{
							type: 'number',
							name: 'code',
							onChange: (event: any) => setCode(event.target.value),
							defaultValue: code || '',
						}}
						error={
							errors?.code?.message || normalizedExternalErrors?.errors?.code || ''
						}
						id="code"
						label="Code"
					/>
				</section>

				{renderRemarkSection}

				<Button
					className={classNames(styles.btn, btnIsDisabled ? styles.disabled : '')}
					disabled={btnIsDisabled}
					type="submit"
					small
					minWidth={260}
					minHeight={56}
				>
					{loadingState ? <Loader thin maxHeight={20} maxWidth={20} /> : 'Continue'}
				</Button>
			</form>
		);
	}, [
		code,
		btnIsDisabled,
		errors?.code?.message,
		handleSubmit,
		loadingState,
		normalizedExternalErrors?.errors?.code,
		onFormSubmitHandler,
		register,
		renderRemarkSection,
	]);

	useEffect(() => {
		if (isResendClicked) {
			setTimeout(() => setIsResendClicked(false), 1500);
		}
	}, [isResendClicked]);

	useEffect(() => {
		if (authInfo?.code) {
			setCode(authInfo.code.toString());
		}
	}, [authInfo?.code]);

	return (
		<div className={styles.validation}>
			{renderTopSection}

			{renderForm}
		</div>
	);
};

export default ValidateEmailStep;
