import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';

import Button from '../../../atoms/buttons/button/Button';

import styles from './BoostJobForm.module.scss';
import BoostJobFormInterface from './boost-job-form.interface';
import SelectWithFloatLabel from '../../form-elemets/select-with-float-label/SelectWithFloatLabel';
import { boostJob, BoostJobDataInterface, clearJob } from '../../../../store/slices/job.slice';
import { requireValidation } from '../../../../helpers/forms/forms';
import InfoPopover from '../../../molecules/ui/info-popover';
import ExternalLink from '../../../atoms/navigation/external-link/ExternalLink';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Container from '../../../templates/container';
import Loader from '../../../molecules/loader';
import {
	CREDITS_MAX_VALUE,
	MAX_OPENINGS_NUMBER,
	CREDITS_MIN_VALUE,
	CREDITS_CALCULATIONS_STEP,
	StorageItemKeys,
} from '../../../../helpers/constants';
import CreditsIncrementer from '../../../molecules/custom/credits-incrementer';
import { getActiveCompanyBalance } from '../../../../store/slices/companies.slice';
import { calculateRecommendedCredits } from '../../../../helpers/custom/calculations';
import {
	addCreditsIntentGA4Event,
	boostJobGA4Event,
	learnMoreGA4Event,
} from '../../../../helpers/google-analytics-4/events';
import {
	getCompanyDataForGA,
	getJobDataForGA,
} from '../../../../helpers/google-analytics-4/helpers';
import useCompanies from '../../../../helpers/hooks/use-companies/useCompanies';
import useActiveCompany from '../../../../helpers/hooks/use-active-company/useActiveCompany';
import { GAItemBrand, GAMethod } from '../../../../helpers/google-analytics-4/index.constants';
import { getContentDataForGA } from '../../../../helpers/custom/common';
import { APP_URLS } from '../../../../helpers/routes/routes';
import ResizeDeviceContext from '../../../../contexts/resize-device-context';
import { generateNumberOptions } from '../../../../helpers/custom/data-options/options';

const BoostJobForm: FC<BoostJobFormInterface> = ({
	className,
	formTitle,
	job,
	companyId,
	companyBalance = 0,
	boostLoading,
	containerClassName,
	customSuccessHandler,
	doneClickHandler,
	onValuesChange,
	...rest
}) => {
	const { id: jobId, numberOfOpenings } = job || {};

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const { activeCompanyId, companies } = useCompanies();
	const activeCompany = useActiveCompany(activeCompanyId, companies);

	const { register, handleSubmit, errors } = useForm();

	const [boostSuccess, setBoostSuccess] = useState(false);
	const [boostError, setBoostError] = useState(false);

	const [creditsValue, setCreditsValue] = useState(CREDITS_MIN_VALUE);
	const [openingsValue, setOpeningsValue] = useState<number>();
	const [recommendedCreditsValue, setRecommendedCreditsValue] =
		useState(CREDITS_CALCULATIONS_STEP);

	const device = useContext(ResizeDeviceContext);

	const formSubmitHandler = useCallback(async () => {
		const baseData = {
			jobId,
			numberOfOpenings: Number(openingsValue),
			creditsAmount: creditsValue,
		} as BoostJobDataInterface;

		try {
			await dispatch(boostJob({ ...baseData }));
			setBoostSuccess(true);

			dispatch(clearJob());
		} catch (error) {
			setBoostError(true);
		}
	}, [creditsValue, dispatch, jobId, openingsValue]);

	const getOpeningsOptions = useMemo(() => generateNumberOptions(MAX_OPENINGS_NUMBER), []);

	const saveBoostDataToStorage = useCallback((data: { openings?: number; credits?: number }) => {
		const { openings, credits } = data || {};

		if (openings) sessionStorage.setItem(StorageItemKeys.boostOpenings, openings?.toString());
		if (credits) sessionStorage.setItem(StorageItemKeys.boostCredits, credits?.toString());
	}, []);

	const renderRecommendationsInfoBoxContent = useMemo(() => {
		const boostKnowledgeBaseUrl = process.env.REACT_APP_BOOST_KNOWLEDGE_BASE_URL;

		const triggerLearnMoreGA4Event = () => {
			learnMoreGA4Event(
				{
					jobData: getJobDataForGA(job),
					companyData: getCompanyDataForGA(activeCompany),
					contentData: getContentDataForGA(location),
				},
				{
					itemBrand: GAItemBrand.sparkBoost,
					method: GAMethod.popup,
				},
			);
		};

		return (
			<div className={styles['info-content']}>
				<div className={styles.title}>About Boost recommendations</div>

				<div className={styles.text}>
					The recommended number of SparkBoost credits directly corresponds to the number
					of openings you have. SparkBoost works by elevating the visibility of your job
					postings, increasing the number of applications and your chances of finding the
					right candidates.
				</div>

				{boostKnowledgeBaseUrl ? (
					<ExternalLink
						className={styles['learn-more']}
						href={boostKnowledgeBaseUrl}
						target="_blank"
						rel="noopener"
						onClick={triggerLearnMoreGA4Event}
					>
						Learn more
					</ExternalLink>
				) : null}
			</div>
		);
	}, [activeCompany, job, location]);

	const renderNotEnoughCreditsSection = useMemo(() => {
		return (
			<div className={styles['contact-section']}>
				<div className={classNames(styles.text, styles['with-popup'])}>
					We recommend more than {CREDITS_MAX_VALUE} credits to boost this job.
					<InfoPopover
						className={classNames(styles['info-box'], styles.inline)}
						defaultTrigger
						contentClassName={styles['popover-width']}
					>
						{renderRecommendationsInfoBoxContent}
					</InfoPopover>
				</div>

				<div className={styles.title}>Great. Let's get you the best Boost</div>

				<div className={classNames(styles.text, styles['with-popup'])}>
					Given the number of openings, we recommend you{' '}
					<a href="mailto:sales@engin.co" className={styles.link}>
						contact
					</a>{' '}
					our customer care team so we can facilitate the best Boost for your needs.
				</div>
			</div>
		);
	}, [renderRecommendationsInfoBoxContent]);

	const triggerCreditsIntentGA4Event = useCallback(() => {
		addCreditsIntentGA4Event(
			{
				jobData: getJobDataForGA(job),
				companyData: getCompanyDataForGA(activeCompany),
				contentData: getContentDataForGA(location),
			},
			{
				method: GAMethod.button,
				needMoreCredits: creditsValue > companyBalance,
				recommendedCredits: recommendedCreditsValue,
			},
		);
	}, [activeCompany, companyBalance, creditsValue, job, location, recommendedCreditsValue]);

	const renderMainCreditsSection = useMemo(() => {
		return (
			<>
				<div className={styles['form-item']}>
					<div className={styles.text}>
						We recommend {recommendedCreditsValue} credits to boost your job.
					</div>

					<div className={classNames(styles.text, styles['with-popup'], styles.flex)}>
						Adjust amount of Credits below.{' '}
						<InfoPopover
							className={styles['info-box']}
							defaultTrigger
							contentClassName={styles['popover-width']}
						>
							{renderRecommendationsInfoBoxContent}
						</InfoPopover>
					</div>
				</div>

				<div className={styles['credits']}>
					<CreditsIncrementer
						onValueChange={(value) => {
							saveBoostDataToStorage({
								credits: value,
							});
							setCreditsValue(value);
						}}
						creditsValue={creditsValue}
					/>
				</div>

				<div className={classNames(styles.text, styles.small)}>
					{creditsValue > companyBalance ? (
						<div className={styles.error}>
							You do not currently have enough SparkBoost credits.
						</div>
					) : null}
					<span className={styles.highlighted}>{companyBalance}</span> currently
					available. Need more?{' '}
					<Link
						to={APP_URLS.addCredits}
						state={{ hasHistoryBackContext: true, fromBoostFlow: true }}
						className={styles.link}
						onClick={triggerCreditsIntentGA4Event}
					>
						Add credits
					</Link>{' '}
					now.
				</div>
			</>
		);
	}, [
		companyBalance,
		creditsValue,
		recommendedCreditsValue,
		renderRecommendationsInfoBoxContent,
		saveBoostDataToStorage,
		triggerCreditsIntentGA4Event,
	]);

	const renderBoostForm = useMemo(() => {
		return (
			<form
				className={classNames(styles.form, className)}
				onSubmit={handleSubmit(formSubmitHandler)}
				{...rest}
			>
				<div className={styles.title}>
					{formTitle || 'Need more candidates? Boost your job now.'}
				</div>

				<div className={styles['form-item']}>
					<span className={classNames(styles['item-part'], styles.text)}>
						This job has
					</span>

					<SelectWithFloatLabel
						className={classNames(
							'form-item',
							'disable-bottom-margin',
							styles['item-part'],
							styles.select,
						)}
						minWidth="37px"
						maxWidth="37px"
						ref={register(requireValidation())}
						error={errors.state && '"Number Of Openings" is a required field'}
						selectSettings={{
							options: getOpeningsOptions,
							name: 'numberOfOpenings',
							value: openingsValue,
							defaultValue: openingsValue,
							onChange: (e: any) => {
								setOpeningsValue(e.target.value);

								saveBoostDataToStorage({
									openings: e.target.value,
								});
							},
						}}
						iconClassName={styles['select-icon']}
						id="numberOfOpenings"
						label=""
					/>

					<span className={classNames(styles['item-part'], styles.text)}>
						opening(s).
					</span>
				</div>

				{recommendedCreditsValue <= CREDITS_MAX_VALUE
					? renderMainCreditsSection
					: renderNotEnoughCreditsSection}

				<Button
					className={styles.btn}
					minWidth={device !== 'desktop' ? 150 : 272}
					maxWidth={272}
					disabled={
						creditsValue > CREDITS_MAX_VALUE ||
						creditsValue > companyBalance ||
						boostLoading
					}
				>
					{boostLoading ? <Loader thin maxHeight={20} maxWidth={20} /> : 'Boost job'}
				</Button>
			</form>
		);
	}, [
		boostLoading,
		className,
		companyBalance,
		creditsValue,
		device,
		errors.state,
		formSubmitHandler,
		formTitle,
		getOpeningsOptions,
		handleSubmit,
		openingsValue,
		recommendedCreditsValue,
		register,
		renderMainCreditsSection,
		renderNotEnoughCreditsSection,
		rest,
		saveBoostDataToStorage,
	]);

	const renderSuccessMessage = useMemo(() => {
		const onDoneClick = () => {
			if (doneClickHandler) return doneClickHandler();

			const hasHistoryBackContext =
				//@ts-ignore
				location?.state?.hasHistoryBackContext;

			if (hasHistoryBackContext) return navigate(-1);
			return navigate(`/jobs/${jobId}`);
		};

		return (
			<div className={styles['success-message']}>
				<div className={styles.title}>Success!</div>

				<div className={styles.description}>
					Your job is now being boosted. You can anticipate an increase in candidates in
					the upcoming days.
				</div>

				<Button className={styles.btn} onClick={onDoneClick}>
					Done
				</Button>
			</div>
		);
	}, [doneClickHandler, jobId, location?.state, navigate]);

	const renderErrorMessage = useMemo(() => {
		return (
			<div className={styles['error-message']}>
				<div className={styles.title}>Oops!</div>

				<div className={styles.description}>
					Something went wrong. Please try again later.
				</div>
			</div>
		);
	}, []);

	const renderContent = useMemo(() => {
		if (boostSuccess) {
			if (customSuccessHandler) {
				customSuccessHandler();
				return null;
			}
			return renderSuccessMessage;
		}

		if (boostError) return renderErrorMessage;

		return renderBoostForm;
	}, [
		boostError,
		boostSuccess,
		customSuccessHandler,
		renderBoostForm,
		renderErrorMessage,
		renderSuccessMessage,
	]);

	useEffect(() => {
		if (boostSuccess) {
			boostJobGA4Event(
				{
					jobData: getJobDataForGA(job),
					companyData: getCompanyDataForGA(activeCompany),
					contentData: getContentDataForGA(location),
				},
				{
					numberOfOpenings: openingsValue,
					appliedCredits: creditsValue,
					recommendedCredits: recommendedCreditsValue,
				},
			);
		}
	}, [
		activeCompany,
		boostSuccess,
		creditsValue,
		job,
		location,
		openingsValue,
		recommendedCreditsValue,
	]);

	useEffect(() => {
		if (onValuesChange)
			onValuesChange({
				recommendedCredits: recommendedCreditsValue,
				needMoreCredits: creditsValue > companyBalance,
				numberOfOpenings: openingsValue,
			});
	}, [recommendedCreditsValue, creditsValue, openingsValue, onValuesChange, companyBalance]);

	useEffect(() => {
		if (companyId) {
			dispatch(getActiveCompanyBalance(companyId));
		}
	}, [companyId, dispatch]);

	useEffect(() => {
		if (openingsValue) {
			const value = calculateRecommendedCredits(Number(openingsValue));

			setRecommendedCreditsValue(value);
			setCreditsValue(value);
			saveBoostDataToStorage({
				credits: value,
			});
		}
	}, [openingsValue, saveBoostDataToStorage]);

	useEffect(() => {
		//StorageClearHoc clears sessionStorage when navigating to other pages

		const savedOpenings = sessionStorage.getItem(StorageItemKeys.boostOpenings);
		const savedCredits = sessionStorage.getItem(StorageItemKeys.boostCredits);

		if (savedOpenings && savedCredits) {
			setOpeningsValue(Number(savedOpenings));
			setCreditsValue(Number(savedCredits));

			return;
		}

		const openingsChecked = numberOfOpenings && numberOfOpenings > 10 ? 10 : numberOfOpenings;

		const openings = openingsChecked || 1;
		const credits = creditsValue || 100;

		setOpeningsValue(openings);
		setCreditsValue(credits);

		saveBoostDataToStorage({ openings, credits });
	}, [creditsValue, numberOfOpenings, saveBoostDataToStorage]);

	const isFinalPopup = useMemo(() => !!(boostError || boostSuccess), [boostError, boostSuccess]);

	return (
		<Container
			maxWidth={isFinalPopup ? 368 : 383}
			className={classNames(styles.content, containerClassName)}
		>
			{renderContent}
		</Container>
	);
};

export default BoostJobForm;
