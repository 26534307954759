import { Dispatch } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import authorizationApi from '../../api/authorization.api';
import { getUserInfoFailed, setUserToken, UserInfoInterface } from './user.slice';
import { RootStateType } from '../reducers/root-reducer';
import { ExternalErrorType } from '../../helpers/axios/axios-global';

export type AutoLoginData = {
	tokenType?: number;
	action: string;
	destinationUrl: string;
	product?: string;
	originTag?: string;
};

export interface AuthorizationSliceInterface {
	loading?: boolean;
	needTerms?: boolean;
	error?: string;
	info: Partial<SignUpInterface>;
}

export interface SignUpResultInterface {
	token: string;
	user: Partial<UserInfoInterface>;
}

export interface AuthorizationInfoInterface {
	token: string;
}

export interface AuthorizationPayloadInterface {
	email: string;
	password: string;
	code: number;
	acceptedCustomerTerms: 1 | 0;
}

export interface SignUpInterface extends AuthorizationPayloadInterface, AutoLoginData {
	firstName: string;
	lastName: string;
	originTag?: string;
	product?: string;
}

const initialState: AuthorizationSliceInterface = {
	loading: false,
	needTerms: false,
	info: {},
};

export const authorizationSlice = createSlice({
	name: 'authorization',
	initialState,
	reducers: {
		loading: (state) => {
			state.loading = true;
		},
		loaded: (state) => {
			state.loading = false;
		},
		getAuthorizationInfoSuccess: (state) => {
			state.loading = false;
			state.error = '';
		},
		getAuthorizationInfoFailed: (state, { payload }: PayloadAction<string>) => {
			state.loading = false;
			state.error = payload;
		},
		getAuthorizationNeedTermsSuccess(state, { payload }: any) {
			state.needTerms = payload.needTerms;
			state.loading = false;
		},
		getAuthorizationNeedTermsFailed(state, action: PayloadAction<string>) {
			state.loading = false;
			console.log(action);
		},
		setAuthorizationEmailSuccess(state, { payload }: PayloadAction<string>) {
			state.info!.email = payload;
		},
		setAuthorizationCodeSuccess(state, { payload }: PayloadAction<number>) {
			state.info!.code = payload;
		},
		setAuthorizationInfoSuccess(state, { payload }: PayloadAction<Partial<SignUpInterface>>) {
			state.info = payload;
		},
	},
});

export const getAuthorizationNeedTerms = (email: string) => async (dispatch: Dispatch<any>) => {
	dispatch(loading());

	try {
		const response = await authorizationApi.getAuthorizationNeedTerms(email);

		dispatch(getAuthorizationNeedTermsSuccess(response));
	} catch (error) {
		dispatch(getAuthorizationNeedTermsFailed(error.toString()));
	}
};

export const sendValidationCode =
	(data: { email: string }, autologinData: AutoLoginData) => async (dispatch: Dispatch<any>) => {
		dispatch(loading());

		try {
			await authorizationApi.sendValidationCode({
				...data,
				...autologinData,
			});

			dispatch(setAuthorizationEmailSuccess(data.email));
			dispatch(loaded());
		} catch (error) {
			dispatch(loaded());
			dispatch(getUserInfoFailed(error.toString()));
		}
	};

export const sendSignUpValidationCode =
	(data: Partial<SignUpInterface>, autologinData: AutoLoginData) =>
	async (dispatch: Dispatch<any>) => {
		dispatch(loading());

		try {
			const response = await authorizationApi.sendSignUpValidationCode({
				...data,
				...autologinData,
			});

			dispatch(setAuthorizationInfoSuccess(data));
			dispatch(loaded());

			return response;
		} catch (error) {
			dispatch(loaded());
			return error;
		}
	};

export const setAuthorizationEmail = (email: string) => async (dispatch: Dispatch<any>) => {
	try {
		return dispatch(setAuthorizationEmailSuccess(email));
	} catch (error) {
		return error;
	}
};

export const signUp =
	(code: number) => async (dispatch: Dispatch<any>, getState: () => RootStateType) => {
		try {
			const data = getState().authorization.info;

			const response = await authorizationApi.signUp({ ...data, code });

			//@ts-ignore
			if (response?.token) {
				//@ts-ignore
				dispatch(setUserToken(response?.token));

				return response as SignUpResultInterface;
			}

			return response as Partial<ExternalErrorType>;
		} catch (error) {
			return error;
		}
	};

export const validateCode =
	(data: { code: number }) => async (dispatch: Dispatch<any>, getState: () => any) => {
		const state = getState();
		dispatch(loading());

		try {
			await authorizationApi.validateCode({ email: state.authorization.info.email, ...data });

			dispatch(setAuthorizationCodeSuccess(data.code));
			dispatch(loaded());
		} catch (error) {
			dispatch(loaded());
			dispatch(getUserInfoFailed(error.toString()));
			throw error;
		}
	};

export const loginWithCode =
	(data: { password: string; email?: string; validationCode?: string }) =>
	async (dispatch: Dispatch<any>, getState: () => any) => {
		const state = getState();
		dispatch(loading());

		try {
			let result: any;

			if (state.authorization.info.email) {
				result = await authorizationApi.loginWithCodeThe({
					email: state.authorization.info.email,
					acceptedCustomerTerms: 1,
					code: Number(state.authorization.info.code),
					...data,
				});
			} else if (data.email && data.validationCode) {
				result = await authorizationApi.loginWithCodeThe({
					email: data.email,
					acceptedCustomerTerms: 1,
					code: Number(data.validationCode),
					...data,
				});
			}

			if (result) {
				dispatch(getAuthorizationInfoSuccess());
				dispatch(setUserToken(result.token));
			}

			dispatch(loaded());
		} catch (error) {
			dispatch(loaded());

			if (error) {
				dispatch(getUserInfoFailed(error.toString()));
			}

			throw error;
		}
	};

export const loginWithEmailAndCode =
	(email: string, code: number, acceptedCustomerTerms?: number) => async (dispatch: Dispatch<any>) => {
		dispatch(loading());

		try {
			const result = await authorizationApi.loginWithEmailAndCode({
				email,
				code,
				acceptedCustomerTerms,
			});

			if (result) {
				dispatch(getAuthorizationInfoSuccess());
				dispatch(setUserToken(result.token));

				return { token: result.token };
			}
		} catch (error) {
			return error;
		} finally {
			dispatch(loaded());
		}
	};

export const getAuthorizationInfo =
	(data: Partial<AuthorizationPayloadInterface>) => async (dispatch: Dispatch<any>) => {
		dispatch(loading());

		try {
			const { token } = await authorizationApi.getAuthorizationInfo(data);

			if (token) {
				dispatch(getAuthorizationInfoSuccess());
				dispatch(setUserToken(token));
			} else {
				dispatch(getAuthorizationInfoFailed('Email or password is incorrect'));
			}
		} catch (error) {
			dispatch(getAuthorizationInfoFailed('Email or password is incorrect'));
		}
	};

export const quickSignUp = (data: Partial<SignUpInterface>) => async (dispatch: Dispatch<any>) => {
	dispatch(loading());

	try {
		const response = await authorizationApi.quickSignUp(data);

		dispatch(setAuthorizationInfoSuccess(data));
		dispatch(loaded());

		return response;
	} catch (error) {
		dispatch(loaded());
		return error;
	}
};

export const {
	loading,
	loaded,
	setAuthorizationInfoSuccess,
	setAuthorizationEmailSuccess,
	getAuthorizationInfoSuccess,
	setAuthorizationCodeSuccess,
	getAuthorizationInfoFailed,
	getAuthorizationNeedTermsSuccess,
	getAuthorizationNeedTermsFailed,
} = authorizationSlice.actions;
export default authorizationSlice.reducer;
