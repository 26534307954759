import React, { FC, useCallback, useMemo } from 'react';
import InfoWarning from '../../../../molecules/info-warning';
import LearnAboutBoostCTAInterface from './learn-about-boost-cta.interface';
import styles from './LearnAboutBoostCTA.module.scss';
import useCompanies from '../../../../../helpers/hooks/use-companies/useCompanies';
import { learnMoreGA4Event } from '../../../../../helpers/google-analytics-4/events';
import {
	GAContentGroup,
	GAContentId,
	GAItemBrand,
	GAMethod,
} from '../../../../../helpers/google-analytics-4/index.constants';
import useActiveCompany from '../../../../../helpers/hooks/use-active-company/useActiveCompany';
import { getCompanyDataForGA } from '../../../../../helpers/google-analytics-4/helpers';

const LearnAboutBoostCTA: FC<LearnAboutBoostCTAInterface> = () => {
	const { activeCompanyCanBoost, activeCompanyId, companies } = useCompanies();
	const activeCompany = useActiveCompany(activeCompanyId, companies);

	const boostKnowledgeBaseUrl = process.env.REACT_APP_BOOST_KNOWLEDGE_BASE_URL;

	const triggerLearnMoreGA4Event = useCallback(() => {
		learnMoreGA4Event(
			{
				companyData: getCompanyDataForGA(activeCompany),
				contentData: {
					id: GAContentId.jobList,
					group: GAContentGroup.jobList,
				},
			},
			{
				itemBrand: GAItemBrand.sparkBoost,
				method: GAMethod.banner,
			},
		);
	}, [activeCompany]);

	const warningText = useMemo(
		() => (
			<>
				Need candidates faster?{' '}
				<a
					href={boostKnowledgeBaseUrl}
					className={styles.link}
					onClick={triggerLearnMoreGA4Event}
					target="_blank"
					rel="noopener"
				>
					Learn about SparkBoost
				</a>
			</>
		),
		[boostKnowledgeBaseUrl, triggerLearnMoreGA4Event],
	);

	return (
		<InfoWarning hidden={!activeCompanyCanBoost || !boostKnowledgeBaseUrl} text={warningText} />
	);
};

export default LearnAboutBoostCTA;
