const DASHBOARD = 'dashboard';

const AUTH = `/${DASHBOARD}/auth/login`;
const JOBS = `/${DASHBOARD}/jobs`;
const JOBS_VALIDATE = `/${DASHBOARD}/jobs/validation`;
const WURK = `/${DASHBOARD}/applications/hireinwurk`;
const EIN = `/${DASHBOARD}/applications/ein`;
const USER = `/${DASHBOARD}/users/me`;
const USER_COMPANY_SETTINGS = `${USER}/companysettings`;
const USER_JOB_SETTINGS = `${USER}/jobsettings`;
const JOB_SETTINGS = `/${DASHBOARD}/userjobsettings`;
const TERMS = `/${DASHBOARD}/needterms`;
const NOTES = `/${DASHBOARD}/notes`;
const USERS = `/${DASHBOARD}/users`;
const SIGNUP = `/${DASHBOARD}/auth/signup`;
const EVENTS = `/${DASHBOARD}/events`;
const COMPANIES = `/${DASHBOARD}/companies`;
const CUSTOMER_COMPANY = `/${DASHBOARD}/customer/company`;
const FACILITIES = `/${DASHBOARD}/facilities`;
const CANDIDATES = `/${DASHBOARD}/applications`;
const USER_UPDATE = `/${DASHBOARD}/users/me`;
const VALIDATE_CODE = `/${DASHBOARD}/auth/validatecode`;
const JOBS_TEMPLATES = `/${DASHBOARD}/jobprofiles`;
const DEPARTMENTS_AND_LEVELS = `/${DASHBOARD}/jobs/departments-and-levels`;
const LOGIN_WITH_CODE = `/${DASHBOARD}/auth/loginwithcode`;
const SEND_VALIDATION_CODE = `/${DASHBOARD}/auth/sendvalidationcode`;
const SEND_SIGN_UP_VALIDATION_CODE = `/${DASHBOARD}/auth/sendsignupvalidationcode`;
const AUTHORIZED_CHANGE_USER_PASSWORD = `${USER}/change-password`;
const REJECTION_REASONS = `/${DASHBOARD}/applications/rejection-reasons`;
const COMMUNICATION_TEMPLATES = `/${DASHBOARD}/communicationtemplates`;
const PRODUCTS = `/${DASHBOARD}/products`;
const COST_PER_APPLICANTS = `/${DASHBOARD}/cost-per-applicants`;
const VALIDATION_CODE_FROM_LINK = `/${DASHBOARD}/auth/getvalidationcodefromlink`;
const QUICK_SIGN_UP = `/${DASHBOARD}/auth/quicksignup`;
const JOB_DESCRIPTION = `/${DASHBOARD}/job-description`;
const COMPUTE_POWER_PROFILE = `/${DASHBOARD}/job-description/classify`;

export {
	EIN,
	AUTH,
	SIGNUP,
	JOBS,
	JOBS_VALIDATE,
	WURK,
	EVENTS,
	FACILITIES,
	CANDIDATES,
	USER,
	USER_COMPANY_SETTINGS,
	USER_JOB_SETTINGS,
	JOB_SETTINGS,
	USERS,
	NOTES,
	USER_UPDATE,
	SEND_VALIDATION_CODE,
	SEND_SIGN_UP_VALIDATION_CODE,
	AUTHORIZED_CHANGE_USER_PASSWORD,
	VALIDATE_CODE,
	LOGIN_WITH_CODE,
	COMPANIES,
	JOBS_TEMPLATES,
	TERMS,
	REJECTION_REASONS,
	COMMUNICATION_TEMPLATES,
	DEPARTMENTS_AND_LEVELS,
	PRODUCTS,
	COST_PER_APPLICANTS,
	VALIDATION_CODE_FROM_LINK,
	QUICK_SIGN_UP,
	CUSTOMER_COMPANY,
	JOB_DESCRIPTION,
	COMPUTE_POWER_PROFILE,
};
