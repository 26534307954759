import React, { FC, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	AuthorizationSliceInterface,
	getAuthorizationNeedTerms,
} from '../../../../../store/slices/authorization.slice';
import EmailStepInterface from './email-step.interface';
import InputWithFloatLabel from '../../../form-elemets/input-with-float-label/InputWithFloatLabel';
import LoginFormContext from '../../../../../contexts/login-form-context';
import Button from '../../../../atoms/buttons/button/Button';
import ButtonLink from '../../../../atoms/buttons/button-link';

import styles from '../../login-form/LoginForm.module.scss';

const EmailStep: FC<EmailStepInterface> = ({
	sliderRef,
	setTroubleSigningInState,
	activeSliderNumberState,
	setNextHandler,
}) => {
	const dispatch = useDispatch();

	const { register, errors, trigger, getValues } = useContext(LoginFormContext);

	const { error, loading } = useSelector(
		({ authorization }: { authorization: AuthorizationSliceInterface }) => authorization,
	);

	const nextStep = () => {
		trigger!('email').then((response) => {
			if (response) {
				Promise.all([dispatch(getAuthorizationNeedTerms(getValues!().email))]).finally(
					() => {
						// @ts-ignore
						sliderRef.current.slickNext();
					},
				);
			}
		});
	};

	useEffect(() => {
		if (
			setNextHandler &&
			(activeSliderNumberState === undefined || activeSliderNumberState === 0) &&
			nextStep
		) {
			setNextHandler(() => nextStep);
		}
		// eslint-disable-next-line
	}, [activeSliderNumberState]);

	return (
		<div className={styles.step}>
			{register && (
				<InputWithFloatLabel
					className={styles.input}
					loading={loading}
					ref={register({
						required: {
							value: true,
							message: 'This field is required',
						},
						pattern: {
							value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
							message: 'Enter a valid e-mail address',
						},
					})}
					inputSettings={{
						type: 'email',
						name: 'email',
						autoFocus: true,
					}}
					error={(errors && errors.email && errors.email.message) || error}
					id="email"
					label="Email"
				/>
			)}

			<div className={styles.wrapper}>
				<Button type="button" small minWidth={206} maxWidth={206} onClick={nextStep}>
					Next
				</Button>

				<div className={styles['trouble-sign-in-wrap']}>
					<ButtonLink type="button" onClick={() => setTroubleSigningInState(true)}>
						Trouble signing in?
					</ButtonLink>
				</div>
			</div>
		</div>
	);
};

export default EmailStep;
