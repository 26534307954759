import * as React from 'react';
import { Navigate } from 'react-router-dom';

import { BaseRouteInterface } from './routes.interface';
import JobInfo from '../../pages/JobInfo';
import CompanyInfo from '../../components/organisms/custom/company-info/CompanyInfo';
import EditJob from '../../pages/EditJob';
import Jobs from '../../pages/Jobs';
import Login from '../../pages/Login';
import CompanyStatisticTopBar from '../../components/organisms/custom/company-statistic-top-bar/CompanyStatisticTopBar';
import PromoteJob from '../../pages/PromoteJob/PromoteJob';
import CandidateInfo from '../../pages/CandidateInfo';
import Account from '../../pages/Account';
import ProfileTopBar from '../../components/organisms/custom/profile-top-bar';
import ChangeAuthorizedUserPassword from '../../pages/ChangeAuthorizedUserPassword';
import NotFound from '../../pages/NotFound';
import AccessDenied from '../../pages/AccessDenied';
import CandidateRejection from '../../pages/CandidateRejection';
import BoostJob from '../../pages/BoostJob';
import AddCredits from '../../pages/AddCredits';
import CreateJob from '../../components/organisms/custom/create-job/CreateJob';
import SignUpOnDemand from '../../pages/SignUpOnDemand/SignUpOnDemand';
import ExpiredAutoLogin from '../../pages/ExpiredAutoLogin';

export const APP_URLS = {
	account: '/account',
	addCredits: '/add-credits',
	changePassword: '/change-password',
	job: '/jobs/:id',
	createJob: '/create-job',
	boostJob: '/boost-job/:id',
	promoteJob: '/promote-job/:id',
	editJob: '/edit-job/:id',
	jobs: '/jobs',
	candidate: '/candidate/:jobId/:id',
	candidateRejection: '/rejection',
	login: '/login',
	getStarted: '/get-started',
	404: '/404',
	403: '/403',
	signup: '/signup',
	expiredAutologin: '/expired-autologin',
};

export const routes: BaseRouteInterface[] = [
	{
		title: 'account',
		path: APP_URLS.account,
		type: 'private',
		navLinks: ['jobs', 'account'],
		topLayoutContent: <ProfileTopBar />,
		component: Account,
	},
	{
		title: 'add credits',
		path: APP_URLS.addCredits,
		type: 'private',
		popup: true,
		popupClassName: 'height-100',
		component: AddCredits,
	},
	{
		title: 'change password',
		path: APP_URLS.changePassword,
		type: 'private',
		component: ChangeAuthorizedUserPassword,
	},
	{
		title: 'job',
		path: APP_URLS.job,
		type: 'private',
		navLinks: ['jobs', 'account'],
		topLayoutContent: <CompanyInfo />,
		component: JobInfo,
	},
	{
		title: 'create job',
		path: APP_URLS.createJob,
		type: 'private',
		popup: true,
		component: CreateJob,
	},
	{
		title: 'boost job',
		path: APP_URLS.boostJob,
		type: 'private',
		popup: true,
		popupClassName: 'height-100',
		component: BoostJob,
	},
	{
		title: 'promote job',
		path: APP_URLS.promoteJob,
		type: 'private',
		popup: true,
		component: PromoteJob,
	},
	{
		title: 'edit job',
		path: APP_URLS.editJob,
		type: 'private',
		popup: true,
		component: EditJob,
	},
	{
		title: 'jobs',
		path: APP_URLS.jobs,
		type: 'private',
		navLinks: ['jobs', 'account'],
		topLayoutContent: <CompanyStatisticTopBar />,
		component: Jobs,
	},
	{
		title: 'candidate',
		path: APP_URLS.candidate,
		type: 'private',
		popup: true,
		opacityAnimation: true,
		component: CandidateInfo,
	},
	{
		title: 'rejection',
		path: APP_URLS.candidateRejection,
		type: 'private',
		popup: true,
		component: CandidateRejection,
	},
	{
		title: 'login',
		path: APP_URLS.login,
		type: 'public',
		component: Login,
	},
	{
		title: '404',
		path: APP_URLS[404],
		type: 'private',
		navLinks: ['dashboard', 'jobs', 'account'],
		component: NotFound,
	},
	{
		title: '403',
		path: APP_URLS[403],
		type: 'private',
		navLinks: ['dashboard', 'jobs', 'account'],
		component: AccessDenied,
	},
	{
		title: 'signup',
		path: APP_URLS.signup,
		type: 'public',
		component: SignUpOnDemand,
	},
	{
		title: 'expired autologin',
		path: APP_URLS.expiredAutologin,
		type: 'public',
		component: ExpiredAutoLogin,
	},
];

export const getNavLinks = (routeTitles?: string[]): { title: string; path: string }[] => {
	if (!routeTitles || (routeTitles && !routeTitles.length)) return [];

	const result = routeTitles
		.map((routeTitle) => routes.find((item) => item.title === routeTitle))
		.filter((item) => item) as {
		title: string;
		path: string;
	}[];

	return result;
};

export const redirectTo = (path: string, search?: string | null) => {
	return (
		<Navigate
			to={{
				pathname: path,
				...(search && { search }),
			}}
			replace
		/>
	);
};
