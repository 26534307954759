import axios, { AxiosRequestConfig } from 'axios';

import { EVENTS } from './api.path';

export type EventDataType = {
	eventType: string;
	uniqueName?: string | number;
	email?: string;
	product?: string;
	originTag?: string;
};

const eventApi = {
	dispatchEvent: <T>(data: EventDataType, config?: AxiosRequestConfig): Promise<T> => {
		return axios.post(EVENTS, data, config);
	},
};

export const dispatchEventToBackend = async (data: EventDataType, config?: AxiosRequestConfig) => {
	return await eventApi.dispatchEvent(data, config);
};

export default eventApi;
