import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './ExtendedFitScore.module.scss';
import ExtendedFitScoreInterface from './extended-fit-score.interface';
import SpecificFitScore from '../../../organisms/specific-fit-score';
import { FitScoreLabelsText } from '../../../../helpers/constants';
import ScoreGauge from '../../../atoms/score-gauge';
import CommuteScore from '../../../organisms/custom/commute-score';

const ExtendedFitScore: FC<ExtendedFitScoreInterface> = ({
	score,
	hardScore,
	softScore,
	commuteScoreClassName,
	className,
	smallSize,
}) => {
	return (
		<div className={classNames(styles['extended-fit-score'], className)}>
			<ScoreGauge {...{ score, smallSize }} />

			{score ? (
				<div className={styles.scores}>
					{hardScore ? (
						<SpecificFitScore
							width={smallSize ? 85 : 103}
							height={smallSize ? 12 : 16}
							text={FitScoreLabelsText.skills}
							score={hardScore}
							className={styles.score}
						/>
					) : null}

					{softScore ? (
						<SpecificFitScore
							width={smallSize ? 52 : 63}
							height={smallSize ? 12 : 16}
							text={FitScoreLabelsText.personality}
							className={styles.score}
							score={softScore}
						/>
					) : null}

					{commuteScoreClassName ? (
						<CommuteScore
							className={styles.score}
							scoreClass={commuteScoreClassName}
							extraSmallSize={smallSize}
							smallSize={!smallSize}
							text={FitScoreLabelsText.commute}
						/>
					) : null}
				</div>
			) : null}
		</div>
	);
};

export default ExtendedFitScore;
