import { useSelector } from 'react-redux';

import { UserInterface } from '../../../store/slices/user.slice';

export default function useUser() {
	const {
		user: { info, authorized, loading },
	} = useSelector((store: { user: UserInterface }) => store);

	return {
		info,
		authorized,
		loading,
	};
}
