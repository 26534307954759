import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import TextButtonInterface from './text-button.interface';
import { getMinMaxWidthStyles } from '../../../../helpers';

import styles from './TextButton.module.scss';

const TextButton: FC<TextButtonInterface> = ({
	children,
	className,
	reversedStyles,
	small,
	minWidth,
	maxWidth,
	...rest
}) => {
	const inlineStyles = getMinMaxWidthStyles(minWidth, maxWidth);

	return (
		<Link
			style={inlineStyles}
			className={classNames(
				styles.button,
				reversedStyles && styles.reversed,
				small && styles.small,
				className,
			)}
			{...rest}
		>
			{children}
		</Link>
	);
};

export default TextButton;
