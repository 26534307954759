import axios from 'axios';

import { WURK } from './api.path';
import { CandidateHiredInWurkType } from '../store/slices/candidates.slice';
import { CandidateInterface } from '../store/slices/candidate.slice';

const wurkApi = {
	hireWithWurk: (
		id: string | number,
		data: CandidateHiredInWurkType,
	): Promise<CandidateInterface> => {
		const url = `${WURK}/${id}`;

		return axios.post(url, data);
	},
};

export default wurkApi;
