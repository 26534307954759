import React, { FC } from 'react';
import { NavLink as NavLinkRouter } from 'react-router-dom';
import classNames from 'classnames';

import NavLinkInterface from './nav-link.interface';

import styles from './NavLink.module.scss';

const NavLink: FC<NavLinkInterface> = ({ children, childRoute, disabled, ...rest }) => {
	return (
		<NavLinkRouter
			className={({ isActive }) =>
				classNames(
					styles['nav-link'],
					disabled && styles.disabled,
					isActive ? classNames(styles.active, childRoute && styles['child-rout']) : '',
				)
			}
			onClick={(event) => {
				if (disabled) {
					event.preventDefault();
				}
			}}
			{...rest}
		>
			{children}
		</NavLinkRouter>
	);
};

export default React.memo(NavLink);
