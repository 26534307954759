import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
	clearError,
	ErrorRedirectionInterface,
} from '../../../store/slices/error-redirection.slice';
import ErrorRedirectionHocInterface from './errorRedirectionHoc.interface';
import { APP_URLS } from '../../routes/routes';
import useRedirectionErrors from '../../hooks/use-redirection-errors/useRedirectionErrors';

const ErrorRedirectionHoc: FC<ErrorRedirectionHocInterface> = ({ children }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const { error } = useRedirectionErrors();

	const currentPath = useMemo(() => location.pathname, [location.pathname]);
	const redirectionPath = useMemo(
		() => (currentPath !== APP_URLS.login ? currentPath : APP_URLS.jobs),
		[currentPath],
	);

	const settings: ErrorRedirectionInterface[] = useMemo(
		() => [
			{
				error: 401,
				page: APP_URLS.login,
			},
			{
				error: 403,
				page: APP_URLS[403],
			},
			{
				error: 404,
				page: APP_URLS[404],
			},
		],
		[],
	);

	useEffect(() => {
		const page = settings?.find((item) => item.error === error)?.page;

		if ((error === 401) && page) {
			navigate(page, { state: redirectionPath });
		} else if (error && page) {
			navigate(page);
		}

		return () => {
			dispatch(clearError());
		};
	}, [dispatch, error, navigate, settings, redirectionPath]);

	return <>{children}</>;
};

export default ErrorRedirectionHoc;
