import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import {
	CandidateHiredInWurkType,
	hireCandidateWithWurk,
} from '../../../../store/slices/candidates.slice';
import {
	getPayloadWithoutEmptyAndSpecificFields,
	requireValidation,
} from '../../../../helpers/forms/forms';
import { ExternalErrorType } from '../../../../helpers/axios/axios-global';
import { RootStateType } from '../../../../store/reducers/root-reducer';
import Loader from '../../../molecules/loader';
import Legend from '../../../atoms/form/legend';
import Fieldset from '../../form-elemets/fieldset';
import FormActions from '../../../molecules/form/form-actions';
import TypographyText from '../../../atoms/ui/typography-text';
import useExternalFormErrors from '../../../../helpers/hooks/use-external-form-errors';
import Button from '../../../atoms/buttons/button/Button';
import EinNumberField from '../ein-number-field';
import Error from '../../../molecules/ui/error';
import InputWithFloatLabel from '../../form-elemets/input-with-float-label/InputWithFloatLabel';
import HiredWithWurkFormInterface from './hired-with-wurk-form.interface';

import styles from './HiredWithWurkForm.module.scss';
import classNames from 'classnames';

const HiredWithWurkForm: FC<HiredWithWurkFormInterface> = ({ afterStatusChange }) => {
	const dispatch = useDispatch();
	const {
		candidates: { wurkHiringLoading },
		job: { info: jobInfo },
	} = useSelector((state: RootStateType) => state);
	const { register, handleSubmit, errors } = useForm({
		reValidateMode: 'onChange',
		shouldFocusError: false,
	});
	const [externalErrors, setExternalErrors] = useState<Partial<ExternalErrorType>>();
	const normalizedExternalErrors = useExternalFormErrors(externalErrors);

	const onFormSubmitHandler = (data: CandidateHiredInWurkType) => {
		const normalizedData = getPayloadWithoutEmptyAndSpecificFields(data);

		dispatch<any>(hireCandidateWithWurk(normalizedData))
			.then(() => {
				if (afterStatusChange) {
					afterStatusChange();
				}
			})
			.catch((error: ExternalErrorType) => {
				setExternalErrors(error);
			});
	};

	return (
		<form onSubmit={handleSubmit(onFormSubmitHandler)}>
			<Fieldset className={styles.fieldset}>
				<Legend className={styles.legend}>Dates</Legend>

				<InputWithFloatLabel
					className={styles['form-item']}
					minWidth="100%"
					id="hiredAt"
					ref={register(requireValidation())}
					label="Hire date"
					required={true}
					error={
						errors?.hiredAt?.message ||
						normalizedExternalErrors?.errors?.hiredAt?.message
					}
					inputSettings={{
						name: 'hiredAt',
						type: 'date',
					}}
				/>

				<InputWithFloatLabel
					className={classNames(styles['form-item'], styles.date)}
					minWidth="100%"
					id="startAt"
					ref={register()}
					label="Start date (optional)"
					error={errors?.startAt?.message || normalizedExternalErrors?.errors?.startAt}
					inputSettings={{
						name: 'startAt',
						type: 'date',
					}}
				/>
			</Fieldset>

			<Fieldset className={styles.fieldset}>
				<Legend className={styles.legend}>Company details</Legend>

				<EinNumberField
					minWidth="100%"
					className={styles['form-item']}
					selectSettings={{
						ref: register(requireValidation()),
						defaultValue: jobInfo?.facility?.ein,
					}}
					error={errors?.ein?.message || normalizedExternalErrors?.errors?.ein}
				/>
			</Fieldset>

			<TypographyText robotoFont className={styles.description}>
				Click below to hire this candidate in Würk. It may take up to 10 minutes for this
				information to be reflected in Würk. This action cannot be undone.
				<Error className={styles.error}>
					{!externalErrors?.errors ? externalErrors?.message : null}
				</Error>
			</TypographyText>

			<FormActions centeredContent className={styles.actions}>
				{wurkHiringLoading ? (
					<Loader maxWidth={25} maxHeight={25} />
				) : (
					<Button minWidth="100%">Hire candidate in Würk</Button>
				)}
			</FormActions>
		</form>
	);
};

export default React.memo(HiredWithWurkForm);
