import React, { FC, useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import PasswordStepInterface from './password-step.interface';

import { AuthorizationSliceInterface } from '../../../../../store/slices/authorization.slice';
import InputWithFloatLabel from '../../../form-elemets/input-with-float-label/InputWithFloatLabel';
import Button from '../../../../atoms/buttons/button/Button';
import LoginFormContext from '../../../../../contexts/login-form-context';
import CheckboxWithLabel from '../../../../molecules/form/checkbox-with-label/CheckboxWithLabel';
import ButtonLink from '../../../../atoms/buttons/button-link';
import ButtonLinkInterface from '../../../../atoms/buttons/button-link/button-link.interface';

import styles from '../../login-form/LoginForm.module.scss';

const PasswordStep: FC<PasswordStepInterface> = ({
	sliderRef,
	setTroubleSigningInState,
	setNextHandler,
	activeSliderNumberState,
}) => {
	const submitButtonRef = useRef<ButtonLinkInterface>();
	const { register, errors } = useContext(LoginFormContext);
	const { error, loading, needTerms } = useSelector(
		({ authorization }: { authorization: AuthorizationSliceInterface }) => authorization,
	);

	const prevStep = () => {
		if (sliderRef) {
			// @ts-ignore
			sliderRef.current.slickPrev();
		}
	};

	const submitForm = () => {
		if (submitButtonRef.current) {
			// @ts-ignore
			submitButtonRef.current.click();
		}
	};

	useEffect(() => {
		if (setNextHandler && activeSliderNumberState === 1 && submitForm) {
			setNextHandler(() => submitForm);
		}
		// eslint-disable-next-line
	}, [activeSliderNumberState]);

	const customerTermsUrl =
		process.env.REACT_APP_CUSTOMER_TERMS_URL || 'https://engin.co/terms-and-conditions';

	return (
		<div className={styles.step}>
			{register && (
				<InputWithFloatLabel
					className={styles.input}
					loading={loading}
					ref={(event: HTMLInputElement) => {
						register!(event, {
							required: {
								value: true,
								message: 'This field is required',
							},
						});
					}}
					inputSettings={{
						type: 'password',
						name: 'password',
						autoFocus: false,
					}}
					error={(errors && errors.password && errors.password.message) || error}
					id="password"
					label="Password"
				/>
			)}

			<CheckboxWithLabel
				id="acceptedCustomerTerms"
				className={classNames(styles.checkbox, !needTerms && styles.hidden)}
				name="acceptedCustomerTerms"
				disabled={!needTerms}
				isWhiteTheme
				ref={register!({ required: true })}
				defaultChecked
				error={
					errors && errors.acceptedTerms && "The 'Terms & Conditions' field is required"
				}
				label={
					<span className={styles.text}>
						By signing up, you agree to our{' '}
						<a href={customerTermsUrl} target="_blank" rel="noopener noreferrer">
							Terms & Conditions
						</a>
						.
					</span>
				}
			/>

			<div className={styles.wrapper}>
				<Button
					type="button"
					reversedStyles
					small
					minWidth={100}
					className={styles.btn}
					onClick={prevStep}
				>
					Back
				</Button>

				<Button
					small
					type="submit"
					className={styles.btn}
					minWidth={100}
					ref={submitButtonRef}
				>
					Next
				</Button>

				<div className={styles['trouble-sign-in-wrap']}>
					<ButtonLink type="button" onClick={() => setTroubleSigningInState(true)}>
						Trouble signing in?
					</ButtonLink>
				</div>
			</div>
		</div>
	);
};

export default PasswordStep;
