import React, { FC } from 'react';

import CandidateExportItemInterface from './candidate-export-item.interface';
import DownloadItem from '../../../templates/download-item/DownloadItem';

import styles from './CandidateExportItem.module.scss';

const CandidateExportItem: FC<CandidateExportItemInterface> = (props) => {
	return <div className={styles.wrap}>{<DownloadItem {...props} />}</div>;
};

export default React.memo(CandidateExportItem);
