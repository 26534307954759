import React, { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';

import { ErrorTargetType } from '../../../../helpers/axios/axios-global';
import { changeAuthorizedUserPassword, UserInterface } from '../../../../store/slices/user.slice';
import { getPayloadWithoutEmptyAndSpecificFields } from '../../../../helpers';
import { changeAuthorizedUserPasswordPayloadType } from '../../../../api/user.api';
import troubleFormStyles from '../login-trouble/LoginTrouble.module.scss';
import InputWithFloatLabel from '../../form-elemets/input-with-float-label/InputWithFloatLabel';
import Button from '../../../atoms/buttons/button/Button';
import LinkButton from '../../../atoms/buttons/link-button/LinkButton';
import Error from '../../../molecules/ui/error';

import styles from './ChangeAuthorizedUserPasswordForm.module.scss';
import { APP_URLS } from '../../../../helpers/routes/routes';

const ChangeAuthorizedUserPasswordForm: FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [errorState, setErrorState] = useState<string>();
	const [errorTargetsState, setErrorTargetsState] = useState<ErrorTargetType[]>([]);
	const { register, handleSubmit, watch, errors } = useForm();
	const { changePasswordLoading } = useSelector(({ user }: { user: UserInterface }) => user);

	const newPasswordErrorTarget = errorTargetsState?.find(
			(item: ErrorTargetType) => item && item.property === 'newPassword',
		)?.constraints,
		currentPasswordErrorTarget = errorTargetsState?.find(
			(item: ErrorTargetType) => item && item.property === 'currentPassword',
		)?.constraints;

	const onFormSubmitHandler = useCallback(
		(data: any) => {
			dispatch<any>(
				changeAuthorizedUserPassword(
					getPayloadWithoutEmptyAndSpecificFields(data, [
						'confirmPassword',
					]) as changeAuthorizedUserPasswordPayloadType,
					() => navigate(APP_URLS.account),
				),
			).catch((error: { message: string; errors: [] }) => {
				if (error.errors) {
					setErrorTargetsState(error.errors);
				} else {
					setErrorTargetsState([]);
				}

				setErrorState(error.message);
			});
		},
		[dispatch, navigate],
	);

	const formHandleSubmit = handleSubmit(onFormSubmitHandler);

	return (
		<form onSubmit={formHandleSubmit}>
			<InputWithFloatLabel
				className={styles.input}
				loading={changePasswordLoading}
				ref={register({
					required: {
						value: true,
						message: 'This field is required',
					},
				})}
				error={
					errors && errors.currentPassword && errors.currentPassword.message
						? errors.currentPassword.message
						: currentPasswordErrorTarget && Object.values(currentPasswordErrorTarget)
				}
				minWidth="100%"
				inputSettings={{
					type: 'password',
					id: 'current-password',
					autoComplete: 'new-password',
					name: 'currentPassword',
				}}
				id="current-password"
				label="Current password"
			/>

			<InputWithFloatLabel
				className={styles.input}
				loading={changePasswordLoading}
				ref={register({
					required: {
						value: true,
						message: 'This field is required',
					},
					minLength: { value: 8, message: 'Password must be at least 8 characters long' },
				})}
				inputSettings={{
					type: 'password',
					autoComplete: 'new-password',
					id: 'newPassword',
					name: 'newPassword',
				}}
				minWidth="100%"
				error={
					errors && errors.newPassword && errors.newPassword.message
						? errors.newPassword.message
						: newPasswordErrorTarget && Object.values(newPasswordErrorTarget)
				}
				id="newPassword"
				label="New password"
			/>

			<InputWithFloatLabel
				className={styles.input}
				loading={changePasswordLoading}
				ref={register({
					required: {
						value: true,
						message: 'This field is required',
					},
					validate: (value) => {
						return value === watch('newPassword');
					},
				})}
				error={
					errors && errors.confirmPassword && errors.confirmPassword.message
						? errors.confirmPassword.message
						: errors &&
						  errors.confirmPassword &&
						  errors.confirmPassword.type === 'validate'
						? 'This field does not match'
						: null
				}
				minWidth="100%"
				inputSettings={{
					type: 'password',
					id: 'confirm-password',
					autoComplete: 'new-password',
					name: 'confirmPassword',
				}}
				id="confirm-password"
				label="Confirm password"
			/>

			<div className={classNames(troubleFormStyles.actions, styles.actions)}>
				<Error className={styles.error}>{errorState}</Error>
				<div>
					<LinkButton small reversedStyles minWidth={100} to="/account">
						Cancel
					</LinkButton>

					<Button type="submit" small minWidth={100} maxWidth={100}>
						Save
					</Button>
				</div>
			</div>
		</form>
	);
};

export default ChangeAuthorizedUserPasswordForm;
