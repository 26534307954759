import { BoostItemInterface, JobAnalyticsDataInterface } from '../../../store/slices/job.slice';
import {
	APPLICANTS_PER_HIRE_COEF,
	CREDITS_MIN_VALUE,
	CREDITS_CALCULATIONS_STEP,
	DEFAULT_APPLICATIONS_NUMBER,
	CREDITS_STEP,
} from '../../constants';
import { formatStringAsAbsDecimal } from '../common';
import { getPeriodInDays, getLeftPeriodInDays } from '../datetime';
import { checkIfBeforeDate, checkJobIsBoosted } from '../job';

// not used for now (after boost downgrade)
export const calculateJobCPA = (params: {
	applicantsNumber: number;
	boostId?: number;
	jobBoosts?: BoostItemInterface[];
	status?: number;
}) => {
	const { boostId, applicantsNumber, status, jobBoosts } = params;

	const isBoosted = checkJobIsBoosted(boostId, jobBoosts, status);

	if (applicantsNumber === 0 || !jobBoosts?.length) return 0;

	if (jobBoosts?.length) {
		if (isBoosted) {
			const currentBoost = jobBoosts.find((boost) => boost.id === boostId);

			const { credits, startedAt, endedAt } = currentBoost || {};

			const currentBoostDuration = getPeriodInDays(startedAt, endedAt) || 0;

			const currentBoostSpentDays =
				currentBoostDuration - (getLeftPeriodInDays(endedAt) || 0);

			const currentBoostCost = Number(formatStringAsAbsDecimal(credits!, false));

			const previousBoostsCost = jobBoosts
				.filter((boost) => boost.id !== boostId)
				.reduce((acc: number, boost: BoostItemInterface) => {
					return acc + Number(formatStringAsAbsDecimal(boost.credits, false));
				}, 0);

			const boostSpentTimePercentage = (currentBoostSpentDays || 1) / currentBoostDuration;
			const totalAmountSpent =
				previousBoostsCost + currentBoostCost * boostSpentTimePercentage;

			const currentCPA = parseFloat((totalAmountSpent / applicantsNumber).toFixed(2));

			return currentCPA;
		}

		const allSpentCredits = jobBoosts.reduce((acc: number, boost: BoostItemInterface) => {
			return acc + Number(formatStringAsAbsDecimal(boost.credits, false));
		}, 0);

		const current = parseFloat((allSpentCredits / applicantsNumber).toFixed(2));

		return current;
	}

	return 0;
};

export const calculateApplicantsToAcquire = (openingsNumber: number) => {
	const applicantsForHires =
		DEFAULT_APPLICATIONS_NUMBER +
		DEFAULT_APPLICATIONS_NUMBER * APPLICANTS_PER_HIRE_COEF * (Number(openingsNumber) - 1);

	return applicantsForHires;
};

const customRound = (number: number) => {
	const doubleStep = CREDITS_STEP * 2;

	if (number % 100 === 30) {
		return Math.floor(number / doubleStep) * doubleStep;
	} else {
		return Math.round(number / CREDITS_STEP) * CREDITS_STEP;
	}
};

export const calculateRecommendedCredits = (openingsNumber?: number) => {
	if (openingsNumber) {
		const applicantsForHires = calculateApplicantsToAcquire(openingsNumber);

		const credits =
			(applicantsForHires / DEFAULT_APPLICATIONS_NUMBER) * CREDITS_CALCULATIONS_STEP;

		return customRound(credits);
	}

	return CREDITS_MIN_VALUE;
};

export const calculateAcquiredApplicants = (
	analyticsData: JobAnalyticsDataInterface[],
	startDate: string,
	endDate?: string,
) => {
	if (!analyticsData || !analyticsData.length || !endDate) return 0;

	let applicantsOnStart = 0;
	let applicantsOnEnd = 0;

	analyticsData.forEach((dataItem: JobAnalyticsDataInterface) => {
		if (checkIfBeforeDate(dataItem.date, startDate)) {
			applicantsOnStart += Number(dataItem.numberOfApplications);
		}

		if (checkIfBeforeDate(dataItem.date, endDate)) {
			applicantsOnEnd += Number(dataItem.numberOfApplications);
		}
	});

	const applicantsOnStartDate = analyticsData.find((dataItem) => dataItem.date === startDate);

	if (applicantsOnStartDate) {
		if (startDate === endDate || applicantsOnStart === applicantsOnEnd) {
			return Number(applicantsOnStartDate.numberOfApplications);
		}

		if (applicantsOnStart === Number(applicantsOnStartDate?.numberOfApplications)) {
			return applicantsOnStart + (applicantsOnEnd - applicantsOnStart);
		}
	}

	return applicantsOnEnd - applicantsOnStart;
};
