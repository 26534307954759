import React, { FC, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import EditableViewFormInterface from './editable-view-form.interface';
import Loader from '../../molecules/loader';
import Overlay from '../../molecules/ui/overlay';
import ButtonLink from '../../atoms/buttons/button-link';
import TypographyText from '../../atoms/ui/typography-text';

import styles from './EditableViewForm.module.scss';
import FocusTrap from 'focus-trap-react';

const EditableViewForm: FC<EditableViewFormInterface> = ({
	children,
	className,
	sectionTitle,
	headerClassName,
	sectionTitleClassName,
	formClassName,
	afterModeChange,
	hasEditButton = true,
	addEditableViewFormItemClass = true,
	onSubmit,
	desc,
	editModeState,
	loading,
	editButtonText = 'Edit',
	...rest
}) => {
	const [isViewModeState, setIsViewModeState] = useState<boolean>(true);

	const toggleEditModeState = useCallback(() => {
		setIsViewModeState((prevState) => !prevState);
	}, []);

	const escapeButtonKeydownHandler = useCallback(
		(event: KeyboardEvent) => {
			if (event?.key?.toLowerCase() === 'escape') {
				setIsViewModeState(true);

				if (afterModeChange) {
					afterModeChange(true);
				}
			}
		},
		[afterModeChange, setIsViewModeState],
	);

	useEffect(() => {
		setIsViewModeState(!editModeState);
	}, [editModeState]);

	useEffect(() => {
		if (!isViewModeState) {
			document.addEventListener('keydown', escapeButtonKeydownHandler);
		} else {
			return () => document.removeEventListener('keydown', escapeButtonKeydownHandler);
		}
	}, [isViewModeState, escapeButtonKeydownHandler]);

	return (
		<section className={classNames(styles.wrap, !isViewModeState && styles.edit, className)}>
			<FocusTrap active={!isViewModeState}>
				<div className={styles.content}>
					<div className={classNames(styles.header, headerClassName)}>
						<TypographyText className={classNames(styles.title, sectionTitleClassName)}>
							{sectionTitle}
						</TypographyText>

						{hasEditButton && (
							<div className={styles.actions}>
								{!loading && (
									<>
										{!isViewModeState && (
											<>
												<ButtonLink
													type="button"
													className={styles.btn}
													onClick={() => {
														toggleEditModeState();

														if (afterModeChange) {
															afterModeChange(true);
														}
													}}
												>
													Cancel
												</ButtonLink>

												<i className={styles.slash} />
											</>
										)}

										<ButtonLink
											type="submit"
											className={styles.btn}
											onClick={() => {
												if (!isViewModeState && onSubmit) {
													onSubmit();
													if (afterModeChange) {
														afterModeChange(false);
													}
												} else {
													if (afterModeChange) {
														afterModeChange(false);
													}
													toggleEditModeState();
												}
											}}
										>
											{editButtonText && isViewModeState
												? editButtonText
												: 'Save'}
										</ButtonLink>
									</>
								)}

								{loading && !isViewModeState && (
									<Loader
										className={styles.loader}
										maxWidth={16}
										maxHeight={16}
										thin
									/>
								)}
							</div>
						)}
					</div>

					{desc && <div className={styles.desc}>{desc}</div>}

					<form
						{...rest}
						className={formClassName}
						onKeyDown={(event) => {
							if (event.key.toLowerCase() === 'enter') {
								event.preventDefault();
							}
						}}
					>
						{React.Children.map(children, (child) => {
							// @ts-ignore
							const settingsPropsName = Object.keys(child.props).filter((s) =>
								s.includes('Settings'),
							)[0];

							if (addEditableViewFormItemClass && React.isValidElement(child)) {
								return React.cloneElement(child, {
									disabled: isViewModeState,
									className: styles['form-item'],
									cancelLabelState:
										isViewModeState &&
										!child.props[settingsPropsName]?.defaultValue &&
										!child.props[settingsPropsName]?.value,
								});
							} else if (React.isValidElement(child)) {
								return React.cloneElement(child, {
									disabled: isViewModeState,
								});
							}
						})}
					</form>
				</div>
			</FocusTrap>
			<Overlay open={!isViewModeState} />
		</section>
	);
};

export default React.memo(EditableViewForm);
