import React, { FC } from 'react';
import classNames from 'classnames';

import ContentWithSidebarLayoutInterface from './content-with-sidebar-layout.interface';

import styles from './ContentWithSidebarLayout.module.scss';

const ContentWithSidebarLayout: FC<ContentWithSidebarLayoutInterface> = ({
	content,
	contentElementSettings,
	sidebar,
	sidebarElementSettings,
	className,
}) => {
	return (
		<div className={classNames(styles['content-with-sidebar'], className)}>
			<main
				{...contentElementSettings}
				className={classNames(
					styles.content,
					contentElementSettings && contentElementSettings.className,
				)}
			>
				{content}
			</main>

			{sidebar && (
				<aside
					{...sidebarElementSettings}
					className={classNames(
						styles.sidebar,
						sidebarElementSettings && sidebarElementSettings.className,
					)}
				>
					{sidebar}
				</aside>
			)}
		</div>
	);
};

export default ContentWithSidebarLayout;
