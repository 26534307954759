import { AnyAction, combineReducers, Reducer } from '@reduxjs/toolkit';

import authorization from '../slices/authorization.slice';
import user from '../slices/user.slice';
import job from '../slices/job.slice';
import jobs from '../slices/jobs.slice';
import promotionJobLinks from '../slices/promotion-job-links.slice';
import jobsTemplates from '../slices/jobs-templates.slice';
import candidate from '../slices/candidate.slice';
import candidates from '../slices/candidates.slice';
import facilities from '../slices/facilities.slice';
import companies from '../slices/companies.slice';
import notes from '../slices/notes.slice';
import einNumber from '../slices/ein-numbers.slice';
import errorRedirection from '../slices/error-redirection.slice';
import products from '../slices/products.slice';

const appReducer = combineReducers({
	authorization,
	user,
	job,
	jobs,
	notes,
	promotionJobLinks,
	jobsTemplates,
	candidate,
	candidates,
	facilities,
	companies,
	einNumber,
	errorRedirection,
	products,
});

export type RootStateType = ReturnType<typeof appReducer>;

const rootReducer: Reducer = (state: RootStateType, action: AnyAction) => {
	if (action.type === 'user/logout') {
		state = {
			user: {
				authorized: false,
			},
		} as RootStateType;
	}
	return appReducer(state, action);
};

export default rootReducer;
