import { useReducer } from 'react';
import { filterReducer } from '../../../store/reducers/filter-reducer';

const useFilterParams = () => {
	const filterReducerInitialState = {
		filters: { text: undefined, filterItem: undefined, filterOrder: undefined },
	};

	const [state, dispatch] = useReducer(filterReducer, filterReducerInitialState);

	return { state, dispatch };
};

export default useFilterParams;
