import axios from 'axios';

import { DEPARTMENTS_AND_LEVELS, JOBS_TEMPLATES } from './api.path';
import { JobTemplateInterface } from '../store/slices/jobs-templates.slice';
import { getUrlParamsStringFromObject } from '../helpers';

const jobsTemplatesApi = {
	getJobsTemplatesList: (params: {
		excludeCannabis?: boolean;
	}): Promise<JobTemplateInterface[]> => {
		const queryParams = getUrlParamsStringFromObject(params);

		return axios.get(`${JOBS_TEMPLATES}${queryParams.length ? `?${queryParams}` : ''}`);
	},
	getJobTemplateById: (id: string | number): Promise<JobTemplateInterface> => {
		return axios.get(`${JOBS_TEMPLATES}/${id}`);
	},
	getDepartmentsAndLevels: (): Promise<any> => {
		return axios.get(`${DEPARTMENTS_AND_LEVELS}`);
	},
};

export default jobsTemplatesApi;
