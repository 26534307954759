import React, { FC } from 'react';

import styles from './CheckChipsGroup.module.scss';
import CheckChipsGroupInterface from './check-chips-group.interface';

const CheckChipsGroup: FC<CheckChipsGroupInterface> = ({ children }) => {
	return <div className={styles.chips}>{children}</div>;
};

export default CheckChipsGroup;
