export const getUrlParamsStringFromObject = (params: object) => {
	let queryParamsString = '';

	if (params) {
		for (const key in params) {
			if (!queryParamsString.length && (params as string[])[key as any]) {
				queryParamsString = `${key}=${(params as string[])[key as any]}`;
			} else if ((params as string[])[key as any]) {
				queryParamsString += `&${key}=${(params as string[])[key as any]}`;
			}
		}
	}

	return queryParamsString;
};
