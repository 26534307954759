import React, { FC } from 'react';
import classNames from 'classnames';

import LegendInterface from './legend.interface';

import styles from './Legend.module.scss';

const Legend: FC<LegendInterface> = ({
	children,
	className,
	small,
	extraSmall,
	extraMargin,
	...rest
}) => {
	return (
		<legend
			className={classNames(
				styles.legend,
				className,
				small ? styles.small : '',
				extraSmall ? styles['extra-small'] : '',
				extraMargin && styles['legend__extra-margin'],
			)}
			{...rest}
		>
			{children}
		</legend>
	);
};

export default Legend;
