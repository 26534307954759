import axios from 'axios';

import { EIN } from './api.path';
import { EinNumberInterface } from '../store/slices/ein-numbers.slice';

const einNumbersApi = {
	getEinNumbers: (companyId: string): Promise<EinNumberInterface[]> => {
		const url = `${EIN}/${companyId}`;

		return axios.get(url);
	},
};

export default einNumbersApi;
