import React, { FC } from 'react';
import classNames from 'classnames';

import Close from '../../../atoms/close';
import RoundedCloseButtonInterface from './rounded-close-button.interface';

import styles from './RoundedCloseButton.module.scss';

const RoundedCloseButton: FC<RoundedCloseButtonInterface> = ({ className, ...rest }) => {
	return (
		<div className={classNames(styles.wrap, className)}>
			<Close className={styles.close} {...rest} />
		</div>
	);
};

export default RoundedCloseButton;
