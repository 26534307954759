import { useSelector } from 'react-redux';
import { JobsTemplatesSliceInterface } from '../../../store/slices/jobs-templates.slice';

export default function useJobsTemplates() {
	const {
		jobsTemplates: { templateId, items, departmentsAndLevels, loading },
	} = useSelector((store: { jobsTemplates: JobsTemplatesSliceInterface }) => store);

	return {
		templateId,
		items,
		departmentsAndLevels,
		loading,
	};
}
