import React, { FC, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './NumberIncrementer.module.scss';
import NumberIncrementerInterface from './number-incrementer.interface';

const NumberIncrementer: FC<NumberIncrementerInterface> = ({
	className,
	value,
	onValueChange,
	step = 5,
	minValue = 0,
	maxValue = 500,
}) => {
	const [currentValue, setCurrentValue] = useState(minValue);

	const incrementValue = useCallback(() => {
		const newValue = currentValue + step;

		if (newValue > maxValue) return;

		setCurrentValue(newValue);
		if (onValueChange) onValueChange(newValue);
	}, [currentValue, maxValue, onValueChange, step]);

	const decrementValue = useCallback(() => {
		const newValue = currentValue - step;

		if (newValue < minValue) return;

		setCurrentValue(newValue);
		if (onValueChange) onValueChange(newValue);
	}, [currentValue, minValue, onValueChange, step]);

	useEffect(() => {
		if (value !== undefined) setCurrentValue(value);
	}, [value]);

	return (
		<div className={classNames(styles['number-incrementer'], className)}>
			<div className={styles.number}>{currentValue}</div>

			<div className={styles['increment-handles']}>
				<div
					className={classNames(
						styles.handle,
						styles.up,
						currentValue >= maxValue ? styles.disabled : '',
					)}
					onClick={incrementValue}
				></div>
				<div
					className={classNames(
						styles.handle,
						styles.down,
						currentValue <= minValue ? styles.disabled : '',
					)}
					onClick={decrementValue}
				></div>
			</div>
		</div>
	);
};

export default NumberIncrementer;
