import React from 'react';

type UtmParamsContextType = {
	state: {
		utmSource: string | null;
	};
};

const initialState: UtmParamsContextType = {
	state: {
		utmSource: null,
	},
};

const UtmParamsContext = React.createContext(initialState);

export const { Provider: UtmParamsContextProvider, Consumer: UtmParamsContextConsumer } =
	UtmParamsContext;

export default UtmParamsContext;
