import * as auth from './auth/auth';
import { routes } from './routes/routes';
import { getMinMaxWidthStyles, getMinMaxHeightStyles, getStartEndColors } from './styles';
import { getFormattedDate, getFormattedDateWithShortMonth } from './date/date';
import { getEmploymentTypeLabel, getLabelAccordingToValue } from './response-comparison';
import { candidatesStatusLabels } from './constants';
import { clickedOutside } from './dom';
import {
	preventMinusEnter,
	getPayloadWithoutEmptyAndSpecificFields,
	getErrorTargetByName,
	transformNumericFieldsOfPayload,
} from './forms/forms';
import { setSpecificPagination } from './pagination';
import { setSpecificFiltration } from './filtration';
import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import setupAxios from './axios/axios-global';
import { getUrlParamsStringFromObject } from './axios/api';
import useDebounce from './hooks/use-debounce';

// @ts-ignore
import domainNamesInstancesConfiguration from '../domains.config.json';

const getDomainName = (app = window.location.host) => {
	const env = process.env.REACT_APP_ENV;
	const instance =
		env &&
		// @ts-ignore
		domainNamesInstancesConfiguration[env] &&
		// @ts-ignore
		domainNamesInstancesConfiguration[env][app];

	if (instance) {
		return instance;
	}

	return 'hire';
};

export {
	auth,
	PrivateRoute,
	routes,
	candidatesStatusLabels,
	getLabelAccordingToValue,
	getDomainName,
	setupAxios,
	useDebounce,
	PublicRoute,
	getUrlParamsStringFromObject,
	getMinMaxWidthStyles,
	getMinMaxHeightStyles,
	transformNumericFieldsOfPayload,
	getPayloadWithoutEmptyAndSpecificFields,
	getFormattedDate,
	getErrorTargetByName,
	getFormattedDateWithShortMonth,
	getEmploymentTypeLabel,
	clickedOutside,
	preventMinusEnter,
	getStartEndColors,
	setSpecificPagination,
	setSpecificFiltration,
};
