import { useEffect, useState } from 'react';

import useProducts from '../../use-products/useProducts';
import { useDispatch } from 'react-redux';
import { getProductsList } from '../../../../store/slices/products.slice';

const usePlansAndProducts = () => {
	const dispatch = useDispatch();
	const { productItems } = useProducts();

	const [productsError, setProductsError] = useState(false);

	useEffect(() => {
		if (!productItems?.length && !productsError) {
			(async () => {
				try {
					await dispatch(getProductsList());
				} catch (error) {
					setProductsError(true);
				}
			})();
		}
	}, [dispatch, productItems?.length, productsError]);
};

export default usePlansAndProducts;
