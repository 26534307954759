import { CompanyInterface } from '../../store/slices/companies.slice';
import { JobInterface, PaymentLinkData } from '../../store/slices/job.slice';
import {
	COMPANIES_DROPDOWN_MIN_NUMBER,
	PaymentResultStatus,
	QueryParametersKey,
	StorageItemKeys,
} from '../constants';
import { ErrorType } from '../forms/forms';
import { GAContentGroup, GAContentId, GAContentType } from '../google-analytics-4/index.constants';
import { APP_URLS } from '../routes/routes';
import imageCompression from 'browser-image-compression';

export interface KeyValueDataItem<T> {
	[key: string]: T;
}

export const TokenTypes = {
	validationCode: 40,
};

export const AutoLoginActions = {
	signupValidation: 'signup-validation',
	troubleSignin: 'trouble-signin',
};

export const ConsumerAppUrlByEnv = {
	production: 'https://x.careersincannabis.com',
	staging: 'https://staging-x.careersincannabis.com',
	development: 'https://dev-x.careersincannabis.com',
	localDevelopment: 'https://dev-x.careersincannabis.com',
};

export const CustomerAppUrlByEnv = {
	production: 'https://hire.engin.co',
	staging: 'https://staging-hire.engin.co',
	development: 'https://dev-hire.engin.co',
	localDevelopment: 'https://dev-hire.engin.co',
	//not using localhost for localDevelopment because (for example) Stripe treats it as invalid
};

export const JobCategories = {
	customerInternal: 'customer-internal',
	customerExternal: 'customer-external',
	prospective: 'prospective',
};

export const JobChartBoostStatus = {
	active: 'Active',
	ended: 'Ended',
};

export interface ChartDotPropsInterface {
	cx?: number;
	cy?: number;
	dataKey?: string;
	fill?: string;
	height?: number;
	index?: number;
	payload?: any;
	r?: number;
	stroke?: string;
	strokeWidth?: number;
	value?: number;
	width?: number;
}

export const salaryUnitMapping: any = {
	hourly: 'hour',
	daily: 'day',
	weekly: 'week',
	monthly: 'month',
	yearly: 'year',
};

export const nanToNumber = (number: string | number) =>
	isNaN(Number(number)) ? 0 : Number(number);

export const isFloat = (number: number | string) => {
	return Number(number) % 1 !== 0;
};

const formatSalaryValue = (
	salaryValue: number | string,
	valueToCompare?: string | number | null,
) => {
	if (isFloat(salaryValue) || (valueToCompare && isFloat(valueToCompare)))
		return Number(salaryValue).toFixed(2);

	return salaryValue.toString();
};

export const renderSalaryInfo = (
	salaryUnit: string,
	minSalaryValue?: string | number | null,
	maxSalaryValue?: string | number | null,
) => {
	let result = '';

	if (minSalaryValue) result += '$' + formatSalaryValue(minSalaryValue, maxSalaryValue);
	if (maxSalaryValue) {
		if (result !== '') result += ' to ';

		result += '$' + formatSalaryValue(maxSalaryValue, minSalaryValue);
	}

	if (result !== '') {
		const unit = salaryUnitMapping[salaryUnit!]?.toLowerCase();

		if (unit) result += ` per ${unit}`;
	}

	return result;
};

export const SortProperty = {
	lastName: 'lastName',
	originTag: 'originTag',
	score: 'score',
	views: 'views',
	createdAt: 'createdAt',
	status: 'status',
	commuteScore: 'commuteScore',
};

export const SortOrder = {
	desc: 'desc',
	asc: 'asc',
};

export const DefaultSortOrder = {
	lastName: SortOrder.asc,
	originTag: SortOrder.asc,
	score: SortOrder.desc,
	views: SortOrder.asc,
	createdAt: SortOrder.desc,
	status: SortOrder.asc,
	commuteScore: SortOrder.desc,
};

export const getSecondarySortSettings = (orderBy: string, jobHasPowerProfile: boolean) => {
	const order = orderBy.replace(/(\+asc|\+desc)$/g, '');
	let resultStr = '';

	switch (order) {
		case SortProperty.lastName:
			if (jobHasPowerProfile) resultStr = 'firstName+asc,score+desc';
			else resultStr = 'firstName+asc,createdAt+desc';
			break;
		case SortProperty.originTag:
		case SortProperty.views:
		case SortProperty.status:
			if (jobHasPowerProfile) resultStr = 'score+desc,lastName+asc,firstName+asc';
			else resultStr = 'createdAt+desc,lastName+asc,firstName+asc';
			break;
		case SortProperty.createdAt:
			if (jobHasPowerProfile) resultStr = 'score+desc,lastName+asc,firstName+asc';
			else resultStr = 'lastName+asc,firstName+asc';
			break;
		case SortProperty.score:
		case SortProperty.commuteScore:
			resultStr = 'lastName+asc,firstName+asc';
			break;
		default:
			break;
	}

	return `${resultStr ? ',' : ''}${resultStr}`;
};

export type NameType = {
	firstName: string;
	lastName: string;
};

export type RecipientType = NameType & {
	id: number;
	active?: boolean;
};

export const JobStatusType = {
	inactive: 0,
	active: 10,
};

export const nonScrollablePageOn = () => document.body.classList.add('non-scrollable');
export const nonScrollablePageOff = () => document.body.classList.remove('non-scrollable');

export const CompanyEmailTemplates = {
	rejection: 'application-rejection',
	onsiteInterview: 'onsite-interview-request',
	phoneInterview: 'phone-interview-request',
	additionalInformation: 'additional-information-request',
};

export const getErrorsByProperty = (apiErrors: ErrorType[], keyName: string = 'property') => {
	return apiErrors.reduce(function (groups: any, item: any) {
		const val = item[keyName];
		groups[val] = groups[val] || {};
		groups[val] = { ...item };
		return groups;
	}, {});
};

export const removeErrorsForProperty = (propertyName: string, apiErrors?: ErrorType[]) => {
	if (apiErrors?.length && propertyName) {
		const errorIndex = apiErrors?.findIndex((item) => item.property === propertyName);

		if (errorIndex >= 0) {
			const errors = [...apiErrors];

			errors?.splice(errorIndex, 1);

			return errors;
		}
	}
};

export const clearAndCheckIfEmpty = (str?: string) => {
	if (!str || str === '<p></p>\n') return true;

	let result = str.replace(/\n/g, '').trim();

	const rxForEmptyElements =
		/<.[^>]*>(\s+|()|(&nbsp;)*|\s+(&nbsp;)*|(&nbsp;)*\s+|\s+(&nbsp;)*\s+)<\/.[^>]*>/g;

	const matches = str.match(rxForEmptyElements);

	matches?.forEach((match) => {
		result = result.replace(match, '');
	});

	result = result.replace(/(<([^>]+)>)/gi, '').trim();

	return !result;
};

export const shouldBeDisplayed = (isExternalJob?: boolean, value?: string, altValue?: string) => {
	if (!value && !altValue) return false;

	if (isExternalJob) {
		if (value) return value && !clearAndCheckIfEmpty(value);
		return altValue && !clearAndCheckIfEmpty(altValue);
	}

	return value && !clearAndCheckIfEmpty(value);
};

export const getSliderNumberValue = <T extends { value: any; number: number }>(
	marks: T[],
	defaultValue?: number,
): number | undefined => {
	const chosenObject: T | undefined = marks.find(({ value }) => value === defaultValue);

	return chosenObject?.number;
};

export const getElementWidthByText = (text: string = '') => {
	let width = 0;

	const spanEl = document.createElement('span');
	const dmEl = document.body.appendChild(spanEl);
	dmEl.style.fontSize = '14px';
	dmEl.innerHTML = text;
	width = dmEl.offsetWidth;
	document.body.removeChild(dmEl);

	return width;
};

// to have one method to indicate if company can boost or not
export const checkIfCompanyCanBoost = (company?: Partial<CompanyInterface>) => {
	if (!company || !company?.plan) return false;

	return company?.plan?.isBoostAllowed;
};

export const checkIfCompanyCanSeeAnalytics = (company?: Partial<CompanyInterface>) => {
	if (!company) return false;

	return !company?.isAor;
};

export const checkIfCompanyCanSeeBilling = (company?: Partial<CompanyInterface>) => {
	if (!company) return false;

	return !company?.isAor;
};

export const checkIfCompanyHasPaymentTransactions = (company?: Partial<CompanyInterface>) => {
	if (!company) return false;

	return checkCompanyIsOnJobsWithPaymentPlan(company);
};

export const checkJobIsWithPaymentByCompany = (company?: Partial<CompanyInterface>) => {
	if (!company) return false;

	return checkCompanyIsOnJobsWithPaymentPlan(company);
};

export const checkCompanyIsOnJobsWithPaymentPlan = (company?: Partial<CompanyInterface>) => {
	if (!company || !company?.plan) return false;

	return company?.plan?.uniqueName === process.env.REACT_APP_ON_DEMAND_PLAN_NAME;
};

export const checkIfJobNeedsPayment = (
	job?: Partial<JobInterface>,
	company?: Partial<CompanyInterface>,
	activeCompanyPrepaidJobsBalance?: number,
) => {
	if (!job || !company) return false;

	return !!(
		checkJobIsWithPaymentByCompany(company) &&
		job?.status === JobStatusType.inactive &&
		(company?.jobBalance === undefined || activeCompanyPrepaidJobsBalance === 0)
	);
};

export const TransactionsType = {
	purchase: 'Purchase',
	gift: 'engin gift',
	boost: 'Boost',
	'monthly subscription': 'Monthly plan refill',
	'purchase-job': 'Job listing',
	'increase-job': 'Job listing',
	'decrease-credit': 'Credits Decrease',
};

export const formatStringAsAbsDecimal = (number: string, asLocaleString: boolean = true) => {
	const result = Math.abs(parseFloat(Number(number).toFixed(2)));

	if (asLocaleString) return result.toLocaleString();
	return result;
};

export const getFormattedDatesPeriod = (startDate: string, endDate: string) => {
	if (startDate[startDate?.length - 1] === endDate[endDate?.length - 1]) {
		const separated = startDate?.split('/');
		const start = separated?.[0] + '/' + separated?.[1];
		return `${start} - ${endDate}`;
	}

	return `${startDate} - ${endDate}`;
};

export const getContentDataForGA = (location: any) => {
	if (
		location.pathname.includes(`${APP_URLS.jobs}/`) &&
		location.pathname !== `${APP_URLS.jobs}/`
	) {
		const activeTab =
			new URLSearchParams(location.search).get(QueryParametersKey.tab) || 'candidates';

		return {
			id: GAContentId.job,
			type: GAContentType[activeTab as keyof typeof GAContentType],
			group: GAContentGroup.job,
		};
	}

	if (location.pathname === APP_URLS.jobs || location.pathname === `${APP_URLS.jobs}/`) {
		return {
			id: GAContentId.jobList,
			group: GAContentGroup.jobList,
		};
	}

	if (location.pathname.includes(APP_URLS.account)) {
		const activeTab =
			new URLSearchParams(location.search).get(QueryParametersKey.tab) || 'my-profile';

		return {
			id: GAContentId.account,
			type: GAContentType[activeTab as keyof typeof GAContentType],
			group: GAContentGroup.account,
		};
	}

	if (location.pathname.includes(APP_URLS.boostJob)) {
		return {
			id: GAContentId.boostJob,
			group: GAContentGroup.boostJob,
		};
	}
};

export interface BoostValuesForGAInterface {
	recommendedCredits?: number;
	needMoreCredits?: boolean;
	numberOfOpenings?: number;
}

export interface MotionAnimationType {
	in: any;
	out: any;
	initial?: any;
}
export const getFileExtension = (fileName?: string) => {
	if (!fileName) return '';

	const parts = fileName.split('.');
	return parts[parts.length - 1];
};

export const getFileType = (fileName?: string) => {
	if (!fileName) return '';

	const fileExtension = getFileExtension(fileName);

	if (fileExtension === 'jpeg' || fileExtension === 'jpg') return 'image/jpeg';
	if (fileExtension === 'png') return 'image/png';

	return 'application/pdf';
};

export const getCookie = (name: string) => {
	const cookieArr = document.cookie.split(';');

	for (let i = 0; i < cookieArr.length; i++) {
		const cookiePair = cookieArr[i].split('=');

		if (name === cookiePair[0].trim()) {
			return decodeURIComponent(cookiePair[1]);
		}
	}

	return '';
};

export const setCookie = (name: string, value: string, expirationTime?: Date) => {
	const result = `${name}=${value || ''}; ${
		expirationTime ? `expires=${expirationTime}; ` : ''
	}path=/`;
	document.cookie = result;
};

const originSources = {
	'40_tons': '40 Tons',
	'widget-40_tons': '40 Tons',
	'advancelocal': 'engin AdX',
	'appcast': 'Appcast',
	'widget-cannabiscareers': 'Cannabis Career Academy',
	'cannabis jobs': 'engin AdX Jobs',
	'cannabis+jobs': 'engin AdX',
	'careers in cannabis': 'Careers in Cannabis',
	'careers+in+cannabis': 'Careers in Cannabis',
	'hs_automation': 'Careers in Cannabis',
	'hs_email': 'Careers in Cannabis',
	'mobile_share': 'Careers in Cannabis',
  'widget-partnersite.us': 'Careers in Cannabis',
	'flowerhire': 'Careers page widget',
	'collegerecruiter': 'engin AdX',
	'craigslist': 'engin AdX',
	'attractadx': 'engin AdX',
	'facebook': 'Facebook',
	'google': 'Google',
	'google_jobs_apply': 'Google',
	'grabjobs': 'engin AdX',
	'gfi': 'Green Flower',
	'widget-herogrown': 'HeroGrown',
	'indeed': 'Indeed',
	'indeed levers': 'Indeed',
	'indeed sponsorship': 'Indeed',
	'jobcase': 'engin AdX',
	'widget-justusjobs': 'JUSTÜS Foundation',
	'linkedin': 'LinkedIn',
	'linkin.bio': 'LinkedIn',
	'localstaffing': 'engin AdX',
	'widget-njcba': 'NJCBA',
	'widget-nj-board': 'NJCBA',
	'pandologic': 'engin AdX',
	'recruitology': 'engin AdX',
	'salary.com': 'engin AdX',
	'salary.com sponsorship': 'engin AdX',
	'smart_cannaclub': 'SMART Student MMJ',
	'widget-studentmmj': 'SMART Student MMJ',
	'neuvoo': 'Talent.com',
	'talroo': 'Talroo',
	'trichome institute': 'engin AdX',
	'ziprecruiter': 'ZipRecruiter'
};

export const getSourceTitleByOriginTag = (
	activeCompany?: string | number,
	originTag?: string | null,
) => {
	if (!originTag) return 'Careers in Cannabis';

	const originLowered = originTag.toLocaleLowerCase();
	const result = originSources[originLowered as keyof typeof originSources] || '';

	if (result) return result;

	if (!result) {
		if (originLowered.startsWith('widget-')) {
			const widgetCompany = originLowered.substring(originLowered.indexOf('-') + 1);

			return widgetCompany === activeCompany ? 'Careers page widget' : originTag;
		} else if (originLowered.includes('cinc')) {
			return 'engin talent marketplace';
		}
	}

	return originTag;
};

export const compressImage = async (file: File, maxSizeInMb: number = 1) => {
	const compressedFile = await imageCompression(file, {
		maxSizeMB: maxSizeInMb,
	});

	const processedFile = new File([compressedFile], compressedFile?.name, {
		type: compressedFile?.type,
	});

	return processedFile;
};

export const generatePaymentData = (
	jobId?: number,
	companyId?: string,
	isPostAJobFlow?: boolean,
) => {
	const productName = process.env.REACT_APP_SINGLE_JOB_LISTING_PRODUCT_NAME;

	const baseUrl =
		CustomerAppUrlByEnv[process.env.REACT_APP_ENV as keyof typeof CustomerAppUrlByEnv];

	const dataForLink: PaymentLinkData = {
		jobId,
		companyId,
		productUniqueName: productName || '',
		successUrl: `${baseUrl}${APP_URLS.jobs}/${jobId}?${QueryParametersKey.paymentResult}=${
			PaymentResultStatus.success
		}${isPostAJobFlow ? `&${QueryParametersKey.postAJob}=yes` : ''}`,
		cancelUrl: `${baseUrl}${APP_URLS.jobs}/${jobId}?${QueryParametersKey.paymentResult}=${PaymentResultStatus.cancel}`,
	};

	return dataForLink;
};

export const transformTextToRegularCaseStyle = (text: string) => {
	if (!text) return '';

	const word = text.charAt(0).toUpperCase() + text.substring(1).toLowerCase();

	return word;
};

export const transformGeneratedText = (str: string) => {
	if (!str) return '';

	const transformedStr = str.replace(/\n/g, '<br />').replace(/<li>-/gi, '<li>');

	return transformedStr;
};

export const processCompanyChange = (companyId?: string) => {
	if (!companyId) return;

	const recentCompanies = localStorage.getItem(StorageItemKeys.recentCompanies);

	if (!recentCompanies) {
		localStorage.setItem(StorageItemKeys.recentCompanies, companyId);
		return;
	}

	if (recentCompanies.includes(companyId)) {
		const recentCompaniesArr = recentCompanies.split(',');
		const index = recentCompaniesArr.indexOf(companyId);

		recentCompaniesArr.splice(index, 1);
		recentCompaniesArr.push(companyId);

		localStorage.setItem(StorageItemKeys.recentCompanies, `${recentCompaniesArr.join(',')}`);
		return;
	}

	const recentCompaniesArr = recentCompanies.split(',');

	if (recentCompaniesArr.length < COMPANIES_DROPDOWN_MIN_NUMBER) {
		recentCompaniesArr.push(companyId);

		localStorage.setItem(StorageItemKeys.recentCompanies, `${recentCompaniesArr.join(',')}`);
		return;
	}

	recentCompaniesArr.splice(0, 1);
	recentCompaniesArr.push(companyId);

	localStorage.setItem(StorageItemKeys.recentCompanies, `${recentCompaniesArr.join(',')}`);
};
