import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import JobForm from '../../components/organisms/custom/job-form';
import { clearErrors, getJobInfo } from '../../store/slices/job.slice';
import NestedPopupHeader from '../../components/organisms/popup-headers/nested-popup-header/NestedPopupHeader';
import Loader from '../../components/molecules/loader';

import { JobFormModel, transformJobData } from '../../helpers/custom/job';
import ConfirmationPopup from '../../components/organisms/custom/confirmation-popup';
import { getDepartmentData } from '../../store/slices/jobs-templates.slice';
import useJobsTemplates from '../../helpers/hooks/use-jobs-templates/useJobsTemplates';
import useJob from '../../helpers/hooks/use-job/useJob';
import styles from './EditJob.module.scss';
import useCompanies from '../../helpers/hooks/use-companies/useCompanies';
import useActiveCompany from '../../helpers/hooks/use-active-company/useActiveCompany';
import { cancelGA4Event } from '../../helpers/google-analytics-4/events';
import {
	GAContentGroup,
	GAContentId,
	GAContentType,
	GAMethod,
} from '../../helpers/google-analytics-4/index.constants';
import { getCompanyDataForGA, getJobDataForGA } from '../../helpers/google-analytics-4/helpers';

const EditJob: FC = () => {
	const { id } = useParams();
	const [jobTitle, setJobTitle] = useState('');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	// if user came to this page by direct link there can be troubles with going back in history navigation in some browsers
	//@ts-ignore
	const hasHistoryBackContext = useMemo(
		() => location?.state?.hasHistoryBackContext,
		[location?.state?.hasHistoryBackContext],
	);

	const { departmentsAndLevels } = useJobsTemplates();
	const { info, loading } = useJob();
	const { activeCompanyId, companies } = useCompanies();
	const activeCompany = useActiveCompany(activeCompanyId, companies);

	const [openModal, setOpenModal] = useState(false);
	const [closureConfirmed, setClosureConfirmed] = useState(false);

	const [editFormState, setEditFormState] = useState<JobFormModel>({});

	const companyData = useMemo(
		() => ({
			companyDescription: info.hasExternalApply
				? info.companyDescription || info.facility?.company?.description
				: info.companyDescription,
			benefits: info.hasExternalApply
				? info.benefits || info.facility?.company?.benefits
				: info.benefits,
			eoe: info.hasExternalApply ? info.eoe || info.facility?.company?.eoe : info.eoe,
		}),
		[
			info.benefits,
			info.companyDescription,
			info.eoe,
			info.facility?.company?.benefits,
			info.facility?.company?.description,
			info.facility?.company?.eoe,
			info.hasExternalApply,
		],
	);

	const companyDataFields = useMemo(() => ['companyDescription', 'benefits', 'eoe'], []);

	const onCloseFormClick = useCallback(() => {
		const transformedEditData = transformJobData(editFormState);

		const isNotChanged = Object.keys(transformedEditData).every((key) => {
			if (info[key as keyof typeof info] !== undefined) {
				if (info.hasExternalApply && companyDataFields.indexOf(key) >= 0) {
					return (
						transformedEditData[key as keyof typeof transformedEditData] ===
						//@ts-ignore
						companyData[key]
					);
				} else if (
					key === 'salaryUnit' &&
					!info?.salaryUnit &&
					transformedEditData[key] === 'hourly'
				) {
					// there can be cases for CEJ when salaryUnit was not specified, so 'hourly' is used by default
					return true;
				} else {
					return (
						transformedEditData[key as keyof typeof transformedEditData] ===
						info[key as keyof typeof info]
					);
				}
			} else return true;
		});

		if (activeCompany) {
			cancelGA4Event(
				{
					jobData: getJobDataForGA(info),
					companyData: getCompanyDataForGA(activeCompany),
					contentData: {
						id: GAContentId.job,
						group: GAContentGroup.job,
						type: GAContentType.update,
					},
				},
				{
					method: isNotChanged ? '' : GAMethod.popup,
				},
			);
		}

		if (!isNotChanged) setOpenModal(true);
		else {
			if (hasHistoryBackContext) navigate(-1);
			else navigate(`/jobs/${id}`);
		}
	}, [
		activeCompany,
		companyData,
		companyDataFields,
		editFormState,
		hasHistoryBackContext,
		id,
		info,
		navigate,
	]);

	const renderJobForm = useMemo(
		() => (
			<JobForm
				context="edit"
				hasPowerProfile={
					info && info.hasPowerProfile !== undefined ? info.hasPowerProfile : true
				}
				title={jobTitle}
				setJobTitle={setJobTitle}
				formState={editFormState}
				setFormState={setEditFormState}
				info={info}
				urlId={id}
				onFormLeave={onCloseFormClick}
			/>
		),
		[editFormState, id, info, jobTitle, onCloseFormClick],
	);

	const renderConfirmationPopup = useMemo(
		() => (
			<ConfirmationPopup
				title="Are you sure?"
				description="Are you sure you want to close this window? You'll lose all the job information you've added."
				popupWidth={364}
				backButtonText="No, go back"
				confirmButtonText="Yes, I'm sure"
				open={openModal}
				openStateHandler={setOpenModal}
				onConfirmAndClose={() => {
					dispatch(clearErrors());
					setClosureConfirmed(true);
				}}
			/>
		),
		[dispatch, openModal],
	);

	useEffect(() => {
		if (info && info.title) {
			setJobTitle(info.title);

			setEditFormState({
				title: info.title,
				employmentType: info.employmentType,
				numberOfOpenings: info.numberOfOpenings,
				facilityId: info.facilityId,
				locationType: info.locationType,
				relocationAllowed: info.relocationAllowed,
				minSalaryValue: info.minSalaryValue,
				maxSalaryValue: info.maxSalaryValue,
				salaryUnit: info.salaryUnit || 'hourly',
				department: info.department,
				level: info.level,

				workSeniority: info.workSeniority,
				cannabisSeniority: info.cannabisSeniority,
				requireResume: info.requireResume,
				requireCover: info.requireCover,

				summary: info.summary,
				mustHaveSkills: info.mustHaveSkills,
				niceToHaveSkills: info.niceToHaveSkills,
				companyDescription: companyData.companyDescription,
				benefits: companyData.benefits,
				eoe: companyData.eoe,
			});
		}
	}, [companyData.benefits, companyData.companyDescription, companyData.eoe, info, setJobTitle]);

	useEffect(() => {
		if (id && info.id?.toString() !== id) dispatch(getJobInfo(id));

		if (!departmentsAndLevels) dispatch(getDepartmentData());
	}, [departmentsAndLevels, dispatch, id, info.id]);

	useEffect(() => {
		if (closureConfirmed) {
			if (hasHistoryBackContext) navigate(-1);
			else navigate(`/jobs/${id}`);
		}
	}, [closureConfirmed, dispatch, hasHistoryBackContext, id, navigate]);

	return (
		<div className={styles['edit-job']}>
			<NestedPopupHeader
				withoutLogo={true}
				title={`Edit Job <strong>${jobTitle || ''}</strong>`}
				backgroundColor={'#525668'}
				close={onCloseFormClick}
			/>

			{loading ? (
				<Loader thin minHeight={20} minWidth={20} className={styles.loader} />
			) : (
				<>
					{renderJobForm}

					{renderConfirmationPopup}
				</>
			)}
		</div>
	);
};

export default React.memo(EditJob);
