import React, { FC } from 'react';
import ReactHtmlParser from 'react-html-parser';

import OptionInterface from './option.interface';

import styles from './Option.module.scss';

const Option: FC<OptionInterface> = ({ children, indent, ...rest }) => {
	const getIndent = () => {
		let indentElement = '';

		if (indent) {
			const array: any[] = [];
			array.length = indent;
			array.fill('');

			array.forEach(() => {
				indentElement += '&#160;&#160;&#160;&#160;';
			});
		}

		return indentElement;
	};

	return (
		<option className={styles.option} {...rest}>
			{indent ? ReactHtmlParser(getIndent()) : null}

			{children}
		</option>
	);
};

export default Option;
