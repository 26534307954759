import { useSelector } from 'react-redux';
import { NotesSliceInterface } from '../../../store/slices/notes.slice';

export default function useNotes() {
	const {
		notes: { items: noteItems, loading: notesLoading, adding: addingNote, userId },
	} = useSelector((store: { notes: NotesSliceInterface }) => store);

	return {
		noteItems,
		notesLoading,
		addingNote,
		userId,
	};
}
