import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import DropdownInterface from './dropdown.interface';
import DropdownToggle from '../../atoms/ui/dropdown-toggle';
import DropdownContent from '../dropdown-content';

import styles from './Dropdown.module.scss';
import ClickAwayListener from '../../../helpers/hocs/click-away-listener/ClickAwayListener';

const Dropdown: FC<DropdownInterface> = ({
	toggle,
	dropdownContent,
	className,
	dropdownContentClassName,
	open,
	onOpenStateChangeHandler,
}) => {
	const [openState, setOpenState] = useState(false);
	const dropdownContentRef = useRef(null);

	const toggleOpenState = useCallback(() => {
		setOpenState((prevState) => {
			if (onOpenStateChangeHandler) onOpenStateChangeHandler(!prevState);
			return !prevState;
		});
	}, [onOpenStateChangeHandler]);

	const onClickAwayHandler = useCallback(() => {
		if (onOpenStateChangeHandler) onOpenStateChangeHandler(false);
		setOpenState.bind(null, false)();
	}, [onOpenStateChangeHandler]);

	useEffect(() => {
		if (open !== undefined) setOpenState(open);
	}, [open]);

	return (
		<ClickAwayListener onClickAway={onClickAwayHandler}>
			<div className={classNames(styles.dropdown, className)}>
				<DropdownToggle open={openState} onClick={toggleOpenState}>
					{toggle}
				</DropdownToggle>

				<DropdownContent
					className={dropdownContentClassName}
					ref={dropdownContentRef}
					open={openState}
				>
					{dropdownContent}
				</DropdownContent>
			</div>
		</ClickAwayListener>
	);
};

export default React.memo(Dropdown);
