import React, { FC } from 'react';
import classNames from 'classnames';

import { calculatePercent } from '../../../../../helpers/charts';
import HorizontalRangeBarInterface from './horizontal-range-bar.interface';

import styles from './HorizontalRangeBar.module.scss';

const HorizontalRangeBar: FC<HorizontalRangeBarInterface> = ({
	crossing,
	maxItemValue,
	maxValue,
	preview,
}) => {
	return (
		<span
			className={classNames(
				styles.bar,
				styles.crossing,
				crossing && crossing[0] === 0 && styles['starts-from-zero'],
				crossing &&
					maxItemValue !== undefined &&
					maxItemValue <= crossing[0] &&
					styles.failed,
				preview && styles.preview,
			)}
			style={
				crossing && {
					left: `${calculatePercent(crossing[0], maxValue)}%`,
					width: `${calculatePercent(crossing[1] - crossing[0], maxValue)}%`,
				}
			}
		/>
	);
};

export default HorizontalRangeBar;
