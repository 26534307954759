import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { changeJobStatus, getJobPaymentLink } from '../../../../store/slices/job.slice';
import {
	JobStatusType,
	RecipientType,
	checkIfJobNeedsPayment,
	generatePaymentData,
	checkJobIsWithPaymentByCompany,
} from '../../../../helpers/custom/common';
import Container from '../../../templates/container';
import TypographyText from '../../../atoms/ui/typography-text';
import TextChip from '../../../atoms/ui/text-chip/TextChip';
import TextChipsGroup from '../../../molecules/ui/text-chips-group/TextChipsGroup';
import ButtonLink from '../../../atoms/buttons/button-link';
import SectionTitle from '../../../atoms/ui/section-title';
import ManageRecipientsScreen from '../manage-recipients-screen';
import {
	changeJobNotificationsSettings,
	getJobSubscribers,
} from '../../../../store/slices/user.slice';
import Loader from '../../../molecules/loader';

import styles from './JobSettings.module.scss';
import InfoPopover from '../../../molecules/ui/info-popover';
import Button from '../../../atoms/buttons/button/Button';
import ConfirmationPopup from '../confirmation-popup';
import { checkJobIsBoosted } from '../../../../helpers/custom/job';
import useJob from '../../../../helpers/hooks/use-job/useJob';
import {
	activateJobGA4Event,
	deactivateJobGA4Event,
	paymentIntentGA4Event,
} from '../../../../helpers/google-analytics-4/events';
import {
	getCompanyDataForGA,
	getJobDataForGA,
} from '../../../../helpers/google-analytics-4/helpers';
import useCompanies from '../../../../helpers/hooks/use-companies/useCompanies';
import useActiveCompany from '../../../../helpers/hooks/use-active-company/useActiveCompany';
import {
	GAContentGroup,
	GAContentId,
	GAJobStatus,
	GAMethod,
} from '../../../../helpers/google-analytics-4/index.constants';
import { GAContentType } from '../../../../helpers/google-analytics-4/index.constants';
import PaymentConfirmationPopup from '../payment-confirmation-popup';
import { getActiveCompanyPrepaidJobsBalance } from '../../../../store/slices/companies.slice';
import PrepaidPublishConfirmationPopup from '../prepaid-publish-confirmation-popup';
import ErrorNotification from '../../../molecules/ui/errors-notification';
import { getFormattedDateWithLongMonth } from '../../../../helpers/date/date';

const JobSettings: FC = () => {
	const dispatch = useDispatch();

	const { info, statusLoading } = useJob();
	const { status, jobProfileTitle, hasExternalApply, id: jobId, boostId, jobBoosts } = info || {};

	const {
		activeCompanyId,
		companies,
		prepaidJobsBalanceUpdateLoading,
		activeCompanyPrepaidJobsBalance,
	} = useCompanies();
	const activeCompany = useActiveCompany(activeCompanyId, companies);

	const [openBoostWarningModal, setOpenBoostWarningModal] = useState(false);
	const [openPaidJobDeactivationModal, setOpenPaidJobDeactivationModal] = useState(false);
	const [deactivationConfirmed, setDeactivationConfirmed] = useState(false);

	const [manageRecipientsOpen, setManageRecipientsOpen] = useState(false);

	const [allRecipients, setAllRecipients] = useState<RecipientType[]>([]);
	const activeRecipients = allRecipients.filter((recipient) => recipient.active);
	const [recipientsLoading, setRecipientsLoading] = useState(false);
	const [removingInProgress, setRemovingInProgress] = useState(false);
	const [openPaymentConfirmationModal, setOpenPaymentConfirmationModal] = useState(false);
	const [paymentLinkLoading, setPaymentLinkLoading] = useState(false);
	const [openPrepaidPublishConfirmationModal, setOpenPrepaidPublishConfirmationModal] =
		useState(false);
	const [paymentRelatedError, setPaymentRelatedError] = useState(false);

	const isBoosted = useMemo(
		() => checkJobIsBoosted(boostId, jobBoosts, status),
		[boostId, jobBoosts, status],
	);

	const isJobWithPayment = useMemo(
		() => checkJobIsWithPaymentByCompany(activeCompany),
		[activeCompany],
	);

	const jobNeedsPayment = useMemo(
		() => checkIfJobNeedsPayment(info, activeCompany, activeCompanyPrepaidJobsBalance),
		[activeCompany, info, activeCompanyPrepaidJobsBalance],
	);

	const gaEventData = useMemo(
		() => ({
			jobData: getJobDataForGA(info),
			companyData: getCompanyDataForGA(activeCompany),
			contentData: {
				id: GAContentId.job,
				group: GAContentGroup.job,
				type: GAContentType.settings,
			},
		}),
		[activeCompany, info],
	);

	const onPaymentConfirm = useCallback(async () => {
		setPaymentLinkLoading(true);

		if (activeCompany) paymentIntentGA4Event(gaEventData, '', GAMethod.popup);

		const dataForLink = generatePaymentData(Number(info?.id), `${activeCompanyId}`);
		const generatedLink = await dispatch(getJobPaymentLink(dataForLink));

		if (generatedLink) window.location.href = `${generatedLink}`;
		else setPaymentRelatedError(true);

		setPaymentLinkLoading(false);
	}, [activeCompany, activeCompanyId, dispatch, gaEventData, info?.id]);

	const renderPaymentConfirmationPopup = useMemo(() => {
		return (
			<PaymentConfirmationPopup
				openState={openPaymentConfirmationModal}
				openStateHandler={setOpenPaymentConfirmationModal}
				onConfirmHandler={onPaymentConfirm}
				loading={paymentLinkLoading}
				gaEventData={gaEventData}
			/>
		);
	}, [openPaymentConfirmationModal, onPaymentConfirm, paymentLinkLoading, gaEventData]);

	const statusChangeHandler = useCallback(() => {
		const newStatus =
			status === JobStatusType.active ? JobStatusType.inactive : JobStatusType.active;

		if (newStatus === JobStatusType.active)
			activateJobGA4Event(gaEventData, info?.numberOfOpenings);

		if (newStatus === JobStatusType.inactive)
			deactivateJobGA4Event(gaEventData, info?.numberOfOpenings);

		if (jobId) {
			(async () => {
				const result = await dispatch(changeJobStatus(jobId, newStatus));

				//@ts-ignore
				if (result?.message) setPaymentRelatedError(true);
			})();
		}
	}, [dispatch, info, jobId, status, gaEventData]);

	const renderPrepaidPublishConfirmationPopup = useMemo(() => {
		return (
			<PrepaidPublishConfirmationPopup
				openState={openPrepaidPublishConfirmationModal}
				openStateHandler={setOpenPrepaidPublishConfirmationModal}
				onConfirmHandler={statusChangeHandler}
			/>
		);
	}, [openPrepaidPublishConfirmationModal, statusChangeHandler]);

	const renderPaidJobDeactivationPopup = useMemo(() => {
		return (
			<ConfirmationPopup
				title="Are you sure?"
				titleClassName={styles['modal-title']}
				description={
					<span className={styles.text}>
						You are about to unpublish a paid job.{' '}
						<span className={styles.highlighted}>
							You will need to pay again to republish this job.
						</span>
					</span>
				}
				descriptionClassName={styles['modal-description']}
				popupWidth={387}
				backButtonText="Cancel"
				confirmButtonText="Yes, unpublish job"
				open={openPaidJobDeactivationModal}
				btnsReversedOrder
				openStateHandler={setOpenPaidJobDeactivationModal}
				onConfirmAndClose={() => setDeactivationConfirmed(true)}
			/>
		);
	}, [openPaidJobDeactivationModal]);

	const onJobStatusChange = useCallback(() => {
		if (status === JobStatusType.inactive && jobNeedsPayment) {
			setOpenPaymentConfirmationModal(true);
			return;
		}

		if (status === JobStatusType.inactive && isJobWithPayment && !jobNeedsPayment) {
			setOpenPrepaidPublishConfirmationModal(true);
			return;
		}

		if (status === JobStatusType.active && isJobWithPayment) {
			setOpenPaidJobDeactivationModal(true);
			return;
		}

		if (isBoosted && status === JobStatusType.active) {
			setOpenBoostWarningModal(true);
			return;
		}

		statusChangeHandler();
	}, [isBoosted, isJobWithPayment, jobNeedsPayment, status, statusChangeHandler]);

	const onRecipientRemove = useCallback(
		async (id: number) => {
			if (jobId && id) {
				setRemovingInProgress(true);

				const result = await dispatch(
					changeJobNotificationsSettings(jobId, [
						{
							settingsName: 'newRelevantApplication',
							settingsValue: false,
							userId: id,
						},
					]),
				);

				if (!result) {
					const recipients = await dispatch(
						getJobSubscribers(jobId, 'newRelevantApplication'),
					);

					//@ts-ignore
					if (recipients) setAllRecipients(recipients);
				}

				setRemovingInProgress(false);
			}
		},
		[dispatch, jobId],
	);

	const renderRecipients = useMemo(() => {
		if (activeRecipients && activeRecipients.length) {
			return (
				<div className={styles.recipients}>
					<TextChipsGroup>
						{activeRecipients.map((recipient) => {
							return (
								<TextChip
									key={recipient.id}
									item={{
										id: recipient.id,
										text: `${recipient.firstName} ${recipient.lastName}`,
									}}
									onRemoveHandler={() => onRecipientRemove(recipient.id)}
								/>
							);
						})}
					</TextChipsGroup>
				</div>
			);
		} else {
			return (
				<TypographyText className={styles.empty}>
					No recipients have been subscribed to this notification.
				</TypographyText>
			);
		}
	}, [activeRecipients, onRecipientRemove]);

	const updateRecipients = useCallback(async () => {
		if (jobId) {
			(async () => {
				setRecipientsLoading(true);

				const recipients = await dispatch(
					getJobSubscribers(jobId, 'newRelevantApplication'),
				);

				//@ts-ignore
				if (recipients) setAllRecipients(recipients);
				setRecipientsLoading(false);
			})();
		}
	}, [dispatch, jobId]);

	const afterRecipientsChange = useCallback(() => updateRecipients(), [updateRecipients]);

	const renderNotificationSection = useMemo(() => {
		return (
			<div className={styles.notifications}>
				<div className={styles['notifications-title']}>Notifications</div>

				<SectionTitle className={styles['section-title']}>
				{ jobProfileTitle? 'New relevant application' : 'New application' }
					{removingInProgress ? (
						<Loader
							className={styles['title-loader']}
							maxWidth={15}
							maxHeight={15}
							thin
						/>
					) : null}
					<ButtonLink
						className={styles['btn-link']}
						onClick={() => setManageRecipientsOpen(true)}
					>
						{recipientsLoading
							? ''
							: activeRecipients && activeRecipients.length
							? 'Manage recipients'
							: 'Add recipients'}
					</ButtonLink>
				</SectionTitle>

				<TypographyText className={styles.description}>
				{ jobProfileTitle? 'Email with each new relevant application.' : 'Email with each application.' }
				</TypographyText>

				{recipientsLoading ? (
					<Loader className={styles.loader} maxHeight={20} maxWidth={20} thin />
				) : (
					renderRecipients
				)}
			</div>
		);
	}, [activeRecipients, recipientsLoading, removingInProgress, renderRecipients, jobProfileTitle]);

	const renderPPInfoBoxContent = useMemo(() => {
		return (
			<div className={styles['info-content']}>
				<div className={styles.title}>PowerProfile™</div>

				<div className={styles.text}>
					Power profiles are professionally-curated job templates that are optimized for
					SEO and allow job seekers to check their fit.
				</div>
			</div>
		);
	}, []);

	const renderProfileSection = useMemo(() => {
		const title = jobProfileTitle
			? jobProfileTitle
			: 'This job is not based on a PowerProfile.';

		return (
			<div className={styles['settings-section']}>
				<SectionTitle className={styles['section-header']}>
					PowerProfile™
					<InfoPopover
						className={styles['info-box']}
						defaultTrigger
						contentClassName={styles['popover-width']}
					>
						{renderPPInfoBoxContent}
					</InfoPopover>
				</SectionTitle>

				<TypographyText className={styles['profile-name']}>{title}</TypographyText>
			</div>
		);
	}, [jobProfileTitle, renderPPInfoBoxContent]);

	const renderStatusText = useMemo(() => {
		if (status === JobStatusType.inactive) {
			if (jobNeedsPayment) {
				return (
					<span className={styles.text}>
						This job is inactive. Payment is required to publish this job.
					</span>
				);
			}

			return (
				<span className={styles.text}>
					This job is <span className={styles.highlight}>inactive</span>. It is not
					visible and is not able to accept candidates.
				</span>
			);
		}

		if (status === JobStatusType.active) {
			if (isBoosted)
				return (
					<span className={styles.text}>
						This job is active and currently being boosted.{' '}
						<span className={styles.highlight}>
							If you unpublish this job, the boost will be canceled - no credit
							refunds are issued.
						</span>
					</span>
				);

			if (isJobWithPayment) {
				return (
					<span className={styles.text}>
						This job is active. Payment was completed on{' '}
						{getFormattedDateWithLongMonth(info?.publishedAt || '')}.
					</span>
				);
			}

			return (
				<span className={styles.text}>
					Job status is active. This job is published and accepting applications.
				</span>
			);
		}
	}, [status, jobNeedsPayment, isBoosted, isJobWithPayment, info.publishedAt]);

	const renderStatusSection = useMemo(() => {
		const renderBtnContent = () => {
			if (statusLoading || prepaidJobsBalanceUpdateLoading)
				return <Loader thin maxHeight={20} maxWidth={20} />;

			if (status === JobStatusType.active) return 'Unpublish job';

			if (status === JobStatusType.inactive) {
				if (jobNeedsPayment) return 'Pay & Publish job';
				return 'Publish job';
			}
		};

		return (
			<div className={styles['settings-section']}>
				<SectionTitle className={styles['section-header']}>Status</SectionTitle>

				<div className={styles['status-info']}>{renderStatusText}</div>

				<Button
					reversedStyles
					small
					minWidth={180}
					className={styles['activation-btn']}
					onClick={onJobStatusChange}
				>
					{renderBtnContent()}
				</Button>
			</div>
		);
	}, [
		jobNeedsPayment,
		onJobStatusChange,
		prepaidJobsBalanceUpdateLoading,
		renderStatusText,
		status,
		statusLoading,
	]);

	const renderBoostedDeactivationPopup = useMemo(() => {
		return (
			<ConfirmationPopup
				title="Are you sure?"
				titleClassName={styles['modal-title']}
				description={
					<span className={styles.text}>
						You are about to unpublish a job that is currently being boosted.{' '}
						<span className={styles.highlighted}>
							Your boost will be canceled and credits lost.
						</span>
					</span>
				}
				descriptionClassName={styles['modal-description']}
				popupWidth={387}
				backButtonText="Cancel"
				confirmButtonText="Yes, unpublish job"
				open={openBoostWarningModal}
				btnsReversedOrder
				openStateHandler={setOpenBoostWarningModal}
				onConfirmAndClose={() => setDeactivationConfirmed(true)}
			/>
		);
	}, [openBoostWarningModal]);

	useEffect(() => {
		if (
			activeCompanyId &&
			checkJobIsWithPaymentByCompany(activeCompany) &&
			status === JobStatusType.inactive
		) {
			dispatch(getActiveCompanyPrepaidJobsBalance(activeCompanyId));
		}
	}, [activeCompany, activeCompanyId, dispatch, status]);

	useEffect(() => {
		if (deactivationConfirmed && jobId) {
			deactivateJobGA4Event(gaEventData, info?.numberOfOpenings, GAJobStatus.boosted);

			dispatch(changeJobStatus(jobId, JobStatusType.inactive));
			setDeactivationConfirmed(false);
		}
	}, [activeCompany, deactivationConfirmed, dispatch, gaEventData, info, jobId]);

	useEffect(() => {
		updateRecipients();
	}, [jobId, updateRecipients]);

	return (
		<>
			<Container maxWidth="720px" className={styles['job-settings']}>
				{renderProfileSection}

				{renderStatusSection}

				{!hasExternalApply ? renderNotificationSection : null}
			</Container>

			<ManageRecipientsScreen
				jobId={jobId}
				recipients={allRecipients}
				afterRecipientsChange={afterRecipientsChange}
				open={manageRecipientsOpen}
				close={() => setManageRecipientsOpen(false)}
			/>

			{isBoosted ? renderBoostedDeactivationPopup : null}

			{isJobWithPayment && status === JobStatusType.inactive
				? renderPrepaidPublishConfirmationPopup
				: null}

			{isJobWithPayment && status === JobStatusType.active
				? renderPaidJobDeactivationPopup
				: null}

			{jobNeedsPayment ? renderPaymentConfirmationPopup : null}

			{paymentRelatedError ? (
				<ErrorNotification message="Sorry, something went wrong." />
			) : null}
			
			{paymentLinkLoading ? <Loader wide withOverlay /> : null}
		</>
	);
};

export default JobSettings;
