import { generateGA4Event } from '.';
import { CommonEventDataInterface, EventParamsInterface } from './index.interface';

const generateCommonEvent = (
	eventName: string,
	commonData: CommonEventDataInterface,
	params?: EventParamsInterface,
) => {
	const { jobData, companyData, contentData } = commonData;
	const {
		method,
		appliedCredits,
		recommendedCredits,
		numberOfOpenings,
		needMoreCredits,
		itemBrand,
		itemId,
		price,
		jobStatus,
	} = params || {};

	generateGA4Event(eventName, {
		...(jobData && {
			job_id: jobData.jobId,
			job_category: jobData.jobCategory,
			job_profile: jobData.jobProfile,
			job_state: jobData.jobState,
			job_department: jobData.jobDepartment,
			job_level: jobData.jobLevel,
		}),
		...(companyData && {
			company_id: companyData.companyId,
			company_name: companyData.companyName,
			...(companyData.companyType && {
				company_type: companyData.companyType,
			}),
		}),
		...(contentData && {
			content_id: contentData.id,
			content_type: contentData.type,
			content_group: contentData.group,
		}),
		...(method && {
			method,
		}),
		...(appliedCredits && {
			applied_credits: appliedCredits,
		}),
		...(recommendedCredits && {
			recommended_credits: recommendedCredits,
		}),
		...(numberOfOpenings && {
			number_of_openings: numberOfOpenings,
		}),
		...(needMoreCredits !== undefined && {
			need_more_credits: needMoreCredits ? 'yes' : 'no',
		}),
		...(itemBrand && {
			item_brand: itemBrand,
		}),
		...(itemId && {
			item_id: itemId,
		}),
		...(price && {
			price,
		}),
		...(jobStatus && {
			job_status: jobStatus,
		}),
	});
};

export const boostJobIntentGA4Event = (data: CommonEventDataInterface, method?: string) => {
	generateCommonEvent('boost_job_intent', data, { method });
};

export const boostJobGA4Event = (data: CommonEventDataInterface, params: EventParamsInterface) => {
	generateCommonEvent('boost_job', data, params);
};

export const addCreditsIntentGA4Event = (
	data: CommonEventDataInterface,
	params?: EventParamsInterface,
) => {
	generateCommonEvent('add_credits_intent', data, params);
};

export const addCreditsGA4Event = (
	data: CommonEventDataInterface,
	itemId: number,
	price: string,
) => {
	generateCommonEvent('add_credits', data, { itemId, price });
};

export const learnMoreGA4Event = (
	data: CommonEventDataInterface,
	params?: EventParamsInterface,
) => {
	generateCommonEvent('learn_more', data, params);
};

export const cancelGA4Event = (data: CommonEventDataInterface, params?: EventParamsInterface) => {
	generateCommonEvent('cancel', data, params);
};

export const backGA4Event = (data: CommonEventDataInterface) => {
	generateCommonEvent('back', data);
};

export const activateJobGA4Event = (data: CommonEventDataInterface, numberOfOpenings?: number) => {
	generateCommonEvent('activate_job', data, { numberOfOpenings });
};

export const deactivateJobGA4Event = (
	data: CommonEventDataInterface,
	numberOfOpenings?: number,
	jobStatus?: string,
) => {
	generateCommonEvent('deactivate_job', data, { numberOfOpenings, jobStatus });
};

export const deleteJobGA4Event = (
	data: CommonEventDataInterface,
	numberOfOpenings?: number,
	jobStatus?: string,
) => {
	generateCommonEvent('delete_job', data, { numberOfOpenings, jobStatus });
};

export const createJobGA4Event = (data: CommonEventDataInterface, numberOfOpenings?: number, itemBrand?: string) => {
	generateCommonEvent('create_job', data, { numberOfOpenings, itemBrand });
};

export const createAndActivateJobGA4Event = (
	data: CommonEventDataInterface,
	numberOfOpenings?: number,
	itemBrand?: string,
) => {
	generateCommonEvent('create_and_activate_job', data, { numberOfOpenings, itemBrand });
};

export const loginIntentGA4Event = (data: CommonEventDataInterface, itemBrand?: string) => {
	generateCommonEvent('login_intent', data, { itemBrand });
};

export const validatedGA4Event = (data: CommonEventDataInterface, itemBrand?: string) => {
	generateCommonEvent('validated', data, { itemBrand });
};

export const registeredGA4Event = (data: CommonEventDataInterface, itemBrand?: string) => {
	generateCommonEvent('registered', data, { itemBrand });
};

export const termsGA4Event = (data: CommonEventDataInterface, itemBrand?: string) => {
	generateCommonEvent('terms', data, { itemBrand });
};

export const browseJobsGA4Event = (data: CommonEventDataInterface, itemBrand?: string) => {
	generateCommonEvent('browse_jobs', data, { itemBrand });
};

export const resendCodeGA4Event = (data: CommonEventDataInterface, itemBrand?: string) => {
	generateCommonEvent('resend_code', data, { itemBrand });
};

export const companyCreatedGA4Event = (data: CommonEventDataInterface, itemBrand?: string) => {
	generateCommonEvent('company_created', data, { itemBrand });
};

export const jobProfileSelectionGA4Event = (data: CommonEventDataInterface, itemBrand?: string) => {
	generateCommonEvent('job_profile_selection', data, { itemBrand });
};

export const jobBasicsGA4Event = (data: CommonEventDataInterface, itemBrand?: string) => {
	generateCommonEvent('job_basics', data, { itemBrand });
};

export const jobRequirementsGA4Event = (data: CommonEventDataInterface, itemBrand?: string) => {
	generateCommonEvent('job_requirements', data, { itemBrand });
};

export const jobDetailsGA4Event = (data: CommonEventDataInterface, itemBrand?: string) => {
	generateCommonEvent('job_details', data, { itemBrand });
};

export const jobCompanyDetailsGA4Event = (data: CommonEventDataInterface, itemBrand?: string) => {
	generateCommonEvent('job_company_details', data, { itemBrand });
};

export const jobPreviewGA4Event = (data: CommonEventDataInterface, itemBrand?: string) => {
	generateCommonEvent('job_preview', data, { itemBrand });
};

export const paymentIntentGA4Event = (data: CommonEventDataInterface, itemBrand?: string, method?: string) => {
	generateCommonEvent('payment_intent', data, { itemBrand, method });
};

export const paymentIntentCancelGA4Event = (data: CommonEventDataInterface, itemBrand?: string, method?: string) => {
	generateCommonEvent('payment_intent_cancel', data, { itemBrand, method });
};

export const paymentSuccessGA4Event = (data: CommonEventDataInterface, itemBrand?: string) => {
	generateCommonEvent('payment_success', data, { itemBrand });
};

export const paymentCancelGA4Event = (data: CommonEventDataInterface) => {
	generateCommonEvent('payment_cancel', data);
};

export const createJobIntentGA4Event = (data: CommonEventDataInterface) => {
	generateCommonEvent('create_job_intent', data);
};
