import React, { FC } from 'react';
import classNames from 'classnames';

import SlideFormHeadingInterface from './slide-form-heading.interface';
import Title from '../../../atoms/title/Title';
import TypographyText from '../../../atoms/ui/typography-text';
import ReactHtmlParser from 'react-html-parser';

import styles from './SlideFormHeading.module.scss';

const SlideFormHeading: FC<SlideFormHeadingInterface> = ({
	title,
	description,
	className,
	titleSettings,
	descriptionSettings,
	...rest
}) => {
	return (
		<div className={classNames(styles.wrap, className)} {...rest}>
			{title && (
				<Title
					component={'h3'}
					fontSize={20}
					fontWeight={900}
					marginBottom={12}
					{...titleSettings}
				>
					{title}
				</Title>
			)}

			{description && (
				<TypographyText
					{...descriptionSettings}
					className={classNames(styles.desc, descriptionSettings?.className)}
				>
					{description && typeof description === 'string'
						? ReactHtmlParser(description)
						: description}
				</TypographyText>
			)}
		</div>
	);
};

export default SlideFormHeading;
