import { useEffect } from 'react';

import { useQuery } from '../../use-query/useQuery';
import { QueryParametersKey, StorageItemKeys } from '../../../constants';

const setUtmSource = () => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const query = useQuery();
	const originTag = query.get(QueryParametersKey.utmSource);

	// eslint-disable-next-line react-hooks/rules-of-hooks
	useEffect(() => {
		if (sessionStorage && originTag) {
			sessionStorage.setItem(StorageItemKeys.originTag, originTag);
		}
	}, [originTag]);
};

export default setUtmSource;
