import React, { FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { CompanyInterface, editCompany } from '../../../../store/slices/companies.slice';
import { RootStateType } from '../../../../store/reducers/root-reducer';
import EditableViewForm from '../../editable-view-form';
import EditCompanyDescriptionSectionInterface from './edit-company-description-section.interface';
import useActiveCompany from '../../../../helpers/hooks/use-active-company/useActiveCompany';
import EditableTextEditor from '../../editable-text-editor';
import { EDITOR_EMPTY_STATE } from '../../../../helpers/constants';

const EditCompanyDescriptionSection: FC<EditCompanyDescriptionSectionInterface> = () => {
	const { register, handleSubmit } = useForm();
	const dispatch = useDispatch();
	const [editModeState, setEditModeState] = useState<boolean>();

	const {
		companies: { activeCompanyId, items, loading },
	} = useSelector((state: RootStateType) => state);
	const activeCompany = useActiveCompany(activeCompanyId, items);

	const onSubmit = useCallback(
		(data: Partial<CompanyInterface>) => {
			setEditModeState(true);

			data.description = data.description === EDITOR_EMPTY_STATE ? '' : data.description;

			if (activeCompany) {
				dispatch<any>(editCompany(activeCompany.id, data)).then(() =>
					setEditModeState(false),
				);
			}
		},
		[activeCompany, dispatch],
	);

	return (
		<EditableViewForm
			{...{ editModeState, loading }}
			onSubmit={handleSubmit(onSubmit)}
			sectionTitle="Company description"
		>
			<EditableTextEditor
				text={activeCompany?.description}
				textareaSettings={{ name: 'description', ref: register() }}
			/>
		</EditableViewForm>
	);
};

export default EditCompanyDescriptionSection;
