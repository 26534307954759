import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';

import styles from './TextEllipsis.module.scss';
import TextEllipsisInterface from './text-ellipsis.interface';
import Tooltip from '../../../atoms/ui/tooltip';
import { Link } from 'react-router-dom';
import { getElementWidthByText } from '../../../../helpers/custom/common';
import ResizeDeviceContext from '../../../../contexts/resize-device-context';

const TextEllipsis: FC<TextEllipsisInterface> = ({ text, linkUrl, noLinkStyles }) => {
	const wrapRef = useRef<HTMLDivElement | null>(null);
	const [wrapWidth, setWrapWidth] = useState<number>();

	const device = useContext(ResizeDeviceContext);

	const updateSize = useCallback(() => {
		//@ts-ignore
		setWrapWidth(wrapRef?.current?.offsetParent?.offsetWidth!);
	}, []);

	useEffect(() => updateSize(), [updateSize, device]);

	useEffect(() => {
		window.addEventListener('resize', updateSize);

		return () => window.removeEventListener('resize', updateSize);
	}, [updateSize]);

	const checkAndRenderLongText = useMemo(() => {
		if (wrapWidth === undefined) return;

		let needsEllipsis = false;

		const width = getElementWidthByText(text);

		needsEllipsis = width > (wrapWidth - 20);

		const renderLink = () => {
			return (
				<Link
					className={classNames(styles.link, noLinkStyles ? styles['as-text'] : '')}
					to={linkUrl!}
				>
					{text}
				</Link>
			);
		};

		if (needsEllipsis) {
			return (
				<div className={classNames(styles.item, styles['item-with-tooltip'])}>
					<span
						className={classNames(
							styles['with-overflow'],
							linkUrl ? styles['as-link'] : '',
							noLinkStyles ? styles['as-text'] : '',
						)}
						style={{ width: wrapWidth - 20}}
					>
						{linkUrl ? renderLink() : text}
					</span>

					<Tooltip className={styles['item-tooltip']} text={text} />
				</div>
			);
		} else return linkUrl ? renderLink() : text;
	}, [linkUrl, noLinkStyles, text, wrapWidth]);

	return (
		<div className={styles['ellipsis-wrap']} ref={wrapRef}>
			{checkAndRenderLongText}
		</div>
	);
};

export default TextEllipsis;
