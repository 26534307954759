import React, { FC } from 'react';
import SpecificScoreBarInterface from './specific-score-bar.interface';
import styles from './SpecificScoreBar.module.scss';

const SpecificScoreBar: FC<SpecificScoreBarInterface> = ({ score, width, height = 16 }) => {
	const inlineProgressStyle = {
		width: `${score}%`,
		borderRadius: `${height / 2}px`,
	};

	return (
		<span
			className={styles['progress-container']}
			style={{
				width: `${width}px`,
				height: `${height}px`,
				borderRadius: `${height / 2}px`,
			}}
		>
			<span style={inlineProgressStyle} className={styles.progress} />
		</span>
	);
};

export default SpecificScoreBar;
