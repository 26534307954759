import React, { FC } from 'react';

import styles from './TableHead.module.scss';
import TableHeadInterface from './table-head.interface';

const TableHead: FC<TableHeadInterface> = ({ children }) => {
	return <div className={styles['table-head']}>{children}</div>;
};

export default TableHead;
