import React, { FC, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import {
	changeUserEmail,
	UserInfoInterface,
	UserInterface,
} from '../../../../store/slices/user.slice';
import MyProfileScreenInterface from './my-profile-screen.interface';
import EditableAccountDetailsViewForm from '../editable-account-details-view-form';
import EditableViewForm from '../../editable-view-form';
import InputWithFloatLabel from '../../form-elemets/input-with-float-label/InputWithFloatLabel';
import Container from '../../../templates/container';
import LinkButton from '../../../atoms/buttons/link-button/LinkButton';
import { ExternalErrorType } from '../../../../helpers/axios/axios-global';
import useExternalFormErrors from '../../../../helpers/hooks/use-external-form-errors';
import {
	CompaniesSliceInterface,
	setCompaniesErrors,
} from '../../../../store/slices/companies.slice';
import EditableAccountCommunicationsForm from '../editable-account-communications-form';
import useActiveCompany from '../../../../helpers/hooks/use-active-company/useActiveCompany';
import ResizeDeviceContext from '../../../../contexts/resize-device-context';

const MyProfileScreen: FC<MyProfileScreenInterface> = ({ className }) => {
	const dispatch = useDispatch();
	const { register, handleSubmit, clearErrors } = useForm();
	const [editModeState, setEditModeState] = useState<boolean>(false);
	const [externalErrors, setExternalErrors] = useState<Partial<ExternalErrorType>>();
	const normalizedExternalErrors = useExternalFormErrors(externalErrors);
	const [cancelState, setCancelState] = useState<boolean>(true);
	const [values, setValues] = useState<Pick<UserInfoInterface, 'email'>>({ email: '' });
	const {
		companies: { activeCompanyId, items: companies },
		user: { info: userInfo },
	} = useSelector((store: { companies: CompaniesSliceInterface; user: UserInterface }) => store);

	const activeCompany = useActiveCompany(activeCompanyId, companies);

	const device = useContext(ResizeDeviceContext);

	useEffect(() => {
		if (userInfo?.email) {
			setValues({
				email: userInfo.email,
			});

			clearErrors();
			dispatch(setCompaniesErrors([]));
		}
		// eslint-disable-next-line
	}, [userInfo, cancelState, dispatch]);

	const onFormSubmitHandler = (data: any) => {
		setEditModeState(true);

		if (data && Object.keys(data).length) {
			dispatch<any>(changeUserEmail(data))
				.then((data: ExternalErrorType | Partial<UserInfoInterface>) => {
					if ((data as ExternalErrorType)?.errors) {
						setExternalErrors(data as ExternalErrorType);
					} else if (data) {
						setValues((data as any).profile);
						setEditModeState(false);
						setExternalErrors(undefined);
					}
				})
				.catch((error: ExternalErrorType) => {
					setExternalErrors(error);
				});
		}
	};

	return (
		<Container maxWidth={device !== 'desktop' ? "95%" : "58.8%"} className={classNames(className)}>
			<EditableAccountDetailsViewForm {...{ userInfo }} />

			<EditableViewForm
				{...{ editModeState }}
				hasEditButton={false}
				sectionTitle="Email"
				afterModeChange={setCancelState}
				onSubmit={handleSubmit(onFormSubmitHandler)}
			>
				<InputWithFloatLabel
					className={classNames('form-item')}
					minWidth="calc(50% - 32px)"
					maxWidth="calc(50% - 32px)"
					id="email"
					ref={register({ required: true })}
					visibleDisableStyles={false}
					label="Email"
					error={normalizedExternalErrors?.errors?.email}
					inputSettings={{
						name: 'email',
						value: values?.email,
						onChange: (event) => {
							setValues((prevState) => ({
								...prevState,
								email: event.target.value,
							}));
						},
					}}
				/>
			</EditableViewForm>

			<EditableAccountCommunicationsForm
				{...{ activeCompanyId }}
				activeCompanyName={activeCompany?.name}
			/>

			<LinkButton to="/change-password" small reversedStyles>
				Change password
			</LinkButton>
		</Container>
	);
};

export default React.memo(MyProfileScreen);
