import React, { FC } from 'react';

import styles from './InfoValue.module.scss';
import InfoValueInterface from './info-value.interface';

const InfoValue: FC<InfoValueInterface> = ({ children }) => {
	return <div className={styles['info-value']}>{children}</div>;
};

export default InfoValue;
