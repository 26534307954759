import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import styles from './JobProfileType.module.scss';
import JobProfileTypeInterface from './job-profile-type.interface';
import Tabs from '../../../../molecules/ui/tabs/Tabs';
import PowerProfiles from '../power-profiles';
import CustomJobListing from '../custom-job-listing';
import Container from '../../../../templates/container';
import { useDispatch } from 'react-redux';
import useActiveCompany from '../../../../../helpers/hooks/use-active-company/useActiveCompany';
import useCompanies from '../../../../../helpers/hooks/use-companies/useCompanies';
import {
	getDepartmentData,
	getJobsTemplatesList,
} from '../../../../../store/slices/jobs-templates.slice';
import useJobsTemplates from '../../../../../helpers/hooks/use-jobs-templates/useJobsTemplates';

const JobProfileType: FC<JobProfileTypeInterface> = ({ className, setFormState, formState }) => {
	const [activeTab, setActiveTab] = useState('power-profiles');

	const dispatch = useDispatch();
	const { activeCompanyId, companies } = useCompanies();
	const activeCompany = useActiveCompany(activeCompanyId, companies);

	const { items } = useJobsTemplates();
	const { departmentsAndLevels: departments } = useJobsTemplates();

	useEffect(() => {
		if (items && !items.length) {
			dispatch(
				getJobsTemplatesList({
					excludeCannabis: !activeCompany?.allowsCannabis,
				}),
			);
		}

		if (!departments) dispatch(getDepartmentData());
	}, [activeCompany, departments, dispatch, items]);

	const onTabChange = useCallback(
		(tab: string) => {
			setActiveTab(tab);

			if (tab === 'custom-job-listing')
				setFormState((prev) => ({
					...prev,
					jobProfileId: -1,
					jobProfileLabel: '',
					title: '',
					summary: '',
					mustHaveSkills: '',
					niceToHaveSkills: '',
					department: undefined,
					departmentLabel: '',
					departmentKey: '',
					level: undefined,
				}));
			else {
				setFormState((prev) => ({ ...prev, jobProfileId: null }));
			}
		},
		[setFormState],
	);

	const tabs = useMemo(() => {
		return (
			<Tabs
				tabsWrapClassName={styles['tabs-wrap']}
				tabClassName={styles.tab}
				activeTabClassName={styles['active-tab']}
				className={styles.tabs}
				defaultActiveTab={activeTab}
				noUrlChange
				onTabChange={onTabChange}
				tabs={[
					{
						key: 'power-profiles',
						text: 'Power Profiles',
						component: (
							<PowerProfiles setFormState={setFormState} formState={formState} />
						),
					},
					{
						key: 'custom-job-listing',
						text: 'Custom Job Listing',
						component: <CustomJobListing setFormState={setFormState} />,
					},
				]}
			/>
		);
	}, [activeTab, formState, onTabChange, setFormState]);

	return (
		<Container maxWidth={1200} className={className}>
			{tabs}
		</Container>
	);
};

export default React.memo(JobProfileType);
