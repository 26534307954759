import React, { FC, useContext } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import DomainNameContext from '../../../../contexts/domain-name-context';

// The main site branding
import LogoImage from '../../../../assets/icons/logo.svg';
import LogoImageBright from '../../../../assets/icons/logo-bright.svg';
import LogoImageLight from '../../../../assets/icons/logo-light.svg';
import LogoImageWhite from '../../../../assets/icons/logo-white.svg';
import LogoInterface, { LogoTypes } from './logo.interface';

// Engin branding
import EnginLogoImage from '../../../../assets/icons/multisite/engin/logo-small.png';
import EnginLogoImageWhite from '../../../../assets/icons/multisite/engin/logo-white.png';

// Hire branding
import HireLogoImage from '../../../../assets/icons/multisite/hire-engin/engin-logo-colored.svg';
import HireLogoImageWhite from '../../../../assets/icons/multisite/hire-engin/engin-logo-white.svg';
import HireLogoImageGray from '../../../../assets/icons/multisite/hire-engin/logo-small-gray.svg';

import styles from './Logo.module.scss';

type LogoConfigType = Record<Exclude<LogoTypes, 'simple'> | 'default', string>;

const Logo: FC<LogoInterface> = ({ to, type = 'simple', className, ...rest }) => {
	const env = useContext(DomainNameContext);
	let config: Partial<LogoConfigType>;

	const extendWithMultisiteConfig = (callback: Function) => {
		switch (env) {
			case 'hire':
				config = {
					light: HireLogoImage,
					white: HireLogoImageWhite,
					default: HireLogoImageGray,
				};
				break;
			case 'engin':
				config = {
					bright: EnginLogoImageWhite,
					white: EnginLogoImageWhite,
					default: EnginLogoImage,
				};
				break;
			default:
				config = {
					bright: LogoImageBright,
					white: LogoImageWhite,
					light: LogoImageLight,
					default: LogoImage,
				};
		}

		return callback.call(null, config);
	};

	const getLogoName = (type: LogoTypes): string => {
		return extendWithMultisiteConfig((data: any) => {
			return data[type] || data.default;
		});
	};

	const picture = (
		<picture className={classNames(styles.logo, className)} {...rest}>
			<img
				src={getLogoName(type)}
				alt={'engin logo'}
				className={classNames(env === 'hire' && styles.hire)}
			/>
		</picture>
	);

	if (to) {
		return (
			<Link to={to} className={className}>
				{picture}
			</Link>
		);
	}

	return picture;
};

export default React.memo(Logo);
