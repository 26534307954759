import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import InfoWarning from '../../../../molecules/info-warning';
import useActiveCompany from '../../../../../helpers/hooks/use-active-company/useActiveCompany';
import { EDITOR_EMPTY_STATE, QueryParametersKey } from '../../../../../helpers/constants';
import useCompanies from '../../../../../helpers/hooks/use-companies/useCompanies';
import { APP_URLS } from '../../../../../helpers/routes/routes';

const CompanyMissingDataWarning: FC = () => {
	const { activeCompanyId, companies } = useCompanies();
	const activeCompany = useActiveCompany(activeCompanyId, companies);

	return (
		<InfoWarning
			hidden={false}
			text={
				<>
					{!activeCompany?.logoUrl
						? 'Upload your logo to ' ||
						  (!activeCompany?.logoUrl &&
								(!activeCompany?.description ||
									activeCompany?.description === EDITOR_EMPTY_STATE))
						: !activeCompany?.description ||
						  activeCompany?.description === EDITOR_EMPTY_STATE
						? 'Take a minute to '
						: null}
					<Link to={`${APP_URLS.account}?${QueryParametersKey.tab}=company`}>complete your company profile.</Link>
				</>
			}
		/>
	);
};

export default CompanyMissingDataWarning;
