import { PayloadAction } from '@reduxjs/toolkit';

export interface LoginRedirectionPathInterface {
	pathname?: string | null;
	search?: string | null;
}

export const loginRedirectionPathReducer = (
	state: LoginRedirectionPathInterface,
	{ type, payload: { pathname, search } }: PayloadAction<LoginRedirectionPathInterface>,
) => {
	switch (type) {
		case 'SET_LOGIN_REDIRECTION_STATE':
			return {
				...state,
				pathname,
				search,
			};
		default:
			throw new Error();
	}
};

export const setLoginRedirectionState = (pathname?: string | null, search?: string | null) => {
	return {
		type: 'SET_LOGIN_REDIRECTION_STATE',
		payload: {
			pathname,
			search,
		},
	};
};
