import React, { FC, useState } from 'react';
import ReactPlayer from 'react-player';
import classNames from 'classnames';

import AddImageTileInterface from './add-image-tile.interface';
import Loader from '../../loader';
import ReactHtmlParser from 'react-html-parser';
import RoundedCloseButton from '../../custom/rounded-close-button';

import styles from './AddImageTile.module.scss';

const AddImageTile: FC<AddImageTileInterface> = ({
	className,
	imageUrl,
	videoUrl,
	text = 'Add image',
	description,
	loading,
	onRemoveButtonClick,
	...rest
}) => {
	const [isRemovingState, setIsRemovingState] = useState(false);

	return (
		<div className={classNames(styles.wrap, className)} {...rest}>
			{loading && <Loader className={styles.loader} />}

			{!loading && text && !imageUrl && !videoUrl ? (
				<div className={styles.info}>
					<strong className={styles.text}>{text}</strong>

					{description && (
						<div className={styles.desc} data-testid="description">
							{ReactHtmlParser(description)}
						</div>
					)}
				</div>
			) : null}

			{(!loading && (imageUrl || videoUrl)) || (isRemovingState && (imageUrl || videoUrl)) ? (
				<>
					{!videoUrl ? (
						<RoundedCloseButton
							className={styles.close}
							onClick={() => {
								setIsRemovingState(true);

								if (onRemoveButtonClick) {
									const removePromise: Promise<any> = onRemoveButtonClick();

									if (removePromise) {
										removePromise.finally(() => {
											setIsRemovingState(false);
										});
									}
								}
							}}
						/>
					) : null}

					{videoUrl ? (
						<ReactPlayer
							className={styles.image}
							url={videoUrl}
							config={{ vimeo: { playerOptions: { controls: true } } }}
						/>
					) : imageUrl ? (
						<img className={styles.image} src={imageUrl} alt="Recently uploaded file" />
					) : null}
				</>
			) : null}
		</div>
	);
};

export default React.memo(AddImageTile);
