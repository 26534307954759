import React, { FC } from 'react';
import classNames from 'classnames';

import InfoListItemInterface from './info-list-item.interface';
import InfoKey from '../../../atoms/ui/info-key/InfoKey';
import InfoValue from '../../../atoms/ui/info-value/InfoValue';

import styles from './InfoListItem.module.scss';

const InfoListItem: FC<InfoListItemInterface> = ({ keyString, className, value, additionalInfo, ...rest }) => {
	return (
		<>
			{value && (
				<li className={classNames(styles['info-list-item'], className)} {...rest}>
					<InfoKey>{keyString}</InfoKey>
					<InfoValue>{value}</InfoValue>
					{additionalInfo ? <InfoValue>{additionalInfo}</InfoValue> : null}
				</li>
			)}
		</>
	);
};

export default InfoListItem;
