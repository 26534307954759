import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './SectionTitle.module.scss';
import SectionTitleInterface from './section-title.interface';

const SectionTitle: FC<SectionTitleInterface> = ({
	children,
	className,
	...rest
}) => {
	return (
		<div className={classNames(styles['section-title'], className)} {...rest}>
			{children}
		</div>
	);
};

export default SectionTitle;
