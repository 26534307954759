import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { APP_URLS } from '../../../routes/routes';
import useUser from '../../../hooks/use-user/useUser';
import useCompanies from '../../../hooks/use-companies/useCompanies';
import MissingCompanyRedirectionHocInterface from './missing-company-redirection-hoc.interface';
import useRedirectionErrors from '../../../hooks/use-redirection-errors/useRedirectionErrors';

const MissingCompanyRedirectionHoc: FC<MissingCompanyRedirectionHocInterface> = ({ children }) => {
	const [loadingState, setLoadingState] = useState(true);
	const navigate = useNavigate();
	const location = useLocation();

	const { authorized } = useUser();
	const { companies } = useCompanies();
	const { error } = useRedirectionErrors();

	useEffect(() => {
		if (
			!error &&
			authorized &&
			companies !== undefined &&
			!companies?.length
		) {
			setLoadingState(false);
			navigate(APP_URLS.signup);
		} else {
			setLoadingState(false);
		}
	}, [authorized, companies, error, location.pathname, navigate]);

	return <>{loadingState && !companies?.length ? null : children}</>;
};

export default MissingCompanyRedirectionHoc;
