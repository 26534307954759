import React, { FC } from 'react';
import classNames from 'classnames';

import IconButtonInterface from './icon-button.interface';
import Icon from '../../icons/icon';

import styles from './IconButton.module.scss';

const IconButton: FC<IconButtonInterface> = ({
	icon,
	iconSettings,
	className,
	disabled,
	children,
	...rest
}) => {
	return (
		<button
			className={classNames(styles.wrap, className, disabled ? styles.disabled : '')}
			{...rest}
			disabled={disabled}
		>
			<Icon icon={icon} {...iconSettings} disabled={disabled} />

			{children ? children : null}
		</button>
	);
};

export default IconButton;
