import React, { FC } from 'react';
import classNames from 'classnames';

import SpinnerInterface from './spinner.interface';

import styles from './Spinner.module.scss';

const Spinner: FC<SpinnerInterface> = ({
	text = 'Spinner',
	thin,
	style,
	width,
	height,
	...rest
}) => {
	const inlineStyles = {
		...style,
		width,
		height,
	};

	return (
		<div
			style={inlineStyles}
			className={classNames(styles.spinner, thin && styles.thin)}
			{...rest}
		>
			{text}
		</div>
	);
};

export default Spinner;
