import axios from 'axios';

import { PromotionJobLinkInterface } from '../store/slices/promotion-job-links.slice';
import { JOBS } from './api.path';

const jobApi = {
	getJobPromotionLinks: (jobId: string | number): Promise<PromotionJobLinkInterface[]> => {
		const url = `${JOBS}/${jobId}/links`;

		return axios.get(url);
	},
	createNewJobPromotionLink: (
		jobId: string | number,
		originTag: string,
	): Promise<PromotionJobLinkInterface[]> => {
		const url = `${JOBS}/${jobId}/links`;

		const data = {
			originTag,
		};

		return axios.put(url, data);
	},
};

export default jobApi;
