import React from 'react';
import { FilterReducerInterface } from '../../store/reducers/filter-reducer';

type FilterContextState = {
	state: FilterReducerInterface;
};

export interface FilterContextInterface extends FilterContextState {
	dispatch: React.Dispatch<any>;
}

const initialState: FilterContextInterface = {
	state: {
		filters: {
			text: '',
			filterItem: '',
			filterOrder: undefined,
		},
	},
	dispatch: () => {},
};

const FilterContext = React.createContext(initialState);

export const { Provider: FilterProvider, Consumer: ProviderConsumer } = FilterContext;
export default FilterContext;
