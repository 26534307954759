import { default as ReactGA4 } from 'react-ga4';
import { getDomainName } from '..';
import { KeyValueDataItem } from '../custom/common';

import ga4Environments from './env-ids.json';

const useGoogleAnalytics4 = () => {
	const env: string | undefined = process.env.REACT_APP_ENV;
	if (env === 'localDevelopment') return;

	const domainEnv = getDomainName();

	// @ts-ignore
	const trackingId = ga4Environments[domainEnv][env];

	ReactGA4.initialize(trackingId);
};

export const generateGA4Event = (eventName: string, params: KeyValueDataItem<any>) => {
	const env: string | undefined = process.env.REACT_APP_ENV;
	if (env === 'localDevelopment') return;

	return ReactGA4.event(eventName, { ...params });
};

export default useGoogleAnalytics4;
