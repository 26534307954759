import React, { FC } from 'react';
import classNames from 'classnames';

import InputWithSpinnerInterface from './input-with-spinner.interface';
import Input from '../../../atoms/form/input/Input';
import Loader from '../../../molecules/loader';

import styles from './InputWithSpinner.module.scss';

const InputWithSpinner: FC<InputWithSpinnerInterface> = React.forwardRef(
	({ id, inputSettings, loading, onFocus, onBlur, ...rest }, ref) => {
		return (
			<>
				{loading && <Loader className={styles.spinner} maxWidth={20} maxHeight={20} />}

				<Input
					{...{ id, ref, onBlur, ...inputSettings, ...rest }}
					className={classNames(styles.input, loading && styles['input-has-loader'])}
					onFocus={(event) => {
						if (onFocus) {
							onFocus(event);
						}

						if (inputSettings && inputSettings.onFocus) {
							inputSettings.onFocus(event);
						}
					}}
				/>
			</>
		);
	},
);

export default InputWithSpinner;
