import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Loader from '../../components/molecules/loader';
import BoostJobForm from '../../components/organisms/custom/boost-job-form';
import NestedPopupHeader from '../../components/organisms/popup-headers/nested-popup-header/NestedPopupHeader';
import { BoostValuesForGAInterface } from '../../helpers/custom/common';
import { cancelGA4Event } from '../../helpers/google-analytics-4/events';
import { getCompanyDataForGA, getJobDataForGA } from '../../helpers/google-analytics-4/helpers';
import { GAContentGroup, GAContentId } from '../../helpers/google-analytics-4/index.constants';
import useActiveCompany from '../../helpers/hooks/use-active-company/useActiveCompany';
import useCompanies from '../../helpers/hooks/use-companies/useCompanies';
import useJob from '../../helpers/hooks/use-job/useJob';
import { getJobById, JobInterface } from '../../store/slices/job.slice';

import styles from './BoostJob.module.scss';
import { APP_URLS } from '../../helpers/routes/routes';

const BoostJob: FC = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();

	const { activeCompanyId, activeCompanyBalance, activeCompanyCanBoost, companies } =
		useCompanies();
	const { boostLoading } = useJob();
	const activeCompany = useActiveCompany(activeCompanyId, companies);

	const [currentJob, setCurrentJob] = useState<JobInterface | null>();
	const [error, setError] = useState(false);
	const [jobLoading, setJobLoading] = useState(false);

	const [boostValuesForGA, setBoostValuesForGA] = useState<BoostValuesForGAInterface>();

	//@ts-ignore
	const hasHistoryBackContext = location?.state?.hasHistoryBackContext;

	const onClose = useCallback(() => {
		cancelGA4Event(
			{
				...(currentJob && { jobData: getJobDataForGA(currentJob) }),
				companyData: getCompanyDataForGA(activeCompany),
				contentData: {
					id: GAContentId.boostJob,
					group: GAContentGroup.boostJob,
				},
			},
			{
				...boostValuesForGA,
			},
		);

		if (hasHistoryBackContext) return navigate(-1);
		navigate(`/jobs/${id}`);
	}, [activeCompany, boostValuesForGA, currentJob, hasHistoryBackContext, id, navigate]);

	const renderContent = useMemo(() => {
		if (error)
			return (
				<div className={styles['error-message']}>
					Oops! Something went wrong. Please try again later.
				</div>
			);

		return (
			<>
				<NestedPopupHeader
					withoutLogo
					title={`Boost job`}
					className={styles.header}
					close={onClose}
				/>

				{jobLoading ? (
					<Loader
						thin
						minHeight={20}
						minWidth={20}
						className={styles.loader}
						color="#ffffff"
						transparentColor="#70c9c2"
					/>
				) : currentJob ? (
					<BoostJobForm
						formTitle="Set up your Boost."
						className={styles.form}
						job={currentJob}
						companyId={activeCompanyId}
						companyBalance={activeCompanyBalance}
						boostLoading={boostLoading}
						onValuesChange={setBoostValuesForGA}
					/>
				) : null}
			</>
		);
	}, [
		activeCompanyBalance,
		activeCompanyId,
		boostLoading,
		currentJob,
		error,
		jobLoading,
		onClose,
	]);

	useEffect(() => {
		if (id) {
			(async () => {
				setJobLoading(true);

				const jobToBoost = await dispatch(getJobById(id));

				//@ts-ignore
				if (jobToBoost && jobToBoost?.id) {
					//@ts-ignore
					setCurrentJob(jobToBoost);
				} else {
					setError(true);
				}

				setJobLoading(false);
			})();
		}
	}, [dispatch, id]);

	useEffect(() => {
		if (!activeCompanyCanBoost) navigate(APP_URLS.jobs);

		return () => {
			setCurrentJob(null);
			setError(false);
			setJobLoading(false);
		};
	}, [activeCompanyCanBoost, dispatch, navigate]);

	return <div className={styles['boost-job']}>{renderContent}</div>;
};

export default BoostJob;
