import React, { FC } from 'react';

import styles from './UnorderedList.module.scss';
import UnorderedListInterface from './unordered-list.interface';
import classNames from 'classnames';

const UnorderedList: FC<UnorderedListInterface> = ({ children, className }) => {
	return <ul className={classNames(styles.list, className)}>{children}</ul>;
};

export default UnorderedList;
