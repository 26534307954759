import Cookies from 'js-cookie';
import { StorageItemKeys } from '../constants';

const isAuthCookiesExist = () => {
	const token = Cookies.get(StorageItemKeys.authToken);

	return !!token;
};

const authorize = (token: string) => {
	Cookies.set(StorageItemKeys.authToken, token);
};

const logout = () => {
	localStorage.removeItem(StorageItemKeys.filterSettings);
	Cookies.remove(StorageItemKeys.authToken);
};

export { isAuthCookiesExist, authorize, logout };
