import React, { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HtmlParser from 'react-html-parser';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { RootStateType } from '../../../../store/reducers/root-reducer';
import { deleteNote, NoteActions, NoteStatusDisplay } from '../../../../store/slices/notes.slice';
import NoteItemInterface from './note-item.interface';
import IconButton from '../../../atoms/buttons/icon-button/IconButton';
import AuthorAvatar from '../../../atoms/author-avatar';
import TypographyText from '../../../atoms/ui/typography-text';
import Loader from '../../../molecules/loader';
import Overlay from '../../../molecules/ui/overlay';
import NoteField from '../note-field';

import styles from './NoteItem.module.scss';
import ButtonLink from '../../../atoms/buttons/button-link';
import { getRelativeTimeString } from '../../../../helpers/custom/datetime';
import { isEditorValueEmpty } from '../../../../helpers/custom/editors';

const NoteItem: FC<NoteItemInterface> = ({
	editMode,
	editHandler,
	className,
	id,
	disableActionButtons,
	candidateId,
	note,
	author,
	job,
	createdAt,
	updatedAt,
	action,
	newStatus,
	label,
	color,
	loading,
	onSubmit,
	afterDeleteHandler,
}) => {
	const dispatch = useDispatch();
	const initials = `${author?.firstName[0]}${author?.lastName[0]}`;
	const {
		user: { info: userInfo },
		companies: { activeCompanyId },
		notes: { deletingIds: deletingNoteIds },
	} = useSelector((state: RootStateType) => state);

	const [noteEmptyValue, setNoteEmptyValue] = useState(false);

	const auditLogNote = !!action;

	const hasActionElements = useCallback(
		() => !disableActionButtons && !auditLogNote && author.id === userInfo?.id,
		[auditLogNote, author.id, disableActionButtons, userInfo?.id],
	);

	const deleteNodeHandler = useCallback(
		async (id: number) => {
			if (activeCompanyId && userInfo?.id) {
				await dispatch(
					deleteNote({ nodeId: id, userId: candidateId, companyId: activeCompanyId }),
				);

				if (afterDeleteHandler) afterDeleteHandler();
				if (editHandler) editHandler(undefined);
			}
		},
		[activeCompanyId, afterDeleteHandler, candidateId, dispatch, editHandler, userInfo?.id],
	);

	const actionsElements = useMemo(
		() => (
			<div className={styles['actions-wrap']}>
				<IconButton
					icon="edit"
					onClick={() =>
						editHandler && !editMode
							? editHandler(id)
							: editHandler
							? editHandler(undefined)
							: undefined
					}
				/>
				<IconButton icon="delete" onClick={deleteNodeHandler.bind(null, id)} />
			</div>
		),
		[deleteNodeHandler, editHandler, editMode, id],
	);

	const getNoteTimestamp = useCallback(
		(showEditedLabel?: boolean) => {
			const created = dayjs(createdAt).valueOf();
			const updated = dayjs(updatedAt).valueOf();

			const relativeTimeStr = getRelativeTimeString(created);

			if (showEditedLabel && created !== updated)
				return relativeTimeStr + (showEditedLabel ? ' (edited)' : '');

			return relativeTimeStr;
		},
		[createdAt, updatedAt],
	);

	const noteInfo = useMemo(
		() => (
			<div className={styles.bottom}>
				<AuthorAvatar text={initials} className={styles.avatar} {...{ color }} />

				<div>
					<TypographyText className={styles.info}>
						{author.firstName} {author.lastName}
						{!editMode && (
							<>
								{' • '}
								{getNoteTimestamp(true)}
							</>
						)}
					</TypographyText>

					{job ? (
						<TypographyText className={styles.info}>
							Position:{' '}
							{`${job?.title}, ${job?.location.city}, ${job?.location.state}`}
						</TypographyText>
					) : null}
				</div>
			</div>
		),
		[author.firstName, author.lastName, color, editMode, getNoteTimestamp, initials, job],
	);

	const renderActionNote = useMemo(() => {
		if (label) return label;
		else if (newStatus) {
			const currentStatus = NoteStatusDisplay[newStatus as keyof typeof NoteStatusDisplay];
			return `Status update: ${currentStatus}`;
		} else if (action === NoteActions.rejectionEmailSent)
			return 'Candidate rejected. Email sent.';
		return '';
	}, [action, label, newStatus]);

	const onNoteValueChange = useCallback(
		(value?: string) => setNoteEmptyValue(isEditorValueEmpty(value)),
		[],
	);

	return (
		<li
			className={classNames(styles.wrap, editMode && styles.edit, className)}
			id={id.toString()}
		>
			<div className={styles.content}>
				{!editMode ? (
					<>
						<div>
							<TypographyText
								className={classNames(
									styles['note-content'],
									auditLogNote ? styles['status-note'] : '',
								)}
							>
								{note ? HtmlParser(note) : renderActionNote}
							</TypographyText>

							{noteInfo}
						</div>

						<div className={styles.actions}>
							{hasActionElements() && !deletingNoteIds.find((item) => item === id) ? (
								actionsElements
							) : deletingNoteIds.find((item) => item === id) ? (
								<Loader thin maxWidth={20} maxHeight={20} />
							) : null}
						</div>
					</>
				) : (
					<NoteField
						{...{ onSubmit, loading }}
						className={styles['edit-field']}
						onValueChangeHandler={onNoteValueChange}
						actionElements={
							<>
								{noteInfo}

								{!loading ? (
									<>
										<div className={styles['edit-actions']}>
											<ButtonLink
												type="button"
												className={styles.btn}
												onClick={() => {
													if (editHandler) {
														editHandler(undefined);
													}
												}}
											>
												Cancel
											</ButtonLink>

											<i className={styles.slash} />
										</div>
										<ButtonLink
											type="submit"
											className={classNames(styles.btn)}
											disabled={noteEmptyValue}
										>
											Save
										</ButtonLink>
									</>
								) : null}
							</>
						}
						noteDefaultValue={note}
					/>
				)}
			</div>

			<Overlay open={!!editMode} />
		</li>
	);
};

export default NoteItem;
