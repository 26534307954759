import React, { cloneElement, FC, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import { getMinMaxHeightStyles } from '../../../helpers';
import ShowMoreStaticContentInterface from './show-more-static-content.interface';
import ButtonLink from '../../atoms/buttons/button-link';

import styles from './ShowMoreStaticContent.module.scss';

const ShowMoreStaticContent: FC<ShowMoreStaticContentInterface> = ({
	children,
	countItemsToDisplay = 1,
	minHeight,
	maxHeight,
}) => {
	const [heightState, setHeightState] = useState<number>(0);
	const [maxHeightState, setMaxHeightState] = useState<number>(0);
	const [itemsCountState] = useState<number>(React.Children.count(children));

	const wrapInlineStyles = {
		...getMinMaxHeightStyles(minHeight, maxHeight),
	};
	const cotentInlinestyles = {
		height: heightState,
	};

	const childrenDomElements: HTMLElement[] = [];

	const renderChildren = () => {
		return React.Children.map(children, (child, index) => {
			if (typeof child === 'number' || typeof child === 'string') {
				return (
					<div
						//@ts-ignore
						ref={(element: HTMLElement) => {
							childrenDomElements.push(element);
						}}
						key={index}
					>
						{child}
					</div>
				);
			}

			return cloneElement(child as React.ReactElement, {
				ref: (element: HTMLElement) => {
					childrenDomElements.push(element);
				},
			});
		});
	};

	useEffect(() => {
		let temporaryHeight: number = 0;

		childrenDomElements
			.filter((element) => element)
			.forEach((element, index) => {
				if (index <= itemsCountState - 1) {
					temporaryHeight += element.clientHeight;
				}

				if (countItemsToDisplay - 1 === index || countItemsToDisplay >= itemsCountState) {
					setHeightState(temporaryHeight);
				}

				if (index === itemsCountState - 1) {
					setMaxHeightState(temporaryHeight);
				}
			});

		// eslint-disable-next-line
	}, [children, countItemsToDisplay]);

	const showMoreHandler = useCallback(() => {
		setHeightState(maxHeightState);
	}, [maxHeightState]);

	return (
		<div className={styles.wrap} style={wrapInlineStyles}>
			<div
				style={cotentInlinestyles}
				className={classNames(styles.content, heightState && styles.loaded)}
			>
				{renderChildren()}
			</div>

			{maxHeightState !== heightState && (
				<ButtonLink onClick={showMoreHandler} className={styles.button}>
					Show{' '}
					{itemsCountState > countItemsToDisplay
						? itemsCountState - countItemsToDisplay
						: ''}{' '}
					more
				</ButtonLink>
			)}
		</div>
	);
};

export default ShowMoreStaticContent;
