import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import Container from '../../../templates/container';
import CompanyTemplatesScreenInterface from './company-templates-screen.interface';

import TypographyText from '../../../atoms/ui/typography-text';
import SelectWithFloatLabel from '../../form-elemets/select-with-float-label/SelectWithFloatLabel';
import EditableCompanyTemplateForm from '../editable-company-template-form/EditableCompanyTemplateForm';
import {
	editCommunicationTemplate,
	EmailTemplateType,
	getCommunicationTemplates,
} from '../../../../store/slices/companies.slice';
import EditableViewForm from '../../editable-view-form';

import styles from './CompanyTemplatesScreen.module.scss';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../../../store/reducers/root-reducer';
import OptionInterface from '../../../atoms/form/option/option.interface';
import Loader from '../../../molecules/loader';
import ResizeDeviceContext from '../../../../contexts/resize-device-context';

const CompanyTemplatesScreen: FC<CompanyTemplatesScreenInterface> = ({ className }) => {
	const {
		companies: { activeCompanyId, loading },
	} = useSelector((state: RootStateType) => state);

	const dispatch = useDispatch();
	const { register, handleSubmit, errors } = useForm();

	const [companyTemplates, setCompanyTemplates] = useState<EmailTemplateType[]>([]);
	const [templateOptions, setTemplateOptions] = useState<OptionInterface[]>([]);
	const [templateOptionsLoading, setTemplateOptionsLoading] = useState(true);

	const [currentTemplateId, setCurrentTemplateId] = useState('');
	const [currentTemplate, setCurrentTemplate] = useState<EmailTemplateType>();
	const [editModeState, setEditModeState] = useState<boolean>();
	const [isUpdated, setIsUpdated] = useState(false);

	const device = useContext(ResizeDeviceContext);

	const onFormSubmitHandler = useCallback(
		async (data: any) => {
			setEditModeState(true);

			const response = await dispatch(
				editCommunicationTemplate(currentTemplateId, {
					...data,
					companyId: activeCompanyId,
				}),
			);

			if (response) {
				setIsUpdated(true);
				//@ts-ignore
				const { subject, body } = response;
				if (subject && body)
					//@ts-ignore
					setCurrentTemplate((prevState) => ({ ...prevState, subject, body }));
			}

			setEditModeState(false);
		},
		[activeCompanyId, currentTemplateId, dispatch],
	);

	useEffect(() => {
		(async () => {
			if (activeCompanyId) {
				const templates = await dispatch(getCommunicationTemplates(activeCompanyId));

				if (templates && templates.length) {
					//@ts-ignore
					setCompanyTemplates(templates);

					setTemplateOptions(
						//@ts-ignore
						templates.map((template) => {
							return { value: template.id, text: template.label };
						}),
					);
				}
			}
		})();
	}, [activeCompanyId, dispatch]);

	useEffect(() => {
		if (isUpdated) {
			(async () => {
				if (activeCompanyId) {
					const templates = await dispatch(getCommunicationTemplates(activeCompanyId));
					//@ts-ignore
					if (templates) setCompanyTemplates(templates);
				}
			})();
		}
	}, [activeCompanyId, dispatch, isUpdated]);

	useEffect(() => {
		if (currentTemplateId)
			setCurrentTemplate(companyTemplates.find((temp) => temp.id === currentTemplateId));
	}, [companyTemplates, currentTemplateId]);

	useEffect(() => {
		if (templateOptions?.length) setTemplateOptionsLoading(false);
	}, [templateOptions]);

	return !templateOptionsLoading ? (
		<Container
			maxWidth={device !== 'desktop' ? '95%' : '720px'}
			className={classNames(className, styles['templates-settings'])}
		>
			<TypographyText className={styles.description}>
				Choose a template below to customize it for your company for use in messaging with
				candidates.
				<br />
				<span className={styles.note}>
					Note: These are company-wide templates and may be modified by anyone on your
					team at any time.
				</span>
			</TypographyText>

			<SelectWithFloatLabel
				className={styles['template-options']}
				minWidth={device === 'mobile' ? '95%' : 'calc(50% - 32px)'}
				maxWidth={device === 'mobile' ? '95%' : 'calc(50% - 32px)'}
				id="template"
				visibleDisableStyles={false}
				label={currentTemplateId ? 'Template' : 'Select message template'}
				selectSettings={{
					options: templateOptions,
					name: 'template',
					onChange: ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
						setCurrentTemplateId(value);
					},
				}}
			/>

			{currentTemplate ? (
				<EditableViewForm
					key={currentTemplate.id}
					editModeState={editModeState}
					onSubmit={handleSubmit(onFormSubmitHandler)}
					sectionTitle="Template"
					sectionTitleClassName={styles['section-title']}
					loading={loading}
				>
					<EditableCompanyTemplateForm
						template={currentTemplate}
						registerFormItem={register}
						formErrors={errors}
					/>
				</EditableViewForm>
			) : null}
		</Container>
	) : (
		<Loader className={styles.loader} />
	);
};

export default React.memo(CompanyTemplatesScreen);
