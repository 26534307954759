import React, { FC, useEffect, useState } from 'react';

import { getObjectByNumberPosition } from '../../../../helpers/forms/forms';
import SliderElementWithDynamicStateDisplayingInterface, {
	SliderElementWithDynamicStateDisplayingMarkType,
} from './slider-element-with-dynamic-state-displaying.interface';
import SliderElement from '../../../molecules/form/slider-element';

import styles from './SliderElementWithDynamicStateDisplaying.module.scss';
import classNames from 'classnames';

const SliderElementWithDynamicStateDisplaying: FC<
	SliderElementWithDynamicStateDisplayingInterface
> = ({ marks, className, label, value, onChangeHandler, hideMarks = true, ...rest }) => {
	const [currentState, setCurrentState] = useState<
		SliderElementWithDynamicStateDisplayingMarkType | undefined
	>(marks![0]);

	useEffect(() => {
		if (value !== undefined) {
			setCurrentState(getObjectByNumberPosition(marks, value));
		}
	}, [marks, value]);

	return (
		<div className={classNames(styles.wrap, className, hideMarks ? styles['hide-marks'] : '')}>
			{label && <div className={styles.label}>{label}</div>}

			{currentState?.label && <div className={styles.value}>{currentState?.label}</div>}

			<SliderElement
				hideMarks={hideMarks}
				dotStyle={{
					top: 0,
					border: 0,
					height: 1,
					background: '#000',
					width: 8,
					transform: 'rotate(90deg)',
				}}
				onChange={(number) => {
					const value = getObjectByNumberPosition(marks, number);

					if (value !== undefined) {
						setCurrentState(value);
						if (onChangeHandler) onChangeHandler(value);
					}
				}}
				{...{ marks, value, ...rest }}
			/>
		</div>
	);
};

export default React.memo(SliderElementWithDynamicStateDisplaying);
