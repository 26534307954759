import React, { FC } from 'react';

import styles from './UnorderedListItem.module.scss';
import UnorderedListItemInterface from './unordered-list-item.interface';
import classNames from 'classnames';

const UnorderedListItem: FC<UnorderedListItemInterface> = ({ children, checkStyle }) => {
	return (
		<li className={classNames(styles['list-item'], checkStyle ? styles.check : '')}>
			{children}
		</li>
	);
};

export default UnorderedListItem;
