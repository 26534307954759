import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import {
	ComposableMap,
	Geographies,
	Geography,
	Marker,
	ZoomableGroup,
	Line,
} from 'react-simple-maps';
import * as turf from '@turf/turf';
import { FeatureCollection } from '@turf/turf';

import { remoteUSAStates, usaStates, UsaStatesType } from '../../../helpers/constants';
import { getMinMaxHeightStyles, getMinMaxWidthStyles } from '../../../helpers';
import BuildingImage from '../../../assets/icons/map/building.svg';
import PersonImage from '../../../assets/icons/map/person.svg';
import MapWithDistanceInterface from './map-with-distance.interface';

import styles from './MapWithDistance.module.scss';

import geoObj from './geo/states-10m.json';
import ErrorBoundary from '../../../helpers/hocs/ErrorBoundary';

const MapWithDistance: FC<MapWithDistanceInterface> = ({
	minWidth,
	maxWidth,
	minHeight = '10vw',
	maxHeight,
	candidateLongitude,
	candidateLatitude,
	candidateState,
	jobLongitude,
	jobLatitude,
	jobState,
	setDistanceBetweenPoints,
	className,
}) => {
	const inRemoteState = candidateState && remoteUSAStates.includes(candidateState);
	const [markersScaleFactor, setMarkersScaleFactor] = useState(inRemoteState ? 2 : 1);

	const geoMode = useMemo(() => {
		// using different modes because geoAlbersUsa causes errors for some remote states
		if (!candidateState || inRemoteState) return 'geoMercator';

		return 'geoAlbersUsa';
	}, [candidateState, inRemoteState]);

	const stylesInline = useMemo(
		() => ({
			...getMinMaxWidthStyles(minWidth, maxWidth),
			...getMinMaxHeightStyles(minHeight, maxHeight),
		}),
		[minHeight, maxHeight, minWidth, maxWidth],
	);

	const coordinates = useMemo(
		() =>
			candidateLongitude && candidateLatitude && jobLongitude && jobLatitude
				? [
						[candidateLongitude, candidateLatitude],
						[jobLongitude, jobLatitude],
				  ]
				: null,
		[candidateLatitude, candidateLongitude, jobLatitude, jobLongitude],
	);

	const getStateInfoByPostalAbbreviation = useCallback(
		(postalAbbreviation: string): UsaStatesType | undefined => {
			return usaStates.find((item) => item.value === postalAbbreviation);
		},
		[],
	);

	const getGeographyByPostalAbbreviation = useCallback(
		(geographies: any[], postalAbbreviation: string) => {
			const featureObject = getStateInfoByPostalAbbreviation(postalAbbreviation);

			return (
				featureObject && [
					geographies.find((item) => item.properties.name === featureObject.text),
				]
			);
		},
		[getStateInfoByPostalAbbreviation],
	);

	const features: FeatureCollection | null = useMemo(
		() => coordinates && turf.points(coordinates),
		[coordinates],
	);

	const hasTheSameState = useCallback(() => {
		return candidateState && jobState && candidateState === jobState;
	}, [candidateState, jobState]);

	const stateInfo = useMemo(
		() => candidateState && getStateInfoByPostalAbbreviation(candidateState),
		[candidateState, getStateInfoByPostalAbbreviation],
	);

	const line = useMemo(() => coordinates && turf.lineString(coordinates), [coordinates]);

	const length = useMemo(
		() =>
			line && Math.round(turf.length(line, { units: 'miles' })) < 5
				? 5
				: line && Math.round(turf.length(line, { units: 'miles' }))
				? Math.round(turf.length(line, { units: 'miles' }))
				: null,
		[line],
	);

	useEffect(() => {
		if (length) {
			setDistanceBetweenPoints(length);
		}

		return () => setDistanceBetweenPoints(undefined);
	}, [length, setDistanceBetweenPoints]);

	// needs refactoring (all ternaries here)!!!

	const center = useMemo(
		() =>
			hasTheSameState() && stateInfo
				? stateInfo.centerCoordinates
				: !hasTheSameState() && length && length <= 100 && features
				? turf.center(features).geometry.coordinates
				: [-97.225149, 38.550649],
		[features, hasTheSameState, length, stateInfo],
	);
	
	const zoom = useMemo(
		() =>
			inRemoteState
				? 2
				: hasTheSameState() && stateInfo
				? stateInfo.zoom
				: !hasTheSameState() && length && length <= 100
				? 4
				: 1,
		[hasTheSameState, inRemoteState, length, stateInfo],
	);

	const renderGeographies = useCallback(
		(coordinates?: number[][] | null) => (
			<>
				<Geographies geography={geoObj}>
					{({ geographies }) => {
						const geoItems =
							hasTheSameState() && candidateState
								? getGeographyByPostalAbbreviation(geographies, candidateState)
								: geographies;
						if (geoItems) {
							return (
								<>
									{geoItems.map((geo: { rsmKey: string | number }) => (
										<Geography
											key={geo?.rsmKey}
											stroke="#FFF"
											geography={geo}
											fill="#a9e3de"
											className={classNames(
												geoItems?.length === 1 && styles['only-state'],
											)}
										/>
									))}
								</>
							);
						}
					}}
				</Geographies>

				{coordinates ? (
					<>
						<Line
							from={coordinates[0] as any}
							to={coordinates[1] as any}
							stroke="#000"
							strokeWidth={(zoom ? (1 / zoom) * 3 : 1) / markersScaleFactor}
						/>
						<Marker coordinates={coordinates[1] as any}>
							<image
								href={BuildingImage}
								width={
									(zoom && 21 / zoom > 1
										? (21 / zoom) * 3
										: zoom && 21 / zoom <= 1
										? 1
										: 21) / markersScaleFactor
								}
								height={
									(zoom && 21 / zoom > 1
										? (21 / zoom) * 3
										: zoom && 21 / zoom <= 1
										? 1
										: 21) / markersScaleFactor
								}
								x={(zoom ? -(((21 / zoom) * 3) / 2) : 21) / markersScaleFactor}
								y={(zoom ? -(21 / zoom) * 2.7 : 21) / markersScaleFactor}
							/>
						</Marker>

						<Marker coordinates={coordinates[0] as any}>
							<image
								href={PersonImage}
								width={
									(zoom && (7 / zoom) * 3 > 1
										? (7 / zoom) * 3
										: zoom && (7 / zoom) * 3 <= 1
										? 1
										: 7) / markersScaleFactor
								}
								height={
									(zoom && (16 / zoom) * 3 > 1
										? (16 / zoom) * 3
										: zoom && (16 / zoom) * 3 <= 1
										? 1
										: 16) / markersScaleFactor
								}
								x={(zoom ? -(((7 / zoom) * 3) / 2) : 7) / markersScaleFactor}
								y={(zoom ? -(16 / zoom) * 3 : 16) / markersScaleFactor}
							/>
						</Marker>
					</>
				) : null}
			</>
		),
		[
			candidateState,
			getGeographyByPostalAbbreviation,
			hasTheSameState,
			markersScaleFactor,
			zoom,
		],
	);

	const onZoomMove = useCallback(
		({ k }: { k: number }) => {
			if (!hasTheSameState()) setMarkersScaleFactor(k);
		},
		[hasTheSameState],
	);

	return (
		center && (
			<ErrorBoundary>
				<div style={stylesInline} className={classNames(styles.map, className)}>
					<ComposableMap projection={geoMode}>
						<ZoomableGroup center={center as any} zoom={zoom} onMove={onZoomMove}>
							{renderGeographies(coordinates)}
						</ZoomableGroup>
					</ComposableMap>
				</div>
			</ErrorBoundary>
		)
	);
};

export default React.memo(MapWithDistance);
