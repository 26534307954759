import React, { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import {
	AuthorizationSliceInterface,
	loginWithCode,
} from '../../../../../store/slices/authorization.slice';
import TroubleSignInResetPasswordStepInterface from './trouble-sign-in-reset-password-step.interface';
import InputWithFloatLabel from '../../../form-elemets/input-with-float-label/InputWithFloatLabel';
import Button from '../../../../atoms/buttons/button/Button';
import troubleFormStyles from '../../login-trouble/LoginTrouble.module.scss';
import SlideFormHeading from '../../../../molecules/custom/slide-form-heading';
import CheckboxWithLabel from '../../../../molecules/form/checkbox-with-label/CheckboxWithLabel';

import styles from './TroubleSignInResetPasswordStep.module.scss';
import { getTargetMessageByProperty } from '../../../../../helpers/forms/forms';
import { useNavigate } from 'react-router-dom';
import { APP_URLS } from '../../../../../helpers/routes/routes';
import { QueryParametersKey } from '../../../../../helpers/constants';

const TroubleSignInResetPasswordStep: FC<TroubleSignInResetPasswordStepInterface> = ({
	title,
	description,
	sliderRef,
	activeSliderNumberState,
	setNextHandler,
	validationData,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [errorState, setErrorState] = useState('');
	const { register, errors, watch, handleSubmit } = useForm({
		reValidateMode: 'onChange',
	});

	const { error, loading, needTerms } = useSelector(
		({ authorization }: { authorization: AuthorizationSliceInterface }) => authorization,
	);

	const onFormSubmitHandler = useCallback(
		async (data: any) => {
			try {
				const loginData = {
					password: data?.password,
					...(validationData &&
						Object.keys(validationData).length && {
							validationCode: validationData.validationCode,
						}),
					...(validationData &&
						Object.keys(validationData).length && {
							email: validationData.user?.email,
						}),
				};

				await dispatch(loginWithCode({ ...loginData }));
			} catch (error: any) {
				if (error?.errors) {
					const codeError = getTargetMessageByProperty('code', error.errors);

					if (codeError) return setErrorState('Validation error. Please try again.');
				}

				setErrorState('The user with such an email does not exit.');
			}
		},
		[dispatch, validationData],
	);

	const prevStep = () => {
		const urlParams = new URLSearchParams(location.search);
		const token = urlParams.get(QueryParametersKey.autologin);

		if (token) return navigate(APP_URLS.login);

		if (sliderRef) {
			// @ts-ignore
			sliderRef.current.slickPrev();
		}
	};

	const formHandleSubmit = handleSubmit(onFormSubmitHandler);

	useEffect(() => {
		if (activeSliderNumberState === 2 && setNextHandler) {
			setNextHandler(() => formHandleSubmit);
		}

		// eslint-disable-next-line
	}, [activeSliderNumberState]);

	const customerTermsUrl =
		process.env.REACT_APP_CUSTOMER_TERMS_URL || 'https://engin.co/terms-and-conditions';

	return (
		<form
			className={classNames(troubleFormStyles.step, styles.form)}
			onSubmit={formHandleSubmit}
		>
			<SlideFormHeading className={styles.heading} {...{ title, description }} />

			<InputWithFloatLabel
				className={classNames(troubleFormStyles.input, styles.input)}
				loading={loading}
				ref={register({
					required: {
						value: true,
						message: 'This field is required',
					},
					minLength: { value: 8, message: 'Password must be at least 8 characters long' },
				})}
				inputSettings={{
					type: 'password',
					autoComplete: 'new-password',
					id: 'password',
					name: 'password',
				}}
				error={
					errors && errors.password && errors.password.message
						? errors.password.message
						: errors &&
						  errors.confirmPassword &&
						  errors.confirmPassword.type === 'validate'
						? 'This field does not match'
						: error || errorState
				}
				id="password"
				label="Password"
			/>

			<InputWithFloatLabel
				className={classNames(troubleFormStyles.input, styles.input)}
				loading={loading}
				ref={register({
					required: {
						value: true,
						message: 'This field is required',
					},
					validate: (value) => {
						return value === watch('password');
					},
				})}
				error={
					errors && errors.confirmPassword && errors.confirmPassword.message
						? errors.confirmPassword.message
						: errors &&
						  errors.confirmPassword &&
						  errors.confirmPassword.type === 'validate'
						? 'This field does not match'
						: error || errorState
				}
				inputSettings={{
					type: 'password',
					id: 'confirm-password',
					autoComplete: 'new-password',
					name: 'confirmPassword',
				}}
				id="confirm-password"
				label="Confirm password"
			/>

			<CheckboxWithLabel
				id="acceptedCustomerTermsTroubeSignInFlow"
				className={classNames(styles.checkbox, !needTerms && styles.hidden)}
				name="acceptedCustomerTerms"
				ref={register({
					required: true,
				})}
				defaultChecked
				error={
					errors && errors.acceptedTerms && "The 'Terms & Conditions' field is required"
				}
				label={
					<span className={styles.text}>
						By signing up, you agree to our{' '}
						<a href={customerTermsUrl} target="_blank" rel="noopener noreferrer">
							Terms & Conditions
						</a>
						.
					</span>
				}
			/>

			<div className={troubleFormStyles.actions}>
				<Button
					small
					type="button"
					className={styles.btn}
					minWidth={100}
					onClick={prevStep}
				>
					Back
				</Button>

				<Button type="submit" small minWidth={100} maxWidth={100}>
					Next
				</Button>
			</div>
		</form>
	);
};

export default TroubleSignInResetPasswordStep;
