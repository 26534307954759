import classNames from 'classnames';
import React, { FC } from 'react';
import TemplateValueTagInterface from './template-value-tag.interface';

import styles from './TemplateValueTag.module.scss';

const TemplateValueTag: FC<TemplateValueTagInterface> = ({ children, className, onClickHandler }) => {
	return (
		<span className={classNames(styles['value-tag'], className)} onClick={onClickHandler}>
			[{`${children}`}]
		</span>
	);
};

export default TemplateValueTag;
