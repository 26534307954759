import classNames from 'classnames';
import React, { FC, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ExternalErrorType } from '../../../../helpers/axios/axios-global';
import { spacesValidation } from '../../../../helpers/forms/forms';
import useExternalFormErrors from '../../../../helpers/hooks/use-external-form-errors';
import { RootStateType } from '../../../../store/reducers/root-reducer';
import { changeUserInfo, UserInfoInterface } from '../../../../store/slices/user.slice';
import Button from '../../../atoms/buttons/button/Button';
import FormActions from '../../../molecules/form/form-actions';
import Loader from '../../../molecules/loader';
import InputWithFloatLabel from '../../form-elemets/input-with-float-label/InputWithFloatLabel';
import ModalWindow from '../../modal-window';
import CalendarLinkSettingPopupInterface from './calendar-link-setting-popup.interface';

import styles from './CalendarLinkSettingPopup.module.scss';

const CalendarLinkSettingPopup: FC<CalendarLinkSettingPopupInterface> = ({
	className,
	open,
	onSuccess,
	openStateHandler,
	nonScrollable = true,
}) => {
	const dispatch = useDispatch();

	const {
		user: { editLoading: userEditLoading },
	} = useSelector((state: RootStateType) => state);

	const { register, handleSubmit, errors } = useForm();

	const [calendarLink, setCalendarLink] = useState('');
	const [externalErrors, setExternalErrors] = useState<Partial<ExternalErrorType>>();
	const normalizedExternalErrors = useExternalFormErrors(externalErrors);

	const calendarLinkHandleSubmit = useCallback(
		async (data: any) => {
			if (data?.calendarLink) {
				//@ts-ignore
				const result: ExternalErrorType | Partial<UserInfoInterface> = await dispatch(
					changeUserInfo({ calendarLink: data?.calendarLink }),
				);

				if ((result as ExternalErrorType)?.errors) {
					setExternalErrors(result as ExternalErrorType);
				} else if (result) {
					setExternalErrors(undefined);
					if (onSuccess) onSuccess();
				}
			}
		},
		[dispatch, onSuccess],
	);

	return (
		<ModalWindow
			className={styles['modal-wrapper']}
			contentClassName={styles['calendar-link-popup']}
			nonScrollable={nonScrollable}
			open={open}
			close={() => openStateHandler(false)}
			opacityAnimation
		>
			{userEditLoading ? (
				<Loader />
			) : (
				<div className={classNames(styles.wrapper, className)}>
					<div className={styles.title}>Set calendar link</div>
					<div className={styles.description}>
						Your calendar link has not been set. Add it now to include in this email or
						remove the link and set it later.
					</div>

					<form onSubmit={handleSubmit(calendarLinkHandleSubmit)}>
						<InputWithFloatLabel
							className={styles.input}
							ref={(ref) => {
								register(ref, {
									...spacesValidation,
								});
							}}
							error={
								(errors && errors.calendarLink && errors.calendarLink.message) ||
								normalizedExternalErrors?.errors?.calendarLink
							}
							minWidth="100%"
							inputSettings={{
								name: 'calendarLink',
								defaultValue: calendarLink,
								onChange: (event) => setCalendarLink(event.target?.value),
							}}
							id="calendar-link"
							label="Your calendar link"
						/>

						<FormActions centeredContent className={styles['modal-actions']}>
							<Button
								type="submit"
								minWidth={300}
								maxWidth={300}
								className={styles.btn}
								disabled={!calendarLink}
							>
								Save calendar link
							</Button>

							<Button
								type="button"
								minWidth={300}
								maxWidth={300}
								reversedStyles
								className={styles.btn}
								onClick={() => openStateHandler(false)}
							>
								Cancel
							</Button>
						</FormActions>
					</form>
				</div>
			)}
		</ModalWindow>
	);
};

export default React.memo(CalendarLinkSettingPopup);
