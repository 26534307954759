export interface PageState {
	pageNumber: number;
	items: any[];
}

export interface PaginationState {
	prevPage: PageState | null;
	currentPage: PageState | null;
	nextPage: PageState | null;
}

export const initialPaginationState: PaginationState = {
	prevPage: null,
	currentPage: {
		pageNumber: 1,
		items: [],
	},
	nextPage: null,
};

interface PaginationInterface {
	currentPageNumber?: number | null;
	moveToPrevPage: any;
	moveToNextPage: any;
	hasNextPage: boolean;
	className?: string;
	urlToAddPageNumber?: string;
	loading?: boolean;
}

export default PaginationInterface;
