import axios from 'axios';

import { FACILITIES } from './api.path';
import { FacilityInterface } from '../store/slices/facilities.slice';

const facilitiesApi = {
	getFacilitiesList: (companyId: string | number) => {
		const url = `${FACILITIES}?companyId=${companyId}`;

		return axios.get(url);
	},
	createFacility: (data: object): Promise<FacilityInterface> => {
		return axios.post(FACILITIES, data);
	},
	updateFacility: (id: string | number, data: object): Promise<FacilityInterface> => {
		const url = `${FACILITIES}/${id}`;

		return axios.patch(url, data);
	},
	deleteFacility: (id: string | number): Promise<FacilityInterface> => {
		const url = `${FACILITIES}/${id}`;

		return axios.delete(url);
	},
};

export default facilitiesApi;
