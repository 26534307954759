import React, { FC, useMemo } from 'react';

import { RouteHOCInterface } from '../routes.interface';
import { APP_URLS, getNavLinks, redirectTo } from '../routes';
import PrivateLayout from '../../../components/templates/private-layout';
import Popup from '../../../components/templates/popup';
import ErrorRedirectionHoc from '../../hocs/errorRedirectionHoc/ErrorRedirectionHoc';
import BrowserStorageCrudHoc from '../../hocs/browser-storage-crud-hoc/BrowserStorageCrudHoc';
import useLoginRedirection from '../../hooks/custom/use-login-redirection';
import StorageClearHoc from '../../hocs/storageClearHoc/StorageClearHoc';
import { AnimatedScreenWrapper } from '../../../helpers/hocs/transition/AnimatedScreenWrapper';
import useUser from '../../hooks/use-user/useUser';
import MissingCompanyRedirectionHoc from '../../hocs/custom /missing-company-redirection-hoc/MissingCompanyRedirectionHoc';
import PostFlowAbandonmentRedirectionHoc from '../../hocs/custom /post-flow-abandonment-redirection-hoc/PostFlowAbandonmentRedirectionHoc';

const PrivateRoute: FC<RouteHOCInterface> = ({
	component: Component,
	navLinks: navLinksConfig,
	topLayoutContent,
	popup,
	popupClassName,
	hasHeader,
	...rest
}) => {
	const { authorized } = useUser();

	const navLinks = useMemo(() => getNavLinks(navLinksConfig), [navLinksConfig]);

	useLoginRedirection();

	const renderComponent = useMemo(() => {
		return popup ? (
			<ErrorRedirectionHoc>
				<AnimatedScreenWrapper opacityAnimation={rest.opacityAnimation}>
					<Popup key={rest.title} {...rest} className={popupClassName}>
						<MissingCompanyRedirectionHoc>
							<PostFlowAbandonmentRedirectionHoc>
								<BrowserStorageCrudHoc>
									<StorageClearHoc>
										<Component />
									</StorageClearHoc>
								</BrowserStorageCrudHoc>
							</PostFlowAbandonmentRedirectionHoc>
						</MissingCompanyRedirectionHoc>
					</Popup>
				</AnimatedScreenWrapper>
			</ErrorRedirectionHoc>
		) : (
			<ErrorRedirectionHoc>
				<PrivateLayout {...{ navLinks, topLayoutContent, hasHeader }}>
					<MissingCompanyRedirectionHoc>
						<PostFlowAbandonmentRedirectionHoc>
							<BrowserStorageCrudHoc>
								<StorageClearHoc>
									<Component />
								</StorageClearHoc>
							</BrowserStorageCrudHoc>
						</PostFlowAbandonmentRedirectionHoc>
					</MissingCompanyRedirectionHoc>
				</PrivateLayout>
			</ErrorRedirectionHoc>
		);
	}, [Component, hasHeader, navLinks, popup, popupClassName, rest, topLayoutContent]);

	return <>{!authorized ? redirectTo(APP_URLS.login) : renderComponent}</>;
};

export default React.memo(PrivateRoute);
