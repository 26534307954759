import { Component } from 'react';

class ErrorBoundary extends Component {
	constructor(props: any) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		return {
			hasError: true,
		};
	}

    componentDidCatch() {
        console.error('Error while rendering (ErrorBoundary).');
    }

	render() {
		const { hasError } = this.state;

		// if error occurs - just do not display component
		if (hasError) return null;
		return this.props.children;
	}
}

export default ErrorBoundary;
