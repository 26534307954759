import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { candidatesStatusLabels } from '../../../../helpers';
import {
	changeCandidateStatus,
	setHiringWithWurkCandidateId,
} from '../../../../store/slices/candidates.slice';
import { RootStateType } from '../../../../store/reducers/root-reducer';
import TypographyText from '../../../atoms/ui/typography-text';
import useActiveCompany from '../../../../helpers/hooks/use-active-company/useActiveCompany';
import StatusSelect from '../../../molecules/status-select/StatusSelect';
import OptionInterface from '../../../atoms/form/option/option.interface';
import CandidatesSelectStatusInterface from './candidates-select-status.interface';

import styles from './CandidateSelectStatus.module.scss';
import { CandidatesStatusValues } from '../../../../helpers/constants';

const CandidateSelectStatus: FC<CandidatesSelectStatusInterface> = ({
	candidateId,
	onChange,
	defaultValue,
	hiredInWurk,
	statusLabel = 'Hired in Würk',
	...rest
}) => {
	const [value, setValue] = useState<string | number | readonly string[] | undefined>('');
	const dispatch = useDispatch();
	const {
		companies: { activeCompanyId, items: companies },
	} = useSelector((state: RootStateType) => state);
	const activeCompany = useActiveCompany(activeCompanyId, companies);
	const hiredText = 'Hired in Würk';
	const candidatesStatusOptions = Array.from(candidatesStatusLabels, (item: OptionInterface) => ({
		...item,
	}));

	useEffect(() => {
		if (defaultValue !== undefined) {
			setValue(defaultValue);
		}
	}, [defaultValue]);

	const changeStatus = useCallback(
		(status?: string) => {
			setValue(status);
			dispatch(changeCandidateStatus(candidateId as string, status));
		},
		[candidateId, dispatch],
	);

	const setStatus = useCallback(
		(status?: string) => {
			if (!activeCompany?.hasWurk) {
				changeStatus(status);
			} else if (
				activeCompany?.hasWurk &&
				status !== CandidatesStatusValues.hired.toString()
			) {
				changeStatus(status);
			} else {
				dispatch(setHiringWithWurkCandidateId(candidateId as string));
			}
		},
		[activeCompany, changeStatus, candidateId, dispatch],
	);

	const onChangeHandler = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
		if (event.target.value !== CandidatesStatusValues.rejected.toString())
			setStatus(event.target.value);

		if (onChange) {
			onChange(event);
		}
	}, [onChange, setStatus]);

	if (value === CandidatesStatusValues.hired && !hiredInWurk) {
		candidatesStatusOptions[
			candidatesStatusOptions.findIndex((item) => item.value === CandidatesStatusValues.hired)
		].text = statusLabel;
	} else if (activeCompany?.hasWurk) {
		candidatesStatusOptions[
			candidatesStatusOptions.findIndex((item) => item.value === CandidatesStatusValues.hired)
		].text = hiredText;
	}

	return (
		<>
			{hiredInWurk && defaultValue === CandidatesStatusValues.hired ? (
				<TypographyText className={styles['text-status']}>{statusLabel}</TypographyText>
			) : (
				<StatusSelect
					options={candidatesStatusOptions}
					onChange={onChangeHandler}
					{...{ value, ...rest }}
				/>
			)}
		</>
	);
};

export default React.memo(CandidateSelectStatus);
