import React, { FC } from 'react';
import classNames from 'classnames';

import ErrorPageLayoutInterface from './error-page-layout.interface';
import Title from '../../../atoms/title/Title';
import TypographyText from '../../../atoms/ui/typography-text';

import styles from './ErrorPageLayout.module.scss';
import Logo from '../../../atoms/icons/logo';

const ErrorPageLayout: FC<ErrorPageLayoutInterface> = ({
	title,
	text,
	className,
	withLogo,
	...rest
}) => {
	return (
		<div className={classNames(styles.wrap, className)} {...rest}>
			<div className={styles.content}>
				{withLogo ? <Logo type="simple" /> : null}

				<Title component="h2" fontSize={70} fontWeight={600} className={styles.title}>
					{title}
				</Title>
				<TypographyText className={styles.desc}>{text}</TypographyText>
			</div>
		</div>
	);
};

export default ErrorPageLayout;
