import React, { FC } from 'react';

import SearchInputFilterInterface from './search-input-filter.interface';
import InputWithFloatLabel from '../../../organisms/form-elemets/input-with-float-label/InputWithFloatLabel';
import styles from './SearchInputFilter.module.scss';

const SearchInputFilter: FC<SearchInputFilterInterface> = ({
	id = 'search',
	label = 'Search',
	inputSettings,
	withClear,
	onClearClickHandler,
	...rest
}) => {
	return (
		<div className={styles['input-filter']}>
			<InputWithFloatLabel
				{...{ id, label, inputSettings, ...rest }}
				iconSettings={{ color: '#cbccdc' }}
				iconBefore="search"
				maxWidth={156}
			/>

			{withClear ? <div className={styles.clear} onClick={onClearClickHandler} /> : null}
		</div>
	);
};

export default SearchInputFilter;
