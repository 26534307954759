import React, { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useController, useForm } from 'react-hook-form';
import classNames from 'classnames';

import { createFacility, updateFacility } from '../../../../store/slices/facilities.slice';
import { getErrorTargetByName } from '../../../../helpers';
import { ErrorType } from '../../../../helpers/forms/forms';
import { RootStateType } from '../../../../store/reducers/root-reducer';
import OptionInterface from '../../../atoms/form/option/option.interface';
import SelectWithFloatLabel from '../../form-elemets/select-with-float-label/SelectWithFloatLabel';
import FacilityFormInterface from './facility-form.interface';
import Button from '../../../atoms/buttons/button/Button';
import InputWithFloatLabel from '../../form-elemets/input-with-float-label/InputWithFloatLabel';
import Legend from '../../../atoms/form/legend';
import Fieldset from '../../form-elemets/fieldset';
import FormItem from '../../../molecules/form/form-item/FormItem';
import FormActions from '../../../molecules/form/form-actions';
import Loader from '../../../molecules/loader';
import EinNumberField from '../ein-number-field';
import useActiveCompany from '../../../../helpers/hooks/use-active-company/useActiveCompany';
import { statesOptions } from '../../../../helpers/custom/data-options/options';

const FacilityForm: FC<FacilityFormInterface> = ({
	facilityItem,
	setCreatedFacilityState,
	backHandler,
}) => {
	const dispatch = useDispatch();
	const { register, errors, handleSubmit, control } = useForm();
	const {
		facilities: { loading: facilitiesLoading },
		companies: { activeCompanyId, items: companies },
	} = useSelector((state: RootStateType) => state);
	const activeCompany = useActiveCompany(activeCompanyId, companies);
	const [errorTargets, setErrorTargets] = useState<ErrorType[]>([]);
	const { field: stateProps } = useController({
		name: 'state',
		defaultValue: facilityItem?.state,
		rules: { required: true },
		control,
	});

	const onSubmitHandler = useCallback(
		async (data: object) => {
			const result = {};

			for (const createdFacilityStateKey in data) {
				// @ts-ignore
				if (data[createdFacilityStateKey]) {
					// @ts-ignore
					result[createdFacilityStateKey] = data[createdFacilityStateKey];
				}
			}

			if (facilityItem) {
				try {
					await dispatch(updateFacility(facilityItem.id, result));

					if (backHandler) backHandler();
				} catch (errorData: any) {
					if (errorData.errors) setErrorTargets(errorData.errors as ErrorType[]);
				}
			} else {
				try {
					const facility = await dispatch(createFacility(result));

					if (setCreatedFacilityState) {
						//@ts-ignore
						setCreatedFacilityState(facility.id, facility.name);
					}

					if (backHandler) backHandler();
				} catch (errorData: any) {
					if (errorData.errors) setErrorTargets(errorData.errors as ErrorType[]);
				}
			}
		},
		[backHandler, dispatch, facilityItem, setCreatedFacilityState],
	);

	const getFacilityOptions = useCallback((): OptionInterface[] => statesOptions, []);

	let errorZip = useMemo(
		() => getErrorTargetByName({ targets: errorTargets, name: 'zip' }),
		[errorTargets],
	);
	if (!errorZip) errorZip = getErrorTargetByName({ targets: errorTargets, name: 'state' });

	const getZipErrors = useMemo(() => {
		if (errors.zip && errors.zip.type === 'minLength') return errors.zip.message;
		else if (errors.zip) return '"Zip code" is a required field';
		//@ts-ignore
		else if (errorZip && errorZip.constraints?.IsConsistentZipAndState)
			return 'The zip code does not match the state';
		//@ts-ignore
		else if (errorZip && errorZip.constraints?.IsValidZipCode) {
			//@ts-ignore
			return errorZip.constraints?.IsValidZipCode;
		} else return '';
	}, [errorZip, errors.zip]);

	// @ts-ignore
	return (
		<>
			{facilitiesLoading && <Loader wide />}

			<form onSubmit={handleSubmit(onSubmitHandler)}>
				<Fieldset>
					<Legend hidden>Enter facility details below.</Legend>

					<Fieldset>
						<Legend small extraMargin>
							Enter facility details below.
						</Legend>

						<InputWithFloatLabel
							className="form-item"
							minWidth="calc(50% - 5px)"
							maxWidth="calc(50% - 5px)"
							id="address1"
							ref={register()}
							label="Address 1"
							inputSettings={{
								name: 'address1',
								autoFocus: false,
								autoComplete: 'nope',
								defaultValue: facilityItem?.address1,
							}}
						/>

						<InputWithFloatLabel
							className="form-item"
							minWidth="calc(50% - 5px)"
							maxWidth="calc(50% - 5px)"
							id="address2"
							ref={register()}
							label="Adress 2"
							inputSettings={{
								name: 'address2',
								autoComplete: 'nope',
								defaultValue: facilityItem?.address2,
							}}
						/>

						<InputWithFloatLabel
							className={classNames('form-item', 'disable-bottom-margin')}
							minWidth="calc(50% - 5px)"
							maxWidth="calc(50% - 5px)"
							id="city"
							ref={register({
								required: true,
							})}
							label="City"
							error={errors.city && '"City" is a required field'}
							inputSettings={{
								name: 'city',
								autoComplete: 'nope',
								defaultValue: facilityItem?.city,
							}}
						/>

						<FormItem
							className={classNames('form-item', 'disable-bottom-margin')}
							minWidth="calc(50% - 5px)"
							maxWidth="calc(50% - 5px)"
						>
							<SelectWithFloatLabel
								className={classNames('form-item', 'disable-bottom-margin')}
								minWidth="calc(40% - 5px)"
								maxWidth="calc(40% - 5px)"
								error={errors.state && '"State" is a required field'}
								selectSettings={{
									options: getFacilityOptions(),
									name: 'state',
									defaultValue: facilityItem?.state,
								}}
								id="state"
								label="State"
								{...stateProps}
							/>

							<InputWithFloatLabel
								className={classNames('form-item', 'disable-bottom-margin')}
								minWidth="calc(60% - 5px)"
								maxWidth="calc(60% - 5px)"
								id="zip"
								ref={register({
									required: true,
									minLength: {
										value: 5,
										message: 'zip must be longer than or equal to 5 characters',
									},
								})}
								label="Zip code"
								error={getZipErrors}
								inputSettings={{
									name: 'zip',
									autoComplete: 'nope',
									defaultValue: facilityItem?.zip,
								}}
							/>
						</FormItem>
					</Fieldset>

					{activeCompany?.hasWurk && (
						<Fieldset>
							<Legend small extraMargin>
								Add your EIN (optional).
							</Legend>

							<EinNumberField
								className="form-item"
								minWidth="calc(50% - 5px)"
								maxWidth="calc(50% - 5px)"
								error={errors?.ein?.message}
								selectSettings={{ defaultValue: facilityItem?.ein }}
							/>
						</Fieldset>
					)}

					<Fieldset>
						<Legend small extraMargin>
							Give your facility a friendly name (optional).
						</Legend>

						<InputWithFloatLabel
							className="form-item"
							minWidth="calc(50% - 5px)"
							maxWidth="calc(50% - 5px)"
							id="name"
							ref={register()}
							label="Facility name"
							error={errors.name && '"Facility name" is a required field'}
							inputSettings={{
								name: 'name',
								defaultValue: facilityItem?.name,
							}}
						/>

						<FormActions centeredContent>
							<Button>{facilityItem ? 'Save facility' : 'Add facility'}</Button>
						</FormActions>
					</Fieldset>
				</Fieldset>
			</form>
		</>
	);
};

export default React.memo(FacilityForm);
