export const GAProduct = {
	sparkBoost: 'spark_boost',
};

export const GAContentId = {
	boostJob: 'boost_job',
	addCredits: 'add_credits',
	jobList: 'job_list',
	job: 'job',
	jobPreview: 'job_preview',
	account: 'account',
	postAJob: 'post_a_job',
};

export const GAContentGroup = {
	boostJob: 'Boost Job',
	addCredits: 'Add Credits',
	jobList: 'Job List',
	job: 'Job',
	jobPreview: 'Job Preview',
	account: 'Account',
	postAJob: 'Post a Job',
};

export const GAContentType = {
	candidates: 'candidates',
	analytics: 'analytics',
	settings: 'settings',
	details: 'details',
	billing: 'billing',
	'my-profile': 'my_profile',
	company: 'company',
	templates: 'templates',
	facilities: 'facilities',
	userInfo: 'user_info',
	companyInfo: 'company_info',
	validateEmail: 'validate_email',
	create: 'create',
	update: 'update',
};

// not used for now (after boost downgrade)
export const GABoostPeriod = {
	twoWeeks: '2_weeks',
	oneMonth: '1_month',
};

export const GAMethod = {
	popup: 'pop_up',
	banner: 'banner',
	header: 'header',
	button: 'button',
};

export const GAItemBrand = {
	sparkBoost: 'spark_boost',
	cinc: 'careers_in_cannabis',
	engin: 'engin',
};

export const GAJobStatus = {
	boosted: 'boosted',
	active: 'active',
	inactive: 'inactive',
};
