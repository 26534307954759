import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../../../store/reducers/root-reducer';

import EditCompanyEoeSectionInterface from './edit-company-eoe-section.interface';
import EditableViewForm from '../../editable-view-form';
import EditableTextEditor from '../../editable-text-editor';
import useActiveCompany from '../../../../helpers/hooks/use-active-company/useActiveCompany';
import { CompanyInterface, editCompany } from '../../../../store/slices/companies.slice';

const EditCompanyEoeSection: FC<EditCompanyEoeSectionInterface> = () => {
	const { register, handleSubmit } = useForm();
	const dispatch = useDispatch();
	const [editModeState, setEditModeState] = useState<boolean>();

	const {
		companies: { activeCompanyId, items, loading },
	} = useSelector((state: RootStateType) => state);
	const activeCompany = useActiveCompany(activeCompanyId, items);

	const onSubmit = (data: Partial<CompanyInterface>) => {
		setEditModeState(true);

		if (activeCompany) {
			dispatch<any>(editCompany(activeCompany.id, data)).then(() => setEditModeState(false));
		}
	};

	return (
		<EditableViewForm
			{...{ editModeState, loading }}
			onSubmit={handleSubmit(onSubmit)}
			sectionTitle="Equal opportunity employer"
		>
			<EditableTextEditor
				text={activeCompany?.eoe}
				textareaSettings={{ name: 'eoe', ref: register() }}
			/>
		</EditableViewForm>
	);
};

export default EditCompanyEoeSection;
