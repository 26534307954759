import React, { FC } from 'react';
import classNames from 'classnames';

import DashIconInterface from './dash-icon.interface';
import Icon from '../../icons/icon';

import styles from './DashIcon.module.scss';

const DashIcon: FC<DashIconInterface> = ({ className, ...rest }) => {
	return <Icon className={classNames(styles.icon, className)} {...rest} />;
};

export default DashIcon;
