import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';

import CheckboxWithLabelInterface from './checkbox-with-label.interface';
import FormItem from '../form-item/FormItem';
import Icon from '../../../atoms/icons/icon';
import Error from '../../ui/error';
import Label from '../../../atoms/form/label/Label';

import styles from './CheckboxWithLabel.module.scss';

const CheckboxWithLabel: FC<CheckboxWithLabelInterface> = React.forwardRef(
	(
		{
			id,
			defaultChecked,
			className,
			icon: IconContent = 'check',
			onChange,
			label,
			tabIndex,
			isWhiteTheme,
			error,
			disabled,
			...rest
		},
		ref,
	) => {
		const [checkedState, setCheckedState] = useState(false);

		useEffect(() => {
			setCheckedState(!!defaultChecked);
		}, [defaultChecked]);

		return (
			<FormItem
				className={classNames(styles.checkbox, disabled && styles.disabled, className)}
				{...{ tabIndex }}
			>
				<input
					type="checkbox"
					checked={checkedState}
					className={classNames(styles.input, isWhiteTheme && styles['white-theme'])}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						setCheckedState((prevState) => !prevState);

						if (onChange) {
							onChange(event);
						}
					}}
					{...{ disabled, id, ref, ...rest }}
				/>

				<Label
					htmlFor={id}
					className={classNames(
						styles.label,
						label && styles['has-text'],
						isWhiteTheme && styles['white-theme'],
					)}
				>
					{typeof IconContent === 'string' ? (
						<Icon className={styles.icon} icon={IconContent} />
					) : (
						<IconContent className={styles.icon} />
					)}

					{label}
				</Label>

				<Error className={styles.error}>{error}</Error>
			</FormItem>
		);
	},
);

export default CheckboxWithLabel;
