import React, { FC } from 'react';
import classNames from 'classnames';

import 'rc-slider/assets/index.css';

import styles from './CreditsIncrementer.module.scss';
import { CREDITS_MAX_VALUE, CREDITS_MIN_VALUE, CREDITS_STEP } from '../../../../helpers/constants';

import CreditsIncrementerInterface from './credits-incrementer.interface';
import NumberIncrementer from '../../../atoms/ui/number-incrementer';

const CreditsIncrementer: FC<CreditsIncrementerInterface> = ({ creditsValue, onValueChange }) => {
	return (
		<div className={classNames(styles['credits-incrementer'])}>
			<NumberIncrementer
				step={CREDITS_STEP}
				minValue={CREDITS_MIN_VALUE}
				maxValue={CREDITS_MAX_VALUE}
				value={creditsValue}
				onValueChange={onValueChange}
			/>
		</div>
	);
};

export default React.memo(CreditsIncrementer);
