import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { auth } from '../../helpers';
import { Dispatch } from 'react';
import userApi, { changeAuthorizedUserPasswordPayloadType } from '../../api/user.api';
import companiesApi from '../../api/companies.api';
import { getAvailableCompaniesFailed, getAvailableCompaniesSuccess } from './companies.slice';
import { SignUpInterface } from './authorization.slice';

export interface UserAndCodeInfoInterface {
	validationCode: string;
	user: Partial<SignUpInterface>;
}

export type UserCoreInfoType = {
	id: number;
	firstName: string;
	lastName: string;
};

export type CommunicationPreferencesType = {
	weeklyDigest?: boolean;
};

export type JobNotificationsType = {
	newRelevantApplication?: boolean;
};

export type JobSettingsType = {
	settingsName: string;
	settingsValue: boolean;
	userId: number;
	firstName?: string;
	lastName?: string;
};

export interface UserSliceInterface {
	loading?: boolean;
	editLoading?: boolean;
	editEmailLoading?: boolean;
	changePasswordLoading?: boolean;
	authorized: boolean;
	emailValidationLoading: boolean;
}

export type ProfileType = {
	firstName: string;
	lastName: string;
	gender: string;
	address1: string;
	address2: string;
	city: string;
	state: string;
	zip: string;
	phoneCountryCode: string;
	phoneNumber: string;
	title: string;
	calendarLink?: string;
	degree: number;
	age21: boolean;
	latino?: string;
	veteran?: string;
	disability?: string;
	linkedInUrl: string;
	resumeUrl: string;
	maxCommute: number;
	receiveWeeklyDigest?: boolean;
};

export interface UserInfoInterface {
	id: number;
	email: string;
	createdAt: number;
	needEmailValidation?: boolean;
	profile: ProfileType;
}

export interface UserInterface extends UserSliceInterface {
	info?: Partial<UserInfoInterface>;
}

const initialState: UserInterface = {
	authorized: auth.isAuthCookiesExist(),
	loading: false,
	editLoading: false,
	editEmailLoading: false,
	emailValidationLoading: false,
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		loading: (state) => {
			state.loading = true;
		},
		loaded: (state) => {
			state.loading = false;
		},
		editLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.editLoading = payload;
		},
		editEmailLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.editEmailLoading = payload;
		},
		changePasswordInProcess: (state) => {
			state.changePasswordLoading = true;
		},
		changePasswordFinished: (state) => {
			state.changePasswordLoading = false;
		},
		resetUserToken: (state) => {
			state.authorized = false;
			auth.logout();
		},
		setAuthorizeState: (state, { payload }: PayloadAction<boolean>) => {
			state.authorized = payload;
		},
		setUserToken: (state, { payload }) => {
			auth.authorize(payload);

			state.authorized = true;
		},
		getUserInfoSuccess: (state, { payload }: PayloadAction<UserInfoInterface>) => {
			state.info = payload;
			state.loading = false;
		},
		getUserInfoFailed: (
			state,
			{ payload: { logOut } }: PayloadAction<{ logOut?: boolean }>,
		) => {
			state.loading = false;

			if (logOut) {
				auth.logout();
			}
		},
		clearUserInfo: (state) => {
			state.info = undefined;
		},
		logout: () => {},
		setEmailValidationLoadingState: (state, { payload }: PayloadAction<boolean>) => {
			state.emailValidationLoading = payload;
		},
	},
});

export const getUserInfo = () => async (dispatch: Dispatch<any>) => {
	dispatch(loading());

	try {
		const [userInfo, companiesInfo] = await Promise.allSettled([
			userApi.getUserInfo(),
			companiesApi.getUserAvailableCompanies(),
		]);

		const user = userInfo.status === 'fulfilled' && userInfo.value;
		const companies = companiesInfo.status === 'fulfilled' ? companiesInfo.value : [];

		if (user) {
			dispatch(getUserInfoSuccess(user));
		}

		if (companies?.length) {
			dispatch(getAvailableCompaniesSuccess(companies));
		} else {
			dispatch(getAvailableCompaniesFailed());
		}
	} catch (error) {
		dispatch(
			getUserInfoFailed({
				//@ts-ignore
				logOut: error.status !== 402,
			}),
		);
	}
};

export const changeUserInfo = (data: Partial<ProfileType>) => async (dispatch: Dispatch<any>) => {
	dispatch(editLoading(true));

	try {
		const result = await userApi.changeUserInfo(data);
		dispatch(editLoading(false));
		dispatch(getUserInfoSuccess(result));
		return result;
	} catch (error) {
		dispatch(editLoading(false));

		return error;
	}
};

export const changeUserEmail =
	(data: Pick<UserInfoInterface, 'email'>) => async (dispatch: Dispatch<any>) => {
		dispatch(editEmailLoading(true));

		try {
			const result = await userApi.changeUserEmail(data);
			dispatch(editEmailLoading(false));
			dispatch(getUserInfoSuccess(result));
			return result;
		} catch (error) {
			dispatch(editEmailLoading(false));

			return error;
		}
	};

export const changeAuthorizedUserPassword =
	(data: changeAuthorizedUserPasswordPayloadType, callback?: () => void) =>
	async (dispatch: Dispatch<any>) => {
		dispatch(changePasswordInProcess());

		try {
			await userApi.changeAuthorizedUserPassword(data);

			if (callback) {
				callback();
			}

			dispatch(changePasswordFinished());
		} catch (error) {
			dispatch(changePasswordFinished());

			throw error;
		}
	};

export const getCommunicationPreferences = (companyId: string | number) => async () => {
	try {
		return await userApi.getCommunicationPreferences(companyId);
	} catch (error) {
		return error;
	}
};

export const changeCommunicationPreferences =
	(companyId: string | number, data: CommunicationPreferencesType) => async () => {
		try {
			return await userApi.changeCommunicationPreferences(companyId, data);
		} catch (error) {
			return error;
		}
	};

export const getJobNotificationsSettings = (jobId: number) => async () => {
	try {
		return await userApi.getJobNotificationsSettings(jobId);
	} catch (error) {
		return error;
	}
};

export const changeJobNotificationsSettings =
	(jobId: number | string, data: JobSettingsType[]) => async () => {
		try {
			return await userApi.changeJobNotificationsSettings(jobId, data);
		} catch (error) {
			return error;
		}
	};

export const getJobSubscribers =
	(jobId: number | string, settingsName: string, showActive?: boolean) => async () => {
		try {
			return await userApi.getJobSubscribers(jobId, settingsName, showActive);
		} catch (error) {
			return error;
		}
	};

export const loginUser = () => async (dispatch: Dispatch<any>) => {
	return dispatch(setAuthorizeState(true));
};

export const logoutUser = () => (dispatch: Dispatch<any>) => {
	dispatch(resetUserToken());
	dispatch(logout());
};

export const getValidationCodeFromLink =
	(token?: string | null) => async (dispatch: Dispatch<any>) => {
		if (!token) return;

		dispatch(setEmailValidationLoadingState(true));

		try {
			return await userApi.getValidationCodeFromLink(token);
		} catch (error: any) {
			return error;
		} finally {
			dispatch(setEmailValidationLoadingState(false));
		}
	};

export const {
	loading,
	loaded,
	changePasswordInProcess,
	changePasswordFinished,
	setAuthorizeState,
	setUserToken,
	editLoading,
	editEmailLoading,
	resetUserToken,
	getUserInfoSuccess,
	clearUserInfo,
	getUserInfoFailed,
	logout,
	setEmailValidationLoadingState,
} = userSlice.actions;
export default userSlice.reducer;
