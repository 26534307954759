import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type RedirectionErrorStatusType = null | 400 | 401 | 402 | 403 | 404 | 500;

export interface ErrorRedirectionInterface {
	error: RedirectionErrorStatusType;
	page?: string;
}

export interface ErrorRedirectionSliceInterface extends Omit<ErrorRedirectionInterface, 'page'> {}

const initialState: ErrorRedirectionSliceInterface = {
	error: null,
};

export const errorRedirectionSlice = createSlice({
	name: 'error redirection',
	initialState,
	reducers: {
		setError(state, { payload }: PayloadAction<RedirectionErrorStatusType>) {
			if (payload) {
				state.error = payload;
			}
		},
		clearError(state) {
			state.error = null;
		},
	},
});

export const { setError, clearError } = errorRedirectionSlice.actions;
export default errorRedirectionSlice.reducer;
