import { PayloadAction } from '@reduxjs/toolkit';

export interface FilterReducerInterface {
	filters: {
		text?: string;
		filterItem?: string;
		filterOrder?: string;
		filterType?: number;
	};
}

export const filterReducer = (
	state: FilterReducerInterface,
	{
		type,
		payload: {
			filters: { text, filterItem, filterOrder, filterType },
		},
	}: PayloadAction<FilterReducerInterface>,
) => {
	switch (type) {
		case 'SET_FILTER':
			return {
				...state,
				filters: {
					text,
					filterItem,
					filterOrder,
					filterType,
				},
			};
		default:
			throw new Error();
	}
};
