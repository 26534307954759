import axios from 'axios';

import {
	AUTH,
	SEND_VALIDATION_CODE,
	VALIDATE_CODE,
	TERMS,
	LOGIN_WITH_CODE,
	SEND_SIGN_UP_VALIDATION_CODE,
	SIGNUP,
	QUICK_SIGN_UP,
} from './api.path';
import {
	AuthorizationInfoInterface,
	AuthorizationPayloadInterface,
	AutoLoginData,
	SignUpInterface,
	SignUpResultInterface,
} from '../store/slices/authorization.slice';
import { ExternalErrorType } from '../helpers/axios/axios-global';

const authorizationApi = {
	getAuthorizationInfo: (
		data: Partial<AuthorizationPayloadInterface>,
	): Promise<AuthorizationInfoInterface> => {
		const url = `${AUTH}`;

		return axios.post(url, data);
	},
	getAuthorizationNeedTerms: (email: string) => {
		const url = `${TERMS}?email=${email}`;

		return axios.get(url);
	},
	signUp: (
		data: Partial<SignUpInterface>,
	): Promise<SignUpResultInterface | Partial<ExternalErrorType>> => {
		return axios.post(SIGNUP, data);
	},
	quickSignUp: (data: Partial<SignUpInterface>): Promise<any> => {
		return axios.post(QUICK_SIGN_UP, data);
	},
	sendValidationCode: (data: { email: string } & AutoLoginData): Promise<any> => {
		return axios.post(SEND_VALIDATION_CODE, data);
	},
	validateCode: (data: { email: string; code: number }): Promise<any> => {
		const url = `${VALIDATE_CODE}`;

		return axios.post(url, data);
	},
	sendSignUpValidationCode: (data: Partial<SignUpInterface> & AutoLoginData): Promise<any> => {
		return axios.post(SEND_SIGN_UP_VALIDATION_CODE, data);
	},
	loginWithCodeThe: (data: {
		email: string;
		password: string;
		code: number;
		acceptedCustomerTerms: number;
	}): Promise<any> => {
		const url = `${LOGIN_WITH_CODE}`;

		return axios.post(url, data);
	},
	loginWithEmailAndCode: (data: { email: string; code: number; acceptedCustomerTerms?: number }): Promise<any> => {
		const url = `${LOGIN_WITH_CODE}`;

		return axios.post(url, data);
	},
};

export default authorizationApi;
