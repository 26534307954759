import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';

import { RootStateType } from '../../../../store/reducers/root-reducer';
import EditableViewForm from '../../editable-view-form';
import InputWithFloatLabel from '../../form-elemets/input-with-float-label/InputWithFloatLabel';
import EditCompanyVideoSectionInterface from './edit-company-video-section.interface';
import useActiveCompany from '../../../../helpers/hooks/use-active-company/useActiveCompany';
import {
	CompanyInterface,
	editCompany,
	setCompaniesErrors,
} from '../../../../store/slices/companies.slice';

const EditCompanyVideoSection: FC<EditCompanyVideoSectionInterface> = () => {
	const { register, handleSubmit } = useForm();
	const dispatch = useDispatch();
	const [editModeState, setEditModeState] = useState<boolean>();
	const [videoUrlState, setVideoUrlState] = useState<number | string | undefined>();
	const [cancelState, setCancelState] = useState<boolean>(true);

	const {
		companies: { activeCompanyId, items, loading },
	} = useSelector((state: RootStateType) => state);

	const activeCompany = useActiveCompany(activeCompanyId, items);

	useEffect(() => {
		if (activeCompany) {
			setVideoUrlState(activeCompany.videoUrl);

			dispatch(setCompaniesErrors([]));
		}
	}, [activeCompany, cancelState, dispatch]);

	const onSubmit = (data: Partial<CompanyInterface>) => {
		setEditModeState(true);

		if (activeCompany) {
			dispatch<any>(editCompany(activeCompany.id, data)).then(() => setEditModeState(false));
		}
	};

	return (
		<EditableViewForm
			{...{ editModeState, loading }}
			afterModeChange={setCancelState}
			onSubmit={handleSubmit(onSubmit)}
			sectionTitle="Video"
			editButtonText="Edit link"
			desc="Video is displayed as the primary asset on the company page."
		>
			<InputWithFloatLabel
				className={classNames('form-item')}
				minWidth="calc(65% - 32px)"
				maxWidth="calc(65% - 32px)"
				id="video-url"
				ref={register()}
				visibleDisableStyles={false}
				label=""
				inputSettings={{
					name: 'videoUrl',
					placeholder: 'URL to video',
					// defaultValue: activeCompany?.videoUrl,
					value: cancelState ? activeCompany?.videoUrl || '' : videoUrlState,
					onChange: (event) => {
						setVideoUrlState(event.target.value);
					},
				}}
			/>
		</EditableViewForm>
	);
};

export default React.memo(EditCompanyVideoSection);
