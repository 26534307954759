import React, { FC, useEffect, useMemo, useState } from 'react';

import Container from '../../../templates/container';
import styles from './JobAnalytics.module.scss';
import JobAnalyticsInterface from './job-analytics.interface';
import classNames from 'classnames';
import Title from '../../../atoms/title/Title';
import JobApplicantsChart from '../../../molecules/custom/job-applicants-chart';
import {
	getJob,
	getJobAnalytics,
	JobAnalyticsDataInterface,
} from '../../../../store/slices/job.slice';
import Loader from '../../../molecules/loader';
import useJob from '../../../../helpers/hooks/use-job/useJob';
import { dateInMDYFormat } from '../../../../helpers/custom/datetime';
import BoostJobForm from '../boost-job-form';
import { useDispatch } from 'react-redux';
import { checkJobIsBoosted } from '../../../../helpers/custom/job';
import useCompanies from '../../../../helpers/hooks/use-companies/useCompanies';
import { JobStatusType } from '../../../../helpers/custom/common';

const JobAnalytics: FC<JobAnalyticsInterface> = () => {
	const { info: jobInfo, boostLoading } = useJob();
	const { id } = jobInfo || {};
	const { activeCompanyId, activeCompanyBalance, activeCompanyCanBoost } = useCompanies();

	const dispatch = useDispatch();

	const [dataLoading, setDataLoading] = useState(false);
	const [analyticsData, setAnalyticsData] = useState<JobAnalyticsDataInterface[] | null>(null);

	const isBoosted = useMemo(
		() => checkJobIsBoosted(jobInfo?.boostId, jobInfo?.jobBoosts, jobInfo?.status),
		[jobInfo?.boostId, jobInfo?.jobBoosts, jobInfo?.status],
	);

	useEffect(() => {
		(async () => {
			setDataLoading(true);

			if (id) {
				const analyticsResult = await getJobAnalytics(id);

				if (analyticsResult?.length) {
					const transformed = analyticsResult.map(
						(dataItem: JobAnalyticsDataInterface) => {
							const customDate = dateInMDYFormat(dataItem.date);

							return {
								date: customDate,
								numberOfApplications: Number(dataItem.numberOfApplications),
							};
						},
					);

					setAnalyticsData(transformed);
				} else {
					setAnalyticsData([]);
				}
			}

			setDataLoading(false);
		})();
	}, [id]);

	const applicantsChartSection = useMemo(() => {
		const displayChart =
			(!!jobInfo?.publishedAt && analyticsData !== null) ||
			(!jobInfo?.publishedAt && analyticsData?.length);

		const analytics = !analyticsData?.length
			? [
					{
						date: dateInMDYFormat(Date.now()),
						numberOfApplications: 0,
					},
			  ]
			: analyticsData;

		return (
			<div
				className={classNames(
					styles.section,
					styles['applications-chart'],
					displayChart ? '' : styles['with-empty-message'],
				)}
			>
				<div className={styles['top-section']}>
					<div className={styles.text}>
						<Title
							component="h3"
							fontSize={18}
							marginBottom={30}
							className={styles.title}
						>
							Applications
						</Title>
					</div>

					{displayChart && activeCompanyCanBoost ? (
						<div className={styles.legend}>Boosted</div>
					) : null}
				</div>

				{displayChart ? (
					<JobApplicantsChart analyticsData={analytics} />
				) : (
					<div className={styles['empty-chart-message']}>
						This job hasn't been activated yet.
					</div>
				)}
			</div>
		);
	}, [activeCompanyCanBoost, analyticsData, jobInfo?.publishedAt]);

	const renderBoostForm = useMemo(() => {
		const onBoostSuccess = () => {
			if (id)
				dispatch(
					getJob(id.toString(), {
						pageSize: 15,
						pageNumber: 1,
					}),
				);
		};

		return (
			<div
				className={classNames(
					styles['boost-job'],
					isBoosted || jobInfo?.status === JobStatusType.inactive ? styles.inactive : '',
				)}
			>
				<BoostJobForm
					job={jobInfo}
					companyId={activeCompanyId}
					companyBalance={activeCompanyBalance}
					containerClassName={styles['boost-job-form']}
					boostLoading={boostLoading}
					customSuccessHandler={onBoostSuccess}
				/>
			</div>
		);
	}, [activeCompanyBalance, activeCompanyId, boostLoading, dispatch, id, isBoosted, jobInfo]);

	if (dataLoading) return <Loader thin className={styles.loader} />;

	return (
		<Container className={styles.analytics}>
			{applicantsChartSection}

			{activeCompanyCanBoost ? renderBoostForm : null}
		</Container>
	);
};

export default React.memo(JobAnalytics);
